import request from '../Request';

export default function AuthorizedSignatoryGetRequest(user) {
  const method = 'GET';

  const url = 'authorized-signatories';

  // const data = {
  //     authorized_signatory: authorizedSignatory
  // };

  return request(user || 'authenticated-user', method, url);
}
