import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import { BlockFormLabel } from '../../../components/styledComponents';
import Select from 'react-select';
import countries from './ncic_citizenship_codes';

export default function CitizenshipCountryQuestion({ isHidden, value, onBack, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState([...value]);

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col xs="12" sm="6">
          <BlockFormLabel>
            <Typography variant="h6">{t('question12')}</Typography>
          </BlockFormLabel>
          <FormLabel>
            <Typography variant="subtitle2">{t('question12_extra')}</Typography>
          </FormLabel>
          <FormGroup>
            <Select
              isMulti
              options={countries.map(c => ({ ...c, value: c.label }))}
              classNamePrefix="airbadge"
              className="form-select"
              value={state}
              placeholder=""
              onChange={option => setState(option)}
            />
          </FormGroup>
        </Col>
      </Row>

      <BackButton onClick={onBack} />
      <NextButton isDisabled={!state || !state.length} onClick={() => onNext(state)} />
    </>
  );
}
