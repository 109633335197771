import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

export default function DynamsoftTest() {
  const [scannedFile, setScannedFile] = useState(null);
  const [DWObject, setDWObject] = useState(null);

  const onCaptureImageClick = async () => {
    // Setup event handler
    DWObject.Addon.Camera.on('video-closed', () => {
      let type = null;
      let indices = [];
      let extension = '';
      if (DWObject.HowManyImagesInBuffer === 1) {
        type = window.Dynamsoft.DWT.EnumDWT_ImageType.IT_JPG;
        extension = '.jpg';
        indices = [0];
      } else if (DWObject.HowManyImagesInBuffer > 1) {
        type = window.Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF;
        extension = '.pdf';
        indices = [...Array(DWObject.HowManyImagesInBuffer).keys()];
      }

      if (DWObject.HowManyImagesInBuffer > 0) {
        DWObject.ConvertToBlob(
          indices,
          type,
          result => {
            try {
              const file = new File([result], `ScannedImage${extension}`, { type: result.type });
              setScannedFile(file);
              const reader = new FileReader();
              reader.onload = function (e) {
                document.getElementById('documentImage').src = e.target.result;
              };
              reader.readAsDataURL(file);
            } catch (e) {
              console.error(e);
            }
          },
          (errorCode, errorString) => console.error(errorCode, errorString)
        );
      }

      setTimeout(() => {
        DWObject.RemoveAllImages();
      }, 500);
    });

    // Start the webcam scanner
    DWObject.Addon.Camera.scanDocument({
      scannerViewer: {
        background: 'red',
        enableBorderDetection: true,
        resolution: { visibility: true },
        autoScan: { visibility: false },
        autoDetect: { visibility: true, enableAutoDetect: true, acceptedPolygonConfidence: 80 },
        continuousScan: { visibility: false, enableContinuousScan: true },
        switchCamera: { visibility: false },
        loadLocalFile: { visibility: true },
      },
      filterViewer: { exitDocumentScanAfterSave: true, filter: { visibility: false } },
    });
  };

  useEffect(() => {
    setScannedFile(null);
    window.Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      const obj = window.Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
      obj.Viewer.setViewMode(2, 2);
      setDWObject(obj);
    });
    window.Dynamsoft.DWT.Load();
  }, []);

  return (
    <div className="app">
      <div className="app-body m-0">
        <div id="mainContentArea" className={'main'}>
          <div className="pt-4">
            <Container fluid={true}>
              <Row>
                <Col className="text-center">
                  <h1 className="mb-4 page-title">Dynamsoft Testing</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          flexDirection: 'column',
                          height: '100%',
                          borderWidth: 2,
                          borderRadius: 2,
                          borderColor: 'rgb(238, 238, 238)',
                          borderStyle: 'dashed',
                          backgroundColor: 'rgb(250, 250, 250)',
                          color: 'rgb(189, 189, 189)',
                        }}
                      >
                        <div style={{ marginBottom: 10 }}>Click the button to use a webcam to capture an image</div>
                        <Button size="sm" onClick={onCaptureImageClick}>
                          Scan Image Using Webcam
                        </Button>
                        {scannedFile ? (
                          <div
                            className="fileName"
                            style={{ fontSize: 'larger', opacity: 0.5, color: 'black' }}
                          >{`${scannedFile.name}`}</div>
                        ) : (
                          <div className="fileName">(No images scanned)</div>
                        )}
                      </div>

                      <div className="mt-3 mb-3">&nbsp;</div>

                      <img id="documentImage" alt="document image" />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
