import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import { getDrivingOptions } from '../../../../../shared/BadgeTypes/drivingOptionsUtils';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';

export default function DrivingEndorsementPicker({
  context,
  isDisabled,
  isReadOnly,
  displayAsGridItem,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const { drivingEndorsementConfig, drivingSelection, setDrivingSelection, shouldSkipDefaultSelection, data } = context;

  const [isShown, setIsShown] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    if (!data.badgeType) {
      setDrivingSelection(null);
      setIsShown(false);
      return;
    }
    const drivingOptions = getDrivingOptions(drivingEndorsementConfig);

    let driving4Option = drivingEndorsementConfig?.options?.driving_4 || {};
    driving4Option = { ...driving4Option, value: 'driving_4' };

    let driving5Option = drivingEndorsementConfig?.options?.driving_5 || {};
    driving5Option = { ...driving5Option, value: 'driving_5' };

    let limitedOption = drivingEndorsementConfig?.options?.limited || {};
    limitedOption = { ...limitedOption, value: 'limited' };

    let movementAreasOption = drivingEndorsementConfig?.options?.movement_areas || {};
    movementAreasOption = { ...movementAreasOption, value: 'movement_areas' };

    let noneOption = drivingEndorsementConfig?.options?.none || {};
    noneOption = { ...noneOption, value: 'none' };

    let enabledOptions = [];
    if (drivingEndorsementConfig?.options) {
      enabledOptions = [driving4Option, driving5Option, limitedOption, movementAreasOption, noneOption].filter(
        option => option.isEnabled
      );
      const options = [];
      if (noneOption.isEnabled) options.push(drivingOptions.find(option => option.value === 'none'));
      if (limitedOption.isEnabled) options.push(drivingOptions.find(option => option.value === 'limited'));
      if (movementAreasOption.isEnabled) options.push(drivingOptions.find(option => option.value === 'movement_areas'));
      if (driving4Option.isEnabled) options.push(drivingOptions.find(option => option.value === 'driving_4'));
      if (driving5Option.isEnabled) options.push(drivingOptions.find(option => option.value === 'driving_5'));
      setDropdownOptions(options);
    } else {
      setDropdownOptions(drivingOptions);
    }

    setIsShown(!drivingEndorsementConfig?.options || enabledOptions.length > 1);

    if (!shouldSkipDefaultSelection) {
      // Determine which option is the default
      const defaultOption = enabledOptions.find(option => option.isDefault);
      if (defaultOption && enabledOptions.length > 1) {
        setDrivingSelection(drivingOptions.find(option => option.value === defaultOption.value));
      } else if (enabledOptions.length === 1) {
        setDrivingSelection(drivingOptions.find(option => option.value === enabledOptions[0].value));
      } else if (drivingEndorsementConfig?.options && enabledOptions.length === 0) {
        setDrivingSelection(drivingOptions.find(option => option.value === 'none'));
      } else {
        setDrivingSelection(null);
      }
    }
  }, [data.badgeType, drivingEndorsementConfig]);

  if (dropdownOptions.length === 1) {
    return (
      <div>
        <Label>Which driving endorsement does this individual need?</Label>
        <br />
        <i>{drivingSelection?.label || 'error'}</i>
        <div>&nbsp;</div>
      </div>
    );
  }

  if (!isShown) {
    return null;
  }

  if (isReadOnly) {
    if (displayAsGridItem) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <ReadOnlyFormData label="Driving" value={drivingSelection?.label || 'error'} />
        </Grid>
      );
    }
    return (
      <div>
        <ReadOnlyFormData label="Driving" value={drivingSelection?.label || 'error'} />
      </div>
    );
  }

  return (
    <>
      <FormGroup>
        <Label className="required">Which driving endorsement does this individual need?</Label>
        <Select
          placeholder=""
          classNamePrefix="airbadge"
          className="form-select"
          options={dropdownOptions}
          value={drivingSelection}
          onChange={option => setDrivingSelection(option)}
          isDisabled={!!isDisabled}
        />
      </FormGroup>
    </>
  );
}
