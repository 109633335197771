import React from 'react';

export default function InfoBox({ className, children }) {
  return (
    <div
      className={className}
      style={{
        width: '100%',
        border: '1px solid #c8ced3',
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        padding: 12,
      }}
    >
      {children}
    </div>
  );
}
