import React, { useContext, useState } from 'react';
import { Col, Row } from 'reactstrap';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext, colors } from '../../../../../App';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import BadgeApplicationBadgerData from '../../BadgeApplicationsList/BadgeApplicationBadgerData';
import BadgeApplicationConfigEditor from '../../../ApplicationAdministration/BadgeApplications/BadgeApplicationConfigEditor';
import BadgeApplicationAuxConfigEditor from '../../../ApplicationAdministration/BadgeApplications/BadgeApplicationAuxConfigEditor';
import useConfirm from '../../../../../shared/useConfirm';
import trainingCourseApi from '../../../../../ajax/Training/trainingCourseApi';
import { handleError, notify } from '../../../../../utils';

export default function BadgeApplicationAdminActionPanel({ badgeApplication, reload }) {
  const app = useContext(AppContext);
  const showConfirm = useConfirm();
  const isSuperAdmin = app?.user?.isSuperAdmin;
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isBadgerModalOpen, setIsBadgerModalOpen] = useState(false);
  const [isAuxiliaryConfigModalOpen, setIsAuxiliaryConfigModalOpen] = useState(false);

  const onAutoAssignTrainingClicked = () => {
    showConfirm({
      title: 'Are you sure?',
      content: (
        <div>
          <div className="mb-2">The following will be done in each LMS that is connected and turned on:</div>
          <ul>
            <li className="mb-2">Create a user profile if the person doesn't already exist</li>
            <li>Assign all pre-selected training</li>
          </ul>
        </div>
      ),
      onConfirm: () => {
        const {
          uuid: badgeApplicationUuid,
          applicant: { uuid: applicantUuid },
        } = badgeApplication;
        app.api.toggleLoading(true);
        trainingCourseApi
          .createAccount({ userUuid: applicantUuid, badgeApplicationUuid })
          .then(({ success, message }) => {
            if (!success) {
              app.api.toggleLoading(false);
              return notify({ message: message, severity: 'error', duration: 10000 });
            }
            reload();
          })
          .catch(error => handleError({ error, message: 'Uh oh!' }))
          .finally(() => app.api.toggleLoading(false));
      },
      isDangerous: true,
      okButtonText: 'Auto-Assign Training Now',
    });
  };

  if (!badgeApplication || !isSuperAdmin) return null;

  return (
    <Row>
      <style>{`
        .icon-size-adjust {
          min-width: 38px;
        }
        .color-adjust {
          color: ${colors.danger}
        }
      `}</style>

      <Col>
        <List>
          <ListItem button onClick={onAutoAssignTrainingClicked}>
            <ListItemIcon classes={{ root: 'icon-size-adjust' }}>
              <FontAwesomeIcon className="fa-solid fa-bolt-lightning" color={colors.danger} />
            </ListItemIcon>
            <ListItemText primary="Auto-Assign Training Now" classes={{ root: 'color-adjust' }} />
          </ListItem>
        </List>
      </Col>
      <Col>
        <List>
          <ListItem button onClick={() => setIsConfigModalOpen(true)}>
            <ListItemIcon classes={{ root: 'icon-size-adjust' }}>
              <FontAwesomeIcon className="fa-solid fa-screwdriver-wrench" color={colors.danger} />
            </ListItemIcon>
            <ListItemText primary="Modify Process Config" classes={{ root: 'color-adjust' }} />
          </ListItem>
          <ListItem button onClick={() => setIsAuxiliaryConfigModalOpen(true)}>
            <ListItemIcon classes={{ root: 'icon-size-adjust' }}>
              <FontAwesomeIcon className="fa-solid fa-screwdriver-wrench" color={colors.danger} />
            </ListItemIcon>
            <ListItemText primary="Modify Auxiliary Config" classes={{ root: 'color-adjust' }} />
          </ListItem>
          <ListItem button onClick={() => setIsBadgerModalOpen(true)}>
            <ListItemIcon classes={{ root: 'icon-size-adjust' }}>
              <FontAwesomeIcon className="fa-solid fa-screwdriver-wrench" color={colors.danger} />
            </ListItemIcon>
            <ListItemText primary="Modify Badger Data" classes={{ root: 'color-adjust' }} />
          </ListItem>
        </List>
      </Col>

      <BadgeApplicationConfigEditor
        isOpen={isConfigModalOpen}
        config={badgeApplication}
        onClose={args => {
          setIsConfigModalOpen(false);
          if (args?.shouldReload) reload();
        }}
      />

      <BadgeApplicationAuxConfigEditor
        isOpen={isAuxiliaryConfigModalOpen}
        config={badgeApplication}
        onClose={args => {
          setIsAuxiliaryConfigModalOpen(false);
          if (args?.shouldReload) reload();
        }}
      />

      <BadgeApplicationBadgerData
        isOpen={isBadgerModalOpen}
        selectedBadgeApplication={badgeApplication}
        onClose={args => {
          setIsBadgerModalOpen(false);
          if (args?.shouldReload) reload();
        }}
      />
    </Row>
  );
}
