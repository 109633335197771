import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import NativeSelect from '../NativeSelect/NativeSelect';
import moment from 'moment/moment';

export const monthOptions = [
  { value: '01', label: '1 - January' },
  { value: '02', label: '2 - February' },
  { value: '03', label: '3 - March' },
  { value: '04', label: '4 - April' },
  { value: '05', label: '5 - May' },
  { value: '06', label: '6 - June' },
  { value: '07', label: '7 - July' },
  { value: '08', label: '8 - August' },
  { value: '09', label: '9 - September' },
  { value: '10', label: '10 - October' },
  { value: '11', label: '11 - November' },
  { value: '12', label: '12 - December' },
];

export default function StaggeredDatePicker({
  startingYear,
  endingYear,
  reverseYearOrder,
  defaultValue,
  onValidDate,
  onInvalidDate,
  isDisabled = false,
}) {
  const [yearOptions, setYearOptions] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);
  const [includeBlankYear, setIncludeBlankYear] = useState(true);
  const [includeBlankMonth, setIncludeBlankMonth] = useState(true);
  const [includeBlankDay, setIncludeBlankDay] = useState(true);
  const [chosenMonth, setChosenMonth] = useState(null);
  const [chosenDay, setChosenDay] = useState(null);
  const [chosenYear, setChosenYear] = useState(null);

  const onExpireDateChanged = ({ year, month, day }) => {
    if (year !== chosenYear || month !== chosenMonth) {
      setIncludeBlankDay(true);
      day = null;
    }

    if (year && month && !day) {
      const allowedDays = moment(`${year.value}-${month.value}`, 'YYYY-MM').daysInMonth();
      setDayOptions(
        Array(allowedDays)
          .fill('')
          .map((ignored, i) => {
            const day = 1 + i;
            return { label: `${day}`, value: `${day < 10 ? `0${day}` : day}` };
          })
      );
    }

    setChosenYear(year);
    setChosenMonth(month);
    setChosenDay(day);

    if (year && month && day) {
      onValidDate({ year: year.value, month: month.value, day: day.value });
    } else {
      onInvalidDate && onInvalidDate(null);
    }
  };

  useEffect(() => {
    if (!startingYear || !endingYear) return;

    let years = Array(endingYear - startingYear + 1)
      .fill('')
      .map((ignored, i) => ({
        label: `${endingYear - i}`,
        value: `${endingYear - i}`,
      }));

    if (!reverseYearOrder) {
      years = years.reverse();
    }

    setYearOptions(years);
  }, [startingYear, endingYear]);

  useEffect(() => {
    if (!defaultValue) return;

    const [year, month, day] = defaultValue.split('-');
    setChosenYear({ label: `${year}`, value: `${year}` });
    setChosenMonth(monthOptions.find(m => m.value === `${month}`));
    setChosenDay({ label: `${day}`, value: `${day}` });
    const allowedDays = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
    setDayOptions(
      Array(allowedDays)
        .fill('')
        .map((ignored, i) => {
          const day = 1 + i;
          return { label: `${day}`, value: `${day < 10 ? `0${day}` : day}` };
        })
    );
  }, [defaultValue]);

  return (
    <Row>
      <Col>
        <i>Year</i>
        <NativeSelect
          id="yearSelect"
          name="yearSelect"
          options={yearOptions}
          value={chosenYear}
          onChange={selectedOption => {
            setIncludeBlankYear(false);
            onExpireDateChanged({ year: selectedOption, month: chosenMonth, day: chosenDay });
          }}
          disabled={isDisabled}
          includeBlank={includeBlankYear}
        />
      </Col>
      <Col>
        <i>Month</i>
        <NativeSelect
          id="monthSelect"
          name="monthSelect"
          options={monthOptions}
          value={chosenMonth}
          onChange={selectedOption => {
            setIncludeBlankMonth(false);
            onExpireDateChanged({ year: chosenYear, month: selectedOption, day: chosenDay });
          }}
          disabled={isDisabled || !chosenYear}
          includeBlank={includeBlankMonth}
        />
      </Col>
      <Col>
        <i>Day</i>
        <NativeSelect
          id="daySelect"
          name="DaySelect"
          options={dayOptions}
          value={chosenDay}
          onChange={selectedOption => {
            setIncludeBlankDay(false);
            onExpireDateChanged({ year: chosenYear, month: chosenMonth, day: selectedOption });
          }}
          disabled={isDisabled || !chosenMonth}
          includeBlank={includeBlankDay}
        />
      </Col>
    </Row>
  );
}
