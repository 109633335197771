import React from 'react';
import { Alert } from 'reactstrap';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function BadgeApplicationTrainingConfirmation({ isOpen, onClose, onSave, fullName }) {
  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Badge Application Training Notification"
      isOpen={isOpen}
      onClose={onClose}
      closeLabel="Cancel"
      onSave={onSave}
      saveLabel="Manage Training Assignments"
    >
      <Alert color="warning">{fullName} has an open badge application</Alert>
      <p>
        If you intend to assign training directly related to the badge application, you will need to assign it from the
        badge application screen and not here.
      </p>
    </AirBadgeModal>
  );
}
