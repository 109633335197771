import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { appSettings } from '../../../App';
import request from '../../../ajax/Request';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';

export default function ImpersonateUser({ api, state, location }) {
  const [params, setParams] = useState({});
  const [user, setUser] = useState({ name: '', email: '' });
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [airport, setAirport] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const login = () => {
    setError('');
    setIsLoggingIn(true);
    api.toggleLoading(true);
    request('authenticated-user', 'POST', `admin/impersonate-user`, { ...params, code })
      .then(({ success, data }) => {
        if (success) {
          const { enrollment, ...rest } = data;
          const user = {
            ...rest,
            authToken: rest.auth_token,
            firstName: rest.first_name,
            middleName: rest.middle_name,
            lastName: rest.last_name,
            activeProfile: rest.active_profile,
            managerForCompanies: rest.manager_for_companies,
          };
          const role = user.profiles.length ? user.profiles[0].role.name : '';
          localStorage.setItem('AirportBadges.user', JSON.stringify(user));
          api.updateState({ user, role, enrollment });
          window.location.href = '#/default/home';
        }
      })
      .catch(e => {
        console.error(e);
        setError('INVALID AUTH CODE');
      })
      .finally(() => {
        api.toggleLoading(false);
        setIsLoggingIn(false);
      });

    return false;
  };

  const goToLogin = () => {
    window.location.href = '#/login';
    return false;
  };

  useEffect(() => {
    if (!state || !state.airport) return;
    setAirport(state.airport);

    const { search } = location;
    if (!search) {
      window.location.href = '#/login';
    } else {
      const segments = search.slice(1).split('&');
      const params = {};
      segments.forEach(segment => {
        const equalsSignIndex = segment.indexOf('=');
        const key = segment.substring(0, equalsSignIndex);
        params[key] = decodeURI(segment.substring(equalsSignIndex + 1));
      });
      setParams(params);

      api.toggleLoading(true);
      request('authenticated-user', 'POST', `admin/impersonate-user`, params)
        .then(({ success, user, code }) => {
          if (success) {
            setUser(user);
            setCode(code); // This only happens when working with localhost
          }
        })
        .catch(e => {
          console.error(e);
          setError('INVALID URL TOKEN');
        })
        .finally(() => api.toggleLoading(false));
    }
  }, [state.airport]);

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col>
            <div className="m-auto" style={{ maxWidth: '500px' }}>
              <div className="text-center py-4">
                <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
              </div>
              {error && (
                <Alert color="danger" className="text-center">
                  {error}
                </Alert>
              )}
              <Card className="p-4">
                <CardBody>
                  <h1 className="text-center mb-2">
                    <i className="fa fa-exclamation-triangle mr-2 text-danger" aria-hidden="true"></i>
                    <span>Impersonate User</span>
                    <i className="fa fa-exclamation-triangle ml-2 text-danger" aria-hidden="true"></i>
                  </h1>
                  <div className="text-center text-muted mb-2 mt-4">
                    <strong>
                      {user.name} ({user.email})
                    </strong>
                  </div>
                  <InputGroup className="mb-3">
                    <InputGroupText>
                      <i className="fa fa-lock" />
                    </InputGroupText>
                    <Input
                      autoFocus
                      id="tfa_input"
                      type="password"
                      value={code}
                      placeholder="Code"
                      autoComplete="one-time-code"
                      onChange={({ target }) => setCode(target.value)}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          const btn = document.getElementById('tfa_btn');
                          if (btn) btn.click();
                        }
                      }}
                    />
                  </InputGroup>
                  <Row>
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {isLoggingIn ? (
                        <Button id="tfa_btn" color="danger" onClick={login} disabled={true}>
                          Logging in as {user.name} ...
                        </Button>
                      ) : (
                        <Button id="tfa_btn" color="danger" onClick={login} disabled={!code}>
                          Login as {user.name}
                        </Button>
                      )}
                      <button style={{ marginTop: 15 }} type="button" className="btn btn-link" onClick={goToLogin}>
                        Return to Main Login
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-center">
                <p>AirBadge is Provided by</p>
                <p>{airport && airport.name}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
