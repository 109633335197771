import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import actionItemApi from '../../ajax/ActionItem/actionItemApi';
import { handleError } from '../../utils';

function ActionItemsLink({ label }) {
  const history = useHistory();
  return (
    <button
      style={{ padding: 0, position: 'relative', top: -1 }}
      className="btn btn-link"
      onClick={() => history.push('/default/action-items')}
    >
      {label}
    </button>
  );
}

export default function ActionItemsNotice({ relatedToUserUuid, badgeApplicationUuid }) {
  const [message, setMessage] = useState(null);

  const callApi = () => {
    actionItemApi
      .getCount({ relatedToUserUuid, badgeApplicationUuid })
      .then(({ success, count }) => {
        if (success && count) {
          setMessage(
            <span>
              There are open <ActionItemsLink label="action items" /> related to this badge application
            </span>
          );
        }
      })
      .catch(error => handleError({ error }));
  };

  useEffect(() => {
    if (!badgeApplicationUuid) return;

    setMessage(null);
    callApi();
  }, [badgeApplicationUuid]);

  if (!badgeApplicationUuid || !message) return null;

  return <Alert color="danger">{message}</Alert>;
}
