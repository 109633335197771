import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';
import { Alert, Button, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import BadgeApplicationPaymentForm from '../../../../Home/BadgeApplicationsList/components/BadgeApplicationPaymentForm';
import AirBadgeModal, {
  MODAL_SIZE_LARGE,
  MODAL_SIZE_MEDIUM,
} from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { AppContext, basePaymentChoices, invoicePaymentChoice, noChargePaymentChoice } from '../../../../../../App';
import badgeOfficeApi from '../../../../../../ajax/BadgeOffice/badgeOfficeApi';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { buildFullName, handleError, validEmailAddressRegex } from '../../../../../../utils';
import paymentsApi from '../../../../../../ajax/Payments/paymentsApi';
import InputWithCharacterLimit from '../../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import AutoCompleteDropdown, {
  AutoCompleteUserOption,
  filterUserOptions,
} from '../../../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import get from 'lodash/get';
import peopleApi from '../../../../../../ajax/People/peopleApi';
import useConfirm from '../../../../../../shared/useConfirm';

let usersRequestTimeout = null;
let usersAbortController = null;

export default function PayForBadgeApplicationAction() {
  const app = useContext(AppContext);
  const showConfirm = useConfirm();
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);

  const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
  const [isChangePayerModalOpen, setIsChangePayerModalOpen] = useState(false);
  const [paymentChoice, setPaymentChoice] = useState(basePaymentChoices.find(x => x.value === 'signatory'));
  const [shouldAllowPayInPerson, setShouldAllowPayInPerson] = useState(false);
  const [isMarkPaidModalOpen, setIsMarkPaidModalOpen] = useState(false);
  const [payer, setPayer] = useState('');
  const [amount, setAmount] = useState(0.0);
  const [notes, setNotes] = useState('');
  const [wasChanged, setWasChanged] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addLabel, setAddLabel] = useState('');
  const [addAmount, setAddAmount] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [paymentItems, setPaymentItems] = useState([]);
  const [airBadgeUser, setAirBadgeUser] = useState(null);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [payerChoice, setPayerChoice] = useState(null);
  const [email, setEmail] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [tender, setTender] = useState(null);

  const checkEmailForErrors = email => {
    if (!email) {
      return setHasEmailError(false);
    }

    setHasEmailError(!validEmailAddressRegex.test(email));
  };

  const findUsers = event => {
    if (usersRequestTimeout) {
      if (usersAbortController) usersAbortController.abort();
      clearTimeout(usersRequestTimeout);
    }

    const fullName = get(event, 'target.value', null);
    if (!fullName || fullName.length < 3) {
      setIsUsersLoading(false);
      return;
    }

    setIsUsersLoading(true);

    usersRequestTimeout = setTimeout(() => {
      usersAbortController = new AbortController();
      peopleApi
        .find({ fullName }, usersAbortController.signal)
        .then(({ success, matches }) => {
          if (success) setUsers(matches.map(m => ({ name: buildFullName(m), value: m.uuid, original: m })));
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsUsersLoading(false));
    }, 500);
  };

  const onRemoveItemClicked = ({ item }) => {
    const updatedList = paymentItems.filter(x => x.id !== item.id);
    setPaymentItems(updatedList);
    setWasChanged(true);
  };

  const onAddItemClicked = () => {
    setSelectedId(null);
    setIsEditMode(false);
    setAddLabel('');
    setAddAmount('');
    setIsAddModalOpen(true);
  };

  const onEditItemClicked = ({ item }) => {
    setSelectedId(item.id);
    setIsEditMode(true);
    setAddLabel(item.label);
    setAddAmount(item.amount);
    setIsAddModalOpen(true);
  };

  const onAddModalClose = wasAdded => {
    setIsAddModalOpen(false);
    if (wasAdded) {
      let updatedList;
      if (isEditMode) {
        updatedList = paymentItems.map(x => {
          if (x.id === selectedId) {
            return { ...x, label: addLabel, amount: parseFloat(addAmount).toFixed(2) };
          }
          return x;
        });
      } else {
        updatedList = [
          ...paymentItems,
          {
            id: uuid(),
            label: addLabel,
            amount: parseFloat(addAmount).toFixed(2),
          },
        ];
      }
      setPaymentItems(updatedList);
      setNotes('');
      setWasChanged(true);
    }
  };

  const onMarkPaid = () => {
    app.api.toggleLoading(true);

    let airBadgeUserUuid = null;
    if (payerChoice === 'applicant') {
      airBadgeUserUuid = badgeApplication.applicant.uuid;
    } else if (payerChoice === 'signatory') {
      airBadgeUserUuid = badgeApplication.signatory.uuid;
    } else if (payerChoice === 'airbadge') {
      airBadgeUserUuid = airBadgeUser?.value;
    }

    paymentsApi
      .recordInPersonPayment({
        amount,
        notes,
        badgeApplicationUuid: badgeApplication.uuid,
        airBadgeUserUuid,
        payerName: payer,
        payerEmail: email,
        tender: tender?.value,
      })
      .then(() => {
        onMarkPaidModalClose();
        onActionDone({ shouldReload: true });
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const isMarkPaidDisabled = () => {
    if (hasEmailError) return true;
    if (!payerChoice) return true;
    if (payerChoice === 'airbadge' && !airBadgeUser) return true;
    if (payerChoice === 'external' && (!payer || !email || !tender)) return true;
    return !amount || amount <= 0.0;
  };

  const onMarkPaidModalClose = () => {
    setIsMarkPaidModalOpen(false);
    setPayer('');
    setAirBadgeUser(null);
    setPayerChoice(null);
    setTender(null);
    setUsers([]);
    setHasEmailError(false);
    setEmail('');
    setIsUsersLoading(false);
    setAmount(0.0);
    setNotes('');
  };

  const onPayClicked = () => {
    if (isCurrentUserSignatory && paymentChoice.value === 'applicant') {
      showConfirm({
        title: 'Are you sure?',
        content: (
          <div>
            The Applicant is responsible for paying for this badge application. Are you sure you want to proceed?
          </div>
        ),
        onConfirm: () => {
          setIsPaymentFormOpen(true);
        },
        isDangerous: false,
        okButtonText: 'Pay for Badge Application',
      });
    } else if (paymentChoice.value === 'in-person') {
      setIsMarkPaidModalOpen(true);
    } else {
      setIsPaymentFormOpen(true);
    }
  };

  const onSavepaymentItemsClicked = () => {
    if (paymentItems) {
      let total = paymentItems.reduce((total, { amount }) => total + parseFloat(amount), 0);
      if (total <= 0 && paymentChoice.value !== 'no-charge') {
        return showConfirm({
          title: 'Invalid Payment Option Selected',
          content: (
            <Alert color="warning">
              <p>
                <strong>Fees total is $0.00</strong>
              </p>
              If this was intentional, you must also change the payment option to "No Charge" to proceed with the
              changes.
            </Alert>
          ),
          onClose: false,
        });
      }
    }

    app.api.toggleLoading(true);

    const changes = { paymentChoice: paymentChoice.value };
    if (!isCurrentUserSignatory) {
      changes.paymentItems = paymentItems;
    }

    badgeApplicationApi
      .updateSettings({
        badgeApplicationUuid: badgeApplication.uuid,
        changes,
        comments: notes,
      })
      .then(() => {
        onChangePayerModalClose(true);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const getPaymentOptions = useCallback(() => {
    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled } = app.airport.payments;
    let choices = [...basePaymentChoices];
    if (!shouldAllowPayInPerson) {
      choices = choices.filter(x => x.value !== 'in-person');
    }
    if (!isApplicantPaymentEnabled) {
      choices = choices.filter(x => x.value !== 'applicant');
    }
    if (!isSignatoryPaymentEnabled) {
      choices = choices.filter(x => x.value !== 'signatory');
    }
    const authorizedSignatoryCompany = badgeApplication.signatory.company;
    if (authorizedSignatoryCompany) {
      if (authorizedSignatoryCompany.shouldNeverChargePayment) {
        choices.push(noChargePaymentChoice);
      } else if (authorizedSignatoryCompany.shouldAllowPaymentInvoice) {
        choices.push(invoicePaymentChoice);
      }
    }
    return choices;
  }, [shouldAllowPayInPerson, badgeApplication]);

  const onChangePayerModalClose = shouldReload => {
    setWasChanged(false);
    setIsChangePayerModalOpen(false);
    onActionDone({ shouldReload: !!shouldReload });
  };

  const isPayActionHidden = useCallback(() => {
    if (!!badgeApplication.active === false || badgeApplication.isPaid) {
      return true;
    }
    if (
      isCurrentUserSignatory &&
      (badgeApplication.paymentChoice === 'applicant' || badgeApplication.paymentChoice === 'signatory')
    ) {
      return false;
    }
    return badgeApplication.paymentChoice !== 'in-person';
  }, [badgeApplication]);

  const isModifyActionHidden = () => {
    if (isCurrentUserSignatory || !!badgeApplication.active === false || badgeApplication.isPaid) {
      return true;
    }
    return !badgeApplication?.config?.validation?.isComplete;
  };

  useEffect(() => {
    if (badgeApplication.paymentChoice === 'no-charge') {
      setPaymentChoice(noChargePaymentChoice);
    } else if (badgeApplication.paymentChoice === 'invoice') {
      setPaymentChoice(invoicePaymentChoice);
    } else {
      setPaymentChoice(basePaymentChoices.find(x => x.value === badgeApplication.paymentChoice));
    }

    badgeOfficeApi.getSettings().then(({ settings }) => {
      setShouldAllowPayInPerson(settings.shouldAllowPayInPerson);
    });

    setPaymentItems([]);
    if (badgeApplication.auxiliaryConfig?.payments?.items?.length) {
      setPaymentItems(
        badgeApplication.auxiliaryConfig.payments.items.map(({ label, amount }) => ({
          id: uuid(),
          label,
          amount: parseFloat(amount).toFixed(2),
        }))
      );
    }
  }, [badgeApplication]);

  if (isModifyActionHidden() && isPayActionHidden()) return null;

  const getTotal = () => {
    const total = paymentItems.reduce((total, { amount }) => total + parseFloat(amount), 0);
    return parseFloat(total).toFixed(2);
  };

  const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 16,
    borderBottom: '1px dashed #ccc',
  };

  return (
    <>
      {isModifyActionHidden() ? null : (
        <ListItem button onClick={() => setIsChangePayerModalOpen(true)}>
          <ListItemIcon>
            <FontAwesomeIcon className="fa-solid fa-pen-to-square" />
          </ListItemIcon>
          <ListItemText primary="Modify Payment Details" />
        </ListItem>
      )}

      {isPayActionHidden() ? null : (
        <ListItem button onClick={onPayClicked}>
          <ListItemIcon>
            <FontAwesomeIcon className="fa-solid fa-money-check-dollar" />
          </ListItemIcon>
          <ListItemText
            primary={paymentChoice?.value === 'in-person' ? 'Mark Badge Application Paid' : 'Pay for Badge Application'}
          />
        </ListItem>
      )}

      <AirBadgeModal
        size={MODAL_SIZE_LARGE}
        title="Modify Payment Details"
        isOpen={isChangePayerModalOpen}
        onClose={() => onChangePayerModalClose(false)}
        saveLabel="Save Changes"
        onSave={onSavepaymentItemsClicked}
        saveDisabled={!wasChanged}
      >
        <Grid container direction="column">
          <Grid item xs={12} sm={7}>
            <FormGroup>
              <Label>Who should be responsible for paying for this badge application?</Label>
              <Select
                placeholder=""
                classNamePrefix="airbadge"
                className="form-select"
                options={getPaymentOptions()}
                value={paymentChoice}
                onChange={option => {
                  setPaymentChoice(option);
                  setWasChanged(true);
                }}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <div className="separator mt-2 mb-4">FEES</div>

        {paymentItems.map((item, index) => (
          <Grid
            key={index}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              marginBottom: 18,
              padding: 16,
              borderRadius: 5,
              border: '1px solid black',
            }}
          >
            <Grid item xs={2}>
              $ {item.amount}
            </Grid>
            <Grid item xs={!isCurrentUserSignatory ? 7 : 10}>
              {item.label}
            </Grid>
            {!isCurrentUserSignatory && (
              <Grid container direction="row" justifyContent="space-evenly" item xs={3}>
                <Grid item>
                  <button className="btn btn-link p-0" onClick={() => onEditItemClicked({ item })}>
                    Edit
                  </button>
                </Grid>
                {paymentItems.length > 1 && (
                  <Grid item>
                    <button className="btn btn-link p-0 text-danger" onClick={() => onRemoveItemClicked({ item })}>
                      Remove
                    </button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        ))}

        {!isCurrentUserSignatory && (
          <Button size="sm" color="primary" className="mt-2" onClick={() => onAddItemClicked()}>
            <i className="fa-solid fa-plus mr-2"></i>Add Fee
          </Button>
        )}

        <div className="mt-4">
          <FormGroup>
            <Label>
              Notes / Comments <i style={{ fontSize: 'smaller' }}>(optional)</i>
            </Label>
            <InputWithCharacterLimit limit={255} value={notes} onChange={setNotes} />
          </FormGroup>
        </div>
      </AirBadgeModal>

      <BadgeApplicationPaymentForm
        isOpen={isPaymentFormOpen}
        onClose={({ shouldReload }) => {
          setIsPaymentFormOpen(false);
          onActionDone({ shouldReload });
        }}
        badgeApplicationUuid={badgeApplication.uuid}
      />

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Mark Badge Application Paid"
        isOpen={isMarkPaidModalOpen}
        onClose={onMarkPaidModalClose}
        saveLabel="Mark Paid"
        onSave={onMarkPaid}
        saveDisabled={isMarkPaidDisabled()}
      >
        <div style={{ marginBottom: 40 }}>
          {paymentItems.map(({ label, amount }, index) => (
            <div key={index} style={rowStyle}>
              <div>{label}</div>
              <div style={{ flex: 1 }}>&nbsp;</div>
              <div>{amount}</div>
            </div>
          ))}
          <div style={{ ...rowStyle, borderBottom: 'none' }}>
            <div>
              <strong>Total Amount Due</strong>
            </div>
            <div style={{ flex: 1 }}>&nbsp;</div>
            <div>
              <strong>$ {getTotal()}</strong>
            </div>
          </div>
          <div className="separator"></div>
        </div>

        <Grid container direction="column" spacing={1}>
          <Grid item>
            <FormGroup>
              <Label>Who made the payment?</Label>
              <RadioGroup
                aria-label="payer-choice"
                name="payer-choice"
                value={payerChoice}
                onChange={event => {
                  setPayerChoice(event.target.value);
                  setAirBadgeUser(null);
                  setPayer('');
                }}
              >
                <FormControlLabel
                  value="applicant"
                  control={<Radio color="primary" />}
                  label={
                    <div>
                      Applicant{' '}
                      <i>
                        <small>({badgeApplication.applicant.fullName})</small>
                      </i>
                    </div>
                  }
                />
                <FormControlLabel
                  value="signatory"
                  control={<Radio color="primary" />}
                  label={
                    <div>
                      Signatory{' '}
                      <i>
                        <small>({badgeApplication.signatory.fullName})</small>
                      </i>
                    </div>
                  }
                />
                <FormControlLabel value="airbadge" control={<Radio color="primary" />} label="AirBadge User" />
                <FormControlLabel value="external" control={<Radio color="primary" />} label="Other" />
              </RadioGroup>
              {payerChoice === 'external' && (
                <>
                  <FormGroup style={{ marginTop: 16 }}>
                    <Label className="required">Name of Payer</Label>
                    <Input value={payer} onChange={event => setPayer(event.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <Label className="required">Email Address for Receipt</Label>
                    <Input
                      type="email"
                      value={email}
                      onChange={event => {
                        const email = event.target.value;
                        setEmail(email);
                        checkEmailForErrors(email);
                      }}
                      invalid={hasEmailError}
                    />
                    <FormFeedback>Invalid email address</FormFeedback>
                  </FormGroup>
                </>
              )}
              {payerChoice === 'airbadge' && (
                <FormGroup style={{ marginTop: 16 }}>
                  <Label className="required">AirBadge User</Label>
                  <AutoCompleteDropdown
                    id="selectedUser"
                    value={airBadgeUser}
                    onInputChange={findUsers}
                    onValueSelected={setAirBadgeUser}
                    options={users}
                    isLoading={isUsersLoading}
                    placeholder="Search by first or last name"
                    size="small"
                    filterOptions={filterUserOptions}
                    renderOption={user => <AutoCompleteUserOption user={user} />}
                    disabled={false}
                  />
                </FormGroup>
              )}
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <Label className="required">Amount Paid</Label>
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input type="number" value={amount} onChange={event => setAmount(event.target.value)} />
              </InputGroup>
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <Label className="required">Payment Method</Label>
              <Select
                placeholder=""
                classNamePrefix="airbadge"
                className="form-select"
                options={[
                  { label: 'Card', value: 'card' },
                  { label: 'Cash', value: 'cash' },
                  {
                    label: 'Check',
                    value: 'check',
                  },
                ]}
                value={tender}
                onChange={option => setTender(option)}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <Label>
                Notes <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <Input type="textarea" rows={4} value={notes} onChange={event => setNotes(event.target.value)} />
            </FormGroup>
          </Grid>
        </Grid>
      </AirBadgeModal>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title={isEditMode ? 'Edit Payment Item' : 'Add Payment Item'}
        isOpen={isAddModalOpen}
        onClose={() => onAddModalClose(false)}
        onSave={() => onAddModalClose(true)}
        saveLabel={isEditMode ? 'Save Changes' : 'Add Item'}
        saveDisabled={!addLabel || !addAmount}
      >
        <FormGroup>
          <Label>Label</Label>
          <Input value={addLabel} onChange={event => setAddLabel(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>Amount</Label>
          <InputGroup>
            <InputGroupText>$</InputGroupText>
            <Input type="number" value={addAmount} onChange={event => setAddAmount(event.target.value)} />
          </InputGroup>
        </FormGroup>
      </AirBadgeModal>
    </>
  );
}
