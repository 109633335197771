import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import ViewContainer from '../../../shared/components/ViewContainer/ViewContainer';
import AppointmentsList from './AppointmentsList/AppointmentsList';

export const emptyAppointmentType = { value: 'all', label: 'All Appointment Types' };
export const emptyStaff = { value: 'all', label: 'All Resources' };

export default function Appointments(props) {
  const [appointmentType, setAppointmentType] = useState(emptyAppointmentType);
  const [staff, setStaff] = useState(emptyStaff);

  const currentProfileName = props.state?.user?.activeProfile?.role?.name;
  const allowedRoles = ['system-admin', 'airport-security-coordinator', 'trusted-agent'];
  if (!allowedRoles.includes(currentProfileName)) {
    return <Redirect to="/default/home" />;
  }

  return (
    <ViewContainer title="Appointments" iconClass="fa fa-calendar">
      <AppointmentsList
        api={props.api}
        user={props.state.user}
        timeZone={props.state.timezone}
        appointmentType={appointmentType}
        setAppointmentType={setAppointmentType}
        staff={staff}
        setStaff={setStaff}
      />
    </ViewContainer>
  );
}
