import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function ForceRescheduleModal({ isOpen, onClose, onSave, appointment, status, templates }) {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('Notification');
  const [saveButtonLabel, setSaveButtonLabel] = useState('Save');

  const onCloseModal = () => {
    setSubject('');
    setContent('');
    setTitle('Notification');
    setSaveButtonLabel('Save');
    onClose();
  };

  const onSubmit = () => {
    onSave({ subject, content }).then(() => onCloseModal());
  };

  const setSubjectAndContent = ({ templateSubject, templateContent }) => {
    templateSubject = templateSubject.replaceAll('[attendeeName]', appointment.attendee_name);
    templateSubject = templateSubject.replaceAll('[appointmentType]', appointment.appointmentType.name);
    templateSubject = templateSubject.replaceAll('[appointmentStart]', appointment.start);
    templateSubject = templateSubject.replaceAll('[appointmentEnd]', appointment.end);
    setSubject(templateSubject);

    templateContent = templateContent.replaceAll('[attendeeName]', appointment.attendee_name);
    templateContent = templateContent.replaceAll('[appointmentType]', appointment.appointmentType.name);
    templateContent = templateContent.replaceAll('[appointmentStart]', appointment.start);
    templateContent = templateContent.replaceAll('[appointmentEnd]', appointment.end);
    setContent(templateContent);
  };

  useEffect(() => {
    if (!isOpen) return;

    let templateSubject = '';
    let templateContent = '';

    if (status === 'missed') {
      setTitle('Notify Attendee of Missed Appointment');
      templateSubject = templates?.appointmentMissed?.subject;
      templateContent = templates?.appointmentMissed?.content;
      setSaveButtonLabel('Mark Missed and Send Notification');
    } else if (status === 'reschedule') {
      setTitle('Require Attendee to Reschedule Appointment');
      templateSubject = templates?.forceReschedule?.subject;
      templateContent = templates?.forceReschedule?.content;
      setSaveButtonLabel('Force Reschedule and Send Notification');
    }
    setSubjectAndContent({ templateSubject, templateContent });
  }, [isOpen, status, templates]);

  if (!isOpen) {
    return null;
  }

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      onSave={onSubmit}
      saveColor="warning"
      saveLabel={saveButtonLabel}
    >
      <FormGroup>
        <Label>Subject</Label>
        <Input value={subject} onChange={({ target }) => setSubject(target.value)} />
      </FormGroup>

      <br />

      <FormGroup>
        <Label>Content</Label>
        <Input type="textarea" rows="10" value={content} onChange={({ target }) => setContent(target.value)} />
      </FormGroup>
    </AirBadgeModal>
  );
}
