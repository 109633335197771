import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';
import { Alert, FormGroup, Input, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import CreateBadgeApplicationBadgeModalInfoBlock from './components/CreateBadgeApplicationBadgeModalInfoBlock';
import StaggeredDatePicker from '../../../../../shared/components/DatePickers/StaggeredDatePicker';
import AutoCompleteDropdown from '../../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import badgeApi from '../../../../../ajax/Badge/badgeApi';
import { handleError } from '../../../../../utils';
import { AppContext } from '../../../../../App';
import BadgesForUserRequest from '../../../../../ajax/Badge/BadgesForUserRequest';
import { nonCollectibleStatuses, reasons } from '../BadgeCreateModal';
import request from '../../../../../ajax/Request';
import BadgeApplicationIssueBadgeRequest from '../../../../../ajax/BadgeApplication/BadgeApplicationIssueBadgeRequest';
import trim from 'lodash/trim';
import IssueBadgeApplicationBadgeConfirmationModal from './components/IssueBadgeApplicationBadgeConfirmationModal';
import { hasFeature } from '../../../../../shared/Feature';
import { uploadProfilePicForPerson } from '../../../../../imageUtils';

export const getDefaultBadgeExpiration = ({ badgeType, timezone }) => {
  if (!badgeType?.config?.badgeExpiration) return null;

  const { fixedDate, durationMonths, isExpiringEndOfMonth } = badgeType.config.badgeExpiration;

  if (fixedDate) {
    const fixedDateMoment = moment.tz(fixedDate, timezone);
    if (fixedDateMoment.isValid()) {
      return fixedDateMoment.format('YYYY-MM-DD');
    }
  } else if (durationMonths) {
    let durationMoment = moment().tz(timezone);
    durationMoment.add(durationMonths, 'months');
    if (isExpiringEndOfMonth) {
      durationMoment = durationMoment.endOf('month');
    }
    return durationMoment.format('YYYY-MM-DD');
  }

  return null;
};

export default function CreateBadgeApplicationBadgeModal({ isOpen, onClose, badgeApplication }) {
  const app = useContext(AppContext);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [expirationDate, setExpirationDate] = useState(moment().endOf('month').add(2, 'years').format('YYYY-MM-DD'));
  const [group, setGroup] = useState(null);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [newGroupManualEntry, setNewGroupManualEntry] = useState('');
  const [badgeNumber, setBadgeNumber] = useState('');
  const [cardSerialNumber, setCardSerialNumber] = useState('');
  const [isLoadingBadges, setIsLoadingBadges] = useState(false);
  const [existingBadgesForApplicant, setExistingBadgesForApplicant] = useState([]);
  const [badgeToDeactivate, setBadgeToDeactivate] = useState(null);
  const [deactivationReason, setDeactivationReason] = useState({ label: 'Reactivated Other', value: 'Reactivated' });
  const [isCollected, setIsCollected] = useState(false);
  const [isMisprintWarningShown, setIsMisprintWarningShown] = useState(false);
  const [deactivationComment, setDeactivationComment] = useState('');
  const [collectedDate, setCollectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [isIssueBadgeApplicationBadgeConfirmationModalOpen, setIsIssueBadgeApplicationBadgeConfirmationModalOpen] =
    useState(false);
  const [isExpirationDateDisabled, setIsExpirationDateDisabled] = useState(false);
  const [isBadgeIdDisabled, setIsBadgeIdDisabled] = useState(false);
  const [isCardNumberDisabled, setIsCardNumberDisabled] = useState(false);
  const [shouldUseOriginalSignatory, setShouldUseOriginalSignatory] = useState(false);
  const [shouldShowPacsSecurityGroups, setShouldShowPacsSecurityGroups] = useState(false);
  const [isLoadingPacsSecurityGroups, setIsLoadingPacsSecurityGroups] = useState(false);
  const [pacsSecurityGroups, setPacsSecurityGroups] = useState([]);
  const [selectedPacsSecurityGroups, setSelectedPacsSecurityGroups] = useState([]);

  const reset = () => {
    setStartDate(moment().format('YYYY-MM-DD'));
    setExpirationDate(moment().endOf('month').add(2, 'years').format('YYYY-MM-DD'));
    setIsGroupsLoading(false);
    setGroup(null);
    setGroups([]);
    setNewGroupManualEntry('');
    setBadgeNumber('');
    setCardSerialNumber('');
    setIsLoadingBadges(false);
    setExistingBadgesForApplicant([]);
    setBadgeToDeactivate(null);
    setDeactivationReason({ label: 'Reactivated Other', value: 'Reactivated' });
    setIsCollected(false);
    setIsMisprintWarningShown(false);
    setDeactivationComment('');
    setCollectedDate(moment().format('YYYY-MM-DD'));
    setIsIssueBadgeApplicationBadgeConfirmationModalOpen(false);
    setIsExpirationDateDisabled(false);
    setIsBadgeIdDisabled(false);
    setIsCardNumberDisabled(false);
    setShouldUseOriginalSignatory(false);
    setShouldShowPacsSecurityGroups(false);
    setIsLoadingPacsSecurityGroups(false);
    setPacsSecurityGroups([]);
    setSelectedPacsSecurityGroups([]);
  };

  const loadPacsSecurityGroups = () => {
    let features = app?.airport?.pacsConfig?.features || [];
    const isPacsEnabled = !!app?.airport?.isPacsEnabled;
    const isPacsActivationEnabled = features.includes('allow_activation_from_airbadge');
    setShouldShowPacsSecurityGroups(isPacsActivationEnabled);
    setPacsSecurityGroups([]);
    if (isPacsEnabled && isPacsActivationEnabled) {
      setIsLoadingPacsSecurityGroups(true);
      const companyUuid = badgeApplication?.signatory?.company?.uuid || 'error';

      Promise.all([
        request('authenticated-user', 'GET', 'pacs/security-groups'),
        request('authenticated-user', 'GET', `pacs/security-groups/${companyUuid}`),
      ])
        .then(([allSecurityGroupsResponse, companySecurityGroupsResponse]) => {
          const allSecurityGroups = allSecurityGroupsResponse?.groups || [];
          const companySecurityGroups = companySecurityGroupsResponse?.groups || [];
          const allGroups = allSecurityGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ pacsSecurityGroupId, name }) => ({
              label: name,
              value: pacsSecurityGroupId,
            }));
          setPacsSecurityGroups(allGroups);

          const selectedPacsSecurityGroups = [];
          companySecurityGroups.forEach(group => {
            const found = allGroups.find(({ value }) => value === group.pacsSecurityGroupId);
            if (found) {
              selectedPacsSecurityGroups.push(found);
            }
          });
          setSelectedPacsSecurityGroups(selectedPacsSecurityGroups);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingPacsSecurityGroups(false));
    }
  };

  const isCollectibleStatus = () => {
    return deactivationReason && !nonCollectibleStatuses.includes(deactivationReason.value);
  };

  const loadGroups = () => {
    setIsGroupsLoading(true);
    badgeApi
      .getGroups()
      .then(({ success, results = [] }) => {
        if (success) setGroups(results.map(r => ({ label: r, value: r })));
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsGroupsLoading(false));
  };

  const loadExistingBadgesForApplicant = () => {
    setIsLoadingBadges(true);
    BadgesForUserRequest(app.user, { userUuid: badgeApplication.applicant.uuid })
      .then((badgesFromResponse = []) => {
        const badges = badgesFromResponse
          .filter(x => x.active)
          .map(x => {
            const status = app.badgeStatuses.find(s => s.value === x.status) || { label: 'unknown' };
            const badge = { label: `${x.number} (${x.type.toLocaleUpperCase()}) (${status.label})`, value: x.uuid };
            if (!!badgeApplication.isRenewal && x.uuid === badgeApplication.renewingBadge?.uuid) {
              setBadgeToDeactivate(badge);
            }
            return badge;
          });
        setExistingBadgesForApplicant(badges);
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsLoadingBadges(false));
  };

  const onModalClose = () => {
    onClose(false);
  };

  const deactivateBadge = () => {
    if (!badgeToDeactivate) return Promise.resolve(true);

    const payload = {
      new_status: deactivationReason.value.toLocaleLowerCase(),
      comment: deactivationComment,
    };
    if (isCollectibleStatus()) {
      payload.collected = isCollected ? 1 : 0;
      payload.collectedDate = moment.tz(collectedDate, app.timezone).format('YYYY-MM-DD HH:mm:ss');
    }

    return request('authenticated-user', 'PUT', `badges/${badgeToDeactivate.value}/status`, payload);
  };

  const buildBadgeData = () => {
    const effectiveDate = moment(startDate, 'YYYY-MM-DD');
    const expiresDate = moment(expirationDate, 'YYYY-MM-DD');

    let groupName = null;
    if (newGroupManualEntry) {
      groupName = newGroupManualEntry;
    } else if (group) {
      groupName = group.value;
    }

    let collected = null;
    if (isCollected && collectedDate) {
      collected = moment(collectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
    }

    let signatoryProfileUuid = badgeApplication.signatory.profileUuid;
    if (shouldUseOriginalSignatory) {
      signatoryProfileUuid = badgeApplication.originalSignatory.profileUuid;
    }

    return {
      badge_application_uuid: badgeApplication.uuid,
      badge_holder_uuid: badgeApplication.applicant.uuid,
      authorized_signatory_profile_uuid: signatoryProfileUuid,
      type: badgeApplication.badgeType.badgeTypeID,
      status: 'in_use',
      number: trim(badgeNumber),
      card_serial_number: trim(cardSerialNumber),
      effective: effectiveDate.format('YYYY-MM-DD HH:mm:ss'),
      expires: expiresDate.format('YYYY-MM-DD HH:mm:ss'),
      group: trim(groupName),
      driving: badgeApplication.badge.driving,
      parking: 0,
      escort: badgeApplication.badge.isEscort ? 1 : 0,
      leo: badgeApplication.badge.isLEO ? 1 : 0,
      collected,
      pacsSecurityGroups: selectedPacsSecurityGroups.map(({ value }) => value),
    };
  };

  const issueBadge = () => {
    app.api.toggleLoading(true);
    const badge = buildBadgeData();

    deactivateBadge()
      .then(() => {
        return BadgeApplicationIssueBadgeRequest('authenticated-user', badgeApplication.uuid, badge)
          .then(() => {
            if (badgeApplication?.badge?.template?.sampledata[0]?.faceImage) {
              return uploadProfilePicForPerson({
                userUuid: badgeApplication.applicant.uuid,
                imageData: badgeApplication.badge.template.sampledata[0].faceImage,
              }).finally(() => onModalClose(true));
            } else {
              return onClose(true);
            }
          })
          .catch(error => handleError({ error, message: 'Badge could not be issued' }));
      })
      .catch(error => handleError({ error, message: 'Unable to deactivate badge' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onIssueBadgeApplicationBadgeConfirmationModalClose = didConfirm => {
    setIsIssueBadgeApplicationBadgeConfirmationModalOpen(false);
    if (didConfirm) {
      issueBadge();
    }
  };

  const onIssueBadgeClicked = () => {
    const from = moment(startDate, 'YYYY-MM-DD');
    const to = moment(expirationDate, 'YYYY-MM-DD');
    if (!from.isBefore(to)) {
      handleError({ message: 'The Start (activation) date must be before the Expiration (deactivation) date' });
      return;
    }

    setIsIssueBadgeApplicationBadgeConfirmationModalOpen(true);
  };

  const isSaveDisabled = () => {
    if (badgeToDeactivate && !deactivationReason) {
      return true;
    }
    if (isCollected && !collectedDate) {
      return true;
    }
    return !startDate || !expirationDate || !badgeNumber || !cardSerialNumber;
  };

  useEffect(() => {
    reset();

    if (isOpen && badgeApplication) {
      if (badgeApplication.badgeType?.config) {
        const defaultExpirationDate = getDefaultBadgeExpiration({
          badgeType: badgeApplication.badgeType,
          timezone: app.timezone,
        });
        setExpirationDate(defaultExpirationDate);
      }

      loadGroups();
      loadExistingBadgesForApplicant();
      loadPacsSecurityGroups();

      if (hasFeature('printing', app?.airport?.config?.features || [])) {
        const { badgeNumber, cardNumber, expireDate, template } = badgeApplication.badge;
        if (template) {
          // The existence of a template means that the badge was printed and the following data was entered
          if (badgeNumber && badgeNumber !== '0') {
            setBadgeNumber(badgeNumber);
            setIsBadgeIdDisabled(true);
          }
          if (cardNumber) {
            setCardSerialNumber(cardNumber);
            setIsCardNumberDisabled(true);
          }
          if (expireDate) {
            setExpirationDate(moment.tz(expireDate, app.timezone).format('YYYY-MM-DD'));
            setIsExpirationDateDisabled(true);
          }
        }
      }
    }
  }, [isOpen, badgeApplication]);

  if (!isOpen || !badgeApplication) {
    return null;
  }

  return (
    <>
      <AirBadgeModal
        title={`Issue ${badgeApplication.badgeType.displayName} Badge`}
        size={MODAL_SIZE_LARGE}
        isOpen={isOpen}
        onClose={onModalClose}
        saveLabel={`Issue ${badgeApplication.badgeType.displayName} Badge`}
        onSave={onIssueBadgeClicked}
        saveDisabled={isSaveDisabled()}
      >
        {!!badgeApplication?.originalSignatory && !!badgeApplication?.originalSignatory?.isActive && (
          <Alert color="warning">
            <i>{badgeApplication?.signatory?.fullName}</i> replaced{' '}
            <i>{badgeApplication?.originalSignatory?.fullName}</i> as the signatory on this badge application.
            <br />
            <br />
            Would you like to make <i>{badgeApplication?.originalSignatory?.fullName}</i> the signatory on the issued
            badge?
            <RadioGroup
              row
              value={`${shouldUseOriginalSignatory}`}
              onChange={event => {
                event.stopPropagation();
                setShouldUseOriginalSignatory(event?.target?.value === 'true');
              }}
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </Alert>
        )}

        <CreateBadgeApplicationBadgeModalInfoBlock badgeApplication={badgeApplication} />

        <div className="mt-3">&nbsp;</div>

        <Grid container spacing={3}>
          <Grid item xs={9}>
            <FormGroup>
              <Label className="required">Start (Activated) Date</Label>
              {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
              <StaggeredDatePicker
                startingYear={new Date().getFullYear() - 9}
                endingYear={new Date().getFullYear()}
                defaultValue={startDate}
                onValidDate={({ year, month, day }) => setStartDate(`${year}-${month}-${day}`)}
                onInvalidDate={() => setStartDate(null)}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={9}>
            <FormGroup>
              <Label className="required">Expiration (Deactivation) Date</Label>
              {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
              <StaggeredDatePicker
                startingYear={new Date().getFullYear()}
                endingYear={new Date().getFullYear() + 5}
                defaultValue={expirationDate}
                onValidDate={({ year, month, day }) => setExpirationDate(`${year}-${month}-${day}`)}
                onInvalidDate={() => setExpirationDate(null)}
                isDisabled={isExpirationDateDisabled}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <div className="mt-2">&nbsp;</div>

        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <FormGroup>
              <Label className="required">Badge ID</Label>
              <Input
                id="badgeId"
                value={badgeNumber}
                onChange={event => setBadgeNumber(event.target.value)}
                className={isBadgeIdDisabled ? 'form-control custom-disabled' : 'form-control'}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormGroup>
              <Label className="required">Card Number</Label>
              <Input
                id="cardNumber"
                value={cardSerialNumber}
                onChange={event => setCardSerialNumber(event.target.value)}
                className={isCardNumberDisabled ? 'form-control custom-disabled' : 'form-control'}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {shouldShowPacsSecurityGroups && (
            <Grid item sm={12} md={6}>
              <Label>
                PACS Security Groups <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <Select
                isMulti
                isLoading={isLoadingPacsSecurityGroups}
                placeholder=""
                classNamePrefix="airbadge"
                className="form-select"
                options={pacsSecurityGroups}
                value={selectedPacsSecurityGroups}
                onChange={setSelectedPacsSecurityGroups}
              />
            </Grid>
          )}
          <Grid item sm={12} md={6}>
            <FormGroup>
              <Label>
                Group <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <AutoCompleteDropdown
                id="newGroup"
                value={group}
                onValueSelected={setGroup}
                options={groups}
                placeholder=""
                size="small"
                isLoading={isGroupsLoading}
                getOptionLabel={option => option.label || 'error'}
                onInputChange={({ target: { value } }) => setNewGroupManualEntry(value)}
                freeSolo
                disableClearable
              />
            </FormGroup>
          </Grid>
        </Grid>

        <div className="separator mt-4">Deactivation</div>

        <div style={{ margin: '16px 0 24px 0' }}>
          Does this individual have an active badge that needs to be deactivated?
        </div>

        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <FormGroup>
              <Label>Badge to be deactivated</Label>
              <Select
                isClearable
                classNamePrefix="airbadge"
                options={existingBadgesForApplicant}
                className="form-select"
                value={badgeToDeactivate}
                placeholder=""
                isLoading={isLoadingBadges}
                noOptionsMessage={() => 'No active badges to deactivate'}
                onChange={option => {
                  setBadgeToDeactivate(option);
                  if (!option) {
                    setDeactivationReason({ label: 'Reactivated Other', value: 'Reactivated' });
                    setIsMisprintWarningShown(false);
                    setIsCollected(false);
                  }
                }}
              />
            </FormGroup>
          </Grid>
          {badgeToDeactivate && (
            <Grid item sm={12} md={6}>
              <FormGroup>
                <Label className="required">Deactivation Reason</Label>
                <Select
                  classNamePrefix="airbadge"
                  options={reasons}
                  className="form-select"
                  value={deactivationReason}
                  placeholder=""
                  isDisabled={!badgeToDeactivate}
                  onChange={reason => {
                    setDeactivationReason(reason);
                    setIsMisprintWarningShown(false);
                    setIsCollected(!nonCollectibleStatuses.includes(reason.value));
                  }}
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>

        {isCollectibleStatus() && badgeToDeactivate && (
          <Grid container spacing={1}>
            <Grid item sm={12} md={9}>
              <MuiFormGroup row>
                <MuiFormControlLabel
                  control={<Switch color="primary" />}
                  label="Badge Was Collected"
                  checked={isCollected}
                  disabled={!badgeToDeactivate}
                  onChange={event => {
                    const isCollected = event.target.checked;
                    setIsCollected(isCollected);
                    setIsMisprintWarningShown(!isCollected && deactivationReason.label === 'Misprint');
                  }}
                />
              </MuiFormGroup>
            </Grid>
            {isCollected && badgeToDeactivate && (
              <Grid item sm={12} md={9}>
                <FormGroup>
                  <Label className="required">Collected Date</Label>
                  {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
                  <StaggeredDatePicker
                    startingYear={new Date().getFullYear() - 1}
                    endingYear={new Date().getFullYear()}
                    defaultValue={collectedDate}
                    onValidDate={({ year, month, day }) => setCollectedDate(`${year}-${month}-${day}`)}
                    onInvalidDate={() => setCollectedDate(null)}
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        )}

        {isMisprintWarningShown && (
          <Grid container>
            <Grid item>
              <Alert color="warning">
                If this badge was issued, select a different status to represent why it is being deactivated.
              </Alert>
            </Grid>
          </Grid>
        )}

        <div>&nbsp;</div>

        {badgeToDeactivate && (
          <Grid container>
            <Grid item xs={12}>
              <FormGroup>
                <Label>
                  Deactivation Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
                </Label>
                <Input
                  disabled={!badgeToDeactivate}
                  type="textarea"
                  value={deactivationComment}
                  onChange={event => setDeactivationComment(event.target.value)}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </AirBadgeModal>

      <IssueBadgeApplicationBadgeConfirmationModal
        isOpen={isIssueBadgeApplicationBadgeConfirmationModalOpen}
        onClose={onIssueBadgeApplicationBadgeConfirmationModalClose}
        isDeactivatingBadge={!!badgeToDeactivate}
      />
    </>
  );
}
