import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { AppContext } from '../../../App';

export default function FormattedDateTime({ value, format = 'MM/DD/YYYY' }) {
  const app = useContext(AppContext);

  if (!value) return '';

  return <>{moment.tz(value, app.timezone).format(format)}</>;
}

export function useFormattedDateTime({ value, format = 'MM/DD/YYYY' }) {
  const app = useContext(AppContext);
  const [formatted, setFormatted] = useState('');

  useEffect(() => {
    if (value && format && app.timezone) setFormatted(moment.tz(value, app.timezone).format(format));
  }, [value, format, app.timezone]);

  return [formatted];
}

export function formatDateTimeWithTimezone({ value, format = 'MM/DD/YYYY', timezone }) {
  if (!value || !timezone) return '';
  return moment.tz(value, timezone).format(format);
}
