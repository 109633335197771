import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col, FormGroup } from 'reactstrap';
import InputMask from 'react-input-mask';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';

export default function PersonEditEnrollMobileModal({ isOpen, onClose, onSave, fullName }) {
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    setNewValue('');
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Modal size="md" isOpen={isOpen} toggle={onClose}>
      <CustomModalHeader toggle={onClose}>Mobile Phone Notifications Enrollment</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>
              Enter a valid mobile phone number to enroll <strong>{fullName}</strong> in text notifications
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <InputMask
                mask="+1 (999) 999-9999"
                className="form-control"
                value={newValue || ''}
                onChange={({ target }) => setNewValue(target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Close"
          onClose={onClose}
          onSave={() => onSave({ newValue })}
          saveDisabled={!newValue}
        />
      </ModalFooter>
    </Modal>
  );
}
