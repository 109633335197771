import React, { useContext, useState } from 'react';
import { Alert, Input, Label } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import peopleApi from '../../../../../ajax/People/peopleApi';
import { handleError } from '../../../../../utils';
import { AppContext } from '../../../../../App';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

function BanUserIcon() {
  return (
    <div>
      <style scoped>{`
        .icon-container {
          position: relative;
        }

        .icon-container i.bottom-icon {
          z-index: 1;     
          font-size: 18px;   
          position: relative;
          left: 3px;
        }

        .icon-container i.top-icon {
          z-index: 2;
          font-size: 28px;
          opacity: 0.7;
          position: absolute;                    
          top: -4px;
          left: -3px;                    
        }
      `}</style>
      <div className="icon-container">
        <i className="fa-solid fa-user bottom-icon"></i>
        <i className="fa-solid fa-ban top-icon text-danger"></i>
      </div>
    </div>
  );
}

export default function BanAccountAction({ userUuid, currentStatus, userFullName, onDone }) {
  const app = useContext(AppContext);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selection, setSelection] = useState(null);
  const [reason, setReason] = useState('');

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setSelection(null);
    setReason('');
  };

  const showProblems = problems => {
    app.api.confirmMessage(
      'Unable to Ban Individual',
      <Alert color="warning">
        <div style={{ marginBottom: 16 }}>The following issues must be resolved before this account can be banned:</div>
        <ul>
          {problems.map((p, i) => (
            <li key={i}>{p}</li>
          ))}
        </ul>
      </Alert>,
      null,
      false
    );
  };

  const banAccount = () => {
    app.api.toggleLoading(true);
    peopleApi
      .banAccount({ userUuid, reason })
      .then(({ success, problems }) => {
        onCloseConfirmModal();

        if (success) {
          return onDone({ shouldReload: true });
        }

        app.api.toggleLoading(false);

        if (problems?.length) {
          showProblems(problems);
        } else {
          handleError({ message: 'Unable to ban account. Contact support.' });
        }
      })
      .catch(error => {
        app.api.toggleLoading(false);
        handleError({ error });
      });
  };

  if (currentStatus === 'banned') return null;

  return (
    <div>
      <ListItem button onClick={() => setIsConfirmModalOpen(true)}>
        <ListItemIcon>
          <BanUserIcon />
        </ListItemIcon>
        <ListItemText primary="Permanently Ban Individual" />
      </ListItem>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Confirmation of Permanent Ban"
        isOpen={isConfirmModalOpen}
        onClose={onCloseConfirmModal}
        closeLabel={selection === 'true' ? 'Cancel' : 'Close'}
        onSave={selection === 'true' && banAccount}
        saveLabel="Permanently Ban Individual"
        saveColor="danger"
        saveDisabled={!reason}
      >
        <div className="mb-2">
          Are you sure you want to permanently ban <strong>{userFullName}</strong> from having a badge at this airport?
        </div>
        <RadioGroup
          row
          aria-label="selection"
          name="selection"
          value={selection}
          onChange={event => setSelection(event?.target?.value)}
        >
          <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
        </RadioGroup>

        {selection === 'true' && (
          <>
            <Label className="required">Reason</Label>
            <Input type="textarea" value={reason} onChange={event => setReason(event.target.value)} />

            <Alert color="danger" fade={false} style={{ marginTop: 24 }}>
              For security reasons, there is no way to remove this ban
            </Alert>
          </>
        )}
      </AirBadgeModal>
    </div>
  );
}
