import React from 'react';
import { Alert } from 'reactstrap';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';

export function compareValuesAndFlagIfDifferent(value1, value2) {
  value1 = (value1 ?? '-').trim().toLocaleUpperCase();
  value2 = (value2 ?? '-').trim().toLocaleUpperCase();

  return value1 === value2 ? (
    <>{value1}</>
  ) : (
    <>
      <i className="fa-solid fa-exclamation-circle text-danger mr-2"></i>
      {value1}
    </>
  );
}

export default function ResolveTSCDataMismatchAction({ actionItem }) {
  const {
    metadata,
    relatedToUserFirstName,
    relatedToUserMiddleName,
    relatedToUserLastName,
    relatedToUserSuffix,
    relatedToUserDateOfBirth,
  } = actionItem;
  const { action, tscData } = metadata;

  if (action !== 'resolve_tsc_data_mismatch') {
    return null;
  }

  const { firstName, middleName, lastName, suffix, birthDate } = tscData;

  return (
    <div>
      <div className="separator" style={{ marginTop: 16, marginBottom: 8 }}>
        TSC
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around' }}>
        <ReadOnlyFormData
          label="First Name"
          value={compareValuesAndFlagIfDifferent(firstName, relatedToUserFirstName)}
          skipTextTransform
        />
        <ReadOnlyFormData
          label="Middle Name"
          value={compareValuesAndFlagIfDifferent(middleName, relatedToUserMiddleName)}
          skipTextTransform
        />
        <ReadOnlyFormData
          label="Last Name"
          value={compareValuesAndFlagIfDifferent(lastName, relatedToUserLastName)}
          skipTextTransform
        />
        <ReadOnlyFormData
          label="Suffix"
          value={compareValuesAndFlagIfDifferent(suffix, relatedToUserSuffix)}
          skipTextTransform
        />
        <ReadOnlyFormData
          label="Date of Birth"
          value={compareValuesAndFlagIfDifferent(birthDate, relatedToUserDateOfBirth)}
          skipTextTransform
        />
      </div>

      <div className="separator" style={{ marginTop: 24, marginBottom: 8 }}>
        AIRBADGE
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <ReadOnlyFormData label="First Name" value={relatedToUserFirstName || '-'} skipTextTransform />
        <ReadOnlyFormData label="Middle Name" value={relatedToUserMiddleName || '-'} skipTextTransform />
        <ReadOnlyFormData label="Last Name" value={relatedToUserLastName || '-'} skipTextTransform />
        <ReadOnlyFormData label="Suffix" value={relatedToUserSuffix || '-'} skipTextTransform />
        <ReadOnlyFormData label="Date of Birth" value={relatedToUserDateOfBirth || '-'} skipTextTransform />
      </div>
      <Alert color="warning" className="mt-4">
        <p>
          Fields marked with <i className="fa-solid fa-exclamation-circle text-danger ml-1 mr-1"></i> have conflicting
          values.
        </p>
        Click the person's name below to go to the details screen. Then click the "View TSC Name" action for information
        on how to proceed.
      </Alert>
    </div>
  );
}
