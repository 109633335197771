import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import { AppContext, colors } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import filesApi from '../../../../../../ajax/File/filesApi';
import { handleError } from '../../../../../../utils';
import FileUploadForm from '../../../../../../shared/components/FileUploadModal/FileUploadForm';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';

export function UploadChrcDocumentationModal({ isOpen, badgeApplication, onDone }) {
  const app = useContext(AppContext);
  const [comment, setComment] = useState('');
  const [caseNumber, setCaseNumber] = useState('');
  const [files, setFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);

  const onCloseWithoutSaving = () => {
    setCaseNumber('');
    setComment('');
    setFiles([]);
    onDone({ wasChrcDocumentationUploaded: false });
  };

  const onSaveChanges = async () => {
    const { api, user } = app;
    api.toggleLoading(true);

    if (files && files.length) {
      const fileType = fileTypes.find(ft => ft.systemName === 'chrc-documentation');
      const file = files[0];
      const data = {
        user: badgeApplication.applicant.uuid,
        type: fileType.uuid,
        badgeApplication: badgeApplication.uuid,
        signatory: badgeApplication.signatory.uuid,
        company: badgeApplication.signatory.company.uuid,
        comment: 'Uploaded as part of completing the CHRC Documentation for the badge application.',
      };
      const response = await filesApi.upload({ user, file, data });
      if (!response.success) {
        api.toggleLoading(false);
        return handleError({ message: 'Unable to upload file' });
      }
    }

    const response = await badgeApplicationApi.provideChrcDocumentation({
      badgeApplicationUuid: badgeApplication.uuid,
      caseNumber,
      comment,
    });

    api.toggleLoading(false);

    if (!response.success) {
      return handleError({ message: 'Unable to mark badge application step as completed' });
    }

    setCaseNumber('');
    setComment('');
    setFiles([]);
    return onDone({ wasChrcDocumentationUploaded: true });
  };

  const isSaveDisabled = () => {
    return !files.length || !caseNumber;
  };

  const addFiles = files => {
    setFiles(files);
  };

  useEffect(() => {
    if (!isOpen || !badgeApplication) return;

    filesApi
      .fileTypes()
      .then(({ success, data }) => {
        if (success) setFileTypes(data);
      })
      .catch(error => handleError({ error }));
  }, [isOpen, badgeApplication]);

  if (!isOpen || !badgeApplication) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Provide CHRC Documentation"
      isOpen={isOpen}
      onClose={onCloseWithoutSaving}
      closeLabel="Cancel"
      onSave={onSaveChanges}
      saveLabel="Complete CHRC Documentation"
      saveDisabled={isSaveDisabled()}
    >
      <div>
        A copy of the <i>CHRC documentation</i> must be uploaded and attached to this badge application before you can
        proceed
      </div>
      <div className="mt-4 mb-4">
        <FileUploadForm onFilesAdded={addFiles} />
      </div>

      <FormGroup>
        <Label>
          <div className="required">Case Number</div>
          <div style={{ fontSize: 'smaller' }}>
            <i>Required by TSC in order to initiate the background check</i>
          </div>
        </Label>
        <Input
          style={{ width: 330 }}
          required
          value={caseNumber}
          onChange={event => setCaseNumber(event?.target?.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label>
          <div>Comments</div>
          <div style={{ fontSize: 'smaller' }}>
            <i>Provide any additional details that should be recorded</i>
          </div>
        </Label>
        <Input required type="textarea" value={comment} onChange={event => setComment(event?.target?.value)} />
      </FormGroup>
    </AirBadgeModal>
  );
}

export default function UploadChrcDocumentationAction() {
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const { applicationDocument, uploadChrcDocumentation, adjudicateCHRC } = badgeApplication.config;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isHidden = useCallback(() => {
    if (!isCurrentUserSignatory) {
      return true;
    }
    if (applicationDocument.isEnabled && !applicationDocument.isComplete) {
      // Application document needs to be done first
      return true;
    }
    if (!uploadChrcDocumentation?.isEnabled || uploadChrcDocumentation?.isComplete) {
      return true;
    }
    if (adjudicateCHRC.isEnabled && adjudicateCHRC.isComplete) {
      return true;
    }
    return false;
  }, [applicationDocument]);

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <DescriptionIcon style={{ color: colors.primary }} />
        </ListItemIcon>
        <ListItemText primary="Provide CHRC Documentation" />
      </ListItem>

      <UploadChrcDocumentationModal
        isOpen={isModalOpen}
        badgeApplication={badgeApplication}
        onDone={({ wasChrcDocumentationUploaded }) => {
          setIsModalOpen(false);
          onActionDone({ shouldReload: wasChrcDocumentationUploaded });
        }}
      />
    </>
  );
}
