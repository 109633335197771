import React, { useContext, useEffect, useState } from 'react';
import peopleApi from '../../../../../../../../ajax/People/peopleApi';
import MergeDuplicateUsersModal from '../../../../../../People/PeopleList/MergeDuplicateUsersModal';
import { AppContext } from '../../../../../../../../App';
import { handleError, notify } from '../../../../../../../../utils';
import badgeApplicationCommentApi from '../../../../../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';

export default function ValidatePersonDupeChecker({ isOpen, onClose, badgeApplication }) {
  const app = useContext(AppContext);
  const [duplicates, setDuplicates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = ({ wasMerged, notDuplicate }) => {
    setDuplicates([]);
    setIsModalOpen(false);
    onClose({ wasMerged, notDuplicate });
  };

  const addBadgeApplicationComment = async ({ countOfPossibleDuplicateUsers }) => {
    try {
      const { uuid: badgeApplicationUuid } = badgeApplication;
      await badgeApplicationCommentApi.create({
        user: 'authenticated-user',
        badgeApplicationUuid,
        comment: `Found ${countOfPossibleDuplicateUsers} possible duplicate AirBadge users during validation`,
      });
    } catch (error) {
      handleError({ error });
    }
  };

  const findDuplicates = () => {
    app.api.toggleLoading(true);
    setDuplicates([]);

    const {
      applicant: { uuid: excludedUserUuid, badgerData },
    } = badgeApplication;
    const { firstName, middleName, lastName, dob: dateOfBirth } = badgerData;
    const defaultErrorMessage =
      'A problem occurred while trying to determine if this applicant is duplicated before transmitting to TSC. Contact support.';

    // Check for possible duplicates!
    peopleApi
      .findDuplicates({
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        excludedUserUuid,
      })
      .then(({ success, results }) => {
        if (success && results) {
          if (results.length) {
            // STOP, there are duplicates!
            addBadgeApplicationComment({ countOfPossibleDuplicateUsers: results.length });
            setDuplicates(results);
            setIsModalOpen(true);
          } else {
            notify({ message: 'No duplicates found', type: 'success' });
            onModalClose({ wasMerged: false, notDuplicate: true });
          }
        } else {
          handleError({ message: defaultErrorMessage });
        }
      })
      .catch(error => handleError({ error, message: defaultErrorMessage }))
      .finally(() => app.api.toggleLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      findDuplicates();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div>
      <MergeDuplicateUsersModal
        isForBackgroundCheck={true}
        isOpen={isModalOpen}
        onClose={onModalClose}
        duplicates={duplicates}
        onMergeActionLabel="validation"
        sourcePerson={badgeApplication?.applicant}
      />
    </div>
  );
}
