import request from '../Request';

export default {
  list,
  getBadgeStatsForCurrentUser,
  getDetails,
  preventRenewal,
  allowRenewal,
  updateBadge,
  getGroups,
  issuePacsBadge,
};

function list({ pageSize, page, sorts, filters, signal }) {
  return request(
    'authenticated-user',
    'POST',
    'badges/custom/data-table',
    {
      pageSize,
      page,
      sorted: sorts,
      filtered: filters,
    },
    signal
  );
}

function getDetails({ badgeUuid, signal }) {
  return request('authenticated-user', 'GET', `badge/${badgeUuid}`, null, signal);
}

function getBadgeStatsForCurrentUser({ signal }) {
  return request('authenticated-user', 'GET', 'badges/current-user-stats', null, signal);
}

function preventRenewal({ badgeUuid, reason }) {
  return request('authenticated-user', 'POST', `badges/${badgeUuid}/modify-renewal`, {
    isRenewalAllowed: false,
    reason,
  });
}

function allowRenewal({ badgeUuid, reason }) {
  return request('authenticated-user', 'POST', `badges/${badgeUuid}/modify-renewal`, {
    isRenewalAllowed: true,
    reason,
  });
}

function updateBadge(badgeUuid, badgeData) {
  return request('authenticated-user', 'PATCH', `badge/${badgeUuid}`, badgeData);
}

function getGroups() {
  return request('authenticated-user', 'GET', `badges/groups`);
}

function issuePacsBadge(data) {
  return request('authenticated-user', 'POST', `pacs-badge`, data);
}
