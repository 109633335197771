import request from '../Request';

export default function BadgeApplicationDatableRequest(
  user,
  pageSize,
  page,
  sorted,
  filtered,
  quickFilter = 'none',
  signal
) {
  // For "status" filter -> adding underscore because that is how it is saved in the database
  const filters = { ...filtered };
  Object.keys(filters).forEach(x => {
    if (filters[x] && filters[x].id === 'status') {
      const withUnderscore = filters[x].value ? filters[x].value.split(' ').join('_') : '';
      filters[x] = { id: filters[x].id, value: withUnderscore };
    }
  });

  return request(
    user || 'authenticated-user',
    'POST',
    'badge-applications/custom/data-table',
    {
      pageSize,
      page,
      sorted,
      filtered: filters,
      quickFilter,
    },
    signal
  );
}
