import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import AirBadgeModal, { MODAL_SIZE_EXTRA_LARGE } from '../components/AirBadgeModal/AirBadgeModal';
import request from '../../ajax/Request';
import { handleError } from '../../utils';
import ReactTableTotalCount from '../../views/DefaultWorkflow/Common/ReactTableTotalCount';
import { AppContext } from '../../App';

let requestTimeout = null;
let apiAbortController = null;

export default function FullUserActivity({ user, isOpen, onClose }) {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [data, setData] = useState([]);
  const [params, setParams] = useState([]);

  const onModalClose = () => {
    if (apiAbortController) apiAbortController.abort();
    if (requestTimeout) clearTimeout(requestTimeout);
    setPages(null);
    setTotal(null);
    setData([]);
    setParams([]);
    setIsLoading(false);
    onClose();
  };

  const loadData = tableState => {
    if (apiAbortController) apiAbortController.abort();
    if (requestTimeout) clearTimeout(requestTimeout);

    const { filtered, pageSize, page, sorted } = tableState;
    setParams([null, pageSize, page, sorted, filtered]);

    requestTimeout = setTimeout(() => {
      apiAbortController = new AbortController();
      setIsLoading(true);
      request(
        'authenticated-user',
        'POST',
        `admin/full-activity-log/${user.uuid}`,
        {
          page,
          pageSize,
          sorted,
          filtered,
        },
        apiAbortController.signal
      )
        .then(({ results, pages, total }) => {
          setData(results);
          setPages(pages);
          setTotal(total);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (apiAbortController) apiAbortController.abort();
      if (requestTimeout) clearTimeout(requestTimeout);
    };
  }, []);

  if (!isOpen || !user) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_EXTRA_LARGE}
      title={
        <div>
          <div>All Recorded Comments & Activity</div>
          <div style={{ fontSize: 'smaller' }}>
            <span className="mr-2">for</span>
            <i>
              {user.fullName} ({user.airportPersonId})
            </i>
          </div>
        </div>
      }
      isOpen={isOpen}
      onClose={onModalClose}
      closeLabel="Close"
    >
      <Alert color="info">
        <ul className="pl-2">
          <li>
            All date/time values are displayed in the airport's timezone: <i>{app.timezone}</i>
          </li>
          <li>
            All comments/activity with "AirBadge" as the author were created by the AirBadge software during some
            process
          </li>
          <li>Types are: Person, Badge, Login, Audit, Application, Profile, Action Item, File</li>
        </ul>
      </Alert>
      <div style={{ maxHeight: '60vh', overflow: 'scroll' }}>
        <ReactTable
          className="border-0 -striped"
          manual
          defaultSorted={[{ id: 'created_at', desc: true }]}
          loading={isLoading}
          data={data}
          columns={[
            {
              id: 'created_at',
              Header: 'Date/Time',
              accessor: ({ created_at }) =>
                created_at ? moment.tz(created_at, app.timezone).format('YYYY-MM-DD h:mm:ss A') : '?',
              width: 215,
              filterable: true,
            },
            {
              id: 'comment_type',
              Header: 'Type',
              accessor: 'comment_type',
              width: 120,
              filterable: true,
            },
            {
              id: 'comment_text',
              Header: 'Comment / Activity',
              accessor: 'comment_text',
              minWidth: 250,
              filterable: true,
            },
            {
              id: 'author',
              Header: 'Author',
              accessor: 'author',
              filterable: true,
            },
          ]}
          pages={pages}
          minRows={0}
          defaultPageSize={50}
          onFetchData={loadData}
          showPagination={pages > 1}
        />
      </div>
      <ReactTableTotalCount params={params} total={total} />
    </AirBadgeModal>
  );
}
