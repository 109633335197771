import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import AuthorizedSignatoryReassignBadgesRequest from '../../../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryReassignBadgesRequest';
import AuthorizedSignatoryGetRequest from '../../../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryGetRequest';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import { AppContext } from '../../../../../../App';
import { handleError } from '../../../../../../utils';

export default function ReassignSignatoryModal({ isOpen, onClose, onSave, badge }) {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existingSignatory, setExistingSignatory] = useState({});
  const [newSignatory, setNewSignatory] = useState(null);
  const [signatoryOptions, setSignatoryOptions] = useState([]);
  const [comment, setComment] = useState('');
  const [shouldOverrideFilter, setShouldOverrideFilter] = useState(false);

  const cleanup = () => {
    setIsModalOpen(false);
    setNewSignatory(null);
    setSignatoryOptions([]);
  };

  const onModalClose = () => {
    cleanup();
    onClose();
  };

  const onSaveChanges = () => {
    app.api.toggleLoading(true);
    AuthorizedSignatoryReassignBadgesRequest(
      app.user,
      badge.authorizedSignatoryProfileUuid,
      newSignatory.value,
      badge.uuid,
      comment
    )
      .then(response => {
        if (response.success) {
          onSave({ newSignatory: newSignatory.original, ...response });
          cleanup();
        }
      })
      .catch(error => handleError({ error, message: 'Unable to reassign Authorized Signatory' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const loadData = shouldOverrideFilter => {
    setIsLoading(true);
    setNewSignatory(null);
    AuthorizedSignatoryGetRequest(app.user)
      .then(list => {
        setSignatoryOptions(
          list
            .filter(x => x.active)
            .filter(x => {
              if (shouldOverrideFilter) {
                return x.uuid !== badge.authorizedSignatoryUuid;
              }
              return x.uuid !== badge.authorizedSignatoryUuid && x.companyUUID === badge.authorizedSignatoryCompanyUuid;
            })
            .map(x => {
              const { user, profile_id, companyName } = x;
              const { first_name, last_name } = user;
              return {
                label: `${first_name} ${last_name}, ${companyName}`,
                value: profile_id,
                original: x,
              };
            })
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isOpen || !badge) return;

    loadData();

    setNewSignatory(null);
    setExistingSignatory(badge.authorizedSignatoryName);
    setIsModalOpen(true);
  }, [isOpen, badge]);

  if (!isOpen) return null;

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Reassign Authorized Signatory</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label>From</Label>
              <Input disabled readOnly value={`${existingSignatory}`} style={{ fontStyle: 'italic' }} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="required">To</Label>
              <Select
                classNamePrefix="airbadge"
                isLoading={isLoading}
                options={signatoryOptions}
                className="form-select"
                value={newSignatory}
                placeholder=""
                onChange={x => setNewSignatory(x)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Checkbox
              color="primary"
              checked={shouldOverrideFilter}
              onChange={event => {
                setShouldOverrideFilter(event.target.checked);
                loadData(event.target.checked);
              }}
            />
            Allow reassign to companies other than <i>{badge.authorizedSignatoryCompany}</i>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <Input type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel="Reassign Signatory"
          onSave={onSaveChanges}
          saveDisabled={!newSignatory}
        />
      </ModalFooter>
    </Modal>
  );
}
