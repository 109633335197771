import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import peopleApi from '../../../../../ajax/People/peopleApi';
import { AppContext } from '../../../../../App';
import AirBadgeModal from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { buildFullName } from '../PeopleListEditModal';
import BadgerFormLabel from '../../../../Badger/components/BadgerFormLabel';
import NativeSelect from '../../../../../shared/components/NativeSelect/NativeSelect';
import { handleError } from '../../../../../utils';
import trim from 'lodash/trim';

const suffixes = [
  { label: 'JR', value: 'JR', lower: 'jr' },
  { label: 'SR', value: 'SR', lower: 'sr' },
  { label: 'I', value: 'I', lower: 'i' },
  { label: 'II', value: 'II', lower: 'ii' },
  { label: 'III', value: 'III', lower: 'iii' },
  { label: 'IV', value: 'IV', lower: 'iv' },
  { label: 'V', value: 'V', lower: 'v' },
  { label: 'VI', value: 'VI', lower: 'vi' },
  { label: 'VII', value: 'VII', lower: 'vii' },
  { label: 'VIII', value: 'VIII', lower: 'viii' },
  { label: 'IX', value: 'IX', lower: 'ix' },
];

export default function UpdateFullNameModal({ isOpen, person, fullName, onClose, setPerson, setFullName }) {
  const { api } = useContext(AppContext);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [suffix, setSuffix] = useState(null);

  const onSaveHandler = () => {
    if (firstName && lastName) {
      api.toggleLoading(true);
      peopleApi
        .patch({
          uuid: person.uuid,
          updates: {
            first_name: trim(firstName),
            middle_name: trim(middleName),
            last_name: trim(lastName),
            suffix: suffix ? trim(suffix.value) : null,
          },
        })
        .then(({ success }) => {
          if (success) {
            const updatedPerson = { ...person };
            updatedPerson.first_name = firstName;
            updatedPerson.middle_name = middleName;
            updatedPerson.last_name = lastName;
            updatedPerson.suffix = suffix ? suffix.value : null;
            setPerson(updatedPerson);
            const flName = buildFullName(updatedPerson);
            setFullName(flName);
            onClose();
          }
        })
        .catch(error => handleError({ error, message: 'Unable to update this User' }))
        .finally(() => api.toggleLoading(false));
    }
  };

  const onChange =
    field =>
    ({ target }) => {
      let stateFunction = null;
      if (field === 'firstName') {
        stateFunction = setFirstName;
      } else if (field === 'middleName') {
        stateFunction = setMiddleName;
      } else if (field === 'lastName') {
        stateFunction = setLastName;
      } else {
        handleError({ error: `${field} IS NOT VALID` });
        return;
      }
      const regex = /^[a-zA-Z]+(?:[' -]?[a-zA-Z0-9-' ])*$/;
      const newValue = target.value;
      if (!newValue) {
        stateFunction('');
      } else if (regex.test(newValue)) {
        stateFunction(newValue);
      }
    };

  const isSaveDisabled = () => {
    return !firstName || !lastName;
  };

  useEffect(() => {
    if (!isOpen) {
      setFirstName('');
      setMiddleName('');
      setLastName('');
      setSuffix(null);
    }

    if (person) {
      if (person.first_name) setFirstName(person.first_name);
      if (person.middle_name) setMiddleName(person.middle_name);
      if (person.last_name) setLastName(person.last_name);
      if (person.suffix) {
        setSuffix(suffixes.find(s => s.lower === person.suffix.toLocaleLowerCase()));
      }
    }
  }, [isOpen, person]);

  return (
    <AirBadgeModal
      title="Update Full Name"
      size="md"
      isOpen={isOpen}
      onSave={onSaveHandler}
      saveDisabled={isSaveDisabled()}
      saveLabel="Save Change"
      onClose={() => onClose()}
    >
      <Row>
        <Col>
          <ReadOnlyFormData skipTextTransform faded label="Current Full Name" value={fullName} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label>
              First Name <span className="text-danger">*</span>
            </Label>
            <Input value={firstName} onChange={onChange('firstName')} required />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Middle Name</Label>
            <Input value={middleName} onChange={onChange('middleName')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>
              Last Name <span className="text-danger">*</span>
            </Label>
            <Input value={lastName} onChange={onChange('lastName')} required />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <BadgerFormLabel>Suffix</BadgerFormLabel>
            <NativeSelect
              id="suffixSelect"
              name="suffixSelect"
              options={suffixes}
              includeBlank
              value={suffix}
              onChange={setSuffix}
            />
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
