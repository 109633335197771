import React from 'react';
import { ModalHeader } from 'reactstrap';

export default function CustomModalHeader({ children, label, onClick, toggle }) {
  return (
    <ModalHeader
      toggle={onClick || toggle}
      close={
        <button className="close" onClick={onClick || toggle} style={{ outline: 'none' }}>
          <i className="fa fa-times" />
        </button>
      }
    >
      {label || children || 'Missing Title!'}
    </ModalHeader>
  );
}
