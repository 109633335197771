import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { AppContext, basePaymentChoices, invoicePaymentChoice, noChargePaymentChoice } from '../../../../../../../App';
import badgeOfficeApi from '../../../../../../../ajax/BadgeOffice/badgeOfficeApi';
import badgeApplicationApi from '../../../../../../../ajax/BadgeApplication/badgeApplicationApi';

export default function SignatoryPreVerifyPayments({ isShown, badgeApplication, onPaymentChoiceChange }) {
  const app = useContext(AppContext);
  const [shouldAllowPayInPerson, setShouldAllowPayInPerson] = useState(false);
  const [paymentChoice, setPaymentChoice] = useState(basePaymentChoices.find(x => x.value === 'signatory'));

  const onChangePaymentChoice = choice => {
    setPaymentChoice(choice);
    badgeApplicationApi.updateSettings({
      badgeApplicationUuid: badgeApplication.uuid,
      changes: { paymentChoice: choice.value },
    });
    onPaymentChoiceChange(choice.value === 'signatory');
  };

  const getPaymentOptions = useCallback(() => {
    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled } = app.airport.payments;
    let choices = [...basePaymentChoices];
    if (!shouldAllowPayInPerson) {
      choices = choices.filter(x => x.value !== 'in-person');
    }
    if (!isApplicantPaymentEnabled) {
      choices = choices.filter(x => x.value !== 'applicant');
    }
    if (!isSignatoryPaymentEnabled) {
      choices = choices.filter(x => x.value !== 'signatory');
    }
    const authorizedSignatoryCompany = badgeApplication.signatory.company;
    if (authorizedSignatoryCompany) {
      if (authorizedSignatoryCompany.shouldNeverChargePayment) {
        choices.push(noChargePaymentChoice);
      } else if (authorizedSignatoryCompany.shouldAllowPaymentInvoice) {
        choices.push(invoicePaymentChoice);
      }
    }
    return choices;
  }, [shouldAllowPayInPerson, badgeApplication]);

  useEffect(() => {
    if (!isShown || !badgeApplication) return;

    if (badgeApplication.paymentChoice === 'no-charge') {
      setPaymentChoice(noChargePaymentChoice);
    } else if (badgeApplication.paymentChoice === 'invoice') {
      setPaymentChoice(invoicePaymentChoice);
    } else {
      setPaymentChoice(basePaymentChoices.find(x => x.value === badgeApplication.paymentChoice));
    }

    badgeOfficeApi.getSettings().then(({ settings }) => {
      setShouldAllowPayInPerson(settings.shouldAllowPayInPerson);
    });
  }, [isShown, badgeApplication]);

  if (!isShown || !badgeApplication) return null;

  return (
    <Grid container>
      <Grid item>
        <FormGroup>
          <Label>Who should be responsible for paying for this badge application?</Label>
          <Select
            placeholder=""
            classNamePrefix="airbadge"
            className="form-select"
            options={getPaymentOptions()}
            value={paymentChoice}
            onChange={onChangePaymentChoice}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}
