import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import get from 'lodash/get';

export default function SupplementalDocuments({ isHidden, value, data, onChange, onNext }) {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isNextHidden, setIsNextHidden] = useState(true);

  if (isHidden) return null;

  const shouldOnlyShowI94Option = () => {
    const listADocument = get(data, 'listADocuments.value');
    return ['permanent-resident-card', 'i-766'].indexOf(listADocument) > -1;
  };

  return (
    <>
      <BlockFormLabel>
        <Typography variant="h6">{translate('supplementalDocuments.question1.label')}</Typography>
      </BlockFormLabel>

      <FormControl>
        <RadioGroup
          aria-label="supplemental documents"
          name="supplementalDocuments"
          value={value}
          onChange={({ target }) => {
            onChange(target.value);
            setIsNextHidden(!target.value);
          }}
        >
          <FormControlLabel
            value="i-94"
            control={<Radio color="primary" />}
            label={translate('supplementalDocuments.question1.answer1')}
          />

          {!shouldOnlyShowI94Option() && (
            <FormControlLabel
              value="arn"
              control={<Radio color="primary" />}
              label={translate('supplementalDocuments.question1.answer2')}
            />
          )}

          <FormControlLabel
            value="none"
            control={<Radio color="primary" />}
            label={translate('supplementalDocuments.question1.answer3')}
          />
        </RadioGroup>
      </FormControl>

      <NextButton isDisabled={isNextHidden} onClick={onNext} />
    </>
  );
}
