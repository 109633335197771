import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Col, Row } from 'reactstrap';
import AirBadgeModal from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { handleError } from '../../../../../utils';
import appointmentApi from '../../../../../ajax/Appointment/appointmentApi';
import { AppContext } from '../../../../../App';

function AppointmentsModal({ isOpen, onClose, badgeApplication }) {
  const app = useContext(AppContext);
  const [appointments, setAppointments] = useState([]);

  const getTimeDisplay = ({ started, ended }) => {
    const startMoment = moment.tz(started, app.timezone);
    const endMoment = moment.tz(ended, app.timezone);
    return `${startMoment.format('MM/DD/YYYY')} @ ${startMoment.format('h:mm A')} - ${endMoment.format('h:mm A')}`;
  };

  const onModalClose = () => {
    setAppointments([]);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) return;
    setAppointments([]);
    app.api.toggleLoading(true);
    appointmentApi
      .getAllForBadgeApplication({
        attendeeUuid: badgeApplication.badgeHolderUserUuid,
        badgeApplicationUuid: badgeApplication.uuid,
      })
      .then(({ success, appointments }) => {
        if (success) setAppointments(appointments);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <AirBadgeModal isOpen={isOpen} title="Upcoming Appointments" onClose={onModalClose} closeLabel="Close">
      <Row>
        <Col>
          <ReadOnlyFormData skipTextTransform label="Badge Applicant" value={badgeApplication.badgeHolderUserName} />
        </Col>
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Authorized Signatory (Company)"
            value={`${badgeApplication.authorizedSignatoryName} (${badgeApplication.companyName})`}
          />
        </Col>
      </Row>
      <div className="separator mt-3 mb-3">UPCOMING APPOINTMENTS</div>
      {appointments.map((a, i) => (
        <React.Fragment key={a.uuid}>
          {i > 0 && <div className="mt-4 mb-4" style={{ width: '100%', border: '1px dashed #c4c9cb' }}></div>}
          <Row className="mb-3">
            <Col>
              <ReadOnlyFormData skipTextTransform label="Time" value={getTimeDisplay(a)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ReadOnlyFormData skipTextTransform label="Type" value={a.appointmentTypeName} />
            </Col>
            <Col>
              <ReadOnlyFormData skipTextTransform label="Location" value={a.appointmentLocation} />
            </Col>
            <Col>
              <ReadOnlyFormData skipTextTransform label="Staff" value={a.staffName} />
            </Col>
          </Row>
        </React.Fragment>
      ))}
    </AirBadgeModal>
  );
}

export default function BadgeApplicationTablePhaseCell({ user, badgeApplication }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let { currentPhase = 'error', config, appointments = 0, isWaitingOnApplicant, trainingStatus } = badgeApplication;
  const shouldShowUpcomingAppointmentIndicator = Number(appointments) > 0;

  // Capitalize the first letter of the phase
  currentPhase = currentPhase.charAt(0).toUpperCase() + currentPhase.slice(1);

  const showAppointments = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  let shouldShowIndicator = false;
  let shouldShowCalendarIndicator = false;
  const parsedConfig = JSON.parse(config);
  const applicationDocumentTransactions = parsedConfig?.applicationDocument?.documentTransactions;
  if (
    applicationDocumentTransactions?.applicant?.isComplete === true &&
    applicationDocumentTransactions?.signatory?.isComplete === false
  ) {
    shouldShowIndicator = true;
  } else if (
    parsedConfig?.authorizationDocument?.isEnabled === true &&
    parsedConfig?.authorizationDocument?.documentTransactions?.signatory?.isComplete === false
  ) {
    shouldShowIndicator = true;
  }

  if (currentPhase === 'Prepare') {
    const isFingerprintAppointmentEnabled = !!parsedConfig?.fingerprintAppointment?.isEnabled;
    const isFingerprintAppointmentScheduled = !!parsedConfig?.fingerprintAppointment?.appointmentUuid;
    shouldShowCalendarIndicator = isFingerprintAppointmentEnabled && !isFingerprintAppointmentScheduled;
  } else if (currentPhase === 'Authorize') {
    const isSTAEnabled = !!parsedConfig?.approveBackgroundCheckResults?.isEnabled;
    const isSTAComplete = !!parsedConfig?.approveBackgroundCheckResults?.isComplete;
    const isCHRCEnabled = !!parsedConfig?.adjudicateCHRC?.isEnabled;
    const isCHRCComplete = !!parsedConfig?.adjudicateCHRC?.isComplete;
    const isTrainingAppointmentEnabled = !!parsedConfig?.trainingAppointment?.isEnabled;
    const isTrainingAppointmentScheduled = !!parsedConfig?.trainingAppointment?.appointmentUuid;
    shouldShowCalendarIndicator = isTrainingAppointmentEnabled && !isTrainingAppointmentScheduled;
    if (isSTAEnabled && !isSTAComplete) shouldShowCalendarIndicator = false;
    else if (isCHRCEnabled && !isCHRCComplete) shouldShowCalendarIndicator = false;
  } else if (currentPhase === 'Issue') {
    const isBadgePickupAppointmentEnabled = !!parsedConfig?.badgePickupAppointment?.isEnabled;
    const isBadgePickupAppointmentScheduled = !!parsedConfig?.badgePickupAppointment?.appointmentUuid;
    shouldShowCalendarIndicator = isBadgePickupAppointmentEnabled && !isBadgePickupAppointmentScheduled;
  }

  const waitingOnApplicantIcon = (
    <i
      className="fa-solid fa-hourglass-end text-primary"
      style={{ paddingLeft: 5, cursor: 'help' }}
      title="Waiting on Applicant"
    ></i>
  );

  const calendarIcon = (
    <i
      className="fa-regular fa-calendar-alt text-primary"
      style={{ paddingLeft: 5, cursor: 'help' }}
      title="An appointment is ready to be scheduled"
    ></i>
  );

  const appointmentsIcon = (
    <>
      <i
        className="fa-regular fa-calendar-check text-success"
        style={{ paddingLeft: 5, cursor: 'pointer' }}
        onClick={showAppointments}
        title="There is an upcoming appointment (click to see details)"
      ></i>
    </>
  );

  const trainingCompletedIcon = (
    <i
      className="fa-solid fa-graduation-cap text-success"
      style={{ paddingLeft: 5, cursor: 'help' }}
      title="Assigned training completed"
    ></i>
  );

  if (user?.activeProfile?.role?.name !== 'authorized-signatory') {
    return (
      <div title={currentPhase}>
        {currentPhase}
        {!!isWaitingOnApplicant && waitingOnApplicantIcon}
        {shouldShowCalendarIndicator && calendarIcon}
        {shouldShowUpcomingAppointmentIndicator && appointmentsIcon}
        {trainingStatus === 'completed' && trainingCompletedIcon}

        <AppointmentsModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          user={user}
          badgeApplication={badgeApplication}
        />
      </div>
    );
  }

  return (
    <div title={currentPhase}>
      {currentPhase}
      {!!isWaitingOnApplicant && waitingOnApplicantIcon}
      {shouldShowIndicator && (
        <i
          className="fa fa-circle text-warning"
          style={{ paddingLeft: 5, cursor: 'help' }}
          title="The badge application has
                  something for you to do"
        ></i>
      )}
      {shouldShowCalendarIndicator && calendarIcon}
      {shouldShowUpcomingAppointmentIndicator && appointmentsIcon}
      {trainingStatus === 'completed' && trainingCompletedIcon}

      <AppointmentsModal isOpen={isModalOpen} onClose={onModalClose} badgeApplication={badgeApplication} />
    </div>
  );
}
