import request from '../Request';

export default {
  updateStatus: updateAppointmentStatus,
  getAllForBadgeApplication,
  getMyAppointments,
  getAppointmentRequests,
  createAppointmentRequest,
  deleteAppointmentRequest,
  sendAppointmentRequestNotification,
  getDetailsForUser,
};

function updateAppointmentStatus({
  appointmentUuid,
  status,
  subject,
  content,
  shouldCancelBadgeApplication,
  badgeApplicationTerminationReason,
}) {
  let notification = undefined;
  if (subject && content) {
    notification = { subject, content };
  }
  return request('authenticated-user', 'PUT', `appointments/${appointmentUuid}`, {
    appointment: { status },
    notification,
    shouldCancelBadgeApplication,
    badgeApplicationTerminationReason,
  });
}

function getAllForBadgeApplication({ attendeeUuid, badgeApplicationUuid }) {
  return request(
    'authenticated-user',
    'GET',
    `appointments/upcoming-for-badge-application/${attendeeUuid}/${badgeApplicationUuid}`
  );
}

function getMyAppointments({ signal }) {
  return request('authenticated-user', 'GET', 'appointments/my-appointments', null, signal);
}

function getAppointmentRequests({ data, signal }) {
  return request('authenticated-user', 'POST', 'appointments/requests/all', data, signal);
}

function createAppointmentRequest(data) {
  return request('authenticated-user', 'POST', 'appointments/requests', data);
}

function deleteAppointmentRequest(appointmentRequestId) {
  return request('authenticated-user', 'DELETE', `appointments/requests/${appointmentRequestId}`);
}

function sendAppointmentRequestNotification(appointmentRequestId) {
  return request('authenticated-user', 'POST', `appointments/requests/${appointmentRequestId}/notify`);
}

function getDetailsForUser({ userUuid, signal }) {
  return request('authenticated-user', 'GET', `appointments/user-details/${userUuid}`, null, signal);
}
