import request from '../Request';

export default function BadgeDataTableRequest(user, pageSize, page, sorted, filtered, signal) {
  const method = 'POST';

  const url = 'badges/custom/data-table';

  const data = {
    pageSize,
    page,
    sorted,
    filtered,
  };

  return request('authenticated-user', method, url, data, signal);
}
