import React, { useCallback, useContext, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AppContext } from '../../../../../../App';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import { handleError } from '../../../../../../utils';
import FileUploadForm from '../../../../../../shared/components/FileUploadModal/FileUploadForm';
import filesApi from '../../../../../../ajax/File/filesApi';

export default function MarkTrainingCompleteAction() {
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserASC, isCurrentUserTrustedAgent } =
    useContext(ActionPanelContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [decision, setDecision] = useState(null);

  const onFilesAdded = files => {
    setFiles(files);
  };

  const isHidden = useCallback(() => {
    const { active, config } = badgeApplication;
    const { backgroundCheck, approveBackgroundCheckResults, training } = config;
    if (!!active === false) {
      // Badge Application is not active
      return true;
    }
    if (!isCurrentUserASC && !isCurrentUserTrustedAgent) {
      // Only ASCs and TAs can do this action
      return true;
    }
    if (
      (backgroundCheck.isEnabled && !backgroundCheck.isComplete) ||
      (approveBackgroundCheckResults.isEnabled && !approveBackgroundCheckResults.isComplete)
    ) {
      // Background check must be done first
      return true;
    }
    if (training.isComplete) {
      // This requirement is already done
      return true;
    }
    return false;
  }, [badgeApplication, isCurrentUserASC, isCurrentUserTrustedAgent]);

  const onClick = () => {
    setIsModalOpen(true);
  };

  const onSave = async () => {
    const { user, api } = app;
    api.toggleLoading(true);

    if (files && files.length) {
      const { data: fileTypes } = await filesApi.fileTypes();
      const trainingFileType = fileTypes.find(ft => ft.systemName === 'training');
      const file = files[0];
      const data = {
        user: badgeApplication.applicant.uuid,
        type: trainingFileType.uuid,
        badgeApplication: badgeApplication.uuid,
        signatory: badgeApplication.signatory.uuid,
        company: badgeApplication.signatory.company.uuid,
        comment: 'Uploaded as supporting documentation for the Training step of the badge application.',
      };
      const response = await filesApi.upload({ user, file, data });
      if (!response.success) {
        api.toggleLoading(false);
        return handleError({ message: 'Unable to upload file' });
      }
    }

    badgeApplicationApi
      .markTrainingComplete({
        badgeApplicationUuid: badgeApplication.uuid,
        shouldScheduleBadgePickupAppointment: decision === 'true',
      })
      .then(({ success }) => {
        if (success) {
          setIsModalOpen(false);
          onActionDone({ shouldReload: true });
        } else {
          api.toggleLoading(false);
        }
      })
      .catch(error => {
        api.toggleLoading(false);
        handleError({ error });
      });
  };

  const onModalClose = () => {
    setDecision(null);
    setIsModalOpen(false);
    onActionDone({ shouldReload: false });
  };

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={onClick}>
        <ListItemIcon>
          <FontAwesomeIcon
            className="fa fa-graduation-cap"
            style={{ marginRight: 0, fontSize: 22, position: 'relative', left: -5 }}
          />
        </ListItemIcon>
        <ListItemText primary="Mark Training Complete" />
      </ListItem>

      <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
        <CustomModalHeader toggle={onModalClose}>Mark Training Complete</CustomModalHeader>
        <ModalBody>
          <Row>
            <Col>
              By marking this requirement as complete, you are certifying that the applicant has successfully completed
              all required training for a <strong>{badgeApplication.badgeType.displayName}</strong> badge.
            </Col>
          </Row>

          <div style={{ marginTop: 16, marginBottom: 8 }}>
            <Label>Do you have any supporting documentation to attach?</Label>
            <FileUploadForm fileUploadOnly onFilesAdded={onFilesAdded} />
          </div>

          <Row className="mt-4">
            <Col>
              <FormGroup>
                <Label>Does this individual need to schedule a badge pickup appointment?</Label>
                <RadioGroup
                  row
                  aria-label="decision"
                  name="decision"
                  value={decision}
                  onChange={event => setDecision(event.target.value)}
                >
                  <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Cancel"
            onClose={onModalClose}
            saveLabel="Mark As Complete"
            onSave={onSave}
            saveDisabled={!decision}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
