import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AppContext } from '../../../../../App';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import peopleApi from '../../../../../ajax/People/peopleApi';

export default function PersonEditComments({ selectedPerson }) {
  const app = useContext(AppContext);
  const dateFormat = 'MM/DD/YYYY h:mm a';
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const timezone = app.timezone;

  const onCommentModalClose = () => {
    setIsCommentModalOpen(false);
    setNewComment('');
  };

  const loadComments = () => {
    const { api } = app;
    api.toggleLoading(true);
    peopleApi
      .getComments({ userUuid: selectedPerson.uuid })
      .then(({ success, comments }) => {
        if (success) setComments(comments);
        else api.displayMessage('Unable to load comments', 'warning');
      })
      .catch(e => {
        console.error(e);
        api.displayMessage('Unable to load comments', 'warning');
      })
      .finally(() => api.toggleLoading(false));
  };

  const saveComment = async () => {
    const { api } = app;
    api.toggleLoading(true);
    peopleApi
      .addComment({ userUuid: selectedPerson.uuid, comment: newComment })
      .then(({ success, comments }) => {
        if (success) {
          setComments(comments);
          onCommentModalClose();
        } else {
          api.displayMessage('Unable to add comment', 'warning');
        }
      })
      .catch(e => {
        console.error(e);
        api.displayMessage('Unable to add comment', 'warning');
      })
      .finally(() => api.toggleLoading(false));
  };

  useEffect(() => {
    onCommentModalClose();

    if (!selectedPerson) return;

    loadComments();
  }, [selectedPerson]);

  // noinspection JSXUnresolvedComponent
  return (
    <>
      <div>
        <Button
          style={{ width: 120, marginBottom: 16 }}
          color="primary"
          onClick={() => setIsCommentModalOpen(true)}
          size="sm"
        >
          Add Comment
        </Button>
      </div>

      <Card>
        <CardBody>
          <div id="commentsContainer" className="comments-container" style={{ maxHeight: 264, minHeight: 264 }}>
            {comments.map(x => (
              <div key={x.uuid}>
                <div>
                  <small className="text-muted">
                    <strong className="mr-2">{x.author}</strong>
                    {moment.tz(x.created, timezone).format(dateFormat)}
                  </small>
                </div>
                <p>
                  {x.content?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      <span>{line}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>

      <Modal isOpen={isCommentModalOpen} toggle={onCommentModalClose} size="md">
        <CustomModalHeader toggle={onCommentModalClose}>Add Comment</CustomModalHeader>
        <ModalBody>
          <Input required type="textarea" value={newComment} onChange={event => setNewComment(event.target.value)} />
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Cancel"
            onClose={onCommentModalClose}
            saveLabel="Save"
            onSave={saveComment}
            saveDisabled={!newComment}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
