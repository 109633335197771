import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import StaggeredDatePicker from '../../../../../shared/components/DatePickers/StaggeredDatePicker';

export default function DateOfBirthQuestion({ isHidden, value, onBack, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState(value || '');

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col xs="12" sm="4">
          <FormLabel>
            <Typography variant="h6">{t('question9')}</Typography>
          </FormLabel>
          <FormGroup>
            {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
            <StaggeredDatePicker
              startingYear={new Date().getFullYear() - 99}
              endingYear={new Date().getFullYear()}
              reverseYearOrder
              defaultValue={state}
              onValidDate={({ year, month, day }) => setState(`${year}-${month}-${day}`)}
            />
            {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
          </FormGroup>
        </Col>
      </Row>

      <BackButton onClick={onBack} />
      <NextButton isDisabled={!state} onClick={() => onNext(state)} />
    </>
  );
}
