import React, { useContext, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import PaymentForm from '../../../Payments/PaymentForm';
import { AppContext } from '../../../../../App';
import paymentsApi from '../../../../../ajax/Payments/paymentsApi';
import { handleError } from '../../../../../utils';

export default function BadgeApplicationPaymentForm({ isOpen, onClose, badgeApplicationUuid }) {
  const app = useContext(AppContext);
  const [paymentUuid, setPaymentUuid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reset = () => {
    setPaymentUuid(null);
    setIsLoading(false);
  };

  const onPaymentCancelled = () => {
    reset();
    onClose({ shouldReload: false });
  };

  const onPaymentSuccess = () => {
    app.api.toggleLoading(true);
    paymentsApi
      .update({ uuid: paymentUuid, changes: { status: 'paid' } })
      .then(() => {
        reset();
        onClose({ shouldReload: true });
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const createPayment = () => {
    setIsLoading(true);
    app.api.toggleLoading(true);
    paymentsApi
      .create({
        service: 'stripe',
        badgeApplicationUuid,
        notes: 'Initiated by applicant after signing badge application',
      })
      .then(({ uuid }) => {
        setPaymentUuid(uuid);
      })
      .catch(error => handleError({ error }))
      .finally(() => {
        app.api.toggleLoading(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      createPayment();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  if (isLoading) {
    return (
      <div>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Pay For Badge Application"
      isOpen={isOpen}
      onClose={onClose}
      hideFooter
    >
      <PaymentForm
        paymentUuid={paymentUuid}
        onPaymentSuccess={onPaymentSuccess}
        onPaymentCancelled={onPaymentCancelled}
      />
    </AirBadgeModal>
  );
}
