import React from 'react';
import { Button } from 'reactstrap';

export default function ReadOnlyFormData({ label, value, onEdit, skipTextTransform, extraTextStyle, faded }) {
  let textStyle = { fontWeight: 500, textTransform: 'capitalize' };
  if (skipTextTransform) textStyle.textTransform = undefined;
  if (extraTextStyle) textStyle = { ...textStyle, ...extraTextStyle };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontSize: 'smaller', fontStyle: 'italic', opacity: 0.7 }}>{label}</div>
      <div style={textStyle}>
        <span style={faded ? { opacity: 0.7 } : null}>{value}</span>
        {onEdit && (
          <Button
            color="secondary"
            bssize="sm"
            className="px-1 py-0 ml-2"
            style={{ marginLeft: 5, position: 'relative', top: -2, fontSize: 11 }}
            onClick={onEdit}
          >
            <i className="fa fa-pencil" />
          </Button>
        )}
      </div>
    </div>
  );
}
