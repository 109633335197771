const codes = [
  {
    label: 'United States of America',
    twoCharacterCode: 'US',
    threeCharacterCode: 'USA',
  },
  {
    label: 'Afghanistan',
    twoCharacterCode: 'AF',
    threeCharacterCode: 'AFG',
  },
  {
    label: 'Albania',
    twoCharacterCode: 'AA',
    threeCharacterCode: 'ALB',
  },
  {
    label: 'Algeria',
    twoCharacterCode: 'AN',
    threeCharacterCode: 'DZA',
  },
  {
    label: 'Andorra',
    twoCharacterCode: 'AD',
    threeCharacterCode: 'AND',
  },
  {
    label: 'Angola',
    twoCharacterCode: 'AO',
    threeCharacterCode: 'AGO',
  },
  {
    label: 'Antigua and Barbuda',
    twoCharacterCode: 'AI',
    threeCharacterCode: 'ATG',
  },
  {
    label: 'Argentina',
    twoCharacterCode: 'AT',
    threeCharacterCode: 'ARG',
  },
  {
    label: 'Armenia',
    twoCharacterCode: 'AP',
    threeCharacterCode: 'ARM',
  },
  {
    label: 'Aruba',
    twoCharacterCode: 'AJ',
    threeCharacterCode: 'ABW',
  },
  {
    label: 'Australia',
    twoCharacterCode: 'AS',
    threeCharacterCode: 'AUS',
  },
  {
    label: 'Austria',
    twoCharacterCode: 'AU',
    threeCharacterCode: 'AUT',
  },
  {
    label: 'Azerbaijan',
    twoCharacterCode: 'AV',
    threeCharacterCode: 'AZE',
  },
  {
    label: 'Bahamas',
    twoCharacterCode: 'BD',
    threeCharacterCode: 'BHS',
  },
  {
    label: 'Bahrain',
    twoCharacterCode: 'BE',
    threeCharacterCode: 'BHR',
  },
  {
    label: 'Bangladesh',
    twoCharacterCode: 'BL',
    threeCharacterCode: 'BGD',
  },
  {
    label: 'Barbados',
    twoCharacterCode: 'BB',
    threeCharacterCode: 'BRB',
  },
  {
    label: 'Belarus',
    twoCharacterCode: 'BY',
    threeCharacterCode: 'BLR',
  },
  {
    label: 'Belgium',
    twoCharacterCode: 'BG',
    threeCharacterCode: 'BEL',
  },
  {
    label: 'Belize (formerly British Honduras)',
    twoCharacterCode: 'BH',
    threeCharacterCode: 'BLZ',
  },
  {
    label: 'Benin (formerly Dahomey)',
    twoCharacterCode: 'DH',
    threeCharacterCode: 'BEN',
  },
  {
    label: 'Bhutan',
    twoCharacterCode: 'BN',
    threeCharacterCode: 'BTN',
  },
  {
    label: 'Bolivia',
    twoCharacterCode: 'BV',
    threeCharacterCode: 'BOL',
  },
  {
    label: 'Bonaire (Netherlands Antilles)',
    twoCharacterCode: 'NX',
    threeCharacterCode: 'BES',
  },
  {
    label: 'Bosnia and Herzegovina',
    twoCharacterCode: 'BP',
    threeCharacterCode: 'BIH',
  },
  {
    label: 'Botswana',
    twoCharacterCode: 'BT',
    threeCharacterCode: 'BWA',
  },
  {
    label: 'Brazil',
    twoCharacterCode: 'BZ',
    threeCharacterCode: 'BRA',
  },
  {
    label: 'Brunei',
    twoCharacterCode: 'BX',
    threeCharacterCode: 'BRN',
  },
  {
    label: 'Bulgaria',
    twoCharacterCode: 'BU',
    threeCharacterCode: 'BGR',
  },
  {
    label: 'Burkina Faso (formerly Upper Volta)',
    twoCharacterCode: 'UV',
    threeCharacterCode: 'BFA',
  },
  {
    label: 'Burma (Myanmar)',
    twoCharacterCode: 'BR',
    threeCharacterCode: 'MMR',
  },
  {
    label: 'Burundi',
    twoCharacterCode: 'BI',
    threeCharacterCode: 'BDI',
  },
  {
    label: 'Cambodia',
    twoCharacterCode: 'CJ',
    threeCharacterCode: 'KHM',
  },
  {
    label: 'Cameroon',
    twoCharacterCode: 'CM',
    threeCharacterCode: 'CMR',
  },
  {
    label: 'Canada',
    twoCharacterCode: 'CD',
    threeCharacterCode: 'CAN',
  },
  {
    label: 'Cape Verde',
    twoCharacterCode: 'CV',
    threeCharacterCode: 'CPV',
  },
  {
    label: 'Central African Republic',
    twoCharacterCode: 'CW',
    threeCharacterCode: 'CAF',
  },
  {
    label: 'Chad',
    twoCharacterCode: 'CF',
    threeCharacterCode: 'TCD',
  },
  {
    label: 'Chile',
    twoCharacterCode: 'CQ',
    threeCharacterCode: 'CHL',
  },
  {
    label: "China (People's Republic, not Taiwan)",
    twoCharacterCode: 'RC',
    threeCharacterCode: 'CHN',
  },
  {
    label: 'Colombia',
    twoCharacterCode: 'CB',
    threeCharacterCode: 'COL',
  },
  {
    label: 'Comoros',
    twoCharacterCode: 'DG',
    threeCharacterCode: 'COM',
  },
  {
    label: 'Congo, Democratic Republic (formerly Zaire Kinshasa)',
    twoCharacterCode: 'ZR',
    threeCharacterCode: 'COD',
  },
  {
    label: 'Congo, Republic (Brazzaville)',
    twoCharacterCode: 'RB',
    threeCharacterCode: 'COG',
  },
  {
    label: 'Cook Islands',
    twoCharacterCode: 'DI',
    threeCharacterCode: 'COK',
  },
  {
    label: 'Costa Rica',
    twoCharacterCode: 'CR',
    threeCharacterCode: 'CRI',
  },
  {
    label: 'Croatia',
    twoCharacterCode: 'KC',
    threeCharacterCode: 'HRV',
  },
  {
    label: 'Cuba',
    twoCharacterCode: 'CC',
    threeCharacterCode: 'CUB',
  },
  {
    label: 'Curacao',
    twoCharacterCode: 'NX',
    threeCharacterCode: 'CUW',
  },
  {
    label: 'Cyprus',
    twoCharacterCode: 'CS',
    threeCharacterCode: 'CYP',
  },
  {
    label: 'Czech Republic',
    twoCharacterCode: 'EZ',
    threeCharacterCode: 'CZE',
  },
  {
    label: 'Denmark',
    twoCharacterCode: 'DK',
    threeCharacterCode: 'DNK',
  },
  {
    label: 'Djibouti',
    twoCharacterCode: 'DN',
    threeCharacterCode: 'DJI',
  },
  {
    label: 'Dominica',
    twoCharacterCode: 'DM',
    threeCharacterCode: 'DMA',
  },
  {
    label: 'Dominican Republic',
    twoCharacterCode: 'DR',
    threeCharacterCode: 'DOM',
  },
  {
    label: 'Ecuador',
    twoCharacterCode: 'EU',
    threeCharacterCode: 'ECU',
  },
  {
    label: 'Egypt',
    twoCharacterCode: 'EY',
    threeCharacterCode: 'EGY',
  },
  {
    label: 'El Salvador',
    twoCharacterCode: 'EL',
    threeCharacterCode: 'SLV',
  },
  {
    label: 'Equatorial Guinea',
    twoCharacterCode: 'EK',
    threeCharacterCode: 'GNQ',
  },
  {
    label: 'Eritrea',
    twoCharacterCode: 'ET',
    threeCharacterCode: 'ERI',
  },
  {
    label: 'Estonia',
    twoCharacterCode: 'ES',
    threeCharacterCode: 'EST',
  },
  {
    label: 'Ethiopia',
    twoCharacterCode: 'EO',
    threeCharacterCode: 'ETH',
  },
  {
    label: 'Faroe Islands',
    twoCharacterCode: 'FO',
    threeCharacterCode: 'FRO',
  },
  {
    label: 'Fiji Islands',
    twoCharacterCode: 'FJ',
    threeCharacterCode: 'FJI',
  },
  {
    label: 'Finland',
    twoCharacterCode: 'FD',
    threeCharacterCode: 'FIN',
  },
  {
    label: 'France',
    twoCharacterCode: 'FN',
    threeCharacterCode: 'FRA',
  },
  {
    label: 'Gabon',
    twoCharacterCode: 'GB',
    threeCharacterCode: 'GAB',
  },
  {
    label: 'Gambia',
    twoCharacterCode: 'GK',
    threeCharacterCode: 'GMB',
  },
  {
    label: 'Georgia (Soviet Socialist Republic)',
    twoCharacterCode: 'GD',
    threeCharacterCode: 'GEO',
  },
  {
    label: 'Germany',
    twoCharacterCode: 'GE',
    threeCharacterCode: 'DEU',
  },
  {
    label: 'Ghana',
    twoCharacterCode: 'GG',
    threeCharacterCode: 'GHA',
  },
  {
    label: 'Greece',
    twoCharacterCode: 'GC',
    threeCharacterCode: 'GRC',
  },
  {
    label: 'Greenland',
    twoCharacterCode: 'GN',
    threeCharacterCode: 'GRL',
  },
  {
    label: 'Grenada',
    twoCharacterCode: 'GJ',
    threeCharacterCode: 'GRD',
  },
  {
    label: 'Guatemala',
    twoCharacterCode: 'GT',
    threeCharacterCode: 'GTM',
  },
  {
    label: 'Guinea',
    twoCharacterCode: 'GI',
    threeCharacterCode: 'GIN',
  },
  {
    label: 'Guinea-Bissau (formerly Portugese Guinea)',
    twoCharacterCode: 'PG',
    threeCharacterCode: 'GNB',
  },
  {
    label: 'Guyana',
    twoCharacterCode: 'GY',
    threeCharacterCode: 'GUY',
  },
  {
    label: 'Haiti',
    twoCharacterCode: 'HT',
    threeCharacterCode: 'HTI',
  },
  {
    label: 'Honduras',
    twoCharacterCode: 'HD',
    threeCharacterCode: 'HND',
  },
  {
    label: 'Hong Kong',
    twoCharacterCode: 'HK',
    threeCharacterCode: 'HKG',
  },
  {
    label: 'Hungary',
    twoCharacterCode: 'HU',
    threeCharacterCode: 'HUN',
  },
  {
    label: 'Iceland',
    twoCharacterCode: 'IC',
    threeCharacterCode: 'ISL',
  },
  {
    label: 'India',
    twoCharacterCode: 'II',
    threeCharacterCode: 'IND',
  },
  {
    label: 'Indonesia (includes Portugese Timor)',
    twoCharacterCode: 'IO',
    threeCharacterCode: 'IDN',
  },
  {
    label: 'Iran',
    twoCharacterCode: 'IR',
    threeCharacterCode: 'IRN',
  },
  {
    label: 'Iraq',
    twoCharacterCode: 'IQ',
    threeCharacterCode: 'IRQ',
  },
  {
    label: 'Ireland (does not include Northern)',
    twoCharacterCode: 'IE',
    threeCharacterCode: 'IRL',
  },
  {
    label: 'Israel',
    twoCharacterCode: 'IS',
    threeCharacterCode: 'ISR',
  },
  {
    label: 'Italy',
    twoCharacterCode: 'IT',
    threeCharacterCode: 'ITA',
  },
  {
    label: 'Ivory Coast',
    twoCharacterCode: 'IY',
    threeCharacterCode: 'CIV',
  },
  {
    label: 'Jamaica',
    twoCharacterCode: 'JM',
    threeCharacterCode: 'JAM',
  },
  {
    label: 'Japan',
    twoCharacterCode: 'JA',
    threeCharacterCode: 'JPN',
  },
  {
    label: 'Jordan',
    twoCharacterCode: 'JO',
    threeCharacterCode: 'JOR',
  },
  {
    label: 'Kazakhstan',
    twoCharacterCode: 'KT',
    threeCharacterCode: 'KAZ',
  },
  {
    label: 'Kenya',
    twoCharacterCode: 'KE',
    threeCharacterCode: 'KEN',
  },
  {
    label: 'Kiribati',
    twoCharacterCode: 'KB',
    threeCharacterCode: 'KIR',
  },
  {
    label: 'Korea (North)',
    twoCharacterCode: 'KN',
    threeCharacterCode: 'PRK',
  },
  {
    label: 'Korea (South)',
    twoCharacterCode: 'KO',
    threeCharacterCode: 'KOR',
  },
  {
    label: 'Kuwait',
    twoCharacterCode: 'KU',
    threeCharacterCode: 'KWT',
  },
  {
    label: 'Kyrgyzstan',
    twoCharacterCode: 'KZ',
    threeCharacterCode: 'KGZ',
  },
  {
    label: 'Laos',
    twoCharacterCode: 'LS',
    threeCharacterCode: 'LAO',
  },
  {
    label: 'Latvia',
    twoCharacterCode: 'LT',
    threeCharacterCode: 'LVA',
  },
  {
    label: 'Lebanon',
    twoCharacterCode: 'LN',
    threeCharacterCode: 'LBN',
  },
  {
    label: 'Lesotho',
    twoCharacterCode: 'LE',
    threeCharacterCode: 'LSO',
  },
  {
    label: 'Liberia',
    twoCharacterCode: 'LB',
    threeCharacterCode: 'LBR',
  },
  {
    label: 'Libya',
    twoCharacterCode: 'LY',
    threeCharacterCode: 'LBY',
  },
  {
    label: 'Liechtenstein',
    twoCharacterCode: 'LI',
    threeCharacterCode: 'LIE',
  },
  {
    label: 'Lithuania',
    twoCharacterCode: 'LH',
    threeCharacterCode: 'LTU',
  },
  {
    label: 'Luxembourg',
    twoCharacterCode: 'LX',
    threeCharacterCode: 'LUX',
  },
  {
    label: 'Macao',
    twoCharacterCode: 'OC',
    threeCharacterCode: 'MAC',
  },
  {
    label: 'Madagascar (Malagasy Republic)',
    twoCharacterCode: 'MP',
    threeCharacterCode: 'MDG',
  },
  {
    label: 'Malawi',
    twoCharacterCode: 'MF',
    threeCharacterCode: 'MWI',
  },
  {
    label: 'Malaysia',
    twoCharacterCode: 'MZ',
    threeCharacterCode: 'MYS',
  },
  {
    label: 'Maldives',
    twoCharacterCode: 'MV',
    threeCharacterCode: 'MDV',
  },
  {
    label: 'Mali',
    twoCharacterCode: 'ML',
    threeCharacterCode: 'MLI',
  },
  {
    label: 'Malta',
    twoCharacterCode: 'MY',
    threeCharacterCode: 'MLT',
  },
  {
    label: 'Marshall Islands',
    twoCharacterCode: 'MH',
    threeCharacterCode: 'MHL',
  },
  {
    label: 'Martinique',
    twoCharacterCode: 'ZB',
    threeCharacterCode: 'MTQ',
  },
  {
    label: 'Mauritania',
    twoCharacterCode: 'MU',
    threeCharacterCode: 'MRT',
  },
  {
    label: 'Mauritius',
    twoCharacterCode: 'UM',
    threeCharacterCode: 'MUS',
  },
  {
    label: 'Mayotte (France)',
    twoCharacterCode: 'YO',
    threeCharacterCode: 'MYT',
  },
  {
    label: 'Mexico',
    twoCharacterCode: 'MM',
    threeCharacterCode: 'MEX',
  },
  {
    label: 'Micronesia (Federated States of)',
    twoCharacterCode: 'FS',
    threeCharacterCode: 'FSM',
  },
  {
    label: 'Moldova',
    twoCharacterCode: 'LD',
    threeCharacterCode: 'MDA',
  },
  {
    label: 'Monaco',
    twoCharacterCode: 'MJ',
    threeCharacterCode: 'MCO',
  },
  {
    label: 'Mongolia',
    twoCharacterCode: 'MG',
    threeCharacterCode: 'MNG',
  },
  {
    label: 'Montenegro',
    twoCharacterCode: 'XB',
    threeCharacterCode: 'MNE',
  },
  {
    label: 'Morocco',
    twoCharacterCode: 'MQ',
    threeCharacterCode: 'MAR',
  },
  {
    label: 'Mozambique',
    twoCharacterCode: 'ZO',
    threeCharacterCode: 'MOZ',
  },
  {
    label: 'Namibia (South-West Africa)',
    twoCharacterCode: 'SJ',
    threeCharacterCode: 'NAM',
  },
  {
    label: 'Nauru',
    twoCharacterCode: 'NR',
    threeCharacterCode: 'NRU',
  },
  {
    label: 'Nepal',
    twoCharacterCode: 'NP',
    threeCharacterCode: 'NPL',
  },
  {
    label: 'Netherlands (Includes Holland)',
    twoCharacterCode: 'NE',
    threeCharacterCode: 'NLD',
  },
  {
    label: 'Nevis and Saint Christopher Kitts',
    twoCharacterCode: 'TS',
    threeCharacterCode: 'KNA',
  },
  {
    label: 'New Zealand',
    twoCharacterCode: 'NZ',
    threeCharacterCode: 'NZL',
  },
  {
    label: 'Nicaragua',
    twoCharacterCode: 'NU',
    threeCharacterCode: 'NIC',
  },
  {
    label: 'Niger',
    twoCharacterCode: 'NN',
    threeCharacterCode: 'NER',
  },
  {
    label: 'Nigeria',
    twoCharacterCode: 'NG',
    threeCharacterCode: 'NGA',
  },
  {
    label: 'Niue',
    twoCharacterCode: 'IU',
    threeCharacterCode: 'NIU',
  },
  {
    label: 'North Macedonia',
    twoCharacterCode: 'ZD',
    threeCharacterCode: 'MKD',
  },
  {
    label: 'Norway',
    twoCharacterCode: 'NW',
    threeCharacterCode: 'NOR',
  },
  {
    label: 'Oman',
    twoCharacterCode: 'OM',
    threeCharacterCode: 'OMN',
  },
  {
    label: 'Pakistan',
    twoCharacterCode: 'PK',
    threeCharacterCode: 'PAK',
  },
  {
    label: 'Palau',
    twoCharacterCode: 'PD',
    threeCharacterCode: 'PLW',
  },
  {
    label: 'Panama',
    twoCharacterCode: 'PM',
    threeCharacterCode: 'PAN',
  },
  {
    label: 'Papua New Guinea',
    twoCharacterCode: 'NO',
    threeCharacterCode: 'PNG',
  },
  {
    label: 'Paraguay',
    twoCharacterCode: 'PV',
    threeCharacterCode: 'PRY',
  },
  {
    label: 'Peru',
    twoCharacterCode: 'PU',
    threeCharacterCode: 'PER',
  },
  {
    label: 'Philippines',
    twoCharacterCode: 'PI',
    threeCharacterCode: 'PHL',
  },
  {
    label: 'Poland',
    twoCharacterCode: 'PO',
    threeCharacterCode: 'POL',
  },
  {
    label: 'Portugal',
    twoCharacterCode: 'PT',
    threeCharacterCode: 'PRT',
  },
  {
    label: 'Qatar',
    twoCharacterCode: 'QA',
    threeCharacterCode: 'QAT',
  },
  {
    label: 'Romania',
    twoCharacterCode: 'RU',
    threeCharacterCode: 'ROU',
  },
  {
    label: 'Rwanda',
    twoCharacterCode: 'RW',
    threeCharacterCode: 'RWA',
  },
  {
    label: 'Saint Barts',
    twoCharacterCode: 'FN',
    threeCharacterCode: 'BLM',
  },
  {
    label: 'Saint Lucia',
    twoCharacterCode: 'LU',
    threeCharacterCode: 'LCA',
  },
  {
    label: 'Saint Martin (French part)',
    twoCharacterCode: 'FN',
    threeCharacterCode: 'MAF',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    twoCharacterCode: 'VV',
    threeCharacterCode: 'VCT',
  },
  {
    label: 'San Marino',
    twoCharacterCode: 'SH',
    threeCharacterCode: 'SMR',
  },
  {
    label: 'Sao Tome and Principe',
    twoCharacterCode: 'TP',
    threeCharacterCode: 'STP',
  },
  {
    label: 'Saudi Arabia',
    twoCharacterCode: 'SB',
    threeCharacterCode: 'SAU',
  },
  {
    label: 'Senegal',
    twoCharacterCode: 'SG',
    threeCharacterCode: 'SEN',
  },
  {
    label: 'Serbia',
    twoCharacterCode: 'XA',
    threeCharacterCode: 'SRB',
  },
  {
    label: 'Seychelles',
    twoCharacterCode: 'SE',
    threeCharacterCode: 'SYC',
  },
  {
    label: 'Sierra Leone',
    twoCharacterCode: 'SA',
    threeCharacterCode: 'SLE',
  },
  {
    label: 'Singapore',
    twoCharacterCode: 'SR',
    threeCharacterCode: 'SGP',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    twoCharacterCode: 'NE',
    threeCharacterCode: 'SXM',
  },
  {
    label: 'Slovakia',
    twoCharacterCode: 'LF',
    threeCharacterCode: 'SVK',
  },
  {
    label: 'Slovenia',
    twoCharacterCode: 'LO',
    threeCharacterCode: 'SVN',
  },
  {
    label: 'Solomon Islands',
    twoCharacterCode: 'BS',
    threeCharacterCode: 'SLB',
  },
  {
    label: 'Somalia',
    twoCharacterCode: 'SM',
    threeCharacterCode: 'SOM',
  },
  {
    label: 'South Africa',
    twoCharacterCode: 'SF',
    threeCharacterCode: 'ZAF',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    twoCharacterCode: 'GS',
    threeCharacterCode: 'SGS',
  },
  {
    label: 'South Sudan',
    twoCharacterCode: 'ZF',
    threeCharacterCode: 'SSD',
  },
  {
    label: 'Spain',
    twoCharacterCode: 'SP',
    threeCharacterCode: 'ESP',
  },
  {
    label: 'Sri Lanka (formerly Ceylon)',
    twoCharacterCode: 'CY',
    threeCharacterCode: 'LKA',
  },
  {
    label: 'Sudan',
    twoCharacterCode: 'SU',
    threeCharacterCode: 'SDN',
  },
  {
    label: 'Suriname',
    twoCharacterCode: 'ZC',
    threeCharacterCode: 'SUR',
  },
  {
    label: 'Swaziland/Eswatini',
    twoCharacterCode: 'SW',
    threeCharacterCode: 'SWZ',
  },
  {
    label: 'Sweden',
    twoCharacterCode: 'SQ',
    threeCharacterCode: 'SWE',
  },
  {
    label: 'Switzerland',
    twoCharacterCode: 'SZ',
    threeCharacterCode: 'CHE',
  },
  {
    label: 'Syria',
    twoCharacterCode: 'SY',
    threeCharacterCode: 'SYR',
  },
  {
    label: 'Taiwan (Republic of China)',
    twoCharacterCode: 'TW',
    threeCharacterCode: 'TWN',
  },
  {
    label: 'Tajikistan',
    twoCharacterCode: 'TJ',
    threeCharacterCode: 'TJK',
  },
  {
    label: 'Tanzania',
    twoCharacterCode: 'TZ',
    threeCharacterCode: 'TZA',
  },
  {
    label: 'Thailand',
    twoCharacterCode: 'TH',
    threeCharacterCode: 'THA',
  },
  {
    label: 'Timor-Leste',
    twoCharacterCode: 'IO',
    threeCharacterCode: 'TLS',
  },
  {
    label: 'Togo',
    twoCharacterCode: 'TO',
    threeCharacterCode: 'TGO',
  },
  {
    label: 'Tonga',
    twoCharacterCode: 'TG',
    threeCharacterCode: 'TON',
  },
  {
    label: 'Trinidad and Tobago',
    twoCharacterCode: 'TT',
    threeCharacterCode: 'TTO',
  },
  {
    label: 'Tunisia',
    twoCharacterCode: 'TU',
    threeCharacterCode: 'TUN',
  },
  {
    label: 'Turkey',
    twoCharacterCode: 'TY',
    threeCharacterCode: 'TUR',
  },
  {
    label: 'Turkmenistan',
    twoCharacterCode: 'UR',
    threeCharacterCode: 'TKM',
  },
  {
    label: 'Tuvalu',
    twoCharacterCode: 'TV',
    threeCharacterCode: 'TUV',
  },
  {
    label: 'Uganda',
    twoCharacterCode: 'UG',
    threeCharacterCode: 'UGA',
  },
  {
    label: 'Ukraine',
    twoCharacterCode: 'UK',
    threeCharacterCode: 'UKR',
  },
  {
    label: 'United Arab Emirates (formerly Trucial States)',
    twoCharacterCode: 'TC',
    threeCharacterCode: 'ARE',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    twoCharacterCode: 'UN',
    threeCharacterCode: 'GBR',
  },
  {
    label: 'United Kingdom - England',
    twoCharacterCode: 'EN',
    threeCharacterCode: 'GBR',
  },
  {
    label: 'United Kingdom - Scotland',
    twoCharacterCode: 'SS',
    threeCharacterCode: 'GBR',
  },
  {
    label: 'United Kingdom - Wales',
    twoCharacterCode: 'WL',
    threeCharacterCode: 'GBR',
  },
  {
    label: 'Uruguay',
    twoCharacterCode: 'UY',
    threeCharacterCode: 'URY',
  },
  {
    label: 'Uzbekistan',
    twoCharacterCode: 'UZ',
    threeCharacterCode: 'UZB',
  },
  {
    label: 'Vanuatu (formerly New Hebrides)',
    twoCharacterCode: 'HN',
    threeCharacterCode: 'VUT',
  },
  {
    label: 'Venezuela',
    twoCharacterCode: 'VZ',
    threeCharacterCode: 'VEN',
  },
  {
    label: 'Vietnam',
    twoCharacterCode: 'RV',
    threeCharacterCode: 'VNM',
  },
  {
    label: 'West Bank',
    twoCharacterCode: 'WB',
    threeCharacterCode: 'PSE',
  },
  {
    label: 'Western Sahara (formerly Spanish Sahara)',
    twoCharacterCode: 'RS',
    threeCharacterCode: 'ESH',
  },
  {
    label: 'Western Samoa',
    twoCharacterCode: 'WS',
    threeCharacterCode: 'WSM',
  },
  {
    label: 'Yemen',
    twoCharacterCode: 'RY',
    threeCharacterCode: 'YEM',
  },
  {
    label: 'Zambia',
    twoCharacterCode: 'ZM',
    threeCharacterCode: 'ZMB',
  },
  {
    label: 'Zimbabwe (formerly Rhodesia)',
    twoCharacterCode: 'RH',
    threeCharacterCode: 'ZWE',
  },
];

export default codes;
