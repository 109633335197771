import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import ReactTable from 'react-table';
import { Alert, Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
import { styled } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import AirBadgeModal, {
  MODAL_SIZE_LARGE,
  MODAL_SIZE_MEDIUM,
} from '../../../shared/components/AirBadgeModal/AirBadgeModal';
import violationsApi from '../../../ajax/Violations/violationsApi';
import { buildFullName, handleError } from '../../../utils';
import { AppContext } from '../../../App';
import ReadOnlyFormData from '../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import Collapsible from '../../../shared/components/Collapsible/Collapsible';
import FontAwesomeIcon from '../../../shared/components/FontAwesome/FontAwesomeIcon';
import ChangeViolationStatusModal, { getViolationStatusLabel } from './ChangeViolationStatusModal';
import EditViolationModal from './EditViolationModal';
import UploadViolationFileModal from './UploadViolationFileModal';
import FileViewModal from '../../../shared/components/FileViewModal/FileViewModal';

const RowWithSpacer = styled(Row)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

let requestTimeout = null;
let abortController = null;

export default function ViolationDetailsModal({ isOpen, onClose, violationUuid }) {
  const app = useContext(AppContext);
  const [violation, setViolation] = useState(null);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isFilesExpanded, setIsFilesExpanded] = useState(false);
  const [isFilesLoading, setIsFilesLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDataChanged, setIsDataChanged] = useState(false);

  const columns = [
    {
      id: 'actions',
      Header: null,
      accessor: row => {
        if (row.mimeType === 'application/octet-stream') {
          return null;
        }

        return (
          <span title="View File" aria-label="View File" style={{ cursor: 'pointer' }} onClick={() => onViewFile(row)}>
            <ImageSearchIcon color="primary" />
          </span>
        );
      },
      show: true,
      mobile: true,
      filterable: false,
      maxWidth: 20,
    },
    {
      id: 'uploadedByName',
      Header: 'Uploaded By',
      accessor: ({ uploadedByName }) => <span title={uploadedByName}>{uploadedByName}</span>,
      show: true,
      mobile: true,
      filterable: false,
    },
    {
      id: 'notes',
      Header: 'Notes',
      accessor: ({ notes }) => <span title={notes}>{notes}</span>,
    },
  ];

  const onViewFile = file => {
    setSelectedFile(file);
    setIsViewModalOpen(true);
  };

  const onCloseFileModal = () => {
    setSelectedFile(null);
    setIsViewModalOpen(false);
  };

  const onUploadClicked = () => {
    setIsUploadModalOpen(true);
  };

  const onUploadModalClose = ({ shouldReload }) => {
    setIsUploadModalOpen(false);
    if (shouldReload) {
      setIsDataChanged(true);
      loadData();
      if (isFilesExpanded) loadFiles();
    }
  };

  const onEditModalClose = ({ shouldReload }) => {
    setIsEditModalOpen(false);
    if (shouldReload) {
      setIsDataChanged(true);
      loadData();
    }
  };

  const onChangeStatusClicked = () => {
    setIsChangeStatusModalOpen(true);
  };

  const onCloseChangeStatusModal = ({ shouldReload }) => {
    setIsChangeStatusModalOpen(false);
    if (shouldReload) {
      setIsDataChanged(true);
      loadData();
    }
  };

  const onMakeCorrectionsClicked = () => {
    setIsEditModalOpen(true);
  };

  const onCommentModalClose = () => {
    setIsCommentModalOpen(false);
    setNewComment('');
  };

  const saveComment = () => {
    app.api.toggleLoading(true);
    violationsApi
      .addViolationComment({
        violationUuid: violation.uuid,
        comment: newComment,
      })
      .then(({ success }) => {
        if (success) {
          onCommentModalClose();
          loadData();
        } else {
          app.api.toggleLoading(false);
        }
      })
      .catch(error => {
        handleError({ error });
        app.api.toggleLoading(false);
      });
  };

  const getRelatedPersonName = () => {
    if (!violation?.relatedUserUuid) return '-';

    const { relatedUserFirstName, relatedUserMiddleName, relatedUserLastName, relatedUserSuffix } = violation;
    return buildFullName({
      firstName: relatedUserFirstName,
      middleName: relatedUserMiddleName,
      lastName: relatedUserLastName,
      suffix: relatedUserSuffix,
    });
  };

  const getRelatedCompany = () => {
    if (!violation?.relatedCompanyUuid) return '-';

    return violation?.relatedCompanyName;
  };

  const reset = () => {
    setViolation(null);
    setIsCommentsExpanded(false);
    setIsCommentModalOpen(false);
    setNewComment('');
    setIsEditModalOpen(false);
    setIsChangeStatusModalOpen(false);
    setIsFilesExpanded(false);
    setIsFilesLoading(false);
    setIsViewModalOpen(false);
    setSelectedFile(null);
    setFiles([]);
  };

  const onCloseModal = () => {
    reset();
    onClose({ shouldReload: isDataChanged });
  };

  const loadData = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    app.api.toggleLoading(true);

    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      violationsApi
        .getViolation({ violationUuid, signal: abortController.signal })
        .then(({ success, violation, comments }) => {
          if (success) {
            setViolation(violation);
            setComments(comments);
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }, 500);
  };

  const loadFiles = () => {
    setIsFilesLoading(true);
    setFiles([]);
    violationsApi
      .getFiles({ violationUuid })
      .then(({ success, files }) => {
        if (success) setFiles(files);
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsFilesLoading(false));
  };

  useEffect(() => {
    setIsDataChanged(false);

    if (isOpen && violationUuid) {
      loadData();
    }

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [isOpen, violationUuid]);

  if (!isOpen || !violationUuid) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Violation Details"
      isOpen={isOpen}
      onClose={onCloseModal}
      closeLabel="Close"
    >
      {!!violation?.mustBeCompletedBy && (
        <Alert color="warning">
          Must be completed by{' '}
          <strong>{moment.tz(violation.mustBeCompletedBy, app.timezone).format('MMMM DD, YYYY')} 11:59:59 PM</strong>
        </Alert>
      )}
      <RowWithSpacer>
        {violation?.relatedUserUuid ? (
          <Col>
            <ReadOnlyFormData skipTextTransform label="Related Person" value={getRelatedPersonName()} />
          </Col>
        ) : (
          <Col>
            <ReadOnlyFormData skipTextTransform label="Related Company" value={getRelatedCompany()} />
          </Col>
        )}
        <Col>
          <ReadOnlyFormData skipTextTransform label="Date of Violation" value={violation?.violationDate} />
        </Col>
        <Col>
          <ReadOnlyFormData skipTextTransform label="Status" value={getViolationStatusLabel(violation?.status)} />
        </Col>
      </RowWithSpacer>
      <RowWithSpacer>
        <Col>
          <ReadOnlyFormData skipTextTransform label="Type" value={violation?.violationType} />
        </Col>
      </RowWithSpacer>
      <RowWithSpacer>
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Offense Number"
            value={violation?.isWarning ? 'Warning' : violation?.numOffense || '0'}
          />
        </Col>
      </RowWithSpacer>
      <RowWithSpacer>
        <Col>
          <ReadOnlyFormData skipTextTransform label="Description" value={violation?.description || '-'} />
        </Col>
      </RowWithSpacer>

      <RowWithSpacer>
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Fine Amount"
            value={
              violation?.fineAmount
                ? '$' +
                  new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(violation?.fineAmount)
                : '$0.00'
            }
          />
        </Col>
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Retraining required?"
            value={violation?.isRetrainingRequired ? 'Yes' : 'No'}
          />
        </Col>
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Badge suspended?"
            value={violation?.isBadgeSuspended ? 'Yes' : 'No'}
          />
        </Col>
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Badge confiscated?"
            value={violation?.isBadgeConfiscated ? 'Yes' : 'No'}
          />
        </Col>
      </RowWithSpacer>

      <div className="separator mt-4">ACTIONS</div>

      <Row className="mb-4">
        <Col>
          <List>
            <ListItem button onClick={onChangeStatusClicked} disabled={violation?.status === 'resolved'}>
              <ListItemIcon>
                <FontAwesomeIcon className="fa-solid fa-list-check" />
              </ListItemIcon>
              <ListItemText primary="Change Status" />
            </ListItem>
            <ListItem button onClick={onUploadClicked} disabled={violation?.status === 'resolved'}>
              <ListItemIcon>
                <FontAwesomeIcon className="fa-solid fa-cloud-arrow-up" />
              </ListItemIcon>
              <ListItemText primary="Upload File" />
            </ListItem>
          </List>
        </Col>
        <Col>
          <List>
            <ListItem button onClick={onMakeCorrectionsClicked} disabled={violation?.status === 'resolved'}>
              <ListItemIcon>
                <FontAwesomeIcon className="fa-solid fa-pen-to-square" />
              </ListItemIcon>
              <ListItemText primary="Edit Details" />
            </ListItem>
          </List>
        </Col>
      </Row>

      <Row>
        <Col>
          <Collapsible
            id="violation-files"
            title="Files"
            icon={<i className="fa-solid fa-copy mr-2" />}
            expanded={isFilesExpanded}
            onChange={() => {
              const isOpen = !isFilesExpanded;
              setIsFilesExpanded(isOpen);
              if (isOpen) loadFiles();
            }}
            style={{ backgroundColor: '#f0f0f0' }}
          >
            <Card>
              <CardBody>
                <div className="table-responsive" style={{ maxHeight: 264, minHeight: 264 }}>
                  <ReactTable
                    manual
                    loading={isFilesLoading}
                    className="border-0 -striped"
                    data={files}
                    columns={columns}
                    pages={1}
                    minRows={0}
                    defaultPageSize={10000}
                    showPagination={false}
                  />
                </div>
              </CardBody>
            </Card>
          </Collapsible>
          <Collapsible
            id="violation-comments"
            title="Comments / Activity"
            icon={<i className="fa fa-comments-o mr-2" />}
            expanded={isCommentsExpanded}
            onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
            style={{ backgroundColor: '#f0f0f0' }}
          >
            <div style={{ marginBottom: 16 }}>
              <Button style={{ width: 120 }} color="primary" onClick={() => setIsCommentModalOpen(true)} size="sm">
                Add Comment
              </Button>
            </div>
            <Card>
              <CardBody>
                <div id="commentsContainer" className="comments-container" style={{ maxHeight: 264, minHeight: 264 }}>
                  {comments.map(({ id, content, createdOn, createdBy }) => (
                    <div key={id}>
                      <div>
                        <small className="text-muted">
                          <strong className="mr-2">{createdBy}</strong>
                          {moment.tz(createdOn, app.timezone).format('MM/DD/YYYY h:mm a')}
                        </small>
                      </div>
                      <p>
                        {content?.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            <span>{line}</span>
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Collapsible>
        </Col>
      </Row>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Add Violation Comment"
        isOpen={isCommentModalOpen}
        onClose={onCommentModalClose}
        closeLabel="Cancel"
        onSave={saveComment}
        saveLabel="Add Comment"
        saveDisabled={!newComment}
      >
        <Input
          style={{ height: 100 }}
          type="textarea"
          value={newComment}
          onChange={event => setNewComment(event?.target?.value)}
        />
      </AirBadgeModal>

      <ChangeViolationStatusModal
        isOpen={isChangeStatusModalOpen}
        onClose={onCloseChangeStatusModal}
        violation={violation}
      />

      <EditViolationModal isOpen={isEditModalOpen} onClose={onEditModalClose} violation={violation} />

      <UploadViolationFileModal isOpen={isUploadModalOpen} onClose={onUploadModalClose} violation={violation} />

      <FileViewModal file={selectedFile} isOpen={isViewModalOpen} onClose={onCloseFileModal} />
    </AirBadgeModal>
  );
}
