import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { image, text } from '@pdfme/schemas';
import { generate } from '@pdfme/generator';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { colors } from '../../../../../App';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function ViewPrintedBadgeAction({ badge }) {
  const [isPrintedBadgeModalOpen, setIsPrintedBadgeModalOpen] = useState(false);

  const onModalClose = () => {
    setIsPrintedBadgeModalOpen(false);
  };

  const renderImage = async () => {
    const { template } = badge;
    const pdf = await generate({ template, plugins: { text, image }, inputs: template.sampledata });
    const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
    document.getElementById('finalized').src = URL.createObjectURL(blob);
  };

  const onViewClicked = async () => {
    setIsPrintedBadgeModalOpen(true);
    await renderImage();
  };

  return (
    <div>
      <span title={!badge?.template ? 'No print template found' : 'View Printed Badge'}>
        <ListItem button onClick={onViewClicked} disabled={!badge?.template}>
          <ListItemIcon>
            <FontAwesomeIcon className="fa-regular fa-id-badge" color={colors.primary} />
          </ListItemIcon>
          <ListItemText primary="View Printed Badge" />
        </ListItem>
      </span>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        isOpen={isPrintedBadgeModalOpen}
        title="View Printed badge"
        onClose={onModalClose}
      >
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <iframe id="finalized" style={{ height: 600, width: 400 }}></iframe>
        </div>
      </AirBadgeModal>
    </div>
  );
}
