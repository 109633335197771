import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import appointmentApi from '../../../../../ajax/Appointment/appointmentApi';
import { handleError } from '../../../../../utils';
import { AppContext } from '../../../../../App';
import { StatusBadge } from '../../../Appointments/AppointmentsList/components/DailyViewAppointmentEntry';

function getDateRangeDisplay({ start, end, timezone }) {
  if (start && end) {
    const startMoment = moment.tz(start, timezone);
    const endMoment = moment.tz(end, timezone);
    const startDisplay = startMoment.format('MMM D, YYYY h:mm A');
    const endTime = endMoment.format('h:mm A');
    return `${startDisplay} - ${endTime}`;
  }
  return 'ERROR';
}

let requestTimeout = null;
let apiAbortController = null;

export default function PersonEditAppointments({ person, isOpen }) {
  const app = useContext(AppContext);
  const timezone = app.timezone || 'UTC';
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const loadData = () => {
    if (requestTimeout) clearTimeout(requestTimeout);
    if (apiAbortController) apiAbortController.abort();

    setIsLoading(true);

    requestTimeout = setTimeout(() => {
      setIsLoading(true);
      apiAbortController = new AbortController();

      appointmentApi
        .getDetailsForUser({ userUuid: person.uuid, signal: apiAbortController.signal })
        .then(({ appointments = [] }) => {
          setData(appointments);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  useEffect(() => {
    if (person && isOpen) {
      setIsLoading(false);
      setData([]);
      loadData();
    }

    return () => {
      if (requestTimeout) clearTimeout(requestTimeout);
      if (apiAbortController) apiAbortController.abort();
    };
  }, [person, isOpen]);

  return (
    <Card>
      <CardBody>
        <div className="table-responsive" style={{ maxHeight: 150, minHeight: 150 }}>
          <ReactTable
            manual
            defaultSorted={[{ id: 'started', desc: true }]}
            className="border-0 -striped"
            loading={isLoading}
            data={data}
            columns={[
              {
                id: 'status',
                Header: 'Status',
                accessor: ({ status }) => <StatusBadge status={status} />,
                sortable: false,
                width: 150,
              },
              {
                id: 'appointmentType',
                Header: 'Type',
                accessor: 'appointmentType',
                sortable: false,
              },
              {
                id: 'dateTime',
                Header: 'Date / Time',
                accessor: ({ started, ended }) => getDateRangeDisplay({ start: started, end: ended, timezone }),
                sortable: false,
                width: 275,
              },
            ]}
            pages={1}
            minRows={0}
            defaultPageSize={500}
            showPagination={false}
          />
        </div>
      </CardBody>
    </Card>
  );
}
