import React, { useContext, useEffect, useState } from 'react';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Badge from '@material-ui/core/Badge';
import logo from './airbadge-logo.png';
import { AppContext, colors } from '../../App';
import Feature from '../../shared/Feature';
import BadgeOfficeContactInformation from '../../shared/BadgeOfficeContactInformation/BadgeOfficeContactInformationModal';
import SiteHeaderSearch from '../../shared/Search/SiteHeaderSearch';

export default function DefaultHeader({ state }) {
  const app = useContext(AppContext);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isSignatory, setIsSignatory] = useState(false);
  const [isTrustedAgent, setIsTrustedAgent] = useState(false);
  const [isTsaAgentUser, setIsTsaAgentUser] = useState(false);
  const [isOpsUser, setIsOpsUser] = useState(false);
  const [isASC, setIsASC] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isBadgeOfficeContactInformationOpen, setIsBadgeOfficeContactInformationOpen] = useState(false);

  const user = state.user;
  const isKioskUser = user?.isKiosk;
  const hasProfiles = user?.profiles?.filter(x => x.role.name !== 'badge-holder')?.length;
  const activeProfile = user?.activeProfile;
  const actionItemCount = state.counts?.activeActionItems;
  const activeAuditsCount = state.counts?.activeAuditsCount;
  const activeAuditIssuesCount = state.counts?.activeAuditIssuesCount;

  const showBadgeOfficeContactInformation = () => {
    setIsNavbarOpen(!isNavbarOpen);
    setIsBadgeOfficeContactInformationOpen(true);
  };

  const getDisplayName = profile => {
    if (!profile) {
      const { firstName, lastName } = state.user;
      return `${firstName} ${lastName}`;
    }

    const roleName = getRoleName(profile.role.name);
    const badgeOffice = profile.badge_office ? `at ${profile.badge_office.location_identifier} ` : '';
    return `${profile.user?.first_name} (${badgeOffice}as ${roleName} for ${profile.company})`;
  };

  const setProfile = profile => {
    const user = JSON.parse(localStorage.getItem('AirportBadges.user'));
    user.activeProfile = profile;
    app.api.updateState({ user });
    localStorage.setItem('AirportBadges.user', JSON.stringify(user));
    window.location.reload();
  };

  const getRoleName = role => {
    switch (role) {
      default:
      case 'badge-holder':
        return 'Badge Holder';
      case 'tsa-agent':
        return 'TSI';
      case 'operations':
        return 'Ops';
      case 'authorized-signatory':
        return 'Signatory';
      case 'trusted-agent':
        return 'TA';
      case 'airport-security-coordinator':
        return 'ASC';
      case 'system-admin':
        return 'System Admin';
    }
  };

  const buildProfilesDisplay = () => {
    const user = state.user;
    const profiles = user?.profiles?.filter(x => x.active)?.filter(x => x.role.name !== 'badge-holder');
    const currentActiveProfile = user?.activeProfile?.uuid;

    if (!profiles || !profiles.length) return null;

    return (
      <>
        {profiles.map(x => (
          <DropdownItem key={x.uuid} onClick={() => setProfile(x)}>
            <NavLink href="/#" className="p-0">
              <i
                className={`fa ${currentActiveProfile === x.uuid ? 'fa-user' : 'fa-user-o'} mr-2`}
                style={{ color: currentActiveProfile === x.uuid ? colors.primary : undefined }}
              />
              {getDisplayName(x)}
            </NavLink>
          </DropdownItem>
        ))}
        <DropdownItem header tag="div" style={{ padding: '5px 20px' }}>
          {/*  */}
        </DropdownItem>
      </>
    );
  };

  useEffect(() => {
    const profileName = state.user?.activeProfile?.role?.name;
    setIsSignatory(profileName === 'authorized-signatory');
    setIsTrustedAgent(profileName === 'trusted-agent');
    setIsASC(profileName === 'airport-security-coordinator');
    setIsTsaAgentUser(profileName === 'tsa-agent');
    setIsOpsUser(profileName === 'operations');
    setIsSuperAdmin(!!state.user?.isSuperAdmin);
  }, [state.user]);

  if (isKioskUser) {
    return (
      <Navbar id="main-nav-bar" color="white" light expand="lg" className="shadow-sm">
        <NavbarBrand href="#/default/home">
          <img src={logo} width={50} height={50} alt="AirBadge Logo" className="d-inline-block align-middle mr-1" />
          <div className="d-inline-block align-middle mr-1"></div>
        </NavbarBrand>

        <Nav className="ml-auto" navbar>
          <div style={{ position: 'absolute', top: 2, right: 39 }}>
            <span style={{ opacity: 0.7, fontStyle: 'italic', fontSize: 'smaller' }}>Logged in as:</span>
            <strong style={{ marginLeft: 8 }}>{getDisplayName()}</strong>
          </div>

          <NavLink href="#/default/home">
            <NavItem>
              <i className="fa fa-home mr-2" />
              Home
            </NavItem>
          </NavLink>

          <NavLink href="/#/default/settings">
            <i className="fa fa-cogs mr-2" />
            Account Settings
          </NavLink>

          <NavLink href="/#/logout">
            <i className="fa fa-sign-out mr-2" />
            Log Out
          </NavLink>
        </Nav>
      </Navbar>
    );
  }

  return (
    <>
      <Navbar id="main-nav-bar" color="white" light expand="lg" className="shadow-sm">
        <style scoped>{`
          .MuiBadge-badge {
            color: #ffffff;
          }
        `}</style>

        <NavbarBrand href="#/default/home">
          <img src={logo} width={50} height={50} alt="AirBadge Logo" className="d-inline-block align-middle mr-1" />
          <div className="d-inline-block align-middle mr-1"></div>
        </NavbarBrand>

        {(isTrustedAgent || isASC) && <SiteHeaderSearch />}

        <NavbarToggler onClick={() => setIsNavbarOpen(!isNavbarOpen)} className="rounded-0 p-1" />

        <Collapse isOpen={isNavbarOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavLink href="#/default/home">
              <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                <i className="fa fa-home mr-2" />
                Home
              </NavItem>
            </NavLink>

            {(isSignatory || isOpsUser || isTsaAgentUser) && (
              <NavLink href="/#/default/badges">
                <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <i className="fa-regular fa-id-card mr-2" />
                  Badges
                </NavItem>
              </NavLink>
            )}

            {(isSignatory || isTsaAgentUser) && (
              <NavLink href="/#/default/badge-applications">
                <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <i className="fa fa-file-text-o mr-2" />
                  Badge Applications
                </NavItem>
              </NavLink>
            )}

            {(isTrustedAgent || isASC) && (
              <UncontrolledDropdown hidden={!activeProfile}>
                <DropdownToggle nav caret>
                  <i className="fa-regular fa-id-card mr-2" />
                  Badges
                </DropdownToggle>
                <DropdownMenu end={!isMobile}>
                  <NavLink href="/#/default/badges" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa-regular fa-id-card mr-2" />
                      Badges
                    </DropdownItem>
                  </NavLink>

                  <NavLink href="/#/default/badge-applications" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa fa-file-text-o mr-2" />
                      Applications
                    </DropdownItem>
                  </NavLink>

                  <Feature id="renewals_dashboard">
                    <NavLink href="/#/default/renewals" className="p-0">
                      <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                        <i className="fa-solid fa-arrows-spin mr-2" />
                        Renewals
                      </DropdownItem>
                    </NavLink>
                  </Feature>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            {(isTrustedAgent || isASC) && (
              <UncontrolledDropdown>
                <DropdownToggle nav caret>
                  <i className="fa fa-users mr-2" />
                  People
                </DropdownToggle>
                <DropdownMenu end={!isMobile}>
                  <NavLink href="/#/default/people" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa fa-users mr-2" />
                      People
                    </DropdownItem>
                  </NavLink>

                  <NavLink href="/#/default/authorized-signatories" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa-solid fa-person-military-pointing mr-2"></i>
                      Signatories
                    </DropdownItem>
                  </NavLink>
                  <NavLink href="/#/default/companies" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa-solid fa-city mr-2" />
                      Companies
                    </DropdownItem>
                  </NavLink>

                  <NavLink href="/#/default/keys" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa-solid fa-key mr-2" />
                      Keys
                    </DropdownItem>
                  </NavLink>
                  <NavLink href="/#/default/violations" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa-solid fa-person-circle-exclamation mr-2" />
                      Violations
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            {(isASC || isTrustedAgent) && (
              <NavLink href="/#/default/appointments">
                <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <i className="fa fa-calendar mr-2" />
                  Appointments
                </NavItem>
              </NavLink>
            )}

            {(isTrustedAgent || isASC) && Number(activeAuditsCount) > 0 ? (
              <NavLink href="/#/default/audits/audit-details">
                <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <Badge badgeContent={activeAuditIssuesCount || 0} color="error" overlap="rectangular">
                    <i className="fa fa-list-ol mr-2" style={{ position: 'relative', top: 3 }} />
                    <span>Audit</span>
                  </Badge>
                </NavItem>
              </NavLink>
            ) : null}

            {isSignatory && Number(activeAuditsCount) > 0 ? (
              <NavLink href="/#/default/authorized-signatory-auditing">
                <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <i className="fa fa-calendar-check-o mr-2" />
                  Auditing
                </NavItem>
              </NavLink>
            ) : null}

            <Feature id="messages">
              {!activeProfile ? (
                <NavLink href="#/default/messages-received">
                  <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                    <i className="fa fa-envelope mr-2" />
                    Messages
                  </NavItem>
                </NavLink>
              ) : (
                <UncontrolledDropdown>
                  <DropdownToggle nav caret>
                    <i className="fa fa-envelope mr-2" />
                    Messages
                  </DropdownToggle>
                  <DropdownMenu end={!isMobile}>
                    <NavLink href="/#/default/messages-received" className="p-0">
                      <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                        <i className="fa fa-inbox mr-2" />
                        Received
                      </DropdownItem>
                    </NavLink>

                    <NavLink href="/#/default/messages-sent" className="p-0">
                      <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                        <i className="fa fa-paper-plane mr-2" />
                        Sent
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Feature>

            {(isTrustedAgent || isASC) && (
              <NavLink href="/#/default/action-items">
                <NavItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <Badge badgeContent={actionItemCount || 0} color="error" overlap="rectangular">
                    <i className="fa fa-list-ol mr-2" style={{ position: 'relative', top: 3 }} />
                    <span>Action Items</span>
                  </Badge>
                </NavItem>
              </NavLink>
            )}

            {!isTrustedAgent && !isASC && (
              <UncontrolledDropdown>
                <DropdownToggle nav caret title="Help">
                  <i className="fa-solid fa-question-circle mr-2" />
                  Help
                </DropdownToggle>
                <DropdownMenu end={!isMobile}>
                  <DropdownItem onClick={showBadgeOfficeContactInformation} style={{ borderBottom: 'none' }}>
                    <i className="fa-solid fa-address-book mr-2" />
                    Contact Badge Office
                  </DropdownItem>
                  <NavLink href="https://help.airbadge.us" target="_blank" rel="noopener noreferrer" className="p-0">
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <i className="fa fa-question-circle mr-2" style={{ fontSize: 16 }} />
                      AirBadge Help
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            <UncontrolledDropdown>
              <DropdownToggle className="current-profile" nav caret title={getDisplayName(state.user?.activeProfile)}>
                <i className="fa fa-user-o mr-2" />
                {getDisplayName(state.user?.activeProfile)}
              </DropdownToggle>
              <DropdownMenu end={!isMobile}>
                <DropdownItem header tag="div" hidden={!hasProfiles}>
                  <strong>Profile</strong>
                </DropdownItem>

                {buildProfilesDisplay()}

                {(isTrustedAgent || isASC) && (
                  <>
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <NavLink href="/#/default/reports" className="p-0">
                        <i className="fa fa-bar-chart mr-2" />
                        Reports
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <NavLink href="/#/default/badge-office" className="p-0">
                        <i className="fa fa-wrench mr-2" />
                        Tools & Settings
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem header tag="div" style={{ padding: '5px 20px' }}>
                      {/*  */}
                    </DropdownItem>
                  </>
                )}

                <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <NavLink href="/#/default/settings" className="p-0">
                    <i className="fa fa-cogs mr-2" />
                    Account Settings
                  </NavLink>
                </DropdownItem>

                <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                  <NavLink href="/#/logout" className="p-0">
                    <i className="fa fa-sign-out mr-2" />
                    Log Out
                  </NavLink>
                </DropdownItem>

                {isSuperAdmin ? (
                  <>
                    <DropdownItem header tag="div" style={{ padding: '5px 20px' }}>
                      {/*  */}
                    </DropdownItem>
                    <DropdownItem onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                      <NavLink href="/#/default/app-admin" className="text-danger p-0">
                        <i className="text-danger fa fa-cogs mr-2" />
                        Application Administration
                      </NavLink>
                    </DropdownItem>
                  </>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>

      <BadgeOfficeContactInformation
        isOpen={isBadgeOfficeContactInformationOpen}
        onClose={() => setIsBadgeOfficeContactInformationOpen(false)}
      />
    </>
  );
}
