import request from '../Request';

export default function AuthorizedSignatoryUpdateRequest(user, authorizedSignatory, uuid) {
  const method = 'PUT';

  const url = 'authorized-signatories/' + uuid;

  const data = {
    authorized_signatory: authorizedSignatory,
  };

  return request(user || 'authenticated-user', method, url, data);
}
