import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import moment from 'moment-timezone';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Alert, FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiFormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import { AppContext } from '../../../../App';
import AirBadgeModal, {
  MODAL_SIZE_LARGE,
  MODAL_SIZE_MEDIUM,
} from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import AutoCompleteDropdown, {
  AutoCompleteUserOption,
  filterUserOptions,
} from '../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import peopleApi from '../../../../ajax/People/peopleApi';
import { buildFullName, handleError } from '../../../../utils';
import AuthorizedSignatoryGetRequest from '../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryGetRequest';
import badgeApi from '../../../../ajax/Badge/badgeApi';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import {
  BadgeEntry,
  ColumnWithData,
  CustomBadgeDropdownOption,
  defaultBadgeData,
  parseDates,
  RowWithSpacer,
} from './IssuePacsBadgeModal';
import { nonCollectibleStatuses, reasons } from './BadgeCreateModal';
import BadgesForUserRequest from '../../../../ajax/Badge/BadgesForUserRequest';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import request from '../../../../ajax/Request';
import EscortEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import DrivingEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import { uploadProfilePicForPerson } from '../../../../imageUtils';
import { getDrivingOptions } from '../../../../shared/BadgeTypes/drivingOptionsUtils';

let badgeHoldersRequestTimeout = null;
let badgeHoldersAbortController = null;

let badgesRequestTimeout = null;
let badgesAbortController = null;

export default function ReplacePacsBadgeModal({ isOpen, onClose, badgeTypes, defaultBadgeHolder, originalBadgeType }) {
  const theme = useTheme();
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBadgeHolder, setSelectedBadgeHolder] = useState(null);
  const [isBadgeHoldersLoading, setIsBadgeHoldersLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [individualOptions, setIndividualOptions] = useState([]);
  const [authorizedSignatoryOptions, setAuthorizedSignatoryOptions] = useState([]);
  const [selectedAuthorizedSignatory, setSelectedAuthorizedSignatory] = useState(null);
  const [groups, setGroups] = useState([]);
  const [badges, setBadges] = useState([]);
  const [badgeApplicationWarning, setBadgeApplicationWarning] = useState(null);
  const [unmatchedBadges, setUnmatchedBadges] = useState([]);
  const [newBadge, setNewBadge] = useState({ ...defaultBadgeData });
  const [selectedPacsBadge, setSelectedPacsBadge] = useState(null);
  const [remainingPacsBadges, setRemainingPacsBadges] = useState([]);
  const [hasBadgeToDeactivate, setHasBadgeToDeactivate] = useState(null);
  const [isLoadingBadges, setIsLoadingBadges] = useState(false);
  const [oldBadge, setOldBadge] = useState(null);
  const [deactivationReason, setDeactivationReason] = useState(null);
  const [isMisprintWarningShown, setsMisprintWarningShown] = useState(false);
  const [isCollected, setIsCollected] = useState(false);
  const [collectedDate, setCollectedDate] = useState(moment());
  const [deactivationComment, setDeactivationComment] = useState('');
  const [newGroupManualEntry, setNewGroupManualEntry] = useState('');
  const [isBadgeTypeModalOpen, setIsBadgeTypeModalOpen] = useState(false);
  const [selectedBadgeType, setSelectedBadgeType] = useState(null);

  const badgeTypeOptions = (badgeTypes || []).map(type => {
    return {
      label: type.displayName,
      value: type.badgeTypeID,
      original: type,
    };
  });

  const reset = () => {
    setNewGroupManualEntry('');
    setDeactivationComment('');
    setHasBadgeToDeactivate(null);
    setIsLoadingBadges(false);
    setOldBadge(null);
    setDeactivationReason(null);
    setsMisprintWarningShown(false);
    setIsCollected(false);
    setCollectedDate(moment());
    setSelectedPacsBadge(null);
    setIsLoadingData(false);
    setBadgeApplicationWarning(null);
    setBadges([]);
    setUnmatchedBadges([]);
    setRemainingPacsBadges([]);
    setNewBadge({ ...defaultBadgeData });
  };

  const onModalClose = ({ shouldReload = false }) => {
    reset();
    setSelectedAuthorizedSignatory(null);
    setAuthorizedSignatoryOptions([]);
    setIndividualOptions([]);
    setIsBadgeHoldersLoading(false);
    setSelectedBadgeHolder(null);
    setGroups([]);
    setIsModalOpen(false);
    onClose(shouldReload);
  };

  const isCollectibleStatus = () => {
    return deactivationReason && !nonCollectibleStatuses.includes(deactivationReason.value);
  };

  const findBadgeHolders = event => {
    const fullName = event?.target?.value;
    if (badgeHoldersRequestTimeout) {
      if (badgeHoldersAbortController) badgeHoldersAbortController.abort();
      clearTimeout(badgeHoldersRequestTimeout);
    }

    if (!fullName || fullName.length < 3) {
      setIsBadgeHoldersLoading(false);
      return;
    }

    setIndividualOptions([]);
    setIsBadgeHoldersLoading(true);

    badgeHoldersRequestTimeout = setTimeout(() => {
      badgeHoldersAbortController = new AbortController();
      peopleApi
        .find({ fullName, withUserId: true }, badgeHoldersAbortController.signal)
        .then(({ success, matches }) => {
          if (success)
            setIndividualOptions(
              matches.map(m => ({
                name: buildFullName(m),
                value: m.userId,
                uuid: m.uuid,
                pacsPersonId: m.pacs_person_id || m.pacsPersonId,
                tscPersonId: m.tsc_person_id || m.tscPersonId,
                full_name: buildFullName(m),
                original: m,
              }))
            );
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsBadgeHoldersLoading(false));
    }, 500);
  };

  const loadData = () => {
    Promise.all([AuthorizedSignatoryGetRequest(), badgeApi.getGroups()])
      .then(([authorizedSignatoriesResponse, groupsResponse]) => {
        const signatories = authorizedSignatoriesResponse
          .filter(x => x.active)
          .map(authorizedSignatory => {
            const { user, uuid, companyName, profile_id: profileId } = authorizedSignatory;
            const { first_name, last_name } = user;
            return {
              label: `${first_name} ${last_name}, ${companyName}`,
              value: uuid,
              uuid,
              profileId,
            };
          });
        setAuthorizedSignatoryOptions(signatories);

        setGroups(groupsResponse.results.map(r => ({ label: r, value: r })));
      })
      .catch(error => handleError({ error }))
      .finally();
  };

  const processApiResponseForUnmatchedBadges = ({ unmatchedBadges: badges }) => {
    badges = badges.map(b => ({
      ...b,
      badgeType: badgeTypes.find(({ badgeTypeID }) => badgeTypeID === get(b, 'type.badgeTypeID')),
      effectiveDates: parseDates(b, app.timezone),
    }));

    setUnmatchedBadges(badges.map(b => ({ label: b.number, value: b.pacsBadgeId, original: b })));

    if (badges.length === 1) {
      const match = badges[0];
      if (originalBadgeType) {
        const type = badgeTypes.find(({ uuid }) => uuid === originalBadgeType?.uuid);
        setNewBadge({ ...defaultBadgeData, ...match, badgeType: type });
      } else {
        onBadgeSelectedFromQueue({ badge: match, pacsBadgeId: match.pacsBadgeId });
      }
    }
  };

  const onSelectBadgeHolder = badgeHolder => {
    if (badgesRequestTimeout) {
      if (badgesAbortController) badgesAbortController.abort();
      clearTimeout(badgesRequestTimeout);
    }

    setSelectedBadgeHolder(badgeHolder);
    reset();
    if (!badgeHolder) {
      setBadges([]);
      return false;
    }

    if (app.airport.isPacsEnabled && !badgeHolder.pacsPersonId) {
      app.api.confirmMessage(
        'PACS Notice',
        <Alert color="warning">
          <strong>{badgeHolder.full_name} </strong>
          <span>
            has not been reconciled with PACS. A badge cannot be issued to this person until the reconciliation is
            complete.
          </span>
        </Alert>,
        false,
        false
      );
    }

    setBadgeApplicationWarning(null);
    setBadges([]);

    setIsLoadingData(true);
    badgesRequestTimeout = setTimeout(() => {
      badgesAbortController = new AbortController();
      Promise.all([
        BadgesForUserRequest(null, { userUuid: badgeHolder.uuid }, badgesAbortController.signal),
        badgeApplicationApi.getAllBadgeApplicationsForUser({
          userUuid: badgeHolder.uuid,
          signal: badgesAbortController.signal,
        }),
        request('authenticated-user', 'GET', `pacs/unmatched-badges/${badgeHolder.tscPersonId}`),
      ])
        .then(([badges, badgeApplications, unmatchedBadgesResponse]) => {
          if (badges && badges.length) {
            setBadges(
              badges
                .filter(x => x.active)
                .map(x => {
                  const status = app.badgeStatuses.find(s => s.value === x.status) || { label: 'unknown' };
                  return { label: `${x.number} (${x.type.toLocaleUpperCase()}) (${status.label})`, value: x.uuid };
                })
            );
          }

          if (badgeApplications && badgeApplications.length) {
            const noun =
              badgeApplications.length === 1 ? 'a badge application' : `${badgeApplications.length} badge applications`;
            setBadgeApplicationWarning(`This badge holder has ${noun} in progress`);
          }

          if (unmatchedBadgesResponse.success) processApiResponseForUnmatchedBadges(unmatchedBadgesResponse);
        })
        .catch(error => handleError({ error }))
        .finally(() => {
          setIsLoadingData(false);
        });
    }, 500);
  };

  const onIssueBadge = () => {
    const reason = deactivationReason ? deactivationReason.value.toLocaleLowerCase() : null;
    const collected =
      isCollected && collectedDate ? moment.tz(collectedDate, app.timezone).format('YYYY-MM-DD HH:mm:ss') : null;
    const data = {
      isActive: true,
      badgeHolderUuid: selectedBadgeHolder ? selectedBadgeHolder.uuid : null,
      signatoryUuid: selectedAuthorizedSignatory ? selectedAuthorizedSignatory.uuid : null,
      signatoryProfileId: selectedAuthorizedSignatory ? selectedAuthorizedSignatory.profileId : null,
      pacsBadgeId: newBadge.pacsBadgeId,
      badgeTypeUuid: newBadge.badgeType?.uuid || newBadge.type?.uuid || null,
      group: newBadge.group ? newBadge.group.value : newGroupManualEntry || null,
      parking: 0,
      driving: newBadge.driving.value,
      deactivatedBadge:
        hasBadgeToDeactivate === 'yes'
          ? {
              uuid: oldBadge ? oldBadge.value : null,
              reason,
              collected,
              comment: deactivationComment,
            }
          : null,
      accessControlBadgesToCleanup: remainingPacsBadges.map(b => ({
        pacsBadgeId: b.pacsBadgeId,
        badgeTypeUuid: b.badgeType?.uuid || b.type?.uuid || null,
        resolution: b.resolution,
      })),
    };

    app.api.toggleLoading(true);
    badgeApi
      .issuePacsBadge(data)
      .then(({ success, headshotImageData }) => {
        if (!success) return;
        if (headshotImageData) {
          return uploadProfilePicForPerson({
            userUuid: data.badgeHolderUuid,
            imageData: headshotImageData,
          }).finally(() => onModalClose({ shouldReload: true }));
        } else {
          onModalClose({ shouldReload: true });
        }
      })
      .catch(error =>
        handleError({
          error,
          message: 'Unable to issue badge',
        })
      )
      .finally(() => app.api.toggleLoading(false));
  };

  const onBadgeTypeModalClose = () => {
    setIsBadgeTypeModalOpen(false);
  };

  const onBadgeSelectedFromQueue = ({ badge, pacsBadgeId }) => {
    setSelectedPacsBadge(badge);
    if (badge?.badgeType?.displayName) {
      setRemainingPacsBadges(
        unmatchedBadges
          .filter(b => b.value !== pacsBadgeId)
          .map(b => ({
            ...b.original,
            resolved: false,
            resolution: null,
          }))
      );
      setNewBadge({ ...defaultBadgeData, ...badge });
    } else {
      setSelectedBadgeType(null);
      setIsBadgeTypeModalOpen(true);
    }
  };

  const isSaveDisabled = () => {
    const { badgeType, cardSerialNumber, number, effective, expires, driving } = newBadge;
    if (!badgeType || !cardSerialNumber || !number || !effective || !expires || !driving) {
      return true;
    }
    if (!selectedBadgeHolder || !selectedAuthorizedSignatory) {
      return true;
    }
    if (!hasBadgeToDeactivate || (hasBadgeToDeactivate === 'yes' && !oldBadge)) {
      return true;
    }
    if (oldBadge && !deactivationReason) {
      return true;
    }
    if (remainingPacsBadges.find(b => b.resolved === false)) {
      return true;
    }
    return false;
  };

  const getDrivingOption = () => {
    const drivingOptions = getDrivingOptions(newBadge?.badgeType?.config?.endorsements?.driving);
    return drivingOptions.find(option => option.value === newBadge?.driving) || 'ERROR';
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(true);
      loadData();

      if (defaultBadgeHolder?.uuid) {
        onSelectBadgeHolder({ ...defaultBadgeHolder });
      }
    }
  }, [isOpen, defaultBadgeHolder?.uuid]);

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Replace Badge"
      isOpen={isModalOpen}
      onClose={onModalClose}
      closeLabel="Cancel"
      onSave={onIssueBadge}
      saveLabel="Replace Badge"
      saveDisabled={isSaveDisabled()}
    >
      <Grid container spacing={4}>
        <Grid item sm={12} md={6}>
          <FormGroup>
            <Label className="required">Badge Holder</Label>
            <AutoCompleteDropdown
              id="badge-holders"
              value={selectedBadgeHolder}
              onValueSelected={onSelectBadgeHolder}
              onInputChange={findBadgeHolders}
              options={individualOptions}
              isLoading={isBadgeHoldersLoading}
              placeholder="Search by first or last name"
              size="small"
              filterOptions={filterUserOptions}
              renderOption={user => <AutoCompleteUserOption user={user} />}
              disabled={!!defaultBadgeHolder?.uuid}
            />
          </FormGroup>
        </Grid>
        <Grid item sm={12} md={6}>
          <FormGroup>
            <Label className="required">Authorized Signatory</Label>
            <Select
              classNamePrefix="airbadge"
              options={authorizedSignatoryOptions}
              className="form-select"
              value={selectedAuthorizedSignatory}
              placeholder=""
              onChange={setSelectedAuthorizedSignatory}
              isDisabled={isLoadingData}
            />
          </FormGroup>
        </Grid>
      </Grid>

      {!!badgeApplicationWarning && <Alert color="danger">{badgeApplicationWarning}</Alert>}

      {selectedBadgeHolder && !isLoadingData && unmatchedBadges.length === 0 && (
        <Alert color="warning" style={{ marginTop: 16 }}>
          No badges found waiting in the queue. The badge needs to be first created in access control before the badge
          can be issued in AirBadge. Make sure you wait at least 90 seconds after creating the badge in access control
          before continuing the process here in AirBadge.
        </Alert>
      )}

      {isLoadingData && (
        <Alert color="info" style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress color="inherit" size={30} />
          <span className="ml-2">Checking PACS queue for unreconciled badges</span>
        </Alert>
      )}

      {unmatchedBadges.length > 1 && !selectedPacsBadge && (
        <div>
          <Alert color="info" style={{ marginBottom: 16, marginTop: 16 }}>
            <i>{unmatchedBadges.length} access control badges were found waiting in the queue.</i>
          </Alert>
          <FormGroup>
            <Label>Which badge should be issued?</Label>
            <Select
              classNamePrefix="airbadge"
              name="pacsBadge"
              options={unmatchedBadges}
              value={selectedPacsBadge}
              onChange={entry => onBadgeSelectedFromQueue({ badge: entry.original, pacsBadgeId: entry.value })}
              className="form-select"
              placeholder=""
              components={{ Option: CustomBadgeDropdownOption }}
            />
          </FormGroup>
        </div>
      )}

      {newBadge.pacsBadgeId && (
        <>
          <Alert color="info" fade={false} style={{ padding: '14px' }}>
            If any of the data below is incorrect, it will need to be corrected in access control before this badge can
            be issued
          </Alert>
          <RowWithSpacer>
            <ColumnWithData>
              <ReadOnlyFormData
                skipTextTransform
                label="Badge Type"
                value={newBadge?.badgeType?.label || newBadge?.badgeType?.displayName || 'Not Set'}
              />
            </ColumnWithData>
            <ColumnWithData>
              <ReadOnlyFormData skipTextTransform label="Effective Dates" value={newBadge.effectiveDates} />
            </ColumnWithData>
          </RowWithSpacer>
          <RowWithSpacer>
            <ColumnWithData>
              <ReadOnlyFormData label="Badge ID" value={newBadge.number} />
            </ColumnWithData>
            <ColumnWithData>
              <ReadOnlyFormData label="Card Number" value={newBadge.cardSerialNumber} />
            </ColumnWithData>
          </RowWithSpacer>
          <RowWithSpacer>
            <ColumnWithData>
              <EscortEndorsementPicker
                isReadOnly={true}
                context={{
                  escortEndorsementConfig: newBadge?.badgeType?.config?.endorsements?.escort,
                  escortSelection: newBadge.isEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
                  setEscortSelection: () => false,
                  shouldSkipDefaultSelection: true,
                  data: { badgeType: newBadge?.badgeType },
                }}
              />
            </ColumnWithData>
            <ColumnWithData>
              <ReadOnlyFormData label="Law Enforcement Officer (LEO)" value={newBadge.isLEO ? 'Yes' : 'No'} />
            </ColumnWithData>
          </RowWithSpacer>
          <Grid container spacing={4}>
            <ColumnWithData>
              <FormGroup>
                <DrivingEndorsementPicker
                  context={{
                    drivingEndorsementConfig: newBadge?.badgeType?.config?.endorsements?.driving,
                    drivingSelection: getDrivingOption(),
                    setDrivingSelection: option => {
                      setNewBadge({ ...newBadge, driving: option });
                    },
                    shouldSkipDefaultSelection: true,
                    data: { badgeType: newBadge?.badgeType },
                  }}
                />
              </FormGroup>
            </ColumnWithData>
          </Grid>
          <RowWithSpacer>
            <Grid item xs={12}>
              <FormGroup>
                <Label>
                  Group <i style={{ fontSize: 'smaller' }}>(optional)</i>
                </Label>
                <AutoCompleteDropdown
                  id="newGroup"
                  value={newBadge.group}
                  onValueSelected={option => setNewBadge({ ...newBadge, group: option })}
                  options={groups}
                  placeholder=""
                  size="small"
                  getOptionLabel={option => option.label || 'error'}
                  onInputChange={event => setNewGroupManualEntry(event?.target?.value)}
                  freeSolo
                  disableClearable
                />
              </FormGroup>
            </Grid>
          </RowWithSpacer>

          <div className="separator" style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}>
            Deactivation
          </div>

          <Grid container spacing={1}>
            <Grid item>
              Does <strong>{selectedBadgeHolder.full_name || 'ERROR'}</strong> have an active badge that needs to be
              deactivated?
            </Grid>
          </Grid>

          <RowWithSpacer>
            <ColumnWithData>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="hasBadgeToDeactivate"
                  name="hasBadgeToDeactivate"
                  value={hasBadgeToDeactivate}
                  onChange={({ target: { value } }) => setHasBadgeToDeactivate(value)}
                >
                  <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </FormControl>
            </ColumnWithData>
          </RowWithSpacer>

          {hasBadgeToDeactivate === 'yes' && (
            <>
              <Grid container spacing={4}>
                <ColumnWithData>
                  <FormGroup>
                    <Label className="required">Badge to be deactivated</Label>
                    <Select
                      classNamePrefix="airbadge"
                      className="form-select"
                      options={badges}
                      value={oldBadge}
                      placeholder=""
                      isClearable
                      isDisabled={hasBadgeToDeactivate !== 'yes'}
                      isLoading={isLoadingBadges}
                      noOptionsMessage={() => 'No active badges to deactivate'}
                      onChange={value => setOldBadge(value)}
                    />
                  </FormGroup>
                </ColumnWithData>
                {oldBadge && (
                  <ColumnWithData xs="12" sm="6">
                    <FormGroup>
                      <Label className="required">Deactivation Reason</Label>
                      <Select
                        classNamePrefix="airbadge"
                        options={reasons}
                        className="form-select"
                        value={deactivationReason}
                        placeholder=""
                        isDisabled={!oldBadge}
                        onChange={x => {
                          setDeactivationReason(x);
                          setsMisprintWarningShown(false);
                          setIsCollected(!nonCollectibleStatuses.includes(x.value));
                        }}
                      />
                    </FormGroup>
                  </ColumnWithData>
                )}
              </Grid>
              {isCollectibleStatus() && oldBadge && (
                <Grid
                  container
                  spacing={4}
                  style={!isCollected && oldBadge ? { marginBottom: theme.spacing(1) } : null}
                >
                  <ColumnWithData>
                    <MuiFormGroup row>
                      <FormControlLabel
                        control={<Switch color="primary" />}
                        label={isCollected ? 'Badge was collected' : 'Badge was not collected'}
                        checked={isCollected}
                        disabled={!oldBadge}
                        onChange={({ target: { checked } }) => {
                          setIsCollected(checked);
                          setsMisprintWarningShown(!checked && deactivationReason.label === 'Misprint');
                        }}
                      />
                    </MuiFormGroup>
                  </ColumnWithData>
                  {isCollected && oldBadge && (
                    <ColumnWithData>
                      <FormGroup>
                        <Label className="required">Collected Date</Label>
                        <DatetimePickerTrigger
                          className="collectedDate-trigger"
                          moment={collectedDate}
                          showTimePicker={false}
                          onChange={moment => setCollectedDate(moment)}
                        >
                          <Input type="text" id="collectedDate" value={collectedDate.format('MM/DD/YYYY')} readOnly />
                        </DatetimePickerTrigger>
                      </FormGroup>
                    </ColumnWithData>
                  )}
                </Grid>
              )}
              {isMisprintWarningShown && (
                <RowWithSpacer>
                  <Grid item>
                    <Alert color="warning" fade={false}>
                      If this badge was issued, select a different status to represent why it is being deactivated.
                    </Alert>
                  </Grid>
                </RowWithSpacer>
              )}
              {oldBadge && (
                <RowWithSpacer>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Label>
                        Deactivation Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
                      </Label>
                      <Input
                        id="deactivationComment"
                        required
                        disabled={!oldBadge}
                        type="textarea"
                        value={deactivationComment}
                        onChange={({ target: { value } }) => setDeactivationComment(value)}
                      />
                    </FormGroup>
                  </Grid>
                </RowWithSpacer>
              )}
            </>
          )}
        </>
      )}

      {!!remainingPacsBadges.length && (
        <>
          <div
            className="separator"
            style={{ marginBottom: theme.spacing(2), marginTop: hasBadgeToDeactivate === 'yes' && theme.spacing(2) }}
          >
            Other Access Control Badges
          </div>

          <p>
            What should be done with the other access control badge{`${remainingPacsBadges.length > 1 ? 's' : ''}`} in
            the queue?
          </p>

          <Grid container direction="column">
            <style scoped>{`
              .pacs-resolution-label {
                font-size: 0.9rem;
              }
            `}</style>
            {remainingPacsBadges.map((badge, index) => (
              <Grid
                key={badge.pacsBadgeId}
                item
                style={{
                  borderBottom: remainingPacsBadges.length !== 1 && '1px dashed black',
                  marginBottom: theme.spacing(2),
                }}
              >
                <BadgeEntry badge={badge} />
                <FormControl>
                  <RadioGroup
                    aria-label="PACS badge resolution"
                    name="resolution"
                    value={badge.resolution}
                    onChange={({ target: { value } }) => {
                      badge.resolved = true;
                      badge.resolution = value;
                      remainingPacsBadges.splice(index, 1, badge);
                      setRemainingPacsBadges([...remainingPacsBadges]);
                    }}
                  >
                    <FormControlLabel
                      value="not_printed"
                      control={<Radio color="primary" size="small" />}
                      label="Not printed"
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                    <FormControlLabel
                      value="misprint_collected"
                      control={<Radio color="primary" size="small" />}
                      label={
                        <div>
                          Misprint <i style={{ fontSize: 'smaller' }}>(not issued or collected)</i>
                        </div>
                      }
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                    <FormControlLabel
                      value="misprint_not_collected"
                      control={<Radio color="primary" size="small" />}
                      label={
                        <div>
                          Misprint <i style={{ fontSize: 'smaller' }}>(issued and not collected)</i>
                        </div>
                      }
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                    <FormControlLabel
                      value="not_related"
                      control={<Radio color="primary" size="small" />}
                      label={
                        <div>
                          Not related to this badge <i style={{ fontSize: 'smaller' }}>(very uncommon)</i>
                        </div>
                      }
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Select a Badge Type"
        isOpen={isBadgeTypeModalOpen}
        onClose={onBadgeTypeModalClose}
        saveLabel="Proceed"
        saveDisabled={!selectedBadgeType}
        onSave={() => {
          const updatedPacsBadgeData = { ...selectedPacsBadge, badgeType: selectedBadgeType.original };
          const remainingBadges = unmatchedBadges
            .filter(b => b.value !== updatedPacsBadgeData.pacsBadgeId)
            .map(b => ({
              ...b.original,
              badgeType: b.original.badgeType || selectedBadgeType.original || null,
              type: b.original.badgeType || selectedBadgeType.original || null,
              resolved: false,
              resolution: null,
            }));
          setSelectedPacsBadge(updatedPacsBadgeData);
          setRemainingPacsBadges(remainingBadges);
          setNewBadge({ ...defaultBadgeData, ...updatedPacsBadgeData });
          setIsBadgeTypeModalOpen(false);
        }}
      >
        <Alert color="warning">Unable to determine Badge Type from PACS data</Alert>
        <Label>Which Badge Type is being issued?</Label>
        <Select
          placeholder=""
          classNamePrefix="airbadge"
          className="form-select"
          options={badgeTypeOptions}
          value={selectedBadgeType}
          onChange={setSelectedBadgeType}
        />
      </AirBadgeModal>
    </AirBadgeModal>
  );
}
