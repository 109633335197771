import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import BadgeCommentCreateRequest from '../../../../ajax/BadgeComment/BadgeCommentCreateRequest';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import { handleError } from '../../../../utils';

const dateFormat = 'MM/DD/YYYY h:mm a';

export default function BadgeComments({ user, badge, appFunctions, appState }) {
  const [existingComments, setExistingComments] = useState([]);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [newComment, setNewComment] = useState('');
  const timezone = appState.timezone;

  const onCommentModalClose = () => {
    setIsCommentModalOpen(false);
    setNewComment('');
  };

  const saveComment = async () => {
    appFunctions.toggleLoading(true);
    BadgeCommentCreateRequest(user, {
      badge_id: badge.id,
      author: `${user.firstName} ${user.lastName}`,
      content: newComment,
    })
      .then(response => {
        setExistingComments([response, ...existingComments]);
        onCommentModalClose();
      })
      .catch(error => handleError({ error, message: 'Unable to add comment' }))
      .finally(() => appFunctions.toggleLoading(false));
  };

  useEffect(() => {
    if (badge && badge.comments && badge.comments.length) {
      const reversed = badge.comments.reverse();
      setExistingComments(reversed);
    }
  }, [badge]);

  // noinspection JSXUnresolvedComponent
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Button style={{ width: 120 }} color="primary" onClick={() => setIsCommentModalOpen(true)} size="sm">
          Add Comment
        </Button>
      </div>

      <Card>
        <CardBody>
          <div id="commentsContainer" className="comments-container" style={{ maxHeight: 264, minHeight: 264 }}>
            {existingComments.map(x => (
              <div key={x.uuid}>
                <div>
                  <small className="text-muted">
                    <strong className="mr-2">{x.author}</strong>
                    {moment.tz(x.created, timezone).format(dateFormat)}
                  </small>
                </div>
                <p>
                  {x.content?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      <span>{line}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>

      <Modal isOpen={isCommentModalOpen} toggle={onCommentModalClose} size="md">
        <CustomModalHeader toggle={onCommentModalClose}>Add Badge Comment</CustomModalHeader>
        <ModalBody>
          <Input required type="textarea" value={newComment} onChange={event => setNewComment(event.target.value)} />
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Cancel"
            onClose={onCommentModalClose}
            saveLabel="Save"
            onSave={saveComment}
            saveDisabled={!newComment}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
