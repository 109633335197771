import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col, FormGroup, Input } from 'reactstrap';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';

export default function PersonEditTscAssociationModal({ isOpen, onClose, onSave }) {
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    setNewValue('');
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Modal size="md" isOpen={isOpen} toggle={onClose}>
      <CustomModalHeader toggle={onClose}>Add Airport Person ID Association</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>Enter the correct Airport Person ID for this person.</p>

            <p>
              A wrong ID association will result in failed background checks and the inability to complete badge
              applications for this person.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input value={newValue} onChange={({ target }) => setNewValue(target.value)} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Close"
          onClose={onClose}
          onSave={() => onSave({ newValue })}
          saveDisabled={!newValue}
        />
      </ModalFooter>
    </Modal>
  );
}
