import React, { useCallback, useContext, useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext, colors } from '../../../../../../App';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import FileUploadForm from '../../../../../../shared/components/FileUploadModal/FileUploadForm';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';

export default function UploadFingerprintsAction() {
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const { applicationDocument, fingerprintAppointment, adjudicateCHRC, approveBackgroundCheckResults } =
    badgeApplication.config;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [comment, setComment] = useState('');

  const isHidden = useCallback(() => {
    if (!fingerprintAppointment.isEnabled) {
      // This badge application workflow doesn't require fingerprints
      return true;
    }
    if (applicationDocument.isEnabled && !applicationDocument.isComplete) {
      // Application document needs to be done first
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for fingerprints
      return true;
    }
    if (adjudicateCHRC.isEnabled && adjudicateCHRC.isComplete) {
      // Background check is done so there is no longer a need for fingerprints
      return true;
    }
    return false;
  }, [applicationDocument, isCurrentUserSignatory, adjudicateCHRC, approveBackgroundCheckResults]);

  const onModalClose = () => {
    setIsModalOpen(false);
    setComment('');
    setFiles([]);
  };

  const addFiles = files => {
    setFiles(files);
  };

  const isSaveDisabled = () => {
    return !files.length || !comment;
  };

  const onSave = () => {
    const file = files[0];
    const { api } = app;
    api.toggleLoading(true);

    return badgeApplicationApi
      .uploadFingerprints({
        badgeApplicationUuid: badgeApplication.uuid,
        comment,
        file,
      })
      .then(({ success }) => {
        if (success) {
          api.displayMessage('Fingerprints file successfully uploaded', 'success');
          onModalClose();
          onActionDone({ shouldReload: true });
        }
      })
      .catch(e => {
        console.error(e);
        api.displayMessage('Unable to upload fingerprints file', 'warning');
      })
      .finally(() => api.toggleLoading(false));
  };

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <FontAwesomeIcon className="fa fa-cloud-upload" color={colors.primary} />
        </ListItemIcon>
        <ListItemText primary="Upload Fingerprints (manual)" />
      </ListItem>

      <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
        <CustomModalHeader toggle={onModalClose}>Upload Fingerprints File</CustomModalHeader>
        <ModalBody>
          <div style={{ marginBottom: 24, textAlign: 'center', fontWeight: 500 }}>
            <i className="fa fa-exclamation-triangle text-warning" style={{ marginRight: 5 }}></i>
            <span>WARNING!</span> <div>Only use this when pre-enrollment is unavailable</div>
          </div>
          <div style={{ marginBottom: 16 }}>
            <FileUploadForm
              onFilesAdded={addFiles}
              fileUploadOnly
              allowedFileTypes={{ 'application/octet-stream': ['.eft'] }}
            />
          </div>

          <FormGroup>
            <Label>
              <div className="required">Comment</div>
              <div style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
                Provide a comment explaining why the fingerprints needed to be manually uploaded
              </div>
            </Label>
            <Input required type="textarea" value={comment} onChange={event => setComment(event.target.value)} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Cancel"
            onClose={onModalClose}
            saveLabel="Upload"
            onSave={onSave}
            saveDisabled={isSaveDisabled()}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
