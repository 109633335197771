import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import countries from './ncic_codes';
import states from './states';
import { trim } from './badgerUtils';
import { InvalidCharacterMessage } from './MailingAddressQuestion';

export default function PlaceOfBirthQuestion({ isHidden, value, onBack, onNext, usaBorn }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState({ ...value });
  const [isStateShown, setIsStateShown] = useState(false);
  const [isCityShown, setIsCityShown] = useState(false);
  const [cityHasError, setCityHasError] = useState(false);

  const isNextButtonHidden = () => {
    if (cityHasError || !state.country || !state.country.value) return true;

    if (state.country.value === 'USA' || state.country.threeCharacterCode === 'USA') {
      return !state.state || !state.state.value;
    } else {
      return !state.city;
    }
  };

  const cleanupData = () => {
    const newState = { ...state };
    newState.city = trim(newState.city);
    if (newState.country.threeCharacterCode === 'USA') {
      newState.ncic = state.state.value;
    } else {
      newState.ncic = newState.country.twoCharacterCode;
    }
    setState(newState);
    return newState;
  };

  useEffect(() => {
    setCityHasError(false);
    if (usaBorn === true) {
      setState({
        ...state,
        country: {
          label: 'United States of America',
          twoCharacterCode: 'US',
          threeCharacterCode: 'USA',
          value: 'US',
        },
      });
      setIsStateShown(true);
      setIsCityShown(false);
    } else if (!isHidden && state?.country) {
      setIsStateShown(state?.country?.threeCharacterCode === 'USA');
      setIsCityShown(state?.country?.threeCharacterCode !== 'USA');
    }
  }, [usaBorn, isHidden]);

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col xs="12" sm="4">
          <FormLabel>
            <Typography variant="h6">{t('question8')}</Typography>
          </FormLabel>
          <FormGroup>
            <Select
              placeholder=""
              classNamePrefix="airbadge"
              className="form-select"
              options={countries.map(c => ({ ...c, value: c.twoCharacterCode }))}
              value={state.country}
              onChange={option => {
                const newData = { ...state, country: option };
                if (option.threeCharacterCode === 'USA') {
                  setIsStateShown(true);
                  setIsCityShown(false);
                  newData.city = null;
                } else {
                  setIsStateShown(false);
                  setIsCityShown(true);
                  newData.state = null;
                }
                setState(newData);
              }}
            />
          </FormGroup>
        </Col>
      </Row>

      {isStateShown && (
        <Row>
          <Col xs="12" sm="4">
            <FormLabel>
              <Typography variant="subtitle1">{t('question8_part2')}</Typography>
            </FormLabel>
            <FormGroup>
              <Select
                placeholder=""
                classNamePrefix="airbadge"
                className="form-select"
                options={states}
                value={state.state}
                onChange={option => setState({ ...state, state: option })}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      {isCityShown && (
        <Row>
          <Col xs="12" sm="4">
            <FormLabel>
              <Typography variant="subtitle1">{t('question8_part3')}</Typography>
            </FormLabel>
            <FormGroup>
              <Input
                value={state.city || ''}
                onChange={event => {
                  const city = event?.target?.value;
                  if (!city) {
                    setCityHasError(false);
                    setState({ ...state, city });
                  } else {
                    const validCharactersRegex = /^[a-zA-Z- ]+$/;
                    setCityHasError(!validCharactersRegex.test(city));
                    setState({ ...state, city });
                  }
                }}
              />
              {cityHasError && <InvalidCharacterMessage />}
            </FormGroup>
          </Col>
        </Row>
      )}

      <BackButton onClick={onBack} />
      <NextButton isDisabled={isNextButtonHidden()} onClick={() => onNext(cleanupData())} />
    </>
  );
}
