import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import violationsApi from '../../../ajax/Violations/violationsApi';
import { handleError } from '../../../utils';
import ViolationDetailsModal from './ViolationDetailsModal';

export default function ViolationsLimitedListing({ isOpen, company, person }) {
  const [isLoading, setIsLoading] = useState(false);
  const [violations, setViolations] = useState([]);
  const [isViolationDetailsModalOpen, setIsViolationDetailsModalOpen] = useState(false);
  const [selectedViolationUuid, setSelectedViolationUuid] = useState(null);

  const columns = [
    {
      id: 'violationDate',
      Header: 'Date',
      accessor: 'violationDate',
      filterable: false,
      sortable: false,
      width: 125,
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: ({ status }) => <span style={{ textTransform: 'capitalize' }}>{status}</span>,
      filterable: false,
      sortable: false,
      width: 110,
    },
    {
      id: 'violationType',
      Header: 'Type',
      accessor: ({ uuid, violationType }) => (
        <button
          title={violationType}
          className="btn btn-link"
          style={{ margin: 0, padding: 0 }}
          onClick={() => onViewDetailsClick(uuid)}
        >
          {violationType}
        </button>
      ),
      filterable: false,
      sortable: false,
    },
  ];

  const loadViolations = () => {
    const filtered = [];
    if (company) {
      filtered.push({ id: 'relatedCompanyUuid', value: company.uuid });
    } else if (person) {
      filtered.push({ id: 'relatedUserUuid', value: person.uuid });
    }
    setIsLoading(true);
    return violationsApi
      .getAllViolations({
        data: {
          filtered,
          pageSize: 100,
          page: 0,
          sorted: [{ id: 'violationDate', desc: true }],
        },
      })
      .then(({ results }) => {
        setViolations(results);
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsLoading(false));
  };

  const onViewDetailsClick = violationUuid => {
    setSelectedViolationUuid(violationUuid);
    setIsViolationDetailsModalOpen(true);
  };

  const onViolationsDetailsModalClose = () => {
    setIsViolationDetailsModalOpen(false);
    setSelectedViolationUuid(null);
  };

  useEffect(() => {
    if (!isOpen) {
      setViolations([]);
      setIsLoading(false);
      setIsViolationDetailsModalOpen(false);
      setSelectedViolationUuid(null);
    }

    if (isOpen && (company || person)) {
      loadViolations();
    }
  }, [isOpen, company, person]);

  if (!isOpen && !company) return null;

  if (isLoading) {
    return (
      <Card>
        <CardBody>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <CircularProgress size={30} />
            <div className="ml-4">Loading violations</div>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <div className="table-responsive" style={{ maxHeight: 150, minHeight: 150 }}>
          <ReactTable
            manual
            defaultSorted={[{ id: 'violationDate', desc: true }]}
            className="border-0 -striped"
            data={violations}
            columns={columns}
            pages={1}
            minRows={0}
            defaultPageSize={100}
            showPagination={false}
            noDataText="No violations"
          />
        </div>
      </CardBody>

      <ViolationDetailsModal
        isOpen={isViolationDetailsModalOpen}
        onClose={onViolationsDetailsModalClose}
        violationUuid={selectedViolationUuid}
      />
    </Card>
  );
}
