import React, { useContext, useEffect, useState } from 'react';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment';
import Select from 'react-select';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AppContext } from '../../../../../../App';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import request from '../../../../../../ajax/Request';
import { handleError } from '../../../../../../utils';

const reasons = [
  { label: 'Terminated Employment', value: 'Terminated' },
  { label: 'Expired', value: 'Expired' },
  { label: 'Surrendered', value: 'Surrendered' },
  { label: 'Lost', value: 'Lost' },
  { label: 'Stolen', value: 'Stolen' },
  { label: 'Unaccounted for', value: 'Unaccounted For' },
  { label: 'Destroyed', value: 'Destroyed' },
  { label: 'Suspended', value: 'Suspended' },
  { label: 'Misprint', value: 'Misprint' },
  { label: 'Reactivated Other', value: 'Reactivated' },
];

export default function ReceiveBadgeModal({ isOpen, onClose, onSave, badge }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [collectedDate, setCollectedDate] = useState(moment());
  const [reason, setReason] = useState(null);

  const cleanup = () => {
    setIsModalOpen(false);
    setComment('');
    setCollectedDate(moment());
    setReason(null);
  };

  const onModalClose = () => {
    cleanup();
    onClose();
  };

  const onSaveChanges = async () => {
    try {
      app.api.toggleLoading(true);
      const response = await request(app.user, 'PUT', `badges/${badge.uuid}/status`, {
        new_status: reason.value.toLocaleLowerCase(),
        collected: 1,
        collectedDate: moment(collectedDate).format('YYYY-MM-DD HH:mm:ss'),
        comment,
      });
      if (response.success) {
        onSave({ ...response });
        cleanup();
      } else {
        app.api.toggleLoading(false);
      }
    } catch (error) {
      app.api.toggleLoading(false);
      handleError({ error, message: 'Unable to mark badge as received' });
    }
  };

  useEffect(() => {
    if (!isOpen || !badge) return;
    const currentStatus = (badge.badgeStatus || '').toLocaleLowerCase();
    setReason(reasons.find(r => (r.value || '').toLocaleLowerCase() === currentStatus));
    setIsModalOpen(true);
  }, [isOpen, badge]);

  if (!isOpen) return null;

  const onModalClick = () => {
    const date = document.querySelector('.collectedDate-trigger .datetime-picker-popup');
    if (date && date.style.display !== 'none') {
      document.querySelector('#collectedDate').click();
    }
  };

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose} onClick={onModalClick}>
      <CustomModalHeader toggle={onModalClose}>Receive Badge</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label>Badge Status</Label>
              <Select
                classNamePrefix="airbadge"
                options={reasons}
                className="form-select"
                value={reason}
                placeholder=""
                onChange={setReason}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Collected Date</Label>
              <DatetimePickerTrigger
                className="collectedDate-trigger"
                moment={collectedDate}
                showTimePicker={false}
                onChange={moment => setCollectedDate(moment)}
              >
                <Input type="text" id="collectedDate" value={collectedDate.format('MM/DD/YYYY')} readOnly />
              </DatetimePickerTrigger>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <Input type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel="Receive Badge"
          saveDisabled={!reason}
          onSave={onSaveChanges}
        />
      </ModalFooter>
    </Modal>
  );
}
