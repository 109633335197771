import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import CustomModalHeader from '../CustomModalHeader/CustomModalHeader';
import { AppContext } from '../../../App';

export default function FileViewModal({ file, isOpen, onClose }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  useEffect(() => {
    if (!isOpen || !file) return;

    setIsModalOpen(true);
    app.api.toggleLoading(true);

    function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200 && document.querySelector('#file-contents-frame')) {
          document.querySelector('#file-contents-frame').src = URL.createObjectURL(this.response) + '';
        }
      }
      setTimeout(() => app.api.toggleLoading(false), 1000);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/api.php/default/v1/files/${file.uuid}/stream`);
    xhr.setRequestHeader('X-AUTH-TOKEN', app.user.authToken);
    xhr.setRequestHeader('X-PROFILE-UUID', app.user.activeProfile ? app.user.activeProfile.uuid : '');
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.send();
  }, [isOpen, file]);

  if (!isModalOpen || !file) return null;

  return (
    <Modal style={{ maxWidth: '95%' }} size="xl" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>{file.fileTypeName}</CustomModalHeader>
      <ModalBody>
        <iframe
          id="file-contents-frame"
          title={file.fileTypeName}
          style={{
            border: 0,
            width: '100%',
            height: '80vh',
          }}
        ></iframe>
      </ModalBody>
    </Modal>
  );
}
