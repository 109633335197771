import React, { useContext, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import trainingCourseApi from '../../../../../ajax/Training/trainingCourseApi';
import { handleError, notify } from '../../../../../utils';
import { AppContext } from '../../../../../App';
import { PersonManageTrainingModalContext } from './PersonManageTrainingModal';

export default function SSIErrorPanel({ message }) {
  if (!message) return null;

  if (!message.toLocaleLowerCase().includes('not found')) {
    return (
      <Alert color="danger">
        <h5>SSI - {message}</h5>
      </Alert>
    );
  }

  const app = useContext(AppContext);
  const trainingModalContext = useContext(PersonManageTrainingModalContext);
  const [promptChoice, setPromptChoice] = useState(null);
  const [saveButtonLabel, setSaveButtonLabel] = useState(null);

  const resetPanel = () => {
    setPromptChoice(null);
    setSaveButtonLabel(null);
    trainingModalContext.reloadData();
  };

  const createAccount = () => {
    const errorMessage =
      'Unable to create the account. You will need to manually create the account. Make sure to set the correct email address or AirBadge will not be able to reconcile the SSI account with the AirBadge account.';
    app.api.toggleLoading(true);
    trainingCourseApi
      .createAccount({
        userUuid: trainingModalContext.person.uuid,
        lms: 'ssi',
      })
      .then(({ success, message }) => {
        if (!success) {
          notify({ message: message || errorMessage, severity: 'error', duration: 10000 });
        } else {
          resetPanel();
        }
      })
      .catch(error => {
        handleError({ error });
        notify({ message: errorMessage, severity: 'error', duration: 10000 });
      })
      .finally(() => app.api.toggleLoading(false));
  };

  const performSelectedOption = () => {
    if (promptChoice === 'create') {
      createAccount();
    } else {
      resetPanel();
    }
  };

  return (
    <Alert color="danger">
      <h5>SSI - {message}</h5>
      <i>
        SSI accounts must have an email address set that matches the email address in AirBadge in order to synchronize
        training data
      </i>
      <div className="mt-3">
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormLabel className="required">Choose an option</FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="choice"
                name="rgChoice"
                value={promptChoice}
                onChange={event => {
                  const choice = event?.target?.value;
                  setPromptChoice(choice);
                  if (choice === 'create') {
                    setSaveButtonLabel('Create SSI Account');
                  } else {
                    setSaveButtonLabel('SSI Account Has Been Fixed');
                  }
                }}
              >
                <FormControlLabel
                  value="create"
                  control={<Radio color="primary" />}
                  label="Create the account for me"
                />
                <FormControlLabel
                  value="self-correct"
                  control={<Radio color="primary" />}
                  label="I will fix or create the account"
                />
              </RadioGroup>
            </FormControl>
            <div>
              <Button
                color={saveButtonLabel ? 'primary' : 'secondary'}
                disabled={!saveButtonLabel}
                onClick={performSelectedOption}
              >
                {saveButtonLabel ? saveButtonLabel : 'Choose an option'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Alert>
  );
}
