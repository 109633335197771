import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import 'moment-timezone';
import { Alert, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import { AppContext } from '../../../../../App';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import usaStates from '../../DataConfirmation/questions/states';
import canadaProvinces from '../../DataConfirmation/questions/states_canada';
import countries from '../../DataConfirmation/questions/countries';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import { BadgerContext } from '../../../Badger';
import { download } from '../../../../../ajax/Request';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

const states = [...usaStates, ...canadaProvinces];

export default function ScanPreview({ isOpen, onClose, scan, requirements, startOverAction }) {
  const app = useContext(AppContext);
  const badger = useContext(BadgerContext);
  const theme = useTheme();
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mimeType, setMimeType] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [isCertified, setIsCertified] = useState(false);
  const [url, setUrl] = useState('');
  const [isImageDisplayed, setIsImageDisplayed] = useState(true);
  const [isExpirationDisplayed, setIsExpirationDisplayed] = useState(false);
  const [isIssuingAuthorityDisplayed, setIsIssuingAuthorityDisplayed] = useState(false);
  const [isCountryDisplayed, setIsCountryDisplayed] = useState(false);

  const getFormattedDate = date => {
    try {
      if (date) return moment.tz(date, app.timezone).format('yyyy-MM-DD');
      else return 'not entered';
    } catch {
      return 'invalid date';
    }
  };

  const getIssuingAuthority = authority => {
    if (authority) {
      const match = states.find(s => s.value === authority.value);
      return match ? match.label : 'invalid entry';
    }
    return 'not entered';
  };

  const getCountry = country => {
    if (country) {
      const match = countries.find(s => s.value === country.value);
      return match ? match.label : 'invalid entry';
    }
    return 'not entered';
  };

  const onModalClose = ({ shouldContinue = false }) => {
    setIsImageDisplayed(true);
    setIsExpirationDisplayed(false);
    setIsIssuingAuthorityDisplayed(false);
    setIsCountryDisplayed(false);
    setIsModalOpen(false);
    setIsCertified(false);
    setIsLoadingImage(false);
    setMimeType('');
    onClose({ shouldContinue });
  };

  const loadImage = ({ scan, mimeType }) => {
    setIsLoadingImage(true);
    download(null, 'POST', `badger/scan/preview`, {
      key: badger.key,
      fileUuid: scan.file.uuid,
    })
      .then(response => {
        if (!response) return;

        const fileName = 'ScannedImage.jpg';
        const options = { type: mimeType };
        const file = new File([response], fileName, options);
        setUrl(URL.createObjectURL(file));
      })
      .catch(e => console.error(e))
      .finally(() => setIsLoadingImage(false));
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(isOpen);
    }

    if (isOpen && scan && scan.status !== 'skipped' && scan.file && scan.file.uuid && scan.file.name) {
      const isPDF = scan.file.name.toLocaleLowerCase().indexOf('.pdf') > -1;
      const mimeType = isPDF ? 'application/pdf' : 'image/jpeg';
      setMimeType(mimeType);
      loadImage({ scan, mimeType });
    }

    setIsIssuingAuthorityDisplayed(requirements.issuingAuthority.enabled && requirements.issuingAuthority.hasState);
    setIsCountryDisplayed(requirements.issuingAuthority.enabled && requirements.issuingAuthority.hasCountry);
    setIsImageDisplayed(requirements.image);
    setIsExpirationDisplayed(requirements.expirationDate.enabled);
  }, [isOpen, scan, requirements]);

  if (!isOpen || !scan) return null;

  if (scan?.status === 'skipped') {
    return (
      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Unable to Continue Badge Application"
        isOpen={isModalOpen}
        onClose={onModalClose}
        closeLabel="Go Back"
        onSave={startOverAction}
        saveLabel="Start Over"
      >
        <Alert color="danger">
          You have chosen to skip a required document and cannot continue the badge application. You can either go back
          and supply this document, or choose to start over from the beginning.
        </Alert>
      </AirBadgeModal>
    );
  }

  return (
    <Modal size="lg" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>{translate(`full-documents-list.${scan.code}`)}</CustomModalHeader>
      <ModalBody>
        {isImageDisplayed && (
          <>
            {scan.status === 'skipped' ? (
              <ReadOnlyFormData label="Document Image" value={'not provided'} />
            ) : (
              <Row>
                <Col className="text-center">
                  {isLoadingImage && <CircularProgress />}
                  {mimeType.indexOf('pdf') > -1 ? (
                    <div style={{ height: 350, overflow: 'scroll' }}>
                      <object
                        id="filePreview"
                        type="application/pdf"
                        data={url}
                        width="100%"
                        height="100%"
                        style={{ display: isLoadingImage ? 'none' : null }}
                      >
                        <p>Unable to display preview. Your web browser doesn't have a PDF plugin</p>
                      </object>
                    </div>
                  ) : (
                    <div style={{ maxHeight: 350, overflow: 'scroll' }}>
                      <img
                        id="filePreview"
                        src={url}
                        alt={`${translate(`full-documents-list.${scan.code}`)} preview`}
                        style={{
                          objectFit: 'cover',
                          display: isLoadingImage ? 'none' : null,
                          width: '100%',
                          overflow: 'scroll',
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </>
        )}

        <Row style={{ marginTop: theme.spacing(2) }}>
          <Col>
            <ReadOnlyFormData
              label={translate(`scanning.document_number.${requirements.documentNumber.i18nLabelKey}`)}
              value={scan.file.documentNumber || 'not entered'}
            />
          </Col>
        </Row>

        {isExpirationDisplayed && (
          <Row style={{ marginTop: theme.spacing(2) }}>
            <Col>
              <ReadOnlyFormData
                label={translate('scanning.expiration_date.label')}
                value={getFormattedDate(scan.file.expirationDate)}
              />
            </Col>
          </Row>
        )}

        {isIssuingAuthorityDisplayed && (
          <Row style={{ marginTop: theme.spacing(2) }}>
            <Col>
              <ReadOnlyFormData
                label={
                  requirements.issuingAuthority.showCanada
                    ? translate('scanning.issuing_authority.state_and_province_label')
                    : translate('scanning.issuing_authority.state_label')
                }
                value={getIssuingAuthority(get(scan, 'file.stateAbbreviation', null))}
              />
            </Col>
          </Row>
        )}

        {isCountryDisplayed && (
          <Row style={{ marginTop: theme.spacing(2) }}>
            <Col>
              <ReadOnlyFormData
                label={translate('scanning.issuing_authority.country_label')}
                value={getCountry(get(scan, 'file.country', null))}
              />
            </Col>
          </Row>
        )}

        {/* TODO: Driver's License specific fields */}
        {/*{scan.code === 'drivers-license' && (*/}
        {/*  <>*/}
        {/*    <Row style={{ marginTop: theme.spacing(2) }}>*/}
        {/*      <Col>*/}
        {/*        <ReadOnlyFormData*/}
        {/*          label={translate('scanning.extra_drivers_license.is_commercial_drivers_license')}*/}
        {/*          value={get(scan, 'file.isCommercialDriversLicense', false) ? 'Yes' : 'No'}*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row style={{ marginTop: theme.spacing(2) }}>*/}
        {/*      <Col>*/}
        {/*        <ReadOnlyFormData*/}
        {/*          skipTextTransform*/}
        {/*          label={translate('scanning.extra_drivers_license.restrictions')}*/}
        {/*          value={get(scan, 'file.restrictions', 'none')}*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </>*/}
        {/*)}*/}

        <Row style={{ marginTop: theme.spacing(2) }}>
          <Col>
            <MuiFormControlLabel
              label={translate('scanning.certify')}
              control={
                <Checkbox
                  color="primary"
                  name="confirm"
                  checked={isCertified}
                  onChange={({ target }) => setIsCertified(target.checked)}
                />
              }
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Go Back"
          onClose={onModalClose}
          saveLabel="Continue"
          onSave={() => onModalClose({ shouldContinue: true })}
          saveDisabled={!isCertified}
        />
      </ModalFooter>
    </Modal>
  );
}
