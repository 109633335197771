import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PeopleListEditModal from '../../../People/PeopleList/PeopleListEditModal';
import { isSmallScreen } from '../../../../../utils';
import DailyViewAppointmentEntry from './DailyViewAppointmentEntry';

const API_DATE_FORMAT = 'dddd MMMM DD, YYYY';
const DATE_FORMAT = 'dddd, MMMM DD';

const isMobile = isSmallScreen();

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 350px)',
  },
  dayColumn: {
    border: '1px solid lightgray',
    borderRadius: 5,
    flex: 1,
    paddingBottom: 5,
    marginLeft: isMobile ? 10 : 15,
    marginRight: isMobile ? 10 : 15,
    '&:first-of-type': {
      marginLeft: isMobile ? undefined : 0,
      marginBottom: isMobile ? 10 : undefined,
    },
    '&:last-of-type': {
      marginRight: isMobile ? undefined : 0,
    },
  },
  dayTitle: {
    padding: '10px 5px',
    backgroundColor: 'lightgray',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.1em',
  },
  noAppointments: { textAlign: 'center', marginTop: 15, fontStyle: 'italic', fontSize: '18px' },
});

export default function BadgeOfficeCalendar({ isLoading, startDate, appointments, onEdit }) {
  const classes = useStyles();
  const [entries, setEntries] = useState(null);
  const [isViewPersonModalOpen, setIsViewPersonModalOpen] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState(null);

  const onViewPersonModalOpen = attendee => {
    setIsViewPersonModalOpen(true);
    setSelectedAttendee(attendee);
  };

  const onViewPersonModalClose = () => {
    setIsViewPersonModalOpen(false);
    setSelectedAttendee(null);
  };

  const getColumnStyles = index => {
    if (isMobile) return classes.dayColumn;

    if (index === 1) {
      return `${classes.dayColumn} ${classes.dayColumnMiddleLeft}`;
    } else if (index === 2) {
      return `${classes.dayColumn} ${classes.dayColumnMiddleRight}`;
    }
    return classes.dayColumn;
  };

  useEffect(() => {
    const maximumDayColumnsToDisplay = 1;
    const bundle = [];
    let current = moment(startDate);
    for (let i = 0; i < maximumDayColumnsToDisplay; i++) {
      let match;
      if (!appointments || !Object.keys(appointments).length) {
        match = null;
      } else {
        match = appointments[current.format(API_DATE_FORMAT)];
      }
      bundle.push({ label: current.format(DATE_FORMAT), appointments: match || [] });
      current.add(1, 'days');
    }
    setEntries(bundle);
  }, [appointments, startDate]);

  if (isLoading) {
    return (
      <div className={`${classes.noAppointments} d-flex justify-content-center align-items-center mb-4 mt-4`}>
        <CircularProgress color="inherit" size={30} thickness={5} />
        <span className="ml-3">Loading</span>
      </div>
    );
  }

  if (!entries || !Object.keys(entries).length) {
    return <div className={classes.noAppointments}>No scheduled appointments</div>;
  }

  return (
    <div className={classes.wrapper}>
      {entries.map((x, i) => (
        <div className={getColumnStyles(i)} key={i}>
          <div className={classes.dayTitle}>{x.label}</div>
          {!x.appointments.length && <div className={classes.noAppointments}>No scheduled appointments</div>}
          <div>
            {x.appointments.map(a => (
              <DailyViewAppointmentEntry
                key={a.uuid}
                id={a.uuid}
                appointment={a}
                onEdit={() => onEdit(a)}
                expanded={false}
                onAttendeeClicked={onViewPersonModalOpen}
              />
            ))}
          </div>
        </div>
      ))}

      <PeopleListEditModal
        isOpen={isViewPersonModalOpen}
        onClose={onViewPersonModalClose}
        selectedPerson={selectedAttendee}
      />
    </div>
  );
}
