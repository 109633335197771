import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Input, Navbar, NavbarBrand } from 'reactstrap';
import { createTheme, makeStyles, responsiveFontSizes, ThemeProvider, useTheme } from '@material-ui/core/styles';
import request from '../../ajax/Request';
import Loading from '../../containers/DefaultLayout/Loading';
import Message from '../../containers/DefaultLayout/Message';
import logo from '../../containers/DefaultLayout/airbadge-logo.png';
import DefaultFooter from '../../containers/DefaultLayout/DefaultFooter';
import BadgerWizard from './BadgerWizard';
import { isSmallScreen } from '../../utils';

export const BadgerContext = createContext({
  language: 'en',
  key: null,
  baseUrl: `${window.location.origin}/#/badger`,
});

const useStyles = makeStyles(theme => ({
  card: { minHeight: 'calc(100vh - 200px)' },
  languageSelectorLabel: { fontWeight: 400, paddingBottom: 3 },
  languageSelector: { width: 140, borderColor: '#e4e7ea', borderRadius: 5 },
  error: {
    height: '100%',
    fontWeight: 600,
    fontSize: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBody: {
    overflowX: 'hidden',
    overflowY: 'auto',
    margin: 0,
    height: 'calc(100vh - 182px)',
    backgroundColor: '#ffffff',
  },
}));

export default function Badger({ state, api, location, history }) {
  const currentTheme = useTheme();
  let theme = createTheme({
    ...currentTheme,
    typography: {
      fontSize: isSmallScreen() ? 14 : 16,
    },
  });
  theme = responsiveFontSizes(theme);

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'badger' });
  const classes = useStyles();
  const [language, setLanguage] = useState('en');
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [key, setKey] = useState(null);
  const [shouldStopAfterScans, setShouldStopAfterScans] = useState(false);

  const changeLanguage = value => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  const load = key => {
    request(null, 'POST', `badger/info`, { key })
      .then(({ success, ...rest }) => {
        rest.key = key;
        if (success) setInfo(rest);
      })
      .catch(error => {
        console.error(error);
        setError('Invalid URL');
      });
  };

  useEffect(() => {
    // Remove the "?" and split into chunks
    let params = location.search.slice(1).split('&');

    let key = null;
    let language = 'en';
    let shouldStopAfterScans = false;
    params.forEach(param => {
      if (param.indexOf('key=') > -1) {
        key = decodeURI(param.replace('key=', ''));
      } else if (param.indexOf('language=') > -1) {
        language = param.replace('language=', '');
      } else if (param.indexOf('stopAfterScans=') > -1) {
        const value = param.replace('stopAfterScans=', '');
        shouldStopAfterScans = (value && (value === 'true' || value === 'yes')) || value === '1';
      }
    });

    if (!key) {
      window.location.href = '/#/default/home';
      return false;
    }

    setLanguage(language);
    i18n.changeLanguage(language);

    setShouldStopAfterScans(shouldStopAfterScans);

    setKey(key);
    load(key);
  }, [location]);

  return (
    <div className="app">
      <Loading loading={state.loading} target={state.loadingTarget} />
      <Message brand={state.messageBrand} message={state.messageContent} />
      <Navbar id="badgerHeader" color="white" light expand="lg" className="shadow-sm">
        <NavbarBrand href="/#/" style={shouldStopAfterScans ? { pointerEvents: 'none' } : null}>
          <img src={logo} width={50} height={50} alt="AirBadge Logo" className="d-inline-block align-middle mr-1" />
          <div className="d-inline-block align-middle mr-1"></div>
        </NavbarBrand>
        <div>
          <div className={classes.languageSelectorLabel}>{t('choose-language')}</div>
          <Input
            type="select"
            className={classes.languageSelector}
            value={language}
            onChange={event => changeLanguage(event.target.value)}
          >
            <option value="en">English</option>
            <option value="es">Español</option>
          </Input>
        </div>
      </Navbar>

      <div className={classes.appBody}>
        <div id="mainContentArea" className="main">
          <BadgerContext.Provider
            value={{
              ...state,
              ...api,
              language,
              key,
              baseUrl: `${window.location.origin}/#/badger`,
              history,
              shouldStopAfterScans,
            }}
          >
            <ThemeProvider theme={theme}>
              {error ? <div className={classes.error}>{t('invalid-url')}</div> : <BadgerWizard info={info} />}
            </ThemeProvider>
          </BadgerContext.Provider>
        </div>
      </div>

      <footer
        id="badgerFooter"
        className="app-footer"
        style={{ position: 'fixed', bottom: 0, width: '100%', height: 50 }}
      >
        <Container>
          <DefaultFooter applicationState={state} />
        </Container>
      </footer>
    </div>
  );
}
