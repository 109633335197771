import React, { useContext, useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import request from '../../../../../ajax/Request';
import { AppContext } from '../../../../../App';

export default function DeleteDocumentModal({ isOpen, onClose, badgeApplication, selectedFile }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [option, setOption] = useState(null);

  const onModalClose = () => {
    setIsModalOpen(false);
    onClose(false);
  };

  const onSave = () => {
    app.api.toggleLoading(true);
    request(
      app.user,
      'DELETE',
      `badge-applications/${badgeApplication.uuid}/scan/${selectedFile.scanUuid}?option=${option || 'delete'}`
    )
      .then(({ success }) => {
        if (success) {
          setIsModalOpen(false);
          onClose(true);
        }
      })
      .catch(e => console.error(e))
      .finally(() => app.api.toggleLoading(false));
  };

  const isSaveDisabled = () => {
    if (selectedFile && selectedFile.uuid) return !option;
    return false;
  };

  useEffect(() => {
    if (!isOpen || !badgeApplication || !selectedFile) return;
    setOption(null);
    setIsModalOpen(true);
  }, [isOpen, badgeApplication, selectedFile]);

  if (!isModalOpen || !badgeApplication || !selectedFile) return null;

  return (
    <Modal isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Delete Document</CustomModalHeader>
      <ModalBody>
        <div>This will remove the document from the badge application workflow.</div>
        {selectedFile && selectedFile.uuid && (
          <>
            <div>What would you like to do with it?</div>

            <FormGroup check className="mt-4">
              <Label check style={{ display: 'flex', cursor: 'pointer' }}>
                <Input
                  type="radio"
                  name="delete-choices"
                  value="keep"
                  onChange={() => setOption('keep')}
                  checked={option === 'keep'}
                  style={{ height: 20, width: 20, cursor: 'pointer' }}
                />
                <span style={{ marginLeft: 8, position: 'relative', top: 3, cursor: 'pointer' }}>
                  Keep it attached to this badge application workflow for record keeping
                </span>
              </Label>
            </FormGroup>
            <FormGroup check className="mt-4">
              <Label check style={{ display: 'flex', cursor: 'pointer' }}>
                <Input
                  type="radio"
                  name="delete-choices"
                  value="delete"
                  onChange={() => setOption('delete')}
                  checked={option === 'delete'}
                  style={{ height: 20, width: 20, cursor: 'pointer' }}
                />
                <span style={{ marginLeft: 8, position: 'relative', top: 3, cursor: 'pointer' }}>
                  Delete it permanently
                </span>
              </Label>
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onModalClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={onSave} style={{ marginLeft: 15, minWidth: 100 }} disabled={isSaveDisabled()}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
}
