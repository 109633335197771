import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import request from '../../../ajax/Request';
import { BlockFormLabel } from '../components/styledComponents';
import DocSpringDocument from '../../DefaultWorkflow/DocSpringDocument/DocSpringDocument';

const useStyles = makeStyles(theme => ({
  empty: {
    height: '100%',
    marginTop: theme.spacing(4),
    fontSize: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

export default function BadgeApplication({ info, badgeApplicationData, isHidden, onNext }) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'badger.badgeApplication' });
  const [isLoading, setIsLoading] = useState(false);

  const [isFrameVisible, setIsFrameVisible] = useState(false);
  const [transactionUuid, setTransactionUuid] = useState(null);

  const showIFrame = transaction => {
    setTransactionUuid(transaction);
    setIsFrameVisible(true);
  };

  const load = () => {
    setIsLoading(true);
    request(null, 'POST', `badger/badge-application-document`, { key: info.key, data: badgeApplicationData })
      .then(({ success, ...rest }) => {
        if (success) {
          showIFrame(rest.transaction);
        }
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (info && !isHidden) load();
  }, [info, isHidden]);

  if (isHidden) return null;

  return (
    <>
      {!isLoading && !isHidden && isFrameVisible && transactionUuid ? (
        <div className="animated fadeIn">
          <DocSpringDocument transactionUuid={transactionUuid} badgerKey={info.key} />
        </div>
      ) : (
        <>
          <BlockFormLabel>
            <Typography variant="h6">{t('statement')}</Typography>
          </BlockFormLabel>
          <div className={classes.empty}>
            <CircularProgress size={60} />
          </div>
        </>
      )}
    </>
  );
}
