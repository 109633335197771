import React, { useContext, useCallback, useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/MailOutline';
import { AppContext, colors } from '../../../../../../App';
import peopleApi from '../../../../../../ajax/People/peopleApi';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import { notify, handleError } from '../../../../../../utils';

export default function ResendInvitationEmailAction() {
  const app = useContext(AppContext);
  const { badgeApplication } = useContext(ActionPanelContext);
  const [label, setLabel] = useState('Send AirBadge Invitation Email');

  const onClickResendInvitation = useCallback(async () => {
    const { api, user } = app;
    try {
      api.toggleLoading(true);
      const { applicant } = badgeApplication;
      const { success, message } = await peopleApi.resendInvitation({ user, uuid: applicant.uuid });
      if (success) {
        notify({ message: `Invitation email sent to ${applicant.fullName}`, severity: 'success' });
      } else {
        handleError({ message });
      }
    } catch (error) {
      handleError({ error, message: 'Unable to resend invitation' });
    } finally {
      api.toggleLoading(false);
    }
  }, [app, badgeApplication, peopleApi]);

  useEffect(() => {
    if (badgeApplication?.applicant?.lastLogin !== null) {
      setLabel('Resend AirBadge Invitation Email');
    } else {
      setLabel('Send AirBadge Invitation Email');
    }
  }, [badgeApplication]);

  return (
    <ListItem button onClick={onClickResendInvitation} hidden={false}>
      <ListItemIcon>
        <EmailIcon style={{ color: colors.primary }} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
}
