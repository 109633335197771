import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import peopleApi from '../../../../../ajax/People/peopleApi';
import { AppContext } from '../../../../../App';
import AirBadgeModal from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import StaggeredDatePicker from '../../../../../shared/components/DatePickers/StaggeredDatePicker';
import { FULL_DATE_FORMAT } from '../PeopleListEditModal';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';

export default function UpdateDateOfBirthModal({ isOpen, person, onClose, setPerson, setDateOfBirth }) {
  const { api } = useContext(AppContext);
  const [current, setCurrent] = useState('-');
  const [dob, setDob] = useState(person.date_of_birth);

  useEffect(() => {
    setDob(null);
    setCurrent('-');
    if (person && person.date_of_birth) {
      setDob(person.date_of_birth);
      setCurrent(moment(person.date_of_birth).format(FULL_DATE_FORMAT));
    }
  }, [person]);

  const onSaveHandler = () => {
    api.toggleLoading(true);
    peopleApi
      .patch({ uuid: person.uuid, updates: { date_of_birth: dob } })
      .then(({ success, message }) => {
        if (success) {
          const updatedPerson = { ...person };

          updatedPerson.date_of_birth = dob;
          setPerson(updatedPerson);
          setDateOfBirth(dob ? moment(dob).format(FULL_DATE_FORMAT) : '-');
          api.displayMessage('Date of birth successfully updated', 'success');
          onClose();
        } else {
          api.displayMessage(message || 'Unable to update this User', 'warning');
        }
      })
      .catch(e => {
        console.error(e);
        api.displayMessage('Unable to update this User', 'warning');
      })
      .finally(() => api.toggleLoading(false));
  };

  return (
    <AirBadgeModal
      title="Update Date of Birth"
      size="md"
      isOpen={isOpen}
      onSave={onSaveHandler}
      saveLabel="Save Change"
      onClose={() => onClose()}
    >
      <Row>
        <Col>
          <ReadOnlyFormData skipTextTransform label="Current Date of Birth" value={current} faded />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={12}>
          <FormGroup>
            <Label>New Date of Birth</Label>
            {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
            <StaggeredDatePicker
              startingYear={new Date().getFullYear() - 99}
              endingYear={new Date().getFullYear()}
              reverseYearOrder
              defaultValue={dob}
              onValidDate={({ year, month, day }) => setDob(`${year}-${month}-${day}`)}
            />
            {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
