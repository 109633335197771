import React, { useContext, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { toCSV } from 'react-csv/lib/core';
import { Button, Input } from 'reactstrap';
import { Grid, Switch } from '@material-ui/core';
import ReactTableTotalCount from '../../Common/ReactTableTotalCount';
import AppointmentsListEditModal from './AppointmentsListEditModal';
import { AppContext } from '../../../../App';
import Portal from '@material-ui/core/Portal';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import request from '../../../../ajax/Request';
import PeopleListEditModal from '../../People/PeopleList/PeopleListEditModal';
import { handleError } from '../../../../utils';

let requestTimeout = null;
let apiAbortController = null;

export default function AppointmentsGrid({
  shouldReload,
  setShouldReload,
  appointmentTypes = [],
  staffOptions = [],
  appointmentType,
  setAppointmentType,
  staff,
  setStaff,
  shouldShowExtraActionButtons,
}) {
  const { user, api, timezone } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [params, setParams] = useState([50, 1, [], []]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [showFilters, setShowFilters] = useState(true);
  const [optionsHidden, setOptionsHidden] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({
    started: true,
    appointment_type: true,
    location: true,
    attendee_name: true,
    staff: true,
    actions: true,
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [appointmentToEdit, setAppointmentToEdit] = useState(null);
  const [isViewPersonModalOpen, setIsViewPersonModalOpen] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState(null);

  const onViewPersonModalOpen = attendee => {
    setIsViewPersonModalOpen(true);
    setSelectedAttendee(attendee);
  };

  const onViewPersonModalClose = () => {
    setIsViewPersonModalOpen(false);
    setSelectedAttendee(null);
  };

  const onEditModalClose = () => {
    setIsEditModalOpen(false);
    setAppointmentToEdit(null);
  };

  const reload = () => {
    requestData(...params);
    setShouldReload(false);
  };

  const prepareCsvData = data => {
    // Header
    const header = [];
    columns.forEach(column => {
      if (columnVisibility[column.id]) {
        header.push(column.Header);
      }
    });

    // Rows
    const rows = [];

    data.forEach(entry => {
      const row = [];
      columns.forEach(column => {
        if (columnVisibility[column.id]) {
          if (column.Header === 'Type') {
            row.push(entry.appointment_type_name);
          } else if (column.Header === 'Start') {
            row.push(`${entry.date} ${entry.start}`);
          } else if (column.Header === 'Resource') {
            row.push(entry.staffName);
          } else {
            row.push(entry[column.stateKey]);
          }
        }
      });

      rows.push(row);
    });

    return [header, ...rows];
  };

  const fetchData = state => {
    if (requestTimeout) clearTimeout(requestTimeout);
    if (apiAbortController) apiAbortController.abort();

    const { pageSize, page, sorted, filtered } = state;
    const newParams = [pageSize, page, sorted, filtered];
    setParams(newParams);

    requestTimeout = setTimeout(() => {
      apiAbortController = new AbortController();
      const signal = apiAbortController.signal;
      requestData(pageSize, page, sorted, filtered, signal);
    }, 500);
  };

  const callApi = ({ signal, ...rest }) => {
    return request('authenticated-user', 'POST', 'appointments/data-table', rest, signal);
  };

  const requestData = async (pageSize, page, sorted, filtered, signal) => {
    setLoading(true);
    try {
      const { data, pages, total } = await callApi({ pageSize, page, sorted, filtered, signal });
      setData(data);
      setPages(pages);
      setTotal(total);
    } catch (error) {
      handleError({ error });
    } finally {
      setLoading(false);
    }
  };

  const fetchCsvData = () => {
    api.toggleLoading(true);
    callApi({
      pageSize: params[0],
      page: params[1],
      sorted: params[2] || [],
      filtered: params[3] || [],
    })
      .then(({ data }) => {
        const csvData = prepareCsvData(data);
        const filename = 'appointments.csv';
        const blob = new Blob([toCSV(csvData)]);

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
          return;
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => handleError({ error }))
      .finally(() => {
        api.toggleLoading(false);
      });

    return false;
  };

  const toggleColumn = column => {
    setColumnVisibility(prev => {
      const toReturn = { ...prev };
      toReturn[column.id] = !prev[column.id];
      return toReturn;
    });
  };

  const columns = [];
  columns.push({
    id: 'started',
    Header: 'Start',
    stateKey: 'started',
    accessor: row => (row.start ? <span title={`${row.date} ${row.start}`}>{`${row.date} ${row.start}`}</span> : ''),
    minWidth: 80,
    filterable: false,
    mobile: true,
    sortable: true,
    show: columnVisibility.started,
  });

  columns.push({
    id: 'appointment_type',
    Header: 'Type',
    accessor: row => row.appointment_type_name,
    Filter: ({ filter, onChange }) => (
      <Input
        type="select"
        value={filter ? filter.value : ''}
        onChange={event => {
          const value = event.target.value || null;
          onChange(event.target.value);
          setAppointmentType(appointmentTypes.find(x => x.value === value));
        }}
      >
        {appointmentTypes.map(x => (
          <option key={x.value} value={x.value}>
            {x.label}
          </option>
        ))}
      </Input>
    ),
    minWidth: 100,
    filterable: showFilters,
    mobile: false,
    show: columnVisibility.appointment_type,
  });

  columns.push({
    id: 'location',
    Header: 'Location',
    stateKey: 'location',
    accessor: 'location',
    minWidth: 80,
    filterable: false,
    mobile: false,
    sortable: true,
    show: columnVisibility.location,
  });

  columns.push({
    id: 'attendee_name',
    Header: 'Attendee',
    stateKey: 'attendee_name',
    accessor: row => (
      <button type="button" className="btn btn-link" onClick={() => onViewPersonModalOpen({ uuid: row.attendee_uuid })}>
        {row.attendee_name}
      </button>
    ),
    minWidth: 80,
    filterable: true,
    mobile: true,
    sortable: true,
    show: columnVisibility.attendee_name,
  });

  columns.push({
    id: 'staff',
    Header: 'Resource',
    stateKey: 'staff',
    accessor: 'staffName',
    Filter: ({ filter, onChange }) => (
      <Input
        type="select"
        value={filter ? filter.value : ''}
        onChange={event => {
          onChange(event.target.value);
          setStaff(staffOptions.find(x => x.value === event.target.value));
        }}
      >
        {staffOptions.map(x => (
          <option key={x.value} value={x.value}>
            {x.label}
          </option>
        ))}
      </Input>
    ),
    minWidth: 80,
    filterable: showFilters,
    mobile: false,
    show: columnVisibility.staff,
  });

  columns.push({
    id: 'uuid',
    Header: 'Actions',
    stateKey: 'uuid',
    accessor: row => (
      <>
        <Button
          type="button"
          color="primary"
          onClick={() => {
            setAppointmentToEdit(row);
            setIsEditModalOpen(true);
          }}
        >
          View Details
        </Button>
      </>
    ),
    minWidth: 80,
    filterable: false,
    sortable: false,
    mobile: false,
    show: columnVisibility.actions,
  });

  const columnNames = [];
  columns.forEach(column => {
    if (column.Header !== 'Actions') {
      columnNames.push(
        <label
          key={column.id}
          className={'mr-3 cursor-pointer ' + (column.show ? 'font-weight-bold' : '')}
          onClick={() => toggleColumn(column)}
        >
          {column.Header}
        </label>
      );
    }
  });

  useEffect(() => {
    if (shouldReload) {
      reload();
      setShouldReload(false);
    }

    return () => {
      if (requestTimeout) clearTimeout(requestTimeout);
      if (apiAbortController) apiAbortController.abort();
    };
  }, [shouldReload]);

  const buildDefaultFilters = () => {
    const filters = [];

    if (staff) {
      filters.push({
        id: 'staff',
        value: staff.value,
      });
    }

    if (appointmentType) {
      filters.push({
        id: 'appointment_type',
        value: appointmentType.value,
      });
    }

    return filters;
  };

  return (
    <>
      <Portal container={document.getElementById('gridOptionsBtn')}>
        <Button color="secondary" type="button" onClick={() => setOptionsHidden(prev => !prev)}>
          <i className="fa fa-cog" />
          &nbsp;Options
        </Button>
      </Portal>

      <Grid container style={optionsHidden ? { display: 'none' } : { marginTop: 16, marginBottom: 24 }}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <MuiFormControlLabel
                control={<Switch color="primary" />}
                label="Show Filters"
                checked={showFilters}
                onChange={event => setShowFilters(event.target.checked)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item>
              <h5 className="mb-2">Columns</h5>
              {columnNames}
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item>
              <Button color="primary" onClick={() => fetchCsvData()}>
                <i className="fa fa-download" />
                &nbsp;Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={optionsHidden ? { marginTop: 10 } : {}}>
        <Grid item xs={12}>
          <div className="table-responsive">
            <ReactTable
              manual
              defaultSorted={[{ id: 'started', desc: false }]}
              defaultFiltered={buildDefaultFilters()}
              className="border-0 -striped"
              loading={loading}
              data={data}
              columns={columns}
              pages={pages}
              minRows={0}
              defaultPageSize={50}
              onFetchData={fetchData}
              showPagination={pages > 1}
            />
            <ReactTableTotalCount params={[0, ...params]} total={total} />
          </div>
        </Grid>
      </Grid>

      <AppointmentsListEditModal
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        user={user}
        api={api}
        reload={reload}
        timeZone={timezone}
        editAppointment={appointmentToEdit}
        shouldShowExtraActionButtons={shouldShowExtraActionButtons}
      />

      <PeopleListEditModal
        isOpen={isViewPersonModalOpen}
        onClose={onViewPersonModalClose}
        selectedPerson={selectedAttendee}
      />
    </>
  );
}
