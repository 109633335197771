import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { AppContext } from '../../../../../../App';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import BadgeApplicationTrainingEditor from '../../../../ApplicationAdministration/BadgeApplications/components/BadgeApplicationTrainingEditor';
import cloneDeep from 'lodash/cloneDeep';
import ReadOnlyFormData from '../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { handleError } from '../../../../../../utils';
import PersonAccomplishments from '../../../../People/PeopleList/components/PersonAccomplishments';
import { getDrivingOptions } from '../../../../../../shared/BadgeTypes/drivingOptionsUtils';

export default function PreAssignTrainingAction() {
  const app = useContext(AppContext);
  const { badgeApplication, isCurrentUserSignatory, onActionDone } = useContext(ActionPanelContext);
  const [isTrainingEnabled, setIsTrainingEnabled] = useState(false);
  const [isPreAssignModalOpen, setIsPreAssignModalOpen] = useState(false);
  const [auxiliaryConfig, setAuxiliaryConfig] = useState(null);
  const [wasChanged, setWasChanged] = useState(false);
  const [isAccomplishmentsModalOpen, setIsAccomplishmentsModalOpen] = useState(false);

  const onAccomplishmentsModalClose = () => {
    setIsAccomplishmentsModalOpen(false);
  };

  const onAccomplishmentsModalOpen = () => {
    setIsAccomplishmentsModalOpen(true);
  };

  const onSaveConfig = () => {
    app.api.toggleLoading(true);
    badgeApplicationApi
      .updateConfig({ badgeApplicationUuid: badgeApplication.uuid, auxiliaryConfig })
      .then(({ success }) => {
        if (success) {
          onPreAssignModalClose();
          onActionDone({ shouldReload: true });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onTrainingConfigChanged = ({ updatedConfig }) => {
    auxiliaryConfig.training = updatedConfig;
    setAuxiliaryConfig(cloneDeep(auxiliaryConfig));
    setWasChanged(true);
  };

  const onPreAssignModalOpen = () => {
    setWasChanged(false);
    setAuxiliaryConfig(cloneDeep(badgeApplication.auxiliaryConfig));
    setIsPreAssignModalOpen(true);
  };

  const onPreAssignModalClose = () => {
    setAuxiliaryConfig(null);
    setIsPreAssignModalOpen(false);
  };

  const isHidden = () => {
    const { active, currentPhase, config } = badgeApplication;
    const { training } = config;

    if (!isTrainingEnabled) {
      return true;
    }
    if (!!active === false) {
      return true;
    }
    if (!training.isEnabled) {
      return true;
    } else if (training.isComplete) {
      // This requirement is already done
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for assigning training
      return true;
    }
    return currentPhase.toLocaleLowerCase() !== 'authorize';
  };

  const getActionTitle = () => {
    if (!badgeApplication) {
      return 'Pre-assign Training';
    }

    const { currentPhase } = badgeApplication;
    if ('authorize' === currentPhase.toLocaleLowerCase()) {
      return 'Assign Training';
    }
    return 'Pre-assign Training';
  };

  const getDrivingOption = () => {
    const drivingOptions = getDrivingOptions(badgeApplication?.badgeType?.config?.endorsements?.driving);
    return drivingOptions.find(({ value }) => value === badgeApplication.driving)?.label || 'error';
  };

  useEffect(() => {
    if (!app.airport) return null;
    const { isIETEnabled, isAirBadgeLMSEnabled, isSSIEnabled, isDigicastEnabled } = app.airport;
    setIsTrainingEnabled(isIETEnabled || isDigicastEnabled || isAirBadgeLMSEnabled || isSSIEnabled);
  }, [app.airport]);

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={onPreAssignModalOpen}>
        <ListItemIcon>
          <FontAwesomeIcon
            className="fa-solid fa-chalkboard-user"
            style={{ marginRight: 0, fontSize: 20, position: 'relative', left: -5 }}
          />
        </ListItemIcon>
        <ListItemText primary={getActionTitle()} />
      </ListItem>

      <ListItem button onClick={onAccomplishmentsModalOpen}>
        <ListItemIcon>
          <FontAwesomeIcon
            className="fa-solid fa-list-check"
            style={{ marginRight: 0, fontSize: 22, position: 'relative', left: -2 }}
          />
        </ListItemIcon>
        <ListItemText primary="Review Training Accomplishments" />
      </ListItem>

      <AirBadgeModal
        size={MODAL_SIZE_LARGE}
        title={
          <div>
            Pre-assign Training for {badgeApplication.applicant.fullName}{' '}
            <span>({badgeApplication.applicant.airportPersonId || '-'})</span>
          </div>
        }
        isOpen={isPreAssignModalOpen}
        onClose={onPreAssignModalClose}
        saveLabel="Save Changes"
        onSave={onSaveConfig}
        saveDisabled={!wasChanged}
      >
        <div
          style={{
            width: '100%',
            border: '1px solid #c8ced3',
            backgroundColor: '#f0f0f0',
            borderRadius: 5,
            padding: '12px 18px',
            marginBottom: 18,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <ReadOnlyFormData
              label="New or Renewal?"
              value={`${badgeApplication.isRenewal ? 'Renewal' : 'New'}`}
              skipTextTransform
            />
            <ReadOnlyFormData label="Badge Type" value={badgeApplication.badgeType.displayName} skipTextTransform />
            <ReadOnlyFormData label="Company" value={badgeApplication.signatory.company.name} />
          </div>
          <div className="separator mt-2 mb-2">Endorsements</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <ReadOnlyFormData label="Driving" value={getDrivingOption()} />
            <ReadOnlyFormData label="Escort" value={badgeApplication.isEscort ? 'Yes' : 'No'} />
          </div>
        </div>
        <Alert color="info" style={{ marginBottom: 32 }}>
          Select training to be automatically assigned after vetting has completed
        </Alert>
        <BadgeApplicationTrainingEditor
          config={auxiliaryConfig?.training}
          onTrainingConfigChanged={onTrainingConfigChanged}
        />
      </AirBadgeModal>

      <AirBadgeModal
        size={MODAL_SIZE_LARGE}
        title={
          <div>
            <div>Training Accomplishments</div>
            <div style={{ fontSize: 14, fontStyle: 'italic', marginTop: 3 }}>
              for {badgeApplication.applicant.fullName}{' '}
              <span>({badgeApplication.applicant.airportPersonId || '-'})</span>
            </div>
          </div>
        }
        isOpen={isAccomplishmentsModalOpen}
        onClose={onAccomplishmentsModalClose}
        closeLabel="Close"
      >
        <PersonAccomplishments
          badgeApplication={badgeApplication}
          selectedPerson={{ uuid: badgeApplication.applicant.uuid }}
          isOpen={isAccomplishmentsModalOpen}
        />
      </AirBadgeModal>
    </>
  );
}
