import React, { useContext, useEffect, useState } from 'react';
import { Alert, Input, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import InputWithCharacterLimit from '../../../../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import { AppContext } from '../../../../../../../../App';
import badgeApplicationApi from '../../../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { handleError } from '../../../../../../../../utils';
import request from '../../../../../../../../ajax/Request';

export default function RejectIdModal({
  isOpen,
  onClose,
  badgeApplication,
  rejectedDocument,
  mode,
  isActivatedBySignatory = false,
}) {
  const app = useContext(AppContext);
  const [applicantSubject, setApplicantSubject] = useState('');
  const [applicantMessage, setApplicantMessage] = useState('');
  const [signatorySubject, setSignatorySubject] = useState('');
  const [signatoryMessage, setSignatoryMessage] = useState('');
  const [isSignatoryMessageSkipped, setIsSignatoryMessageSkipped] = useState(false);

  const onSave = () => {
    app.api.toggleLoading(true);
    const data = { badgeApplicationUuid: badgeApplication.uuid, applicantSubject, applicantMessage };
    if (!isSignatoryMessageSkipped) {
      data.signatorySubject = signatorySubject;
      data.signatoryMessage = signatoryMessage;
    }
    if (mode === 'reject') {
      badgeApplicationApi
        .redoBadgeApplication(data)
        .then(() => onClose({ wasRejected: true }))
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    } else {
      const url = `badge-applications/${badgeApplication.uuid}/scan/${rejectedDocument.scanUuid}?option=rescan`;
      request('authenticated-user', 'DELETE', url, data)
        .then(() => onClose({ wasRejected: true }))
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }
  };

  const prepareForm = () => {
    setIsSignatoryMessageSkipped(isActivatedBySignatory);

    const applicantName = badgeApplication.applicant.fullName;
    const signatoryName = badgeApplication.signatory.fullName;
    const documentName = rejectedDocument.fileTypeName.replace('Identity: ', '');

    if (mode === 'reject') {
      setApplicantSubject('Identification Document Rejected');
      let message = `${applicantName},\n\nYour ${documentName} document was rejected. You will need to redo your badge application. Please ensure you provide clear pictures of your identification documents.`;
      if (isActivatedBySignatory) {
        message = `${message}\n\nThank you,\n${badgeApplication.signatory.fullName}`;
      } else {
        message = `${message}\n\nThank you,\n${app.airport.abbreviation} Credentialing Office`;
      }
      setApplicantMessage(message);

      if (!isActivatedBySignatory) {
        setIsSignatoryMessageSkipped(false);
        setSignatorySubject(`Identification Document Rejected for ${applicantName}`);
        setSignatoryMessage(
          `${signatoryName},\n\nThe ${documentName} document for ${applicantName} was rejected. They will need to redo their badge application.\n\nThank you,\n${app.airport.abbreviation} Credentialing Office`
        );
      }
    } else if (mode === 'replace') {
      setApplicantSubject('Replacement Image Requested for Identification Document');
      let message = `${applicantName},\n\nThe image for your ${documentName} document needs to be replaced. Please ensure you provide clear pictures of your identification documents.\n\nLog into AirBadge and click the button on your home page to provide a replacement image.`;
      if (isActivatedBySignatory) {
        message = `${message}\n\nThank you,\n${badgeApplication.signatory.fullName}`;
      } else {
        message = `${message}\n\nThank you,\n${app.airport.abbreviation} Credentialing Office`;
      }
      setApplicantMessage(message);

      if (!isActivatedBySignatory) {
        setIsSignatoryMessageSkipped(false);
        setSignatorySubject(`Replacement Image Requested for Identification Document for ${applicantName}`);
        setSignatoryMessage(
          `${signatoryName},\n\nThe image for the ${documentName} document for ${applicantName} needs to be replaced. \n\nThank you,\n${app.airport.abbreviation} Credentialing Office`
        );
      }
    }
  };

  const isSaveDisabled = () => {
    if (isActivatedBySignatory) {
      return !applicantSubject || !applicantMessage;
    }
    return !applicantSubject || !applicantMessage || !signatorySubject || !signatoryMessage;
  };

  useEffect(() => {
    if (!isOpen || !badgeApplication || !rejectedDocument || !mode) return;
    prepareForm();
  }, [isOpen, badgeApplication, rejectedDocument, mode]);

  if (!isOpen || !badgeApplication || !rejectedDocument || !mode) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title={mode === 'reject' ? 'Reject ID & Restart Badge Application' : 'Request Replacement Image'}
      isOpen={isOpen}
      onClose={() => onClose({ wasRejected: false })}
      onSave={onSave}
      saveLabel={mode === 'reject' ? 'Restart Badge Application' : 'Request Replacement Image'}
      saveColor={mode === 'reject' ? 'danger' : 'warning'}
      saveDisabled={isSaveDisabled()}
    >
      {mode === 'reject' ? (
        <Alert color="danger" className="mb-4">
          <p>
            This will discard the badge application document, discard any uploaded documents, and ask the applicant to
            redo their badge application.
          </p>
          <p>
            Update the message below to provide a detailed reason so the applicant can successfully provide satisfactory
            identification documents on their next attempt.
          </p>
        </Alert>
      ) : (
        <Alert color="info" className="mb-4">
          <p>This will discard the image for this document and ask the applicant to provide a replacement.</p>
          <p>
            Update the message below to provide a detailed reason so the applicant can successfully provide a
            satisfactory image on their next attempt.
          </p>
        </Alert>
      )}
      {!isActivatedBySignatory && <div className="separator mt-4 mb-3">Message To Applicant</div>}
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Label>Subject</Label>
          <Input value={applicantSubject} onChange={event => setApplicantSubject(event.target.value)} />
        </Grid>
        <Grid item>
          <Label>Message</Label>
          <InputWithCharacterLimit
            limit={500}
            value={applicantMessage}
            onChange={value => setApplicantMessage(value)}
            style={{ height: mode === 'reject' ? 180 : 210 }}
          />
        </Grid>
      </Grid>
      {!isActivatedBySignatory && (
        <>
          <div className="separator mt-4 mb-3">Message To Signatory</div>
          <MuiFormGroup row className="mb-3">
            <MuiFormControlLabel
              control={<Switch color="primary" />}
              label="Don't send message to Signatory"
              checked={isSignatoryMessageSkipped}
              onChange={event => setIsSignatoryMessageSkipped(event.target.checked)}
            />
          </MuiFormGroup>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Label>Subject</Label>
              <Input
                value={signatorySubject}
                onChange={event => setSignatorySubject(event.target.value)}
                className={isSignatoryMessageSkipped ? 'form-control custom-disabled' : 'form-control'}
              />
            </Grid>
            <Grid item>
              <Label>Message</Label>
              <InputWithCharacterLimit
                limit={500}
                value={signatoryMessage}
                onChange={setSignatoryMessage}
                style={{ height: 180 }}
                className={isSignatoryMessageSkipped ? 'form-control custom-disabled' : 'form-control'}
              />
            </Grid>
          </Grid>
        </>
      )}
    </AirBadgeModal>
  );
}
