import React from 'react';
import { Button } from 'reactstrap';

export default function ModalActions({
  closeLabel = 'Cancel',
  onClose,
  closeColor,
  saveLabel = 'Save',
  onSave,
  saveDisabled = false,
  saveColor = 'primary',
  leftSideExtra,
  middleExtra,
}) {
  if (leftSideExtra) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>{leftSideExtra}</div>
        <div>
          {onClose && (
            <Button color={closeColor} onClick={onClose}>
              {closeLabel}
            </Button>
          )}
          {onSave && (
            <Button
              color={saveColor}
              onClick={onSave}
              disabled={saveDisabled}
              style={{ marginLeft: 15, minWidth: 100 }}
            >
              {saveLabel}
            </Button>
          )}
        </div>
      </div>
    );
  }

  let buttonsCount = 1;
  if (middleExtra) buttonsCount++;
  if (onSave) buttonsCount++;

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
      {onClose && (
        <Button color={closeColor} onClick={onClose} size={buttonsCount > 1 ? 'md' : 'sm'}>
          {closeLabel}
        </Button>
      )}
      {middleExtra && <div style={{ marginLeft: 15 }}>{middleExtra}</div>}
      {onSave && (
        <Button color={saveColor} onClick={onSave} disabled={saveDisabled} style={{ marginLeft: 15, minWidth: 100 }}>
          {saveLabel}
        </Button>
      )}
    </div>
  );
}
