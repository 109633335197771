import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import Select from 'react-select';
import { Alert, Col, FormGroup, Input, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import RemoveIcon from '@material-ui/icons/Remove';
import Portal from '@material-ui/core/Portal';
import Button from '@material-ui/core/Button';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { AppContext, colors } from '../../../../../App';
import request from '../../../../../ajax/Request';
import NativeSelect from '../../../../../shared/components/NativeSelect/NativeSelect';
import BadgerFormLabel from '../../../components/BadgerFormLabel';
import countries from '../../DataConfirmation/questions/countries';
import usaStates from '../../DataConfirmation/questions/states';
import canadaProvinces from '../../DataConfirmation/questions/states_canada';
import NextButton from '../../../components/NextButton';
import ScanPreview from './ScanPreview';
import documentRequirements, { defaultRequirement } from './documentRequirements';
import InputMask from 'react-input-mask';
import { handleError, isSmallScreen } from '../../../../../utils';
import trim from 'lodash/trim';

function FormInputIcon({ isValid = false, isSkipped = false }) {
  const style = { marginRight: 8 };
  if (isSkipped) {
    return <BlockIcon style={style} htmlColor={colors.warning} />;
  } else if (isValid) {
    return <CheckIcon style={style} htmlColor={colors.success} />;
  }
  return <RemoveIcon style={style} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    '&.MuiListItemIcon-root': {
      minWidth: theme.spacing(4),
    },
  },
  rowSpacer: {
    marginTop: theme.spacing(4),
  },
  option: {
    marginRight: theme.spacing(1),
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  optionContent: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
  },
  optionActionsLabel: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  optionActions: {
    display: 'flex',
    flexDirection: 'row',
    '& p:last-child': {
      marginLeft: theme.spacing(4),
    },
    marginBottom: theme.spacing(4),
  },
  scanAction: {
    color: `${colors.primary} !important`,
    cursor: 'pointer',
  },
  skippedScanAction: {
    opacity: 0.5,
    pointerEvents: 'none',
    color: 'inherit',
  },
  skipAction: {
    marginTop: theme.spacing(3),
  },
  scanEntryLeft: {
    flex: 0.75,
  },
  imagePreview: {
    flex: 0.25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  noImage: {
    width: 80,
    height: 80,
    border: '1px dashed black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondary,
    borderRadius: 5,
    opacity: 0.5,
  },
  doneButton: {
    color: 'white',
  },
}));

const monthOptions = [
  { value: '01', label: '1 - January' },
  { value: '02', label: '2 - February' },
  {
    value: '03',
    label: '3 - March',
  },
  { value: '04', label: '4 - April' },
  { value: '05', label: '5 - May' },
  {
    value: '06',
    label: '6 - June',
  },
  { value: '07', label: '7 - July' },
  { value: '08', label: '8 - August' },
  {
    value: '09',
    label: '9 - September',
  },
  { value: '10', label: '10 - October' },
  { value: '11', label: '11 - November' },
  {
    value: '12',
    label: '12 - December',
  },
];

let requestTimeout = null;
let apiAbortController = null;

export default function ScanEntry({
  info,
  scan,
  totalScans,
  onScanUpdated,
  onClick,
  onClickFileUpload,
  onNext,
  onSkip,
  startOverAction,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });
  const app = useContext(AppContext);
  const classes = useStyles();
  const isIphone = window.navigator.userAgent.toLocaleLowerCase().indexOf('iphone') > -1;
  const fileUploadInputRef = useRef(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);
  const [includeBlankYear, setIncludeBlankYear] = useState(true);
  const [includeBlankMonth, setIncludeBlankMonth] = useState(true);
  const [includeBlankDay, setIncludeBlankDay] = useState(true);
  const [expireMonth, setExpireMonth] = useState(null);
  const [expireDay, setExpireDay] = useState(null);
  const [expireYear, setExpireYear] = useState(null);
  const [icon, setIcon] = useState('');
  const [message, setMessage] = useState('');
  const [states, setStates] = useState([]);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isUserTryingToSkip, setIsUserTryingToSkip] = useState(false);
  const [requirements, setRequirements] = useState({ ...defaultRequirement });
  const [isDriversLicense, setIsDriversLicense] = useState(false);

  const onExpireDateChanged = ({ year, month, day }) => {
    if (year !== expireYear || month !== expireMonth) {
      setIncludeBlankDay(true);
      day = null;
    }

    if (year && month && !day) {
      const allowedDays = moment(`${year.value}-${month.value}`, 'YYYY-MM').daysInMonth();
      setDayOptions(
        Array(allowedDays)
          .fill('')
          .map((ignored, i) => {
            const day = 1 + i;
            return { label: `${day}`, value: `${day < 10 ? `0${day}` : day}` };
          })
      );
    }

    setExpireYear(year);
    setExpireMonth(month);
    setExpireDay(day);

    if (year && month && day) {
      scan.file.expirationDate = `${year.value}-${month.value}-${day.value}`;
      updateScan({ ...scan });
    }
  };

  const onSkipScanEntry = isSkipped => {
    onSkip(isSkipped);
  };

  const getNativeSelectClasses = () => {
    let classes = '';
    if (!isIphone) classes = `${classes} countrySelect`;
    return classes;
  };

  const updateScan = updatedScan => {
    onScanUpdated(updatedScan);

    if (requestTimeout) {
      if (apiAbortController) apiAbortController.abort();
      clearTimeout(requestTimeout);
    }

    requestTimeout = setTimeout(() => {
      apiAbortController = new AbortController();
      const data = { key: info.key, ...updatedScan };
      data.file = { ...updatedScan.file };
      if (data.file.country) {
        data.file.country = data.file.country.value;
      }
      if (data.file.stateAbbreviation) {
        data.file.stateAbbreviation = data.file.stateAbbreviation.value;
      }
      if (data.file.documentNumber) {
        // We need to ensure that the document number is UTF-8 encoded
        data.file.documentNumber = data.file.documentNumber.trim();
        // Encode the string as UTF-8
        const utf8Encoder = new TextEncoder();
        const utf8Bytes = utf8Encoder.encode(data.file.documentNumber);
        // Decode the UTF-8 bytes back to a string
        const utf8Decoder = new TextDecoder('utf-8');
        data.file.documentNumber = utf8Decoder.decode(utf8Bytes);
      }
      const signal = apiAbortController.signal;
      request(null, 'PATCH', `badger/scan/${scan.uuid}`, data, signal).catch(error => console.error(error));
    }, 500);
  };

  const isDocumentNumberValid = () => {
    const { documentNumber } = scan.file;
    const trimmed = (documentNumber || '').trim();
    if (scan.code === 'social-security-card' && trimmed) {
      return trimmed !== '___-__-____' && trimmed.indexOf('_') < 0 && trimmed.length === 11;
    }
    return !!trimmed;
  };

  const isNextButtonDisabled = () => {
    if (isUserTryingToSkip && scan.status !== 'skipped') {
      return true;
    }

    if (!scan || !scan.status) {
      return true;
    }

    if (requirements.image && scan.status === 'pending') {
      return true;
    }

    if (scan.status === 'skipped') {
      return false;
    }

    if (requirements.image && (!scan.file || !scan.file.uuid || !scan.file.path)) {
      return true;
    }

    const { documentNumber, expirationDate, issuingAuthority } = requirements;
    if (documentNumber.enabled && documentNumber.required) {
      if (!scan.file.documentNumber || !isDocumentNumberValid()) return true;
    }
    if (expirationDate.enabled && expirationDate.required) {
      if (!scan.file.expirationDate) return true;
    }
    if (issuingAuthority.enabled && issuingAuthority.required) {
      if (issuingAuthority.hasState && !scan.file.stateAbbreviation) {
        return true;
      }
      if (issuingAuthority.hasCountry && !scan.file.country) {
        return true;
      }
    }

    {
      /* TODO: Driver's License specific fields */
    }
    // if (isDriversLicense) {
    //   if (scan.file.isCommercialDriversLicense === null) return true;
    //   if (!scan.file.restrictions || trim(scan.file.restrictions) === '') return true;
    // }

    return false;
  };

  const onGotoNext = () => {
    setIsPreviewModalOpen(true);
  };

  const onPreviewModalClose = ({ shouldContinue = false }) => {
    setIsPreviewModalOpen(false);
    if (shouldContinue) {
      if (info.step === 'rescan' && scan.index + 1 === totalScans) {
        onNext({ isRescanDone: true });
      } else {
        onNext();
      }
    }
  };

  useEffect(() => {
    setExpireYear(null);
    setExpireMonth(null);
    setExpireDay(null);
    setIncludeBlankYear(true);
    setIncludeBlankMonth(true);
    setIncludeBlankDay(true);
    setIsUserTryingToSkip(false);
    setRequirements({ ...defaultRequirement });
    setIcon('');
    setMessage('');
    setIsDriversLicense(false);

    if (!scan) return;

    if (scan.code === 'usa-passport') {
      scan.file.country = countries[0];
    }

    setIsDriversLicense(scan.code === 'drivers-license');

    if (scan.status === 'skipped') {
      setIsUserTryingToSkip(true);
    }

    if (scan.file.expirationDate) {
      const momentObj = moment.tz(scan.file.expirationDate, app.timezone);
      const parts = momentObj.format('YYYY-MM-DD').split('-');
      setIncludeBlankYear(false);
      setIncludeBlankMonth(false);
      setIncludeBlankDay(false);
      setExpireYear({ label: parts[0], value: parts[0] });
      setExpireMonth(monthOptions.find(m => m.value === parts[1]));
      setExpireDay({ label: parts[2], value: parts[2] });

      setDayOptions(
        Array(momentObj.daysInMonth())
          .fill('')
          .map((ignored, i) => {
            const day = 1 + i;
            return { label: `${day}`, value: `${day < 10 ? `0${day}` : day}` };
          })
      );
    }

    const documentCode = scan.code.toLocaleLowerCase();
    const requirements = documentRequirements[documentCode];
    if (requirements.issuingAuthority.showCanada) {
      setStates([...usaStates, ...canadaProvinces]);
    } else {
      setStates([...usaStates]);
    }
    setRequirements(requirements);
  }, [scan]);

  const getExtraInfo = () => {
    const i18nTag = `full-documents-list.${scan.code}_extra`;
    const text = t(i18nTag);
    if (text && text !== `badger.${i18nTag}`) {
      return (
        <Alert color="info" style={{ marginTop: 16, marginBottom: 0 }}>
          {text}
        </Alert>
      );
    }
    return null;
  };

  useEffect(() => {
    let currentYear = new Date().getFullYear();
    const years = Array(90)
      .fill('')
      .map((ignored, i) => ({
        label: `${currentYear + i}`,
        value: `${currentYear + i}`,
      }));
    setYearOptions(years);
  }, []);

  return (
    <>
      {icon && message && (
        <Row style={{ marginBottom: 16 }}>
          <Col xs={1} style={{ display: 'flex', alignItems: 'center', maxWidth: isSmallScreen() ? null : 35 }}>
            {icon}
          </Col>
          <Col xs={11} style={{ display: 'flex', alignItems: 'center' }}>
            {message}
          </Col>
        </Row>
      )}
      <div className={classes.optionLabel}>
        <Typography variant="h5">{t(`full-documents-list.${scan.code}`)}</Typography>
      </div>
      <div>
        <div className={classes.scanEntryLeft}>
          <div className={classes.optionContent}>
            {requirements.image && (
              <>
                <div className={classes.optionActionsLabel} style={{ display: 'flex' }}>
                  <FormInputIcon isValid={scan.status === 'success'} isSkipped={scan.status === 'skipped'} />
                  <BadgerFormLabel>{t('scanning.image.label')}</BadgerFormLabel>
                </div>
                <div className={classes.optionActions}>
                  <Typography>
                    <Link
                      className={scan.status === 'skipped' ? classes.skippedScanAction : classes.scanAction}
                      onClick={onClick}
                    >
                      <PhotoCameraIcon fontSize="small" />{' '}
                      {scan.file.uuid && scan.file.path
                        ? t('scanning.image.re_scan_button')
                        : t('scanning.image.scan_button')}
                    </Link>
                  </Typography>
                  <Typography>
                    <Link
                      className={scan.status === 'skipped' ? classes.skippedScanAction : classes.scanAction}
                      onClick={() => fileUploadInputRef.current.click()}
                    >
                      <CloudUploadIcon fontSize="small" /> {t('scanning.image.upload_image_button')}
                      <input
                        ref={fileUploadInputRef}
                        style={{ display: 'none' }}
                        type="file"
                        name="file"
                        accept=".jpg, .jpeg, .pdf, .png"
                        onChange={({ target }) => {
                          const file = target.files[0];
                          if (file) {
                            const fileName = file.name || '';
                            const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLocaleLowerCase();
                            if (['jpg', 'jpeg', 'pdf', 'png'].indexOf(fileExtension) > -1) {
                              onClickFileUpload(file);
                            } else {
                              handleError({
                                message: 'Only JPG, JPEG, PNG, and PDF files may be uploaded.',
                              });
                            }
                          }
                        }}
                      />
                    </Link>
                  </Typography>
                </div>
              </>
            )}

            <Row style={{ marginTop: 24 }}>
              {/* DOCUMENT NUMBER */}
              {requirements.documentNumber.enabled && (
                <>
                  <Col xs={12} sm={4}>
                    <FormGroup>
                      <div style={{ display: 'flex' }}>
                        <FormInputIcon isValid={isDocumentNumberValid()} />
                        <BadgerFormLabel>
                          {t(`scanning.document_number.${requirements.documentNumber.i18nLabelKey}`)}{' '}
                          {!requirements.documentNumber.required && t('scanning.document_number.is_optional')}
                        </BadgerFormLabel>
                      </div>
                      {scan.code === 'social-security-card' ? (
                        <InputMask
                          mask="999-99-9999"
                          className="form-control"
                          value={scan.file.documentNumber || ''}
                          onChange={({ target }) => {
                            scan.file.documentNumber = trim(target.value);
                            updateScan({ ...scan });
                          }}
                        />
                      ) : (
                        <Input
                          value={scan.file.documentNumber || ''}
                          onChange={({ target }) => {
                            scan.file.documentNumber = trim(target.value);
                            updateScan({ ...scan });
                          }}
                        />
                      )}
                      {getExtraInfo()}
                    </FormGroup>
                  </Col>
                  <Col xs={0} sm={12}>
                    &nbsp;
                  </Col>
                </>
              )}

              {/* EXPIRATION DATE */}
              {requirements.expirationDate.enabled && (
                <Col xs={12} sm={6}>
                  <FormGroup>
                    <div style={{ display: 'flex' }}>
                      <FormInputIcon isValid={!!scan.file.expirationDate} />
                      <BadgerFormLabel>
                        {t('scanning.expiration_date.label')}{' '}
                        {!requirements.expirationDate.required && t('scanning.expiration_date.label_optional')}
                      </BadgerFormLabel>
                    </div>
                    <Row>
                      <Col>
                        <i>{t('scanning.expiration_date.year_label')}</i>
                        <NativeSelect
                          id="yearSelect"
                          name="yearSelect"
                          options={yearOptions}
                          value={expireYear}
                          onChange={selectedOption => {
                            setIncludeBlankYear(false);
                            onExpireDateChanged({ year: selectedOption, month: expireMonth, day: expireDay });
                          }}
                          includeBlank={includeBlankYear}
                        />
                      </Col>
                      <Col>
                        <i>{t('scanning.expiration_date.month_label')}</i>
                        <NativeSelect
                          id="monthSelect"
                          name="monthSelect"
                          options={monthOptions}
                          value={expireMonth}
                          onChange={selectedOption => {
                            setIncludeBlankMonth(false);
                            onExpireDateChanged({ year: expireYear, month: selectedOption, day: expireDay });
                          }}
                          disabled={!expireYear}
                          includeBlank={includeBlankMonth}
                        />
                      </Col>
                      <Col>
                        <i>{t('scanning.expiration_date.day_label')}</i>
                        <NativeSelect
                          id="daySelect"
                          name="DaySelect"
                          options={dayOptions}
                          value={expireDay}
                          onChange={selectedOption => {
                            setIncludeBlankDay(false);
                            onExpireDateChanged({ year: expireYear, month: expireMonth, day: selectedOption });
                          }}
                          disabled={!expireMonth}
                          includeBlank={includeBlankDay}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              )}
            </Row>

            {/* ISSUING AUTHORITY STATE */}
            {requirements.issuingAuthority.enabled && requirements.issuingAuthority.hasState && (
              <Row style={{ marginTop: 24 }}>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <div style={{ display: 'flex' }}>
                      <FormInputIcon isValid={!!scan.file.stateAbbreviation} />
                      <BadgerFormLabel>
                        {requirements.issuingAuthority.showCanada
                          ? t('scanning.issuing_authority.state_and_province_label')
                          : t('scanning.issuing_authority.state_label')}{' '}
                        {!requirements.issuingAuthority.required &&
                          t('scanning.issuing_authority.state_label_optional')}
                      </BadgerFormLabel>
                    </div>
                    {isSmallScreen() ? (
                      <>
                        <style scoped>{`
                          .stateSelect {
                            height: 200px;
                          }
                        `}</style>
                        <NativeSelect
                          id="stateSelect"
                          name="stateSelect"
                          className={getNativeSelectClasses()}
                          options={states}
                          value={scan.file.stateAbbreviation}
                          onChange={option => {
                            scan.file.stateAbbreviation = option;
                            updateScan({ ...scan });
                          }}
                          includeBlank={true}
                          disabled={!scan.file.uuid}
                        />
                      </>
                    ) : (
                      <Select
                        placeholder=""
                        className="form-select"
                        options={states}
                        value={scan.file.stateAbbreviation}
                        onChange={option => {
                          scan.file.stateAbbreviation = option;
                          updateScan({ ...scan });
                        }}
                        isDisabled={!scan.file.uuid}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}

            {/* ISSUING AUTHORITY COUNTRY */}
            {requirements.issuingAuthority.enabled && requirements.issuingAuthority.hasCountry && (
              <Row style={{ marginTop: 24 }}>
                <Col xs="12" sm="4">
                  <FormGroup>
                    <div style={{ display: 'flex' }}>
                      <FormInputIcon isValid={!!scan.file.country} />
                      <BadgerFormLabel>
                        {t('scanning.issuing_authority.country_label')}{' '}
                        {!requirements.issuingAuthority.required &&
                          t('scanning.issuing_authority.country_label_optional')}
                      </BadgerFormLabel>
                    </div>
                    {isSmallScreen() ? (
                      <>
                        <style scoped>{`
                          .countrySelect {
                            height: 200px;
                          }
                        `}</style>
                        <NativeSelect
                          id="countrySelect"
                          name="countrySelect"
                          className={getNativeSelectClasses()}
                          options={countries}
                          value={scan.file.country}
                          onChange={option => {
                            scan.file.country = option;
                            updateScan({ ...scan });
                          }}
                          includeBlank={true}
                          disabled={!scan.file.uuid}
                        />
                      </>
                    ) : (
                      <Select
                        placeholder=""
                        className="form-select"
                        options={countries}
                        value={scan.file.country}
                        onChange={option => {
                          scan.file.country = option;
                          updateScan({ ...scan });
                        }}
                        isDisabled={!scan.file.uuid}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}

            {/* TODO: Driver's License specific fields */}
            {/*/!* Extra Drivers License Fields *!/*/}
            {/*{isDriversLicense && (*/}
            {/*  <>*/}
            {/*    <Row style={{ marginTop: 24 }}>*/}
            {/*      <Col xs="12" sm="4">*/}
            {/*        <FormGroup>*/}
            {/*          <div style={{ display: 'flex' }}>*/}
            {/*            <FormInputIcon*/}
            {/*              isValid={*/}
            {/*                scan.file.isCommercialDriversLicense === true ||*/}
            {/*                scan.file.isCommercialDriversLicense === false*/}
            {/*              }*/}
            {/*            />*/}
            {/*            <BadgerFormLabel>*/}
            {/*              {t('scanning.extra_drivers_license.is_commercial_drivers_license')}*/}
            {/*            </BadgerFormLabel>*/}
            {/*          </div>*/}
            {/*          <RadioGroup*/}
            {/*            row*/}
            {/*            aria-label="is_commercial_drivers_license"*/}
            {/*            name="is_commercial_drivers_license"*/}
            {/*            value={`${scan.file.isCommercialDriversLicense}`}*/}
            {/*            onChange={event => {*/}
            {/*              scan.file.isCommercialDriversLicense = event.target.value === 'true';*/}
            {/*              updateScan({ ...scan });*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <FormControlLabel*/}
            {/*              value="true"*/}
            {/*              control={<Radio color="primary" />}*/}
            {/*              label={allTranslations('yes')}*/}
            {/*            />*/}
            {/*            <FormControlLabel*/}
            {/*              value="false"*/}
            {/*              control={<Radio color="primary" />}*/}
            {/*              label={allTranslations('no')}*/}
            {/*            />*/}
            {/*          </RadioGroup>*/}
            {/*        </FormGroup>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}

            {/*    <Row style={{ marginTop: 12 }}>*/}
            {/*      <Col xs="12" sm="4">*/}
            {/*        <FormGroup>*/}
            {/*          <div style={{ display: 'flex' }}>*/}
            {/*            <FormInputIcon isValid={!!scan.file.restrictions && trim(scan.file.restrictions) !== ''} />*/}
            {/*            <div>*/}
            {/*              <BadgerFormLabel>*/}
            {/*                {t('scanning.extra_drivers_license.restrictions')}{' '}*/}
            {/*                <small>*/}
            {/*                  <i>({t('scanning.extra_drivers_license.restrictions_help')})</i>*/}
            {/*                </small>*/}
            {/*              </BadgerFormLabel>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*          <Input*/}
            {/*            value={scan.file.restrictions || ''}*/}
            {/*            onChange={event => {*/}
            {/*              scan.file.restrictions = event.target.value;*/}
            {/*              updateScan({ ...scan });*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        </FormGroup>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*  </>*/}
            {/*)}*/}

            <Row className={classes.skipAction}>
              <Col>
                <MuiFormGroup row>
                  <MuiFormControlLabel
                    control={<Switch color="primary" />}
                    label={t('scanning.skip')}
                    checked={isUserTryingToSkip}
                    onChange={({ target }) => {
                      setIsUserTryingToSkip(target.checked);
                      if (!target.checked && scan.status === 'skipped') onSkipScanEntry(false);
                    }}
                  />
                </MuiFormGroup>
              </Col>
            </Row>

            {isUserTryingToSkip && (
              <Alert color="warning">
                <MuiFormGroup row>
                  <MuiFormControlLabel
                    style={{ alignItems: 'flex-start' }}
                    control={<Checkbox color="primary" />}
                    label={t('pre-scanning.refuse-statement')}
                    checked={scan.status === 'skipped'}
                    onChange={({ target }) => onSkipScanEntry(target.checked)}
                  />
                </MuiFormGroup>
              </Alert>
            )}
          </div>
        </div>
      </div>

      {info.step === 'rescan' && scan.index + 1 === totalScans ? (
        <Portal container={document.getElementById('badgerNextButton')}>
          <Button
            size={isSmallScreen() ? 'small' : 'large'}
            variant="contained"
            color="primary"
            onClick={onGotoNext}
            className={classes.doneButton}
            disabled={isNextButtonDisabled()}
          >
            {t('rescan-done')}
          </Button>
        </Portal>
      ) : (
        <NextButton onClick={onGotoNext} isDisabled={isNextButtonDisabled()} />
      )}

      <ScanPreview
        isOpen={isPreviewModalOpen}
        onClose={onPreviewModalClose}
        scan={scan}
        requirements={requirements}
        startOverAction={startOverAction}
      />
    </>
  );
}
