import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { basePaymentChoices, invoicePaymentChoice, noChargePaymentChoice } from '../../../../../App';
import Feature from '../../../../../shared/Feature';
import EscortEndorsementPicker from '../../BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import DrivingEndorsementPicker from '../../BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import { getDrivingOptions } from '../../../../../shared/BadgeTypes/drivingOptionsUtils';

export default function InfoContent({ badgeApplication }) {
  if (!badgeApplication) return null;

  const { applicant, signatory, isRenewal, badgeType, doors, purpose, paymentChoice } = badgeApplication;

  const { fullName: applicantFullName } = applicant;
  const { fullName: signatoryFullName, company } = signatory;
  const { name: companyName } = company;

  const getPaymentChoiceValue = paymentChoice => {
    const allPaymentChoices = [...basePaymentChoices, noChargePaymentChoice, invoicePaymentChoice];
    const match = allPaymentChoices.find(({ value }) => value === paymentChoice);
    return match?.label || 'None selected';
  };

  const getDrivingOption = () => {
    const drivingOptions = getDrivingOptions(badgeApplication?.badgeType?.config?.endorsements?.driving);
    return drivingOptions.find(option => option.value === badgeApplication?.badge?.driving) || 'ERROR';
  };

  return (
    <Card>
      <CardBody>
        <div style={{ minHeight: 264 }}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <ReadOnlyFormData label="Applicant" value={applicantFullName} />
            </Grid>
            <Grid item sm={12} md={6}>
              <ReadOnlyFormData
                label="Badge Type (Application Type)"
                value={`${badgeType.displayName} (${isRenewal ? 'Renewal' : 'New'})`}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: 16 }}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <ReadOnlyFormData
                  label="Company (Authorized Signatory)"
                  value={`${companyName} (${signatoryFullName})`}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <DrivingEndorsementPicker
                  isReadOnly={true}
                  context={{
                    drivingEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.driving,
                    drivingSelection: getDrivingOption(),
                    setDrivingSelection: () => false,
                    shouldSkipDefaultSelection: true,
                    data: { badgeType: badgeApplication?.badgeType },
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <EscortEndorsementPicker
                  isReadOnly={true}
                  context={{
                    escortEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.escort,
                    escortSelection: badgeApplication?.badge?.isEscort
                      ? { label: 'yes', value: 1 }
                      : { label: 'no', value: 0 },
                    setEscortSelection: () => false,
                    shouldSkipDefaultSelection: true,
                    data: { badgeType: badgeApplication?.badgeType },
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 16 }}>
            <Grid container spacing={3}>
              {doors && (
                <Grid item sm={12} md={6}>
                  <ReadOnlyFormData
                    label="Which door, pedestrian gate, and/or vehicle gate access is requested?"
                    value={doors}
                  />
                </Grid>
              )}
              {purpose && (
                <Grid item sm={12} md={6}>
                  <ReadOnlyFormData label="For what purpose does this individual require a badge?" value={purpose} />
                </Grid>
              )}
            </Grid>
          </div>

          <Feature id="payments">
            <div style={{ marginTop: 16 }}>
              <Grid container spacing={3}>
                <Grid item sm={12} md={6}>
                  <ReadOnlyFormData label="Payment Choice" value={getPaymentChoiceValue(paymentChoice)} />
                </Grid>
              </Grid>
            </div>
          </Feature>
        </div>
      </CardBody>
    </Card>
  );
}
