import React, { Component } from 'react';
import moment from 'moment';

export default class QuickAuditFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
          <a className="mr-5" target="_blank" rel="noreferrer" href="https://airportbadges.com/terms">
            Terms & Conditions
          </a>
          <a className="mr-5" target="_blank" rel="noreferrer" href="https://airportbadges.com/privacy">
            Privacy Policy
          </a>
          <a className="mr-5" target="_blank" rel="noreferrer" href="https://airportbadges.com/help">
            Help
          </a>
        </span>
        <span className="float-right">
          <a href={'https://airportbadges.com'} target="_blank" rel="noreferrer">
            AirBadge, LLC &copy; {moment().format('YYYY')}
          </a>
        </span>
      </React.Fragment>
    );
  }
}
