import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AppContext } from '../../../../../App';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import request from '../../../../../ajax/Request';

const useStyles = makeStyles(theme => ({
  code: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: 0,
    height: 100,
    backgroundColor: '#e4e7ea',
  },
  codeBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'xx-large',
    letterSpacing: 5,
    fontWeight: 500,
  },
  copy: {
    marginTop: theme.spacing(2),
    cursor: 'copy',
  },
  placeholderText: {
    fontSize: 16,
    letterSpacing: 'normal',
    textAlign: 'center',
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
  customSettingsBox: {
    marginTop: theme.spacing(3),
  },
  timer: {
    fontSize: 14,
    letterSpacing: 'normal',
    textAlign: 'center',
    fontStyle: 'italic',
  },
}));

export default function FingerprintingCodeModal({ isOpen, onClose, badgeApplicationUuid }) {
  const app = useContext(AppContext);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const [son, setSon] = useState('');
  const [soi, setSoi] = useState('');
  const [ipac, setIpac] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [[mins, secs], setCountdown] = useState([4, 59]);

  const onModalClose = () => {
    setCountdown([4, 59]);
    setIsGenerating(false);
    setCode('');
    setSon('');
    setSoi('');
    setIpac('');
    setIsModalOpen(false);
    onClose({ son, soi, ipac });
  };

  const loadSettings = () => {
    setIsGenerating(true);
    request(app.user, 'GET', `fingerprints/settings/${badgeApplicationUuid}`)
      .then(({ success, ...rest }) => {
        if (success) {
          setSon(rest.son);
          setSoi(rest.soi);
          setIpac(rest.ipac);
        }
      })
      .catch(e => console.error(e))
      .finally(() => setIsGenerating(false));
  };

  const generateCode = () => {
    setIsGenerating(true);
    const payload = { son, soi, ipac };
    request(app.user, 'POST', `fingerprints/access-code/${badgeApplicationUuid}`, payload)
      .then(({ success, code }) => {
        if (success) {
          setCountdown([4, 59]);
          setCode(code);
        }
      })
      .catch(e => console.error(e))
      .finally(() => setIsGenerating(false));
  };

  // Countdown timer
  useEffect(() => {
    let timerInterval = setInterval(() => {
      if (mins === 0 && secs === 0) {
        setCode('');
        clearInterval(timerInterval);
      } else if (secs === 0) {
        setCountdown([mins - 1, 60]);
      } else {
        setCountdown([mins, secs - 1]);
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [mins, secs]);

  useEffect(() => {
    if (!isOpen || !badgeApplicationUuid) return;
    loadSettings();
    setIsModalOpen(true);
  }, [isOpen, badgeApplicationUuid]);

  if (!isModalOpen || !badgeApplicationUuid) return null;

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>One-time use fingerprint code</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div>Enter this code into the fingerprinting machine to begin the process</div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center" style={{ flexDirection: 'column' }}>
            <Card className={classes.code}>
              <CardBody className={classes.codeBody}>
                {code ? (
                  <div>
                    <div style={{ textAlign: 'center' }}>{code}</div>
                    <div className={classes.timer}>
                      {mins === 0 && secs === 0 ? null : (
                        <div>
                          <span>Expires in </span>
                          <span>
                            {mins}:{secs < 10 ? `0${secs}` : secs}
                          </span>
                          <span> minutes</span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <button className={`btn btn-link ${classes.placeholderText}`} onClick={generateCode}>
                    Click here to generate a new code
                  </button>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Hiding this for now until we really need it */}
        <Accordion className={classes.customSettingsBox} style={{ display: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="custom-fingerprint-settings-content"
            id="custom-fingerprint-settings-header"
          >
            Custom Settings
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Row>
              <Col>
                <div style={{ marginBottom: 16 }}>
                  These will be sent to the fingerprinting machine after the code is entered. If you change any of these
                  settings, you will need to generate a new code.
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>SON</Label>
                  <Input
                    type="text"
                    value={son || ''}
                    disabled={isGenerating}
                    onChange={event => {
                      setSon(event.target.value);
                      setCode('');
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>SOI</Label>
                  <Input
                    type="text"
                    value={soi || ''}
                    disabled={isGenerating}
                    onChange={event => {
                      setSoi(event.target.value);
                      setCode('');
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>IPAC</Label>
                  <Input
                    type="text"
                    value={ipac || ''}
                    disabled={isGenerating}
                    onChange={event => {
                      setIpac(event.target.value);
                      setCode('');
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </AccordionDetails>
        </Accordion>
      </ModalBody>
      <ModalFooter>
        <ModalActions closeLabel="Close" onClose={onModalClose} onSave={null} />
      </ModalFooter>
    </Modal>
  );
}
