import React, { useContext, useState } from 'react';
import { Alert, FormGroup, Label } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import InputWithCharacterLimit from '../../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { handleError } from '../../../../../../utils';

export default function ResetPaymentAction() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState('');

  const app = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);

  const onResetPayment = () => {
    app.api.toggleLoading(true);
    badgeApplicationApi
      .resetPayment({ badgeApplicationUuid: badgeApplication.uuid, reason })
      .then(() => {
        onModalClose();
        onActionDone({ shouldReload: true });
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const isSaveDisabled = () => {
    return !reason;
  };

  const onModalClose = () => {
    setReason('');
    setIsModalOpen(false);
  };

  const getRefundMessage = () => {
    const { paymentChoice, payments } = badgeApplication;

    if (paymentChoice === 'no-charge' || payments.length === 0) {
      return null;
    }

    const mostRecentPayment = payments[0];
    if (mostRecentPayment.status !== 'paid') {
      return null;
    }

    const { paidBy } = mostRecentPayment;
    if (!paidBy) {
      return <Alert color="info">A refund for the previous payment will need to be handled outside of AirBadge.</Alert>;
    } else {
      return (
        <Alert color="info">
          A refund for the previous payment made by <i>{paidBy}</i> will need to be handled outside of AirBadge.
        </Alert>
      );
    }
  };

  const isHidden = () => {
    return (
      !!badgeApplication.active === false || !!badgeApplication.isPaid === false || isCurrentUserSignatory === true
    );
  };

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <FontAwesomeIcon className="fa-solid fa-money-bill-transfer" />
        </ListItemIcon>
        <ListItemText primary="Reset Payment" />
      </ListItem>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Reset Payment Confirmation"
        isOpen={isModalOpen}
        onClose={onModalClose}
        saveLabel="Reset Payment"
        onSave={onResetPayment}
        saveDisabled={isSaveDisabled()}
        saveColor="warning"
      >
        <p>This will allow another payment to be made for this badge application.</p>

        {getRefundMessage()}

        <FormGroup>
          <Label className="required">Reason</Label>
          <div style={{ fontSize: 'smaller', marginBottom: 6 }}>
            This will be added to the comments/activity log for this badge application
          </div>
          <InputWithCharacterLimit limit="255" value={reason} onChange={value => setReason(value)} />
        </FormGroup>
      </AirBadgeModal>
    </>
  );
}
