import React, { useContext, useEffect, useState } from 'react';
import { Alert, Table } from 'reactstrap';
import ReactTable from 'react-table';
import { Chip } from '@material-ui/core';
import moment from 'moment-timezone';
import { AppContext, colors, learningManagementSystems } from '../../../../../App';
import trainingCourseApi from '../../../../../ajax/Training/trainingCourseApi';
import { getLMSLabel, handleError } from '../../../../../utils';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';

function LabelWithTitle({ label }) {
  return <span title={label}>{label}</span>;
}

const columns = ({ timezone, lmsList, statusColors }) => [
  {
    id: 'lms',
    Header: 'System',
    accessor: ({ lms }) => <LabelWithTitle label={getLMSLabel(lms)} />,
    show: lmsList.length > 1,
    filterable: false,
    width: 100,
    sortable: false,
  },
  {
    id: 'name',
    Header: 'Course',
    accessor: ({ courseName }) => <LabelWithTitle label={courseName} />,
    show: true,
    filterable: false,
    sortable: false,
  },
  {
    id: 'date',
    Header: 'Date / Time',
    accessor: ({ date }) => <LabelWithTitle label={moment.tz(date, timezone).format('MM/DD/YYYY hh:mm A')} />,
    width: 190,
    show: true,
    filterable: false,
    sortable: false,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => (
      <Chip label={status} style={{ backgroundColor: statusColors[status], color: '#fff' }}></Chip>
    ),
    width: 110,
    show: true,
    filterable: false,
    sortable: false,
  },
];

export default function PersonAccomplishments({ selectedPerson, isOpen, badgeApplication }) {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [accomplishments, setAccomplishments] = useState([]);
  const [enabledLmsList, setEnabledLmsList] = useState([]);
  const [error, setError] = useState(null);

  const { isIETEnabled, isDigicastEnabled, isSSIEnabled, isAirBadgeLMSEnabled } = app.airport;
  let totalConnectedLMS = 0;
  if (isIETEnabled) totalConnectedLMS += 1;
  if (isDigicastEnabled) totalConnectedLMS += 1;
  if (isSSIEnabled) totalConnectedLMS += 1;
  if (isAirBadgeLMSEnabled) totalConnectedLMS += 1;

  const statusColors = {
    pass: colors.success,
    Pass: colors.success,
    PASS: colors.success,
    fail: colors.danger,
    Fail: colors.danger,
    FAIL: colors.danger,
    'in progress': colors.warning,
    'In Progress': colors.warning,
    'IN PROGRESS': colors.warning,
  };

  const requestData = () => {
    setError(null);
    setAccomplishments([]);
    setIsLoading(true);
    trainingCourseApi
      .getAccomplishments({ userUuid: selectedPerson.uuid })
      .then(({ success, results }) => {
        if (success && results?.length) {
          setAccomplishments(results.slice(0, 20));
        }
      })
      .catch(error => {
        handleError({ error });
        setError('Unable to load training accomplishments');
      })
      .finally(() => setIsLoading(false));
  };

  const buildBadgeApplicationAssignmentsDisplay = () => {
    if (!badgeApplication?.auxiliaryConfig?.training) return null;

    const entries = sortBy(cloneDeep(badgeApplication?.auxiliaryConfig?.training), ['system', 'type', 'name']);
    let rows = [];

    if (entries.length === 0) {
      rows.push(
        <tr key="empty">
          <td colSpan={5} className="text-center">
            <i>No training selected</i>
          </td>
        </tr>
      );
    } else {
      rows = entries.map(row => {
        const { system, type, code, name } = row;
        return (
          <tr key={`${system}-${type}-${code}`}>
            {totalConnectedLMS > 1 ? <td>{getLMSLabel(system)}</td> : null}
            <td style={{ textTransform: 'capitalize' }}>{type}</td>
            <td>{name}</td>
          </tr>
        );
      });
    }

    return (
      <>
        <div style={{ marginBottom: 16, fontWeight: 500, fontSize: 'larger' }}>
          <i className="fa-solid fa-chalkboard-user mr-2"></i>Assignments
        </div>
        <Table striped>
          <thead>
            <tr>
              {totalConnectedLMS > 1 ? <th style={{ width: 120 }}>System</th> : null}
              <th style={{ width: 70 }}>Type</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <div style={{ marginTop: 24, marginBottom: 16, fontWeight: 500, fontSize: 'larger' }}>
          <i className="fa-solid fa-list-check mr-2"></i>Accomplishments
        </div>
      </>
    );
  };

  useEffect(() => {
    setEnabledLmsList([]);

    if (!selectedPerson || !isOpen) return;

    const { isIETEnabled, isAirBadgeLMSEnabled, isSSIEnabled } = app.airport;
    let newLmsList = [];
    if (isIETEnabled) newLmsList.push(learningManagementSystems.find(lms => lms.value === 'iet'));
    if (isAirBadgeLMSEnabled) newLmsList.push(learningManagementSystems.find(lms => lms.value === 'airbadge_lms'));
    if (isSSIEnabled) newLmsList.push(learningManagementSystems.find(lms => lms.value === 'ssi'));
    setEnabledLmsList(newLmsList);
    requestData();
  }, [selectedPerson, isOpen, app.airport]);

  if (!selectedPerson || !isOpen) return null;

  return (
    <>
      {!!error && <Alert color="danger">{error}</Alert>}
      {!!badgeApplication && buildBadgeApplicationAssignmentsDisplay()}
      <div className="table-responsive" style={{ maxHeight: 450, minHeight: 264 }}>
        <ReactTable
          manual
          loading={isLoading}
          className="border-0 -striped"
          data={accomplishments}
          columns={columns({ timezone: app.timezone, lmsList: enabledLmsList, statusColors })}
          pages={1}
          minRows={0}
          defaultPageSize={50}
          showPagination={false}
        />
      </div>
    </>
  );
}
