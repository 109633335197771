import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { AppContext } from '../../../../App';
import StaggeredDatePicker from '../../../../shared/components/DatePickers/StaggeredDatePicker';
import AuthorizedSignatoryUpdateRequest from '../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryUpdateRequest';
import { handleError } from '../../../../utils';

export default function ChangeSignatoryTrainingExpirationModal({ isOpen, onClose, signatory }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newDate, setNewDate] = useState(null);

  const onModalClose = ({ shouldReload }) => {
    setNewDate(null);
    setIsModalOpen(false);
    onClose({ shouldReload });
  };

  const onSaveChange = () => {
    const updates = {
      trainingExpires: newDate,
    };

    app.api.toggleLoading(true);
    AuthorizedSignatoryUpdateRequest(null, updates, signatory.profileUuid)
      .then(({ success }) => {
        if (success) onModalClose({ shouldReload: true });
        else handleError({ message: 'Unable to change training expiration date' });
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  useEffect(() => {
    if (!isOpen) return;

    setIsModalOpen(isOpen);
  }, [isOpen]);

  if (!isOpen || !signatory) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Change Training Expiration Date"
      isOpen={isModalOpen}
      onClose={() => onModalClose({ shouldReload: false })}
      closeLabel="Close"
      onSave={onSaveChange}
      saveLabel="Save Changes"
      saveDisabled={!newDate}
    >
      <Row>
        <Col>
          <FormGroup>
            <Label>Select a date</Label>
            {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
            <StaggeredDatePicker
              startingYear={new Date().getFullYear()}
              endingYear={new Date().getFullYear() + 20}
              onValidDate={({ year, month, day }) => setNewDate(`${year}-${month}-${day}`)}
              onInvalidDate={() => setNewDate(null)}
            />
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
