import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { AppContext, colors } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import peopleApi from '../../../../../../ajax/People/peopleApi';
import { buildFullName, handleError } from '../../../../../../utils';
import { hasFeature } from '../../../../../../shared/Feature';
import useConfirm from '../../../../../../shared/useConfirm';
import CreateBadgeApplicationBadgeModal, {
  getDefaultBadgeExpiration,
} from '../../../../Badges/BadgesList/CreateBadgeApplicationBadgeModal/CreateBadgeApplicationBadgeModal';
import IssuePacsBadgeModal from '../../../../Badges/BadgesList/IssuePacsBadgeModal';
import PrintBadgeModal from '../../../../Badges/BadgesList/PrintBadgeModal';
import badgeApi from '../../../../../../ajax/Badge/badgeApi';
import BadgeCreateRequest from '../../../../../../ajax/Badge/BadgeCreateRequest';

export default function IssueBadgeAction() {
  const app = useContext(AppContext);
  const showConfirm = useConfirm();
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const [isIssueBadgeModalOpen, setIsIssueBadgeModalOpen] = useState(false);
  const [isPacsEnabled, setIsPacsEnabled] = useState(false);
  const [hasPrintingFeatureEnabled, setHasPrintingFeatureEnabled] = useState(false);
  const [hasPacsActivationFeatureEnabled, setHasPacsActivationFeatureEnabled] = useState(false);
  const [isPrintBadgeModalOpen, setIsPrintBadgeModalOpen] = useState(false);

  const isHidden = useCallback(() => {
    const { active, config } = badgeApplication;
    const { issueBadge } = config;
    if (!!active === false) {
      // Badge Application is not active
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for doing this
      return true;
    }
    if (issueBadge.isEnabled && issueBadge.isComplete) {
      // Badge has already been issued
      return true;
    }
    return badgeApplication.currentPhase.toLocaleUpperCase() !== 'ISSUE';
  }, [badgeApplication, isCurrentUserSignatory]);

  const onIssueBadgeClick = () => {
    if (isIssueBadgeModalOpen) return;

    const isPaymentsFeatureEnabled = hasFeature('payments', app?.airport?.config?.features || []);
    if (
      isPaymentsFeatureEnabled &&
      !badgeApplication.isPaid &&
      ['applicant', 'signatory', 'in-person'].indexOf(badgeApplication.paymentChoice) > -1
    ) {
      return showConfirm({
        title: 'Payment Notice',
        content: (
          <Alert color="warning">
            <p>No payment has been received for this Badge Application.</p>
            <p>Payment must be settled before the badge can be issued.</p>
          </Alert>
        ),
        onClose: false,
      });
    }

    if (!isPacsEnabled && !hasPrintingFeatureEnabled) return setIsIssueBadgeModalOpen(true);

    if (!isPacsEnabled && hasPrintingFeatureEnabled) {
      if (badgeApplication?.badge?.template) {
        return setIsIssueBadgeModalOpen(true);
      }
      return setIsPrintBadgeModalOpen(true);
    }

    if (!isPacsEnabled) return setIsIssueBadgeModalOpen(true);

    app.api.toggleLoading(true);
    peopleApi
      .getPacsId({ userUuid: badgeApplication?.applicant?.uuid })
      .then(({ pacsId }) => {
        if (pacsId) {
          if (hasPrintingFeatureEnabled && !badgeApplication?.badge?.template) {
            return setIsPrintBadgeModalOpen(true);
          } else {
            return setIsIssueBadgeModalOpen(true);
          }
        }

        const applicantName = badgeApplication?.applicant?.fullName || 'Error';
        return app.api.confirmMessage(
          'Unable to Issue Badge',
          <div>
            <p>
              <strong>{applicantName} </strong>
              <span>has not been reconciled with PACS.</span>
            </p>
            <p>The applicant must be created in PACS and reconciled into AirBadge before you can proceed.</p>
          </div>,
          false,
          false
        );
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onIssueBadgeClose = useCallback(
    didDeactivate => {
      setIsIssueBadgeModalOpen(false);
      onActionDone({ shouldReload: didDeactivate });
    },
    [setIsIssueBadgeModalOpen, onActionDone]
  );

  const createMisprintedBadge = ({ number, cardNumber, expiration, template }) => {
    const now = moment.tz(app.timezone).format('YYYY-MM-DD HH:mm:ss');
    const badge = {
      badge_holder_uuid: badgeApplication.applicant.uuid,
      authorized_signatory_profile_uuid: badgeApplication.signatory.profileUuid,
      type: badgeApplication.badgeType.badgeTypeID,
      status: 'misprint',
      group: null,
      number: number || '???',
      card_serial_number: cardNumber || '???',
      effective: now,
      expires: `${expiration} 00:00:00`,
      driving: badgeApplication.badge.driving,
      parking: 0,
      escort: badgeApplication.badge.isEscort ? 1 : 0,
      leo: badgeApplication.badge.isLEO ? 1 : 0,
      collected: now,
      template: template || null,
    };
    app.api.toggleLoading(true);
    BadgeCreateRequest('authenticated-user', badge)
      .then((success, message) => {
        if (success === false) handleError({ message: message });
      })
      .catch(error => handleError({ error, message: 'Badge could not be created' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onPrintBadgeModalClose = data => {
    if (data) {
      if (data.isMisprint) {
        createMisprintedBadge(data);
      } else {
        app.api.toggleLoading(true);
        const { number, cardNumber, expiration, isEscort, driving, template } = data;
        const updates = {};
        if (number) updates.newBadgeId = number;
        if (cardNumber) updates.newCardNumber = cardNumber;
        if (expiration) updates.newDates = { effectiveDate: null, expirationDate: `${expiration} 00:00:00` };
        if (isEscort === true || isEscort === false) updates.isEscort = isEscort;
        if (driving) updates.driving = driving;
        if (template) updates.newTemplate = template;
        setIsPrintBadgeModalOpen(false);
        badgeApi
          .updateBadge(badgeApplication.badge.uuid, updates)
          .then(() => {
            onActionDone({ shouldReload: true }).then(() => {
              setIsIssueBadgeModalOpen(true);
            });
          })
          .catch(error => handleError({ error }))
          .finally(() => app.api.toggleLoading(false));
      }
    } else {
      setIsPrintBadgeModalOpen(false);
    }
  };

  const getLabel = () => {
    if (!hasPrintingFeatureEnabled && isPacsEnabled && hasPacsActivationFeatureEnabled) {
      throw new Error('Printing feature is required to use PACS with badge activation');
    }

    if (hasPrintingFeatureEnabled) {
      if (badgeApplication?.badge?.template) {
        return 'Issue Badge';
      }
      return 'Print & Issue Badge';
    }

    return 'Issue Badge';
  };

  const buildModal = () => {
    let shouldReturnBadgeCreateModal = false;
    let shouldReturnPacsModal = false;
    let shouldReturnPrintingModal = false;

    if (!isPacsEnabled && !hasPrintingFeatureEnabled) {
      shouldReturnBadgeCreateModal = true;
      shouldReturnPacsModal = false;
      shouldReturnPrintingModal = false;
    } else if (!isPacsEnabled && hasPrintingFeatureEnabled) {
      shouldReturnBadgeCreateModal = true;
      shouldReturnPacsModal = false;
      shouldReturnPrintingModal = true;
    } else if (isPacsEnabled && !hasPrintingFeatureEnabled) {
      shouldReturnBadgeCreateModal = false;
      shouldReturnPacsModal = true;
      shouldReturnPrintingModal = false;
    } else if (isPacsEnabled && hasPrintingFeatureEnabled) {
      let features = app?.airport?.pacsConfig?.features || [];
      const isPacsActivationEnabled = features.includes('allow_activation_from_airbadge');
      shouldReturnBadgeCreateModal = isPacsActivationEnabled;
      shouldReturnPacsModal = !isPacsActivationEnabled;
      shouldReturnPrintingModal = true;
    }

    const { badgeType, signatory, applicant, badge } = badgeApplication;
    let expiration = moment().endOf('month').add(2, 'years').format('YYYY-MM-DD');
    if (badgeType.config) {
      expiration = getDefaultBadgeExpiration({ badgeType, timezone: app.timezone });
    }

    return (
      <>
        {shouldReturnBadgeCreateModal && (
          <CreateBadgeApplicationBadgeModal
            isOpen={isIssueBadgeModalOpen}
            onClose={onIssueBadgeClose}
            badgeApplication={badgeApplication}
          />
        )}

        {shouldReturnPacsModal && (
          <IssuePacsBadgeModal
            isOpen={isIssueBadgeModalOpen}
            onClose={onIssueBadgeClose}
            badgeApplication={badgeApplication}
            badgeTypes={badgeApplication.badgeTypes}
          />
        )}

        {shouldReturnPrintingModal && (
          <PrintBadgeModal
            isOpen={isPrintBadgeModalOpen}
            onClose={onPrintBadgeModalClose}
            badgeType={badgeType}
            badgeData={{
              companyName: signatory?.company?.name || '',
              number: '',
              firstName: applicant?.firstName || '',
              middleName: applicant?.middleName || '',
              lastName: applicant?.lastName || '',
              fullName: buildFullName({ ...applicant, middleName: null }), // no need for middle name
              airportPersonId: applicant?.airportPersonId || '',
              dateOfBirth: applicant?.dob || '',
              expiration,
              endorsements: {
                driving: badge?.driving || 'none',
                isEscort: !!badge?.isEscort,
                isParking: false,
                isLeo: !!badge?.isLEO,
              },
            }}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (!app.airport) return;
    const { isPacsEnabled, pacsConfig, config } = app.airport;
    setIsPacsEnabled(isPacsEnabled);
    setHasPrintingFeatureEnabled(hasFeature('printing', config?.features || []));
    setHasPacsActivationFeatureEnabled(hasFeature('allow_activation_from_airbadge', pacsConfig?.features || []));
  }, [app.airport]);

  if (isHidden()) return null;

  return (
    <ListItem button onClick={onIssueBadgeClick} disabled={isIssueBadgeModalOpen || isPrintBadgeModalOpen}>
      <ListItemIcon>
        <AssignmentIndIcon style={{ color: colors.primary }} />
      </ListItemIcon>
      <ListItemText primary={getLabel()} />

      {buildModal()}
    </ListItem>
  );
}
