import React, { useEffect, useContext, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import moment from 'moment';
import 'moment-timezone';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { AppContext } from '../../../../../App';
import filesApi from '../../../../../ajax/File/filesApi';
import FileViewModal from '../../../../../shared/components/FileViewModal/FileViewModal';

const columns = ({ timezone, onViewFile }) => [
  {
    id: 'actions',
    Header: null,
    accessor: row => {
      if (row.mimeType === 'application/octet-stream') {
        return null;
      }

      return (
        <span title="View File" aria-label="View File" style={{ cursor: 'pointer' }} onClick={() => onViewFile(row)}>
          <ImageSearchIcon color="primary" />
        </span>
      );
    },
    show: true,
    mobile: true,
    filterable: false,
    sortable: false,
    maxWidth: 20,
  },
  {
    id: 'fileTypeName',
    Header: 'Type',
    accessor: row => <span title={row.fileTypeName}>{row.fileTypeName}</span>,
    show: true,
    mobile: true,
    filterable: false,
    sortable: false,
  },
  {
    id: 'uploadedByName',
    Header: 'Created By',
    accessor: row => {
      const content = `${row.uploadedByName} (${moment.tz(row.uploadedOn, timezone).format('MM/DD/YYYY hh:mm A')})`;
      return <span title={content}>{content}</span>;
    },
    show: true,
    mobile: true,
    filterable: false,
    sortable: false,
  },
];

export default function BadgeApplicationFiles({ badgeApplication }) {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const loadData = () => {
    setIsLoading(true);

    return filesApi
      .listForBadgeApplication({ user: app.user, badgeApplicationUuid: badgeApplication.uuid })
      .then(({ success, files }) => {
        if (success) setFiles(files);
      })
      .catch(e => console.error(e))
      .finally(() => setIsLoading(false));
  };

  const onViewFile = file => {
    setSelectedFile(file);
    setIsViewModalOpen(true);
  };

  const onCloseFileModal = () => {
    setSelectedFile(null);
    setIsViewModalOpen(false);
  };

  useEffect(() => {
    if (!badgeApplication) return;

    loadData();
  }, [badgeApplication]);

  if (!badgeApplication) return false;

  return (
    <Card>
      <CardBody>
        <div className="table-responsive" style={{ maxHeight: 264, minHeight: 264 }}>
          <ReactTable
            manual
            defaultSorted={[{ id: 'uploadedByName', desc: true }]}
            loading={isLoading}
            className="border-0 -striped"
            data={files}
            columns={columns({ timezone: app.timezone, onViewFile })}
            pages={1}
            minRows={0}
            defaultPageSize={10000}
            showPagination={false}
          />
        </div>

        <FileViewModal file={selectedFile} isOpen={isViewModalOpen} onClose={onCloseFileModal} />
      </CardBody>
    </Card>
  );
}
