import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';
import InputMask from 'react-input-mask';
import { Col, Row } from 'reactstrap';
import { styled, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { colors } from '../../../../../App';
import request from '../../../../../ajax/Request';
import { BadgerContext } from '../../../Badger';
import { isSmallScreen } from '../../../../../utils';

const MessagesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  height: '100%',
  marginTop: theme.spacing(1),
}));

function WarningMessage() {
  const theme = useTheme();
  const { t } = useTranslation('translation');

  return (
    <Alert severity="warning" style={{ width: 800, marginBottom: theme.spacing(3) }}>
      <AlertTitle>
        <strong>{t('badger.pre-scanning.warning-title')}</strong>
      </AlertTitle>
      <Typography variant="subtitle1" style={{ marginTop: theme.spacing(1) }}>
        {t('badger.pre-scanning.warning-statement')}
      </Typography>
    </Alert>
  );
}

const MAX_TEXTS = 5;

function InfoMessage() {
  const badger = useContext(BadgerContext);
  const theme = useTheme();
  const { t } = useTranslation('translation');
  const [phone, setPhone] = useState('');
  const [isTextSent, setIsTextSent] = useState(false);
  const [errors, setErrors] = useState('');
  const [numTextsSent, setNumTextsSent] = useState(0);

  const buildMobileURL = () => {
    return `${window.location.href}&language=${badger.language}&stopAfterScans=true`;
  };

  const reset = () => {
    setErrors('');
    setIsTextSent(false);
    setPhone('');
  };

  const sendLink = () => {
    if (numTextsSent >= MAX_TEXTS) {
      setIsTextSent(false);
      setErrors(t('badger.pre-scanning.txt-msg-too-many-tries'));
      return;
    }
    setErrors('');
    setNumTextsSent(numTextsSent + 1);
    const data = { key: badger.key, phone, url: buildMobileURL() };
    request(null, 'POST', `badger/send-text`, data)
      .then(response => {
        if (response.success) {
          setIsTextSent(true);
        } else {
          setIsTextSent(false);
          setErrors(t('badger.pre-scanning.txt-msg-error'));
        }
      })
      .catch(error => {
        console.error(error);
        setErrors(t('badger.pre-scanning.txt-msg-error'));
      });
  };

  return (
    <Card style={{ width: 800, marginBottom: theme.spacing(3) }}>
      <CardContent>
        {isTextSent ? (
          <>
            <Typography variant="subtitle1" style={{ marginTop: theme.spacing(1) }}>
              {t('badger.pre-scanning.txt-msg-sent')} <strong>{phone}</strong>
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: theme.spacing(1) }}>
              {t('badger.pre-scanning.txt-msg-delay-notice')}
            </Typography>
            <Accordion style={{ marginTop: theme.spacing(2) }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-primary">{t('badger.pre-scanning.txt-msg-trouble')}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column', alignItems: 'flex-start', paddingTop: 0 }}>
                <Typography component="div" variant="subtitle1">
                  <ul>
                    <li>{t('badger.pre-scanning.txt-msg-trouble-statement1')}</li>
                    <li>{t('badger.pre-scanning.txt-msg-trouble-statement2')}</li>
                    <li>{t('badger.pre-scanning.txt-msg-trouble-statement3')}</li>
                  </ul>
                </Typography>
                <Typography>
                  <strong>{t('badger.pre-scanning.txt-msg-trouble-link-title')}</strong>
                </Typography>
                <Typography>
                  <button type="button" onClick={reset} className="btn btn-link p-0">
                    {t('badger.pre-scanning.txt-msg-trouble-link')}
                  </button>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <Typography variant="subtitle1" style={{ marginTop: theme.spacing(1) }}>
                  {t('badger.pre-scanning.info-statement')}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center', paddingTop: theme.spacing(2) }}>
                <FormGroup style={{ marginTop: theme.spacing(2) }}>
                  <InputMask
                    placeholder="+1 (___) ___-____"
                    mask="+1 (999) 999-9999"
                    className="form-control"
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                    style={{ width: 215 }}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: theme.spacing(2), width: 215 }}
                    onClick={sendLink}
                    disabled={!phone || phone === '+1 (___) ___-____'}
                  >
                    {t('badger.pre-scanning.txt-msg-button')}
                  </Button>
                </FormGroup>
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <QRCode size={140} value={buildMobileURL()} />
              </Col>
            </Row>
            {errors && (
              <Typography variant="subtitle2" style={{ color: colors.danger, marginTop: theme.spacing(1) }}>
                {errors}
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default function ScanList({ scans, onOverlayShown, onBegin, onDoneWithMobileDevice }) {
  const theme = useTheme();
  const { t } = useTranslation('translation');
  const [choice, setChoice] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);

  const onButtonClicked = () => {
    if (isMobile) {
      onBegin();
    } else {
      setShowOverlay(true);
      onOverlayShown(true);
    }
  };

  const onContinue = () => {
    onDoneWithMobileDevice({
      isDoneWithDocuments: choice && choice === 'yes',
      isUserRequestingDesktop: choice && choice === 'no',
    });
    setChoice(null);
    setShowOverlay(false);
    onOverlayShown(false);
  };

  return (
    <>
      {!showOverlay && (
        <>
          {scans.map(s => (
            <React.Fragment key={s.uuid}>
              <Row style={{ marginTop: theme.spacing(1.5) }}>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="fa fa-file-text-o" aria-hidden="true" style={{ marginRight: theme.spacing(1.5) }}></i>
                    <Typography variant="h6" component="div">
                      {t(`badger.full-documents-list.${s.code}`)}
                    </Typography>
                  </div>
                </Col>
              </Row>
              {s.code.toLocaleLowerCase() === 'i-94' && (
                <Typography variant="subtitle2" component="div">
                  {t('badger.full-documents-list.i-94-help')}
                  <a style={{ paddingLeft: 8 }} href="https://i94.cbp.dhs.gov/I94" target="_blank" rel="noreferrer">
                    https://i94.cbp.dhs.gov/I94
                  </a>
                </Typography>
              )}
            </React.Fragment>
          ))}

          {!isMobile && (
            <Row>
              <Col>
                <Typography style={{ marginTop: theme.spacing(4) }}>{t('badger.pre-scanning.statement2')}</Typography>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <Button
                style={{ fontSize: isSmallScreen() ? 16 : 20, marginTop: theme.spacing(4) }}
                variant="contained"
                color="primary"
                onClick={onButtonClicked}
              >
                {t('badger.begin')}
              </Button>
            </Col>
          </Row>
        </>
      )}

      {showOverlay && (
        <>
          <MessagesWrapper>
            <Row>
              <Col>
                <Typography variant="h5">
                  <strong>{t('badger.pre-scanning.info-title')}</strong>
                </Typography>
              </Col>
            </Row>
            <Row style={{ flexDirection: 'column', marginTop: theme.spacing(2) }}>
              <Col>
                <WarningMessage />
              </Col>
              <Col>
                <InfoMessage />
              </Col>
              <Col>
                <Card style={{ width: 800 }}>
                  <CardContent>
                    <Typography variant="body1">
                      <strong>{t('badger.pre-scanning.finished-title')}</strong>
                    </Typography>
                    <FormControl style={{ marginTop: theme.spacing(1) }}>
                      <RadioGroup
                        aria-label="choice"
                        name="choice"
                        value={choice}
                        onChange={({ target }) => setChoice(target.value)}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label={t('badger.pre-scanning.finished-answer-yes')}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label={t('badger.pre-scanning.finished-answer-no')}
                        />
                      </RadioGroup>
                    </FormControl>
                    <br />
                    <Button
                      style={{ marginTop: theme.spacing(1) }}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={onContinue}
                      disabled={!choice}
                    >
                      {t('badger.continue')}
                    </Button>
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </MessagesWrapper>
        </>
      )}
    </>
  );
}
