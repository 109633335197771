import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { AppContext } from '../../../../../../App';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import request from '../../../../../../ajax/Request';

export default function MarkBadgeNotIssuedModal({ isOpen, onClose, onSave, badge }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');

  const cleanup = () => {
    setIsModalOpen(false);
    setComment('');
  };

  const onModalClose = () => {
    cleanup();
    onClose();
  };

  const onSaveChanges = async () => {
    app.api.confirmMessage(
      'Are you sure?',
      <div>
        Changing a badge to "Not Issued" prevents it from becoming activated. Are you sure you want to make this change?
      </div>,
      async () => {
        try {
          app.api.toggleLoading(true);
          const response = await request(app.user, 'PUT', `badges/${badge.uuid}/status`, {
            new_status: 'not_issued',
            comment,
          });
          if (response.success) {
            app.api.displayMessage('Badge successfully marked as not issued', 'success');
            onSave({ ...response });
            cleanup();
          }
        } catch (error) {
          app.api.displayMessage('Unable to mark badge as not issued', 'warning');
        } finally {
          app.api.toggleLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (!isOpen || !badge) return;
    setIsModalOpen(true);
  }, [isOpen, badge]);

  if (!isOpen) return null;

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Mark Badge as Not Issued</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label>Comment</Label>
              <Input required type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel="Mark Badge as Not Issued"
          saveDisabled={!comment}
          onSave={onSaveChanges}
        />
      </ModalFooter>
    </Modal>
  );
}
