import React, { useCallback, useContext, useState } from 'react';
import { Alert, FormGroup, Input, Label } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppContext, colors } from '../../../../../../App';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import request from '../../../../../../ajax/Request';
import { handleError } from '../../../../../../utils';

export default function AddFingerprintsStepAction() {
  const app = useContext(AppContext);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selection, setSelection] = useState(null);
  const [comment, setComment] = useState('');

  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const { applicationDocument, fingerprintAppointment, adjudicateCHRC, approveBackgroundCheckResults } =
    badgeApplication.config;

  const onConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
    setSelection(null);
    setComment('');
  };

  const onConfirmModalSave = () => {
    app.api.toggleLoading(true);
    request('authenticated-user', 'PUT', `badge-applications/${badgeApplication.uuid}/add-fingerprints`, { comment })
      .then(({ success }) => {
        if (success) {
          setIsConfirmModalOpen(false);
          setSelection(null);
          setComment('');
          onActionDone({ shouldReload: true });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const isHidden = useCallback(() => {
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for fingerprints
      return true;
    }
    if (!fingerprintAppointment.isEnabled) {
      return (
        (adjudicateCHRC.isEnabled && adjudicateCHRC.isComplete) ||
        (approveBackgroundCheckResults.isEnabled && approveBackgroundCheckResults.isComplete)
      );
    }
    return true;
  }, [applicationDocument, isCurrentUserSignatory, adjudicateCHRC, approveBackgroundCheckResults]);

  const addFingerprints = () => {
    setSelection(null);
    setComment('');
    setIsConfirmModalOpen(true);
  };

  if (isHidden()) return null;

  return (
    <ListItem button onClick={addFingerprints} disabled={isConfirmModalOpen}>
      <ListItemIcon>
        <FontAwesomeIcon className="fa-solid fa-fingerprint" color={colors.primary} />
      </ListItemIcon>
      <ListItemText primary="Add CHRC/Fingerprints Requirement" />

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Are you sure?"
        isOpen={isConfirmModalOpen}
        onClose={onConfirmModalClose}
        saveLabel="Add CHRC"
        onSave={onConfirmModalSave}
        saveDisabled={!selection || selection === 'false'}
      >
        <Alert color="info">
          This will add the CHRC requirement to this badge application process. If the badge application process is
          ready for fingerprints, an email will be sent to the applicant letting them know they need to schedule a
          fingerprinting appointment.
        </Alert>
        <p>Are you sure you want to proceed?</p>
        <RadioGroup
          row
          aria-label="confirmation"
          name="confirmation"
          value={selection}
          onChange={event => setSelection(event?.target?.value)}
        >
          <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
        </RadioGroup>
        <FormGroup>
          <Label>
            Reason <i style={{ fontSize: 'smaller' }}>(optional)</i>
          </Label>
          <Input type="textarea" value={comment} onChange={event => setComment(event?.target?.value)} />
        </FormGroup>
      </AirBadgeModal>
    </ListItem>
  );
}
