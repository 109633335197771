import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import paymentsApi from '../../../ajax/Payments/paymentsApi';
import { handleError } from '../../../utils';
import { AppContext } from '../../../App';

let requestTimeout = null;
let abortController = null;

function StripeForm({ onPaymentSuccess, onPaymentCancelled }) {
  const app = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    app.api.toggleLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });
    app.api.toggleLoading(false);

    if (result.error) {
      handleError({ error: result.error.message, message: result.error.message });
    } else {
      onPaymentSuccess();
    }
  };

  return (
    <>
      <PaymentElement />
      <div style={{ margin: '15px 0' }}>&nbsp;</div>
      <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Button onClick={onPaymentCancelled}>Cancel</Button>
        </Grid>
        <Grid item>
          <Button color="primary" style={{ width: 150 }} onClick={onSubmit} disabled={!stripe || !elements}>
            Submit Payment
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default function PaymentForm({ paymentUuid, onPaymentSuccess, onPaymentCancelled }) {
  const [isLoading, setIsLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [stripeOptions, setStripeOptions] = useState(null);
  const [fees, setFees] = useState([]);

  const getTotal = () => {
    return fees.reduce((total, { amount }) => total + parseFloat(amount), 0);
  };

  const loadData = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setIsLoading(true);

    requestTimeout = setTimeout(() => {
      setIsLoading(true);
      abortController = new AbortController();
      paymentsApi
        .get({ uuid: paymentUuid, signal: abortController.signal })
        .then(({ payment }) => {
          const { stripePayment, metadata } = payment;
          const { publicKey, clientSecret } = stripePayment;
          const stripePromise = loadStripe(publicKey);
          setStripePromise(stripePromise);
          setStripeOptions({ clientSecret });
          setFees(metadata.items);
        })
        .catch(error => handleError({ error }))
        .then(() => setIsLoading(false));
    }, 500);
  };

  useEffect(() => {
    if (paymentUuid) {
      loadData();
    }

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [paymentUuid]);

  if (isLoading) {
    return (
      <div className="text-center">
        <CircularProgress size={50} />
      </div>
    );
  }

  const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 16,
    borderBottom: '1px dashed #ccc',
  };

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <Alert color="info">
        Payments are processed securely through <a href="https://stripe.com">Stripe</a>. Your credit card information is
        never stored on our servers.
        <div className="mt-2" style={{ fontSize: 'smaller' }}>
          <i>
            Visit <a href="https://stripe.com">https://stripe.com</a> for more information.
          </i>
        </div>
      </Alert>
      <div style={{ marginTop: 32, marginBottom: 40 }}>
        {fees.map(({ label, amount }, index) => (
          <div key={index} style={rowStyle}>
            <div>{label}</div>
            <div style={{ flex: 1 }}>&nbsp;</div>
            <div>{amount}</div>
          </div>
        ))}
        <div style={{ ...rowStyle, borderBottom: 'none' }}>
          <div>
            <strong>Total Amount Due</strong>
          </div>
          <div style={{ flex: 1 }}>&nbsp;</div>
          <div>
            <strong>$ {getTotal()}</strong>
          </div>
        </div>
      </div>

      <StripeForm onPaymentSuccess={onPaymentSuccess} onPaymentCancelled={onPaymentCancelled} />
    </Elements>
  );
}
