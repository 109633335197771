import React, { useState } from 'react';
import set from 'lodash/set';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Button } from 'reactstrap';
import { BlockFormLabel } from '../../components/styledComponents';
import NameQuestion from './questions/NameQuestion';
import AliasesQuestion from './questions/AliasesQuestion';
import GenderQuestion from './questions/GenderQuestion';
import HeightQuestion from './questions/HeightQuestion';
import WeightQuestion from './questions/WeightQuestion';
import EyeColorQuestion from './questions/EyeColorQuestion';
import HairColorQuestion from './questions/HairColorQuestion';
import PlaceOfBirthQuestion from './questions/PlaceOfBirthQuestion';
import DateOfBirthQuestion from './questions/DateOfBirthQuestion';
import SocialSecurityNumberQuestion from './questions/SocialSecurityNumberQuestion';
import PhoneNumberQuestion from './questions/PhoneNumberQuestion';
import CitizenshipCountryQuestion from './questions/CitizenshipCountryQuestion';
import MailingAddressQuestion from './questions/MailingAddressQuestion';
import EmailAddressQuestion from './questions/EmailAddressQuestion';
import CompanyQuestion from './questions/CompanyQuestion';
import OccupationQuestion from './questions/OccupationQuestion';

const useStyles = makeStyles(theme => ({
  empty: {
    height: '100%',
    marginTop: theme.spacing(4),
    fontSize: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

const defaultData = {
  name: {
    value: { first: '', middle: '', last: '', suffix: null },
    hidden: false,
  },
  aliases: {
    value: [],
    hidden: true,
  },
  gender: {
    value: null,
    hidden: true,
  },
  height: {
    value: { feet: `0`, inches: `00` },
    hidden: true,
  },
  weight: {
    value: null,
    hidden: true,
  },
  eyeColor: {
    value: null,
    hidden: true,
  },
  hairColor: {
    value: null,
    hidden: true,
  },
  placeOfBirth: {
    value: { country: null, state: null, city: null, ncic: null },
    hidden: true,
  },
  dateOfBirth: {
    value: null,
    hidden: true,
  },
  social: {
    value: null,
    hidden: true,
  },
  phoneNumber: {
    value: [],
    hidden: true,
  },
  citizenshipCountry: {
    value: [],
    hidden: true,
  },
  mailingAddress: {
    value: { street1: '', street2: '', city: '', state: null, zip: '' },
    hidden: true,
  },
  email: {
    value: null,
    hidden: true,
  },
  company: {
    value: null,
    hidden: true,
  },
  occupation: {
    value: null,
    hidden: true,
  },
};

export default function DataConfirmation({
  isHidden,
  info,
  onNext,
  usaBorn,
  isUSACitizen,
  isSecuredBadge,
  shouldSkipSSNQuestion,
}) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [isLoading] = useState(false);
  const [data, setData] = useState({ ...defaultData });

  const updateData = data => {
    setData(data);
    // Manually scroll browser frame to top of screen
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const curateDataAndProceed = ({
    name,
    aliases,
    gender,
    height,
    weight,
    eyeColor,
    hairColor,
    placeOfBirth,
    dateOfBirth,
    social,
    phoneNumber,
    citizenshipCountry,
    mailingAddress,
    email,
  }) => {
    let heightFeet = 0;
    let heightInches = 0;
    if (height.value) {
      heightFeet = height.value.substring(0, 1);
      heightInches = height.value.substring(1);
    }
    const curatedData = {
      name: {
        first: name.value.first,
        middle: name.value.middle,
        last: name.value.last,
        suffix: name.value.suffix ? name.value.suffix.value : '',
      },
      aliases: [],
      gender: gender.value.value,
      height: height.value,
      heightFeet,
      heightInches,
      weight: weight.value,
      eyeColor: eyeColor.value.value,
      hairColor: hairColor.value.value,
      placeOfBirth: {
        country: placeOfBirth.value.country,
        state: placeOfBirth.value.state ? placeOfBirth.value.state.value : null,
        city: placeOfBirth.value.city ? placeOfBirth.value.city : null,
        ncic: placeOfBirth.value.ncic ? placeOfBirth.value.ncic : null,
      },
      dateOfBirth: dateOfBirth.value,
      social: social.value,
      phoneNumbers: [],
      citizenshipCountry: citizenshipCountry.value.map(x => x.threeCharacterCode).join(', '),
      citizenshipCountryRaw: citizenshipCountry.value.map(x => x.threeCharacterCode),
      citizenshipCountryDoubleDigitCodes: citizenshipCountry.value.map(x => x.twoCharacterCode).join(', '),
      citizenshipCountryDoubleDigitCodesRaw: citizenshipCountry.value.map(x => x.twoCharacterCode),
      mailingAddress: { ...mailingAddress.value, state: mailingAddress.value.state.value },
      email: email.value,
    };

    if (phoneNumber?.value?.length) {
      curatedData.phoneNumbers = phoneNumber.value.map(x => ({
        type: x.type,
        number: x.number,
      }));
    }

    if (aliases && aliases.value && aliases.value.length) {
      curatedData.aliases = aliases.value.map(a => ({
        first: a.first,
        middle: a.middle,
        last: a.last,
        suffix: a.suffix ? a.suffix.value : '',
      }));
    }

    onNext(curatedData);
  };

  // NOTE: THIS IS FOR TESTING PURPOSES ONLY!
  // NOTE: THIS IS FOR TESTING PURPOSES ONLY!
  const skipToTheEnd = async () => {
    if (info?.applicant?.isDemoUser) {
      import('./testData').then(async ({ default: createPerson }) => {
        const newData = { ...(await createPerson(info.applicant || null)) };
        updateData(newData);
        curateDataAndProceed(newData);
      });
    }
  };

  if (isHidden) return null;

  if (isLoading) {
    return (
      <div className={classes.empty}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <>
      {info?.applicant?.isDemoUser && (
        <div style={{ marginBottom: 10, textAlign: 'right' }}>
          <Button color="danger" onClick={skipToTheEnd} size="sm">
            Quick Fill My Info
          </Button>
        </div>
      )}

      <BlockFormLabel>
        <Typography variant="h6">{t('statement')}</Typography>
      </BlockFormLabel>
      <BlockFormLabel style={{ marginBottom: 32 }}>
        <Typography variant="subtitle2">{t('statement_extra')}</Typography>
      </BlockFormLabel>

      <NameQuestion
        isHidden={data.name.hidden}
        value={data.name.value}
        onNext={name => {
          let newData = set(data, 'name.value', name);
          newData = set(newData, 'name.hidden', true);
          newData = set(newData, 'aliases.hidden', false);
          updateData({ ...newData });
        }}
      />

      <AliasesQuestion
        isHidden={data.aliases.hidden}
        value={data.aliases.value}
        onBack={() => {
          let newData = set(data, 'aliases.hidden', true);
          newData = set(newData, 'name.hidden', false);
          updateData({ ...newData });
        }}
        onNext={aliases => {
          let newData = set(data, 'aliases.value', [...aliases]);
          newData = set(newData, 'aliases.hidden', true);
          newData = set(newData, 'gender.hidden', false);
          updateData({ ...newData });
        }}
      />

      <GenderQuestion
        isHidden={data.gender.hidden}
        value={data.gender.value}
        onBack={() => {
          let newData = set(data, 'gender.hidden', true);
          newData = set(newData, 'aliases.hidden', false);
          updateData({ ...newData });
        }}
        onNext={gender => {
          let newData = set(data, 'gender.value', gender);
          newData = set(newData, 'gender.hidden', true);
          newData = set(newData, 'height.hidden', false);
          updateData({ ...newData });
        }}
      />

      <HeightQuestion
        isSecuredBadge={isSecuredBadge}
        isHidden={data.height.hidden}
        value={data.height.value}
        onBack={() => {
          let newData = set(data, 'height.hidden', true);
          newData = set(newData, 'gender.hidden', false);
          updateData({ ...newData });
        }}
        onNext={height => {
          let newData = set(data, 'height.value', height);
          newData = set(newData, 'height.hidden', true);
          newData = set(newData, 'weight.hidden', false);
          updateData({ ...newData });
        }}
      />

      <WeightQuestion
        isSecuredBadge={isSecuredBadge}
        isHidden={data.weight.hidden}
        value={data.weight.value}
        onBack={() => {
          let newData = set(data, 'weight.hidden', true);
          newData = set(newData, 'height.hidden', false);
          updateData({ ...newData });
        }}
        onNext={weight => {
          let newData = set(data, 'weight.value', weight);
          newData = set(newData, 'weight.hidden', true);
          newData = set(newData, 'eyeColor.hidden', false);
          updateData({ ...newData });
        }}
      />

      <EyeColorQuestion
        isHidden={data.eyeColor.hidden}
        value={data.eyeColor.value}
        onBack={() => {
          let newData = set(data, 'eyeColor.hidden', true);
          newData = set(newData, 'weight.hidden', false);
          updateData({ ...newData });
        }}
        onNext={eyeColor => {
          let newData = set(data, 'eyeColor.value', eyeColor);
          newData = set(newData, 'eyeColor.hidden', true);
          newData = set(newData, 'hairColor.hidden', false);
          updateData({ ...newData });
        }}
      />

      <HairColorQuestion
        isHidden={data.hairColor.hidden}
        value={data.hairColor.value}
        onBack={() => {
          let newData = set(data, 'hairColor.hidden', true);
          newData = set(newData, 'eyeColor.hidden', false);
          updateData({ ...newData });
        }}
        onNext={hairColor => {
          let newData = set(data, 'hairColor.value', hairColor);
          newData = set(newData, 'hairColor.hidden', true);
          newData = set(newData, 'placeOfBirth.hidden', false);
          updateData({ ...newData });
        }}
      />

      <PlaceOfBirthQuestion
        isHidden={data.placeOfBirth.hidden}
        value={data.placeOfBirth.value}
        usaBorn={usaBorn}
        onBack={() => {
          let newData = set(data, 'placeOfBirth.hidden', true);
          newData = set(newData, 'hairColor.hidden', false);
          updateData({ ...newData });
        }}
        onNext={placeOfBirth => {
          let newData = set(data, 'placeOfBirth.value', placeOfBirth);
          newData = set(newData, 'placeOfBirth.hidden', true);
          newData = set(newData, 'dateOfBirth.hidden', false);
          updateData({ ...newData });
        }}
      />

      <DateOfBirthQuestion
        isHidden={data.dateOfBirth.hidden}
        value={data.dateOfBirth.value}
        onBack={() => {
          let newData = set(data, 'dateOfBirth.hidden', true);
          newData = set(newData, 'placeOfBirth.hidden', false);
          updateData({ ...newData });
        }}
        onNext={dateOfBirth => {
          let newData = set(data, 'dateOfBirth.value', dateOfBirth);
          newData = set(newData, 'dateOfBirth.hidden', true);

          if (shouldSkipSSNQuestion) {
            newData = set(newData, 'phoneNumber.hidden', false);
          } else {
            newData = set(newData, 'social.hidden', false);
          }

          updateData({ ...newData });
        }}
      />

      <SocialSecurityNumberQuestion
        isHidden={data.social.hidden}
        value={data.social.value}
        onBack={() => {
          let newData = set(data, 'social.hidden', true);
          newData = set(newData, 'dateOfBirth.hidden', false);
          updateData({ ...newData });
        }}
        onNext={social => {
          let newData = set(data, 'social.value', social);
          newData = set(newData, 'social.hidden', true);
          newData = set(newData, 'phoneNumber.hidden', false);
          updateData({ ...newData });
        }}
      />

      <PhoneNumberQuestion
        isHidden={data.phoneNumber.hidden}
        value={data.phoneNumber.value}
        onBack={() => {
          let newData = set(data, 'phoneNumber.hidden', true);

          if (shouldSkipSSNQuestion) {
            newData = set(newData, 'dateOfBirth.hidden', false);
          } else {
            newData = set(newData, 'social.hidden', false);
          }

          updateData({ ...newData });
        }}
        onNext={phoneNumber => {
          let newData = set(data, 'phoneNumber.value', phoneNumber);
          newData = set(newData, 'phoneNumber.hidden', true);
          if (isUSACitizen) {
            const USA = {
              label: 'United States of America',
              value: 'United States of America',
              twoCharacterCode: 'US',
              threeCharacterCode: 'USA',
            };
            newData = set(data, 'citizenshipCountry.value', [USA]);
            newData = set(newData, 'mailingAddress.hidden', false);
          } else {
            newData = set(newData, 'citizenshipCountry.hidden', false);
          }
          updateData({ ...newData });
        }}
      />

      <CitizenshipCountryQuestion
        isHidden={data.citizenshipCountry.hidden}
        value={data.citizenshipCountry.value}
        onBack={() => {
          let newData = set(data, 'citizenshipCountry.hidden', true);
          newData = set(newData, 'phoneNumber.hidden', false);
          updateData({ ...newData });
        }}
        onNext={citizenshipCountry => {
          let newData = set(data, 'citizenshipCountry.value', citizenshipCountry);
          newData = set(newData, 'citizenshipCountry.hidden', true);
          newData = set(newData, 'mailingAddress.hidden', false);
          updateData({ ...newData });
        }}
      />

      <MailingAddressQuestion
        isHidden={data.mailingAddress.hidden}
        value={data.mailingAddress.value}
        onBack={() => {
          let newData = set(data, 'mailingAddress.hidden', true);
          if (isUSACitizen) {
            newData = set(newData, 'phoneNumber.hidden', false);
          } else {
            newData = set(newData, 'citizenshipCountry.hidden', false);
          }
          updateData({ ...newData });
        }}
        onNext={mailingAddress => {
          let newData = set(data, 'mailingAddress.value', mailingAddress);
          newData = set(newData, 'mailingAddress.hidden', true);
          newData = set(newData, 'email.hidden', false);
          updateData({ ...newData });
        }}
      />

      <EmailAddressQuestion
        isHidden={data.email.hidden}
        value={data.email.value}
        onBack={() => {
          let newData = set(data, 'email.hidden', true);
          newData = set(newData, 'mailingAddress.hidden', false);
          updateData({ ...newData });
        }}
        onNext={email => {
          let newData = set(data, 'email.value', email);
          updateData({ ...newData });
          curateDataAndProceed({ ...newData });
        }}
      />

      {/* For now, we have decided to not show this question */}
      {/* The code is still here for it just in case we decide to revert this decision */}
      <CompanyQuestion
        isHidden={data.company.hidden}
        value={data.company.value}
        onBack={() => {
          let newData = set(data, 'company.hidden', true);
          newData = set(newData, 'email.hidden', false);
          updateData({ ...newData });
        }}
        onNext={company => {
          let newData = set(data, 'company.value', company);
          newData = set(newData, 'company.hidden', true);
          newData = set(newData, 'occupation.hidden', false);
          updateData({ ...newData });
        }}
      />

      {/* For now, we have decided to not show this question */}
      {/* The code is still here for it just in case we decide to revert this decision */}
      <OccupationQuestion
        isHidden={data.occupation.hidden}
        value={data.occupation.value}
        onBack={() => {
          let newData = set(data, 'occupation.hidden', true);
          newData = set(newData, 'email.hidden', false);
          updateData({ ...newData });
        }}
        onNext={occupation => {
          let newData = set(data, 'occupation.value', occupation);
          newData = set(newData, 'occupation.hidden', false);
          updateData({ ...newData });
          curateDataAndProceed({ ...newData });
        }}
      />
    </>
  );
}
