import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { AppContext } from '../../../../App';
import request from '../../../../ajax/Request';
import { handleError } from '../../../../utils';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import TitledBox from '../../../../shared/TitledBox/TitledBox';

function STAInfo({ data }) {
  const empty = (
    <TitledBox title="STA">
      <div>
        <i>No STA</i>
      </div>
    </TitledBox>
  );

  if (!data?.backgroundChecks?.length) {
    return empty;
  }

  const sta = data.backgroundChecks.find(x => x.type === 'STA');
  if (!sta) {
    return empty;
  }

  return (
    <TitledBox title="STA">
      <pre>{JSON.stringify({ ...sta, type: undefined }, null, 2)}</pre>
    </TitledBox>
  );
}

function CHRCInfo({ data }) {
  const empty = (
    <TitledBox title="CHRC">
      <div>
        <i>No CHRC</i>
      </div>
    </TitledBox>
  );

  if (!data?.backgroundChecks?.length) {
    return empty;
  }

  const chrc = data.backgroundChecks.find(x => x.type === 'CHRC');
  if (!chrc) {
    return empty;
  }

  return (
    <TitledBox title="CHRC">
      <pre>{JSON.stringify({ ...chrc, type: undefined }, null, 2)}</pre>
    </TitledBox>
  );
}

function RapbackInfo({ data }) {
  if (!data?.rapBackSubscription) {
    return (
      <TitledBox title="Rapback Subscription">
        <div>
          <i>No Rapback Subscription</i>
        </div>
      </TitledBox>
    );
  }

  return (
    <TitledBox title="Rapback Subscription">
      <pre>{JSON.stringify(data.rapBackSubscription, null, 2)}</pre>
    </TitledBox>
  );
}

let requestTimeout = null;
let abortController = null;

export default function AdminPullFullTscInfo({ isOpen, userUuid, airportPersonId, onClose }) {
  const app = useContext(AppContext);
  const [data, setData] = useState({});

  const loadData = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    app.api.toggleLoading(true);

    requestTimeout = setTimeout(() => {
      app.api.toggleLoading(true);
      abortController = new AbortController();
      const signal = abortController.signal;
      request('authenticated-user', 'GET', `admin/full-tsc-info/${userUuid}`, null, signal)
        .then(({ results }) => {
          delete results.transactionId;
          delete results.transactionDate;
          setData(results);
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }, 500);
  };

  useEffect(() => {
    if (!isOpen) return;
    loadData();

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      isOpen={isOpen}
      size={MODAL_SIZE_LARGE}
      title={`Full TSC Record for APID ${airportPersonId}`}
      onClose={onClose}
    >
      <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
        <Grid item style={{ width: '100%' }}>
          <TitledBox title="Applicant">
            <pre>{JSON.stringify({ ...data.applicant, backgroundChecks: undefined }, null, 2)}</pre>
          </TitledBox>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <STAInfo data={data} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <CHRCInfo data={data} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <RapbackInfo data={data} />
        </Grid>
      </Grid>
    </AirBadgeModal>
  );
}
