import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import ReactTable from 'react-table';
import { Button, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { AppContext } from '../../../../../App';
import trainingCourseApi from '../../../../../ajax/Training/trainingCourseApi';
import { getLMSLabel, handleError } from '../../../../../utils';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

function LabelWithTitle({ label }) {
  return <span title={label}>{label}</span>;
}

function GroupCell({ group }) {
  const { name, type, courses } = group;
  const label = `${name} ${type ? `(${type})` : ''}`;
  if (courses?.length) {
    return (
      <>
        <div title={label}>{label}</div>
        <div style={{ marginTop: 5, fontStyle: 'italic' }}>
          <span style={{ fontWeight: 500 }}>Courses:</span> {courses.map(c => c.name).join(', ')}
        </div>
      </>
    );
  }
  return <LabelWithTitle label={label} />;
}

export default function PersonAssociatedTraineeGroupList({ isStillLoadingData, person, trainingData, onRefreshData }) {
  const app = useContext(AppContext);
  const [assignedTraineeGroups, setAssignedTraineeGroups] = useState([]);
  const [isAssignTraineeGroupModalOpen, setIsAssignTraineeGroupModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [traineeGroupList, setTraineeGroupList] = useState([]);
  const [groupLmsList, setGroupLmsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const groupColumns = [
    {
      id: 'lms',
      Header: 'LMS',
      accessor: ({ system }) => <LabelWithTitle label={getLMSLabel(system)} />,
      show: groupLmsList.length > 1,
      sortable: false,
      filterable: false,
      width: 115,
    },
    {
      id: 'name',
      Header: 'Group Name',
      accessor: group => <GroupCell group={group} />,
      show: true,
      sortable: false,
      filterable: false,
    },
    {
      id: 'code',
      Header: 'Group Code',
      accessor: ({ code }) => <LabelWithTitle label={code} />,
      show: false,
      sortable: false,
      filterable: false,
    },
    {
      id: 'actions',
      Header: 'Actions',
      accessor: ({ name, code, system }) => (
        <Button
          title="Remove Group Assignment"
          aria-label="Remove Group Assignment"
          style={{ marginRight: 16 }}
          size="sm"
          color="danger"
          onClick={() => onRemoveGroupClicked({ name, code, system })}
        >
          Remove Assignment
        </Button>
      ),
      show: true,
      sortable: false,
      filterable: false,
      width: 200,
    },
  ];

  const onAssignTraineeGroupModalClose = () => {
    setIsLoading(false);
    setIsAssignTraineeGroupModalOpen(false);
    setSelectedGroup(null);
  };

  const onRemoveGroupClicked = group => {
    app.api.confirmMessage(
      'Confirmation',
      <div>
        Are you sure you want to un-assign the <strong>{group.name}</strong> group from {person.first_name}{' '}
        {person.last_name}?
      </div>,
      () => {
        app.api.toggleLoading(true);
        trainingCourseApi
          .removeGroupForUser({
            userUUID: person.uuid,
            keep: assignedTraineeGroups.filter(x => x.code !== group.code).map(x => x.code),
            remove: group,
            system: group.system,
          })
          .then(({ success, message }) => {
            if (success) {
              onRefreshData();
            } else {
              app.api.toggleLoading(false);
              handleError({ message: message || 'Unable to remove group assignment' });
            }
          })
          .catch(error => {
            app.api.toggleLoading(false);
            handleError({ error, message: 'Unable to remove group assignment' });
          });
      },
      null,
      true,
      'Remove Group Assignment'
    );
  };

  const assignToGroup = async () => {
    app.api.toggleLoading(true);
    trainingCourseApi
      .assignTraineeGroup({
        userUUID: person.uuid,
        groupCode: selectedGroup.value,
        groupName: selectedGroup.name,
        groupType: selectedGroup.type,
        system: selectedGroup.system,
      })
      .then(({ success, message }) => {
        if (success) {
          onAssignTraineeGroupModalClose();
          onRefreshData();
        } else {
          app.api.toggleLoading(false);
          handleError({ message: message || 'Unable to add group assignment' });
        }
      })
      .catch(error => {
        app.api.toggleLoading(false);
        handleError({ error, message: 'Unable to add group assignment' });
      });
  };

  useEffect(() => {
    if (person && trainingData) {
      const lmsList = [];
      let formattedAssignedGroupsList = [];
      let formattedGroupsList = [];
      trainingData.forEach(({ system, success, ...rest }) => {
        const lmsLabel = getLMSLabel(system);
        lmsList.push({ label: lmsLabel, value: system });
        if (success && rest) {
          if (rest?.availableGroups === undefined) {
            // This LMS doesn't support groups (ex: Digicast)
            return;
          }
          const { availableGroups, assignedGroups } = rest;
          formattedAssignedGroupsList = formattedAssignedGroupsList.concat(assignedGroups.map(g => ({ ...g, system })));
          if (assignedGroups.length === 0) {
            formattedGroupsList = formattedGroupsList.concat(
              availableGroups.map(x => ({
                label: `${x.name} ${x.type ? `(${x.type})` : ''}`,
                name: x.name,
                type: x.type,
                value: x.code,
                lms: lmsLabel,
                system,
              }))
            );
          } else {
            formattedGroupsList = formattedGroupsList.concat(
              availableGroups
                .filter(x => assignedGroups.find(ag => ag.code === x.code) === undefined)
                .map(x => ({
                  label: `${x.name} ${x.type ? `(${x.type})` : ''}`,
                  name: x.name,
                  type: x.type,
                  value: x.code,
                  lms: lmsLabel,
                  system,
                }))
            );
          }
        }
      });
      if (lmsList.length > 1) {
        formattedGroupsList.forEach(c => (c.label = `${c.lms}: ${c.label}`));
      }
      setAssignedTraineeGroups(formattedAssignedGroupsList);
      setTraineeGroupList(formattedGroupsList);
      setGroupLmsList(lmsList);
    }
    setIsLoading(false);
  }, [person, trainingData]);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  if (!person || !trainingData) return false;

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: 16 }}
          color="primary"
          onClick={() => setIsAssignTraineeGroupModalOpen(true)}
          disabled={isStillLoadingData}
        >
          <i className="fa fa-plus mr-2" />
          Assign Group
        </Button>
      </div>

      <Card style={!assignedTraineeGroups.length ? { height: 60 } : null}>
        <CardBody>
          {!assignedTraineeGroups.length ? (
            <div style={{ textAlign: 'center' }}>
              <i>{isLoading ? 'Loading groups ...' : 'No groups assigned'}</i>
            </div>
          ) : (
            <div className="table-responsive" style={{ maxHeight: 244, minHeight: 200 }}>
              <ReactTable
                manual
                className="border-0 -striped"
                data={assignedTraineeGroups || []}
                columns={groupColumns}
                pages={1}
                minRows={0}
                defaultPageSize={100}
                showPagination={false}
                // SubComponent={({ original }) => (
                //   <div style={{ marginLeft: 40 }}>
                //     <ReactTable
                //       manual
                //       className="border-1 -striped"
                //       data={original?.courses || []}
                //       columns={courseColumns}
                //       pages={1}
                //       minRows={0}
                //       defaultPageSize={100}
                //       showPagination={false}
                //     />
                //   </div>
                // )}
              />
            </div>
          )}
        </CardBody>
      </Card>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Assign Group"
        isOpen={isAssignTraineeGroupModalOpen}
        onClose={onAssignTraineeGroupModalClose}
        closeLabel="Cancel"
        onSave={assignToGroup}
        saveLabel="Assign Group"
        saveDisabled={!selectedGroup}
      >
        <FormGroup>
          <Label>Training Group</Label>
          <Select
            classNamePrefix="airbadge"
            isSearchable={true}
            className="form-select"
            placeholder="Select training group"
            options={traineeGroupList}
            onChange={option => setSelectedGroup(option)}
          />
        </FormGroup>
      </AirBadgeModal>
    </>
  );
}
