import request from '../Request';

export default function AppointmentUpdateRequest(user, uuid, appointment) {
  const method = 'PUT';

  const url = 'appointments/' + uuid;

  const data = {
    appointment: appointment,
  };

  return request(user, method, url, data);
}
