import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { AppContext } from '../../../../../../App';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import request from '../../../../../../ajax/Request';
import { handleError } from '../../../../../../utils';
import { leoOptions } from '../../BadgeCreateModal';
import DrivingEndorsementPicker from '../../../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import { getDrivingOptions } from '../../../../../../shared/BadgeTypes/drivingOptionsUtils';
import EscortEndorsementPicker from '../../../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';

const defaultEscortOption = { label: 'no', value: 0 };

export default function ModifyEndorsementsModal({ isOpen, onClose, onSave, badge }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newDrivingOption, setNewDrivingOption] = useState(null);
  const [newEscortOption, setNewEscortOption] = useState(null);
  const [newLeoOption, setNewLeoOption] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const cleanup = () => {
    setIsModalOpen(false);
    const drivingOptions = getDrivingOptions(badge?.badgeTypeDetails?.config?.endorsements?.driving);
    setNewDrivingOption(drivingOptions[0]);
    setNewEscortOption(defaultEscortOption);
    setNewLeoOption(leoOptions[0]);
  };

  const onModalClose = () => {
    cleanup();
    onClose();
  };

  const onSaveChanges = async () => {
    try {
      app.api.toggleLoading(true);
      const response = await request(app.user, 'PATCH', `badge/${badge.uuid}`, {
        driving: newDrivingOption.value,
        isEscort: newEscortOption.value,
        isLeo: newLeoOption.value,
        comment: '',
      });
      if (response.success) {
        onSave({
          driving: newDrivingOption.value,
          isEscort: !!newEscortOption.value,
          isLeo: !!newLeoOption.value,
          ...response,
        });
        cleanup();
      }
    } catch (error) {
      handleError({ error, message: 'Badge Endorsements / Extras could not be changed' });
    } finally {
      app.api.toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen || !badge) return;
    const { originalDriving, isEscort, isLeo } = badge;
    const drivingOptions = getDrivingOptions(badge?.badgeTypeDetails?.config?.endorsements?.driving);
    setNewDrivingOption(drivingOptions.find(x => x.value === originalDriving) || drivingOptions[0]);
    setNewEscortOption(isEscort ? { label: 'yes', value: 1 } : defaultEscortOption);
    setNewLeoOption(leoOptions.find(x => !!x.value === isLeo) || leoOptions[0]);
    setIsModalOpen(true);
  }, [isOpen, badge]);

  if (!isOpen) return null;

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Modify Endorsements / Extras</CustomModalHeader>
      <ModalBody>
        <Row className="mb-4">
          <Col>
            <DrivingEndorsementPicker
              context={{
                drivingEndorsementConfig: badge?.badgeTypeDetails?.config?.endorsements?.driving,
                drivingSelection: newDrivingOption,
                setDrivingSelection: option => {
                  setNewDrivingOption(option);
                  setIsFormDirty(true);
                },
                shouldSkipDefaultSelection: true,
                data: { badgeType: badge?.badgeTypeDetails },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <EscortEndorsementPicker
              context={{
                escortEndorsementConfig: badge?.badgeTypeDetails?.config?.endorsements?.escort,
                escortSelection: newEscortOption,
                setEscortSelection: option => {
                  setNewEscortOption(option);
                  setIsFormDirty(true);
                },
                shouldSkipDefaultSelection: true,
                data: { badgeType: badge?.badgeTypeDetails },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Law Enforcement Officer (LEO)</Label>
              <br />
              <Select
                classNamePrefix="airbadge"
                options={leoOptions}
                className="form-select"
                value={newLeoOption}
                placeholder=""
                onChange={x => {
                  setNewLeoOption(x);
                  setIsFormDirty(true);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel="Save Modifications"
          saveDisabled={!isFormDirty}
          onSave={onSaveChanges}
        />
      </ModalFooter>
    </Modal>
  );
}
