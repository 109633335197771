import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function IssueBadgeApplicationBadgeConfirmationModal({ isOpen, onClose, isDeactivatingBadge }) {
  const [didVerifyIds, setDidVerifyIds] = useState(null);
  const [didHandoffBadge, setDidHandoffBadge] = useState(null);
  const [didReceiveDeactivatedBadge, setDidReceiveDeactivatedBadge] = useState(null);

  const isSaveDisabled = () => {
    return didVerifyIds !== 'true' || didHandoffBadge !== 'true' || didReceiveDeactivatedBadge !== 'true';
  };

  useEffect(() => {
    setDidVerifyIds(null);
    setDidHandoffBadge(null);
    if (!isDeactivatingBadge) {
      setDidReceiveDeactivatedBadge('true');
    } else {
      setDidReceiveDeactivatedBadge(null);
    }
  }, [isOpen]);

  return (
    <AirBadgeModal
      title="Issue Badge Confirmation"
      size={MODAL_SIZE_MEDIUM}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      saveLabel="Submit"
      onSave={() => onClose(true)}
      saveDisabled={isSaveDisabled()}
    >
      <Grid container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 5 }}>
            Did you check the applicant’s ID to verify they are who they say they are?
          </Typography>
          <FormControl>
            <RadioGroup row value={didVerifyIds} onChange={event => setDidVerifyIds(event.target.value)}>
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography style={{ marginBottom: 5 }}>Did you hand the applicant their new badge?</Typography>
          <FormControl>
            <RadioGroup row value={didHandoffBadge} onChange={event => setDidHandoffBadge(event.target.value)}>
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {isDeactivatingBadge && (
          <Grid item>
            <Typography style={{ marginBottom: 5 }}>
              Did you receive the old/deactivated badge from the applicant?
            </Typography>
            <FormControl>
              <RadioGroup
                row
                value={didReceiveDeactivatedBadge}
                onChange={event => setDidReceiveDeactivatedBadge(event.target.value)}
              >
                <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </AirBadgeModal>
  );
}
