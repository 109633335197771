import { buildFilterURIString, buildSortURIString } from '../../utils';
import request from '../Request';

export default {
  getAll: getAllTrainingCourses,
  getTrainingCourse,
  update: updateTrainingCourse,
  getAllTrainingData,
  getAccomplishments,
  assignCourse: assignTrainingCourse,
  assignTraineeGroup,
  removeCourseForUser: removeTrainingCourseForUser,
  removeGroupForUser: removeTraineeGroupForUser,
  createAccount,
  createCourse,
  getCourseLaunchUrl,
};

function getAllTrainingCourses([, pageSize, page, sorted, filtered], signal) {
  const url = `training/course?page=${page}&pageSize=${pageSize}&${buildSortURIString(sorted)}&${buildFilterURIString(
    filtered
  )}`;
  return request('authenticated-user', 'GET', url, null, signal);
}

function getTrainingCourse({ courseUuid, signal }) {
  return request('authenticated-user', 'GET', `training/course/${courseUuid}`, null, signal);
}

function updateTrainingCourse(data) {
  return request('authenticated-user', 'PUT', `training/course/${data.uuid}`, data);
}

function removeTrainingCourseForUser({ userUUID, courseCode, courseName, system }) {
  return request('authenticated-user', 'PATCH', `people/${userUUID}/training`, {
    system,
    removeCourse: { code: courseCode, name: courseName },
  });
}

function removeTraineeGroupForUser({ userUUID, keep, remove, system }) {
  return request('authenticated-user', 'PATCH', `people/${userUUID}/training`, {
    system,
    removeGroup: { keep, remove },
  });
}

function assignTrainingCourse({ userUUID, courseCode, courseName, system }) {
  return request('authenticated-user', 'PATCH', `people/${userUUID}/training`, {
    system,
    addCourse: { code: courseCode, name: courseName },
  });
}

function assignTraineeGroup({ userUUID, groupCode, groupName, groupType, system }) {
  return request('authenticated-user', 'PATCH', `people/${userUUID}/training`, {
    system,
    addGroup: { code: groupCode, name: groupName, type: groupType },
  });
}

function getAllTrainingData({ userUuid }) {
  return request('authenticated-user', 'GET', `people/${userUuid}/training`);
}

function getAccomplishments({ userUuid }) {
  return request('authenticated-user', 'GET', `people/${userUuid}/training-accomplishments`);
}

function createAccount({ userUuid, badgeApplicationUuid, lms }) {
  return request('authenticated-user', 'POST', `people/${userUuid}/training/create-account`, {
    badgeApplicationUuid,
    lms,
  });
}

function createCourse(data) {
  return request('authenticated-user', 'POST', 'training/course', data);
}

function getCourseLaunchUrl(data) {
  return request('authenticated-user', 'POST', 'training/course/get-launch-url', data);
}
