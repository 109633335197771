import request, { upload } from '../Request';

export default {
  upload: uploadFile,
  list: listFiles,
  listForBadgeApplication: listFilesForBadgeApplication,
  listForUser: listFilesForUser,
  dataforUser: getAssociatedDataForUser,
  delete: deleteFile,
  fileTypes: getFileTypes,
  createFileType,
  updateFileType,
  deleteFileType,
  getUrl: getSignedUrl,
  logs: getLogs,
};

function deleteFile({ user, fileUuid }) {
  return request(user, 'DELETE', `files/${fileUuid}`);
}

function getAssociatedDataForUser({ user, userUuid }) {
  return request(user, 'GET', `files/data-for-user/${userUuid}`);
}

function uploadFile({ user = 'authenticated-user', file, data = {} }) {
  data.size = file.size;
  data.extension = file.name.split('.').pop();
  data.mimeType = file.type;
  return upload(user, 'POST', `files/upload`, file, data);
}

function listFiles(user = {}, pageSize = 50, page = 0, sorted = [], filtered = {}) {
  return request(user, 'POST', 'files', {
    pageSize,
    page,
    sorted,
    filtered,
  });
}

function listFilesForBadgeApplication({ user, badgeApplicationUuid, onlyIds }) {
  return request(user || 'authenticated-user', 'POST', `files/badge-application/${badgeApplicationUuid}`, { onlyIds });
}

function listFilesForUser({ userUuid }) {
  return request('authenticated-user', 'GET', `files/user/${userUuid}`);
}

function getFileTypes() {
  return request('authenticated-user', 'GET', `files/types`);
}

function createFileType({ user, type }) {
  return request(user, 'POST', `files/types`, type);
}

function updateFileType({ user, type }) {
  return request(user, 'PUT', `files/types/${type.uuid}`, type);
}

function deleteFileType({ user, uuid }) {
  return request(user, 'DELETE', `files/types/${uuid}`);
}

function getSignedUrl({ user, uuid }) {
  return request(user, 'GET', `files/${uuid}/url`);
}

function getLogs({ user, fileUuid }) {
  return request(user, 'GET', `files/${fileUuid}/logs`);
}
