import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '../../../../../shared/components/NativeSelect/NativeSelect';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';

export default function HairColorQuestion({ isHidden, value, onBack, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState(value);
  const [includeBlank, setIncludeBlank] = useState(true);

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col xs="12" sm="4">
          <FormLabel>
            <Typography variant="h6">{t('question7')}</Typography>
          </FormLabel>
          <FormGroup>
            <NativeSelect
              id="hairColorSelect"
              name="hairColorSelect"
              options={[
                { label: 'Black', value: 'BLK' },
                { label: 'Blonde', value: 'BLN' },
                { label: 'Blue', value: 'BLU' },
                { label: 'Brown', value: 'BRO' },
                { label: 'Gray', value: 'GRY' },
                { label: 'Green', value: 'GRN' },
                { label: 'Orange', value: 'ONG' },
                { label: 'Pink', value: 'PNK' },
                { label: 'Purple', value: 'PLE' },
                { label: 'Red', value: 'RED' },
                { label: 'Sandy', value: 'SDY' },
                { label: 'White', value: 'WHI' },
                { label: 'Unknown', value: 'XXX' },
              ]}
              value={state}
              onChange={selectedOption => {
                setState(selectedOption);
                setIncludeBlank(false);
              }}
              includeBlank={includeBlank}
              required
            />
          </FormGroup>
        </Col>
      </Row>

      <BackButton onClick={onBack} />
      <NextButton isDisabled={!state} onClick={() => onNext(state)} />
    </>
  );
}
