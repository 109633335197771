import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import Cropper from 'cropperjs';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import request from '../../../../../../../ajax/Request';
import { buildFullName, handleError } from '../../../../../../../utils';
import { AppContext, colors } from '../../../../../../../App';
import documentRequirements from '../../../../../../Badger/steps/Scanning/components/documentRequirements';
import TitledBox from '../../../../../../../shared/TitledBox/TitledBox';
import RejectIdModal from '../Validate/ValidateDocuments/RejectIdModal';
import { IDInformationDisplay } from '../Validate/ValidateDocuments/ValidateDocuments';
import { hasFeature } from '../../../../../../../shared/Feature';
import SignatoryPreVerifyPayments from './SignatoryPreVerifyPayments';
import useConfirm from '../../../../../../../shared/useConfirm';
import { CropperToolbar, ToolbarButton } from '../../../../../../../shared/ImageEditor/ImageEditor';

function NameDisplay({ badgerData }) {
  const { aliases } = badgerData;
  const labelStyle = { textAlign: 'right', fontWeight: 600 };
  const newLegalName = buildFullName(badgerData);
  return (
    <Grid container direction="column" spacing={1}>
      <Grid container item direction="row" spacing={2}>
        <Grid item xs={2} style={labelStyle}>
          LEGAL NAME
        </Grid>
        <Grid item xs={10} style={{ textAlign: 'left' }}>
          {newLegalName}
        </Grid>
      </Grid>
      {aliases?.length > 0 ? (
        <>
          {aliases.map((a, i) => (
            <Grid key={i} container item direction="row" spacing={2}>
              <Grid item xs={2} style={labelStyle}>
                ALIAS
              </Grid>
              <Grid item xs={10} style={{ textAlign: 'left' }}>
                {buildFullName(a)}
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={2} style={labelStyle}>
            ALIASES
          </Grid>
          <Grid item xs={10} style={{ textAlign: 'left', fontStyle: 'italic' }}>
            none given
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

function DocumentDisplay({ document, badgerData, app, onQuestionAnswered }) {
  const [hasImage, setHasImage] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [isPdf, setIsPdf] = useState(false);
  const [isNameFound, setIsNameFound] = useState(null);
  const [isDocumentDataCorrect, setIsDocumentDataCorrect] = useState(null);

  const loadImage = () => {
    if (cropper) cropper.destroy();
    setCropper(null);
    setHasImage(Number(document.bytes) > 0);
    setIsLoadingImage(true);
    setIsNameFound(null);
    setIsDocumentDataCorrect(null);

    const mimeType = (document.mimeType || '').toLocaleLowerCase();
    const name = (document.name || '').toLocaleLowerCase();
    const isPdf = mimeType.includes('pdf') || name.endsWith('pdf');
    setIsPdf(isPdf);

    if (window.document.querySelector('#document-image')) {
      window.document.querySelector('#document-image').src = null;
    }
    if (window.document.querySelector('#document-pdf')) {
      window.document.querySelector('#document-pdf').src = 'about:blank';
    }

    function handler() {
      if (this.readyState === this.DONE && this.status === 200) {
        if (isPdf && window.document.querySelector('#document-pdf')) {
          window.document.querySelector('#document-pdf').src = URL.createObjectURL(this.response);
        } else if (window.document.querySelector('#document-image')) {
          const imageElement = window.document.querySelector('#document-image');
          imageElement.src = URL.createObjectURL(this.response);
          const cropper = new Cropper(imageElement, {
            viewMode: 1,
            dragMode: 'none',
            zoomOnWheel: false,
            autoCrop: false,
            movable: true,
            background: false,
            toggleDragModeOnDblclick: false,
            minContainerWidth: 545,
            minContainerHeight: 425,
          });
          setCropper(cropper);
        }
      }

      setTimeout(() => setIsLoadingImage(false), 500);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/api.php/default/v1/files/${document.uuid}/stream`);
    xhr.setRequestHeader('X-AUTH-TOKEN', app.user.authToken);
    xhr.setRequestHeader('X-PROFILE-UUID', app.user.activeProfile ? app.user.activeProfile.uuid : '');
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.send();
  };

  const isImageRequired = () => {
    if (!document) return false;
    return !!documentRequirements[document.scanDocumentCode]?.image;
  };

  const isApproveIdDisabled = () => {
    if (!document) return true;
    if ((isNameFound !== 'exact' && isNameFound !== 'no_name') || isDocumentDataCorrect !== 'true') return true;
    if (Number(document.bytes) < 1 && isImageRequired()) {
      return true;
    }
    return document.scanStatus === 'rescan';
  };

  useEffect(() => {
    onQuestionAnswered({ isApproved: isApproveIdDisabled() });
  }, [isNameFound, isDocumentDataCorrect]);

  useEffect(() => {
    if (!document) return;
    loadImage();
  }, [document]);

  if (!document) return null;

  return (
    <Card>
      <CardHeader title={document?.fileTypeName.replace('Identity:', '')} />
      <CardMedia>
        {isLoadingImage && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress size={45} />
          </div>
        )}
        {!hasImage && !isLoadingImage && (
          <div style={{ padding: 30 }}>
            {isImageRequired() ? (
              <div style={{ fontWeight: 'bold', color: colors.danger, textAlign: 'center' }}>
                {document?.scanStatus === 'rescan' ? 'Waiting on applicant to provide a new image' : 'IMAGE REQUIRED'}
              </div>
            ) : (
              'No image provided'
            )}
          </div>
        )}
        <img
          id="document-image"
          alt={!hasImage || isLoadingImage || isPdf ? '' : 'Document Image'}
          style={{
            display: !hasImage || isLoadingImage || isPdf ? 'none' : 'inline-block',
            width: 545,
            height: 425,
          }}
        />
        <iframe
          id="document-pdf"
          title="Identity Document"
          src="about:blank"
          style={{
            display: !hasImage || isLoadingImage || !isPdf ? 'none' : 'inline-block',
            width: 545,
            height: 425,
          }}
        ></iframe>
      </CardMedia>
      <CardContent style={{ position: 'relative' }}>
        <div style={{ marginBottom: 40 }}>
          <CropperToolbar cropper={cropper} buttonColor="secondary" />
        </div>

        <TitledBox title="Names Entered On Badge Application" style={{ width: '100%' }}>
          <NameDisplay badgerData={badgerData} />
          <div className="mt-3 mb-2 required" style={{ fontSize: 16 }}>
            Examine the name on the document. Does it match the legal name or any aliases entered on the badge
            application?
          </div>
          <RadioGroup value={isNameFound} onChange={event => setIsNameFound(event.target.value)}>
            <FormControlLabel
              value="exact"
              control={<Radio color="primary" />}
              label="Matches the legal name or an alias exactly"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="Does not match the legal name exactly, any alias exactly, or is not listed"
            />
            <FormControlLabel
              value="no_name"
              control={<Radio color="primary" />}
              label="No legal name or alias shown on document (uncommon)"
            />
          </RadioGroup>
        </TitledBox>
        <div className="mt-2">&nbsp;</div>
        <TitledBox title="ID Information Entered On Badge Application" style={{ width: '100%' }}>
          <IDInformationDisplay document={document} shouldReduceSize={true} />
          <div className="mt-3 mb-2 required" style={{ fontSize: 16 }}>
            Examine the information on the document. Does it match the information entered on the badge application?
          </div>
          <RadioGroup value={isDocumentDataCorrect} onChange={event => setIsDocumentDataCorrect(event.target.value)}>
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Yes, everything matches exactly"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="No, the data entered is incorrect"
            />
          </RadioGroup>
        </TitledBox>
      </CardContent>
    </Card>
  );
}

let requestTimeout = null;
let abortController = null;

export default function SignatoryPreVerifyModal({ isOpen, badgeApplication, onClose }) {
  const showConfirm = useConfirm();
  const [documents, setDocuments] = useState([]);
  const [badgerData, setBadgerData] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isRejectIdModalOpen, setIsRejectIdModalOpen] = useState(false);
  const [rejectIdMode, setRejectIdMode] = useState(null);
  const [isSignatoryPreVerifyPaymentsShown, setIsSignatoryPreVerifyPaymentsShown] = useState(false);
  const [shouldBePaidBySignatory, setShouldBePaidBySignatory] = useState(false);

  const app = useContext(AppContext);

  const onModalClose = () => {
    onClose({ shouldProceedToSignDocument: false });
  };

  const loadData = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    app.api.toggleLoading(true);
    setRejectIdMode(null);
    setDocuments([]);
    setIsNextButtonDisabled(true);

    requestTimeout = setTimeout(() => {
      app.api.toggleLoading(true);
      abortController = new AbortController();
      request(
        'authenticated-user',
        'GET',
        `badge-applications/${badgeApplication.uuid}/documents-to-verify`,
        null,
        abortController.signal
      )
        .then(({ files, badgerData }) => {
          const documents = (files || []).filter(f => {
            // They only want to validate documents that have an image requirement
            return documentRequirements[f.scanDocumentCode]?.image;
          });
          setDocuments(documents);
          setSelectedDocumentIndex(0);
          setSelectedDocument(documents[0]);
          setBadgerData(badgerData || {});
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }, 500);
  };

  const onRejectIdModalClose = async ({ wasRejected }) => {
    setIsRejectIdModalOpen(false);
    if (wasRejected) {
      onClose({ shouldProceedToSignDocument: false });
    }
  };

  const onRequestNewImageClick = () => {
    setRejectIdMode('replace');
    setIsRejectIdModalOpen(true);
  };

  const onRejectIdClick = () => {
    showConfirm({
      title: 'Are you sure?',
      content: (
        <div>
          <strong className="text-danger">
            This will force the applicant to completely restart their badge application!
          </strong>
          <div className="mt-3">
            If you only require a new image to be uploaded, close this popup and click the "Request New Image" button.
          </div>
        </div>
      ),
      onConfirm: () => {
        setRejectIdMode('reject');
        setIsRejectIdModalOpen(true);
      },
      isDangerous: true,
      okButtonText: 'Reject ID & Restart Badge Application',
    });
  };

  const onGotoNext = () => {
    const nextIndex = selectedDocumentIndex + 1;
    if (nextIndex < documents.length) {
      setSelectedDocumentIndex(nextIndex);
      setSelectedDocument(documents[nextIndex]);
      setIsNextButtonDisabled(true);
    } else {
      if (
        hasFeature('payments', app?.airport?.config?.features || []) &&
        !badgeApplication.isPaid &&
        !isSignatoryPreVerifyPaymentsShown
      ) {
        setSelectedDocument(null);
        setIsSignatoryPreVerifyPaymentsShown(true);
      } else {
        onClose({ shouldProceedToSignDocument: true, shouldBePaidBySignatory });
      }
    }
  };

  const getSaveButtonLabel = () => {
    if (isSignatoryPreVerifyPaymentsShown) {
      return (
        <>
          <i className="fa-solid fa-file-signature mr-2"></i>Sign Badge Application
        </>
      );
    }

    if (selectedDocumentIndex === documents.length - 1) {
      if (hasFeature('payments', app?.airport?.config?.features || []) && !badgeApplication.isPaid) {
        return (
          <>
            Next <i className="fa-solid fa-chevron-right ml-2"></i>
          </>
        );
      } else {
        return (
          <>
            <i className="fa-solid fa-file-signature mr-2"></i>Sign Badge Application
          </>
        );
      }
    }
    return (
      <>
        Next Document<i className="fa-solid fa-chevron-right ml-2"></i>
      </>
    );
  };

  useEffect(() => {
    setIsSignatoryPreVerifyPaymentsShown(false);
    if (isOpen) {
      loadData();
    }

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (badgeApplication) {
      setShouldBePaidBySignatory(badgeApplication.paymentChoice === 'signatory');
    }
  }, [badgeApplication]);

  if (!isOpen || !badgeApplication) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title={isSignatoryPreVerifyPaymentsShown ? 'Review Payment Choice' : 'Validate Documents'}
      isOpen={isOpen}
      onClose={onModalClose}
      onSave={onGotoNext}
      saveLabel={getSaveButtonLabel()}
      saveDisabled={documents.length < 1 || isNextButtonDisabled}
    >
      {!isSignatoryPreVerifyPaymentsShown && (
        <>
          <Alert color="info">
            As the Signatory, you must validate the applicant's documents before signing the badge application. Do not
            sign the badge application until you are able to validate all of the documents. It may be necessary for you
            to ask the applicant to restart their badge application.
          </Alert>
          <DocumentDisplay
            document={selectedDocument}
            badgerData={badgerData}
            app={app}
            onQuestionAnswered={({ isApproved }) => {
              setIsNextButtonDisabled(isApproved);
            }}
          />
        </>
      )}

      <RejectIdModal
        isOpen={isRejectIdModalOpen}
        onClose={onRejectIdModalClose}
        badgeApplication={badgeApplication}
        rejectedDocument={selectedDocument}
        mode={rejectIdMode}
        isActivatedBySignatory={true}
      />

      <SignatoryPreVerifyPayments
        isShown={isSignatoryPreVerifyPaymentsShown}
        badgeApplication={badgeApplication}
        onPaymentChoiceChange={setShouldBePaidBySignatory}
      />

      {!isSignatoryPreVerifyPaymentsShown && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 24,
            marginBottom: 28,
          }}
        >
          <Button
            className="mr-5"
            color="warning"
            onClick={onRequestNewImageClick}
            disabled={document?.scanStatus === 'rescan' || !isNextButtonDisabled}
            title={document?.scanStatus === 'rescan' ? 'Waiting on applicant to provide a new image' : undefined}
          >
            <i className="fa-regular fa-envelope mr-2"></i>Request New Image
          </Button>

          <Button color="danger" onClick={onRejectIdClick} disabled={!isNextButtonDisabled}>
            <i className="fa-solid fa-ban mr-2"></i>Reject ID & Restart Badge Application
          </Button>
        </div>
      )}
    </AirBadgeModal>
  );
}
