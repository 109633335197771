import React from 'react';
import packageInfo from '../../../package.json';
import { isSmallScreen } from '../../utils';

export default function DefaultFooter() {
  const currentYear = new Date().getFullYear();
  const companyName = 'AirBadge, LLC';
  const isMobile = isSmallScreen();

  const privacyPolicy = (
    <a
      className={isMobile ? null : 'mr-3'}
      target="_blank"
      rel="noopener noreferrer"
      href="https://airbadge.us/privacy-policy/"
    >
      Privacy Policy
    </a>
  );

  const help = (
    <a className={isMobile ? null : 'mr-3'} target="_blank" rel="noopener noreferrer" href="https://help.airbadge.us">
      Help
    </a>
  );

  const terms = (
    <a className={isMobile ? null : 'mr-3'} target="_blank" rel="noopener noreferrer" href="terms.html">
      {'Terms & Conditions'}
    </a>
  );

  const copyright = (
    <>
      <a target="_blank" rel="noopener noreferrer" href="https://airportbadges.com">
        {companyName} &copy; {currentYear}
      </a>{' '}
      - v{packageInfo.version}
    </>
  );

  if (isMobile) {
    return (
      <>
        <div className="d-flex justify-content-between">
          <div>{privacyPolicy}</div>
          <div>{copyright}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>{terms}</div>
          <div>{help}</div>
        </div>
      </>
    );
  }

  return (
    <div className="d-flex justify-content-between">
      <div>
        {terms}
        {privacyPolicy}
        {help}
      </div>
      <div>{copyright}</div>
    </div>
  );
}
