import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { BlockFormLabel } from '../components/styledComponents';
import { upload } from '../../../ajax/Request';
import FileUploadForm from '../../../shared/components/FileUploadModal/FileUploadForm';
import { colors } from '../../../App';
import { handleError, isSmallScreen } from '../../../utils';

const useStyles = makeStyles(theme => ({
  uploadButton: {
    color: 'white',
  },
  buttonRow: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  notice: {
    marginTop: theme.spacing(2),
    color: colors.warning,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
}));

export default function ManualUpload({ isHidden, info, badgeApplicationData }) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isDoneVisible, setIsDoneVisible] = useState(false);
  const [files, setFiles] = useState([]);

  const addFiles = files => {
    setFiles(files);
  };

  const doUpload = () => {
    const file = files[0];
    const data = {
      key: info.key,
      size: file.size,
      extension: file.name.split('.').pop(),
      mimeType: file.type,
      badgeApplicationData: JSON.stringify(badgeApplicationData),
    };

    upload(null, 'POST', `badger/badge-application-upload`, file, data).then(({ success }) => {
      if (success) {
        setIsDoneVisible(true);
      } else {
        setIsDoneVisible(false);
        handleError({ message: 'Unable to upload scanned document' });
      }
    });
  };

  if (isHidden) return null;

  if (isDoneVisible) {
    return (
      <BlockFormLabel>
        <Typography variant="h6">{t('badgeApplication.done')}</Typography>
      </BlockFormLabel>
    );
  }

  return (
    <>
      <BlockFormLabel style={{ marginBottom: 24 }}>
        <Typography variant="h6">{t('manualUpload.statement')}</Typography>
      </BlockFormLabel>

      <FileUploadForm onFilesAdded={addFiles} />

      <div className={classes.buttonRow}>
        <Button
          size={isSmallScreen() ? 'small' : 'large'}
          variant="contained"
          color="primary"
          onClick={doUpload}
          className={classes.uploadButton}
          disabled={!files || !files.length}
        >
          {t('manualUpload.uploadButton')}
        </Button>
        <Typography variant="subtitle2" className={classes.notice}>
          {t('manualUpload.uploadNotice')}
        </Typography>
      </div>
    </>
  );
}
