import request from '../Request';

export default {
  generateDataRequestToken,
  generateDataRequestTokenUsingKey,
  updateTransactionStatus,
  getAuditingResponseDocumentDataRequestToken,
};

function generateDataRequestToken(transactionUuid) {
  return request('authenticated-user', 'POST', `doc-spring/generate-data-request-token`, { transactionUuid });
}

function generateDataRequestTokenUsingKey(badgerKey, transactionUuid) {
  return request(null, 'POST', `badger/doc-spring/generate-data-request-token`, {
    transactionUuid,
    badgerKey,
  });
}

function updateTransactionStatus(transactionUuid, submissionId) {
  return request('authenticated-user-or-null', 'POST', `badger/doc-spring/update-document-transaction`, {
    transactionUuid,
    submissionId,
  });
}

function getAuditingResponseDocumentDataRequestToken(auditingResponseDocumentUuid) {
  return request(
    'authenticated-user',
    'GET',
    `authorized-signatory-auditing/get-auditing-response-document/${auditingResponseDocumentUuid}`
  );
}
