import React from 'react';
import { Input } from 'reactstrap';

export default function InputWithCharacterLimit({
  limit = 255,
  type = 'textarea',
  style = { height: 100 },
  value,
  onChange,
  ...rest
}) {
  let warningLimit = Math.floor(limit * 0.08);
  return (
    <>
      <Input
        type={type}
        maxLength={limit}
        style={style}
        value={value}
        onChange={({ target: { value } }) => value.length <= limit && onChange(value)}
        {...rest}
      />
      <div
        style={{
          fontSize: 'smaller',
          marginTop: 3,
          marginRight: 3,
          textAlign: 'right',
          color: limit - value.length < warningLimit ? 'red' : 'black',
        }}
      >
        <i>{limit - value.length} characters remaining</i>
      </div>
    </>
  );
}
