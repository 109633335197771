import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { colors } from '../../../../../App';
import NativeSelect from '../../../../../shared/components/NativeSelect/NativeSelect';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import BadgerFormLabel from '../../../components/BadgerFormLabel';
import { trim } from './badgerUtils';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  removeButton: {
    position: 'absolute',
    zIndex: 100,
    right: 0,
    top: theme.spacing(1),
    color: colors.danger,
    fontSize: 'smaller',
  },
}));

export default function AliasesQuestion({ isHidden, value, onBack, onNext }) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState([...value]);

  const isNextButtonHidden = () => {
    if (state.length < 1) return false;

    let isHidden = false;
    state.forEach(({ first, last }) => {
      if (!first || !last) isHidden = true;
    });
    return isHidden;
  };

  const cleanupData = () => {
    const newState = state.map(x => ({
      ...x,
      last: trim(x.last).replace('- ', '-').replace(' -', '-'),
      first: trim(x.first).replace('- ', '-').replace(' -', '-'),
      middle: trim(x.middle).replace('- ', '-').replace(' -', '-'),
    }));
    setState(newState);
    return newState;
  };

  const addAnotherAlias = () => {
    setState([...state, { unique: new Date().getTime(), first: '', last: '', middle: '', suffix: null }]);
  };

  const onChange =
    (index, field) =>
    ({ target }) => {
      const regex = /^[A-Za-z\s-]*$/;
      const newValue = target.value;
      if (!newValue) {
        state[index][field] = '';
        setState([...state]);
      } else if (regex.test(newValue)) {
        state[index][field] = newValue;
        setState([...state]);
      }
    };

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col>
          <FormLabel>
            <Typography variant="h6">{t('question2')}</Typography>
          </FormLabel>
          <FormLabel>
            <Typography variant="subtitle2">{t('question2_extra')}</Typography>
          </FormLabel>
        </Col>
      </Row>
      {state.map((x, i) => (
        <div style={{ position: 'relative' }} key={x.unique}>
          <hr />
          <Button
            size="small"
            variant="text"
            startIcon={<DeleteIcon />}
            className={classes.removeButton}
            onClick={() => setState([...state.filter(entry => entry.unique !== x.unique)])}
          >
            {t('question2_remove_button')}
          </Button>
          <Row className={classes.row}>
            <Col sm="12" lg="3">
              <FormGroup>
                <BadgerFormLabel>{t('question2_last_name')}</BadgerFormLabel>
                <Input value={x.last} onChange={onChange(i, 'last')} />
              </FormGroup>
            </Col>
            <Col sm="12" lg="3">
              <FormGroup>
                <BadgerFormLabel>{t('question2_first_name')}</BadgerFormLabel>
                <Input value={x.first} onChange={onChange(i, 'first')} />
              </FormGroup>
            </Col>
            <Col sm="12" lg="3">
              <FormGroup>
                <BadgerFormLabel>{t('question2_middle_name')}</BadgerFormLabel>
                <Input value={x.middle} onChange={onChange(i, 'middle')} />
              </FormGroup>
            </Col>
            <Col sm="12" lg="3">
              <FormGroup>
                <BadgerFormLabel>{t('question2_suffix_name')}</BadgerFormLabel>
                <NativeSelect
                  id="suffix"
                  name="suffix"
                  options={[
                    { label: 'JR', value: 'JR' },
                    { label: 'SR', value: 'SR' },
                    { label: 'I', value: 'I' },
                    { label: 'II', value: 'II' },
                    { label: 'III', value: 'III' },
                    { label: 'IV', value: 'IV' },
                    { label: 'V', value: 'V' },
                    { label: 'VI', value: 'VI' },
                    { label: 'VII', value: 'VII' },
                    { label: 'VIII', value: 'VIII' },
                    { label: 'IX', value: 'IX' },
                  ]}
                  includeBlank
                  value={x.suffix}
                  onChange={selectedOption => {
                    state[i].suffix = selectedOption || null;
                    setState([...state]);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      ))}
      {state.length > 0 && <hr />}
      {state.length <= 2 && (
        <Row>
          <Col>
            <Button
              variant="contained"
              size="small"
              className={classes.addButton}
              startIcon={<AddIcon />}
              onClick={addAnotherAlias}
            >
              {t('question2_add_button')}
            </Button>
          </Col>
        </Row>
      )}

      <BackButton onClick={onBack} />
      <NextButton isDisabled={isNextButtonHidden()} onClick={() => onNext(cleanupData())} />
    </>
  );
}
