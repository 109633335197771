import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import { AppContext } from '../../../../App';
import ReactTableTotalCount from '../../Common/ReactTableTotalCount';
import BadgeDataTableRequest from '../../../../ajax/Badge/BadgeDataTableRequest';
import { useFormattedDateTime } from '../../../../shared/components/FormattedDateTime/FormattedDateTime';
import BadgeDetailsModal from '../../Badges/BadgesList/BadgeDetailsModal';

function CellWithTitle({ value, ...rest }) {
  return (
    <span {...rest} title={value}>
      {value}
    </span>
  );
}

function ExpirationDisplay({ date, isInRenewal }) {
  const [formatted] = useFormattedDateTime({ value: date });

  if (!date) return '-';
  return (
    <>
      {formatted}{' '}
      {isInRenewal && (
        <i className="fa fa-circle text-success" style={{ paddingLeft: 5 }} title="Renewal in progress"></i>
      )}
    </>
  );
}

let requestTimeout = null;
let abortController = null;

export default function AuthorizedSignatoryBadgesList({ signatory, reloadTrigger }) {
  const app = useContext(AppContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [badges, setBadges] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState([]);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);

  const onShowBadgeClick = badge => {
    setSelectedBadge(badge);
    setIsDetailModalOpen(true);
  };

  const columns = [
    {
      id: 'id',
      Header: 'ID',
      stateKey: 'number',
      minWidth: 100,
      accessor: row => (
        <button type="button" className="btn btn-link" onClick={() => onShowBadgeClick(row)}>
          {row.number}
        </button>
      ),
    },
    {
      id: 'type',
      Header: 'Type',
      stateKey: 'type',
      accessor: 'type',
      minWidth: 70,
    },
    {
      id: 'name',
      Header: 'Name',
      stateKey: 'badgeHolderName',
      accessor: ({ badgeHolderName }) => <CellWithTitle value={badgeHolderName} />,
      minWidth: 200,
    },
    {
      id: 'expiration_date',
      Header: 'Expires',
      stateKey: 'expires',
      accessor: ({ expires, is_in_renewal }) => <ExpirationDisplay date={expires} isInRenewal={!!is_in_renewal} />,
      minWidth: 110,
    },
  ];

  const fetchData = state => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    if (!signatory) return;

    setBadges([]);
    setPages(0);
    setTotal(0);
    setIsLoading(true);

    requestTimeout = setTimeout(() => {
      const filtered = {
        ...state.filtered,
        filterByAuthorizedSignatory: signatory.profileUuid,
      };
      const params = [null, state.pageSize, state.page, state.sorted, filtered];
      setParams(params);

      abortController = new AbortController();
      callApi(...params, abortController.signal);
    }, 500);
  };

  const callApi = async (user, pageSize, page, sorted, filtered, signal) => {
    const { data, pages, total } = await BadgeDataTableRequest(user, pageSize, page, sorted, filtered, signal);
    setBadges(data);
    setPages(pages);
    setTotal(total);
    setIsLoading(false);
  };

  const onDetailsModalClose = () => {
    setIsDetailModalOpen(false);
    setSelectedBadge(null);
  };

  useEffect(() => {
    if (!reloadTrigger) return;

    fetchData({ pageSize: params[1], page: params[2], sorted: params[3], filtered: params[4] });
  }, [reloadTrigger]);

  useEffect(() => {
    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, []);

  if (!signatory) return null;

  return (
    <Card>
      <CardBody>
        <div className="table-responsive">
          <ReactTable
            manual
            noDataText="No badges assigned"
            defaultSorted={[{ id: 'expiration_date', desc: false }]}
            defaultFiltered={[{ id: 'status', value: 'in_use' }]}
            className="border-0 -striped"
            loading={isLoading}
            data={badges}
            columns={columns}
            pages={pages}
            minRows={0}
            defaultPageSize={50}
            onFetchData={fetchData}
            showPagination={pages > 1}
          />

          <ReactTableTotalCount params={params} total={total} />
        </div>

        <BadgeDetailsModal
          isOpen={isDetailModalOpen}
          onClose={onDetailsModalClose}
          state={app}
          api={app.api}
          badge={selectedBadge}
          history={history}
        />
      </CardBody>
    </Card>
  );
}
