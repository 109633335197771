import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';

export default function EscortEndorsementPicker({
  context,
  isDisabled,
  isReadOnly,
  displayAsGridItem,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const { escortEndorsementConfig, escortSelection, setEscortSelection, shouldSkipDefaultSelection, data } = context;

  const [isShown, setIsShown] = useState(false);

  const noOption = escortEndorsementConfig?.options?.no;
  const yesOption = escortEndorsementConfig?.options?.yes;

  useEffect(() => {
    if (!data.badgeType) {
      setEscortSelection(null);
      setIsShown(false);
      return;
    }

    if (!shouldSkipDefaultSelection) {
      if (yesOption?.isDefault) {
        setEscortSelection({ label: 'yes', value: 1 });
      } else if (noOption?.isDefault) {
        setEscortSelection({ label: 'no', value: 0 });
      } else {
        setEscortSelection(null);
      }
    }

    if (noOption && yesOption) {
      setIsShown(noOption?.isEnabled && yesOption?.isEnabled);
    } else {
      setIsShown(true);
    }
  }, [data.badgeType, escortEndorsementConfig]);

  if ((noOption?.isEnabled && !yesOption?.isEnabled) || (!noOption?.isEnabled && yesOption?.isEnabled)) {
    return (
      <div>
        <Label>Will this individual be an Escort?</Label>
        <br />
        <i>{escortSelection?.value === 1 ? 'Yes' : 'No'}</i>
        <div>&nbsp;</div>
      </div>
    );
  }

  if (!isShown) {
    return null;
  }

  if (isReadOnly) {
    if (displayAsGridItem) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <ReadOnlyFormData label="Escort" value={escortSelection?.label || 'error'} />
        </Grid>
      );
    }
    return (
      <div>
        <ReadOnlyFormData label="Escort" value={escortSelection?.label || 'error'} />
      </div>
    );
  }

  return (
    <div>
      <Label className="required">Will this individual be an Escort?</Label>
      <br />
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="Escort"
          name="rgEscort"
          value={escortSelection?.label || null}
          onChange={({ target }) => {
            if (target.value === 'yes') {
              setEscortSelection({ label: 'yes', value: 1 });
            } else {
              setEscortSelection({ label: 'no', value: 0 });
            }
          }}
        >
          <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" disabled={!!isDisabled} />
          <FormControlLabel value="no" control={<Radio color="primary" />} label="No" disabled={!!isDisabled} />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
