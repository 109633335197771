import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { buildFullName } from '../../utils';
import ReadOnlyFormData from '../components/ReadOnlyFormData/ReadOnlyFormData';

function PersonOption({ data, onClick }) {
  const { uuid, isActive, meta } = data;
  let { date_of_birth: dob, email, tsc_person_id: airportPersonId } = meta;
  if (dob && dob.includes('-')) {
    const [year, month, day] = dob.split('-');
    dob = `${month}/${day}/${year}`;
  }

  return (
    <>
      <button className="btn btn-link p-0" style={{ fontWeight: 'bold' }} onClick={() => onClick({ uuid })}>
        {buildFullName(meta)}
        {!isActive && <i className="ml-1 fa-solid fa-ban text-danger" style={{ opacity: 0.8, fontSize: 12 }}></i>}
      </button>
      <div>
        <i>Airport Person ID:</i> {airportPersonId || '-'}
      </div>
      <div>
        <i>Email:</i> {email || '-'}
      </div>
      <div>
        <i>DoB:</i> {dob || '-'}
      </div>
    </>
  );
}

function BadgeApplicationOption({ data, onClick }) {
  const { badgeApplications } = data;

  if (!badgeApplications?.length) {
    return <ReadOnlyFormData label="Badge Applications" value="-" faded={true} skipTextTransform />;
  }

  return (
    <ReadOnlyFormData
      skipTextTransform
      label="Badge Applications"
      value={badgeApplications.map(({ uuid, display_name, type }) => (
        <div key={uuid} className="mt-2" style={{ cursor: 'pointer' }} onClick={() => onClick({ uuid })}>
          <button className="btn btn-link p-0 text-left mr-1">{display_name}</button>
          <span style={{ fontVariantPosition: 'super', opacity: 0.8 }}>({type})</span>
        </div>
      ))}
    />
  );
}

function BadgeOption({ data, onClick }) {
  const { badges } = data;

  if (!badges?.length) {
    return <ReadOnlyFormData label="Badges" value="-" faded={true} skipTextTransform />;
  }

  return (
    <ReadOnlyFormData
      label="Badges"
      value={badges.map(({ uuid, number, display_name, isActive }) => (
        <div
          key={uuid}
          className="mt-2"
          style={{ cursor: 'pointer' }}
          onClick={() => onClick({ uuid })}
          title={`${number} - ${display_name} - ${isActive ? 'active' : 'deactivated'}`}
        >
          <button className="btn btn-link p-0 text-left mr-1">{number}</button>
          {!isActive && <i className="fa-solid fa-ban text-danger" style={{ opacity: 0.8, fontSize: 12 }}></i>}
        </div>
      ))}
    />
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  wrapperHighlight: {
    backgroundColor: '#E5EEF2',
  },
  person: {
    flex: 0.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  badgeApplication: {
    flex: 0.3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  badge: {
    flex: 0.2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));

export default function SearchResultOption({
  data,
  onPersonClicked,
  onBadgeApplicationClicked,
  onBadgeClicked,
  shouldShowHighlight,
}) {
  const classes = useStyles();

  const wrapperClasses = [classes.wrapper];
  if (shouldShowHighlight) {
    wrapperClasses.push(classes.wrapperHighlight);
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className={classes.person}>
        <PersonOption data={data} onClick={onPersonClicked} />
      </div>
      <div className={classes.badgeApplication}>
        <BadgeApplicationOption data={data} onClick={onBadgeApplicationClicked} />
      </div>
      <div className={classes.badge}>
        <BadgeOption data={data} onClick={onBadgeClicked} />
      </div>
    </div>
  );
}
