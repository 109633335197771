import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import FontAwesomeIcon from '../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { AppContext } from '../../../../App';
import appointmentApi from '../../../../ajax/Appointment/appointmentApi';
import { handleError, notify } from '../../../../utils';

export default function AppointmentRequestEditModal({ isOpen, onClose, selectedRequest }) {
  const app = useContext(AppContext);

  const onModalClose = () => {
    onClose({ shouldReload: false });
  };

  const onSendNotificationClicked = () => {
    app.api.confirmMessage(
      'Are you sure?',
      'Are you sure you want to re-send the notification for this appointment request?',
      () => {
        app.api.toggleLoading(true);
        appointmentApi
          .sendAppointmentRequestNotification(selectedRequest.id)
          .then(({ success }) => {
            if (success) {
              notify({ message: 'Notification sent' });
            }
          })
          .catch(error => handleError({ error }))
          .finally(() => app.api.toggleLoading(false));
      },
      null,
      false,
      'Send Notification'
    );
  };

  const onCancelRequestClicked = () => {
    app.api.confirmMessage(
      'Are you sure?',
      'Are you sure you want to cancel this appointment request?',
      () => {
        app.api.toggleLoading(true);
        appointmentApi
          .deleteAppointmentRequest(selectedRequest.id)
          .then(() => {
            onClose({ shouldReload: true });
          })
          .catch(error => handleError({ error }))
          .finally(() => app.api.toggleLoading(false));
      },
      null,
      'true',
      'Cancel Appointment Request'
    );
  };

  if (!isOpen || !selectedRequest) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Appointment Request Details"
      isOpen={isOpen}
      onClose={onModalClose}
      closeLabel="Close"
    >
      <Row>
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Appointment Type"
            value={selectedRequest?.appointmentType?.name || 'ERROR'}
          />
        </Col>
        <Col>
          <ReadOnlyFormData skipTextTransform label="Request Expires On" value={selectedRequest?.expires || 'ERROR'} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Attendee (AirportPersonId)"
            value={
              <span>
                {selectedRequest?.attendee?.fullName || 'ERROR'} ({selectedRequest?.attendee?.airportPersonId || '-'})
              </span>
            }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <ReadOnlyFormData
            skipTextTransform
            label="Attendee Instructions / Comments"
            value={selectedRequest?.notes || <i>None entered</i>}
          />
        </Col>
      </Row>

      <div className="separator mt-4">ACTIONS</div>

      <style>{`
        .icon-size-adjust {
          min-width: 38px;
        }
      `}</style>

      <Row className="mb-4">
        <Col>
          <List>
            <ListItem button onClick={onSendNotificationClicked}>
              <ListItemIcon classes={{ root: 'icon-size-adjust' }}>
                <FontAwesomeIcon className="fa-solid fa-bell" />
              </ListItemIcon>
              <ListItemText primary="Re-send Notification" />
            </ListItem>
          </List>
        </Col>
        <Col>
          <List>
            <ListItem button onClick={onCancelRequestClicked}>
              <ListItemIcon classes={{ root: 'icon-size-adjust' }}>
                <FontAwesomeIcon className="fa-solid fa-ban text-danger" />
              </ListItemIcon>
              <ListItemText primary="Cancel Request" />
            </ListItem>
          </List>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
