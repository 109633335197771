import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AirBadgeModal, {
  MODAL_SIZE_LARGE,
  MODAL_SIZE_MEDIUM,
} from '../../../shared/components/AirBadgeModal/AirBadgeModal';
import ReadOnlyFormData from '../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { AppContext, colors } from '../../../App';
import FontAwesomeIcon from '../../../shared/components/FontAwesome/FontAwesomeIcon';
import ChangeSignatoryCompanyModal from './components/ChangeSignatoryCompanyModal';
import authorizedSignatoriesApi from '../../../ajax/AuthorizedSignatory/authorizedSignatoriesApi';
import { handleError, notify } from '../../../utils';
import ChangeSignatoryTrainingExpirationModal from './components/ChangeSignatoryTrainingExpirationModal';
import PeopleListEditModal from '../People/PeopleList/PeopleListEditModal';
import ReassignSignatoryBadgesModal from './components/ReassignSignatoryBadgesModal';
import Collapsible from '../../../shared/components/Collapsible/Collapsible';
import InputWithCharacterLimit from '../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import profileCommentApi from '../../../ajax/ProfileComment/profileCommentApi';
import AuthorizedSignatoryToggleActiveRequest from '../../../ajax/AuthorizedSignatory/AuthorizedSignatoryToggleActiveRequest';
import AuthorizedSignatoryBadgesList from './components/AuthorizedSignatoryBadgesList';
import AuthorizedSignatoryBadgeApplications from './components/AuthorizedSignatoryBadgeApplications';

export default function AuthorizedSignatoryDetailsModal({ isOpen, onClose, signatoryUuid }) {
  const app = useContext(AppContext);
  const dateFormat = 'MM/DD/YYYY h:mm a';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangeCompanyModalOpen, setIsChangeCompanyModalOpen] = useState(false);
  const [signatory, setSignatory] = useState(null);
  const [isChangeTrainingModalOpen, setIsChangeTrainingModalOpen] = useState(false);
  const [shouldReloadDataGrid, setShouldReloadDataGrid] = useState(false);
  const [isViewPersonModalOpen, setIsViewPersonModalOpen] = useState(false);
  const [signatoryUser, setSignatoryUser] = useState(null);
  const [isReassignBadgesModalOpen, setIsReassignBadgesModalOpen] = useState(false);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [isBadgesExpanded, setIsBadgesExpanded] = useState(false);
  const [reloadBadgesTrigger, setReloadBadgesTrigger] = useState(null);
  const [isBadgeApplicationsExpanded, setIsBadgeApplicationsExpanded] = useState(false);

  const onDeactivateClicked = () => {
    if (signatory.activeBadgesAssigned) {
      return notify({
        message: 'Cannot deactivate a signatory with active badges assigned',
        severity: 'warning',
      });
    }

    app.api.toggleLoading(true);
    AuthorizedSignatoryToggleActiveRequest({ uuid: signatory.profileUuid })
      .then(({ success }) => {
        if (success) {
          setShouldReloadDataGrid(true);
          loadData();
        } else {
          handleError({ message: 'Unable to deactivate signatory' });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onActivateClicked = () => {
    app.api.toggleLoading(true);
    AuthorizedSignatoryToggleActiveRequest({ uuid: signatory.profileUuid })
      .then(({ success }) => {
        if (success) {
          setShouldReloadDataGrid(true);
          loadData();
        } else {
          handleError({ message: 'Unable to activate signatory' });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const saveComment = () => {
    app.api.toggleLoading(true);
    profileCommentApi
      .create({
        profileUUID: signatory.profileUuid,
        comment: newComment,
      })
      .then(({ success }) => {
        if (success) {
          onCommentModalClose();
        } else {
          handleError({ message: 'Unable to add comment' });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onCommentModalClose = () => {
    setIsCommentModalOpen(false);
    setNewComment('');
  };

  const onReassignBadgesModalClose = ({ shouldReload }) => {
    setIsReassignBadgesModalOpen(false);
    if (shouldReload) {
      setShouldReloadDataGrid(true);
      loadData(true);
    }
  };

  const onViewPersonModalClose = () => {
    setIsViewPersonModalOpen(false);
  };

  const onViewPerson = () => {
    setIsViewPersonModalOpen(true);
  };

  const onChangeTrainingModalClose = ({ shouldReload }) => {
    setIsChangeTrainingModalOpen(false);
    if (shouldReload) {
      setShouldReloadDataGrid(true);
      loadData();
    }
  };

  const onChangeCompanyModalClose = ({ shouldReload }) => {
    setIsChangeCompanyModalOpen(false);
    if (shouldReload) {
      setShouldReloadDataGrid(true);
      loadData();
    }
  };

  const onModalClose = () => {
    setReloadBadgesTrigger(null);
    setIsBadgesExpanded(false);
    setIsBadgeApplicationsExpanded(false);
    setIsCommentModalOpen(false);
    setNewComment('');
    setIsCommentsExpanded(false);
    setIsReassignBadgesModalOpen(false);
    setSignatoryUser(null);
    setIsViewPersonModalOpen(false);
    setShouldReloadDataGrid(false);
    setIsChangeTrainingModalOpen(false);
    setIsChangeCompanyModalOpen(false);
    setSignatory(null);
    setIsModalOpen(false);
    onClose({ shouldReload: shouldReloadDataGrid });
  };

  const loadData = shouldResetReloadBadgesTrigger => {
    app.api.toggleLoading(true);
    authorizedSignatoriesApi
      .getSingle({ profileUuid: signatoryUuid })
      .then(({ success, signatory }) => {
        if (success) {
          setSignatory(signatory);
          setSignatoryUser({ uuid: signatory.userUuid });
          if (shouldResetReloadBadgesTrigger) {
            setReloadBadgesTrigger(new Date().getTime());
          }
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const getModalTitle = () => {
    if (!signatory || signatory.isProfileActive) return 'Authorized Signatory Details';
    else
      return (
        <span>
          Authorized Signatory Details (<span className="text-danger">deactivated</span>)
        </span>
      );
  };

  useEffect(() => {
    if (!isOpen || !signatoryUuid) return;

    loadData();
    setIsModalOpen(isOpen);
  }, [isOpen, signatoryUuid]);

  if (!isOpen || !signatoryUuid) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title={getModalTitle()}
      isOpen={isModalOpen}
      onClose={onModalClose}
      closeLabel="Close"
    >
      <Row
        style={{
          border: '1px solid #c8ced3',
          backgroundColor: '#f0f0f0',
          borderRadius: 5,
          padding: 12,
          margin: '0 0 32px 0',
        }}
      >
        <Col>
          <Row>
            <Col>
              <ReadOnlyFormData
                label="Name"
                value={
                  <button type="button" className="btn btn-link" style={{ padding: 0 }} onClick={onViewPerson}>
                    {signatory?.fullName}
                  </button>
                }
              />
            </Col>
            <Col>
              <ReadOnlyFormData label="Company" value={signatory?.companyName || '-'} />
            </Col>
            <Col>
              <ReadOnlyFormData
                label="Training Expires"
                value={
                  signatory?.trainingExpires
                    ? moment.tz(signatory.trainingExpires, app.timezone).format('MMMM DD, YYYY')
                    : '-'
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {signatory?.totalSignatoryProfiles > 1 && (
                <div className="mt-3">
                  <i className="fa-solid fa-circle-info mr-2" style={{ color: colors.primary }}></i>
                  <i>
                    This individual is an authorized signatory for{' '}
                    <strong>{signatory?.totalSignatoryProfiles - 1}</strong> other{' '}
                    {signatory?.totalSignatoryProfiles === 2 ? 'company' : 'companies'}
                  </i>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          {!signatory?.activeBadgesAssigned && (
            <Alert color="warning">This signatory has no badges assigned to them</Alert>
          )}
        </Col>
      </Row>

      <div className="separator">ACTIONS</div>

      <Row style={{ marginBottom: 32 }}>
        <Col>
          <List>
            <ListItem
              button
              onClick={() => setIsChangeCompanyModalOpen(true)}
              disabled={!!signatory?.activeBadgesAssigned}
            >
              <ListItemIcon>
                <FontAwesomeIcon className="fa-solid fa-city" />
              </ListItemIcon>
              <ListItemText primary="Change Company" />
            </ListItem>
            <ListItem button onClick={() => setIsChangeTrainingModalOpen(true)}>
              <ListItemIcon>
                <FontAwesomeIcon className="fa-solid fa-clock-rotate-left" />
              </ListItemIcon>
              <ListItemText primary="Change Training Expiration" />
            </ListItem>
          </List>
        </Col>
        <Col>
          <List>
            <ListItem
              button
              onClick={() => setIsReassignBadgesModalOpen(true)}
              disabled={!signatory?.activeBadgesAssigned}
            >
              <ListItemIcon>
                <FontAwesomeIcon className="fa-solid fa-people-arrows" />
              </ListItemIcon>
              <ListItemText primary="Reassign All Badges" />
            </ListItem>
            {signatory?.isProfileActive ? (
              <ListItem button onClick={onDeactivateClicked} disabled={!!signatory?.activeBadgesAssigned}>
                <ListItemIcon>
                  <FontAwesomeIcon className="fa-solid fa-ban" color={colors.danger} />
                </ListItemIcon>
                <ListItemText primary="Deactivate Signatory" />
              </ListItem>
            ) : (
              <ListItem button onClick={onActivateClicked}>
                <ListItemIcon>
                  <FontAwesomeIcon className="fa-solid fa-check" color={colors.success} />
                </ListItemIcon>
                <ListItemText primary="Activate Signatory" />
              </ListItem>
            )}
          </List>
        </Col>
      </Row>

      <Collapsible
        id="assigned-badges"
        title="Assigned Badges"
        icon={<i className="fa-regular fa-id-card mr-2" />}
        expanded={isBadgesExpanded}
        onChange={() => setIsBadgesExpanded(!isBadgesExpanded)}
        style={{ backgroundColor: '#f0f0f0' }}
      >
        <AuthorizedSignatoryBadgesList signatory={signatory} reloadTrigger={reloadBadgesTrigger} />
      </Collapsible>
      <Collapsible
        id="badge-applications"
        title="Active Badge Applications"
        icon={<i className="fa-regular fa-file-lines mr-2" />}
        expanded={isBadgeApplicationsExpanded}
        onChange={() => setIsBadgeApplicationsExpanded(!isBadgeApplicationsExpanded)}
        style={{ backgroundColor: '#f0f0f0' }}
      >
        <AuthorizedSignatoryBadgeApplications signatory={signatory} isOpen={isBadgeApplicationsExpanded} />
      </Collapsible>
      <Collapsible
        id="comments-area"
        title="Comments / Activity"
        icon={<i className="fa fa-comments-o mr-2" />}
        expanded={isCommentsExpanded}
        onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
        style={{ backgroundColor: '#f0f0f0' }}
      >
        <div>
          <Button
            style={{ width: 120, marginBottom: 16 }}
            color="primary"
            onClick={() => setIsCommentModalOpen(true)}
            size="sm"
          >
            Add Comment
          </Button>
        </div>

        <Card>
          <CardBody>
            <div id="commentsContainer" className="comments-container" style={{ maxHeight: 264, minHeight: 264 }}>
              {(signatory?.activity || []).map(x => (
                <div key={x.uuid}>
                  <div>
                    <small className="text-muted">
                      <strong className="mr-2">{x.author}</strong>
                      {moment.tz(x.created, app.timezone).format(dateFormat)}
                    </small>
                  </div>
                  <p>
                    {x.content?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        <span>{line}</span>
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Collapsible>

      <PeopleListEditModal
        isOpen={isViewPersonModalOpen}
        onClose={onViewPersonModalClose}
        selectedPerson={signatoryUser}
      />

      <ChangeSignatoryCompanyModal
        isOpen={isChangeCompanyModalOpen}
        onClose={onChangeCompanyModalClose}
        signatory={signatory}
      />

      <ChangeSignatoryTrainingExpirationModal
        isOpen={isChangeTrainingModalOpen}
        onClose={onChangeTrainingModalClose}
        signatory={signatory}
      />

      <ReassignSignatoryBadgesModal
        isOpen={isReassignBadgesModalOpen}
        onClose={onReassignBadgesModalClose}
        signatory={signatory}
      />

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Add Comment"
        isOpen={isCommentModalOpen}
        onClose={onCommentModalClose}
        closeLabel="Cancel"
        onSave={saveComment}
        saveLabel="Add Comment"
        saveDisabled={!newComment}
      >
        <InputWithCharacterLimit style={{ height: 60 }} limit={191} value={newComment} onChange={setNewComment} />
      </AirBadgeModal>
    </AirBadgeModal>
  );
}
