import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, FormGroup, Label } from 'reactstrap';
import ReactTable from 'react-table';
import { AppContext } from '../../../../../App';
import trainingCourseApi from '../../../../../ajax/Training/trainingCourseApi';
import { getLMSLabel, handleError } from '../../../../../utils';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

function LabelWithTitle({ label }) {
  return <span title={label}>{label}</span>;
}

const columns = (onRemoveCourseClicked, lmsList) => [
  {
    id: 'lms',
    Header: 'LMS',
    accessor: ({ system }) => <LabelWithTitle label={getLMSLabel(system)} />,
    show: lmsList.length > 1,
    sortable: false,
    filterable: false,
    width: lmsList.find(({ value }) => value === 'airbadge_lms') ? 135 : 115,
  },
  {
    id: 'code',
    Header: 'Course Code',
    accessor: ({ code }) => <LabelWithTitle label={code} />,
    show: false,
    sortable: false,
    filterable: false,
  },
  {
    id: 'name',
    Header: 'Course Name',
    accessor: ({ name }) => <LabelWithTitle label={name} />,
    show: true,
    sortable: false,
    filterable: false,
  },
  {
    id: 'actions',
    Header: 'Actions',
    accessor: course => {
      return (
        <Button
          title="Remove Course Assignment"
          aria-label="Remove Course Assignment"
          size="sm"
          color="danger"
          onClick={() => onRemoveCourseClicked(course)}
        >
          Remove Assignment
        </Button>
      );
    },
    show: true,
    sortable: false,
    filterable: false,
    width: 200,
  },
];

export default function PersonAssociatedCoursesList({ isStillLoadingData, person, trainingData, onRefreshData }) {
  const app = useContext(AppContext);
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [isAssignCourseModalOpen, setIsAssignCourseModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [lmsList, setLmsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onAssignCourseModalClose = () => {
    setIsLoading(false);
    setIsAssignCourseModalOpen(false);
    setSelectedCourse(null);
  };

  const onRemoveCourseClicked = course => {
    app.api.confirmMessage(
      'Confirmation',
      <div>
        Are you sure you want to un-assign the <strong>{course.name}</strong> course from {person.first_name}{' '}
        {person.last_name}?
      </div>,
      () => {
        app.api.toggleLoading(true);
        trainingCourseApi
          .removeCourseForUser({
            userUUID: person.uuid,
            courseCode: course.code,
            courseName: course.name,
            system: course.system,
          })
          .then(({ success, message }) => {
            if (success) {
              onRefreshData();
            } else {
              app.api.toggleLoading(false);
              handleError({ message: message || 'Unable to remove course assignment' });
            }
          })
          .catch(error => {
            app.api.toggleLoading(false);
            handleError({ error, message: 'Unable to remove course assignment' });
          });
      },
      null,
      true,
      'Remove Course Assignment'
    );
  };

  const assignCourse = async () => {
    app.api.toggleLoading(true);
    trainingCourseApi
      .assignCourse({
        userUUID: person.uuid,
        courseCode: selectedCourse.value,
        courseName: selectedCourse.label,
        system: selectedCourse.system,
      })
      .then(({ success, message }) => {
        if (success) {
          onAssignCourseModalClose();
          onRefreshData();
        } else {
          app.api.toggleLoading(false);
          handleError({ message: message || 'Unable to assign course' });
        }
      })
      .catch(error => {
        app.api.toggleLoading(false);
        handleError({ error, message: 'Unable to assign course' });
      });
  };

  useEffect(() => {
    if (person && trainingData) {
      const lmsList = [];
      let formattedAssignedCoursesList = [];
      let formattedCoursesList = [];
      trainingData.forEach(({ system, success, ...rest }) => {
        const lmsLabel = getLMSLabel(system);
        lmsList.push({ label: lmsLabel, value: system });
        if (success && rest) {
          if (rest?.availableCourses === undefined) {
            // This LMS doesn't support groups (ex: Digicast)
            return;
          }
          const { availableCourses, assignedCourses } = rest;
          formattedAssignedCoursesList = formattedAssignedCoursesList.concat(
            assignedCourses.map(c => ({ ...c, system }))
          );
          if (assignedCourses.length === 0) {
            formattedCoursesList = formattedCoursesList.concat(
              availableCourses.map(c => ({
                label: c.name,
                value: c.code,
                lms: lmsLabel,
                system,
              }))
            );
          } else {
            const assignedCourseCodes = assignedCourses.map(x => x.code);
            formattedCoursesList = formattedCoursesList.concat(
              availableCourses
                .filter(x => !assignedCourseCodes.includes(x.code))
                .map(c => ({ label: c.name, value: c.code, lms: lmsLabel, system }))
            );
          }
        }
      });
      if (lmsList.length > 1) {
        formattedCoursesList.forEach(c => (c.label = `${c.lms}: ${c.label}`));
      }
      setAssignedCourses(formattedAssignedCoursesList);
      setCourseList(formattedCoursesList);
      setLmsList(lmsList);
    }
    setIsLoading(false);
  }, [person, trainingData]);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  if (!person || !trainingData) return false;

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: 16 }}
          color="primary"
          onClick={() => setIsAssignCourseModalOpen(true)}
          disabled={isStillLoadingData}
        >
          <i className="fa fa-plus mr-2" />
          Assign Course
        </Button>
      </div>
      <Card>
        <CardBody>
          <div className="table-responsive" style={{ maxHeight: 244, minHeight: 200 }}>
            <ReactTable
              manual
              noDataText={isLoading ? 'Loading courses ...' : 'No courses assigned'}
              className="border-0 -striped"
              data={assignedCourses}
              columns={columns(onRemoveCourseClicked, lmsList)}
              pages={1}
              minRows={0}
              defaultPageSize={100}
              showPagination={false}
            />
          </div>
        </CardBody>
      </Card>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Assign Group"
        isOpen={isAssignCourseModalOpen}
        onClose={onAssignCourseModalClose}
        closeLabel="Cancel"
        onSave={assignCourse}
        saveLabel="Assign Course"
        saveDisabled={!selectedCourse}
      >
        <FormGroup>
          <Label>Course</Label>
          <Select
            classNamePrefix="airbadge"
            isSearchable={true}
            className="form-select"
            placeholder="Select training course"
            options={courseList}
            onChange={option => setSelectedCourse(option)}
          />
        </FormGroup>
      </AirBadgeModal>
    </>
  );
}
