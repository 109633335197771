import React from 'react';
import uniqBy from 'lodash/uniqBy';

export default function NativeSelect({ id, isMulti, includeBlank, options = [], value, onChange, className, ...rest }) {
  if (!id) throw new Error('ID attribute is required for NativeSelect component');
  if (!onChange) throw new Error('onChange attribute is required for NativeSelect component');

  const getClassName = () => {
    let classes = 'airbadge-select';
    if (className) classes = `${classes} ${className}`;
    if (rest.disabled) classes = `${classes} custom-disabled`;
    return classes;
  };

  return (
    <>
      {isMulti ? (
        <select
          {...rest}
          id={id}
          multiple
          className={getClassName()}
          value={value ? value.map(v => v.value) : []}
          onChange={({ target }) => {
            const selected = [...target.options].filter(o => o.selected);
            const values = selected ? selected.map(x => x.value) : [];
            let selectedOptions = options.filter(o => values.indexOf(o.value) > -1) || [];
            selectedOptions = uniqBy(selectedOptions, 'value');
            onChange(selectedOptions);
          }}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <select
          {...rest}
          id={id}
          className={getClassName()}
          value={value ? value.value : ''}
          onChange={({ target }) => onChange(options.find(o => o.value === target.value))}
        >
          {includeBlank && <option value=""></option>}
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </>
  );
}
