import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Row, Col, FormGroup } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import { BlockFormLabel } from '../../../components/styledComponents';
import { trim } from './badgerUtils';

export default function SocialSecurityNumberQuestion({ isHidden, value, onBack, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState({ social: value || '', isSkipped: false });

  const isNextHidden = () => {
    if (state.isSkipped) return false;
    const curated = (state.social || '').replaceAll('_', '').replaceAll('-', '');
    return !curated || curated.length < 9;
  };

  const cleanupData = () => {
    const newState = { ...state };
    newState.social = trim(newState.social);
    setState(newState);
    return newState.social;
  };

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col xs="12" sm="6">
          <BlockFormLabel>
            <Typography variant="h6">{t('question10')}</Typography>
          </BlockFormLabel>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="2">
          <FormGroup>
            <InputMask
              mask="999-99-9999"
              className={state.isSkipped ? 'form-control custom-disabled' : 'form-control'}
              value={state.social || ''}
              onChange={({ target }) => setState({ ...state, social: target.value })}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormLabel style={{ marginTop: 16 }}>
            <Typography variant="subtitle2">{t('question10_skip')}</Typography>
          </FormLabel>
          <FormGroup>
            <MuiFormGroup row>
              <MuiFormControlLabel
                control={<Switch color="primary" />}
                label={t('question10_skip_confirm')}
                checked={state.isSkipped}
                onChange={({ target }) => setState({ social: '', isSkipped: target.checked })}
              />
            </MuiFormGroup>
          </FormGroup>
        </Col>
      </Row>

      <BackButton onClick={onBack} />
      <NextButton isDisabled={isNextHidden()} onClick={() => onNext(cleanupData())} />
    </>
  );
}
