import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { AppContext, colors } from '../../../App';
import badgeOfficeApi from '../../../ajax/BadgeOffice/badgeOfficeApi';

export default function Fail({ isHidden, onRestartStep }) {
  const app = useContext(AppContext);
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });

  const getLine2Content = () => {
    const text = t('fail.statement-line2');
    const start = text.split('[')[0];
    const end = text.split(']').splice(1);
    const action = text.replace(`${start}[`, '').replace(`]${end}`, '');
    return (
      <>
        {start}
        <Link
          onClick={event => {
            event.preventDefault();
            onRestartStep();
          }}
          style={{ textDecoration: 'underline', color: colors.primary }}
        >
          {action}
        </Link>
        {end}
      </>
    );
  };

  const getLine3Content = () => {
    const text = t('fail.statement-line3');
    const start = text.split('[')[0];
    const end = text.split(']').splice(1);
    const action = text.replace(`${start}[`, '').replace(`]${end}`, '');
    return (
      <>
        {start}
        <Link
          onClick={event => {
            event.preventDefault();
            badgeOfficeApi
              .getContactInfo({ user: app.user, badgeOfficeId: 1 })
              .then(({ success, contact_info }) => {
                if (!success) return;
                app.api.confirmMessage(t('actions.contact-badge-office'), <pre>{contact_info}</pre>, () => true, false);
              })
              .catch(e => console.error(e));
          }}
          color="textPrimary"
          style={{ textDecoration: 'underline', color: colors.primary }}
        >
          {action}
        </Link>
        {end}
      </>
    );
  };

  if (isHidden) return null;

  return (
    <>
      <Typography variant="h5">{t('fail.statement-line1')}</Typography>
      <Typography variant="h5" style={{ marginTop: 16 }}>
        {getLine2Content()}
      </Typography>
      <Typography variant="h5" style={{ marginTop: 16 }}>
        {getLine3Content()}
      </Typography>
    </>
  );
}
