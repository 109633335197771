import React, { useCallback, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CameraOutlinedIcon from '@material-ui/icons/CameraOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppContext, colors } from '../../../../../../../App';
import { ActionPanelContext } from '../../BadgeApplicationActionPanel';
import request from '../../../../../../../ajax/Request';
import DocSpringDocument from '../../../../../DocSpringDocument/DocSpringDocument';
import { handleError } from '../../../../../../../utils';
import FontAwesomeIcon from '../../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import SignatoryPreVerifyModal from './SignatoryPreVerifyModal';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { hasFeature } from '../../../../../../../shared/Feature';
import BadgeApplicationPaymentForm from '../../../../../Home/BadgeApplicationsList/components/BadgeApplicationPaymentForm';

function SignApplicationDocumentAction() {
  const [transactionUuid, setTransactionUuid] = useState(null);
  const [isAcknowledgementModalOpen, setIsAcknowledgementModalOpen] = useState(false);
  const [isSignatoryPreVerifyModalOpen, setIsSignatoryPreVerifyModalOpen] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [shouldBePaidBySignatory, setShouldBePaidBySignatory] = useState(false);
  const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
  const [paymentUuid, setPaymentUuid] = useState(null);

  const app = useContext(AppContext);
  const { badgeApplication, onActionDone } = useContext(ActionPanelContext);
  const { applicationDocument, authorizationDocument } = badgeApplication.config;
  const isSignatory = app?.user?.activeProfile?.role?.name === 'authorized-signatory';

  const onSignatoryPreVerifyModalClose = ({ shouldProceedToSignDocument, shouldBePaidBySignatory = false }) => {
    setIsSignatoryPreVerifyModalOpen(false);
    setShouldBePaidBySignatory(shouldBePaidBySignatory);
    if (shouldProceedToSignDocument) {
      const { transactionUuid } = applicationDocument.documentTransactions.signatory;
      setTransactionUuid(transactionUuid);
    } else {
      onActionDone({ shouldReload: true });
    }
  };

  const isHidden = useCallback(() => {
    const { isEnabled, isComplete, documentTransactions } = applicationDocument;
    if (!isEnabled || isComplete) {
      return true;
    }
    if (!isSignatory) {
      return true;
    }
    if (!documentTransactions.signatory) {
      // This document doesn't require a signatory signature
      return true;
    }
    if (
      documentTransactions.applicant &&
      documentTransactions.applicant.isComplete &&
      !documentTransactions.signatory.isComplete
    ) {
      // Applicant is done and now it is the Signatory's turn
      return false;
    }
    return true;
  }, [applicationDocument, isSignatory]);

  const canRunBadger = useCallback(() => {
    const { isEnabled, isComplete, documentTransactions } = applicationDocument;
    if (!isEnabled || isComplete) {
      return false;
    }
    if (authorizationDocument.isEnabled && !authorizationDocument.isComplete) {
      // Authorization document needs to be done first
      return false;
    }
    if (documentTransactions.applicant && documentTransactions.applicant.isComplete) {
      // Can't run BADGE/R if the applicant (or and ASC) has already done it
      return false;
    }
    if (app.user.uuid === badgeApplication.applicant.uuid) {
      // Current logged in User is also the applicant for this badge application
      return true;
    }
    if (!isSignatory && badgeApplication.badgerPhase !== 'sign') {
      // Current logged in User is an Airport Security Coordinator or TA
      // and is allowed to complete BADGE/R on behalf of the applicant
      //     BUT only if the badge holder hasn't made it to the document yet
      return true;
    }
    return false;
  }, [applicationDocument, isSignatory]);

  const getBadgerButtonText = useCallback(() => {
    let buttonText;
    switch (badgeApplication.badgerPhase) {
      case 'sign':
        buttonText = 'Sign Application';
        break;
      case 'prepare':
        buttonText = 'Prepare and Sign Application';
        break;
      case 'scan':
        buttonText = 'Scan Documents and Complete Application';
        break;
      default:
        buttonText = 'Begin Application';
    }
    return buttonText;
  }, [badgeApplication]);

  const onCancelled = () => {
    setTransactionUuid(null);
  };

  const onSubmitSuccess = () => {
    onActionDone({ shouldReload: true });

    if (
      hasFeature('payments', app?.airport?.config?.features || []) &&
      !badgeApplication.isPaid &&
      shouldBePaidBySignatory
    ) {
      setPaymentUuid(badgeApplication.payments.find(p => p.status === 'unpaid')?.uuid);
      setIsPaymentFormOpen(true);
    }
  };

  const onClick = () => {
    if (badgeApplication.isSignatoryPreVerifyEnabled) {
      setIsAcknowledgementModalOpen(true);
    } else {
      const { transactionUuid } = applicationDocument.documentTransactions.signatory;
      setTransactionUuid(transactionUuid);
    }
  };

  const launchBadger = () => {
    request(app.user, 'GET', `badger/key/${badgeApplication.uuid}`)
      .then(({ success, key }) => {
        if (success) window.open(`${window.location.origin}/#badger?key=${key}`);
      })
      .catch(error => handleError({ error, message: 'Unable to generate secure URL' }));
  };

  return (
    <>
      <ListItem button onClick={onClick} hidden={isHidden()}>
        <ListItemIcon>
          <FontAwesomeIcon className="fa-solid fa-file-signature" color={colors.primary} />
        </ListItemIcon>
        <ListItemText primary="Sign Application Document" />
      </ListItem>

      {canRunBadger() && (
        <ListItem button onClick={launchBadger}>
          <ListItemIcon>
            <CameraOutlinedIcon style={{ color: colors.primary }} />
          </ListItemIcon>
          <ListItemText primary={getBadgerButtonText()} />
        </ListItem>
      )}

      <DocSpringDocument
        transactionUuid={transactionUuid}
        onCancelled={onCancelled}
        onSubmitSuccess={onSubmitSuccess}
      />

      <SignatoryPreVerifyModal
        isOpen={isSignatoryPreVerifyModalOpen}
        onClose={onSignatoryPreVerifyModalClose}
        badgeApplication={badgeApplication}
      />

      <AirBadgeModal
        size={MODAL_SIZE_LARGE}
        title="PII Acknowledgement"
        isOpen={isAcknowledgementModalOpen}
        onClose={() => {
          setIsAcknowledgementModalOpen(false);
          setIsAcknowledged(false);
        }}
        onSave={() => {
          setIsAcknowledgementModalOpen(false);
          setIsSignatoryPreVerifyModalOpen(true);
          setIsAcknowledged(false);
        }}
        saveDisabled={!isAcknowledged}
        saveLabel="Proceed To Document Validation"
      >
        <Alert color="warning">
          <p>
            <strong>Warning: You are about to access personally identifiable information (PII).</strong>
          </p>
          By proceeding, you expressly consent to manage this information with the utmost care and strict
          confidentiality, ensuring its use is strictly for the intended purpose. Furthermore, you certify your
          adherence to all prevailing laws, regulations, and guidelines pertinent to information protection, privacy,
          and safeguarding, as applicable within your jurisdiction, the jurisdiction of the applicant, and that of the
          airport. Such adherence includes compliance with any and all operational policies and regulatory mandates
          specific to the airport's governing body. Any unauthorized access or misuse of this information shall incur
          serious disciplinary measures, up to and including the revocation of access rights and the pursuit of legal
          remedies. For any inquiries or need for clarification, kindly reach out to the credentialing office prior to
          engagement.
        </Alert>
        <div className="text-center mb-4">
          <FormControlLabel
            label="I Acknowledge and Agree to the Above Statement"
            style={{ marginBottom: 0 }}
            control={
              <Checkbox
                checked={isAcknowledged}
                onChange={event => setIsAcknowledged(event?.target?.checked)}
                name="acknowledgement"
                color="primary"
              />
            }
          />
        </div>
      </AirBadgeModal>

      <BadgeApplicationPaymentForm
        isOpen={isPaymentFormOpen}
        onClose={() => {
          setIsPaymentFormOpen(false);
          onActionDone({ shouldReload: true });
        }}
        paymentUuid={paymentUuid}
      />
    </>
  );
}

export default withRouter(SignApplicationDocumentAction);
