import React, { useContext, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import ReactTableTotalCount from '../../Common/ReactTableTotalCount';
import BadgeApplicationDataTableRequest from '../../../../ajax/BadgeApplication/BadgeApplicationDataTableRequest';
import { handleError } from '../../../../utils';
import TableHeaderWithTitle from '../../../../shared/Tables/TableHeaderWithTitle';
import TableCellWithTitle from '../../../../shared/Tables/TableCellWithTitle';
import { AppContext } from '../../../../App';
import BadgeApplicationTablePhaseCell from '../../BadgeApplications/BadgeApplicationsList/components/BadgeApplicationTablePhaseCell';
import BadgeApplicationDetailsModal from '../../BadgeApplications/BadgeApplicationDetailsModal/BadgeApplicationDetailsModal';

let requestTimeout = null;
let abortController = null;

export default function AuthorizedSignatoryBadgeApplications({ signatory, isOpen }) {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [badgeApplications, setBadgeApplications] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(['authenticated-user', 50, 0, {}, {}]);
  const [selectedBadgeApplication, setSelectedBadgeApplication] = useState(null);
  const [isBadgeApplicationModalOpen, setIsBadgeApplicationModalOpen] = useState(false);

  const viewBadgeApplication = badgeApplication => {
    setSelectedBadgeApplication(badgeApplication);
    setIsBadgeApplicationModalOpen(true);
  };

  const columns = [
    {
      id: 'expires',
      Header: <TableHeaderWithTitle value="Expires" />,
      exportHeader: 'Expires',
      accessor: row =>
        row.expires ? (
          <button className="btn btn-link p-0 text-left" onClick={() => viewBadgeApplication(row)}>
            {moment.tz(row.expires, app.timezone).format('MM/DD/YYYY')}
          </button>
        ) : (
          ''
        ),
      filterable: false,
      show: true,
      minWidth: 125,
    },
    {
      id: 'name',
      Header: <TableHeaderWithTitle value="Name" />,
      exportHeader: 'Name',
      exportData: row => row.badgeHolderUserName,
      accessor: row => <TableCellWithTitle value={row.badgeHolderUserName} />,
      filterable: false,
      show: true,
      minWidth: 100,
    },
    {
      id: 'type',
      Header: <TableHeaderWithTitle value="Badge" />,
      exportHeader: 'Badge',
      exportData: row => row.badgeDisplayName,
      accessor: row => <TableCellWithTitle value={row.badgeDisplayName} />,
      filterable: false,
      show: true,
      minWidth: 100,
    },
    {
      id: 'step',
      Header: <TableHeaderWithTitle value="Phase" />,
      exportHeader: 'Phase',
      exportData: row => row.currentPhase,
      accessor: row => <BadgeApplicationTablePhaseCell badgeApplication={row} user={app?.user} />,
      filterable: false,
      show: true,
      minWidth: 130,
    },
    {
      id: 'application_type',
      Header: <TableHeaderWithTitle value="Type" />,
      exportHeader: 'Type',
      accessor: row => {
        const { application_type } = row;
        switch (application_type.toLocaleLowerCase()) {
          case 'new':
            return 'New';
          case 'renewal':
            return 'Renewal';
          default:
            return 'Unknown';
        }
      },
      filterable: false,
      show: true,
      minWidth: 125,
    },
  ];

  const onFetchData = ({ pageSize, page, sorted, filtered }) => {
    const newParams = ['authenticated-user', pageSize, page, sorted, { filters: filtered }];
    setParams(newParams);
    requestData(newParams);
  };

  const requestData = requestParams => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setIsLoading(true);
    setBadgeApplications([]);
    setPages(0);
    setTotal(0);

    requestTimeout = setTimeout(() => {
      setIsLoading(true);
      abortController = new AbortController();
      setIsLoading(true);
      BadgeApplicationDataTableRequest(...requestParams, '', abortController.signal)
        .then(({ data, pages, total }) => {
          setBadgeApplications(data);
          setPages(pages);
          setTotal(total);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  const onBadgeApplicationModalClose = () => {
    setIsBadgeApplicationModalOpen(false);
    setSelectedBadgeApplication(null);
  };

  if (!isOpen) return null;

  return (
    <Card>
      <CardBody>
        <div className="table-responsive">
          <ReactTable
            manual
            noDataText="No active badge applications"
            defaultSorted={[{ id: 'expires', desc: false }]}
            defaultFiltered={[
              { id: 'status', value: 'in_progress' },
              {
                id: 'authorizedSignatoryProfileUuid',
                value: signatory.profileUuid,
              },
            ]}
            className="border-0 -striped"
            loading={isLoading}
            data={badgeApplications}
            columns={columns}
            pages={pages}
            minRows={0}
            defaultPageSize={50}
            onFetchData={onFetchData}
            showPagination={pages > 1}
          />

          {!!total && <ReactTableTotalCount params={params} total={total} />}
        </div>

        <BadgeApplicationDetailsModal
          isOpen={isBadgeApplicationModalOpen}
          onClose={onBadgeApplicationModalClose}
          selectedBadgeApplication={selectedBadgeApplication}
        />
      </CardBody>
    </Card>
  );
}
