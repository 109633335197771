import React, { useCallback, useContext, useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import BadgeApplicationAppointmentCreateModal from '../../../../Home/BadgeApplicationsList/components/BadgeApplicationAppointmentCreateModal';
import AppointmentsListEditModal from '../../../../Appointments/AppointmentsList/AppointmentsListEditModal';

export default function ScheduleTrainingAction() {
  const defaultActionLabel = 'Schedule Training Appointment';
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone } = useContext(ActionPanelContext);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [actionButtonLabel, setActionButtonLabel] = useState(defaultActionLabel);
  const [isRescheduleAppointmentModalOpen, setIsRescheduleAppointmentModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [canBeRescheduled, setCanBeRescheduled] = useState(false);
  const appointmentTypeUuid = badgeApplication?.config?.trainingAppointment?.appointmentTypeUuid || null;

  const checkAppointments = () => {
    const scheduledAppointment = (badgeApplication.appointments || []).find(
      a => a.appointmentType.uuid === appointmentTypeUuid
    );
    setSelectedAppointment(scheduledAppointment);
    setCanBeRescheduled(!!scheduledAppointment);
    if (scheduledAppointment) {
      setActionButtonLabel('Reschedule Training Appointment');
    } else {
      setActionButtonLabel(defaultActionLabel);
    }
  };

  const isHidden = useCallback(() => {
    const { active: isBadgeApplicationActive, config } = badgeApplication;
    const { backgroundCheck, adjudicateCHRC, approveBackgroundCheckResults, trainingAppointment, training } = config;
    if (!!isBadgeApplicationActive === false) return true;
    if (training.isEnabled && training.isComplete) return true;
    if (!trainingAppointment?.isEnabled) return true;
    if (trainingAppointment.appointmentUuid && !canBeRescheduled) return true;
    return !!(
      (backgroundCheck.isEnabled && !backgroundCheck.isComplete) ||
      (adjudicateCHRC.isEnabled && !adjudicateCHRC.isComplete) ||
      (approveBackgroundCheckResults.isEnabled && !approveBackgroundCheckResults.isComplete)
    );
  }, [badgeApplication, canBeRescheduled]);

  const onClick = () => {
    if (canBeRescheduled) {
      setIsRescheduleAppointmentModalOpen(true);
    } else {
      setIsAppointmentModalOpen(true);
    }
  };

  const onSaveAppointment = () => {
    setIsAppointmentModalOpen(false);
    onActionDone({ shouldReload: true });
  };

  const onCloseAppointmentModal = () => {
    setIsAppointmentModalOpen(false);
  };

  const onCloseRescheduleAppointmentModal = () => {
    setIsRescheduleAppointmentModalOpen(false);
  };

  const onRescheduleAppointment = () => {
    setIsRescheduleAppointmentModalOpen(false);
    onActionDone({ shouldReload: true });
  };

  useEffect(() => {
    setCanBeRescheduled(false);
    setSelectedAppointment(null);
    setIsRescheduleAppointmentModalOpen(false);
    setActionButtonLabel(defaultActionLabel);
    checkAppointments();
  }, [badgeApplication]);

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={onClick}>
        <ListItemIcon>
          {canBeRescheduled ? (
            <FontAwesomeIcon className="fa-regular fa-calendar-check" />
          ) : (
            <FontAwesomeIcon className="fa-regular fa-calendar-plus" />
          )}
        </ListItemIcon>
        <ListItemText primary={actionButtonLabel} />
      </ListItem>

      <BadgeApplicationAppointmentCreateModal
        isOpen={isAppointmentModalOpen}
        badgeApplication={badgeApplication}
        user={app.user}
        attendee={badgeApplication.applicant}
        api={app.api}
        timeZone={app.timezone}
        onSave={onSaveAppointment}
        onClose={onCloseAppointmentModal}
        defaultTypeUuid={appointmentTypeUuid}
      />

      {canBeRescheduled && isRescheduleAppointmentModalOpen && (
        <AppointmentsListEditModal
          isOpen={isRescheduleAppointmentModalOpen}
          editAppointment={selectedAppointment}
          onClose={onCloseRescheduleAppointmentModal}
          onSave={onRescheduleAppointment}
          user={app.user}
          api={app.api}
          timeZone={app.timezone}
          mode="badge-holder-reschedule"
        />
      )}
    </>
  );
}
