import request, { upload } from '../Request';

export default {
  badgeTypes: getBadgeTypes,
  activeBadgeTypes: getActiveBadgeTypes,
  createBadgeType,
  updateBadgeType,
  getImages,
  replaceImage,
  get: getSingleBadgeType,
};

function getBadgeTypes() {
  return request('authenticated-user', 'GET', `all-badge-types`);
}

function getActiveBadgeTypes() {
  return request('authenticated-user', 'GET', `all-active-badge-types`);
}

function createBadgeType({ type }) {
  return request('authenticated-user', 'POST', `badge-types`, type);
}

function updateBadgeType({ type }) {
  return request('authenticated-user', 'PUT', `badge-types/${type.uuid}`, type);
}

function getImages({ type, signal }) {
  return request('authenticated-user', 'GET', `badge-types/${type.uuid}/images`, null, signal);
}

function replaceImage({ type, file }) {
  const data = {};
  data.badgeTypeUuid = type.uuid;
  data.size = file.size;
  data.extension = file.name.split('.').pop();
  data.mimeType = file.type;
  return upload('authenticated-user', 'POST', `badge-types/${type.uuid}/images`, file, data);
}

function getSingleBadgeType({ badgeTypeUuid, signal }) {
  return request('authenticated-user', 'GET', `badge-types/${badgeTypeUuid}`, null, signal);
}
