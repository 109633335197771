import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { colors } from '../../../../../App';
import KioskCodeModal from '../../../BadgeOffice/pages/KioskSettings/KioskCodeModal';

export default function GenerateKioskCodeForUserModal({ user }) {
  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);

  const onCodeModalClose = () => {
    setIsCodeModalOpen(false);
    setIsActionDisabled(false);
  };

  const onActionClicked = () => {
    setIsActionDisabled(true);
    setIsCodeModalOpen(true);
  };

  return (
    <ListItem button onClick={onActionClicked} disabled={isActionDisabled}>
      <ListItemIcon>
        <FontAwesomeIcon
          className="fa-solid fa-computer"
          color={colors.primary}
          style={{ fontSize: '1.4rem', paddingLeft: 3 }}
        />
      </ListItemIcon>
      <ListItemText primary="Generate Kiosk Code" />

      <KioskCodeModal user={user} isOpen={isCodeModalOpen} onClose={onCodeModalClose} />
    </ListItem>
  );
}
