import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppContext, colors } from '../../../../App';
import request, { upload } from '../../../../ajax/Request';
import { BlockFormLabel } from '../../components/styledComponents';
import countries from '../DataConfirmation/questions/countries';
import usaStates from '../DataConfirmation/questions/states';
import canadaProvinces from '../DataConfirmation/questions/states_canada';
import ScanEntry from './components/ScanEntry';
import BackButton from '../../components/BackButton';
import ScanList from './components/ScanList';
import { BadgerContext } from '../../Badger';
import { convertPngToJpg, isImageFileTooBig, resizeImage } from '../../../../imageUtils';

export default function Scanning({ info, isHidden, documents, onNext, startOverAction }) {
  const app = useContext(AppContext);
  const badger = useContext(BadgerContext);
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });
  const [state, setState] = useState({ scans: [] });
  const [isReady, setIsReady] = useState(false);
  const [DWObject, setDWObject] = useState(null);
  const [isRescanDone, setIsRescanDone] = useState(false);
  const [currentScan, setCurrentScan] = useState(null);
  const [showMobileDone, setShowMobileDone] = useState(false);
  const [isOverlayShown, setIsOverlayShown] = useState(false);
  const states = [...usaStates, ...canadaProvinces];

  const onOverlayToggled = isShown => {
    setIsOverlayShown(isShown);
  };

  const beginScanning = () => {
    setCurrentScan(state.scans[0]);
  };

  const proceedToNextScan = args => {
    if (args && args.isRescanDone) {
      setIsRescanDone(true);
      return;
    }

    const next = state.scans[currentScan.index + 1];
    if (next) {
      setCurrentScan(next);
    } else if (badger.shouldStopAfterScans) {
      setShowMobileDone(true);
    } else {
      onNext({ scans: state.scans });
    }
  };

  const goBackToPreviousScan = () => {
    setCurrentScan(state.scans[currentScan.index - 1]);
  };

  const processScans = scans => {
    const isRescanMode = info.step === 'rescan';
    const filteredList = [...scans].filter(s => !(isRescanMode && s.status !== 'rescan'));
    setState({
      scans: filteredList
        .map(s => ({ ...s, code: s.code.toLocaleLowerCase() }))
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((s, i) => {
          if (s.file.country) {
            const match = countries.find(c => c.value === s.file.country);
            if (match) s.file.country = match;
          }
          if (s.file.stateAbbreviation) {
            const match = states.find(x => x.value === s.file.stateAbbreviation);
            if (match) s.file.stateAbbreviation = match;
          }
          return { ...s, index: i };
        }),
    });
    setIsReady(true);
  };

  const load = documentsList => {
    request(null, 'POST', `badger/scan/${info.badgeApplicationUuid}`, {
      key: info.key,
      documents: documentsList,
    })
      .then(({ success, ...rest }) => {
        if (success) processScans(rest.scans);
      })
      .catch(error => console.error(error));
  };

  const doUpload = async ({ scan, file }) => {
    try {
      app.api.toggleLoading(true);

      file = await convertPngToJpg(file);
      if (isImageFileTooBig(file)) {
        file = await resizeImage(file);
      }

      const payload = {
        key: info.key,
        size: file.size,
        code: scan.code,
      };
      if (scan.file && scan.file.uuid) {
        payload.existingFileUuid = scan.file.uuid;
      }
      const response = await upload(null, 'POST', `badger/scan/${info.badgeApplicationUuid}/upload`, file, payload);
      if (!response.success) {
        return app.api.displayMessage('Unable to upload document', 'warning');
      }
      const updatedScan = response.scan;
      const stateAbbreviation = updatedScan.file ? updatedScan.file.state : null;
      const matchingState = states.find(x => x.value === stateAbbreviation);
      if (matchingState) updatedScan.file.stateAbbreviation = matchingState;

      const match = state.scans.find(x => x.code === updatedScan.code);
      match.uuid = updatedScan.uuid;
      match.status = updatedScan.status;
      match.file = updatedScan.file;
      match.fileContents = file;

      setState({ ...state, scans: [...state.scans] });
      setCurrentScan({ ...match });
    } catch (e) {
      console.error(e);
      app.api.displayMessage('Unable to upload document', 'warning');
    } finally {
      app.api.toggleLoading(false);
      window.AirBadgeScan = null;
    }
  };

  const uploadScan = result => {
    const file = new File([result], `ScannedImage.jpg`, { type: result.type });
    const scan = window.AirBadgeScan || {};
    return doUpload({ scan, file });
  };

  // Opens the webcam capture UI
  const scanImage = scan => {
    // We have to save this to "localstorage" because the upload event
    //  doesn't have access to this component's state
    window.AirBadgeScan = scan;
    const config = {
      scannerViewer: {
        enableBorderDetection: true,
        maxDocuments: 1,
        resolution: { visibility: true },
        autoScan: { visibility: true },
        autoDetect: { visibility: true, enableAutoDetect: true, acceptedPolygonConfidence: 80 },
        continuousScan: { visibility: false, enableContinuousScan: false },
        switchCamera: { visibility: false },
        loadLocalFile: { visibility: false },
      },
      filterViewer: { exitDocumentScanAfterSave: true, filter: { visibility: false } },
      documentEditorSettings: {
        filter: { visibility: false },
        insert: { visibility: false },
        remove: { visibility: false },
      },
    };
    DWObject.Addon.Camera.scanDocument(config);
  };

  const uploadImage = (scan, file) => {
    if (scan && file) doUpload({ scan, file });
  };

  const onSkipScan = (scan, isSkipped) => {
    const match = state.scans.find(x => x.code === scan.code);
    const data = { key: info.key, scanUuid: match.uuid };

    if (isSkipped && match.file.uuid) {
      data.action = 'skipped';
      data.fileUuid = match.file.uuid;
    } else {
      data.action = isSkipped ? 'skipped' : 'pending';
    }

    app.api.toggleLoading(true);
    request(null, 'PUT', `badger/scan/${info.badgeApplicationUuid}`, data)
      .then(() => {
        match.status = data.action;
        const newScans = [...state.scans];
        setState({ ...state, scans: newScans });
        setCurrentScan({ ...match });
      })
      .catch(error => console.error(error))
      .finally(() => app.api.toggleLoading(false));
  };

  const onScanUpdated = updatedScan => {
    const updatedList = state.scans.map(s => {
      if (updatedScan.uuid === s.uuid) {
        return { ...updatedScan };
      }
      return { ...s };
    });
    setState({ ...state, scans: [...updatedList] });
    setCurrentScan({ ...updatedScan });
  };

  const onDoneWithMobileDevice = ({ isDoneWithDocuments, isUserRequestingDesktop }) => {
    if (isUserRequestingDesktop) {
      beginScanning();
    } else if (isDoneWithDocuments) {
      window.location.reload();
    }
  };

  // Load the scan data
  useEffect(() => {
    if (!info || isHidden) return;

    setIsOverlayShown(false);
    setIsRescanDone(false);
    setShowMobileDone(false);

    if (documents.size === 0 || documents.length === 0) {
      onNext({ scans: [], fail: true });
    } else {
      load(documents ? [...documents] : []);
    }
  }, [info, isHidden, documents]);

  // Initialize the webcam scanner
  useEffect(() => {
    if (!isReady) return;

    window.Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      const obj = window.Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
      obj.Viewer.setViewMode(2, 2);
      setDWObject(obj);
    });
    window.Dynamsoft.DWT.Load();
  }, [isReady]);

  // Initialize image capture event handler
  useEffect(() => {
    if (!DWObject) return;

    DWObject.Addon.Camera.on('video-closed', () => {
      if (DWObject.HowManyImagesInBuffer > 0) {
        const fileType = window.Dynamsoft.DWT.EnumDWT_ImageType.IT_JPG;
        const onError = (errorCode, errorString) => console.error(errorCode, errorString);
        DWObject.ConvertToBlob([0], fileType, uploadScan, onError);
        DWObject.RemoveAllImages();
      }
    });
  }, [DWObject]);

  if (isHidden) return null;

  if (isRescanDone) {
    return (
      <BlockFormLabel>
        <Typography variant="h6">{t('badgeApplication.done')}</Typography>
      </BlockFormLabel>
    );
  }

  if (showMobileDone) {
    return (
      <BlockFormLabel>
        <Typography variant="h6">{t('mobile-scanning-done')}</Typography>
      </BlockFormLabel>
    );
  }

  return (
    <>
      {!currentScan && !isOverlayShown && (
        <>
          <div>
            {info.step === 'rescan' ? (
              <Typography variant="h5">
                The following documents have been sent back due to one or more problems with the document's image or the
                data. Make sure a clear image of the document is provided and all the data entered is correct.
              </Typography>
            ) : (
              <Typography variant="h5">
                <strong>{t('pre-scanning.statement')}</strong>
              </Typography>
            )}
          </div>
          <BlockFormLabel style={{ marginTop: 8, marginBottom: 32, color: colors.danger }}>
            <Typography variant="subtitle2">
              <strong>{t('pre-scanning.skip_notice')}</strong>
            </Typography>
          </BlockFormLabel>

          {(!state.scans || !state.scans.length) && (
            <div
              style={{
                height: '100%',
                marginTop: 32,
                fontSize: '2em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <CircularProgress size={60} />
            </div>
          )}
        </>
      )}

      {state.scans && !currentScan && (
        <ScanList
          scans={state.scans}
          onOverlayShown={onOverlayToggled}
          onBegin={beginScanning}
          onDoneWithMobileDevice={onDoneWithMobileDevice}
        />
      )}

      {currentScan && (
        <ScanEntry
          info={info}
          scan={currentScan}
          totalScans={state.scans.length}
          onScanUpdated={onScanUpdated}
          onClick={() => scanImage(currentScan)}
          onClickFileUpload={file => uploadImage(currentScan, file)}
          onSkip={isSkipped => onSkipScan(currentScan, isSkipped)}
          onNext={proceedToNextScan}
          startOverAction={startOverAction}
        />
      )}

      {currentScan && <BackButton onClick={goBackToPreviousScan} />}
    </>
  );
}
