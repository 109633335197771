import request from '../Request';

export default {
  getPerson,
  create: createPerson,
  find: findPeople,
  resendInvitation,
  deactivate,
  patch,
  activate,
  getComments,
  addComment,
  changeTscExemption,
  getPacsId,
  findDuplicates,
  simulateMerge: simulateMergePeople,
  merge: mergePeople,
  getDoesExistInTSC,
  refreshTscInfo,
  isRapbackCancellable,
  banAccount,
};

function getPerson({ uuid }) {
  return request('authenticated-user', 'GET', `people/${uuid}`);
}

function createPerson({ individual, shouldReturnMatchIfExists = false, shouldStopIfPotentialDuplicatesFound = false }) {
  return request('authenticated-user', 'POST', 'people', {
    user: individual,
    shouldReturnMatchIfExists,
    shouldStopIfPotentialDuplicatesFound,
  });
}

function findPeople(criteria, signal) {
  return request('authenticated-user', 'POST', `people/find`, criteria, signal);
}

function patch({ uuid, updates, comment }) {
  return request('authenticated-user', 'PATCH', `people/${uuid}`, { updates, comment });
}

function resendInvitation({ user, uuid }) {
  return request(user, 'GET', `people/${uuid}/resend-invitation`);
}

function deactivate({ uuid }) {
  return request('authenticated-user', 'PUT', `people/${uuid}/deactivate`);
}

function activate({ user, uuid }) {
  return request(user, 'PUT', `people/${uuid}/activate`);
}

function getComments({ userUuid }) {
  return request('authenticated-user', 'GET', `people/${userUuid}/comments`);
}

function addComment({ userUuid, comment }) {
  return request('authenticated-user', 'POST', `people/${userUuid}/comments`, { comment });
}

function changeTscExemption({ userUuid, isTscExempt, comment }) {
  return request('authenticated-user', 'POST', `people/${userUuid}/tsc-exempt`, { isTscExempt, comment });
}

function getPacsId({ userUuid }) {
  return request('authenticated-user', 'GET', `people/${userUuid}/pacs-id`);
}

function findDuplicates({
  firstName = null,
  middleName = null,
  lastName = null,
  dateOfBirth = null,
  excludedUserUuid = null,
  signal,
}) {
  return request(
    'authenticated-user',
    'POST',
    `people/find-duplicates`,
    {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      excludedUserUuid,
    },
    signal
  );
}

function simulateMergePeople({ source, duplicate }) {
  return request('authenticated-user', 'POST', `people/simulate-merge-users`, {
    source,
    duplicate,
  });
}

function mergePeople({ source, target }) {
  return request('authenticated-user', 'POST', `people/merge-users`, {
    from: source,
    to: target,
  });
}

function getDoesExistInTSC({ userUuid }) {
  return request('authenticated-user', 'GET', `people/${userUuid}/does-exist-tsc`);
}

function refreshTscInfo({ userUuid }) {
  return request('authenticated-user', 'GET', `background-check/refresh-user/${userUuid}`);
}

function isRapbackCancellable({ userUuid }) {
  return request('authenticated-user', 'GET', `people/${userUuid}/is-rapback-cancellable`);
}

function banAccount({ userUuid, reason }) {
  return request('authenticated-user', 'PUT', `people/${userUuid}/status/ban`, { reason });
}
