import request from '../Request';

export default function BadgeCreateRequest(user, badge, options) {
  const method = 'POST';

  const url = 'badge';

  const data = {
    badge: badge,
    options: options,
  };

  return request(user, method, url, data);
}
