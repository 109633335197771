import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { AppContext } from '../../../../../App';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import filesApi from '../../../../../ajax/File/filesApi';
import badgeApplicationApi from '../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { handleError } from '../../../../../utils';
import documentRequirements from '../../../../Badger/steps/Scanning/components/documentRequirements';
import StaggeredDatePicker from '../../../../../shared/components/DatePickers/StaggeredDatePicker';
import countries from '../../../../Badger/steps/DataConfirmation/questions/countries';
import usaStates from '../../../../Badger/steps/DataConfirmation/questions/states';
import canadaProvinces from '../../../../Badger/steps/DataConfirmation/questions/states_canada';
import { ExtraDocumentHelp } from '../components/actions/Validate/ValidateDocuments/ValidateDocuments';

const states = [...usaStates, ...canadaProvinces];

export default function AddIdentificationDocumentModal({ badgeApplication, isOpen, onClose }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [options, setOptions] = useState([]);
  const [requirements, setRequirements] = useState(null);
  const [documentNumber, setDocumentNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState(null);
  const [issuingCountry, setIssuingCountry] = useState(null);
  const [issuingState, setIssuingState] = useState(null);
  const [isCommercialDriversLicense, setIsCommercialDriversLicense] = useState(null);
  const [restrictions, setRestrictions] = useState('');

  const cleanup = () => {
    setDocumentNumber('');
    setExpirationDate(null);
    setIssuingCountry(null);
    setIssuingState(null);
    setRequirements(null);
    setOptions([]);
    setFileType(null);
    setIsCommercialDriversLicense(null);
    setRestrictions('');
    setIsModalOpen(false);
  };

  const onModalClose = () => {
    cleanup();
    onClose(false);
  };

  const onSave = () => {
    app.api.toggleLoading(true);
    badgeApplicationApi
      .addVerificationDocument({
        user: app.user,
        badgeApplicationUuid: badgeApplication.uuid,
        fileTypeUuid: fileType.value,
        documentNumber,
        expirationDate,
        issuingCountry: issuingCountry?.value,
        issuingState: issuingState?.value,
        isCommercialDriversLicense,
        restrictions: restrictions || '',
      })
      .then(({ success, message }) => {
        if (!success) {
          return handleError({ message });
        }
        cleanup();
        onClose(true);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const isSaveDisabled = () => {
    if (isLoading || !fileType) return true;

    // TODO: Driver's License specific fields
    // if (fileType?.original?.systemName === 'drivers-license' && isCommercialDriversLicense === null) {
    //   return true;
    // }

    if (requirements?.documentNumber?.enabled && requirements?.documentNumber?.required && !documentNumber) return true;
    if (requirements?.expirationDate?.enabled && requirements?.expirationDate?.required && !expirationDate) return true;
    if (requirements?.issuingAuthority?.enabled && requirements?.issuingAuthority?.hasCountry && !issuingCountry)
      return true;
    return !!(requirements?.issuingAuthority?.enabled && requirements?.issuingAuthority?.hasState && !issuingState);
  };

  const shouldAllowIssuingAuthorityEntry = () => {
    if (!fileType || !requirements) return false;
    const systemName = fileType?.original?.systemName || '';
    const isPassport = systemName.toLocaleLowerCase().includes('passport');
    return isPassport || (!!requirements?.issuingAuthority?.enabled && !!requirements?.issuingAuthority?.hasCountry);
  };

  useEffect(() => {
    setDocumentNumber('');
    setExpirationDate(null);
    setIssuingCountry(null);
    setIssuingState(null);
    setRequirements(null);
    setIsCommercialDriversLicense(null);
    setRestrictions('');

    if (fileType) {
      const requirements = documentRequirements[fileType.original.systemName];
      setRequirements(requirements);
    }
  }, [fileType]);

  useEffect(() => {
    if (!isOpen || !badgeApplication) return;

    setIsModalOpen(true);
    setIsLoading(true);
    filesApi
      .fileTypes({ user: app.user })
      .then(({ success, data }) => {
        if (success) {
          const types = data
            .filter(x => x.isActive && x.name.startsWith('Identity'))
            .map(t => ({ label: t.name.replace('Identity: ', ''), value: t.uuid, original: t }));
          setOptions(types);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsLoading(false));
  }, [isOpen, badgeApplication]);

  if (!isModalOpen || !badgeApplication) return null;

  return (
    <Modal isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Add Identification Document for Verification</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label>Choose an identification document</Label>
              <Select
                classNamePrefix="airbadge"
                options={options}
                className="form-select"
                value={fileType}
                placeholder=""
                onChange={option => setFileType(option)}
                isDisabled={isLoading}
              />
            </FormGroup>
          </Col>
        </Row>

        <ExtraDocumentHelp documentCode={fileType?.original?.systemName || ''} />

        {!!requirements?.documentNumber?.enabled && (
          <Row className="mt-2">
            <Col>
              <FormGroup>
                <Label className={requirements?.documentNumber?.required ? 'required' : null}>Document Number</Label>
                <Input value={documentNumber} onChange={event => setDocumentNumber(event.target.value)} />
              </FormGroup>
            </Col>
          </Row>
        )}
        {!!requirements?.expirationDate?.enabled && (
          <Row className="mt-2">
            <Col>
              <FormGroup>
                <Label className={requirements?.expirationDate?.required ? 'required' : null}>Expiration Date</Label>
                <StaggeredDatePicker
                  startingYear={new Date().getFullYear()}
                  endingYear={new Date().getFullYear() + 90}
                  onValidDate={({ year, month, day }) => setExpirationDate(`${year}-${month}-${day}`)}
                  onInvalidDate={() => setExpirationDate(null)}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {!!requirements?.issuingAuthority?.enabled && !!requirements?.issuingAuthority?.hasState && (
          <Row className="mt-2">
            <Col>
              <FormGroup>
                <Label className="required">Issuing State/Province</Label>
                <Select
                  placeholder=""
                  classNamePrefix="airbadge"
                  className="form-select"
                  options={states}
                  value={issuingState}
                  onChange={option => setIssuingState(option)}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {shouldAllowIssuingAuthorityEntry() && (
          <Row className="mt-2">
            <Col>
              <FormGroup>
                <Label className="required">Country</Label>
                <Select
                  placeholder=""
                  classNamePrefix="airbadge"
                  className="form-select"
                  options={countries}
                  value={issuingCountry}
                  onChange={option => setIssuingCountry(option)}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {/* TODO: Driver's License specific fields */}
        {/*{fileType?.original?.systemName === 'drivers-license' && (*/}
        {/*  <>*/}
        {/*    <Row className="mt-2">*/}
        {/*      <Col>*/}
        {/*        <FormGroup>*/}
        {/*          <Label className="required">Is this a commercial driver's license?</Label>*/}
        {/*          <RadioGroup*/}
        {/*            row*/}
        {/*            aria-label="is_commercial_drivers_license"*/}
        {/*            name="is_commercial_drivers_license"*/}
        {/*            value={`${isCommercialDriversLicense}`}*/}
        {/*            onChange={event => setIsCommercialDriversLicense(event.target.value === 'true')}*/}
        {/*          >*/}
        {/*            <FormControlLabel*/}
        {/*              value="true"*/}
        {/*              control={<Radio color="primary" />}*/}
        {/*              label="Yes"*/}
        {/*              classes={{ root: 'mb-0' }}*/}
        {/*            />*/}
        {/*            <FormControlLabel*/}
        {/*              value="false"*/}
        {/*              control={<Radio color="primary" />}*/}
        {/*              label="No"*/}
        {/*              classes={{ root: 'mb-0' }}*/}
        {/*            />*/}
        {/*          </RadioGroup>*/}
        {/*        </FormGroup>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}

        {/*    <Row>*/}
        {/*      <Col>*/}
        {/*        <FormGroup>*/}
        {/*          <Label>*/}
        {/*            Restrictions <i style={{ fontSize: 'smaller' }}>(optional)</i>*/}
        {/*          </Label>*/}
        {/*          <Input value={restrictions} onChange={event => setRestrictions(event.target.value)} />*/}
        {/*        </FormGroup>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </>*/}
        {/*)}*/}
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel="Add Document"
          onSave={onSave}
          saveDisabled={isSaveDisabled()}
        />
      </ModalFooter>
    </Modal>
  );
}
