import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalActions from '../ModalActions/ModalActions';

export const MODAL_SIZE_EXTRA_LARGE = 'xl';
export const MODAL_SIZE_LARGE = 'lg';
export const MODAL_SIZE_MEDIUM = 'md';
export const MODAL_SIZE_SMALL = 'sm';

export default function AirBadgeModal(props) {
  const {
    id,
    size = MODAL_SIZE_LARGE,
    title,
    isOpen,
    onModalClicked,
    onClose,
    closeLabel,
    closeColor,
    saveLabel,
    onSave,
    saveDisabled,
    saveColor,
    leftSideExtra,
    hideFooter,
    centered,
    children,
    middleExtra,
    style = {},
    inline,
  } = props;

  if (inline) {
    return <>{children}</>;
  }

  return (
    <Modal
      id={id}
      style={style}
      size={size}
      isOpen={isOpen}
      toggle={onClose}
      centered={centered}
      onClick={onModalClicked}
    >
      <ModalHeader
        toggle={onClose}
        close={
          <button className="close" onClick={onClose} style={{ outline: 'none' }}>
            <i className="fa fa-times" />
          </button>
        }
      >
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      {!hideFooter && (
        <ModalFooter>
          <ModalActions
            closeLabel={closeLabel}
            onClose={onClose}
            closeColor={closeColor}
            saveLabel={saveLabel}
            onSave={onSave}
            saveDisabled={saveDisabled}
            saveColor={saveColor}
            leftSideExtra={leftSideExtra}
            middleExtra={middleExtra}
          />
        </ModalFooter>
      )}
    </Modal>
  );
}
