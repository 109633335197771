import React, { useContext, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppContext, colors } from '../../../../App';
import { handleError, sortBadgeApplicationConfigByMilestone } from '../../../../utils';
import TitledBox from '../../../../shared/TitledBox/TitledBox';
import AirBadgeModal, { MODAL_SIZE_EXTRA_LARGE } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import request from '../../../../ajax/Request';

function ConfigEntry({ config, onChange, isActiveBadgeApplication }) {
  const { type, order, label, isEnabled, isComplete, documentTransactions } = config;
  const boxStyle = { marginTop: 32 };
  const legendStyle = { letterSpacing: 1 };
  if (isEnabled) {
    boxStyle.border = `1px solid ${colors.success}`;
    legendStyle.textDecoration = 'solid';
    legendStyle.fontWeight = 500;
  } else {
    boxStyle.border = `1px dashed ${colors.danger}`;
    legendStyle.textDecoration = 'line-through';
  }
  return (
    <TitledBox title={label} style={boxStyle} legendStyle={legendStyle}>
      <Row>
        <Col sm={2}>
          <FormGroup>
            <Label className="required">Order</Label>
            <Input
              disabled={true}
              type="number"
              value={order}
              onChange={event => onChange({ ...config, order: event.target.value })}
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <Label className="required">
              Label{' '}
              <small>
                <i>(shown in Badge Application details modal UI)</i>
              </small>
            </Label>
            <Input value={label} onChange={event => onChange({ ...config, label: event.target.value })} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={2}>
          <FormGroup>
            <Label>Enabled?</Label>
            <RadioGroup
              value={isEnabled ? 'yes' : 'no'}
              onChange={event => {
                event.stopPropagation();
                onChange({ ...config, isEnabled: event.target.value === 'yes' });
              }}
              row
            >
              <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormGroup>
        </Col>
        {isActiveBadgeApplication && (
          <Col sm={2}>
            <FormGroup>
              <Label>Completed?</Label>
              <RadioGroup
                value={isComplete ? 'yes' : 'no'}
                onChange={event => {
                  event.stopPropagation();
                  onChange({ ...config, isComplete: event.target.value === 'yes' });
                }}
                row
              >
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormGroup>
          </Col>
        )}
      </Row>
      {isActiveBadgeApplication && type === 'document' && (
        <>
          <div className="separator mb-3">Document Transactions</div>
          <Row>
            <Col sm={2}>
              <FormGroup>
                <Label>Signatory Complete?</Label>
                {!documentTransactions.signatory ? (
                  <div style={{ opacity: 0.6 }}>
                    <i>Not applicable</i>
                  </div>
                ) : (
                  <RadioGroup
                    value={documentTransactions.signatory.isComplete ? 'yes' : 'no'}
                    onChange={event => {
                      event.stopPropagation();
                      const isComplete = event.target.value === 'yes';
                      const newConfig = { ...config };
                      newConfig.documentTransactions.signatory.isComplete = isComplete;
                      onChange(newConfig);
                    }}
                    row
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                )}
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup>
                <Label>Applicant Complete?</Label>
                {!documentTransactions.applicant ? (
                  <div style={{ opacity: 0.6 }}>
                    <i>Not applicable</i>
                  </div>
                ) : (
                  <RadioGroup
                    value={documentTransactions.applicant.isComplete ? 'yes' : 'no'}
                    onChange={event => {
                      event.stopPropagation();
                      const isComplete = event.target.value === 'yes';
                      const newConfig = { ...config };
                      newConfig.documentTransactions.applicant.isComplete = isComplete;
                      onChange(newConfig);
                    }}
                    row
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                )}
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </TitledBox>
  );
}

export default function BadgeApplicationConfigEditor({ isOpen, onClose, config }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [badgeApplicationConfig, setBadgeApplicationConfig] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const onCloseModal = params => {
    setError('');
    setActiveTab(0);
    setHasChanged(false);
    setBadgeApplicationConfig(null);
    setIsModalOpen(false);
    onClose({ shouldReload: params && params.shouldReload });
  };

  const onChange = async json => {
    try {
      const config = JSON.parse(json);
      setBadgeApplicationConfig({ ...badgeApplicationConfig, config });
      setHasChanged(true);
      setError('');
    } catch (err) {
      setError('Invalid JSON');
    }
  };

  const onSaveChanges = () => {
    app.api.toggleLoading(true);
    if (badgeApplicationConfig.id) {
      request('authenticated-user', 'PUT', `admin/badge-application/config/${badgeApplicationConfig.id}`, {
        newConfig: badgeApplicationConfig.config,
      })
        .then(({ success }) => {
          if (success) {
            onCloseModal({ shouldReload: true });
          } else {
            handleError({ message: 'Failed to update badge application config' });
          }
        })
        .catch(error => handleError({ error, message: 'Failed to update badge application config' }))
        .finally(() => app.api.toggleLoading(false));
    } else {
      const { uuid: badgeApplicationUuid } = config;
      badgeApplicationApi
        .updateConfig({ badgeApplicationUuid, config: badgeApplicationConfig.config })
        .then(({ success }) => {
          if (success) {
            onCloseModal({ shouldReload: true });
          } else {
            handleError({ message: 'Failed to update badge application config' });
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }
  };

  const buildConfigDisplay = milestone => {
    const entries = [];
    Object.keys(badgeApplicationConfig.config).forEach(key => {
      const entryMilestone = badgeApplicationConfig.config[key].milestone;
      if (milestone === entryMilestone) {
        entries.push(
          <ConfigEntry
            key={key}
            isActiveBadgeApplication={!badgeApplicationConfig.id}
            config={badgeApplicationConfig.config[key]}
            onChange={updatedConfig => {
              badgeApplicationConfig.config[key] = updatedConfig;
              setBadgeApplicationConfig({ ...badgeApplicationConfig });
              setHasChanged(true);
            }}
          />
        );
      }
    });
    return entries;
  };

  useEffect(() => {
    if (!isOpen || !config) return;

    const cloned = cloneDeep(config);
    cloned.config = sortBadgeApplicationConfigByMilestone(cloned.config);
    setBadgeApplicationConfig(cloned);
    setIsModalOpen(true);
  }, [isOpen, config]);

  if (!isOpen || !badgeApplicationConfig) return null;

  let title = 'Badge Application Process Configuration';
  if (badgeApplicationConfig.id) {
    const { isForRenewals, badgeTypeName } = config;
    title = isForRenewals ? `${badgeTypeName} (Renewals) Configuration` : `${badgeTypeName} Configuration`;
  }

  return (
    <AirBadgeModal
      size={MODAL_SIZE_EXTRA_LARGE}
      title={title}
      isOpen={isModalOpen}
      closeLabel="Cancel"
      onClose={onCloseModal}
      saveLabel="Save Changes"
      onSave={onSaveChanges}
      saveDisabled={!hasChanged}
    >
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, currentTab) => setActiveTab(currentTab)}
      >
        <Tab label="Collect" />
        <Tab label="Prepare" />
        <Tab label="Transmit" />
        <Tab label="Authorize" />
        <Tab label="Issue" />
        <Tab label="RAW JSON" style={{ color: colors.danger, fontWeight: 'bold' }} />
      </Tabs>

      <Card>
        <CardContent>
          <style>{`
              .phase-tab {
                width: 100%;
                height: 100%;
                padding: 0 15px 15px 15px;
              }
            `}</style>

          {/* COLLECT PHASE */}
          <div className="phase-tab" style={{ display: activeTab === 0 ? 'inline-block' : 'none' }}>
            {buildConfigDisplay('collect')}
          </div>

          {/* PREPARE PHASE */}
          <div className="phase-tab" style={{ display: activeTab === 1 ? 'inline-block' : 'none' }}>
            {buildConfigDisplay('prepare')}
          </div>

          {/* TRANSMIT PHASE */}
          <div className="phase-tab" style={{ display: activeTab === 2 ? 'inline-block' : 'none' }}>
            {buildConfigDisplay('transmit')}
          </div>

          {/* AUTHORIZE PHASE */}
          <div className="phase-tab" style={{ display: activeTab === 3 ? 'inline-block' : 'none' }}>
            {buildConfigDisplay('authorize')}
          </div>

          {/* ISSUE PHASE */}
          <div className="phase-tab" style={{ display: activeTab === 4 ? 'inline-block' : 'none' }}>
            {buildConfigDisplay('issue')}
          </div>

          {/* RAW JSON */}
          <div className="phase-tab" style={{ display: activeTab === 5 ? 'inline-block' : 'none' }}>
            <Alert color="info">
              Allowed notification placeholders:{' '}
              <i>[applicant_name], [airport_person_id], [signatory_name], [badge_type]</i>
            </Alert>
            {error && (
              <Alert color="warning" fade={false}>
                {error}
              </Alert>
            )}
            <div style={{ height: 750 }}>
              <div className="separator"></div>
              <AceEditor
                name="badge-application-config"
                style={{ zIndex: 0 }}
                mode="json"
                theme="chrome"
                width="100%"
                height="100%"
                defaultValue={JSON.stringify(badgeApplicationConfig.config, null, 2)}
                onChange={onChange}
                editorProps={{ $blockScrolling: false }}
                setOptions={{ useWorker: false }}
              />
              <div className="separator"></div>
            </div>
          </div>
        </CardContent>
      </Card>
    </AirBadgeModal>
  );
}
