import filesApi from './ajax/File/filesApi';
import peopleApi from './ajax/People/peopleApi';

const MAX_FILE_SIZE_KB = 1900;

/**
 * Convert a PNG file to a JPEG file
 *
 * @param file
 * @returns {Promise<unknown>}
 */
export function convertPngToJpg(file) {
  return new Promise(resolve => {
    let source = null;
    let fileNameWithoutExtension = '';
    if (typeof file === 'string') {
      source = file;
      fileNameWithoutExtension = 'document';
    } else if (file.type !== 'image/png') {
      // No need for the conversion
      return resolve(file);
    } else {
      source = URL.createObjectURL(file);
      fileNameWithoutExtension = file.name.split('.')[0];
    }

    const image = new Image();
    image.src = source;
    image.onload = function () {
      const htmlCanvas = document.createElement('canvas');
      htmlCanvas.width = image.width;
      htmlCanvas.height = image.height;

      const ctx = htmlCanvas.getContext('2d');
      ctx.drawImage(image, 0, 0, image.width, image.height);

      const dataURL = htmlCanvas.toDataURL('image/jpeg', 1);
      const byteString = atob(dataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: 'image/jpeg' });
      const jpegFile = new File([blob], `${fileNameWithoutExtension}.jpeg`, { type: 'image/jpeg' });
      resolve(jpegFile);
    };
  });
}

export function isImageFileTooBig(file) {
  if (file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
    return false;
  }
  const sizeInKilobytes = file.size / 1024;
  return sizeInKilobytes > MAX_FILE_SIZE_KB;
}

/**
 * Resize the image to a smaller size if it exceeds the maximum file size
 *
 * @param inputFile
 * @returns {Promise<unknown>}
 */
export function resizeImage(inputFile) {
  return new Promise(resolve => {
    if (inputFile.type !== 'image/jpg' && inputFile.type !== 'image/jpeg') {
      return resolve(inputFile);
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const sizeInKilobytes = inputFile.size / 1024;
        let reductionFactor = 0.9;
        let width = img.width;
        let height = img.height;

        if (sizeInKilobytes > 2345) {
          reductionFactor = Math.sqrt(MAX_FILE_SIZE_KB / sizeInKilobytes);
        }

        width = width * reductionFactor;
        height = height * reductionFactor;

        // Create a canvas element to draw the resized image
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        const dataURL = canvas.toDataURL('image/jpeg', 0.7);
        const byteString = atob(dataURL.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: 'image/jpeg' });

        // Uncomment this to get the image before its uploaded
        // window.open(URL.createObjectURL(blob), '_blank');

        const fileNameWithoutExtension = inputFile.name.split('.')[0];
        const jpegFile = new File([blob], `${fileNameWithoutExtension}.jpeg`, { type: 'image/jpeg' });
        resolve(jpegFile);
      };
    };
    reader.readAsDataURL(inputFile);
  });
}

/**
 * Upload a profile picture for a person
 *
 * @param userUuid
 * @param imageData
 * @returns {Promise<unknown>}
 */
export async function uploadProfilePicForPerson({ userUuid, imageData }) {
  let imageFile = await convertPngToJpg(imageData);
  if (isImageFileTooBig(imageFile)) {
    imageFile = await resizeImage(imageFile);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result.split(',')[1];
      peopleApi
        .patch({
          uuid: userUuid,
          updates: { headshotPicture: base64data },
        })
        .then(() => {
          const data = {
            user: userUuid,
            code: 'portrait-photo',
            comment: 'New headshot picture',
          };
          return filesApi.upload({ file: imageFile, data }).finally(() => resolve(true));
        })
        .catch(reject);
    };
    reader.readAsDataURL(imageFile);
  });
}
