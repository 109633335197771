import React, { Component } from 'react';
import loading from './loading.gif';

export default class Loading extends Component {
  style = {
    loading: {
      position: 'fixed',
      zIndex: '9999',
      background: 'white',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      opacity: '0.8',
    },

    loadingImage: {
      position: 'relative',
      top: '50%',
      marginTop: '-128px',
    },
  };

  componentWillReceiveProps({ target }) {
    if (!target) {
      return;
    }

    const element = document.querySelector(target);

    if (!element) {
      return;
    }

    const loadingElement = document.querySelector('.loading');

    if (!loadingElement) {
      return;
    }

    loadingElement.style.top = element ? element.offsetTop + 'px' : '0';
    loadingElement.style.left = element ? element.offsetLeft + 'px' : '0';
    loadingElement.style.width = element ? element.offsetWidth + 'px' : '100vw';
    loadingElement.style.height = element ? element.offsetHeight + 'px' : '100vh';
  }

  render() {
    return (
      <div className={'loading text-center ' + (this.props.loading ? 'd-block' : 'd-none')} style={this.style.loading}>
        <img src={loading} alt={'Loading'} style={this.style.loadingImage} />
      </div>
    );
  }
}
