import React, { useContext, useState } from 'react';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import request from '../../../../../../ajax/Request';
import { AppContext } from '../../../../../../App';
import { handleError } from '../../../../../../utils';

export default function RetainBadgeModal({ isOpen, onClose, badge }) {
  const app = useContext(AppContext);
  const [comment, setComment] = useState('');
  const [isCollected, setIsCollected] = useState(null);

  const resetModal = () => {
    setComment('');
    setIsCollected(null);
  };

  const onCloseModal = () => {
    resetModal();
    onClose({ shouldReload: false });
  };

  const onSave = () => {
    app.api.toggleLoading(true);
    const newStatus = badge.collected ? 'released' : 'retained';
    const data = { new_status: newStatus, comment: comment || null };
    request('authenticated-user', 'PUT', `badges/${badge.uuid}/status`, data)
      .then(({ success }) => {
        if (success) {
          resetModal();
          onClose({ shouldReload: true });
        }
      })
      .catch(error => {
        app.api.toggleLoading(false);
        handleError({ error });
      });
  };

  if (!isOpen || !badge) return null;

  if (badge.badgeStatus === 'suspended') badge.collected = null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title={badge.collected ? 'Release Retained Badge' : 'Retain Badge'}
      isOpen={isOpen}
      onClose={onCloseModal}
      onSave={onSave}
      saveLabel={badge.collected ? 'Release' : 'Retain'}
      saveDisabled={!isCollected || isCollected === 'false'}
    >
      {badge.collected ? (
        <Alert color="info">
          <div>
            Released badges will no longer be marked as <i>collected</i>.
          </div>
        </Alert>
      ) : (
        <Alert color="warning">
          <div>
            Retained badges are kept <i>active</i> and marked as <i>collected</i>.
          </div>
          <div className="mt-2">This should only be used as a temporary solution for special situations.</div>
        </Alert>
      )}

      <Row>
        <Col>
          {badge.collected ? (
            <Label>
              Do you certify that the badge has been released back to{' '}
              <strong>{badge.badgeHolderName || 'the badge holder'}</strong>?
            </Label>
          ) : (
            <Label>
              Do you certify that the badge has been collected from{' '}
              <strong>{badge.badgeHolderName || 'the badge holder'}</strong> and is securely stored?
            </Label>
          )}
          <RadioGroup
            row
            aria-label={name}
            name={name}
            value={isCollected}
            onChange={event => setIsCollected(event?.target?.value)}
          >
            <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </Col>
      </Row>

      {isCollected === 'false' && (
        <>
          {badge.collected ? (
            <Alert color="danger">Released badges must be returned back to the badge holder.</Alert>
          ) : (
            <Alert color="danger">
              Retained badges must be collected and placed in secure storage until the badge is ready to be released
              back to the badge holder.
            </Alert>
          )}
        </>
      )}

      <Row>
        <Col>
          <FormGroup>
            <Label>
              Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
            </Label>
            <Input required type="textarea" value={comment} onChange={event => setComment(event.target.value)} />
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
