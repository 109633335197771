import request from '../Request';

export default function StaffGetAvailabilityRequest(
  user,
  staff,
  appointmentType,
  startDate,
  timeZone,
  appointment_uuid,
  holder,
  shouldIgnoreLeadTime = false
) {
  let args = [];

  if (staff && staff.value) {
    args.push(`uuid=${staff.value}`);
  }

  if (appointmentType.value) {
    args.push(`appointment_type_uuid=${appointmentType.value}`);
  }

  if (appointment_uuid) {
    args.push(`appointment_uuid=${appointment_uuid}`);
  }

  if (holder && holder.value) {
    args.push(`holder_uuid=${holder.value}`);
  }

  const year = startDate.getFullYear();
  const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
  const day = startDate.getDate().toString().padStart(2, '0');

  args.push(`time=${year}-${month}-${day}`);
  args.push(`shouldIgnoreLeadTime=${shouldIgnoreLeadTime}`);

  const url = 'staff/availability?' + args.join('&');

  return request(user, 'GET', url);
}
