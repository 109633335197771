import React, { useContext, useState } from 'react';
import { Alert, FormGroup, Label } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReplayIcon from '@material-ui/icons/Replay';
import { AppContext, colors } from '../../../../../../App';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import InputWithCharacterLimit from '../../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import { handleError, notify } from '../../../../../../utils';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';

const CHARACTER_LIMIT = 255;

export default function RedoBadgeApplicationAction() {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const { badgeApplication, onActionDone } = useContext(ActionPanelContext);

  const isHidden = () => {
    const { active, config } = badgeApplication;
    const { applicationDocument, approveBackgroundCheckResults } = config;
    const backgroundCheckIsNotDone =
      approveBackgroundCheckResults.isEnabled && !approveBackgroundCheckResults.isComplete;

    if (!!active === false) {
      // Badge Application is not active
      return true;
    }

    if (!applicationDocument.isEnabled) {
      // This workflow doesn't have a badge application
      return true;
    }

    return !backgroundCheckIsNotDone;
  };

  const onCloseWithoutSaving = () => {
    setIsModalOpen(false);
    setReason('');
  };

  const isSaveDisabled = () => {
    return !reason;
  };

  const onProceed = () => {
    const { api } = app;
    const { uuid } = badgeApplication;
    api.toggleLoading(true);
    badgeApplicationApi
      .redoBadgeApplication({ badgeApplicationUuid: uuid, reason })
      .then(() => {
        setIsModalOpen(false);
        setReason('');
        onActionDone({ shouldReload: true });
        notify({ message: 'Badge Application Document has been reset', severity: 'success' });
      })
      .catch(error => handleError({ error, message: 'Unable to reset Badge Application' }))
      .finally(() => api.toggleLoading(false));
  };

  return (
    <ListItem button hidden={isHidden()} onClick={() => setIsModalOpen(true)}>
      <ListItemIcon>
        <ReplayIcon style={{ color: colors.primary }} />
      </ListItemIcon>
      <ListItemText primary="Force Applicant Restart" />

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Force Applicant Restart"
        isOpen={isModalOpen}
        onClose={onCloseWithoutSaving}
        closeLabel="Cancel"
        onSave={onProceed}
        saveLabel="Force Applicant Restart"
        saveDisabled={isSaveDisabled()}
        saveColor="warning"
      >
        <Alert color="info">
          This will discard the badge application document, discard any uploaded documents, and ask the applicant to
          redo their badge application.
        </Alert>
        <FormGroup>
          <Label className="required">Reason</Label>
          <div style={{ fontSize: 'smaller', marginBottom: 6 }}>
            This will be appended to the notification that is sent out to all configured recipients
          </div>
          <InputWithCharacterLimit limit={CHARACTER_LIMIT} value={reason} onChange={value => setReason(value)} />
        </FormGroup>
      </AirBadgeModal>
    </ListItem>
  );
}
