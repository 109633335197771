import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import trim from 'lodash/trim';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import StaggeredDatePicker from '../../../../../shared/components/DatePickers/StaggeredDatePicker';
import { AppContext } from '../../../../../App';
import { validEmailAddressRegex } from '../../../../../utils';

export default function PeopleEditSimpleFieldModal({ isOpen, onClose, onSave, config }) {
  const app = useContext(AppContext);
  const { type, label, value, extraText, property } = config;
  const [oldValue, setOldValue] = useState('');
  const [newValue, setNewValue] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);

  const onModalClick = () => {
    const datePicker = document.querySelector('.edit-date-trigger .datetime-picker-popup');
    if (datePicker && datePicker.style.display !== 'none') {
      document.querySelector('#edit-date').click();
    }
  };

  const isSaveDisabled = () => {
    return !newValue || hasEmailError;
  };

  useEffect(() => {
    setNewValue('');
    setOldValue('');
    setHasEmailError(false);

    if (isOpen && config) {
      if (config.type === 'date') {
        setOldValue(value ? moment.tz(value, app.timezone).format('MMM DD, YYYY') : null);
      } else {
        setOldValue(config.value);
      }
    }
  }, [isOpen, config]);

  if (!isOpen) return null;

  return (
    <Modal size="md" isOpen={isOpen} toggle={onClose} onClick={onModalClick}>
      <CustomModalHeader toggle={onClose}>Change {label}</CustomModalHeader>
      <ModalBody>
        {extraText && (
          <Row>
            <Col>
              {extraText} <br />
              <br />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <ReadOnlyFormData label={`Current ${label}`} value={<i>{oldValue || '-'}</i>} skipTextTransform faded />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <FormGroup>
              <Label className="required">New {label}</Label>
              {type === 'date' ? (
                // NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!!
                <StaggeredDatePicker
                  startingYear={new Date().getFullYear()}
                  endingYear={new Date().getFullYear() + 99}
                  onValidDate={({ year, month, day }) => setNewValue(`${year}-${month}-${day}`)}
                  onInvalidDate={() => setNewValue('')}
                />
              ) : type === 'textarea' ? (
                <Input type="textarea" value={newValue} onChange={({ target }) => setNewValue(target.value)} />
              ) : (
                <>
                  <Input
                    value={newValue}
                    onChange={event => {
                      const value = event?.target?.value;
                      setNewValue(value);
                      setHasEmailError(false);
                      if (property === 'email' && value) {
                        setHasEmailError(!validEmailAddressRegex.test(value));
                      }
                    }}
                    invalid={hasEmailError}
                  />
                  <FormFeedback>Invalid email address</FormFeedback>
                </>
              )}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Close"
          onClose={onClose}
          onSave={() => onSave({ newValue: trim(newValue), comment: '' })}
          saveDisabled={isSaveDisabled()}
          saveLabel="Save Change"
        />
      </ModalFooter>
    </Modal>
  );
}
