import { v4 as uuid } from 'uuid';

function buildAddress({ address, apartment, city, state, country, postalCode }) {
  let fullAddress = address;
  if (apartment) {
    fullAddress = `${fullAddress} ${apartment}`;
  }
  if (city) {
    fullAddress = `${fullAddress}, ${city}`;
  }
  if (state) {
    fullAddress = `${fullAddress}, ${state}`;
  }
  if (postalCode) {
    fullAddress = `${fullAddress}, ${postalCode}`;
  }
  if (country) {
    fullAddress = `${fullAddress}, ${country}`;
  }

  return fullAddress;
}

function buildType02Fields({ airportSON, rapbackCaseNumber, badgeHolderData, fingerprintDate, fingerConfigs }) {
  const {
    firstName,
    lastName,
    middleName,
    aliases = [],
    address,
    apartment,
    city,
    state,
    country,
    postalCode,
    employer,
    social,
    countryOfCitizenShip,
    placeOfBirth,
    dateOfBirth,
    sex,
    race,
    height,
    weight,
    eye,
    hair,
  } = badgeHolderData;

  let fullName = `${lastName}`;
  if (lastName && firstName && middleName) {
    fullName = `${lastName},${firstName} ${middleName}`;
  } else if (lastName && firstName) {
    fullName = `${lastName},${firstName}`;
  }
  fullName = fullName.substring(0, 50);

  const data = {
    5: 'Y', // RETENTION CODE - Y for Yes, N for No
    // 9: '', // CASE NUMBER - 1 to 20 characters
    // 16: '', // SSN - max length 9, no dashes
    18: fullName, // Name - length 3 to 50 - allow commas, hyphens, spaces
    // 19: '', // Aliases - length 3 to 50 - allow commas, hyphens, spaces (MAX 10)
    20: placeOfBirth, // Place of Birth - 2 digit state code
    21: countryOfCitizenShip, // Citizenship country - 2 digit country code
    22: dateOfBirth, // Date of birth - YYYYMMDD - 8 digits
    24: sex, // Sex - M or F
    25: race, // Race - always U
    27: height, // Height - 3 digits - feet and inches
    29: weight, // Weight - 3 digits - pounds
    31: eye, // Eye Color - 3 digits - HAZ, BLK, BRO, GRN, GRY, BLU, MAR, PNK, XXX
    32: hair, // Hair color - 3 digits - BLK, BRO, BLN, GRY, RED, WHI, BAL, XXX
    37: 'TSA CHRC', // REASON FINGERPRINTED - 1 to 75 characters
    38: fingerprintDate, // DATE FINGERPRINTED - YYYYMMDD - 8 digits
    39: employer, // Employer and address - 1 to 120 characters
    // 40: '', // Occupation - 1 to 50 characters
    // 41: '', // Address of applicant - 1 to 120 characters
    70: 'Y', // REQUEST FOR ELECTRONIC RAP SHEET - Y for Yes, N for No
    '73.1.1': airportSON, // Airport's SON identifier
    '73.2.1': 'TD26', // Processing Identifier (PID) - 4 characters
    '73.3.1': '69001104', // CONTROLLING AGENCY IDENTIFIER - 9 characters
  };

  if (rapbackCaseNumber) {
    data[9] = rapbackCaseNumber.substring(0, 20); // CASE NUMBER - 1 to 20 characters
  }

  if (social && social !== '999999999') {
    data[16] = social; // SSN - max length 9, no dashes
  }

  if (aliases?.length) {
    // Aliases - length 3 to 50 - allow commas, hyphens, spaces (MAX 10)
    aliases.slice(0, 10).forEach((alias, idx) => {
      data[`19.${idx + 1}.1`] = alias.substring(0, 50);
    });
  }

  const fullAddress = buildAddress({ address, apartment, city, state, country, postalCode });
  if (fullAddress) {
    data[41] = fullAddress.substring(0, 120); // Address of applicant - 1 to 120 characters
  }

  const [
    leftPinky,
    leftRing,
    leftMiddle,
    leftIndex,
    leftThumb,
    rightPinky,
    rightRing,
    rightMiddle,
    rightIndex,
    rightThumb,
  ] = fingerConfigs;
  let index = 1;
  if (rightThumb.isAmputated || rightThumb.isBandaged) {
    data[`84.${index}.1`] = '01';
    data[`84.${index}.2`] = rightThumb.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (rightIndex.isAmputated || rightIndex.isBandaged) {
    data[`84.${index}.1`] = '02';
    data[`84.${index}.2`] = rightIndex.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (rightMiddle.isAmputated || rightMiddle.isBandaged) {
    data[`84.${index}.1`] = '03';
    data[`84.${index}.2`] = rightMiddle.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (rightRing.isAmputated || rightRing.isBandaged) {
    data[`84.${index}.1`] = '04';
    data[`84.${index}.2`] = rightRing.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (rightPinky.isAmputated || rightPinky.isBandaged) {
    data[`84.${index}.1`] = '05';
    data[`84.${index}.2`] = rightPinky.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (leftThumb.isAmputated || leftThumb.isBandaged) {
    data[`84.${index}.1`] = '06';
    data[`84.${index}.2`] = leftThumb.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (leftIndex.isAmputated || leftIndex.isBandaged) {
    data[`84.${index}.1`] = '07';
    data[`84.${index}.2`] = leftIndex.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (leftMiddle.isAmputated || leftMiddle.isBandaged) {
    data[`84.${index}.1`] = '08';
    data[`84.${index}.2`] = leftMiddle.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (leftRing.isAmputated || leftRing.isBandaged) {
    data[`84.${index}.1`] = '09';
    data[`84.${index}.2`] = leftRing.isAmputated ? 'XX' : 'UP';
    index += 1;
  }
  if (leftPinky.isAmputated || leftPinky.isBandaged) {
    data[`84.${index}.1`] = '10';
    data[`84.${index}.2`] = leftPinky.isAmputated ? 'XX' : 'UP';
  }

  return data;
}

/**
 * @param {Array<Object>} fingers
 */
function buildFingerStats(fingers = []) {
  // FGP - Finger Positions
  // ----------------------
  // 1 - Right Thumb
  // 6 - Left Thumb
  // ----------------------
  // 2 - Right Index
  // 3 - Right Middle
  // 4 - Right Ring
  // 5 - Right Pinky
  // ----------------------
  // 7 - Left Index
  // 8 - Left Middle
  // 9 - Left Ring
  // 10 - Left Pinky

  const results = {};
  fingers.forEach(({ fgp, left, right, top, bottom, quality }, idx) => {
    // Fingerprint coordinates
    results[`21.${idx + 1}.1`] = `${fgp}`;
    results[`21.${idx + 1}.2`] = `${left}`;
    results[`21.${idx + 1}.3`] = `${right}`;
    results[`21.${idx + 1}.4`] = `${top}`;
    results[`21.${idx + 1}.5`] = `${bottom}`;

    // Quality Score
    results[`22.${idx + 1}.1`] = `${fgp}`;
    results[`22.${idx + 1}.2`] = `${quality}`;
  });
  return results;
}

/**
 * Builds EFT data from the provided finger configurations and images.
 *
 * @param {Object} device - The selected fingerprint device
 * @param {string} rapbackCaseNumber - Existing rapback case number
 * @param {Object} badgeHolderData - Badge holder data
 * @param {Array<Object>} fingerConfigs - Array of finger configuration objects
 * @param {string} leftFourImage - Base64 string of the left four fingers image
 * @param {Array<Object>} leftFourStats - Left four fingers image stats
 * @param {string} thumbsImage - Base64 string of the thumbs image
 * @param {Array<Object>} thumbsStats - Thumbs image stats
 * @param {string} rightFourImage - Base64 string of the right four fingers image
 * @param {Array<Object>} rightFourStats - Right four fingers image stats
 *
 * @returns {Object} The constructed EFT data.
 */
export function buildEftData({
  airportSON,
  device,
  rapbackCaseNumber,
  badgeHolderData,
  fingerConfigs,
  leftFourImage,
  leftFourStats,
  thumbsImage,
  thumbsStats,
  rightFourImage,
  rightFourStats,
}) {
  const { deviceName, deviceSerial, vendorName } = device;
  const fingerprintDate = new Date().toISOString().split('T')[0].replace(/-/g, '');

  // Generate a random, but unique, transaction control number
  let transactionControlNumber = uuid().replace(/-/g, '');
  transactionControlNumber = `AB-${transactionControlNumber}`;

  let records = [
    {
      type: 'TYPE_01',
      idx: 1,
      fields: {
        2: '0400', // ???
        4: 'NFUF', // Type of Transaction - (Non-Federal Applicant User Fee)
        5: fingerprintDate, // DATE FINGERPRINTED - YYYYMMDD - 8 digits
        6: '4', // ???
        7: 'WVIAFIS0Z', // Destination Agency Identifier
        8: 'USTSA0NFZ', // Originating Agency Identifier (SOURCE AGENCY/ORI)
        9: transactionControlNumber, // Transaction Control Number - 10 to 40 characters
        11: '00.00', // Native Scanning Resolution
        12: '00.00', // Nominal Transmitting Resolution
        '13.1.1': 'NORAM',
        '13.1.2': 'EBTS 10.0',
      },
    },
    {
      type: 'TYPE_02',
      idx: 1,
      fields: {
        ...buildType02Fields({ airportSON, rapbackCaseNumber, badgeHolderData, fingerprintDate, fingerConfigs }),
        '67.1.1': vendorName,
        '67.1.2': deviceName,
        '67.1.3': deviceSerial,
      },
    },
    {
      type: 'TYPE_14',
      idx: 1,
      img: rightFourImage,
      fields: {
        3: '01', // '3.1.2': '1',
        4: 'USTSA0NFZ', // Originating Agency Identifier (SOURCE AGENCY/ORI)
        5: fingerprintDate, // DATE FINGERPRINTED - YYYYMMDD - 8 digits
        8: '1',
        9: '500',
        10: '500',
        11: 'WSQ20', // Compression algorithm
        13: '13', // FGP = plain right four fingers
        ...buildFingerStats(rightFourStats),
      },
    },
    {
      type: 'TYPE_14',
      idx: 2,
      img: leftFourImage,
      fields: {
        3: '01', // '3.1.2': '1',
        4: 'USTSA0NFZ', // Originating Agency Identifier (SOURCE AGENCY/ORI)
        5: fingerprintDate, // DATE FINGERPRINTED - YYYYMMDD - 8 digits
        8: '1',
        9: '500',
        10: '500',
        11: 'WSQ20', // Compression algorithm
        13: '14', // FGP = plain left four fingers
        ...buildFingerStats(leftFourStats),
      },
    },
    {
      type: 'TYPE_14',
      idx: 3,
      img: thumbsImage,
      fields: {
        3: '01', // '3.1.2': '1',
        4: 'USTSA0NFZ', // Originating Agency Identifier (SOURCE AGENCY/ORI)
        5: fingerprintDate, // DATE FINGERPRINTED - YYYYMMDD - 8 digits
        8: '1',
        9: '500',
        10: '500',
        11: 'WSQ20', // Compression algorithm
        13: '15', // FGP = plain left and right thumbs
        ...buildFingerStats(thumbsStats),
      },
    },
  ];

  return {
    transaction: {
      idcPadded: true,
      records,
    },
  };
}
