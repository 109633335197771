import React, { useContext, useEffect, useRef, useState } from 'react';
import Cropper from 'cropperjs';
import { Button, ButtonGroup } from 'reactstrap';
import AirBadgeModal, { MODAL_SIZE_EXTRA_LARGE } from '../components/AirBadgeModal/AirBadgeModal';
import { upload } from '../../ajax/Request';
import { handleError } from '../../utils';
import { AppContext } from '../../App';

function ToolbarButton({ icon, label, onClick, buttonColor = 'primary' }) {
  return (
    <Button title={label} onClick={onClick} color={buttonColor}>
      <i className={`${icon} ml-1 mr-1`}></i>
    </Button>
  );
}

export function CropperToolbar({ cropper, buttonColor = 'primary' }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 16,
      }}
    >
      <div style={{ position: 'relative' }}>
        <ButtonGroup className="mr-3">
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-magnifying-glass-plus"
            label="Zoom In"
            onClick={() => cropper.zoom(0.1)}
          />
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-magnifying-glass-minus"
            label="Zoom Out"
            onClick={() => cropper.zoom(-0.1)}
          />
        </ButtonGroup>
        <div
          style={{
            position: 'absolute',
            left: 30,
            fontStyle: 'italic',
            opacity: 0.8,
          }}
        >
          Zoom
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <ButtonGroup className="mr-3">
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-arrow-rotate-left"
            label="Rotate Left"
            onClick={() => cropper.rotate(-45)}
          />
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-arrow-rotate-right"
            label="Rotate Right"
            onClick={() => cropper.rotate(45)}
          />
        </ButtonGroup>
        <div style={{ position: 'absolute', left: 26, opacity: 0.8 }}>
          <i>Rotate</i>
        </div>
      </div>

      <div style={{ position: 'relative' }}>
        <ButtonGroup>
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-arrow-left"
            label="Move Left"
            onClick={() => cropper.move(-10, 0)}
          />
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-arrow-right"
            label="Move Left"
            onClick={() => cropper.move(10, 0)}
          />
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-arrow-up"
            label="Move Left"
            onClick={() => cropper.move(0, -10)}
          />
          <ToolbarButton
            buttonColor={buttonColor}
            icon="fa-solid fa-arrow-down"
            label="Move Left"
            onClick={() => cropper.move(0, 10)}
          />
        </ButtonGroup>
        <div style={{ position: 'absolute', left: 72, opacity: 0.8 }}>
          <i>Move</i>
        </div>
      </div>

      <Button onClick={() => cropper.reset()} className="ml-4" color={buttonColor}>
        <i className="fa-solid fa-clock-rotate-left mr-2"></i>
        Reset
      </Button>
    </div>
  );
}

export default function ImageEditor({ isOpen, onClose, file, imageDataUrl }) {
  const app = useContext(AppContext);
  const badgeImageRef = useRef();
  const [cropper, setCropper] = useState(null);

  const onModalClose = () => {
    setCropper(null);
    onClose({ imageDataUrl: null });
  };

  const onSave = () => {
    const croppedCanvas = cropper.getCroppedCanvas();
    const imageDataUrl = croppedCanvas.toDataURL('image/jpeg');
    croppedCanvas.toBlob(blob => {
      app.api.toggleLoading(true);
      const fileObj = new File([blob], file.name, { type: file.mimeType });
      upload('authenticated-user', 'POST', `files/${file.uuid}/replace`, fileObj, { uuid: file.uuid })
        .then(({ success, newFile }) => {
          if (success) {
            setCropper(null);
            onClose({ imageDataUrl, newFile: { ...file, ...newFile } });
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }, file.mimeType);
  };

  const renderImage = () => {
    setCropper(null);
    if (badgeImageRef.current) {
      const imageEditorImage = document.getElementById('imageEditorImage');
      imageEditorImage.src = imageDataUrl;
      const cropper = new Cropper(imageEditorImage, {
        viewMode: 1,
        autoCropArea: 0.5,
        zoomOnWheel: false,
      });
      setCropper(cropper);
    }
  };

  useEffect(() => {
    if (!isOpen || !imageDataUrl) return;
    const domTimer = setTimeout(() => {
      if (badgeImageRef.current) {
        renderImage();
      }
    }, 0);

    return () => {
      clearTimeout(domTimer);
    };
  }, [isOpen, imageDataUrl]);

  return (
    <AirBadgeModal
      size={MODAL_SIZE_EXTRA_LARGE}
      isOpen={isOpen}
      onClose={onModalClose}
      title="Image Editor"
      onSave={onSave}
      saveLabel="Save Cropped Image"
    >
      <div>
        <img ref={badgeImageRef} id="imageEditorImage" src="" alt="Image" style={{ width: 1106, height: 800 }} />
      </div>
      <div style={{ width: '100%', marginTop: 16, marginBottom: 40 }}>
        <CropperToolbar cropper={cropper} />
      </div>
    </AirBadgeModal>
  );
}
