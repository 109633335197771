import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';
import BackButton from '../components/BackButton';
import request from '../../../ajax/Request';
import { AppContext } from '../../../App';
import { handleError } from '../../../utils';
import DocSpringDocument from '../../DefaultWorkflow/DocSpringDocument/DocSpringDocument';
import BadgeApplicationPaymentForm from '../../DefaultWorkflow/Home/BadgeApplicationsList/components/BadgeApplicationPaymentForm';
import { hasFeature } from '../../../shared/Feature';

function DataDisplay({ label, value }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px 0' }}>
      <Typography variant="body2" style={{ fontStyle: 'italic', opacity: 0.7 }}>
        {label}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </div>
  );
}

function buildFullLegalName(data) {
  if (!data) return 'missing';
  const { first, last, middle, suffix } = data;
  let name = '';
  if (first) name = `${first} `;
  if (middle) name = `${name}${middle} `;
  if (last) name = `${name}${last}`;
  if (suffix) name = `${name}, ${suffix}`;
  return name;
}

function getHairColor(hairColor) {
  return [
    { label: 'Red', value: 'RED' },
    { label: 'White', value: 'WHI' },
    {
      label: 'Sandy',
      value: 'SDY',
    },
    { label: 'Black', value: 'BLK' },
    { label: 'Blonde', value: 'BLN' },
    {
      label: 'Gray',
      value: 'GRY',
    },
    { label: 'Brown', value: 'BRO' },
    { label: 'Green', value: 'GRN' },
    {
      label: 'Blue',
      value: 'BLU',
    },
    { label: 'Purple', value: 'PLE' },
    { label: 'Orange', value: 'ONG' },
    {
      label: 'Pink',
      value: 'PNK',
    },
    { label: 'Unknown', value: 'XXX' },
  ].find(x => x.value === hairColor).label;
}

function getEyeColor(eyeColor) {
  return [
    { label: 'Brown', value: 'BRO' },
    { label: 'Blue', value: 'BLU' },
    {
      label: 'Black',
      value: 'BLK',
    },
    { label: 'Gray', value: 'GRY' },
    { label: 'Green', value: 'GRN' },
    {
      label: 'Hazel',
      value: 'HAZ',
    },
    { label: 'Maroon', value: 'MAR' },
    { label: 'Pink', value: 'PNK' },
    {
      label: 'Multicolored',
      value: 'MUL',
    },
    { label: 'Unknown', value: 'XXX' },
  ].find(x => x.value === eyeColor).label;
}

function getPhoneNumberType(type) {
  return [
    { label: 'Home', value: 'H' },
    { label: 'Work', value: 'B' },
    {
      label: 'Mobile',
      value: 'M',
    },
  ].find(x => x.value === type).label;
}

function getPlaceOfBirth({ city, state, country }) {
  return `${city || state}, ${country.threeCharacterCode}`;
}

function getAddress({ street1, street2, city, state, zip }) {
  let address = '';
  if (street1) address = `${street1}, `;
  if (street2) address = `${address}${street2}, `;
  if (city) address = `${address}${city}, `;
  if (state) address = `${address}${state} `;
  if (zip) address = `${address}${zip}`;
  return address;
}

function buildPhoneNumbersDisplay(phoneNumbers = []) {
  const text = phoneNumbers.map(x => `${x.number} (${getPhoneNumberType(x.type)})`).join(', ');
  if (phoneNumbers.length > 1) {
    return <span style={{ fontSize: 'smaller' }}>{text}</span>;
  }
  return text;
}

export default function Finalize({ isHidden, info, onBack, badgeApplicationData }) {
  const app = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });
  const [transactionUuid, setTransactionUuid] = useState(null);
  const [arnFromDocuments, setArnFromDocuments] = useState('');
  const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);

  const {
    name,
    aliases = [],
    gender,
    height,
    heightFeet,
    heightInches,
    weight,
    eyeColor,
    hairColor,
    placeOfBirth,
    arn,
    dateOfBirth,
    social,
    phoneNumber,
    phoneNumbers = [],
    phoneNumberType,
    citizenshipCountryRaw,
    mailingAddress,
    email,
  } = badgeApplicationData;

  const onPaymentFormClose = () => {
    const returnTo = sessionStorage.getItem('AirBadge.BadgeApplications.returnTo');
    let url = '/';
    switch (returnTo) {
      case 'people-grid':
        url = '/default/people';
        break;
      case 'applications-grid':
        url = '/default/badge-applications';
        break;
      default:
        url = '/';
    }
    history.replace(url);
  };

  const onApplicationSigned = () => {
    setIsPaymentFormOpen(true);
  };

  const getDocumentInfo = () => {
    app.api.toggleLoading(true);
    request(null, 'POST', `badger/badge-application-document`, { key: info.key })
      .then(({ success, transaction }) => {
        if (success) {
          setTransactionUuid(transaction);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onCancelled = () => {
    setTransactionUuid(null);
  };

  const onNextClicked = () => {
    app.api.toggleLoading(true);
    request(null, 'POST', `badger/save-data`, { key: info.key, data: badgeApplicationData })
      .then(({ success }) => {
        if (success) {
          getDocumentInfo();
        }
      })
      .catch(error => {
        handleError({ error });
        app.api.toggleLoading(false);
      });
  };

  useEffect(() => {
    setArnFromDocuments('');
    if (isHidden) return;

    request(null, 'POST', `badger/get-arn-from-documents`, { key: info.key })
      .then(({ success, arn }) => {
        if (success && arn) setArnFromDocuments(arn);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  }, [isHidden]);

  if (isHidden) return null;

  return (
    <>
      <BlockFormLabel>
        <Typography variant="h6">{t('finalize.statement1')}</Typography>
      </BlockFormLabel>
      <BlockFormLabel>
        <Typography variant="h6">{t('finalize.summary_statement')}</Typography>
      </BlockFormLabel>

      <div style={{ margin: '32px 0' }}>
        <div className="separator"></div>

        <Row>
          <Col xs="12" md="6" lg="4">
            <DataDisplay label={t('finalize.review.full_legal_name')} value={buildFullLegalName(name)} />
          </Col>
          {aliases.map((x, i) => (
            <Col xs="12" md="6" lg="4" key={x.unique || i}>
              <DataDisplay label={t('finalize.review.alias')} value={buildFullLegalName(x)} />
            </Col>
          ))}
        </Row>

        <div className="separator"></div>

        <Row>
          <Col xs="12" md="6" lg="2">
            <DataDisplay
              label={t('finalize.review.gender')}
              value={gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : 'Other'}
            />
          </Col>
          <Col xs="12" md="6" lg="2">
            <DataDisplay
              label={t('finalize.review.height')}
              value={height !== '000' ? `${heightFeet}' ${heightInches}"` : '-'}
            />
          </Col>
          <Col xs="12" md="6" lg="2">
            <DataDisplay
              label={t('finalize.review.weight')}
              value={weight !== '000' ? `${parseInt(weight, 10)} lbs` : '-'}
            />
          </Col>
          <Col xs="12" md="6" lg="2">
            <DataDisplay label={t('finalize.review.hair_color')} value={getHairColor(hairColor)} />
          </Col>
          <Col xs="12" md="6" lg="2">
            <DataDisplay label={t('finalize.review.eye_color')} value={getEyeColor(eyeColor)} />
          </Col>
        </Row>

        <div className="separator"></div>

        <Row>
          {placeOfBirth.country !== 'USA' ? (
            <>
              <Col xs="12" md="6" lg="3">
                <DataDisplay label={t('finalize.review.date_of_birth')} value={dateOfBirth} />
              </Col>
              <Col xs="12" md="6" lg="3">
                <DataDisplay label={t('finalize.review.arn')} value={arnFromDocuments || arn || '-'} />
              </Col>
              <Col xs="12" md="6" lg="3">
                <DataDisplay label={t('finalize.review.place_of_birth')} value={getPlaceOfBirth(placeOfBirth)} />
              </Col>
              <Col xs="12" md="6" lg="3">
                <DataDisplay label={t('finalize.review.citizenships')} value={citizenshipCountryRaw.join(', ')} />
              </Col>
            </>
          ) : (
            <>
              <Col xs="12" md="6" lg="4">
                <DataDisplay label={t('finalize.review.date_of_birth')} value={dateOfBirth} />
              </Col>
              <Col xs="12" md="6" lg="4">
                <DataDisplay label={t('finalize.review.place_of_birth')} value={getPlaceOfBirth(placeOfBirth)} />
              </Col>
              <Col xs="12" md="6" lg="4">
                <DataDisplay label={t('finalize.review.citizenships')} value={citizenshipCountryRaw.join(', ')} />
              </Col>
            </>
          )}
        </Row>

        <div className="separator"></div>

        <Row>
          <Col xs="12" md="6" lg="4">
            <DataDisplay label={t('finalize.review.ssn')} value={social || '-'} />
          </Col>
          <Col xs="12" md="6" lg="4">
            {!!phoneNumber && (
              <DataDisplay
                label={t('finalize.review.phone')}
                value={`${phoneNumber} (${getPhoneNumberType(phoneNumberType)})`}
              />
            )}
            {!!phoneNumbers.length && (
              <DataDisplay label={t('finalize.review.phone')} value={buildPhoneNumbersDisplay(phoneNumbers)} />
            )}
          </Col>
          <Col xs="12" md="6" lg="4">
            <DataDisplay label={t('finalize.review.address')} value={getAddress(mailingAddress)} />
          </Col>
        </Row>

        <div className="separator"></div>

        <Row>
          <Col xs="12" md="6" lg="6">
            <DataDisplay label={t('finalize.review.email')} value={email || '-'} />
          </Col>
        </Row>

        <div className="separator"></div>
      </div>

      <BlockFormLabel>
        <Typography variant="h6">{t('finalize.statement2')}</Typography>
      </BlockFormLabel>

      <BackButton isHidden={false} onClick={onBack} />
      <NextButton isHidden={false} onClick={onNextClicked} label="sign-application" />

      <BadgeApplicationPaymentForm
        isOpen={isPaymentFormOpen}
        onClose={onPaymentFormClose}
        badgeApplicationUuid={info?.badgeApplicationUuid}
      />

      {hasFeature('payments', app?.airport?.config?.features || []) &&
      info?.paymentChoice === 'applicant' &&
      info?.isPaid === false ? (
        <DocSpringDocument
          transactionUuid={transactionUuid}
          badgerKey={info.key}
          onCancelled={onCancelled}
          onSubmitSuccess={onApplicationSigned}
        />
      ) : (
        <DocSpringDocument transactionUuid={transactionUuid} badgerKey={info.key} onCancelled={onCancelled} />
      )}
    </>
  );
}
