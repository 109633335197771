import request from '../Request';

export default function AccountRecoverPasswordRequest(email) {
  const method = 'POST';

  const url = 'account/recover-password';

  const data = {
    email,
  };

  return request('', method, url, data);
}
