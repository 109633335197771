import React from 'react';
import { colors } from '../../../App';

/**
 * https://fontawesome.com/v5/search
 */
export default function FontAwesomeIcon(props) {
  const { color = colors.primary, style = {} } = props;
  return <i {...props} style={{ fontSize: '1.5rem', paddingLeft: 3, color, ...style }} />;
}
