import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { googleLogout } from '@react-oauth/google';
import moment from 'moment';
import 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import AppConfigurationRequest from './ajax/App/AppConfigurationRequest';
import applicationApi from './ajax/App/applicationApi';
import DefaultLayout from './containers/DefaultLayout';
import QuickAuditLayout from './containers/QuickAuditLayout';
import EnvironmentLabel from './EnvironmentLabel';
import './scss/style.scss';
import { handleError, notify } from './utils';
import Badger from './views/Badger/Badger';
import Enrollment from './views/Pages/Enrollment/Enrollment';
import ForgotPassword from './views/Pages/ForgotPassword/ForgotPassword';
import Login from './views/Pages/Login/Login';
import Logout from './views/Pages/Logout/Logout';
import NewUser from './views/Pages/NewUser/NewUser';
import Page403 from './views/Pages/Page403/Page403';
import Page404 from './views/Pages/Page404/Page404';
import Page500 from './views/Pages/Page500/Page500';
import RedirectTo from './views/Pages/RedirectTo/RedirectTo';
import ResetPasswordPage from './views/Pages/ResetPassword/ResetPasswordPage';
import ImpersonateUser from './views/Pages/SecureLogin/ImpersonateUser';
import VerifyChangeEmailPage from './views/Pages/VerifyChangeEmail/VerifyChangeEmailPage';
import DynamsoftTest from './views/Prototype/DynamsoftTest';
import DocSpringTest from './views/Prototype/DocSpringTest';
import { hasFeature } from './shared/Feature';
import ActionItemsSummaryModal from './shared/components/ActionItems/ActionItemSummaryModal';
import KioskPage from './views/Kiosk/KioskPage';
import packageInfo from '../package.json';
import VersionOutOfDateModal from './shared/VersionOutOfDateModal';
import GoogleLoginPage from './views/GoogleLogin/GoogleLogin';

export const CLIENT_SIDE_VERSION = packageInfo?.version;

export const appSettings = {
  bigLogoStyle: { height: 150, width: 150 },
};

export const colors = {
  primary: '#035c86',
  secondary: '#e3e9ed',
  success: '#4dbd74',
  danger: '#9b0000',
  warning: '#ffc107',
};

export const learningManagementSystems = [
  { label: 'IET', value: 'iet' },
  {
    label: 'AirBadge LMS',
    value: 'airbadge_lms',
  },
  { label: 'SSI', value: 'ssi' },
  { label: 'DIGICAST', value: 'digicast' },
];

// TODO: remove all unused statuses from this list
export const badgeStatuses = [
  { label: 'In Use', value: 'in_use' },
  { label: 'Lost', value: 'lost' },
  {
    label: 'Stolen',
    value: 'stolen',
  },
  { label: 'Unaccounted for', value: 'unaccounted for' },
  {
    label: 'Destroyed',
    value: 'destroyed',
  },
  { label: 'Flagged', value: 'flagged' },
  { label: 'Revoked', value: 'revoked' },
  {
    label: 'Terminated Employment',
    value: 'terminated',
  },
  { label: 'Surrendered', value: 'surrendered' },
  { label: 'Suspended', value: 'suspended' },
  {
    label: 'Misprint',
    value: 'misprint',
  },
  { label: 'Pending', value: 'pending' },
  { label: 'Reactivated Other', value: 'reactivated' },
  {
    label: 'Expired',
    value: 'expired',
  },
  { label: 'In Renewal', value: 'in_renewal' },
  {
    label: 'Authorized For Renewal',
    value: 'authorized_for_renewal',
  },
  { label: 'Renewal Prohibited', value: 'renewal_prohibited' },
  { label: 'Not Issued', value: 'not_issued' },
];

export const basePaymentChoices = [
  { label: 'Signatory', value: 'signatory' },
  {
    label: 'Applicant',
    value: 'applicant',
  },
  { label: 'Pay In Person', value: 'in-person' },
];
export const noChargePaymentChoice = { label: 'No Charge', value: 'no-charge' };
export const invoicePaymentChoice = { label: 'Invoice Company', value: 'invoice' };

export const PUSH_NOTIFICATION_KEY = process.env.REACT_APP_PUSHER_KEY;

export const AppContext = React.createContext();
const defaultContext = {
  badgeStatuses,
};

// 900000 milliseconds = 15 minutes
// 3600000 milliseconds = 1 hour
// 14400000 milliseconds = 4 hours
const Max_Milliseconds_Till_Logout = 14400000;
const Max_Milliseconds_Till_BadgeHolder_Logout = 3600000;
const Max_Milliseconds_Till_Kiosk_Logout = 900000;
let logoutTimer;

class App extends Component {
  state = {
    version: '',
    isVersionOutOfDateModalOpen: false,
    instanceUuid: uuid(),
    language: 'en',
    workflow: 'default',
    environment: 'local',
    timezone: 'America/Los_Angeles',
    user: this.props.user || {
      authToken: null,
      uuid: null,
      firstName: null,
      lastName: null,
      email: null,
      profiles: null,
      activeProfile: null,
      managerForCompanies: null,
    },
    loading: false,
    loadingTarget: null,
    messageContent: '',
    messageBrand: 'info',

    confirmContent: '',
    confirmTitle: '',
    confirmCallbackOnConfirm: undefined,
    confirmCallbackOnClose: undefined,
    confirmIsDangerous: false,
    confirmOkBtnText: 'OK',
    cancelBtnText: 'Cancel',

    primaryColor: colors.primary,
    secondaryColor: colors.secondary,
    successColor: colors.success,
    dangerColor: colors.danger,
    warningColor: colors.warning,
    badgeStatuses: defaultContext.badgeStatuses,
    counts: {},
    pushNotificationsClient: null,
    airport: null,
    isActionItemsModalOpen: false,
  };

  api = {
    updateState: newState => {
      this.setState(newState, () => {
        if (newState.user && newState.user.authToken) {
          this.initializePushNotifications(this.state);
          this.startLogoutTimer(!newState.user.profiles?.length, newState.user.isKiosk);
        }
      });
    },
    notify: props => {
      console.debug('app.api.notify(...) is deprecated');
      // TODO: stop using this old function!
      return notify(props);
    },
    displayMessage: (message, brand = 'info') => {
      console.debug('app.api.displayMessage(...) is deprecated');
      // TODO: stop using this old function!
      let adjustedDuration = 2000;
      let toastFn;
      switch (brand) {
        case 'info':
          toastFn = toast.info;
          break;
        case 'warning':
          toastFn = toast.warn;
          adjustedDuration = 5000;
          break;
        case 'danger':
        case 'error':
          toastFn = toast.error;
          adjustedDuration = 5000;
          break;
        case 'success':
          toastFn = toast.success;
          break;
        default:
          toastFn = toast;
      }
      toastFn(message, { autoClose: adjustedDuration, position: 'top-center' });
    },
    confirmMessage: (title, message, onConfirm, onClose, isDangerous, okBtnText, cancelBtnText) => {
      this.setState({
        confirmTitle: title,
        confirmContent: message,
        confirmCallbackOnConfirm: onConfirm,
        confirmCallbackOnClose: onClose,
        confirmIsDangerous: isDangerous,
        confirmOkBtnText: okBtnText,
        cancelBtnText,
      });
    },
    toggleLoading: (show, target = null) => {
      this.setState({ loading: show, loadingTarget: target });
    },
  };

  initializePushNotifications({ user, airport }) {
    if (this.state.pushNotificationsClient) {
      // We don't want to re-create the client if it already exists
      return;
    }

    // eslint-disable-next-line no-undef
    const pusher = new Pusher(PUSH_NOTIFICATION_KEY, {
      cluster: 'us3',
      userAuthentication: {
        endpoint: '/api.php/default/v1/account/authenticate-notifications-subscription',
        transport: 'ajax',
        params: {},
        headers: {
          'X-Auth-Token': user.authToken,
          'X-PROFILE-UUID': user.activeProfile ? user.activeProfile.uuid : null,
        },
        paramsProvider: null,
        headersProvider: null,
        customHandler: null,
      },
      channelAuthorization: {
        endpoint: '/api.php/default/v1/account/authenticate-notifications-channel',
        transport: 'ajax',
        params: {},
        headers: {
          'X-Auth-Token': user.authToken,
          'X-PROFILE-UUID': user.activeProfile ? user.activeProfile.uuid : null,
        },
        paramsProvider: null,
        headersProvider: null,
        customHandler: null,
      },
    });
    pusher.signin();
    pusher.connection.bind('error', error => handleError({ error }));

    this.setState({ pushNotificationsClient: pusher });

    const globalChannel = pusher.subscribe(`${airport.abbreviation}-global`);
    globalChannel.bind('messages', ({ message, severity }) => {
      notify({ message, severity: severity || 'info' });
    });
    globalChannel.bind('counts', ({ content }) => {
      const counts = JSON.parse(content);
      this.updateCounts(counts);
    });

    const personalChannel = pusher.subscribe(`private-${user.uuid}`);
    personalChannel.bind('alerts', ({ message, severity, content }) => {
      notify({ message, severity: severity || 'info' });
      if (content) {
        const parsed = JSON.parse(content);
        if (parsed?.shouldReloadCounts === true) {
          this.updateCounts();
        }
      }
    });
    personalChannel.bind('reload-counts', () => this.updateCounts());

    this.updateCounts();
  }

  async updateCounts(counts) {
    if (!counts) {
      const response = await applicationApi.getCounts();
      counts = response?.counts;
    }

    // First, check to see what has changed because we don't
    // want to cause unnecessary re-renders
    const updated = { ...this.state.counts };

    if (
      counts.activeActionItems !== null &&
      counts.activeActionItems !== undefined &&
      counts.activeActionItems !== this.state.counts.activeActionItems
    ) {
      updated.activeActionItems = counts.activeActionItems;
    }

    if (
      counts.activeAuditsCount !== null &&
      counts.activeAuditsCount !== undefined &&
      counts.activeAuditsCount !== this.state.counts.activeAuditsCount
    ) {
      updated.activeAuditsCount = counts.activeAuditsCount;
    }

    if (
      counts.activeAuditIssuesCount !== null &&
      counts.activeAuditIssuesCount !== undefined &&
      counts.activeAuditIssuesCount !== this.state.counts.activeAuditIssuesCount
    ) {
      updated.activeAuditIssuesCount = counts.activeAuditIssuesCount;
    }

    if (
      counts.activeAssignedFormsCount !== null &&
      counts.activeAssignedFormsCount !== undefined &&
      counts.activeAssignedFormsCount !== this.state.counts.activeAssignedFormsCount
    ) {
      updated.activeAssignedFormsCount = counts.activeAssignedFormsCount;
    }

    this.setState({ counts: updated });
  }

  startLogoutTimer(isNormalBadgeHolder, isKioskUser) {
    let bc = null;
    if (window.BroadcastChannel) {
      bc = new BroadcastChannel('airbadge_activity_channel');
    }

    clearTimeout(logoutTimer);
    const timeout = isKioskUser
      ? Max_Milliseconds_Till_Kiosk_Logout
      : isNormalBadgeHolder
        ? Max_Milliseconds_Till_BadgeHolder_Logout
        : Max_Milliseconds_Till_Logout;
    logoutTimer = setTimeout(this.forceLogout.bind(this), timeout);

    bc && !bc.closed && bc.postMessage(`event:user_active|from:${this.state.instanceUuid}`);
    if (bc) {
      bc.onmessage = ({ data }) => {
        if (!data) return;
        const parts = data.split('|');
        let event = '';
        let from = '';
        parts.forEach(part => {
          const [label, value] = part.split(':');
          if (label === 'event') event = value;
          if (label === 'from') from = value;
        });

        if (event === 'user_active' && from !== this.state.instanceUuid) {
          clearTimeout(logoutTimer);
          logoutTimer = setTimeout(this.forceLogout.bind(this), timeout);
        } else if (event === 'user_logout' && from !== this.state.instanceUuid) {
          this.forceLogout();
        }
      };
    }

    window.addEventListener('mousemove', this.resetLogoutTimer.bind(this));
    window.addEventListener('keydown', this.resetLogoutTimer.bind(this));
    window.addEventListener('scroll', this.resetLogoutTimer.bind(this));
    window.addEventListener('mousedown', this.resetLogoutTimer.bind(this));
    window.addEventListener('touchstart', this.resetLogoutTimer.bind(this));
  }

  resetLogoutTimer() {
    const timeout = !this.state?.user?.profiles?.length
      ? Max_Milliseconds_Till_BadgeHolder_Logout
      : Max_Milliseconds_Till_Logout;

    let bc = null;
    if (window.BroadcastChannel) {
      bc = new BroadcastChannel('airbadge_activity_channel');
    }
    bc && !bc.closed && bc.postMessage(`event:user_active|from:${this.state.instanceUuid}`);

    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(this.forceLogout.bind(this), timeout);
  }

  forceLogout() {
    // accountApi.logout().catch(error => handleError({ error }));

    const isKioskUser = this.state?.user?.isKiosk;

    if (this.state.pushNotificationsClient) {
      this.state.pushNotificationsClient.disconnect();
    }

    localStorage.removeItem('AirportBadges.user');

    this.api.updateState({
      enrollment: false,
      user: {
        authToken: null,
        uuid: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        profiles: null,
        activeProfile: null,
        managerForCompanies: null,
      },
      pushNotificationsClient: null,
    });

    if (hasFeature('byo_auth')) {
      googleLogout();
    }

    clearTimeout(logoutTimer);

    let bc = null;
    if (window.BroadcastChannel) {
      bc = new BroadcastChannel('airbadge_activity_channel');
    }
    bc && !bc.closed && bc.postMessage(`event:user_logout|from:${this.state.instanceUuid}`);

    window.removeEventListener('mousemove', this.resetLogoutTimer.bind(this));
    window.removeEventListener('keydown', this.resetLogoutTimer.bind(this));
    window.removeEventListener('scroll', this.resetLogoutTimer.bind(this));
    window.removeEventListener('mousedown', this.resetLogoutTimer.bind(this));
    window.removeEventListener('touchstart', this.resetLogoutTimer.bind(this));

    if (isKioskUser) {
      window.location.href = '/#/kiosk';
    } else {
      window.location.href = '/#/logout';
    }
  }

  componentWillMount() {
    // TODO: remove this when we add a language selector
    if (this.props.i18n) {
      this.props.i18n.changeLanguage('en');
    }

    const user = this.props.user;
    if (user) {
      this.setState({ user });
    }

    AppConfigurationRequest().then(configuration => {
      if (configuration) {
        const { version, environment, airport, isMaintenanceMode } = configuration;

        if (airport?.abbreviation) {
          document.title = `AirBadge - ${airport.abbreviation}`;
        } else {
          document.title = 'AirBadge';
        }

        const timezone = configuration.timezone || 'America/Los_Angeles';
        const maxUploadSize = configuration.maxUploadSize || 8;
        this.setState({
          version,
          isMaintenanceMode,
          environment: environment || 'production',
          timezone,
          maxUploadSize,
          airport,
        });

        moment.tz.setDefault(timezone);

        if (user && user.authToken && !this.state.pushNotificationsClient) {
          const isKioskUser = user.isKiosk;
          if (isKioskUser) {
            this.setState({ environment: 'kiosk' });
          }
          this.initializePushNotifications({ user, airport });
          this.startLogoutTimer(!user.profiles?.length, isKioskUser);
        }

        if (version !== CLIENT_SIDE_VERSION) {
          this.setState({ isVersionOutOfDateModalOpen: true });
        }
      }
    });
  }

  showActionItemsSummaryModal() {
    this.setState({ isActionItemsModalOpen: true });
  }

  closeActionItemsSummaryModal() {
    this.setState({ isActionItemsModalOpen: false });
  }

  render() {
    const props = {
      state: this.state,
      api: this.api,
    };
    const user = this.state.user.authToken ? this.state.user : this.props.user;

    return (
      <AppContext.Provider
        value={{
          ...defaultContext,
          ...this.state,
          api: this.api,
          forceLogout: this.forceLogout.bind(this),
          showActionItemsSummaryModal: this.showActionItemsSummaryModal.bind(this),
        }}
      >
        <EnvironmentLabel environment={this.state.environment} />
        <HashRouter>
          <Switch>
            <Route exact path="/dynamsoft-test" name="DynamsoftTest" render={() => <DynamsoftTest />} />
            <Route exact path="/docspring-test" name="DocSpringTest" render={() => <DocSpringTest />} />
            <Route
              exact
              path="/badger"
              name="Badger"
              render={routeProps => <Badger {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/login"
              name="Login"
              render={routeProps => <Login {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/admin-bypass"
              name="ImpersonateUser"
              render={routeProps => <ImpersonateUser {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/enrollment"
              name="Enrollment"
              render={routeProps => <Enrollment {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/logout"
              name="Logout"
              render={routeProps => <Logout {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/redirect"
              name="Redirect"
              render={routeProps => <RedirectTo {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={routeProps => <ForgotPassword {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/reset-password"
              name="Reset Password"
              render={routeProps => <ResetPasswordPage {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/new-user"
              name="New User"
              render={routeProps => <NewUser {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/register"
              name="Register"
              render={routeProps => <ResetPasswordPage register {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/verify-change-email"
              name="Verify Change Email"
              render={routeProps => <VerifyChangeEmailPage {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/403"
              name="Page 403"
              render={routeProps => <Page403 {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={routeProps => <Page404 {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={routeProps => <Page500 {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/default/quick-audits/:uuid"
              name="Quick Audit"
              render={routeProps => <QuickAuditLayout {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/kiosk"
              name="Kiosk"
              render={routeProps => <KioskPage {...Object.assign(routeProps, props)} />}
            />
            <Route
              exact
              path="/google"
              name="Google Login"
              render={routeProps => <GoogleLoginPage {...Object.assign(routeProps, props)} />}
            />
            {!user || !user.authToken ? <Redirect to="/login" /> : ''}
            <Route path="/" name="Home" render={() => <DefaultLayout {...props} />} />
          </Switch>
        </HashRouter>

        <ToastContainer autoClose={2000} />

        <ActionItemsSummaryModal
          isOpen={this.state.isActionItemsModalOpen}
          onClose={this.closeActionItemsSummaryModal.bind(this)}
        />

        <VersionOutOfDateModal
          isOpen={this.state.isVersionOutOfDateModalOpen}
          onClose={() => this.setState({ isVersionOutOfDateModalOpen: false })}
          version={this.state.version}
        />
      </AppContext.Provider>
    );
  }
}

export default withTranslation()(App);
