import React, { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment-timezone';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import InputWithCharacterLimit from '../../../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import appointmentApi from '../../../../../../../ajax/Appointment/appointmentApi';
import { handleError, notify } from '../../../../../../../utils';
import { AppContext } from '../../../../../../../App';

export function CheckedIcon({ fontSize = 24 }) {
  return <i className="fa-solid fa-circle-check text-success" style={{ fontSize }}></i>;
}

export function UncheckedIcon({ fontSize = 24 }) {
  return <i className="fa-regular fa-circle" style={{ fontSize }}></i>;
}

export function DisabledIcon({ fontSize = 24 }) {
  return <i className="fa-solid fa-ban text-danger" style={{ fontSize }}></i>;
}

export function getStepIcon(step) {
  if (!step) {
    return <UncheckedIcon />;
  }
  const { isComplete, isNotNeeded } = step;
  if (isNotNeeded === true) {
    return <DisabledIcon />;
  } else if (isComplete === true) {
    return <CheckedIcon />;
  }
  return <UncheckedIcon />;
}

export function getFormattedDate({ date, timezone }) {
  let value = 'never';
  if (date) value = moment.tz(date, timezone).format('YYYY-MM-DD @ h:mm A');
  return value;
}

export function ThirdPartyChrcDecisionModal({ isOpen, onClose, badgeApplication }) {
  const [choice, setChoice] = useState(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const isSaveDisabled = () => {
    if (choice === 'request' && !message && !subject) return true;
    return !choice;
  };

  useEffect(() => {
    if (isOpen && badgeApplication) {
      const { applicant, signatory, badgeType } = badgeApplication;
      setChoice(null);
      setSubject(`CHRC Documentation Request for ${applicant.fullName}`);
      setMessage(
        `${signatory.fullName},\n\nPlease provide the CHRC documentation for the ${badgeType.displayName} badge application for ${applicant.fullName}.\n\nThank you,\nCredentialing Office`
      );
    }
  }, [isOpen, badgeApplication]);

  if (!isOpen || !badgeApplication) return null;

  return (
    <AirBadgeModal
      isOpen={isOpen}
      size={MODAL_SIZE_MEDIUM}
      title="What would you like to do?"
      onClose={() => onClose({ choice: null, subject: null, message: null })}
      closeLabel="Cancel"
      saveLabel="Proceed"
      onSave={() => onClose({ choice, subject, message })}
      saveDisabled={isSaveDisabled()}
    >
      <RadioGroup value={choice} onChange={event => setChoice(event.target.value)}>
        <FormControlLabel
          value="request"
          control={<Radio color="primary" />}
          label="Ask signatory to provide CHRC documentation"
        />
        <FormControlLabel
          value="provide"
          control={<Radio color="primary" />}
          label="Provide the CHRC documentation myself"
        />
      </RadioGroup>

      {choice === 'request' && (
        <div>
          <div className="separator mt-3 mb-3">Message To Signatory</div>
          <FormGroup>
            <Label>Subject</Label>
            <Input value={subject} onChange={event => setSubject(event.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label>Message</Label>
            <InputWithCharacterLimit
              limit="255"
              value={message}
              onChange={value => setMessage(value)}
              style={{ height: 200 }}
            />
          </FormGroup>
        </div>
      )}
    </AirBadgeModal>
  );
}

export function CancelFingerprintAppointmentPrompt({ isOpen, onClose, badgeApplication }) {
  const app = useContext(AppContext);

  const onSave = () => {
    app.api.toggleLoading(true);
    appointmentApi
      .updateStatus({
        appointmentUuid: badgeApplication?.config?.fingerprintAppointment?.appointmentUuid,
        status: 'cancelled',
        shouldCancelBadgeApplication: false,
      })
      .then(() => {
        notify({ message: 'Appointment cancelled', type: 'success' });
        onClose();
      })
      .catch(error => handleError({ error, message: 'Unable to cancel appointment' }))
      .finally(() => app.api.toggleLoading(false));
  };

  if (!isOpen || !badgeApplication) return null;

  return (
    <AirBadgeModal
      isOpen={isOpen}
      size={MODAL_SIZE_MEDIUM}
      title="Cancel Scheduled Fingerprint Appointment"
      onClose={onClose}
      closeLabel="Cancel"
      saveLabel="Cancel Scheduled Appointment"
      onSave={onSave}
    >
      <p>This applicant has already scheduled a fingerprint appointment.</p>
      <p>The appointment is no longer needed. Would you like to cancel it?</p>
    </AirBadgeModal>
  );
}
