import React from 'react';
import { Label } from 'reactstrap';
import Typography from '@material-ui/core/Typography';

export default function BadgerFormLabel({ children }) {
  return (
    <Label>
      <Typography variant="body1">{children}</Typography>
    </Label>
  );
}
