import React, { useCallback, useContext } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { AppContext, colors } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import ReadOnlyFormData from '../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';

export default function BackgroundCheckStatusAction() {
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);

  const isHidden = useCallback(() => {
    const { active, config } = badgeApplication;
    const { backgroundCheck, approveBackgroundCheckResults, adjudicateCHRC } = config;
    if (!!active === false) {
      // Badge Application is not active
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for doing this
      return true;
    }
    if (!backgroundCheck.isEnabled) {
      return true;
    }
    if (backgroundCheck.isEnabled && !backgroundCheck.isComplete) {
      // Background check has not been started
      return true;
    }
    if (!approveBackgroundCheckResults.isEnabled && !adjudicateCHRC.isEnabled) {
      return true;
    }
    return !(
      (approveBackgroundCheckResults.isEnabled && !approveBackgroundCheckResults.isComplete) ||
      (adjudicateCHRC.isEnabled && !adjudicateCHRC.isComplete)
    );
  }, [badgeApplication, isCurrentUserSignatory]);

  const onClick = () => {
    const { user, api } = app;
    const { uuid: badgeApplicationUuid } = badgeApplication;
    api.toggleLoading(true);
    badgeApplicationApi
      .checkBackgroundCheckStatus({ user, badgeApplicationUuid })
      .then(({ success, status = [] }) => {
        if (success) {
          app.api.confirmMessage(
            'Background Check Status',
            <div style={{ marginTop: 8 }}>
              {status
                .sort((a, b) => a.type.localeCompare(b.type))
                .map((s, i) => (
                  <div key={s.id} style={{ marginBottom: i ? 16 : 40 }}>
                    <div className="separator">{s.type}</div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        padding: '0 16px',
                      }}
                    >
                      <ReadOnlyFormData label={s.type === 'CHRC' ? 'Case Number' : 'ID'} value={s.id || '-'} />
                      <br />
                      <ReadOnlyFormData label="Status" value={(s.status || '-').toLocaleUpperCase()} />
                      <br />
                      <ReadOnlyFormData label="Result" value={(s.result || '-').toLocaleUpperCase()} />
                    </div>
                  </div>
                ))}
            </div>,
            () => onActionDone({ shouldReload: true }),
            false
          );
        }
      })
      .catch(e => console.error(e))
      .finally(() => api.toggleLoading(false));
  };

  if (isHidden()) return null;

  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon>
        <BallotOutlinedIcon style={{ color: colors.primary }} />
      </ListItemIcon>
      <ListItemText primary="Get Background Check Status" />
    </ListItem>
  );
}
