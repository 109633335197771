import React, { useContext, useState } from 'react';
import { Alert } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import peopleApi from '../../../../../../ajax/People/peopleApi';
import { handleError } from '../../../../../../utils';
import { AppContext } from '../../../../../../App';

export default function DeactivateUserModal({ isOpen, onClose, user }) {
  const app = useContext(AppContext);
  const [selection, setSelection] = useState(null);

  const onModalClose = () => {
    setSelection(null);
    onClose();
  };

  const isSaveDisabled = () => {
    return !selection || selection === 'false';
  };

  const onDeactivateUser = () => {
    const { badgeHolderUuid: uuid, badgeHolderName: fullName } = user;
    app.api.confirmMessage(
      'Deactivate Account',
      <div>
        <p>WARNING!</p>
        <p>This will disable the person from logging into AirBadge. No data will be deleted.</p>
        <p>
          Are you sure you want to deactivate the account for <strong>{fullName}</strong>?
        </p>
      </div>,
      () => {
        app.api.toggleLoading(true);
        peopleApi
          .deactivate({ uuid })
          .then(({ success, message }) => {
            if (!success) {
              return handleError({ message: message || `Unable to deactivate account for ${fullName}` });
            }
            onModalClose();
          })
          .catch(error => handleError({ error, message: `Unable to deactivate account for ${fullName}` }))
          .finally(() => app.api.toggleLoading(false));
      },
      null,
      true
    );
  };

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Deactivate Account"
      isOpen={isOpen}
      onClose={onModalClose}
      closeLabel={selection === 'true' ? 'Cancel' : 'Close'}
      onSave={selection === 'true' && onDeactivateUser}
      saveLabel="Deactivate Account"
      saveDisabled={isSaveDisabled()}
      saveColor="danger"
    >
      <Alert color="info">
        <strong>{user.badgeHolderName}</strong> no longer has any active badges
      </Alert>
      <div className="mt-4">Would you like to deactivate their account?</div>
      <RadioGroup
        row
        aria-label="selection"
        name="selection"
        value={selection}
        onChange={event => setSelection(event?.target?.value)}
      >
        <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
        <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
      </RadioGroup>
    </AirBadgeModal>
  );
}
