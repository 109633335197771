import React from 'react';

const containerStyle = {
  position: 'absolute',
  top: -6,
  left: -26,
  zIndex: 1000,
  width: 0,
  height: 0,
  pointerEvents: 'none',
  borderLeft: '40px solid transparent',
  borderRight: '40px solid transparent',
  borderBottom: `40px solid white`,
  transform: 'rotate(-45deg)',
};

const labelStyle = {
  fontSize: 12,
  fontWeight: 'bold',
  position: 'relative',
  top: 17,
  left: -19,
};

const Local = () => (
  <div style={{ ...containerStyle, borderBottomColor: '#B9F6CA' }}>
    <span style={labelStyle}>LOCAL</span>
  </div>
);

const Stage = () => (
  <div style={{ ...containerStyle, borderBottomColor: '#FFCDD2' }}>
    <span style={{ ...labelStyle, left: -20 }}>STAGE</span>
  </div>
);

const Demo = () => (
  <div style={{ ...containerStyle, borderBottomColor: '#BBDEFB' }}>
    <span style={labelStyle}>DEMO</span>
  </div>
);

const Production = () => (
  <div style={{ ...containerStyle, borderBottomColor: 'yellow' }}>
    <span style={{ ...labelStyle, color: 'red' }}>PROD</span>
  </div>
);

const Kiosk = () => (
  <div style={{ ...containerStyle, borderBottomColor: '#035c86' }}>
    <span style={{ ...labelStyle, color: '#ffffff' }}>KIOSK</span>
  </div>
);

export default function EnvironmentLabel({ environment = 'production' }) {
  const hostname = window.location.host;

  if (hostname.indexOf('local') > -1) {
    switch (environment) {
      case 'kiosk':
        return <Kiosk />;
      case 'stage':
        return <Stage />;
      case 'demo':
        return <Demo />;
      case 'prod':
      case 'production':
        return <Production />;
      case 'local':
      case 'localhost':
      default:
        return <Local />;
    }
  } else if (hostname.indexOf('stage') > -1) {
    return <Stage />;
  } else if (hostname.indexOf('demo') > -1) {
    return <Demo />;
  }

  return null;
}
