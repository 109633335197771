import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import { AppContext } from '../../../../../App';
import badgeApplicationApi from '../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { handleError } from '../../../../../utils';
import BadgeApplicationDetailsModal from '../../../BadgeApplications/BadgeApplicationDetailsModal/BadgeApplicationDetailsModal';

let requestTimeout = null;
let apiAbortController = null;

export default function PersonEditBadgeApplications({ selectedPerson }) {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [badgeApplications, setBadgeApplications] = useState([]);
  const [isBadgeApplicationsEditModalOpen, setIsBadgeApplicationsEditModalOpen] = useState(false);
  const [selectedBadgeApplication, setSelectedBadgeApplication] = useState(null);
  const isSignatory = app?.user?.activeProfile?.role?.name === 'authorized-signatory';

  const onBadgeApplicationsEditModalClose = () => {
    setIsBadgeApplicationsEditModalOpen(false);
    setSelectedBadgeApplication(null);
  };

  const onBadgeApplicationClicked = badgeApplicationUuid => {
    setSelectedBadgeApplication({ uuid: badgeApplicationUuid });
    setIsBadgeApplicationsEditModalOpen(true);
  };

  const loadData = () => {
    if (requestTimeout) {
      if (apiAbortController) apiAbortController.abort();
      clearTimeout(requestTimeout);
    }

    const { api } = app;
    api.toggleLoading(true);
    setIsLoading(true);

    requestTimeout = setTimeout(() => {
      apiAbortController = new AbortController();
      badgeApplicationApi
        .getAllBadgeApplicationsForUser({
          userUuid: selectedPerson.uuid,
          shouldIncludeRecentInactive: true,
          signal: apiAbortController.signal,
        })
        .then(list => setBadgeApplications(list || []))
        .catch(error => handleError({ error }))
        .finally(() => {
          api.toggleLoading(false);
          setIsLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    if (!selectedPerson) return;

    setIsLoading(false);
    setBadgeApplications([]);
    setIsBadgeApplicationsEditModalOpen(false);

    loadData();
  }, [selectedPerson]);

  return (
    <Card>
      <CardBody>
        <div className="table-responsive" style={{ maxHeight: 150, minHeight: 150 }}>
          <ReactTable
            manual
            className="border-0 -striped"
            loading={isLoading}
            data={badgeApplications}
            columns={[
              {
                id: 'badgeType',
                Header: 'Badge Type',
                accessor: ({ uuid, badgeType }) => {
                  if (isSignatory) return badgeType.label;

                  return (
                    <button
                      type="button"
                      className="btn btn-link"
                      style={{ padding: 0 }}
                      onClick={() => onBadgeApplicationClicked(uuid)}
                    >
                      {badgeType.label}
                    </button>
                  );
                },
                sortable: false,
              },
              {
                id: 'currentPhase',
                Header: 'Current Phase',
                accessor: 'currentPhase',
                sortable: false,
              },
              {
                id: 'created',
                Header: 'Created',
                accessor: ({ created }) => moment.tz(created, app.timezone).format('MM/DD/YYYY'),
                sortable: false,
              },
              {
                id: 'type',
                Header: 'Type',
                accessor: 'type',
                sortable: false,
              },
            ]}
            pages={1}
            minRows={0}
            defaultPageSize={500}
            showPagination={false}
          />
        </div>

        <BadgeApplicationDetailsModal
          isOpen={isBadgeApplicationsEditModalOpen}
          onClose={onBadgeApplicationsEditModalClose}
          selectedBadgeApplication={selectedBadgeApplication}
        />
      </CardBody>
    </Card>
  );
}
