import React, { useContext, useEffect, useState } from 'react';
import { Alert, Col, FormGroup, Label, Row } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../shared/components/AirBadgeModal/AirBadgeModal';
import InputWithCharacterLimit from '../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import { AppContext } from '../../../App';
import violationsApi from '../../../ajax/Violations/violationsApi';
import { handleError } from '../../../utils';

function StatusLabel({ color, isTitle, children }) {
  return (
    <Alert
      fade={false}
      color={color}
      style={{
        padding: isTitle ? 0 : '3px 5px',
        margin: 0,
        width: 100,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '0.9em',
      }}
    >
      {children}
    </Alert>
  );
}

export const getViolationStatusLabel = (status, isTitle) => {
  switch (status) {
    case 'pending':
      return (
        <StatusLabel color="info" isTitle={isTitle}>
          PENDING
        </StatusLabel>
      );
    case 'dropped':
      return <StatusLabel color="danger">DROPPED</StatusLabel>;
    case 'in appeal':
      return <StatusLabel color="warning">IN APPEAL</StatusLabel>;
    case 'resolved':
      return <StatusLabel color="success">RESOLVED</StatusLabel>;
    default:
      return '';
  }
};

export default function ChangeViolationStatusModal({ isOpen, onClose, violation }) {
  const app = useContext(AppContext);
  const [choice, setChoice] = useState(null);
  const [comment, setComment] = useState('');

  const onModalClose = () => {
    onClose({ shouldReload: false });
  };

  const onSaveClicked = () => {
    app.api.toggleLoading(true);
    violationsApi
      .changeStatus({
        violationUuid: violation.uuid,
        newStatus: choice,
        comment,
      })
      .then(({ success }) => {
        if (success) {
          onClose({ shouldReload: true });
        } else {
          app.api.toggleLoading(false);
        }
      })
      .catch(error => {
        handleError({ error });
        app.api.toggleLoading(false);
      });
  };

  const isSaveDisabled = () => {
    return !choice || !comment;
  };

  useEffect(() => {
    if (isOpen && violation) {
      setChoice(null);
      setComment('');
    }
  }, [isOpen, violation]);

  if (!isOpen || !violation) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Change Violation Status"
      isOpen={isOpen}
      onClose={onModalClose}
      onSave={onSaveClicked}
      saveLabel="Update Status"
      saveDisabled={isSaveDisabled()}
    >
      <Row className="mb-4">
        <Col>
          <Label>Current Status</Label>
          {getViolationStatusLabel(violation?.status)}
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>New Status</Label>
            <RadioGroup
              row
              aria-label="status choice"
              name="status"
              value={choice}
              onChange={event => setChoice(event?.target?.value)}
            >
              {violation?.status !== 'pending' && (
                <FormControlLabel
                  value="pending"
                  control={<Radio color="primary" />}
                  label={getViolationStatusLabel('pending')}
                  style={{ marginRight: 20 }}
                />
              )}
              {violation?.status !== 'dropped' && (
                <FormControlLabel
                  value="dropped"
                  control={<Radio color="primary" />}
                  label={getViolationStatusLabel('dropped')}
                  style={{ marginRight: 20 }}
                />
              )}
              {violation?.status !== 'in appeal' && (
                <FormControlLabel
                  value="in appeal"
                  control={<Radio color="primary" />}
                  label={getViolationStatusLabel('in appeal')}
                  style={{ marginRight: 20 }}
                />
              )}
              {violation?.status !== 'resolved' && (
                <FormControlLabel
                  value="resolved"
                  control={<Radio color="primary" />}
                  label={getViolationStatusLabel('resolved')}
                  style={{ marginRight: 20 }}
                />
              )}
            </RadioGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label className="required">Reason for change</Label>
            <InputWithCharacterLimit type="textarea" limit="255" value={comment} onChange={setComment} />
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
