import React, { useContext, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import { AppContext } from '../../../../../App';
import AuthorizedSignatoryDetailsModal from '../../../AuthorizedSignatories/AuthorizedSignatoryDetailsModal';
import { PersonModalContext } from '../PeopleListEditModal';

export default function PersonDetailsRolesList({ activeProfiles }) {
  const app = useContext(AppContext);
  const personModalContext = useContext(PersonModalContext);
  const [isSignatoryModalOpen, setIsSignatoryModalOpen] = useState(false);
  const [selectedProfileUuid, setSelectedProfileUuid] = useState(null);

  const onSignatoryModalClose = ({ shouldReload }) => {
    setIsSignatoryModalOpen(false);
    if (shouldReload) personModalContext.reloadData();
  };

  const onSignatoryRowClicked = profileUuid => {
    setSelectedProfileUuid(profileUuid);
    setIsSignatoryModalOpen(true);
  };

  return (
    <Card>
      <CardBody>
        <div className="table-responsive" style={{ maxHeight: 200, minHeight: 200 }}>
          <ReactTable
            manual
            className="border-0 -striped"
            noDataText="No roles assigned"
            data={activeProfiles || []}
            columns={[
              {
                id: 'roleName',
                Header: 'Role',
                accessor: ({ uuid, roleName }) => {
                  if (roleName.toLocaleLowerCase().indexOf('signatory') > -1) {
                    return (
                      <button
                        type="button"
                        className="btn btn-link"
                        style={{ padding: 0 }}
                        onClick={() => onSignatoryRowClicked(uuid)}
                      >
                        {roleName}
                      </button>
                    );
                  }

                  return roleName;
                },
                sortable: false,
              },
              {
                id: 'companyName',
                Header: 'Company',
                accessor: 'companyName',
                sortable: false,
              },
              {
                id: 'trainingExpires',
                Header: 'Training Expires',
                accessor: ({ trainingExpires }) =>
                  trainingExpires ? moment.tz(trainingExpires, app.timezone).format('MM/DD/YYYY') : '-',
                sortable: false,
              },
            ]}
            pages={1}
            minRows={0}
            defaultPageSize={500}
            showPagination={false}
          />
        </div>

        <AuthorizedSignatoryDetailsModal
          isOpen={isSignatoryModalOpen}
          onClose={onSignatoryModalClose}
          signatoryUuid={selectedProfileUuid}
        />
      </CardBody>
    </Card>
  );
}
