import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import { AppContext, colors } from '../../../../App';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import badgeApplicationCommentApi from '../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import BadgeApplicationFiles from './components/BadgeApplicationFiles';
import InfoContent from './components/InfoContent';
import BadgeApplicationComments from './components/BadgeApplicationComments';
import BadgeApplicationCompleteAppointmentModal from './components/BadgeApplicationCompleteAppointmentModal';
import Phases from './components/Phases';
import BadgeApplicationActionPanel from './components/BadgeApplicationActionPanel';
import PeopleListEditModal, { BadgesLabel } from '../../People/PeopleList/PeopleListEditModal';
import { handleError, notify } from '../../../../utils';
import ActionItemsNotice from '../../../../shared/ActionItemsNotice/ActionItemsNotice';
import FontAwesomeIcon from '../../../../shared/components/FontAwesome/FontAwesomeIcon';
import BadgeApplicationAdminActionPanel from './BadgeApplicationAdminActionPanel/BadgeApplicationAdminActionPanel';
import Feature from '../../../../shared/Feature';

const Collapsible = ({ id, icon, title, expanded, onChange, children }) => {
  return (
    <Accordion id={id} expanded={expanded} onChange={onChange} style={{ backgroundColor: '#f0f0f0' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {icon} {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>{children}</AccordionDetails>
    </Accordion>
  );
};

function ExpiresDisplay({ badgeApplication }) {
  const app = useContext(AppContext);

  if (!badgeApplication.expires || (badgeApplication.currentPhase || '').toLocaleLowerCase() === 'issue') {
    return null;
  }

  const expiresMoment = moment.tz(badgeApplication.expires, app.timezone);
  let icon = 'fa-regular fa-clock';
  let iconColor = '#000000';
  if (expiresMoment.diff(moment(), 'days') <= 5) {
    icon = 'fa-solid fa-circle-exclamation';
    iconColor = colors.danger;
  }

  return (
    <div style={{ fontStyle: 'italic', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <FontAwesomeIcon className={icon} style={{ marginRight: 5, fontSize: 18, color: iconColor }} />
      Badge application will automatically terminate on {expiresMoment.format('MM/DD/YYYY')} if no progress is made
    </div>
  );
}

export default function BadgeApplicationDetailsModal({ isOpen, onClose, selectedBadgeApplication }) {
  const app = useContext(AppContext);
  const isAuthorizedSignatory = app?.user?.activeProfile?.role?.name === 'authorized-signatory';
  const isTsaAgent = app?.user?.activeProfile?.role?.name === 'tsa-agent';
  const isOperationsUser = app?.user?.activeProfile?.role?.name === 'operations';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [badgeApplication, setBadgeApplication] = useState(null);
  const [comments, setComments] = useState([]);
  const [isCompleteAppointmentModalOpen, setIsCompleteAppointmentModalOpen] = useState(false);
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [isFilesExpanded, setIsFilesExpanded] = useState(false);
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [badgeHolder, setBadgeHolder] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  if (isOperationsUser) {
    return (window.location.href = '/#/default/home');
  }

  const onPersonModalClose = () => {
    setIsPersonModalOpen(false);
    setBadgeHolder(null);
  };

  const onModalClose = () => {
    setIsCompleteAppointmentModalOpen(false);
    setComments([]);
    setBadgeApplication(null);
    setWarningMessage('');
    setInfoMessage('');
    setIsModalOpen(false);
    onClose();
  };

  const loadData = () => {
    const { user, api } = app;
    const { uuid } = selectedBadgeApplication;
    api.toggleLoading(true);
    return badgeApplicationApi
      .details({ user, badgeApplicationUuid: uuid })
      .then(({ success, data }) => {
        if (success) {
          setBadgeApplication(data);
          reloadComments();
          if (
            data?.isRenewal === false &&
            data?.config?.fingerprintAppointment?.alreadyHasValidRapback === true &&
            data?.config?.fingerprintAppointment?.isEnabled === false
          ) {
            setInfoMessage('Applicant has a valid TSC rapback subscription and does not require new fingerprints');
          }
        }
      })
      .catch(error => handleError({ error, message: 'Unable to load Badge Application' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const reloadComments = () => {
    const { user, api } = app;
    const { uuid } = selectedBadgeApplication;
    setComments([]);
    api.toggleLoading(true);
    return badgeApplicationCommentApi
      .list({ user, badgeApplicationUuid: uuid })
      .then(({ success, data }) => {
        if (success) {
          setComments((data || []).reverse());
        }
      })
      .catch(error => handleError({ error, message: 'Unable to load Badge Application comments' }))
      .finally(() => api.toggleLoading(false));
  };

  const onCompleteAppointmentDone = ({ didMakeChanges }) => {
    setIsCompleteAppointmentModalOpen(false);
    if (didMakeChanges) loadData();
  };

  const subscribeToNotificationsChannel = useCallback(() => {
    if (app.pushNotificationsClient) {
      const { pushNotificationsClient, user } = app;
      const { uuid } = selectedBadgeApplication;
      // noinspection JSCheckFunctionSignatures
      pushNotificationsClient.subscribe(`private-badge-application-${uuid}`).bind('updates', ({ content }) => {
        if (content) {
          const { event, actorUuid, actorName } = JSON.parse(content);
          if (event === 'fingerprints-file-received') {
            notify({ message: 'Fingerprints file received' });
            loadData();
          } else if (actorUuid !== user.uuid) {
            setWarningMessage(
              `${actorName} has made changes to this badge application. To see the changes, close this window and re-open the badge application.`
            );
          }
        }
      });
    }
  }, [selectedBadgeApplication]);

  const unsubscribeFromNotificationsChannel = useCallback(() => {
    if (app.pushNotificationsClient) {
      const { pushNotificationsClient } = app;
      const { uuid } = selectedBadgeApplication;
      // noinspection JSIgnoredPromiseFromCall, JSCheckFunctionSignatures
      pushNotificationsClient.unsubscribe(`private-badge-application-${uuid}`);
    }
  }, [selectedBadgeApplication]);

  useEffect(() => {
    if (!isOpen || !selectedBadgeApplication) return;

    loadData();
    setIsInfoExpanded(false);
    setIsCommentsExpanded(false);
    setIsFilesExpanded(false);
    setIsModalOpen(true);

    subscribeToNotificationsChannel();

    return unsubscribeFromNotificationsChannel;
  }, [isOpen, selectedBadgeApplication]);

  const getPaymentStatus = useCallback(
    (isAbbreviatedMode = false) => {
      if (badgeApplication.paymentChoice === 'invoice') {
        return <span>Invoice</span>;
      } else if (badgeApplication.paymentChoice === 'no-charge') {
        return <span>No Charge</span>;
      } else if (badgeApplication.isPaid) {
        return <span className="text-success">Paid</span>;
      } else if (isAbbreviatedMode) {
        return <span className="text-danger">Awaiting Payment</span>;
      }
      return <span className="text-danger">Unpaid</span>;
    },
    [badgeApplication]
  );

  if (!isModalOpen || !badgeApplication) return null;

  return (
    <>
      <Modal size="lg" isOpen={isModalOpen} toggle={onModalClose} style={{ minWidth: '90%' }}>
        <CustomModalHeader toggle={onModalClose}>
          <span style={{ paddingRight: 8 }}>{badgeApplication.badgeType.displayName} Badge Application</span>
          <Feature id="payments">({getPaymentStatus(true)})</Feature>
        </CustomModalHeader>
        <ModalBody>
          {!isAuthorizedSignatory && (
            <ActionItemsNotice
              relatedToUserUuid={badgeApplication.applicant.uuid}
              badgeApplicationUuid={badgeApplication.uuid}
            />
          )}
          {!!warningMessage && <Alert color="warning">{warningMessage}</Alert>}
          <Row style={{ marginBottom: 24, justifyContent: 'center' }}>
            <div
              style={{
                width: '97%',
                border: '1px solid #c8ced3',
                backgroundColor: '#f0f0f0',
                borderRadius: 5,
                padding: 12,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: badgeApplication.active ? 16 : 0,
                }}
              >
                {isAuthorizedSignatory || isTsaAgent ? (
                  <ReadOnlyFormData
                    label="Applicant (Airport Person ID)"
                    value={
                      <div>
                        {badgeApplication.applicant.fullName} ({badgeApplication.applicant.airportPersonId || '-'})
                      </div>
                    }
                    skipTextTransform
                  />
                ) : (
                  <ReadOnlyFormData
                    label="Applicant (Airport Person ID)"
                    value={
                      <>
                        <div>
                          <button
                            type="button"
                            className="btn btn-link"
                            style={{ padding: 0 }}
                            onClick={() => {
                              setBadgeHolder({ uuid: badgeApplication.applicant.uuid });
                              setIsPersonModalOpen(true);
                            }}
                          >
                            {badgeApplication.applicant.fullName}
                          </button>
                          <span className="ml-1">({badgeApplication.applicant.airportPersonId || '-'})</span>
                        </div>
                        <BadgesLabel numActiveBadges={badgeApplication?.applicant?.numActiveBadges} />
                      </>
                    }
                    skipTextTransform
                  />
                )}

                <ReadOnlyFormData
                  label="Badge Type (Application Type)"
                  value={`${badgeApplication.badgeType.displayName} (${badgeApplication.isRenewal ? 'Renewal' : 'New'})`}
                  skipTextTransform
                />
                <ReadOnlyFormData
                  label="Company (Authorized Signatory)"
                  value={`${badgeApplication.signatory.company.name} (${badgeApplication.signatory.fullName})`}
                />

                <Feature id="payments">
                  <ReadOnlyFormData
                    label="Payer (Status)"
                    value={
                      <div>
                        <span style={{ paddingRight: 4 }}>{badgeApplication.paymentChoice}</span>({getPaymentStatus()})
                      </div>
                    }
                  />
                </Feature>
              </div>
              {!!badgeApplication.active && <ExpiresDisplay badgeApplication={badgeApplication} />}
            </div>
          </Row>

          {!!infoMessage && <Alert color="info">{infoMessage}</Alert>}

          <Row>
            <Phases badgeApplication={badgeApplication} />
          </Row>

          {!!app?.airport?.isTrainingEnabled &&
            !!badgeApplication?.active &&
            badgeApplication?.trainingStatus === 'completed' && (
              <Alert color="info" style={{ marginTop: 20 }}>
                All training assigned during this badge application has been completed
              </Alert>
            )}

          {!!badgeApplication.active && !isTsaAgent && (
            <div style={{ marginBottom: 40, marginTop: 32 }}>
              <div id="actionPanelMessages"></div>
              <div className="separator">ACTIONS</div>
              <BadgeApplicationActionPanel badgeApplication={badgeApplication} reload={loadData} />
            </div>
          )}

          {app.user.isSuperAdmin && (
            <div style={{ marginBottom: 40 }}>
              <div className="separator" style={{ color: colors.danger, fontWeight: 'bold' }}>
                ADMIN ACTIONS
              </div>
              <BadgeApplicationAdminActionPanel badgeApplication={badgeApplication} reload={loadData} />
            </div>
          )}

          <Row>
            <Col>
              {isAuthorizedSignatory ? (
                <InfoContent badgeApplication={badgeApplication} />
              ) : (
                <>
                  <Collapsible
                    id="badge-application-info"
                    title="Details"
                    icon={<i className="fa fa-id-card-o mr-2" />}
                    expanded={isInfoExpanded}
                    onChange={() => setIsInfoExpanded(!isInfoExpanded)}
                  >
                    <InfoContent badgeApplication={badgeApplication} />
                  </Collapsible>

                  <Collapsible
                    id="badge-application-comments"
                    title="Comments / Activity"
                    icon={<i className="fa fa-comments-o mr-2" />}
                    expanded={isCommentsExpanded}
                    onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
                  >
                    <BadgeApplicationComments
                      comments={comments}
                      reloadComments={reloadComments}
                      badgeApplicationUuid={badgeApplication.uuid}
                      appFunctions={app.api}
                      user={app.user}
                      appState={app}
                    />
                  </Collapsible>

                  <Collapsible
                    id="badge-application-files"
                    title="Files"
                    icon={<i className="fa fa-files-o mr-2" />}
                    expanded={isFilesExpanded}
                    onChange={() => setIsFilesExpanded(!isFilesExpanded)}
                  >
                    <BadgeApplicationFiles badgeApplication={badgeApplication} />
                  </Collapsible>
                </>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="secondary" onClick={onModalClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <BadgeApplicationCompleteAppointmentModal
        appFunctions={app.api}
        user={app.user}
        isOpen={isCompleteAppointmentModalOpen}
        badgeApplication={badgeApplication}
        onClose={didMakeChanges => onCompleteAppointmentDone({ didMakeChanges })}
      />

      <PeopleListEditModal isOpen={isPersonModalOpen} onClose={onPersonModalClose} selectedPerson={badgeHolder} />
    </>
  );
}
