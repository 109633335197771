import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '../../../../../shared/components/NativeSelect/NativeSelect';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import states from './states';
import { trim } from './badgerUtils';

export function InvalidCharacterMessage() {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  return <span className="text-danger">{t('question13_invalid_character')}</span>;
}

const VALID_CHARACTERS_REGEX = /^[a-zA-Z0-9#&\\:./ -]+$/;

export default function MailingAddressQuestion({ isHidden, value, onBack, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState({ ...value });
  const [includeBlank, setIncludeBlank] = useState(true);
  const [line1HasError, setLine1HasError] = useState(false);
  const [line2HasError, setLine2HasError] = useState(false);
  const [cityHasError, setCityHasError] = useState(false);
  const [zipHasError, setZipHasError] = useState(false);
  const [hasZipLengthError, setHasZipLengthError] = useState(false);

  const validateInput = (field, value) => {
    // Fix all "smart" double quotes and "smart" single quotes (usually only a problem on Windows machines)
    const sanitizedValue = value
      .replaceAll('‘', "'")
      .replaceAll('’', "'")
      .replaceAll('‘', "'")
      .replaceAll('”', '"')
      .replaceAll('“', '"');
    const hasInvalidCharacter = VALID_CHARACTERS_REGEX.test(sanitizedValue) === false;
    const hasError = !sanitizedValue ? false : hasInvalidCharacter;
    switch (field) {
      case 'line1':
        setLine1HasError(hasError);
        break;
      case 'line2':
        setLine2HasError(hasError);
        break;
      case 'city':
        setCityHasError(hasError);
        break;
      case 'zip':
        setZipHasError(hasError);
        break;
      default:
      // do nothing
    }
  };

  const validateZipLength = zipcode => {
    setHasZipLengthError(zipcode?.length !== 5);
  };

  const isNextButtonHidden = () => {
    if (line1HasError || line2HasError || cityHasError || zipHasError || hasZipLengthError) {
      return true;
    }
    return !state.street1 || !state.city || !state.state || !state.zip;
  };

  const cleanupData = () => {
    const newState = { ...state };
    newState.street1 = trim(newState.street1);
    newState.street2 = trim(newState.street2);
    newState.city = trim(newState.city);
    newState.zip = trim(newState.zip);
    setState(newState);
    return newState;
  };

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col>
          <FormLabel>
            <Typography variant="h6">{t('question13')}</Typography>
            <Typography variant="subtitle1">
              <span style={{ paddingRight: 8 }}>{t('question13_warning')}:</span>
              <span style={{ letterSpacing: 5 }}>{'#&\\:.-/'}</span>
            </Typography>
          </FormLabel>
        </Col>
      </Row>

      <Row>
        <Col sm="12" lg="6">
          <FormLabel>
            <Typography variant="h6">{t('question13_street1')}</Typography>
          </FormLabel>
          <FormGroup>
            <Input
              value={state.street1}
              onChange={({ target }) => {
                setState({ ...state, street1: target.value });
                validateInput('line1', target.value);
              }}
            />
            {line1HasError && <InvalidCharacterMessage />}
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormLabel>
            <Typography variant="h6">{t('question13_street2')}</Typography>
          </FormLabel>
          <FormGroup>
            <Input
              value={state.street2}
              onChange={({ target }) => {
                setState({ ...state, street2: target.value });
                validateInput('line2', target.value);
              }}
            />
            {line2HasError && <InvalidCharacterMessage />}
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormLabel>
            <Typography variant="h6">{t('question13_city')}</Typography>
          </FormLabel>
          <FormGroup>
            <Input
              value={state.city}
              onChange={({ target }) => {
                setState({ ...state, city: target.value });
                validateInput('city', target.value);
              }}
            />
            {cityHasError && <InvalidCharacterMessage />}
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormLabel>
            <Typography variant="h6">{t('question13_state')}</Typography>
          </FormLabel>
          <FormGroup>
            <NativeSelect
              id="statesSelect"
              name="statesSelect"
              options={states}
              value={state.state}
              onChange={option => {
                setState({ ...state, state: option });
                setIncludeBlank(false);
              }}
              includeBlank={includeBlank}
              required
            />
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormLabel>
            <Typography variant="h6">{t('question13_zip')}</Typography>
          </FormLabel>
          <FormGroup>
            <Input
              type="number"
              value={state.zip}
              onChange={({ target }) => {
                setState({ ...state, zip: target.value });
                validateInput('zip', target.value);
                validateZipLength(target.value);
              }}
            />
            {zipHasError && <InvalidCharacterMessage />}
            {hasZipLengthError && <span className="text-danger">{t('question13_invalid_zipcode')}</span>}
          </FormGroup>
        </Col>
      </Row>

      <BackButton onClick={onBack} />
      <NextButton isDisabled={isNextButtonHidden()} onClick={() => onNext(cleanupData())} />
    </>
  );
}
