import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label, Alert } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext, colors } from '../../../../../App';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import badgeApi from '../../../../../ajax/Badge/badgeApi';

export function RenewalPreventedNotice({ badge }) {
  if (badge && !!badge.renewalProhibited) {
    return <Alert color="warning">Badge renewal has been prohibited.</Alert>;
  }
  return null;
}

export default function PreventRenewalAction({ badge, isSignatory, onChange }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');

  const onActionClicked = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onSave = () => {
    app.api.toggleLoading(true);

    let promise = null;
    if (badge.renewalProhibited) {
      promise = badgeApi.allowRenewal({ badgeUuid: badge.uuid, reason: comment });
    } else {
      promise = badgeApi.preventRenewal({ badgeUuid: badge.uuid, reason: comment });
    }

    promise
      .then(({ success }) => {
        if (success) {
          onModalClose();
          onChange({ ...badge, renewalProhibited: badge.renewalProhibited ? 0 : 1 });
        } else {
          app.api.displayMessage('A problem occurred while trying to update the badge', 'warning');
        }
      })
      .catch(e => console.error(e))
      .finally(app.api.toggleLoading(false));
  };

  useEffect(() => {
    setIsModalOpen(false);
    setComment('');
  }, [badge]);

  let isDisabled = false;
  let title = badge.renewalProhibited ? 'Allow Renewal' : 'Prevent Renewal';
  if (badge.hasRenewalInProgress) {
    title = 'Renewal in progress';
    isDisabled = true;
  } else if (isSignatory) {
    title = 'Prevent Renewal';
    isDisabled = !!badge.renewalProhibited;
  }

  return (
    <>
      <span title={title}>
        <ListItem button onClick={onActionClicked} disabled={isDisabled}>
          {isSignatory ? (
            <>
              <ListItemIcon>
                <FontAwesomeIcon className="fa fa-pause-circle-o" color={colors.warning} />
              </ListItemIcon>
              <ListItemText primary="Prevent Renewal" />
            </>
          ) : (
            <>
              <ListItemIcon>
                {badge.renewalProhibited ? (
                  <FontAwesomeIcon className="fa fa-play-circle-o" color={colors.success} />
                ) : (
                  <FontAwesomeIcon className="fa fa-pause-circle-o" color={colors.warning} />
                )}
              </ListItemIcon>
              <ListItemText primary={badge.renewalProhibited ? 'Allow Renewal' : 'Prevent Renewal'} />
            </>
          )}
        </ListItem>
      </span>

      <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
        <CustomModalHeader toggle={onModalClose}>
          {badge.renewalProhibited ? 'Allow' : 'Prevent'} Badge Renewal
        </CustomModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {badge.renewalProhibited ? (
                <p>This will allow the badge to be renewed through a badge renewal application.</p>
              ) : (
                <p>This will permanently prevent a badge renewal application from being started for this badge.</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="required">Reason</Label>
                <Input required type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Cancel"
            onClose={onModalClose}
            saveLabel={badge.renewalProhibited ? 'Allow Renewal' : 'Prevent Renewal'}
            saveDisabled={!comment}
            saveColor={badge.renewalProhibited ? 'primary' : 'warning'}
            onSave={onSave}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
