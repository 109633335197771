// -------------------------------------------------------------------------
// TODO: keep this file synchronized with
//          server/app/Services/Badger/BadgerDocumentVerificationService.php
// -------------------------------------------------------------------------
const documentRequirements = {
  passport: {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'usa_passport_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'usa-passport': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'usa_passport_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'drivers-license': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'driving_license_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: true,
      required: true,
      hasCountry: false,
      hasState: true,
      showCanada: true,
      defaultValue: null,
    },
  },
  'state-id-card': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'state_id_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: true,
      required: true,
      hasCountry: false,
      hasState: true,
      showCanada: true,
      defaultValue: null,
    },
  },
  'school-id-card': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'school_id_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: null,
    },
  },
  'voter-registration-card': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'voter_card_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: false,
    },
    issuingAuthority: {
      enabled: true,
      required: true,
      hasCountry: false,
      hasState: true,
      showCanada: false,
      defaultValue: null,
    },
  },
  'military-id': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'military_id_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'military-dependent-id': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'military_id_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'merchant-mariner-card': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'merchant_card_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'tribal-document': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'tribal_document_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'social-security-card': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'ssn_card_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'birth-certificate': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'birth_certificate_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: true,
      required: true,
      hasCountry: false,
      hasState: true,
      showCanada: false,
      defaultValue: null,
    },
  },
  'certificate-of-naturalization': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'naturalization_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'n-560': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'naturalization_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'permanent-resident-card': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'resident_card_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'fs-545': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'fs_545_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'fs-240': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'fs_545_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'ds-1350': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'ds_1350_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'i-94': {
    image: false,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'i_94_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'i-179': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'i_179_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'i-197': {
    image: true,
    documentNumber: {
      enabled: true,
      required: false,
      i18nLabelKey: 'i_197_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'i-766': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'i_766_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'employment-authorization-document': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'i_766_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
  'foreign-passport': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'foreign_passport_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: true,
      required: true,
      hasCountry: true,
      hasState: false,
      showCanada: false,
      defaultValue: null,
    },
  },
  'foreign-passport-i-551': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'foreign_passport_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: true,
      required: true,
      hasCountry: true,
      hasState: false,
      showCanada: false,
      defaultValue: null,
    },
  },
  'foreign-passport-mriv': {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'foreign_passport_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: true,
      required: true,
      hasCountry: true,
      hasState: false,
      showCanada: false,
      defaultValue: null,
    },
  },
  arn: {
    image: false,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'arn_label',
      format: null,
    },
    expirationDate: {
      enabled: false,
      required: false,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      defaultValue: 'usa',
    },
  },
  visa: {
    image: true,
    documentNumber: {
      enabled: true,
      required: true,
      i18nLabelKey: 'visa_label',
      format: null,
    },
    expirationDate: {
      enabled: true,
      required: true,
    },
    issuingAuthority: {
      enabled: false,
      required: false,
      hasCountry: false,
      hasState: false,
      showCanada: false,
      defaultValue: 'usa',
    },
  },
};

export const defaultRequirement = {
  image: true,
  documentNumber: {
    enabled: false,
    required: false,
    i18nLabelKey: '???',
    format: null,
  },
  expirationDate: {
    enabled: false,
    required: false,
  },
  issuingAuthority: {
    enabled: false,
    required: false,
    i18nLabelKey: '???',
    hasCountry: false,
    hasState: false,
    defaultValue: 'usa',
  },
};

export default documentRequirements;

// -------------------------------------------------------------------------
// TODO: keep this file synchronized with
//          server/app/Services/Badger/BadgerDocumentVerificationService.php
// -------------------------------------------------------------------------
