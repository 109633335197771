import React, { useCallback, useContext, useState } from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { AppContext, colors } from '../../../../../../App';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import filesApi from '../../../../../../ajax/File/filesApi';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import FileUploadForm from '../../../../../../shared/components/FileUploadModal/FileUploadForm';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import DocSpringDocument from '../../../../DocSpringDocument/DocSpringDocument';
import { handleError } from '../../../../../../utils';

const useStyles = makeStyles(theme => ({
  notice: {
    marginBottom: theme.spacing(2),
  },
  asterisk: {
    verticalAlign: 'super',
    position: 'relative',
    top: 2,
    left: 1,
  },
  commentNotice: {
    fontSize: 'smaller',
    fontStyle: 'italic',
  },
}));

function SignAuthorizationDocumentAction({ history }) {
  const app = useContext(AppContext);
  const classes = useStyles();
  const { badgeApplication, onActionDone } = useContext(ActionPanelContext);
  const { authorizationDocument } = badgeApplication.config;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState([]);
  const [transactionUuid, setTransactionUuid] = useState(null);
  const isSignatory = get(app, 'user.activeProfile.role.name') === 'authorized-signatory';
  const isASC = get(app, 'user.activeProfile.role.name') === 'airport-security-coordinator';

  const onCancelled = () => {
    setTransactionUuid(null);
  };

  const onSubmitSuccess = () => {
    onActionDone({ shouldReload: true });
  };

  const isHidden = useCallback(() => {
    if (!isSignatory) return true;
    const { isEnabled, isComplete, documentTransactions } = authorizationDocument;
    return !isEnabled || isComplete || documentTransactions.signatory.isComplete;
  }, [authorizationDocument, isSignatory]);

  const onClick = useCallback(() => {
    const { transactionUuid } = authorizationDocument.documentTransactions.signatory;
    setTransactionUuid(transactionUuid);
  }, [authorizationDocument, history]);

  const isSkipHidden = useCallback(() => {
    const { isEnabled, isComplete, documentTransactions } = authorizationDocument;
    return !isEnabled || isComplete || documentTransactions.signatory.isComplete;
  }, [authorizationDocument, isSignatory, isASC]);

  const onSkip = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setComment('');
    setFiles([]);
  };

  const addFiles = files => {
    setFiles(files);
  };

  const isSaveDisabled = useCallback(() => {
    return !files.length || !comment;
  }, [files, comment]);

  const onSave = async () => {
    const { api, user } = app;
    api.toggleLoading(true);

    if (files?.length) {
      const { data: fileTypes } = await filesApi.fileTypes();
      const fileType = fileTypes.find(ft => ft.systemName === 'authorization-statement');
      const file = files[0];
      const data = {
        user: badgeApplication.applicant.uuid,
        type: fileType.uuid,
        badgeApplication: badgeApplication.uuid,
        signatory: badgeApplication.signatory.uuid,
        company: badgeApplication.signatory.company.uuid,
        comment: `Uploaded as part of manually completing the Authorization Statement for the badge application.`,
      };
      const response = await filesApi.upload({ user, file, data });
      if (!response.success) {
        api.toggleLoading(false);
        return handleError({ message: 'Unable to upload file' });
      }
    }

    const response = await badgeApplicationApi.skipStep({
      user,
      uuid: badgeApplication.uuid,
      comment,
      skippedRequirement: 'authorizationDocument',
    });

    api.toggleLoading(false);

    if (!response.success) {
      return handleError({ message: 'Unable to mark badge application step as completed' });
    }

    onModalClose();
    return onActionDone({ shouldReload: true });
  };

  return (
    <>
      <ListItem button onClick={onClick} hidden={isHidden()}>
        <ListItemIcon>
          <EditOutlinedIcon style={{ color: colors.primary }} />
        </ListItemIcon>
        <ListItemText primary="Sign Authorization Statement" />
      </ListItem>

      {!isSkipHidden() && (
        <>
          <ListItem button onClick={onSkip}>
            <ListItemIcon>
              <FontAwesomeIcon className="fa fa-check" color={colors.warning} />
            </ListItemIcon>
            <ListItemText primary="Mark Authorization Statement Complete" />
          </ListItem>

          <Modal size="lg" isOpen={isModalOpen} toggle={onModalClose}>
            <CustomModalHeader toggle={onModalClose}>Mark Authorization Statement Complete</CustomModalHeader>
            <ModalBody>
              <div className={classes.notice}>
                This will mark the <strong>Authorization Statement</strong> as complete and will advance the badge
                application process.
              </div>

              <div className={classes.notice}>
                A copy of the <i>signed authorization statement</i> must be uploaded and attached to this badge
                application before you can proceed.
              </div>
              <div className={classes.notice}>
                <FileUploadForm onFilesAdded={addFiles} />
              </div>

              <FormGroup>
                <Label>
                  <div className="required">Comment</div>
                  <div className={classes.commentNotice}>
                    Provide a comment explaining why this document needed to be manually completed
                  </div>
                </Label>
                <Input required type="textarea" value={comment} onChange={event => setComment(event.target.value)} />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <ModalActions
                closeLabel="Cancel"
                onClose={onModalClose}
                saveLabel="Mark As Complete"
                onSave={onSave}
                saveDisabled={isSaveDisabled()}
              />
            </ModalFooter>
          </Modal>
        </>
      )}

      <DocSpringDocument
        transactionUuid={transactionUuid}
        onCancelled={onCancelled}
        onSubmitSuccess={onSubmitSuccess}
      />
    </>
  );
}

export default withRouter(SignAuthorizationDocumentAction);
