import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '../../../../../shared/components/NativeSelect/NativeSelect';
import NextButton from '../../../components/NextButton';
import BadgerFormLabel from '../../../components/BadgerFormLabel';
import { trim } from './badgerUtils';

export default function NameQuestion({ isHidden, value, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState({ ...value });

  const cleanupData = () => {
    const newState = { ...state };
    newState.last = trim(newState.last);
    newState.first = trim(newState.first);
    newState.middle = trim(newState.middle);

    newState.last = newState.last.replace('- ', '-').replace(' -', '-');
    newState.first = newState.first.replace('- ', '-').replace(' -', '-');
    newState.middle = newState.middle.replace('- ', '-').replace(' -', '-');

    setState(newState);
    return newState;
  };

  const onChange =
    field =>
    ({ target }) => {
      const regex = /^[A-Za-z\s-]*$/;
      const newState = { ...state };
      const newValue = target.value;
      if (!newValue) {
        newState[field] = '';
        setState(newState);
      } else if (regex.test(newValue)) {
        newState[field] = newValue;
        setState(newState);
      }
    };

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col>
          <FormLabel>
            <Typography variant="h6">{t('question1')}</Typography>
          </FormLabel>
          <FormLabel>
            <Typography variant="subtitle2">{t('question1_extra')}</Typography>
          </FormLabel>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <FormGroup>
            <BadgerFormLabel>{t('question1_part1')}</BadgerFormLabel>
            <Input value={state.last} onChange={onChange('last')} />
          </FormGroup>
        </Col>
        <Col xs="12" sm="4">
          <FormGroup>
            <BadgerFormLabel>{t('question1_part2')}</BadgerFormLabel>
            <Input value={state.first} onChange={onChange('first')} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <FormGroup>
            <BadgerFormLabel>{t('question1_part3')}</BadgerFormLabel>
            <Input value={state.middle} onChange={onChange('middle')} />
          </FormGroup>
        </Col>
        <Col xs="12" sm="4">
          <FormGroup>
            <BadgerFormLabel>{t('question1_part4')}</BadgerFormLabel>
            <NativeSelect
              id="suffixSelect"
              name="suffixSelect"
              options={[
                { label: 'JR', value: 'JR' },
                { label: 'SR', value: 'SR' },
                { label: 'I', value: 'I' },
                { label: 'II', value: 'II' },
                { label: 'III', value: 'III' },
                { label: 'IV', value: 'IV' },
                { label: 'V', value: 'V' },
                { label: 'VI', value: 'VI' },
                { label: 'VII', value: 'VII' },
                { label: 'VIII', value: 'VIII' },
                { label: 'IX', value: 'IX' },
              ]}
              includeBlank
              value={state.suffix}
              onChange={selectedOption => {
                setState({ ...state, suffix: selectedOption || null });
              }}
            />
          </FormGroup>
        </Col>
      </Row>

      <NextButton isDisabled={!state.first || !state.last} onClick={() => onNext(cleanupData())} />
    </>
  );
}
