import request from '../Request';

export default {
  create: createProfileComment,
  list: listProfileComments,
};

function createProfileComment({ profileUUID = '', comment }) {
  return request('authenticated-user', 'POST', `profile/${profileUUID}/comments`, {
    comment,
  });
}

function listProfileComments({ profileUUID = '' }) {
  return request('authenticated-user', 'GET', `profile/${profileUUID}/comments`);
}
