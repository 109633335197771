import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReadOnlyFormData from '../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import DrivingEndorsementPicker from '../../../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import EscortEndorsementPicker from '../../../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import { getDrivingOptions } from '../../../../../../shared/BadgeTypes/drivingOptionsUtils';

export default function CreateBadgeApplicationBadgeModalInfoBlock({ badgeApplication }) {
  const getDrivingOption = () => {
    const drivingOptions = getDrivingOptions(badgeApplication?.badgeType?.config?.endorsements?.driving);
    return drivingOptions.find(option => option.value === badgeApplication?.badge?.driving) || 'ERROR';
  };

  return (
    <div
      style={{
        border: '1px solid #c8ced3',
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        padding: 12,
      }}
    >
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <ReadOnlyFormData
            label="Applicant (Airport Person ID)"
            value={
              <div>
                <div>
                  {badgeApplication.applicant.fullName} ({badgeApplication.applicant.airportPersonId || '-'})
                </div>
                <div style={{ fontSize: 'smaller', fontStyle: 'italic', marginTop: 3 }}>
                  Date of Birth: {badgeApplication.applicant.dob || 'not set'}
                </div>
              </div>
            }
            skipTextTransform
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <ReadOnlyFormData
            label="Company (Authorized Signatory)"
            value={`${badgeApplication.signatory.company.name} (${badgeApplication.signatory.fullName})`}
          />
        </Grid>
      </Grid>

      <div className="separator mt-4 mb-4">Endorsements</div>

      <Grid container spacing={3}>
        <DrivingEndorsementPicker
          isReadOnly={true}
          displayAsGridItem={true}
          sm={12}
          md={6}
          context={{
            drivingEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.driving,
            drivingSelection: getDrivingOption(),
            setDrivingSelection: () => false,
            shouldSkipDefaultSelection: true,
            data: { badgeType: badgeApplication?.badgeType },
          }}
        />
        <EscortEndorsementPicker
          isReadOnly={true}
          displayAsGridItem={true}
          sm={12}
          md={6}
          context={{
            escortEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.escort,
            escortSelection: badgeApplication.badge.isEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
            setEscortSelection: () => false,
            shouldSkipDefaultSelection: true,
            data: { badgeType: badgeApplication?.badgeType },
          }}
        />
        <Grid item sm={12} md={6}>
          <ReadOnlyFormData
            skipTextTransform
            label="Law Enforcement Officer (LEO)"
            value={badgeApplication.badge.isLEO ? 'Yes' : 'No'}
          />
        </Grid>
      </Grid>
    </div>
  );
}
