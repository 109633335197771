import request from '../Request';

export default function EnrollmentVerifyNumberRequest(user, number, verificationCode) {
  const method = 'POST';

  const url = `enrollment/verify-number`;

  const data = {
    number,
    verification_code: verificationCode,
  };

  return request(user, method, url, data);
}
