import React, { useContext, useState } from 'react';
import moment from 'moment-timezone';
import { Badge, Button, Col, Row } from 'reactstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { AppointmentCalendarContext } from '../AppointmentsList';
import { AppContext } from '../../../../../App';

const useStyles = makeStyles(theme => ({
  entryHeader: {
    display: 'flex',
    width: '100%',
  },
  entryColumn: {
    '&:first-of-type': {
      minWidth: 100,
      marginRight: 5,
    },
  },
  summaryContent: {
    transition: 'opacity 0.2s ease',
    opacity: 0,
  },
  contentVisible: {
    opacity: 1,
  },
  dataEntry: {
    fontStyle: 'italic',
    opacity: 0.7,
  },
  entryActionRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  dataEntryRowSpacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  separator: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '0 !important',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export function StatusBadge({ status }) {
  const badgeStatus = (status || 'unprocessed').toLocaleUpperCase();
  let color = 'secondary';
  switch (badgeStatus) {
    case 'COMPLETED':
      color = 'success';
      break;
    case 'MISSED':
      color = 'warning';
      break;
    case 'CANCELLED':
      color = 'danger';
      break;
  }
  return (
    <Badge pill color={color} style={{ padding: '5px 10px', width: 110 }}>
      {badgeStatus}
    </Badge>
  );
}

export default function DailyViewAppointmentEntry({ id, appointment, onEdit, onAttendeeClicked }) {
  const app = useContext(AppContext);
  const appointmentCalendarContext = useContext(AppointmentCalendarContext);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const hasBadgeApplication = !!appointment.badgeApplicationUuid;
  const hasBadge = !!appointment.renewingBadgeUuid;
  const isAppointmentAlreadyEnded = moment.tz(appointment.ended, app.timezone).isBefore(moment().tz(app.timezone));

  const onCancelClicked = () => {
    appointmentCalendarContext.onCancelAppointment({ appointment });
  };

  const onMarkCompletedClicked = () => {
    appointmentCalendarContext.onMarkCompleted({ appointment });
  };

  const onMarkMissedClicked = () => {
    appointmentCalendarContext.onMarkMissed({ appointment });
  };

  const onForceRescheduleClicked = () => {
    appointmentCalendarContext.onForceReschedule({ appointment });
  };

  const DataDisplay = ({ label, children }) => {
    return (
      <>
        <Typography variant="body2" className={classes.dataEntry}>
          {label}
        </Typography>
        <Typography>{children}</Typography>
      </>
    );
  };

  const NotesDisplay = ({ notes }) => {
    return notes.split('\n').map((line, index) => <div key={index}>{line}</div>);
  };

  return (
    <Accordion id={id} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.entryHeader}>
          <div className={classes.entryColumn}>
            <Typography>{appointment.start}</Typography>
          </div>
          <div className={classes.entryColumn} style={{ flex: 1 }}>
            <div className={`${classes.summaryContent} ${expanded ? '' : classes.contentVisible}`}>
              <Typography style={{ textTransform: 'uppercase' }}>{appointment.attendee_name}</Typography>
              <Typography style={{ fontSize: 14 }}>
                <span style={{ fontStyle: 'italic' }}>{appointment.appointmentType.name}</span>
              </Typography>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 75 }}>
            <StatusBadge status={appointment.status} />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Row>
          <Col>
            <DataDisplay label="Attendee">
              <button
                type="button"
                className="btn btn-link"
                style={{ padding: 0 }}
                onClick={() => onAttendeeClicked({ uuid: appointment.attendee_uuid })}
              >
                {appointment.attendee_name}
              </button>
            </DataDisplay>
          </Col>
          <Col>
            <DataDisplay label="Resource">{appointment.staff.name}</DataDisplay>
          </Col>
          <Col>
            <DataDisplay label="Type">{appointment.appointmentType.name}</DataDisplay>
          </Col>
        </Row>

        <Row style={{ marginTop: 24 }}>
          <Col>
            <ReadOnlyFormData
              label="Notes"
              skipTextTransform
              value={<NotesDisplay notes={appointment.comment || 'No notes entered'} />}
            />
          </Col>
        </Row>

        {hasBadgeApplication && (
          <>
            <div className={`separator ${classes.separator}`}>BADGE APPLICATION</div>
            <Row>
              <Col>
                <ReadOnlyFormData
                  label="Badge Type (Application Type)"
                  value={`${appointment.badgeApplicationBadgeType.toLocaleUpperCase()} (${
                    appointment.badgeApplicationType
                  })`}
                />
              </Col>
              <Col>
                <ReadOnlyFormData
                  label="Started On"
                  value={moment(appointment.badgeApplicationCreatedOn).format('MM/DD/YYYY')}
                />
              </Col>
            </Row>
            <Row className={classes.dataEntryRowSpacer}>
              {hasBadge && (
                <Col>
                  <ReadOnlyFormData label="Badge ID" value={`${appointment.renewingBadgeNumber}`} />
                </Col>
              )}
              <Col>
                <ReadOnlyFormData
                  label="Company (Authorized Signatory)"
                  value={`${appointment.companyName} (${appointment.authorizedSignatoryName})`}
                />
              </Col>
            </Row>
          </>
        )}

        {!appointment.status && (
          <div className={classes.entryActionRow}>
            <Button color="success" size="sm" className="mr-4" onClick={onMarkCompletedClicked}>
              Mark Completed
            </Button>

            {isAppointmentAlreadyEnded ? (
              <Button color="warning" size="sm" className="mr-4" onClick={onMarkMissedClicked}>
                Mark Missed
              </Button>
            ) : (
              <Button color="warning" size="sm" className="mr-4" onClick={onForceRescheduleClicked}>
                Force Reschedule
              </Button>
            )}

            <Button color="danger" size="sm" onClick={onCancelClicked}>
              Cancel Appointment
            </Button>

            <Button size="sm" onClick={onEdit} color="primary" className="float-right">
              Make Changes
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
