import toPairs from 'lodash/toPairs';

const versions = {
  default: 'v1',
};

export const siteUrl = segment => {
  const base = process.env.REACT_APP_BASE_URL;
  const workflow = process.env.REACT_APP_WORKFLOW || 'default';
  const version = versions[process.env.REACT_APP_WORKFLOW];
  return `${base}/${workflow}/${version}/${segment}`;
};

export default function request(user, method, url, data, signal) {
  if (user === 'authenticated-user') {
    user = JSON.parse(localStorage.getItem('AirportBadges.user'));
  } else if (user === 'authenticated-user-or-null') {
    if (localStorage.getItem('AirportBadges.user')) {
      user = JSON.parse(localStorage.getItem('AirportBadges.user'));
    }
  }
  return new Promise((resolve, reject) => {
    const fetchConfig = {
      method,
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': user ? user.authToken : '',
        'X-PROFILE-UUID': user && user.activeProfile ? user.activeProfile.uuid : '',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: data ? JSON.stringify(data) : undefined,
    };

    // Used for aborting API calls after they've been started
    if (signal) fetchConfig.signal = signal;

    fetch(siteUrl(url), fetchConfig)
      .then(response => {
        if (!response.ok) {
          response
            .text()
            .then(message => {
              const error = new Error(message);
              error.status = response.status;
              throw error;
            })
            .catch(error => {
              // Handle auth error.
              if (error.status === 401 && user && user.authToken === '') {
                return; // login failed
              }

              if (error.status === 401 && user && user.authToken !== '') {
                localStorage.removeItem('AirportBadges.user');
                window.location.reload();
              }
            });
        }

        return response;
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error.message);
        console.error(error.message);
      });
  });
}

export function upload(user, method, url, file, data = {}) {
  if (user === 'authenticated-user') {
    user = JSON.parse(localStorage.getItem('AirportBadges.user'));
  }
  const formData = new FormData();

  formData.append('file', file, file.name);
  toPairs(data).forEach(([key, value]) => formData.append(key, value));

  return new Promise((resolve, reject) => {
    fetch(siteUrl(url), {
      method,
      headers: {
        'X-AUTH-TOKEN': user ? user.authToken : '',
        'X-PROFILE-UUID': user && user.activeProfile ? user.activeProfile.uuid : '',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          response
            .text()
            .then(message => {
              const error = new Error(message);
              error.status = response.status;
              throw error;
            })
            .catch(error => {
              // Handle auth error.
              if (error.status === 401 && user && user.authToken === '') {
                return; // login failed
              }

              if (error.status === 401 && user && user.authToken !== '') {
                localStorage.removeItem('AirportBadges.user');
                window.location.reload();
              }
            });
        }

        return response;
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error.message);
        console.error(error.message);
      });
  });
}

export function download(user, method, url, data) {
  if (user === 'authenticated-user') {
    user = JSON.parse(localStorage.getItem('AirportBadges.user'));
  }
  return new Promise((resolve, reject) => {
    fetch(siteUrl(url), {
      method,
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': user ? user.authToken : '',
        'X-PROFILE-UUID': user && user.activeProfile ? user.activeProfile.uuid : '',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: data ? JSON.stringify(data) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          response
            .text()
            .then(message => {
              const error = new Error(message);
              error.status = response.status;
              throw error;
            })
            .catch(error => {
              // Handle auth error.
              if (error.status === 401 && user && user.authToken === '') {
                return; // login failed
              }

              if (error.status === 401 && user && user.authToken !== '') {
                localStorage.removeItem('AirportBadges.user');
                window.location.reload();
              }
            });
        }

        return response;
      })
      .then(response => {
        return response.arrayBuffer();
      })
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error.message);
        console.error(error.message);
      });
  });
}
