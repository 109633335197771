import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomModalHeader from '../../shared/components/CustomModalHeader/CustomModalHeader';

export default class Confirm extends Component {
  resetState() {
    // This will close the modal right away
    this.props.api.updateState({ confirmContent: '' });

    // This will wait a half second before resetting the rest of the modal
    // Doing this will reduce some UI quirkiness
    setTimeout(() => {
      this.props.api.updateState({
        confirmContent: '',
        confirmTitle: '',
        confirmCallbackOnConfirm: undefined,
        confirmCallbackOnClose: undefined,
        confirmOkBtnText: 'OK',
        cancelBtnText: 'Cancel',
      });
    }, 500);
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.resetState();
  }

  onConfirm() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
    this.resetState();
  }

  render() {
    const { title, message, onClose, isDangerous, okBtnText, cancelBtnText } = this.props;
    const isString = typeof message === 'string';

    return (
      <div>
        <Modal isOpen={!!message} toggle={this.onClose.bind(this)}>
          <CustomModalHeader toggle={this.onClose.bind(this)}>{title}</CustomModalHeader>
          <ModalBody>{isString ? <p>{message}</p> : message}</ModalBody>
          <ModalFooter>
            {onClose !== false && (
              <Button color="secondary" onClick={this.onClose.bind(this)}>
                {cancelBtnText ? cancelBtnText : 'Cancel'}
              </Button>
            )}
            <Button
              color={isDangerous ? 'danger' : 'primary'}
              onClick={this.onConfirm.bind(this)}
              style={{ marginLeft: 15, minWidth: 100 }}
            >
              {okBtnText ? okBtnText : 'OK'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
