import React, { useContext, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Alert } from 'reactstrap';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AirBadgeModal, { MODAL_SIZE_EXTRA_LARGE } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import request from '../../../../ajax/Request';
import { handleError } from '../../../../utils';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import { AppContext, colors } from '../../../../App';
import BadgeApplicationTrainingEditor from './components/BadgeApplicationTrainingEditor';
import BadgeApplicationPaymentEditor from './components/BadgeApplicationPaymentEditor';
import badgeApplicationCommentApi from '../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';

export default function BadgeApplicationAuxConfigEditor({ isOpen, onClose, config }) {
  const app = useContext(AppContext);
  const [title, setTitle] = useState('');
  const [badgeApplicationConfig, setBadgeApplicationConfig] = useState(null);
  const [wasChanged, setWasChanged] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isForLiveBadgeApplication, setIsForLiveBadgeApplication] = useState(false);
  const [badgeApplicationComment, setBadgeApplicationComment] = useState(null);
  const [error, setError] = useState('');

  const onCloseModal = args => {
    setError('');
    setTitle('');
    setBadgeApplicationConfig(null);
    setWasChanged(false);
    setBadgeApplicationComment(null);
    onClose(args);
  };

  const onJsonChange = async json => {
    try {
      badgeApplicationConfig.auxiliaryConfig = JSON.parse(json);
      setBadgeApplicationConfig(cloneDeep(badgeApplicationConfig));
      setWasChanged(true);
      setError('');
    } catch (err) {
      setError('Invalid JSON');
    }
  };

  const onPaymentsConfigChanged = ({ fees, originalTotal, newTotal }) => {
    badgeApplicationConfig.auxiliaryConfig.payments.items = fees;
    setBadgeApplicationConfig(cloneDeep(badgeApplicationConfig));
    setWasChanged(true);
    setBadgeApplicationComment(`Total amount due changed to $${newTotal} (was $${originalTotal})`);
  };

  const onTrainingConfigChanged = ({ updatedConfig }) => {
    badgeApplicationConfig.auxiliaryConfig.training = updatedConfig;
    setBadgeApplicationConfig(cloneDeep(badgeApplicationConfig));
    setWasChanged(true);
  };

  const onSaveChanges = () => {
    app.api.toggleLoading(true);
    if (badgeApplicationConfig.id) {
      request('authenticated-user', 'PUT', `admin/badge-application/config/${badgeApplicationConfig.id}`, {
        newAuxiliaryConfig: badgeApplicationConfig.auxiliaryConfig,
      })
        .then(({ success }) => {
          if (success) {
            onCloseModal({ shouldReload: true });
          } else {
            handleError({ message: 'Failed to update badge application config' });
          }
        })
        .catch(error => handleError({ error, message: 'Failed to update badge application config' }))
        .finally(() => app.api.toggleLoading(false));
    } else {
      const { uuid: badgeApplicationUuid } = config;
      badgeApplicationApi
        .updateConfig({ badgeApplicationUuid, auxiliaryConfig: badgeApplicationConfig.auxiliaryConfig })
        .then(async ({ success }) => {
          if (success) {
            if (badgeApplicationComment) {
              await badgeApplicationCommentApi.create({
                badgeApplicationUuid,
                comment: badgeApplicationComment,
              });
            }
            onCloseModal({ shouldReload: true });
          } else {
            handleError({ message: 'Failed to update badge application config' });
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }
  };

  useEffect(() => {
    if (!isOpen || !config) return;

    const { isForRenewals } = config;
    const badgeTypeName = config.badgeTypeName || config.badgeType?.displayName || '';
    const title = isForRenewals
      ? `${badgeTypeName} (Renewals) Auxiliary Configuration`
      : `${badgeTypeName} Auxiliary Configuration`;
    setTitle(title);

    const cloned = cloneDeep(config);
    cloned.auxiliaryConfig.training = cloned.auxiliaryConfig.training || [];
    setBadgeApplicationConfig(cloned);

    setIsForLiveBadgeApplication(!!config.uuid);
  }, [isOpen, config]);

  if (!isOpen || !badgeApplicationConfig) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_EXTRA_LARGE}
      title={title}
      isOpen={isOpen}
      closeLabel="Cancel"
      onClose={onCloseModal}
      saveLabel="Save Changes"
      onSave={onSaveChanges}
      saveDisabled={!wasChanged || !!error}
    >
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, currentTab) => setActiveTab(currentTab)}
      >
        <Tab label="Training" />
        <Tab label="Payments" />
        <Tab label="RAW JSON" style={{ color: colors.danger, fontWeight: 'bold' }} />
      </Tabs>

      <style>{`
        .aux-config-tab {
          width: 100%;
          height: 100%;
          padding: 24px 15px 15px 15px;
        }
      `}</style>

      {/* TRAINING */}
      <div className="aux-config-tab" style={{ width: '100%', display: activeTab === 0 ? 'inline-block' : 'none' }}>
        <BadgeApplicationTrainingEditor
          badgeType={config?.badgeType}
          config={badgeApplicationConfig.auxiliaryConfig.training}
          onTrainingConfigChanged={onTrainingConfigChanged}
          showAdminMode={true}
        />
      </div>

      {/* PAYMENTS */}
      <div className="aux-config-tab" style={{ width: '100%', display: activeTab === 1 ? 'inline-block' : 'none' }}>
        {!isForLiveBadgeApplication ? (
          <Alert color="warning">
            This portion of the config can only be changed for live (in-progress) badge applications
          </Alert>
        ) : (
          <BadgeApplicationPaymentEditor
            isAlreadyPaid={!!config.isPaid}
            paymentConfig={badgeApplicationConfig.auxiliaryConfig.payments}
            onChanged={onPaymentsConfigChanged}
          />
        )}
      </div>

      {/* RAW JSON */}
      <div className="aux-config-tab" style={{ display: activeTab === 2 ? 'inline-block' : 'none' }}>
        <Alert color="info">
          Allowed notification placeholders:{' '}
          <i>[applicant_name], [airport_person_id], [signatory_name], [badge_type]</i>
        </Alert>
        {error && (
          <Alert color="warning" fade={false}>
            {error}
          </Alert>
        )}
        <div style={{ height: 750 }}>
          <div className="separator"></div>
          <AceEditor
            name="badge-application-aux-config"
            style={{ zIndex: 0 }}
            mode="json"
            theme="chrome"
            width="100%"
            height="100%"
            defaultValue={JSON.stringify(badgeApplicationConfig.auxiliaryConfig, null, 2)}
            onChange={onJsonChange}
            editorProps={{ $blockScrolling: false }}
            setOptions={{ useWorker: false }}
          />
          <div className="separator"></div>
        </div>
      </div>
    </AirBadgeModal>
  );
}
