import React, { useContext, useEffect, useRef, useState } from 'react';
import Cropper from 'cropperjs';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'reactstrap';
import { useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TabActions, ValidateContext } from '../ValidateBadgeApplicationAction';
import request, { upload } from '../../../../../../../../ajax/Request';
import { AppContext, colors } from '../../../../../../../../App';
import { ActionPanelContext } from '../../../BadgeApplicationActionPanel';
import {
  buildFullName,
  formatBytes,
  formatDateWithoutTimeAndTimezone,
  handleError,
  notify,
} from '../../../../../../../../utils';
import AddIdentificationDocumentModal from '../../../../BadgeApplicationVerifyIds/AddIdentificationDocumentModal';
import WebcamCaptureModal from '../../../../../../Badges/BadgesList/WebcamCaptureModal';
import { convertPngToJpg, isImageFileTooBig, resizeImage } from '../../../../../../../../imageUtils';
import AirBadgeModal, {
  MODAL_SIZE_MEDIUM,
} from '../../../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import FileUploadForm from '../../../../../../../../shared/components/FileUploadModal/FileUploadForm';
import TitledBox from '../../../../../../../../shared/TitledBox/TitledBox';
import documentRequirements from '../../../../../../../Badger/steps/Scanning/components/documentRequirements';
import countries from '../../../../../../../Badger/steps/DataConfirmation/questions/countries';
import Collapsible from '../../../../../../../../shared/components/Collapsible/Collapsible';
import filesApi from '../../../../../../../../ajax/File/filesApi';
import useConfirm from '../../../../../../../../shared/useConfirm';
import DeleteDocumentModal from '../../../../BadgeApplicationVerifyIds/DeleteDocumentModal';
import badgeApplicationCommentApi from '../../../../../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';
import ImageEditor from '../../../../../../../../shared/ImageEditor/ImageEditor';
import RejectIdModal from './RejectIdModal';

export function ExtraDocumentHelp({ documentCode, style, shouldOnlyReturnText = false }) {
  const code = documentCode?.toLocaleLowerCase();
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });
  const i18nTag = `full-documents-list.${code}_extra`;
  const text = t(i18nTag);
  if (text && text !== `badger.${i18nTag}`) {
    if (shouldOnlyReturnText) {
      return <div style={style}>{text}</div>;
    }
    return (
      <Alert color="info" style={style}>
        {text}
      </Alert>
    );
  }
  return null;
}

function DocumentEntry({ document, onClick, isSelected }) {
  const { fileTypeName, isScanVerified } = document;
  const icon = isScanVerified ? 'fa-solid fa-circle-check text-success' : 'fa-regular fa-circle';
  return (
    <Chip
      clickable
      variant={isSelected ? 'default' : 'outlined'}
      label={fileTypeName.replace('Identity: ', '')}
      onClick={() => onClick({ document })}
      icon={<i className={icon} style={{ marginLeft: 15, marginRight: 0, padding: 0, fontSize: 16 }}></i>}
      disabled={isSelected}
    />
  );
}

export function IDInformationDisplay({ document, shouldReduceSize = false }) {
  const app = useContext(AppContext);
  if (!document) return null;

  const { documentNumber, expirationDate, stateAbbreviation, threeCharacterCountryCode, scanDocumentCode } = document;

  const requirements = documentRequirements[scanDocumentCode?.toLocaleLowerCase()];
  const leftStyle = { textAlign: 'right', fontWeight: 600 };
  const rightStyle = { textAlign: 'left' };
  const hasDocumentNumber = !!requirements?.documentNumber?.enabled;
  const hasExpirationDate = !!requirements?.expirationDate?.enabled;
  let issuingAuthority = null;
  if (stateAbbreviation && threeCharacterCountryCode) {
    issuingAuthority = `${stateAbbreviation}, ${threeCharacterCountryCode}`;
  } else if (stateAbbreviation) {
    issuingAuthority = stateAbbreviation;
  } else if (threeCharacterCountryCode) {
    issuingAuthority = threeCharacterCountryCode;
    const country = countries.find(c => c.value === threeCharacterCountryCode)?.label;
    if (country) {
      issuingAuthority = `${country} (${threeCharacterCountryCode})`;
    }
  }

  const getExpirationDateDisplay = () => {
    if (!expirationDate) return <i>not entered</i>;
    const date = formatDateWithoutTimeAndTimezone({
      date: expirationDate,
      format: 'YYYY-MM-DD',
    });
    const now = new Date();
    const expiration = new Date(date);
    const diff = expiration - now;
    const days = diff / (1000 * 60 * 60 * 24);
    let warning = '';
    if (days < 365) {
      warning = <i className="ml-2">(expiring in less than 1 year)</i>;
    } else if (days < 730) {
      warning = <i className="ml-2">(expiring in less than 2 years)</i>;
    }

    return (
      <>
        {moment.tz(expirationDate, app.timezone).format('MMMM DD, YYYY')}
        {warning}
      </>
    );
  };

  const leftColumnSize = shouldReduceSize ? 4 : 5;
  const rightColumnSize = shouldReduceSize ? 8 : 7;

  return (
    <Grid container direction="column" spacing={1}>
      {hasDocumentNumber && (
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={leftColumnSize} style={leftStyle}>
            DOCUMENT NUMBER
          </Grid>
          <Grid item xs={rightColumnSize} style={rightStyle}>
            {documentNumber || <i>not entered</i>}
          </Grid>
        </Grid>
      )}
      {hasExpirationDate && (
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={leftColumnSize} style={leftStyle}>
            EXPIRATION DATE
          </Grid>
          <Grid item xs={rightColumnSize} style={rightStyle}>
            {getExpirationDateDisplay()}
          </Grid>
        </Grid>
      )}
      {!!issuingAuthority && (
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={leftColumnSize} style={leftStyle}>
            ISSUING AUTHORITY
          </Grid>
          <Grid item xs={rightColumnSize} style={rightStyle}>
            {issuingAuthority || 'not entered'}
          </Grid>
        </Grid>
      )}
      {/* TODO: Driver's License specific fields */}
      {/*{scanDocumentCode === 'drivers-license' && (*/}
      {/*  <>*/}
      {/*    <Grid container item direction="row" spacing={2}>*/}
      {/*      <Grid item xs={leftColumnSize} style={leftStyle}>*/}
      {/*        COMMERCIAL LICENSE*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={rightColumnSize} style={rightStyle}>*/}
      {/*        {isCommercialDriversLicense ? 'YES' : 'NO'}*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*    <Grid container item direction="row" spacing={2}>*/}
      {/*      <Grid item xs={leftColumnSize} style={leftStyle}>*/}
      {/*        RESTRICTIONS*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={rightColumnSize} style={rightStyle}>*/}
      {/*        {restrictions || 'not entered'}*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </>*/}
      {/*)}*/}
    </Grid>
  );
}

function NameDisplay({ applicant }) {
  const { badgerData } = applicant;
  const { aliases } = badgerData;
  const labelStyle = { textAlign: 'right', fontWeight: 600 };
  const oldLegalName = buildFullName(applicant);
  const newLegalName = buildFullName(badgerData);
  const isChanged = oldLegalName !== newLegalName;
  return (
    <Grid container direction="column" spacing={1}>
      <Grid container item direction="row" spacing={2}>
        <Grid item xs={3} style={labelStyle}>
          LEGAL NAME
        </Grid>
        <Grid item xs={9} style={{ textAlign: 'left' }}>
          {newLegalName}{' '}
          {isChanged && (
            <span className="text-warning ml-2" style={{ fontWeight: 'bold' }}>
              ! NEW !
            </span>
          )}
        </Grid>
      </Grid>
      {aliases?.length > 0 ? (
        <>
          {aliases.map((a, i) => (
            <Grid key={i} container item direction="row" spacing={2}>
              <Grid item xs={3} style={labelStyle}>
                ALIAS
              </Grid>
              <Grid item xs={9} style={{ textAlign: 'left' }}>
                {buildFullName(a)}
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={3} style={labelStyle}>
            ALIASES
          </Grid>
          <Grid item xs={9} style={{ textAlign: 'left', fontStyle: 'italic' }}>
            none given
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

let requestTimeout = null;
let abortController = null;

export default function ValidateDocuments({ isOpen, isPaymentsEnabled }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isAddIdentificationDocumentModalOpen, setIsAddIdentificationDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isWebcamCaptureModalOpen, setIsWebcamCaptureModalOpen] = useState(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [isBadgeApplicationShown, setIsBadgeApplicationShown] = useState(false);
  const [nameChoice, setNameChoice] = useState(null);
  const [isExcludedFromVetting, setIsExcludedFromVetting] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [isRejectIdModalOpen, setIsRejectIdModalOpen] = useState(false);
  const [rejectIdMode, setRejectIdMode] = useState(null);

  const showConfirm = useConfirm();
  const theme = useTheme();
  const app = useContext(AppContext);
  const {
    setShouldReloadBadgeApplication,
    gotoTab,
    closeModal,
    onStepMarkedComplete,
    isBackgroundCheckAlreadyStarted,
  } = useContext(ValidateContext);
  const { badgeApplication, updateBadgeApplicationConfig } = useContext(ActionPanelContext);
  const badgeApplicationIFrameRef = useRef();

  const onMarkComplete = async () => {
    if (badgeApplication?.config?.validation?.isComplete) {
      return closeModal({ shouldReloadBadgeApplication: true, isValidationTotallyComplete: true });
    }

    app.api.toggleLoading(true);
    setShouldReloadBadgeApplication(true);
    badgeApplication.config.validation.meta.documentValidation.isComplete = true;
    if (isPaymentsEnabled) {
      await updateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
      onStepMarkedComplete(badgeApplication.config);
      app.api.toggleLoading(false);
      gotoTab(3);
    } else {
      // Should fingerprints be added now that validation is 100% complete?
      if (
        badgeApplication.config.validation.meta.personValidation.chrcExemption.choice === 'false' &&
        badgeApplication.config.fingerprintAppointment.isEnabled === false
      ) {
        badgeApplication.config.fingerprintAppointment.isEnabled = true;
      }
      badgeApplication.config.validation.isComplete = true;
      await updateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
      onStepMarkedComplete(badgeApplication.config);
      badgeApplicationCommentApi.create({
        badgeApplicationUuid: badgeApplication.uuid,
        comment: 'Badge Application Validation completed',
      });
      closeModal({ shouldReloadBadgeApplication: true, isValidationTotallyComplete: true });
    }
  };

  const isMarkCompleteDisabled = () => {
    if (badgeApplication?.config?.validation?.isComplete) {
      return !isPaymentsEnabled;
    }

    if (!documents?.length) return true;
    let foundLegalName = false;
    for (const document of documents) {
      if (!document.isScanVerified) return true;
      if (document.typeOfNameOnDocument === 'legal') foundLegalName = true;
    }
    return !foundLegalName;
  };

  const markAsApproved = () => {
    app.api.toggleLoading(true);
    request('authenticated-user', 'PUT', `badger/scan/${selectedDocument.scanUuid}/verify`, {
      verified: true,
      badgeApplicationUuid: badgeApplication.uuid,
      isExcludedFromVetting,
      documentNumber: selectedDocument.documentNumber,
      expirationDate: selectedDocument.expirationDate,
      country: selectedDocument.threeCharacterCountryCode,
      stateAbbreviation: selectedDocument.stateAbbreviation,
      typeOfNameOnDocument: nameChoice,
      shouldPatchBackgroundCheck: isBackgroundCheckAlreadyStarted,
    })
      .then(() => {
        if (isBackgroundCheckAlreadyStarted) {
          notify({ message: 'Background Check Updated' });
        }
        setHasChanges(false);
        setSelectedDocument(null);
        setShouldReloadBadgeApplication(true);
        loadData();
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onMarkDocumentApprovedClick = () => {
    if (!isExcludedFromVetting && selectedDocument.bytes > 1999999) {
      showConfirm({
        title: 'Are you sure?',
        content: 'This image is too large to transmit to TSC. Are you sure you want to proceed?',
        onConfirm: () => markAsApproved(),
        okButtonText: 'Mark Approved',
      });
    } else {
      markAsApproved();
    }
  };

  const getBadgeApplicationDocument = () => {
    filesApi
      .listForBadgeApplication({ badgeApplicationUuid: badgeApplication.uuid })
      .then(({ files = [] }) => {
        const badgeApplicationDocument = files.find(f => f.fileTypeName === 'Badge Application');
        if (!badgeApplicationDocument) {
          return;
        }

        function handler() {
          if (
            this.readyState === this.DONE &&
            this.status === 200 &&
            document.querySelector('#badge-application-frame')
          ) {
            document.querySelector('#badge-application-frame').src = URL.createObjectURL(this.response);
            document
              .querySelector('#badge-application-frame')
              .setAttribute('data-fileUuid', badgeApplicationDocument.uuid);
          }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('GET', `/api.php/default/v1/files/${badgeApplicationDocument.uuid}/stream`);
        xhr.setRequestHeader('X-AUTH-TOKEN', app.user.authToken);
        xhr.setRequestHeader('X-PROFILE-UUID', app.user.activeProfile ? app.user.activeProfile.uuid : '');
        xhr.onreadystatechange = handler;
        xhr.responseType = 'blob';
        xhr.send();
      })
      .catch(error => handleError({ error }));
  };

  const openSelectedDocumentInNewTab = ({ fileUuid }) => {
    app.api.toggleLoading(true);

    function handler() {
      if (this.readyState === this.DONE && this.status === 200) {
        const url = URL.createObjectURL(this.response);
        window.open(url, '_blank');
      }

      app.api.toggleLoading(false);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/api.php/default/v1/files/${fileUuid || selectedDocument.uuid}/stream`);
    xhr.setRequestHeader('X-AUTH-TOKEN', app.user.authToken);
    xhr.setRequestHeader('X-PROFILE-UUID', app.user.activeProfile ? app.user.activeProfile.uuid : '');
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.send();
  };

  const popOutBadgeApplicationPdf = () => {
    const fileUuid = document.querySelector('#badge-application-frame').getAttribute('data-fileUuid');
    openSelectedDocumentInNewTab({ fileUuid });
  };

  const onRequestNewImageClick = () => {
    setRejectIdMode('replace');
    setIsRejectIdModalOpen(true);
  };

  const uploadFile = async ({ file }) => {
    if (cropper) cropper.destroy();
    setCropper(null);
    setIsLoadingImage(true);
    setSelectedDocument(null);
    const { success, fileUuid, size, name } = await upload(
      'authenticated-user',
      'POST',
      `badge-applications/${badgeApplication.uuid}/file/${selectedDocument.scanUuid}`,
      file,
      { size: file.size }
    );

    if (!success) {
      return handleError({ error: 'Failed to upload image' });
    }

    setNameChoice(null);
    request('authenticated-user', 'PUT', `badger/scan/${selectedDocument.scanUuid}/verify`, {
      verified: false,
      badgeApplicationUuid: badgeApplication.uuid,
    }).catch(error => handleError({ error }));

    if (!isBackgroundCheckAlreadyStarted) {
      badgeApplication.config.validation.meta.documentValidation.isComplete = false;
      badgeApplication.config.validation.isComplete = false;
      await updateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
      onStepMarkedComplete(badgeApplication.config);
    }

    const newSelectedDocument = {
      ...selectedDocument,
      uuid: fileUuid,
      scanStatus: 'done',
      bytes: size,
      name,
      typeOfNameOnDocument: null,
    };
    setSelectedDocument(newSelectedDocument);
    loadData();
    onDocumentEntryClick({ document: newSelectedDocument });
  };

  const onFilesAdded = async files => {
    setIsFileUploadOpen(false);
    if (files?.length) {
      setIsLoadingImage(true);
      let file = files[0];
      file = await convertPngToJpg(file);
      if (isImageFileTooBig(file)) {
        file = await resizeImage(file);
      }
      await uploadFile({ file });
    }
  };

  const onWebcamCaptureModalClose = async args => {
    setIsWebcamCaptureModalOpen(false);

    if (!args?.imageData) return;

    let imageFile = await convertPngToJpg(args.imageData);
    if (isImageFileTooBig(imageFile)) {
      imageFile = await resizeImage(imageFile);
    }
    await uploadFile({ file: imageFile });
  };

  const onDocumentEntryClick = ({ document }) => {
    setImageDataUrl(null);
    setHasImage(Number(document.bytes) > 0);
    setSelectedDocument(document);
    setIsLoadingImage(true);
    setNameChoice(document.typeOfNameOnDocument || null);
    setIsExcludedFromVetting(!!document.isExcludedFromVetting);
    setHasChanges(false);
    if (cropper) cropper.destroy();
    setCropper(null);

    const mimeType = (document.mimeType || '').toLocaleLowerCase();
    const name = (document.name || '').toLocaleLowerCase();
    const isPdf = mimeType.includes('pdf') || name.endsWith('pdf');
    setIsPdf(isPdf);

    if (window.document.querySelector('#document-image')) {
      window.document.querySelector('#document-image').src = null;
    }
    if (window.document.querySelector('#document-pdf')) {
      window.document.querySelector('#document-pdf').src = 'about:blank';
    }

    function handler() {
      if (this.readyState === this.DONE && this.status === 200) {
        if (isPdf && window.document.querySelector('#document-pdf')) {
          window.document.querySelector('#document-pdf').src = URL.createObjectURL(this.response);
        } else if (window.document.querySelector('#document-image')) {
          const imageElement = window.document.querySelector('#document-image');
          const imageDataUrl = URL.createObjectURL(this.response);
          setImageDataUrl(imageDataUrl);
          imageElement.src = imageDataUrl;
          const cropper = new Cropper(imageElement, {
            viewMode: 1,
            dragMode: 'move',
            zoomOnWheel: true,
            autoCrop: false,
            movable: true,
            wheelZoomRatio: 0.3,
            background: false,
            toggleDragModeOnDblclick: false,
            minContainerWidth: 515,
            minContainerHeight: 395,
          });
          setCropper(cropper);
        }
      }

      setTimeout(() => setIsLoadingImage(false), 500);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/api.php/default/v1/files/${document.uuid}/stream`);
    xhr.setRequestHeader('X-AUTH-TOKEN', app.user.authToken);
    xhr.setRequestHeader('X-PROFILE-UUID', app.user.activeProfile ? app.user.activeProfile.uuid : '');
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.send();
  };

  const onDeleteDocument = wasDeleted => {
    setIsDeleteDocumentModalOpen(false);
    if (wasDeleted) {
      setSelectedDocument(null);
      loadData();
    }
  };

  const onAddDocumentComplete = async didMakeChanges => {
    setIsAddIdentificationDocumentModalOpen(false);
    if (didMakeChanges) {
      if (!isBackgroundCheckAlreadyStarted) {
        badgeApplication.config.validation.meta.documentValidation.isComplete = false;
        badgeApplication.config.validation.isComplete = false;
        await updateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
      }
      setSelectedDocument(null);
      loadData();
    }
  };

  const onAddDocumentClick = () => {
    setIsAddIdentificationDocumentModalOpen(true);
  };

  const onImageEditorClose = ({ imageDataUrl, newFile }) => {
    setIsImageEditorOpen(false);
    if (imageDataUrl) {
      if (cropper) cropper.destroy();
      setCropper(null);
      setSelectedDocument(newFile);
      loadData();
      onDocumentEntryClick({ document: newFile });
    }
  };

  const onCropImageClick = () => {
    setIsImageEditorOpen(true);
  };

  const onCaptureImageClick = () => {
    setIsWebcamCaptureModalOpen(true);
  };

  const loadData = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setIsLoading(true);
    setDocuments([]);

    requestTimeout = setTimeout(() => {
      setIsLoading(true);
      abortController = new AbortController();
      request(
        'authenticated-user',
        'GET',
        `badge-applications/${badgeApplication.uuid}/documents-to-verify`,
        null,
        abortController.signal
      )
        .then(({ files }) => setDocuments(files || []))
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  const isImageRequired = () => {
    if (!selectedDocument) return false;
    return !!documentRequirements[selectedDocument.scanDocumentCode]?.image;
  };

  const isApproveIdDisabled = () => {
    if (!selectedDocument) return true;
    if (Number(selectedDocument.bytes) < 1 && isImageRequired()) {
      return true;
    }
    if (isBackgroundCheckAlreadyStarted && !selectedDocument.scanVerifiedOn) {
      return false;
    }
    return nameChoice === null || isExcludedFromVetting === null || !hasChanges;
  };

  const onRejectIdModalClose = async ({ wasRejected }) => {
    setIsRejectIdModalOpen(false);
    if (wasRejected) {
      setShouldReloadBadgeApplication(true);
      if (rejectIdMode === 'reject') {
        setSelectedDocument(null);
        closeModal({ shouldReloadBadgeApplication: true });
      } else {
        setSelectedDocument(null);
        if (!isBackgroundCheckAlreadyStarted) {
          badgeApplication.config.validation.meta.documentValidation.isComplete = false;
          badgeApplication.config.validation.isComplete = false;
          await updateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
          onStepMarkedComplete(badgeApplication.config);
        }
        loadData();
      }
    }
  };

  const onRejectIdClick = () => {
    setRejectIdMode('reject');
    setIsRejectIdModalOpen(true);
  };

  const shouldShowImageButtons = () => {
    if (isBackgroundCheckAlreadyStarted) {
      return isImageRequired() && !hasImage;
    }
    return true;
  };

  const hasAtLeastOneDocumentWithLegalName = () => {
    return (documents || []).find(x => x.typeOfNameOnDocument === 'legal') !== undefined;
  };

  useEffect(() => {
    setRejectIdMode(null);
    setImageDataUrl(null);
    setCropper(null);
    setHasImage(false);
    setIsPdf(false);
    setIsLoading(false);
    setIsLoadingImage(false);
    setDocuments([]);
    setSelectedDocument(null);
    setIsBadgeApplicationShown(false);
    setHasChanges(false);

    if (isOpen) {
      loadData();
    }

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (badgeApplicationIFrameRef.current) {
      badgeApplicationIFrameRef.current.src = 'about:blank';
      getBadgeApplicationDocument();
    }
  }, [badgeApplicationIFrameRef]);

  return (
    <>
      <Grid container>
        <style>{`
          .no-margin {
            margin-bottom: 0;
          }
        `}</style>

        {/* --------- */}
        {/* LEFT SIDE */}
        {/* --------- */}
        <Grid container item xs={6} direction="column">
          <Grid container item direction="column" spacing={2}>
            {isLoading && (
              <Grid item>
                <CircularProgress size={45} />
              </Grid>
            )}
            {documents.map(document => (
              <Grid item key={document.uuid}>
                <DocumentEntry
                  document={document}
                  onClick={onDocumentEntryClick}
                  isSelected={document.uuid === selectedDocument?.uuid}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <Button className="mt-5" color="primary" onClick={onAddDocumentClick} disabled={isLoading}>
              <i className="fa-solid fa-plus mr-2"></i>Add Another Document
            </Button>
          </Grid>
        </Grid>

        {/* --------- */}
        {/* RIGHT SIDE */}
        {/* --------- */}
        <Grid container item xs={6} direction="column" spacing={2}>
          {!selectedDocument ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                border: '1px dashed #ccc',
                backgroundColor: '#eee',
                borderRadius: 5,
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 18,
              }}
            >
              {isLoadingImage ? (
                <CircularProgress size={45} />
              ) : (
                <div style={{ opacity: 0.6 }}>Select a document to validate</div>
              )}
            </div>
          ) : (
            <>
              <Grid item>
                {selectedDocument && Number(selectedDocument?.bytes) > 1999999 && (
                  <div style={{ textAlign: 'center', marginBottom: 10 }}>
                    <div style={{ color: 'red', fontWeight: 'bold' }}>
                      File size is {formatBytes(selectedDocument.bytes)}
                    </div>
                    <div style={{ fontSize: 12 }}>
                      <i>( If this file is going to be transmitted to TSC, it must be less than 2MB )</i>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    textAlign: 'center',
                    border: '1px dashed #ccc',
                    backgroundColor: '#eee',
                    borderRadius: 5,
                    padding: 5,
                    position: 'relative',
                  }}
                >
                  {isLoadingImage && <CircularProgress size={45} />}
                  {!hasImage && !isLoadingImage && (
                    <div style={{ padding: 30 }}>
                      {isImageRequired() ? (
                        <div style={{ fontWeight: 'bold', color: colors.danger }}>
                          {selectedDocument?.scanStatus === 'rescan'
                            ? 'Waiting on applicant to provide a new image'
                            : 'IMAGE REQUIRED'}
                        </div>
                      ) : (
                        'No image provided'
                      )}
                    </div>
                  )}
                  <img
                    id="document-image"
                    alt={!hasImage || isLoadingImage || isPdf ? '' : 'Document Image'}
                    style={{
                      display: !hasImage || isLoadingImage || isPdf ? 'none' : 'inline-block',
                      width: 515,
                      height: 395,
                    }}
                  />
                  <iframe
                    id="document-pdf"
                    title="Identity Document"
                    src="about:blank"
                    style={{
                      display: !hasImage || isLoadingImage || !isPdf ? 'none' : 'inline-block',
                      width: 515,
                      height: 395,
                    }}
                  ></iframe>
                </div>
              </Grid>
              {!isLoadingImage && hasImage && (
                <Grid item style={{ textAlign: 'center', position: 'relative', paddingTop: isPdf ? 0 : 15 }}>
                  {!isPdf && (
                    <>
                      <div
                        style={{
                          position: 'absolute',
                          top: -9,
                          right: '0%',
                          width: '100%',
                          opacity: 0.6,
                          fontStyle: 'italic',
                          fontSize: 12,
                        }}
                      >
                        Click and drag to move the image. Use the mouse scroll button to zoom in and out.
                      </div>
                      {!(isBackgroundCheckAlreadyStarted && selectedDocument.scanVerifiedOn) && (
                        <Button size="sm" onClick={onCropImageClick} className="mr-3">
                          <i className="fa-solid fa-pencil mr-2"></i>
                          Modify Image
                        </Button>
                      )}
                      <Button size="sm" onClick={() => cropper.reset()} className="mr-3">
                        <i className="fa-solid fa-clock-rotate-left mr-2"></i>
                        Reset Image Position
                      </Button>
                    </>
                  )}
                  <Button size="sm" onClick={openSelectedDocumentInNewTab}>
                    <i className="fa-solid fa-arrow-up-right-from-square mr-2"></i>Pop Out
                  </Button>
                </Grid>
              )}

              {shouldShowImageButtons() && (
                <Grid item style={{ textAlign: 'center' }}>
                  <Button onClick={onCaptureImageClick} disabled={isLoadingImage} className="mr-3">
                    <i className="fa-solid fa-camera mr-2"></i> Capture Image
                  </Button>
                  <Button onClick={() => setIsFileUploadOpen(true)} disabled={isLoadingImage} className="mr-3">
                    <i className="fa-solid fa-cloud-arrow-up mr-2"></i>Upload Image
                  </Button>
                  <Button
                    onClick={onRequestNewImageClick}
                    disabled={selectedDocument?.scanStatus === 'rescan'}
                    title={
                      selectedDocument?.scanStatus === 'rescan'
                        ? 'Waiting on applicant to provide a new image'
                        : undefined
                    }
                  >
                    <i className="fa-regular fa-envelope mr-2"></i>Request New Image
                  </Button>
                </Grid>
              )}

              {!isBackgroundCheckAlreadyStarted && (
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="flex-start"
                  direction="column"
                  style={{ marginTop: 16 }}
                >
                  <TitledBox title="Names Found On Badge Application" style={{ width: '100%' }}>
                    <NameDisplay applicant={badgeApplication.applicant} />
                    <div className="required" style={{ fontWeight: 'bold', marginTop: 24 }}>
                      Name shown on this document is
                    </div>
                    <RadioGroup
                      value={nameChoice}
                      onChange={event => {
                        setNameChoice(event.target.value);
                        setHasChanges(true);
                      }}
                      row
                    >
                      <FormControlLabel
                        value="legal"
                        control={<Radio color="primary" />}
                        label="Legal Name"
                        classes={{ root: 'mb-0' }}
                      />
                      <FormControlLabel
                        value="alias"
                        control={<Radio color="primary" />}
                        label="Alias"
                        classes={{ root: 'mb-0' }}
                      />
                      <FormControlLabel
                        value="n_a"
                        control={<Radio color="primary" />}
                        label="N/A"
                        classes={{ root: 'mb-0' }}
                      />
                      <FormControlLabel
                        value="not_listed"
                        control={<Radio color="primary" />}
                        label="Not Listed"
                        classes={{ root: 'mb-0' }}
                      />
                    </RadioGroup>
                  </TitledBox>
                </Grid>
              )}

              <Grid container item style={isBackgroundCheckAlreadyStarted ? { marginTop: 16 } : undefined}>
                <TitledBox title="ID Information Entered On Badge Application" style={{ width: '100%' }}>
                  {!isBackgroundCheckAlreadyStarted && (
                    <>
                      <Alert color="info">
                        <div>Validate the information below matches the document</div>
                        <ExtraDocumentHelp
                          documentCode={selectedDocument?.scanDocumentCode || ''}
                          shouldOnlyReturnText={true}
                          style={{ marginTop: 10 }}
                        />
                      </Alert>
                    </>
                  )}
                  <IDInformationDisplay document={selectedDocument} />
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 24 }}
                  >
                    <Button style={{ width: 125 }} color="warning" onClick={() => setIsDeleteDocumentModalOpen(true)}>
                      <i className="fa-solid fa-minus mr-2"></i>Remove ID
                    </Button>
                    {!isBackgroundCheckAlreadyStarted && (
                      <Button style={{ width: 125 }} color="danger" className="ml-3 mr-3" onClick={onRejectIdClick}>
                        <i className="fa-solid fa-ban mr-2"></i>Reject ID
                      </Button>
                    )}
                    <Button
                      style={{ width: !isBackgroundCheckAlreadyStarted ? 125 : 200 }}
                      color="primary"
                      disabled={isApproveIdDisabled()}
                      onClick={onMarkDocumentApprovedClick}
                    >
                      <i className="fa-solid fa-circle-check mr-2"></i>
                      <span>{!isBackgroundCheckAlreadyStarted ? 'Approve ID' : 'Approve ID & Transmit'}</span>
                    </Button>
                  </Grid>
                </TitledBox>
              </Grid>
              {!isBackgroundCheckAlreadyStarted && (
                <Grid item>
                  <TitledBox title="Transmission Decision" style={{ width: '100%' }}>
                    <div style={{ fontWeight: 'bold' }}>Should this ID be excluded from the vetting transmission?</div>
                    <RadioGroup
                      value={`${isExcludedFromVetting}`}
                      onChange={event => {
                        setIsExcludedFromVetting(event.target.value === 'true');
                        setHasChanges(true);
                      }}
                      row
                    >
                      <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                  </TitledBox>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <div style={{ marginBottom: theme.spacing(4) }}>&nbsp;</div>

      <Collapsible
        id="badge-application"
        title="Signed Badge Application"
        icon={<i className="fa-regular fa-file-lines mr-2"></i>}
        expanded={isBadgeApplicationShown}
        onChange={() => setIsBadgeApplicationShown(!isBadgeApplicationShown)}
      >
        <div className="mb-3">
          <Button size="sm" onClick={popOutBadgeApplicationPdf} style={{ width: 100 }}>
            <i className="fa-solid fa-arrow-up-right-from-square mr-2"></i>Pop Out
          </Button>
        </div>
        <iframe
          ref={badgeApplicationIFrameRef}
          id="badge-application-frame"
          title="Signed Badge Application"
          src="about:blank"
          style={{
            border: 0,
            width: '100%',
            height: '60vh',
          }}
        ></iframe>
      </Collapsible>

      <div style={{ marginBottom: theme.spacing(3) }}>&nbsp;</div>

      {!isBackgroundCheckAlreadyStarted &&
        isMarkCompleteDisabled() &&
        !isLoading &&
        !hasAtLeastOneDocumentWithLegalName() && (
          <Alert color="warning">
            Cannot complete validation. At least one ID must display the applicant's legal name. {nameChoice}
          </Alert>
        )}

      <TabActions>
        <Button onClick={() => gotoTab(1)}>
          <i className="fa-solid fa-chevron-left mr-2"></i>Badge / Training
        </Button>
        <div>
          {isBackgroundCheckAlreadyStarted ? (
            <Button onClick={() => closeModal()}>Close</Button>
          ) : (
            <>
              <Button onClick={() => closeModal()} className="mr-2">
                Close
              </Button>
              <Button
                color="primary"
                onClick={onMarkComplete}
                disabled={isMarkCompleteDisabled()}
                style={{ marginLeft: 15, minWidth: 100 }}
              >
                {isPaymentsEnabled && !badgeApplication?.config?.validation?.isComplete ? (
                  <>
                    Validate Payment<i className="fa-solid fa-chevron-right ml-2"></i>
                  </>
                ) : (
                  'Mark Validation Complete'
                )}
              </Button>
            </>
          )}
        </div>
      </TabActions>

      <AddIdentificationDocumentModal
        badgeApplication={badgeApplication}
        isOpen={isAddIdentificationDocumentModalOpen}
        onClose={onAddDocumentComplete}
      />

      <WebcamCaptureModal
        isOpen={isWebcamCaptureModalOpen}
        onClose={onWebcamCaptureModalClose}
        isCaptureOnlyMode={true}
        saveButtonLabel="Capture Image"
        title="Capture Document Image"
      />

      <ImageEditor
        isOpen={isImageEditorOpen}
        file={selectedDocument}
        imageDataUrl={imageDataUrl}
        onClose={onImageEditorClose}
      />

      <DeleteDocumentModal
        isOpen={isDeleteDocumentModalOpen}
        onClose={onDeleteDocument}
        badgeApplication={badgeApplication}
        selectedFile={selectedDocument}
      />

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Upload Document Image"
        isOpen={isFileUploadOpen}
        onClose={onFilesAdded}
      >
        <FileUploadForm onFilesAdded={onFilesAdded} fileUploadOnly />
      </AirBadgeModal>

      <RejectIdModal
        isOpen={isRejectIdModalOpen}
        onClose={onRejectIdModalClose}
        badgeApplication={badgeApplication}
        rejectedDocument={selectedDocument}
        mode={rejectIdMode}
      />
    </>
  );
}
