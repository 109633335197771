import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import formsApi from '../../ajax/Forms/formsApi';
import { handleError, isSmallScreen } from '../../utils';
import { colors } from '../../App';

export default function DocSpringTest() {
  const [tokenId, setTokenId] = useState(null);
  const [tokenSecret, setTokenSecret] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [dataRequestState, setDataRequestState] = useState(null);
  const [userUuid, setUserUuid] = useState('');
  const [formId, setFormId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function eventListenerCallback(event) {
    if (event?.data === 'cancelled') {
      window.DocSpring?.closeModal();
    }
  }

  const startTransaction = assignmentUuid => {
    setIsLoading(true);
    formsApi
      .generateDataRequestToken(assignmentUuid, true)
      .then(({ token, dataRequestId, state }) => {
        if (token) {
          const { id, secret } = token;
          setTokenId(id);
          setTokenSecret(secret);
        }

        if (dataRequestId) {
          setRequestId(dataRequestId);
        }

        if (state) {
          setDataRequestState(state);
        }
      })
      .catch(error => handleError({ error, message: 'Unable to display form' }))
      .finally(() => setIsLoading(false));
  };

  const cancelAssignment = assignmentUuid => {
    formsApi.cancelAssignment(assignmentUuid).catch(error => handleError({ error }));
  };

  const createOnTheFlyAssignment = () => {
    setIsLoading(true);
    formsApi
      .assignForm({ assignToUuid: userUuid, formId, isForTesting: true })
      .then(({ success, uuid }) => {
        if (success) {
          startTransaction(uuid);
          cancelAssignment(uuid);
        } else {
          handleError({ message: 'Unable to create document' });
          setIsLoading(false);
        }
      })
      .catch(error => {
        handleError({ error, message: 'Unable to create document' });
        setIsLoading(false);
      });
  };

  const onCancelled = () => {
    setRequestId(null);
    setTokenId(null);
    setTokenSecret(null);
    setDataRequestState(null);
  };

  useEffect(() => {
    if (requestId && tokenId && tokenSecret && dataRequestState !== 'completed') {
      const docSpringConfig = {
        domainVerification: false,
        dataRequestId: requestId,
        tokenId: tokenId,
        tokenSecret: tokenSecret,
        focusFirstFieldOnLoad: false,
        showSignatureModalOnFocus: false,
        closeModalOnClickOverlay: true,
        closeModalOnSave: true,
        showTermsOfServiceLink: false,
        submittingTitle: template => 'Submitting...',
        pdfProcessingTitle: template => 'Processing...',
        pdfProcessingMessage: template => `Please wait while we fill in ${template.name}...`,
        waitingForDataRequestsTitle: (remainingDataRequestsCount, template) => '',
        waitingForDataRequestsMessage: (remainingDataRequestsCount, template) =>
          `${remainingDataRequestsCount} signer(s) remaining, then the final PDF will be generated.`,
        completedTitle: (template, downloadURL) => 'Thank you!',
        completedTitleNoDownload: (template, downloadURL) => 'Thank you!',
        pdfProcessedMessage: (template, downloadURL) =>
          `Click the "Download PDF" button to download the filled in ${template.name} PDF.`,
        downloadButtonLabel: 'Download!',

        footerHTML: function () {
          return `
            <div class="dsp-footerLeft">
              <button class="dsp-button" style="background-color: ${colors.warning}" onclick="window.top.postMessage('cancelled', '*')">Cancel - Do Not Sign</button>
            </div>
          `;
        },

        onInitialize: function () {
          if (onCancelled) {
            // TODO: DocSpring - I am having to do this because the DocSpring JS API doesn't notify
            //       us when the modal is closed without submitting the data
            const overlay = document.getElementsByClassName('dsp-modal-overlay')[0];
            const observer = new MutationObserver(mutationsList => {
              for (const mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
                  // Check if the removedNodes array contains the target element
                  if (Array.from(mutation.removedNodes).includes(overlay)) {
                    onCancelled();
                  }
                }
              }
            });
            observer.observe(document.body, { childList: true });
          }
        },

        onSave: function (submissionId) {
          console.log(submissionId);
          onCancelled();
        },
      };

      if (isSmallScreen()) {
        docSpringConfig.iframeStyle = 'height: 100vh;';
      }

      window.DocSpring.createVisualForm(docSpringConfig);
    }
  }, [requestId, tokenId, tokenSecret, dataRequestState]);

  useEffect(() => {
    window.top.addEventListener('message', eventListenerCallback);

    return () => {
      window.top.removeEventListener('message', eventListenerCallback);
      setRequestId(null);
    };
  }, [requestId]);

  return (
    <div className="app">
      <div className="app-body m-0">
        <div id="mainContentArea" className="main">
          <div className="pt-4">
            <Container fluid={true}>
              <Row>
                <Col className="text-center">
                  <h1 className="mb-4 page-title">DocSpring Testing</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm={12} md={4}>
                          <FormGroup className="mb-4">
                            <Label>User UUID</Label>
                            <Input
                              type="text"
                              id="userUuid"
                              value={userUuid}
                              onChange={event => setUserUuid(event.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Label>Form ID</Label>
                            <Input
                              type="text"
                              id="formId"
                              value={formId}
                              onChange={event => setFormId(event.target.value)}
                            />
                          </FormGroup>
                          <Button
                            color="primary"
                            disabled={!userUuid || !formId}
                            style={{ width: 120 }}
                            onClick={createOnTheFlyAssignment}
                          >
                            Open Form
                          </Button>
                        </Col>
                      </Row>
                      {isLoading && (
                        <Row>
                          <Col>
                            <div className="mt-5">
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: 25 }}>
                                <CircularProgress size={30} thickness={4} />{' '}
                                <span style={{ paddingLeft: 10 }}>Loading...</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
