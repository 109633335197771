import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';

export default function ListADocuments({ isHidden, value, data, onChange, onNext }) {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isNextHidden, setIsNextHidden] = useState(true);

  if (isHidden) return null;

  return (
    <>
      <BlockFormLabel>
        <Typography variant="h6">{translate('listADocuments.question1.label')}</Typography>
      </BlockFormLabel>

      <FormControl>
        <RadioGroup
          aria-label="list A documents"
          name="listADocuments"
          value={value}
          onChange={({ target }) => {
            onChange(target.value);
            setIsNextHidden(!target.value);
          }}
        >
          {['usa_born', 'usa_naturalized', 'usa_born_abroad', 'non_citizen_national'].indexOf(data.citizenship.value) >
            -1 && (
            <FormControlLabel
              value="usa-passport"
              control={<Radio color="primary" />}
              label={translate('listADocuments.question1.answer1')}
            />
          )}

          {['usa_born', 'usa_naturalized', 'usa_born_abroad', 'non_citizen_national'].indexOf(data.citizenship.value) >
            -1 && (
            <FormControlLabel
              value="usa-passport-card"
              control={<Radio color="primary" />}
              label={translate('listADocuments.question1.answer2')}
            />
          )}

          {['permanent_resident', 'non_citizen_national'].indexOf(data.citizenship.value) > -1 && (
            <FormControlLabel
              value="permanent-resident-card"
              control={<Radio color="primary" />}
              label={translate('listADocuments.question1.answer3')}
            />
          )}

          {['permanent_resident', 'authorized_alien', 'non_citizen_national'].indexOf(data.citizenship.value) > -1 && (
            <FormControlLabel
              value="i-766"
              control={<Radio color="primary" />}
              label={translate('listADocuments.question1.answer4')}
            />
          )}

          {['permanent_resident', 'non_citizen_national'].indexOf(data.citizenship.value) > -1 && (
            <FormControlLabel
              value="foreign-passport-i-551"
              control={<Radio color="primary" />}
              label={translate('listADocuments.question1.answer5')}
            />
          )}

          {['permanent_resident', 'non_citizen_national'].indexOf(data.citizenship.value) > -1 && (
            <FormControlLabel
              value="foreign-passport-mriv"
              control={<Radio color="primary" />}
              label={translate('listADocuments.question1.answer6')}
            />
          )}

          {['permanent_resident', 'authorized_alien', 'non_citizen_national'].indexOf(data.citizenship.value) > -1 && (
            <>
              <FormControlLabel
                value="foreign-passport"
                control={<Radio color="primary" />}
                label={translate('listADocuments.question1.answer7')}
              />
            </>
          )}

          <FormControlLabel
            value="none"
            control={<Radio color="primary" />}
            label={translate('listADocuments.question1.answer8')}
          />
        </RadioGroup>
      </FormControl>

      <NextButton isDisabled={isNextHidden} onClick={onNext} />
    </>
  );
}
