import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { AppContext } from '../../../../App';
import AuthorizedSignatoryGetRequest from '../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryGetRequest';
import { handleError } from '../../../../utils';
import AuthorizedSignatoryReassignBadgesRequest from '../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryReassignBadgesRequest';

export default function ReassignSignatoryBadgesModal({ isOpen, onClose, signatory }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableSignatories, setAvailableSignatories] = useState([]);
  const [newAuthorizedSignatory, setNewAuthorizedSignatory] = useState(null);
  const [shouldOverrideFilter, setShouldOverrideFilter] = useState(false);

  const onModalClose = ({ shouldReload }) => {
    setShouldOverrideFilter(false);
    setNewAuthorizedSignatory(null);
    setAvailableSignatories([]);
    setIsModalOpen(false);
    onClose({ shouldReload });
  };

  const onSaveChange = () => {
    app.api.toggleLoading(true);
    AuthorizedSignatoryReassignBadgesRequest(null, signatory.profileUuid, newAuthorizedSignatory.value)
      .then(({ success }) => {
        if (success) onModalClose({ shouldReload: true });
        else handleError({ message: 'Unable to reassign badges' });
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const loadData = shouldOverrideFilter => {
    app.api.toggleLoading(true);
    setNewAuthorizedSignatory(null);
    AuthorizedSignatoryGetRequest()
      .then(records => {
        const options = records
          .filter(x => x.active)
          .filter(x => {
            if (shouldOverrideFilter) {
              return x.profile_id !== signatory.profileId;
            }
            return x.profile_id !== signatory.profileId && x.companyUUID === signatory.companyUuid;
          })
          .map(({ user, profile_id, companyName }) => {
            const { fullName } = user;
            return {
              label: `${fullName}, ${companyName}`,
              value: profile_id,
            };
          });
        setAvailableSignatories(options);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  useEffect(() => {
    if (!isOpen) return;

    loadData();
    setIsModalOpen(isOpen);
  }, [isOpen]);

  if (!isOpen || !signatory) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Reassign All Badges to Another Signatory"
      isOpen={isModalOpen}
      onClose={() => onModalClose({ shouldReload: false })}
      closeLabel="Close"
      onSave={onSaveChange}
      saveLabel="Reassign Badges"
      saveDisabled={!newAuthorizedSignatory}
    >
      <Row>
        <Col>
          <FormGroup>
            <Label>Select the new Authorized Signatory</Label>
            <Select
              classNamePrefix="airbadge"
              className="form-select"
              options={availableSignatories}
              value={newAuthorizedSignatory}
              onChange={setNewAuthorizedSignatory}
              placeholder=""
              noOptionsMessage={() => 'No alternate Signatories found'}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            color="primary"
            checked={shouldOverrideFilter}
            onChange={event => {
              setShouldOverrideFilter(event.target.checked);
              loadData(event.target.checked);
            }}
          />
          Allow reassign to companies other than <i>{signatory.companyName}</i>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
