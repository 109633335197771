import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';

export default class RedirectTo extends Component {
  render() {
    const search = this.props.location.search;

    const url = search.slice(1).replace('url=', '');

    if (!url) {
      return <Redirect to="/default/home" />;
    }

    return (
      <div className={'app flex-row align-items-center'}>
        <Container>
          <Row className={'justify-content-center'}>
            <Col md={'6'} className={'bg-white text-center rounded p-4'}>
              <div className={'clearfix'}>
                <h1 className={'display-4 mr-4'}>Redirection Notice</h1>

                <p className={'text-break'}>
                  The link you clicked leaves AirBadge and sends you to a third party website.
                </p>

                <p className={'text-break border my-2 p-2 bg-light rounded text-monospace'}>{url}</p>

                <p className={'text-break'}>
                  AirBadge does not control or monitor the third party website, its security, contents, reliability or
                  policies in any manner and makes no representation accordingly. AirBadge shall not be liable for its
                  use.
                </p>

                <p className={'text-break'}>Click the button below to go to that page.</p>

                <div className={'form-group'}>
                  <a href={url} className={'btn btn-primary'}>
                    <i className={'fa fa-check-circle-o mr-2'} />
                    OK
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
