import request from '../Request';

export default function AuthorizedSignatoryReassignBadgesRequest(
  user,
  uuid,
  newAuthorizedSignatoryProfileId,
  badgeUuid,
  comment
) {
  return request(user || 'authenticated-user', 'PUT', `authorized-signatories/${uuid}/reassign`, {
    new_authorized_signatory_profile_id: newAuthorizedSignatoryProfileId,
    badge: badgeUuid || undefined,
    comment: comment || undefined,
  });
}
