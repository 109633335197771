import { useContext } from 'react';
import trimStart from 'lodash/trimStart';
import globToRegExp from 'glob-to-regexp';
import { AppContext } from '../App';

const cache = {};

export default function Feature({ id, children }) {
  const app = useContext(AppContext);
  if (!app || !app.airport || !app.airport.config) return null;
  const { features } = app.airport.config;
  if (!id) return children;
  if (cache[id] === undefined || cache[id] === null) {
    cache[id] = hasFeature(id, features);
  }
  return cache[id] ? children : null;
}

export function hasFeature(id, features) {
  let enabled = true;
  if (!features || features.length === 0) {
    enabled = false;
  } else {
    const all = features.filter(c => !!c).map(c => c.trim());
    const direct = all.filter(c => !c.startsWith('!'));
    const negated = all.filter(c => c.startsWith('!')).map(c => trimStart(c, '!'));
    enabled = direct.some(c => globToRegExp(c, { extended: true }).test(id));
    if (enabled && negated.length) {
      enabled = enabled && !negated.some(c => globToRegExp(c, { extended: true }).test(id));
    }
  }
  return enabled;
}
