import React, { useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';

export default function ListCDocuments({ isHidden, value, data, onChange, onNext }) {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isNextHidden, setIsNextHidden] = useState(true);
  const citizenship = get(data, 'citizenship.value', '');

  if (isHidden) return null;

  const isTribalDocumentAlreadySelected = () => {
    return data.requiredDocuments.has('tribal-document');
  };

  return (
    <>
      <BlockFormLabel>
        <Typography variant="h6">{translate('listCDocuments.question1.label')}</Typography>
      </BlockFormLabel>
      <FormControl>
        <RadioGroup
          aria-label="list-c-documents"
          name="list-c-documents"
          value={value}
          onChange={({ target }) => {
            onChange(target.value);
            setIsNextHidden(!target.value);
          }}
        >
          {citizenship === 'usa_born' && (
            <>
              <FormControlLabel
                value="social-security-card"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer1')}
              />
              <FormControlLabel
                value="birth-certificate"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer4')}
              />
              {!isTribalDocumentAlreadySelected() && (
                <FormControlLabel
                  value="tribal-document"
                  control={<Radio color="primary" />}
                  label={translate('listCDocuments.question1.answer6')}
                />
              )}
            </>
          )}

          {citizenship === 'usa_naturalized' && (
            <>
              <FormControlLabel
                value="certificate-of-naturalization"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer5')}
              />
              <FormControlLabel
                value="I-197"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer7')}
              />
              <FormControlLabel
                value="I-179"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer8')}
              />
            </>
          )}

          {citizenship === 'usa_born_abroad' && (
            <>
              <FormControlLabel
                value="FS-545"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer2')}
              />
              <FormControlLabel
                value="FS-240"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer11')}
              />
              <FormControlLabel
                value="DS-1350"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer3')}
              />
              <FormControlLabel
                value="N-560"
                control={<Radio color="primary" />}
                label={translate('listCDocuments.question1.answer9')}
              />
            </>
          )}

          {['permanent_resident', 'authorized_alien', 'non_citizen_national'].indexOf(citizenship) > -1 && (
            <FormControlLabel
              value="social-security-card"
              control={<Radio color="primary" />}
              label={translate('listCDocuments.question1.answer1')}
            />
          )}

          <FormControlLabel
            value="none"
            control={<Radio color="primary" />}
            label={translate('listCDocuments.question1.answer10')}
          />
        </RadioGroup>
      </FormControl>

      <NextButton isHidden={isNextHidden} onClick={onNext} />
    </>
  );
}
