import React, { useContext, useEffect, useState } from 'react';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../AirBadgeModal/AirBadgeModal';
import actionItemApi from '../../../ajax/ActionItem/actionItemApi';
import { handleError } from '../../../utils';
import { AppContext } from '../../../App';
import { hasFeature } from '../../Feature';

let requestTimeout = null;
let abortController = null;

export default function ActionItemsSummaryModal({ isOpen, onClose }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summary, setSummary] = useState(null);

  const onModalClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const loadData = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      actionItemApi
        .getActionItemsSummary({ signal: abortController.signal })
        .then(({ success, summary }) => {
          if (success) {
            // setIsModalOpen(true);
            setSummary(summary);
          }
        })
        .catch(error => handleError({ error }));
    }, 500);
  };

  useEffect(() => {
    if (!isOpen || !app.airport) return;

    const { features } = app.airport.config;
    if (hasFeature('action-items-summary', features)) {
      loadData();
    }

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [isOpen, app.airport]);

  if (!isOpen || !app.airport) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Action Items Summary"
      isOpen={isModalOpen}
      onClose={onModalClose}
      closeLabel="Close"
    >
      {/*  */}
      {/*  */}
      {/*  */}
    </AirBadgeModal>
  );
}
