import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export default function ViewContainer({ title, iconClass, children }) {
  return (
    <div className="animated fadeIn">
      <Container fluid={true}>
        <Row>
          <Col>
            <h1 className="mb-4 page-title">
              {iconClass && <i className={`${iconClass} mr-3`} />}
              {title}
            </h1>
          </Col>
        </Row>
        {children}
      </Container>
    </div>
  );
}
