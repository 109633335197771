import React from 'react';
import { Alert } from 'reactstrap';

export default function BannedBanner({ user }) {
  if (user?.status !== 'banned' || user?.isBanned === false) return null;
  return (
    <Alert color="danger">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <i className="fa-solid fa-triangle-exclamation text-danger" style={{ fontSize: 20 }}></i>
        <div style={{ paddingLeft: 24, paddingRight: 24, textAlign: 'center' }}>
          <strong>This individual has been permanently banned from having a badge at this airport</strong>
        </div>
        <i className="fa-solid fa-triangle-exclamation text-danger" style={{ fontSize: 20 }}></i>
      </div>
    </Alert>
  );
}
