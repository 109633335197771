/*
 * LAKOTA FINGERPRINTS API
 * https://lakotasoftware.com
 */

function callApi({ method = 'GET', url, config, signal, isJson = true, data }) {
  const finalUrl = `${config.local_api_url}/${url}`;

  const headers = {
    'Content-Type': 'application/json',
    'devsvc-api-key': config.api_key,
    'devsvc-api-url': config.remote_api_url,
  };
  if (!isJson) {
    delete headers['Content-Type'];
  }
  if (config.local_api_url.indexOf('ngrok') > -1) {
    headers['ngrok-skip-browser-warning'] = 'true';
  }

  const fetchConfig = {
    mode: 'cors',
    method,
    headers,
  };
  if (signal) {
    fetchConfig.signal = signal;
  }

  if (data) {
    if (typeof data === 'object') {
      data = JSON.stringify(data);
    }
    fetchConfig.body = data;
  }

  return fetch(finalUrl, fetchConfig);
}

function testLocalApiConnection({ config }) {
  return callApi({ url: 'license', config, isJson: false });
}

function getListOfConnectedDevices({ config, signal }) {
  return callApi({ method: 'GET', url: 'device/finger/list', config, signal }).then(response => response.json());
}

function initializeDevice({ config, signal }) {
  return callApi({
    method: 'POST',
    url: 'device/finger/init/0',
    config,
    signal,
    isJson: false,
  });
}

function setFps({ config, fps }) {
  return callApi({
    method: 'POST',
    url: 'device/finger/fps',
    config,
    isJson: false,
    data: fps,
  });
}

function deInitializeDevice({ config }) {
  return callApi({
    method: 'POST',
    url: 'device/finger/deinit',
    config,
    isJson: false,
  });
}

function cancelScan({ config }) {
  return callApi({
    method: 'POST',
    url: 'device/finger/cancel',
    config,
    isJson: false,
  });
}

function getCurrentImage({ config }) {
  return callApi({
    method: 'GET',
    url: 'device/finger/image',
    config,
  }).then(response => response.json());
}

function forceCapture({ config }) {
  return callApi({
    method: 'POST',
    url: 'device/finger/image',
    config,
    isJson: false,
  });
}

function capture({ config, fingerPositions = '' }) {
  if (Array.isArray(fingerPositions)) {
    fingerPositions = fingerPositions.join(',');
  }
  const url = `device/finger/capture?frsType=${fingerPositions}`;
  return callApi({
    method: 'POST',
    url,
    config,
    isJson: false,
  });
}

function createEft({ config, data }) {
  return callApi({
    method: 'POST',
    url: 'captures/eft',
    config,
    data,
  }).then(response => response.json());
}

function detectFingers({ config }) {
  return callApi({ method: 'GET', url: 'device/finger/detect-fingers', config }).then(response => response.json());
}

export default {
  testLocalApiConnection,
  getListOfConnectedDevices,
  initializeDevice,
  setFps,
  deInitializeDevice,
  cancelScan,
  getCurrentImage,
  forceCapture,
  capture,
  createEft,
  detectFingers,
};

export const FINGER_POSITIONS = {
  LEFT_FOUR: 'LEFT_FOUR',
  FLAT_LEFT_THUMB: 'FLAT_LEFT_THUMB',
  FLAT_RIGHT_THUMB: 'FLAT_RIGHT_THUMB',
  RIGHT_FOUR: 'RIGHT_FOUR',
  THUMBS: 'THUMBS',
  FLAT_RIGHT_INDEX: 'FLAT_RIGHT_INDEX',
  FLAT_RIGHT_MIDDLE: 'FLAT_RIGHT_MIDDLE',
  FLAT_RIGHT_RING: 'FLAT_RIGHT_RING',
  FLAT_RIGHT_LITTLE: 'FLAT_RIGHT_LITTLE',
  FLAT_LEFT_INDEX: 'FLAT_LEFT_INDEX',
  FLAT_LEFT_MIDDLE: 'FLAT_LEFT_MIDDLE',
  FLAT_LEFT_RING: 'FLAT_LEFT_RING',
  FLAT_LEFT_LITTLE: 'FLAT_LEFT_LITTLE',
};
