import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import 'react-table/react-table.css';
import 'rc-datetime-picker/dist/picker.css';
import 'cropperjs/dist/cropper.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App, { colors } from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

let theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: colors.success,
    },
    danger: {
      main: colors.danger,
    },
    error: {
      main: colors.danger,
    },
    warning: {
      main: colors.warning,
    },
  },
});
theme = responsiveFontSizes(theme);

const user = JSON.parse(localStorage.getItem('AirportBadges.user'));

// Enable pusher logging - SHOULD NOT BE "true" IN PRODUCTION
let logToConsole = false;
if (process.env.REACT_APP_PUSHER_LOG_TO_CONSOLE === true || process.env.REACT_APP_PUSHER_LOG_TO_CONSOLE === 'true') {
  logToConsole = true;
}
window.Pusher.logToConsole = logToConsole;

// Setup Dynamsoft for image capture
window.OffscreenCanvas = null;
window.Dynamsoft.DWT.AutoLoad = false;
window.Dynamsoft.DWT.Containers = [{ ContainerId: 'dwtcontrolContainer', Width: 1, Height: 1 }];
window.Dynamsoft.DWT.ProductKey =
  'f0068UwAAACzBCUS6wNHslMxuf6S+RApuix633QA9Nj84JS9eAdUEXtYA/iPpltMvNYc9gbi6zzwCQ8tsXOTxNnHXNu+Z0As=';
window.Dynamsoft.DWT.UseLocalService = false;
window.Dynamsoft.DWT.IfAddMD5InUploadHeader = false;
window.Dynamsoft.DWT.IfConfineMaskWithinTheViewer = false;
window.Dynamsoft.DWT.ResourcesPath = '/Resources';

ReactDOM.render(
  <GoogleOAuthProvider clientId="186224256926-ia61ucev6bve830voab0m7cgt6kf17of.apps.googleusercontent.com">
    <ThemeProvider theme={theme}>
      <App user={user} />
    </ThemeProvider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
