import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../../../../../App';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import StartRenewalActionModal from './StartRenewalActionModal';
import { FixEmailModal } from '../../../../BadgeApplications/BadgeApplicationsList/BadgeApplicationModal';
import { notify } from '../../../../../../utils';

const defaultState = {
  isDisabled: false,
  title: 'Start Renewal',
};

export default function StartRenewalAction({ badge, history, onEmailChanged }) {
  const app = useContext(AppContext);
  const [actionState, setActionState] = useState({ ...defaultState });
  const [isBadgeApplicationsCreateModalOpen, setIsBadgeApplicationsCreateModalOpen] = useState(false);
  const [isFixEmailModalOpen, setIsFixEmailModalOpen] = useState(false);
  const [badgeHolder, setBadgeHolder] = useState(null);
  const today = moment().tz(app.timezone).startOf('day');
  const thirtyDaysFromToday = moment().tz(app.timezone).add(30, 'days').endOf('day');
  const badgeExpirationDate = moment.tz(badge.endDate, app.timezone);

  const onFixEmailDone = (isSuccess, email) => {
    setIsFixEmailModalOpen(false);
    if (isSuccess) {
      setIsBadgeApplicationsCreateModalOpen(true);
      onEmailChanged(email);
    }
  };

  const onActionClicked = () => {
    if (!app.airport.isTscEnabled) {
      return notify({ message: 'TSC integration is not enabled', severity: 'error' });
    }

    if (badge?.badgeHolderEmail?.includes('fake')) {
      return setIsFixEmailModalOpen(true);
    }

    setIsBadgeApplicationsCreateModalOpen(true);
  };

  useEffect(() => {
    if (badge) {
      const { renewalProhibited, hasRenewalInProgress, badgeHolderUuid, badgeHolderName } = badge;
      let isDisabled = false;
      let title = 'Start Renewal';

      if (renewalProhibited === 1 || renewalProhibited === true || renewalProhibited === 'true') {
        // Badge is not allowed to be renewed. An ASC must log in and toggle the flag to allow renewals.
        isDisabled = true;
        title = 'Renewal Prohibited';
      } else if (!(badgeExpirationDate.isAfter(today) && badgeExpirationDate.isBefore(thirtyDaysFromToday))) {
        // Badge is no within the allowed time window to start a renewal
        // The airport wants to collect the full fee if they lag and cause the airport inconvenience
        isDisabled = true;
        title = 'Badge is not ready for renewal';
      } else if (hasRenewalInProgress) {
        isDisabled = true;
        title = 'Renewal in progress';
      }

      setActionState({ isDisabled, title });
      setBadgeHolder({
        uuid: badgeHolderUuid,
        fullName: badgeHolderName,
      });
    }
  }, [badge]);

  return (
    <>
      <span title={actionState.title}>
        <ListItem button onClick={onActionClicked} disabled={actionState.isDisabled}>
          <ListItemIcon>
            <FontAwesomeIcon className="fa fa-repeat" />
          </ListItemIcon>
          <ListItemText primary="Start Renewal" />
        </ListItem>
      </span>

      <StartRenewalActionModal
        isOpen={isBadgeApplicationsCreateModalOpen}
        onDone={() => setIsBadgeApplicationsCreateModalOpen(false)}
        badge={badge}
        history={history}
        isSignatory
      />

      <FixEmailModal isOpen={isFixEmailModalOpen} onDone={onFixEmailDone} user={badgeHolder} />
    </>
  );
}
