import React, { useContext, useEffect, useState } from 'react';
import { Alert, FormGroup, Label } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import badgeOfficeApi from '../../../../../ajax/BadgeOffice/badgeOfficeApi';
import { handleError } from '../../../../../utils';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { AppContext } from '../../../../../App';
import appointmentApi from '../../../../../ajax/Appointment/appointmentApi';
import ForceRescheduleModal from './ForceRescheduleModal';
import { AppointmentCalendarContext } from '../AppointmentsList';
import InputWithCharacterLimit from '../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';

export default function AppointmentStatusChangeModal({ isOpen, data, onClose }) {
  const app = useContext(AppContext);
  const appointmentCalendarContext = useContext(AppointmentCalendarContext);
  const [templates, setTemplates] = useState({});
  const [messageChoice, setMessageChoice] = useState(null);
  const [isForceRescheduleModalOpen, setIsForceRescheduleModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const { appointment, status } = data;

  const onForceRescheduleModalClose = () => {
    setIsForceRescheduleModalOpen(false);
  };

  const onModalClose = args => {
    setMessageChoice(null);
    setIsForceRescheduleModalOpen(false);
    onClose({ shouldReload: !!args?.shouldReload });
  };

  const loadMessageTemplates = () => {
    badgeOfficeApi
      .getMessageTemplates({ badgeOfficeId: 1 })
      .then(({ success, templates }) => {
        if (success) setTemplates(templates);
      })
      .catch(error => handleError({ error }));
  };

  const getSaveLabel = () => {
    switch (status) {
      case 'completed':
        return 'Mark Completed';
      case 'missed':
        return 'Mark Missed';
      case 'reschedule':
        return 'Force Reschedule';
      case 'cancelled':
        return 'Cancel Appointment';
      default:
        return 'ERROR';
    }
  };

  const getSaveColor = () => {
    switch (status) {
      case 'completed':
        return 'success';
      case 'missed':
      case 'reschedule':
        return 'warning';
      case 'cancelled':
      default:
        return 'danger';
    }
  };

  const onSave = () => {
    if (messageChoice === 'yes' && status !== 'cancelled') {
      setIsForceRescheduleModalOpen(true);
    } else {
      app.api.toggleLoading(true);
      appointmentApi
        .updateStatus({
          appointmentUuid: appointment.uuid,
          status,
          shouldCancelBadgeApplication: messageChoice === 'yes' && status === 'cancelled',
          badgeApplicationTerminationReason: reason,
        })
        .then(({ success }) => {
          if (success) {
            // This looks weird, but we need to just quickly trigger
            // the useEffect hook in the AppointmentsListEditModal
            appointmentCalendarContext.setIsAppointmentUpdateDone(true);
            appointmentCalendarContext.setIsAppointmentUpdateDone(false);
            onModalClose({ shouldReload: true });
          }
        })
        .catch(error => handleError({ error, message: 'Unable to update appointment' }))
        .finally(() => app.api.toggleLoading(false));
    }
  };

  const onSaveWithNotification = ({ subject, content }) => {
    return new Promise((resolve, reject) => {
      app.api.toggleLoading(true);
      appointmentApi
        .updateStatus({ appointmentUuid: appointment.uuid, status, subject, content })
        .then(({ success }) => {
          if (success) {
            resolve();
            // This looks weird, but we need to just quickly trigger
            // the useEffect hook in the AppointmentsListEditModal
            appointmentCalendarContext.setIsAppointmentUpdateDone(true);
            appointmentCalendarContext.setIsAppointmentUpdateDone(false);
            return onModalClose({ shouldReload: true });
          }
          handleError({ message: 'Unable to send notification' });
          reject();
        })
        .catch(error => {
          handleError({ error, message: 'Unable to send notification' });
          reject();
        })
        .finally(() => app.api.toggleLoading(false));
    });
  };

  const isSaveDisabled = () => {
    if (
      status === 'cancelled' &&
      data.appointment.isBadgeApplicationActive &&
      data.appointment.isAppointmentRequiredForBadgeApplication
    ) {
      if (messageChoice === 'yes' && !reason) return true;
      return !messageChoice;
    }
    return (status === 'missed' || status === 'reschedule') && !messageChoice;
  };

  useEffect(() => {
    if (!isOpen) return;
    loadMessageTemplates();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div>
      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Confirmation"
        isOpen={isOpen}
        onClose={onModalClose}
        saveLabel={getSaveLabel()}
        saveColor={getSaveColor()}
        onSave={onSave}
        saveDisabled={isSaveDisabled()}
      >
        <div>
          Are you sure you want to mark the{' '}
          <i>
            {appointment?.start} {appointment?.appointmentType.name}
          </i>{' '}
          appointment for <strong>{appointment?.attendee_name}</strong> as <i>{status}</i>?
        </div>
        {status === 'cancelled' &&
          data.appointment.isBadgeApplicationActive &&
          data.appointment.isAppointmentRequiredForBadgeApplication && (
            <>
              <Alert color="danger" style={{ marginTop: 24 }}>
                <strong>CAUTION:</strong> This appointment may be crucial to the Badge Application. Cancelling it could
                permanently halt progress.
              </Alert>
              <div style={{ marginTop: 24 }}>
                Would you like to terminate the Badge Application as a result of this appointment being cancelled?
              </div>
              <FormControl>
                <RadioGroup
                  row
                  aria-label="custom message question"
                  name="mesage-question"
                  value={messageChoice}
                  onChange={event => setMessageChoice(event.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </FormControl>
              {messageChoice === 'yes' && (
                <FormGroup>
                  <Label className="required">Reason for termination</Label>
                  <div style={{ fontSize: 'smaller', marginBottom: 6 }}>
                    This will be appended to the notification that is sent out to all configured recipients
                  </div>
                  <InputWithCharacterLimit limit={255} value={reason} onChange={value => setReason(value)} />
                </FormGroup>
              )}
            </>
          )}
        {(status === 'missed' || status === 'reschedule') && (
          <>
            <div style={{ marginTop: 24 }}>Would you like to customize the message that will be sent?</div>
            <FormControl>
              <RadioGroup
                row
                aria-label="custom message question"
                name="mesage-question"
                value={messageChoice}
                onChange={event => setMessageChoice(event.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormControl>
          </>
        )}
      </AirBadgeModal>

      <ForceRescheduleModal
        isOpen={isForceRescheduleModalOpen}
        onClose={onForceRescheduleModalClose}
        onSave={onSaveWithNotification}
        status={status}
        appointment={appointment}
        templates={templates}
      />
    </div>
  );
}
