import React, { useCallback, useContext, useState } from 'react';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { AppContext, colors } from '../../../../../../App';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import { handleError } from '../../../../../../utils';
import { hasFeature } from '../../../../../../shared/Feature';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function ApproveBadgeIssuanceAction() {
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserSignatory, setIsSummaryModalOpen } =
    useContext(ActionPanelContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [decision, setDecision] = useState(null);

  const isHidden = useCallback(() => {
    const { active, config } = badgeApplication;
    const { backgroundCheck, adjudicateCHRC, approveBackgroundCheckResults, approveBadgeIssuance, training } = config;
    if (!!active === false) {
      // Badge Application is not active
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for doing this
      return true;
    }
    if (!approveBadgeIssuance.isEnabled) {
      return true;
    }
    if (
      (backgroundCheck.isEnabled && !backgroundCheck.isComplete) ||
      (adjudicateCHRC.isEnabled && !adjudicateCHRC.isComplete) ||
      (approveBackgroundCheckResults.isEnabled && !approveBackgroundCheckResults.isComplete)
    ) {
      // Background check is not done
      return true;
    }
    if (!training.isComplete) {
      // Training must be done first
      return true;
    }
    return !!(approveBadgeIssuance.isEnabled && approveBadgeIssuance.isComplete);
  }, [badgeApplication, isCurrentUserSignatory]);

  const isSaveDisabled = () => {
    if (decision === 'no' && !comment) return true;
    return !decision;
  };

  const onSaveDecision = () => {
    const { user, api } = app;
    const { uuid: badgeApplicationUuid } = badgeApplication;
    const isApproved = decision === 'yes';
    api.toggleLoading(true);
    badgeApplicationApi
      .updateBadgeIssuanceDecision({ user, badgeApplicationUuid, isApproved, comment })
      .then(({ success }) => {
        if (success) {
          onModalClose();
          onActionDone({ shouldReload: true });
          if (!hasFeature('printing', app.airport?.config?.features || [])) {
            setIsSummaryModalOpen(true);
          }
        } else {
          api.toggleLoading(false);
        }
      })
      .catch(error => {
        api.toggleLoading(false);
        handleError({ error });
      });
  };

  const onModalClose = () => {
    setComment('');
    setDecision(null);
    setIsModalOpen(false);
  };

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <ThumbUp style={{ color: colors.success }} />
        </ListItemIcon>
        <ListItemText primary="Authorize Badge Issuance" />
      </ListItem>

      <AirBadgeModal
        title="Authorization Confirmation"
        size={MODAL_SIZE_MEDIUM}
        isOpen={isModalOpen}
        closeLabel="Cancel"
        onClose={onModalClose}
        saveLabel="Save Decision"
        onSave={onSaveDecision}
        saveDisabled={isSaveDisabled()}
      >
        <Row style={{ marginBottom: 16 }}>
          <Col>
            Do you certify that all information is correct and all mandatory steps have been taken to ensure this
            applicant is legally allowed to be issued a badge?
          </Col>
        </Row>

        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="decision"
            name="decision"
            value={decision}
            onChange={event => setDecision(event.target.value)}
          >
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </FormControl>

        {decision === 'no' && (
          <Alert color="error">
            <div style={{ marginBottom: 8 }}>This will terminate the badge application.</div>
            <div>
              This action is irreversible and will require the badge application process to start over from the
              beginning if this applicant still requires a badge.
            </div>
          </Alert>
        )}

        <div>&nbsp;</div>

        <FormGroup>
          {decision === 'no' ? (
            <Label className="required">Reason for termination</Label>
          ) : (
            <Label>
              Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
            </Label>
          )}
          <Input type="textarea" rows="3" value={comment} onChange={event => setComment(event.target.value)} />
        </FormGroup>
      </AirBadgeModal>
    </>
  );
}
