import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Portal from '@material-ui/core/Portal';
import { isSmallScreen } from '../../../utils';

const useStyles = makeStyles(theme => ({
  nextButton: {
    color: 'white',
  },
}));

export default function NextButton({ label = 'next', isHidden, isDisabled, onClick, ...props }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });
  const classes = useStyles();

  return (
    <Portal container={document.getElementById('badgerNextButton')}>
      <Button
        {...props}
        size={isSmallScreen() ? 'small' : 'large'}
        variant="contained"
        color="primary"
        endIcon={<KeyboardArrowRight />}
        onClick={onClick}
        className={classes.nextButton}
        hidden={isHidden}
        disabled={isDisabled}
      >
        {t(label)}
      </Button>
    </Portal>
  );
}
