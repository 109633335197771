import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../components/AirBadgeModal/AirBadgeModal';
import badgeOfficeApi from '../../ajax/BadgeOffice/badgeOfficeApi';
import { handleError } from '../../utils';

export default function BadgeOfficeContactInformation({ isOpen, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [contactInformation, setContactInformation] = useState('');

  const callApi = () => {
    setIsLoading(true);
    badgeOfficeApi
      .getContactInfo()
      .then(({ contact_info }) => {
        if (!contact_info) {
          setContactInformation('No contact information provided');
        } else {
          setContactInformation(contact_info);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setContactInformation('');
    if (isOpen) {
      callApi();
    }
  }, [isOpen]);

  return (
    <AirBadgeModal
      title="Badge Office Contact Information"
      size={MODAL_SIZE_MEDIUM}
      isOpen={isOpen}
      onClose={onClose}
      closeLabel="Close"
    >
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <div>
          {contactInformation?.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              <span>{line}</span>
              <br />
            </React.Fragment>
          ))}
        </div>
      )}
    </AirBadgeModal>
  );
}
