import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import List from '@material-ui/core/List';
import Portal from '@material-ui/core/Portal';
import { AppContext } from '../../../../../App';
import CancelBadgeApplicationAction from './actions/CancelBadgeApplicationAction';
import ResendInvitationEmailAction from './actions/ResendInvitationEmailAction';
import IssueBadgeAction from './actions/IssueBadgeAction';
import SignAuthorizationDocumentAction from './actions/SignAuthorizationDocumentAction';
import NotifyApplicantAction from './actions/NotifyApplicantAction';
import SignApplicationDocumentAction from './actions/SignApplicationDocument/SignApplicationDocumentAction';
import ScheduleFingerprintAppointmentForApplicantAction from './actions/ScheduleFingerprintAppointmentForApplicantAction';
import GenerateFingerprintCodeAction, {
  isGenerateFingerprintCodeActionHidden,
} from './actions/GenerateFingerprintCodeAction';
import UploadFingerprintsAction from './actions/UploadFingerprintsAction';
import TransmitBackgroundCheckAction from './actions/TransmitBackgroundCheckAction';
import AdjudicateAction from './actions/AdjudicateAction';
import BackgroundCheckStatusAction from './actions/BackgroundCheckStatusAction';
import ApproveBadgeIssuanceAction from './actions/ApproveBadgeIssuanceAction';
import ScheduleBadgePickupAction from './actions/ScheduleBadgePickupAction';
import MarkTrainingCompleteAction from './actions/MarkTrainingCompleteAction';
import RedoBadgeApplicationAction from './actions/RedoBadgeApplicationAction';
import BypassBackgroundCheckAction from './actions/BypassBackgroundCheckAction';
import UploadChrcDocumentationAction from './actions/UploadChrcDocumentationAction';
import ScheduleTrainingAction from './actions/ScheduleTrainingAction';
import ShowIssueBadgeSummaryAction from './actions/ShowIssueBadgeSummaryAction';
import IssueBadgeSummaryModal from './actions/IssueBadgeSummaryModal';
import PreAssignTrainingAction from './actions/PreassignTrainingAction';
import badgeApplicationApi from '../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { handleError } from '../../../../../utils';
import ValidateBadgeApplicationAction from './actions/Validate/ValidateBadgeApplicationAction';
import AddFingerprintsStepAction from './actions/AddFingerprintsStepAction';
import PayForBadgeApplicationAction from './actions/PayForBadgeApplicationAction';
import Feature from '../../../../../shared/Feature';
import ResetPaymentAction from './actions/ResetPaymentAction';

export const ActionPanelContext = createContext();

export default function BadgeApplicationActionPanel({ badgeApplication, reload = () => false }) {
  const app = useContext(AppContext);
  const isCurrentUserSignatory = app?.user?.activeProfile?.role?.name === 'authorized-signatory';
  const isCurrentUserASC = app?.user?.activeProfile?.role?.name === 'airport-security-coordinator';
  const isCurrentUserTrustedAgent = app?.user?.activeProfile?.role?.name === 'trusted-agent';
  const [messages, setMessages] = useState([]);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

  const updateBadgeApplicationConfig = ({ updatedConfig, onSuccess, onFail }) => {
    return badgeApplicationApi
      .updateConfig({ badgeApplicationUuid: badgeApplication.uuid, config: updatedConfig })
      .then(({ success }) => {
        if (success) {
          onSuccess && onSuccess();
        } else {
          handleError({ message: 'Failed to update badge application process' });
          onFail && onFail();
        }
      })
      .catch(error => handleError({ error }));
  };

  const onActionDone = useCallback(
    ({ shouldReload }) => {
      if (shouldReload) {
        return reload();
      } else {
        return Promise.resolve(true);
      }
    },
    [reload]
  );

  useEffect(() => {
    if (!badgeApplication) {
      return setMessages([]);
    }

    const { fingerprintAppointment, backgroundCheck } = badgeApplication.config;
    const messages = [];
    if (
      !isGenerateFingerprintCodeActionHidden({ badgeApplication, isCurrentUserSignatory }) &&
      fingerprintAppointment.isComplete &&
      backgroundCheck.isEnabled &&
      backgroundCheck.isComplete
    ) {
      messages.push(
        'If a new fingerprints file is received, the background check will be automatically updated and sent to TSC'
      );
    }
    setMessages(messages);
  }, [badgeApplication, isCurrentUserSignatory]);

  if (!badgeApplication) return null;

  return (
    <Row>
      <ActionPanelContext.Provider
        value={{
          badgeApplication,
          onActionDone,
          isCurrentUserSignatory,
          isCurrentUserASC,
          isCurrentUserTrustedAgent,
          setIsSummaryModalOpen,
          updateBadgeApplicationConfig,
        }}
      >
        {!!messages.length && (
          <Portal container={document.getElementById('actionPanelMessages')}>
            <Alert color="info">
              {messages.map((m, i) => (
                <div key={i}>{m}</div>
              ))}
            </Alert>
          </Portal>
        )}
        <style>{`
            .MuiListItemIcon-root {
              min-width: 40px;
            }
          `}</style>
        <Col>
          <List>
            <TransmitBackgroundCheckAction />
            <SignAuthorizationDocumentAction />
            <SignApplicationDocumentAction />
            <UploadChrcDocumentationAction />
            <ValidateBadgeApplicationAction />
            <ScheduleFingerprintAppointmentForApplicantAction />
            <GenerateFingerprintCodeAction />
            <BackgroundCheckStatusAction />
            <AdjudicateAction />
            <ScheduleTrainingAction />
            <PreAssignTrainingAction />
            <MarkTrainingCompleteAction />
            <ApproveBadgeIssuanceAction />
            <ScheduleBadgePickupAction />
            <IssueBadgeAction />
            <ShowIssueBadgeSummaryAction />
            <NotifyApplicantAction />
            <Feature id="payments">
              <PayForBadgeApplicationAction />
            </Feature>
          </List>
        </Col>
        <Col>
          <List>
            <ResendInvitationEmailAction />
            <RedoBadgeApplicationAction />
            <ResetPaymentAction />
            <BypassBackgroundCheckAction />
            {app?.airport?.abbreviation === 'MSO' && <AddFingerprintsStepAction />}
            <UploadFingerprintsAction />
            <CancelBadgeApplicationAction />
          </List>
        </Col>

        <IssueBadgeSummaryModal
          isOpen={isSummaryModalOpen}
          onClose={() => setIsSummaryModalOpen(false)}
          badgeApplication={badgeApplication}
        />
      </ActionPanelContext.Provider>
    </Row>
  );
}
