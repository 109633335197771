import { PUSH_NOTIFICATION_KEY } from './App';
import { handleError } from './utils';

export function createPusherInstance({ token, currentProfileUuid }) {
  // eslint-disable-next-line no-undef
  const pusher = new Pusher(PUSH_NOTIFICATION_KEY, {
    cluster: 'us3',
    userAuthentication: {
      endpoint: '/api.php/default/v1/account/authenticate-notifications-subscription',
      transport: 'ajax',
      params: {},
      headers: {
        'X-Auth-Token': token || null,
        'X-PROFILE-UUID': currentProfileUuid || null,
      },
      paramsProvider: null,
      headersProvider: null,
      customHandler: null,
    },
    channelAuthorization: {
      endpoint: '/api.php/default/v1/account/authenticate-notifications-channel',
      transport: 'ajax',
      params: {},
      headers: {
        'X-Auth-Token': token || null,
        'X-PROFILE-UUID': currentProfileUuid || null,
      },
      paramsProvider: null,
      headersProvider: null,
      customHandler: null,
    },
  });
  pusher.signin();
  pusher.connection.bind('error', error => handleError({ error }));
  return pusher;
}
