import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Loading from '../../../containers/DefaultLayout/Loading';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';
import accountApi from '../../../ajax/Account/accountApi';
import { appSettings } from '../../../App';

export default function VerifyChangeEmailPage({ state, location, history, api }) {
  const { airport } = state;
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [newEmail, setNewEmail] = useState('');

  const goToLogin = () => history.replace('/login');

  const { search } = location;
  if (!search) return goToLogin();

  const params = {};
  search
    .slice(1) // Remove '?' character from the front
    .split('&')
    .forEach(segment => {
      const [key, value] = segment.split('=');
      params[key] = decodeURI(value);
    });

  if (!params.code || !params.uuid) return goToLogin();

  const onChangeClick = async () => {
    try {
      setError(null);
      api.toggleLoading(true);
      const response = await accountApi.changeEmail({
        email: newEmail,
        ...params,
      });

      switch (response.error) {
        case 'invalid_request': {
          setError('Invalid request. Please use the exact link sent in your email.');
          break;
        }
        case 'expired_code': {
          setError('Email link has expired. Please login and request a new email change from the Settings page.');
          break;
        }
        case 'already_in_use': {
          setError('Email address is already in use.');
          break;
        }
        default: {
          setError('');
        }
      }

      if (response.success) {
        setIsDone(true);
        setSeconds(5);
      }
    } catch (exception) {
      setError('Unable to change your email. Please try again and contact the Badge Office if the issue persists.');
      setIsDone(false);
    } finally {
      api.toggleLoading(false);
    }
  };

  useEffect(() => {
    (async function () {
      const { success, tempEmail } = await accountApi.getTempEmail({ ...params });
      if (success) setNewEmail(tempEmail);
    })();
    setNewEmail('');
  }, []);

  useEffect(() => {
    if (seconds === null) return;
    if (seconds === 0) return goToLogin();
    setTimeout(() => setSeconds(seconds - 1), 1000);
  }, [seconds]);

  return (
    <div className="app flex-row align-items-center">
      <Loading loading={state.loading} />
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" md="10" lg="8" xl="6">
            <div className="text-center py-4">
              <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
            </div>
            <Card>
              <CardBody>
                {isDone && (
                  <div>
                    <div style={{ marginBottom: 10 }}>Your email was successfully changed</div>
                    <div style={{ marginBottom: 25 }}>
                      You will be redirected to the Login page in {seconds} seconds ...
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Button onClick={goToLogin}>Go to Login</Button>
                    </div>
                  </div>
                )}
                {!isDone && (
                  <>
                    <div style={{ textAlign: 'center' }}>
                      <h1 className="mt-2">Change Email Address</h1>
                    </div>
                    <p style={{ margin: '25px 0 50px 0' }}>
                      Are you sure you want to change your email address to <strong>{newEmail}</strong>?
                      {error && (
                        <div style={{ marginTop: 10 }}>
                          <p style={{ color: 'red', fonsSize: 13 }}>{error}</p>
                        </div>
                      )}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Button color="primary" onClick={onChangeClick} style={{ width: 120 }}>
                        Change Email
                      </Button>
                      <button type="button" className="btn btn-link" onClick={goToLogin} style={{ marginTop: 15 }}>
                        Return to Login
                      </button>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
            <div className="text-center mt-4">
              <p>AirBadge is Provided by</p>
              <p>{airport && airport.name}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
