import React, { useContext, useState } from 'react';
import { Alert } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AppContext } from '../../../../../../App';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import backgroundCheckApi from '../../../../../../ajax/BackgroundCheck/backgroundCheckApi';
import { handleError } from '../../../../../../utils';

export default function CancelRapbackModal({ isOpen, onClose, user }) {
  const app = useContext(AppContext);
  const [selection, setSelection] = useState(null);

  const onModalClose = () => {
    setSelection(null);
    onClose();
  };

  const isSaveDisabled = () => {
    return !selection || selection === 'false';
  };

  const onCancelRapback = () => {
    app.api.toggleLoading(true);
    backgroundCheckApi
      .changeRapbackSubscription({ userUuid: user.badgeHolderUuid, action: 'cancel' })
      .then(({ success, errors, message }) => {
        if (success) {
          onClose();
        } else if (errors) {
          app.api.confirmMessage(
            'Response from TSC',
            <Alert color="danger">
              <p>{errors}</p>
            </Alert>,
            () => null,
            false
          );
        } else if (message) {
          app.api.confirmMessage('Conflict Found', <p>{message}</p>, () => null, false);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Cancel Rapback Confirmation"
      isOpen={isOpen}
      onClose={onModalClose}
      closeLabel={selection === 'true' ? 'Cancel' : 'Close'}
      onSave={selection === 'true' && onCancelRapback}
      saveLabel="Cancel Rapback Subscription"
      saveDisabled={isSaveDisabled()}
      saveColor="warning"
    >
      <Alert color="info">
        <strong>{user.badgeHolderName}</strong> no longer has any active badges that grant the airport the authority to
        receive rapback information
      </Alert>
      <div className="mt-4">Would you like to cancel their rapback subscription now?</div>
      <RadioGroup
        row
        aria-label="selection"
        name="selection"
        value={selection}
        onChange={event => setSelection(event?.target?.value)}
      >
        <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
        <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
      </RadioGroup>
      {selection === 'false' && (
        <Alert color="warning">
          <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>In 5 days this rapback will cancel</span>{' '}
          unless there is a change to this badge holder that allows the airport to receive rapback information
        </Alert>
      )}
    </AirBadgeModal>
  );
}
