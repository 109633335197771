import React, { useContext, useState } from 'react';
import { Alert } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SecurityIcon from '@material-ui/icons/Security';
import { AppContext, colors } from '../../../../../../App';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import peopleApi from '../../../../../../ajax/People/peopleApi';
import { handleError, notify } from '../../../../../../utils';
import MergeDuplicateUsersModal from '../../../../People/PeopleList/MergeDuplicateUsersModal';
import badgeApplicationCommentApi from '../../../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';
import UncancelRapbackModal from '../UncancelRapbackModal';
import { hasFeature } from '../../../../../../shared/Feature';
import useConfirm from '../../../../../../shared/useConfirm';

export default function TransmitBackgroundCheckAction() {
  const showConfirm = useConfirm();
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const { applicationDocument, validation, fingerprintAppointment, backgroundCheck, uploadChrcDocumentation } =
    badgeApplication.config;
  const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [isUncancelRapbackModalOpen, setIsUncancelRapbackModalOpen] = useState(false);

  const isHidden = () => {
    if (!!badgeApplication.active === false) {
      return true;
    }
    if (badgeApplication?.applicant?.isTscExempt) {
      return true;
    }
    if (!backgroundCheck.isEnabled || (backgroundCheck.isEnabled && backgroundCheck.isComplete)) {
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for background checks
      return true;
    }
    if (applicationDocument.isEnabled && !applicationDocument.isComplete) {
      // Application document needs to be done first
      return true;
    }
    if (validation?.isEnabled && !validation?.isComplete) {
      // Validation needs to be completed first
      return true;
    }
    if (uploadChrcDocumentation?.isEnabled && !uploadChrcDocumentation?.isComplete) {
      return true;
    }
    if (fingerprintAppointment.isEnabled && !fingerprintAppointment.fingerprintsFileUuid) {
      // Fingerprints must be created and uploaded first
      return true;
    }
    if (fingerprintAppointment.isEnabled && !fingerprintAppointment.isComplete) {
      // Fingerprints must be created and uploaded first
      return true;
    }
    return false;
  };

  const onUncancelRapbackModalClose = () => {
    setIsUncancelRapbackModalOpen(false);
    onActionDone({ shouldReload: true });
  };

  const handleApiResponse = ({ success, message }) => {
    if (success) {
      notify({ message: 'Background check started' });
      return onActionDone({ shouldReload: true });
    }

    app.api.toggleLoading(false);
    if (message === 'rapback_cancelled') {
      return setIsUncancelRapbackModalOpen(true);
    }

    handleError({ message: message || 'Unable to start the background check' });
  };

  const startBackgroundCheck = () => {
    const { user, api } = app;
    const { uuid: badgeApplicationUuid } = badgeApplication;
    api.toggleLoading(true);
    badgeApplicationApi
      .startBackgroundCheck({ user, badgeApplicationUuid })
      .then(handleApiResponse)
      .catch(error => {
        api.toggleLoading(false);
        handleError({ error, message: 'Unable to start the background check' });
      });
  };

  const addBadgeApplicationComment = async ({ countOfPossibleDuplicateUsers }) => {
    try {
      const { uuid: badgeApplicationUuid } = badgeApplication;
      await badgeApplicationCommentApi.create({
        user: 'authenticated-user',
        badgeApplicationUuid,
        comment: `Found ${countOfPossibleDuplicateUsers} possible duplicate AirBadge users when trying to transmit background check`,
      });
    } catch (error) {
      handleError({ error });
    }
  };

  const start = shouldSkipPaymentConfirm => {
    const isPaymentsFeatureEnabled = hasFeature('payments', app?.airport?.config?.features || []);
    const { isPaid, settings, paymentChoice } = badgeApplication;
    const { shouldPaymentBeSettledBeforeTransmit } = settings;
    if (
      shouldSkipPaymentConfirm !== true &&
      isPaymentsFeatureEnabled &&
      !isPaid &&
      shouldPaymentBeSettledBeforeTransmit &&
      ['applicant', 'signatory', 'in-person'].indexOf(paymentChoice) > -1
    ) {
      return showConfirm({
        title: 'Payment Required',
        content: (
          <div>
            <Alert color="warning">This badge application has not been paid for</Alert>
            <p>
              A payment is required before the background check can be transmitted. You can defer payment in order to
              transmit this badge application immediately, or click Cancel to wait for the payment to be settled.
            </p>
          </div>
        ),
        onConfirm: () => {
          badgeApplicationCommentApi.create({
            badgeApplicationUuid: badgeApplication.uuid,
            comment: 'Payment has been deferred to later in the badge application process',
          });
          start(true);
        },
        okButtonText: 'Defer Payment & Transmit Now',
      });
    }

    app.api.toggleLoading(true);

    const {
      isRenewal,
      applicant: { uuid: excludedUserUuid, badgerData },
    } = badgeApplication;
    const { firstName, middleName, lastName, dob: dateOfBirth } = badgerData;

    setDuplicates([]);

    if (isRenewal || validation?.isEnabled) {
      return startBackgroundCheck();
    }

    const defaultErrorMessage =
      'A problem occurred while trying to determine if this applicant is duplicated before transmitting to TSC. Contact support.';

    // Check for possible duplicates!
    peopleApi
      .findDuplicates({
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        excludedUserUuid,
      })
      .then(({ success, results }) => {
        if (success && results) {
          if (results.length) {
            // STOP!
            addBadgeApplicationComment({ countOfPossibleDuplicateUsers: results.length });
            setDuplicates(results);
            setIsDuplicatesModalOpen(true);
            app.api.toggleLoading(false);
          } else {
            startBackgroundCheck();
          }
        } else {
          handleError({ message: defaultErrorMessage });
          app.api.toggleLoading(false);
        }
      })
      .catch(error => {
        app.api.toggleLoading(false);
        handleError({ error, message: defaultErrorMessage });
      });
  };

  const onDuplicatesModalClosed = ({ wasMerged, notDuplicate }) => {
    setIsDuplicatesModalOpen(false);
    if (wasMerged) {
      notify({
        message:
          'The two records have been merged together and the badge application has not been transmitted. Click the Transmit Background Check Action again. If there are no more duplicates, the background check will be transmitted.',
        severity: 'info',
      });
      onActionDone({ shouldReload: true });
    } else if (notDuplicate) {
      startBackgroundCheck();
    }
  };

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={start}>
        <ListItemIcon>
          <SecurityIcon style={{ color: colors.primary }} />
        </ListItemIcon>
        <ListItemText primary="Transmit Background Check" />
      </ListItem>

      <MergeDuplicateUsersModal
        isForBackgroundCheck
        isOpen={isDuplicatesModalOpen}
        onClose={onDuplicatesModalClosed}
        duplicates={duplicates}
        onMergeActionLabel="transmit background check"
        sourcePerson={badgeApplication?.applicant}
      />

      <UncancelRapbackModal
        isOpen={isUncancelRapbackModalOpen}
        onClose={onUncancelRapbackModalClose}
        user={badgeApplication.applicant ? { ...badgeApplication.applicant } : {}}
      />
    </>
  );
}
