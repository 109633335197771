import request from '../Request';

export default {
  resetPassword,
  getTempEmail,
  changeEmail,
  getUserFullName,
  logout,
  loginKiosk,
};

function resetPassword({ code, uuid, password }) {
  return request(null, 'POST', `account/reset-password`, {
    password,
    uuid,
    code,
  });
}

function getTempEmail({ uuid }) {
  return request(null, 'POST', `account/get-temp-email`, {
    uuid,
  });
}

function changeEmail({ code, uuid, email }) {
  return request(null, 'POST', `account/change-email`, {
    email,
    uuid,
    code,
  });
}

function getUserFullName({ userUuid }) {
  return request(null, 'GET', `account/${userUuid}/full-name`);
}

function logout() {
  return request('authenticated-user', 'POST', 'account/logout');
}

function loginKiosk({ authToken, kioskUuid, authorizedByUserUuid }) {
  return request('authenticated-user', 'POST', 'account/login-kiosk', { authToken, kioskUuid, authorizedByUserUuid });
}
