import React, { createContext, useContext, useEffect, useState } from 'react';
import { Alert, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import PersonAssociatedCoursesList from './PersonAssociatedCoursesList';
import PersonAssociatedTraineeGroupList from './PersonAssociatedTraineeGroupList';
import trainingCourseApi from '../../../../../ajax/Training/trainingCourseApi';
import { buildFullName, handleError } from '../../../../../utils';
import { AppContext } from '../../../../../App';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import IETErrorPanel from './IETErrorPanel';
import DigicastErrorPanel from './DigicastErrorPanel';
import SSIErrorPanel from './SSIErrorPanel';

export const PersonManageTrainingModalContext = createContext({});

export default function PersonManageTrainingModal({ isOpen, onClose, person, airport }) {
  const app = useContext(AppContext);
  const [trainingData, setTrainingData] = useState([]);
  const [isMissingAirportPersonId, setIsMissingAirportPersonId] = useState(false);
  const [errorMessageForIET, setErrorMessageForIET] = useState(null);
  const [errorMessageForDigicast, setErrorMessageForDigicast] = useState(null);
  const [errorMessageForSSI, setErrorMessageForSSI] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Load all the training data needed for all the components in this modal
  const loadData = () => {
    setErrorMessageForIET(null);
    setErrorMessageForDigicast(null);
    setErrorMessageForSSI(null);
    app.api.toggleLoading(true);
    setIsLoading(true);
    trainingCourseApi
      .getAllTrainingData({ userUuid: person.uuid })
      .then(({ success, assignments }) => {
        if (success) {
          setTrainingData(assignments);
          assignments.forEach(({ system, success, message }) => {
            if (!success) {
              switch (system) {
                case 'iet':
                  setErrorMessageForIET(message);
                  break;
                case 'digicast':
                  setErrorMessageForDigicast(message);
                  break;
                case 'ssi':
                  setErrorMessageForSSI(message);
                  break;
              }
            }
          });
          setIsLoading(false);
        }
      })
      .catch(error => handleError({ error, message: 'Unable to load training data' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onRefreshData = () => {
    loadData();
  };

  const getFullName = () => {
    return buildFullName(person);
  };

  const getAirportPersonId = () => {
    return person.tsc_person_id || person.airport_person_id || person.tscPersonId || person.airportPersonId || 0;
  };

  useEffect(() => {
    setErrorMessageForIET(null);
    setErrorMessageForDigicast(null);
    setErrorMessageForSSI(null);
    setIsMissingAirportPersonId(false);
    setTrainingData([]);

    if (isOpen && person) {
      if (!person.tsc_person_id && !person.airport_person_id && !person.tscPersonId && !person.airportPersonId) {
        setIsMissingAirportPersonId(true);
      } else {
        loadData();
      }
    }
  }, [isOpen, person]);

  if (!isOpen || !person) return null;

  if (isMissingAirportPersonId) {
    return (
      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Training Assignments"
        isOpen={isMissingAirportPersonId}
        onClose={onClose}
        closeLabel="Close"
      >
        <Alert color="danger">
          <strong>{getFullName()}</strong> must have completed at least one background check and been issued an Airport
          Person ID before they can be assigned training.
        </Alert>
      </AirBadgeModal>
    );
  }

  return (
    <>
      <Modal style={{ maxWidth: '95%' }} isOpen={isOpen} toggle={onClose}>
        <CustomModalHeader toggle={onClose}>
          Training Assignments for{' '}
          <i>
            {getFullName()} ({getAirportPersonId()})
          </i>
        </CustomModalHeader>
        <ModalBody>
          {!!person.hasActiveBadgeApplication && (
            <Alert color="warning">
              {getFullName()} has an open badge application. If you intend to assign training directly related to the
              badge application, you will need to assign it from the badge application screen and not here.
            </Alert>
          )}
          <PersonManageTrainingModalContext.Provider value={{ reloadData: loadData, person }}>
            <IETErrorPanel message={errorMessageForIET} />
            <DigicastErrorPanel message={errorMessageForDigicast} />
            <SSIErrorPanel message={errorMessageForSSI} />

            {!errorMessageForIET && !errorMessageForDigicast && !errorMessageForSSI && (
              <>
                {/* Only IET, SSI, and Digicast support the "training groups" feature */}
                {(airport.isIETEnabled || airport.isSSIEnabled || airport.isDigicastEnabled) && (
                  <>
                    {/* Only need to show this separator if IET is enabled because it supports both groups and courses */}
                    {airport.isIETEnabled && <div className="separator mt-2 mb-2">GROUP ASSIGNMENTS</div>}
                    <PersonAssociatedTraineeGroupList
                      isStillLoadingData={isLoading}
                      person={person}
                      trainingData={trainingData}
                      onRefreshData={onRefreshData}
                    />
                  </>
                )}

                {/* Only IET and AirBadge LMS support the "training courses" feature */}
                {(airport.isIETEnabled || airport.config.features.includes('airbadge_lms')) && (
                  <>
                    <div className="separator mb-2 mt-5">COURSE ASSIGNMENTS</div>
                    <PersonAssociatedCoursesList
                      isStillLoadingData={isLoading}
                      person={person}
                      trainingData={trainingData}
                      onRefreshData={onRefreshData}
                    />
                  </>
                )}
              </>
            )}
          </PersonManageTrainingModalContext.Provider>
        </ModalBody>
        <ModalFooter>
          <ModalActions closeLabel="Close" onClose={onClose} />
        </ModalFooter>
      </Modal>
    </>
  );
}
