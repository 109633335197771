import request from '../Request';

export default {
  getAllAvailableTraining,
  startSync,
  getSyncStats,
  getSyncResults,
};

function getAllAvailableTraining({ signal }) {
  return request('authenticated-user', 'GET', `training`, null, signal);
}

function startSync(data) {
  return request('authenticated-user', 'POST', `training/sync`, { partialList: data?.partialList || '' });
}

function getSyncStats() {
  return request('authenticated-user', 'GET', `training/sync/stats`);
}

function getSyncResults({ signal, ...rest }) {
  return request('authenticated-user', 'POST', `training/sync/results`, rest, signal);
}
