import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Portal from '@material-ui/core/Portal';
import { isSmallScreen } from '../../../utils';

export default function BackButton({ label, isHidden, onClick }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });

  return (
    <Portal container={document.getElementById('badgerBackButton')}>
      <Button
        size={isSmallScreen() ? 'small' : 'large'}
        variant="outlined"
        color="primary"
        startIcon={<KeyboardArrowLeft />}
        onClick={onClick}
        hidden={isHidden}
      >
        {label || t('back')}
      </Button>
    </Portal>
  );
}
