import request from '../Request';

export default function EnrollmentValidateNumberRequest(user, number) {
  const method = 'POST';

  const url = `enrollment/validate-number`;

  const data = {
    number,
  };

  return request(user, method, url, data);
}
