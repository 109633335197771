import React, { useContext, useEffect, useState } from 'react';
import set from 'lodash/set';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../App';
import request from '../../ajax/Request';
import Confirm from '../../containers/DefaultLayout/Confirm';
import CitizenshipQuestion from './steps/CitizenshipQuestion';
import ListADocuments from './steps/ListADocuments';
import ListBDocuments from './steps/ListBDocuments';
import ListCDocuments from './steps/ListCDocuments';
import Scanning from './steps/Scanning/Scanning';
import DataConfirmation from './steps/DataConfirmation/DataConfirmation';
import ManualUpload from './steps/ManualUpload';
import Finalize from './steps/Finalize';
import BadgeApplication from './steps/BadgeApplication';
import Fail from './steps/Fail';
import SupplementalDocuments from './steps/SupplementalDocuments';
import { BadgerContext } from './Badger';
import VisaDocumentQuestion from './steps/VisaDocumentQuestion';
import { isSmallScreen } from '../../utils';

const defaultWizardData = {
  isSecuredBadge: false,
  requiredDocuments: new Set(),
  scans: [],
  badgeApplicationData: {},
  citizenship: {
    value: null,
    hidden: false,
  },
  listADocuments: {
    value: null,
    hidden: true,
  },
  listBDocuments: {
    value: null,
    hidden: true,
  },
  listCDocuments: {
    value: null,
    hidden: true,
  },
  supplementalDocuments: {
    value: null,
    hidden: true,
  },
  visaDocument: {
    value: null,
    hidden: true,
  },
  collection: {
    value: null,
    hidden: true,
  },
  dataConfirmation: {
    hidden: true,
  },
  manualUpload: {
    hidden: true,
  },
  finalize: {
    hidden: true,
  },
  badgeApplication: {
    hidden: true,
  },
  fail: {
    hidden: true,
  },
};

const useStyles = makeStyles(theme => ({
  card: {
    margin: 0,
    minHeight: 'calc(100vh - 198px)',
    borderBottomColor: 'transparent !important',
    borderRadius: '0 !important',
  },
  cardBody: {
    position: 'relative',
    padding: isSmallScreen() ? theme.spacing(2) : undefined,
    overflow: isSmallScreen() ? 'scroll' : undefined,
  },
  nextButton: {
    color: 'white',
  },
}));

export default function BadgerWizard({ info }) {
  const app = useContext(AppContext);
  const badger = useContext(BadgerContext);
  const { t } = useTranslation('translation', { keyPrefix: 'badger' });
  const classes = useStyles();
  const [data, setData] = useState({ ...defaultWizardData });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [shouldSkipSSNQuestion, setShouldSkipSSNQuestion] = useState(false);

  const onStartOver = () => {
    setIsDrawerOpen(true);
  };

  const restartToPhase = phase => {
    setIsDrawerOpen(false);

    const messageParts = t('actions.start-over-line1').split('{{step}}');
    let phaseText = <span></span>;
    if (phase === 1) {
      phaseText = <span style={{ fontStyle: 'italic' }}>{t('phases.phase1')}</span>;
    } else if (phase === 2) {
      phaseText = <span style={{ fontStyle: 'italic' }}>{t('phases.phase2')}</span>;
    } else if (phase === 3) {
      phaseText = <span style={{ fontStyle: 'italic' }}>{t('phases.phase3')}</span>;
    }

    app.api.confirmMessage(
      t('actions.start-over-confirm'),
      <div>
        <div>
          {messageParts[0]}
          {phaseText}
          {messageParts[1]}
        </div>
        <div style={{ marginTop: 16, textDecoration: 'underline' }}>{t('actions.start-over-line2')}</div>
      </div>,
      () => {
        app.api.toggleLoading(true);
        request(null, 'POST', `badger/start-over`, { key: info.key, phase })
          .then(() => window.location.reload())
          .catch(error => console.error(error))
          .finally(() => app.api.toggleLoading(false));
      }
    );
  };

  const phase = () => {
    if (!data.collection.hidden) return t('phases.phase2');
    if (!data.dataConfirmation.hidden || !data.finalize.hidden) return t('phases.phase3');
    if (!data.badgeApplication.hidden || !data.manualUpload.hidden) return t('phases.phase4');
    return t('phases.phase1');
  };

  const Phase1Restart = (
    <ListItem button onClick={() => restartToPhase(1)}>
      <ListItemText primary={t('phases.phase1')} />
    </ListItem>
  );

  const Phase2Restart = (
    <ListItem button onClick={() => restartToPhase(2)}>
      <ListItemText primary={t('phases.phase2')} />
    </ListItem>
  );

  const Phase3Restart = (
    <ListItem button onClick={() => restartToPhase(3)}>
      <ListItemText primary={t('phases.phase3')} />
    </ListItem>
  );

  const restartActions = () => {
    if (!data.collection.hidden) {
      return (
        <>
          {Phase1Restart}
          {Phase2Restart}
        </>
      );
    }
    if (!data.dataConfirmation.hidden || !data.finalize.hidden) {
      return (
        <>
          {Phase1Restart}
          {Phase2Restart}
          {Phase3Restart}
        </>
      );
    }
    return <>{Phase1Restart}</>;
  };

  useEffect(() => {
    if (info) {
      const { isSida, isSterile, isSecured, step, documents } = info;
      const newData = { ...data, isSecuredBadge: isSida || isSterile || isSecured, ...info };
      if (step !== 'start') {
        if (step === 'scan' || step === 'rescan') {
          newData.citizenship.hidden = true;
          newData.collection.hidden = false;
          newData.requiredDocuments = new Set(documents);
        } else if (step === 'data-entry') {
          newData.citizenship.hidden = true;
          newData.dataConfirmation.hidden = false;
          newData.requiredDocuments = new Set(documents);
        } else if (step.indexOf('application') > -1) {
          newData.citizenship.hidden = true;
          newData.badgeApplication.hidden = false;
        }
      }
      setData(newData);
      setIsReady(true);
      setShouldSkipSSNQuestion(info.shouldSkipSSNQuestion);
    }
  }, [info]);

  if (!isReady) return null;

  return (
    <>
      <style>{`
      .card {
        border: 1px solid #c8ced3;
      }
      `}</style>
      <Card className={classes.card}>
        <CardHeader id="badgerCardHeader" style={{ textAlign: 'center' }}>
          {phase()}
        </CardHeader>
        <CardBody id="badgerCardBody" className={classes.cardBody}>
          <CitizenshipQuestion
            isHidden={data.citizenship.hidden}
            value={data.citizenship.value}
            onChange={value => setData({ ...set(data, 'citizenship.value', value) })}
            onNext={() => {
              let newData = set(data, 'citizenship.hidden', true);
              newData = set(newData, 'listADocuments.hidden', false);
              setData({ ...newData });
            }}
          />

          <ListADocuments
            isHidden={data.listADocuments.hidden}
            value={data.listADocuments.value}
            data={data}
            onChange={value => setData({ ...set(data, 'listADocuments.value', value) })}
            onNext={() => {
              let newData = set(data, 'listADocuments.hidden', true);
              newData = set(newData, 'listBDocuments.hidden', false);
              if (newData.listADocuments.value !== 'none') {
                let document = newData.listADocuments.value;
                if (document === 'usa-passport-card') {
                  newData.requiredDocuments.add('usa-passport');
                } else if (document === 'foreign-passport') {
                  newData.requiredDocuments.add('foreign-passport');
                } else {
                  newData.requiredDocuments.add(document);
                }
              }
              setData({ ...newData });
            }}
          />

          <ListBDocuments
            isHidden={data.listBDocuments.hidden}
            value={data.listBDocuments.value}
            data={data}
            onChange={value => {
              const newData = set(data, 'listBDocuments.value', value);
              setData({ ...newData });
            }}
            onNext={({ nextStep = '' }) => {
              let newData = set(data, 'listBDocuments.hidden', true);
              if (nextStep === 'fail') {
                newData = set(newData, 'fail.hidden', false);
              } else if (nextStep === 'listC') {
                newData = set(newData, 'listCDocuments.hidden', false);
              } else if (nextStep === 'supplemental') {
                newData = set(newData, 'supplementalDocuments.hidden', false);
              } else if (nextStep === 'visa') {
                newData = set(newData, 'visaDocument.hidden', false);
              } else if (nextStep === 'scanning') {
                newData = set(newData, 'collection.hidden', false);
              }

              if (data.listBDocuments.value && data.listBDocuments.value !== 'none') {
                newData.requiredDocuments.add(data.listBDocuments.value);
              }

              setData({ ...newData });
            }}
          />

          <ListCDocuments
            isHidden={data.listCDocuments.hidden}
            value={data.listCDocuments.value}
            data={data}
            onChange={value => {
              const newData = set(data, 'listCDocuments.value', value);
              setData({ ...newData });
            }}
            onNext={() => {
              let newData = set(data, 'listCDocuments.hidden', true);
              if (newData.listCDocuments.value !== 'none') {
                newData.requiredDocuments.add(newData.listCDocuments.value);
                const usaCitizenValues = ['usa_born', 'usa_naturalized', 'usa_born_abroad', 'non_citizen_national'];
                if (usaCitizenValues.indexOf(data.citizenship.value) > -1) {
                  newData = set(newData, 'collection.hidden', false);
                } else {
                  newData = set(newData, 'supplementalDocuments.hidden', false);
                }
              } else {
                newData = set(newData, 'fail.hidden', false);
              }
              setData({ ...newData });
            }}
          />

          <SupplementalDocuments
            isHidden={data.supplementalDocuments.hidden}
            value={data.supplementalDocuments.value}
            data={data}
            onChange={value => {
              const newData = set(data, 'supplementalDocuments.value', value);
              setData({ ...newData });
            }}
            onNext={() => {
              let newData = set(data, 'supplementalDocuments.hidden', true);
              if (data.supplementalDocuments.value !== 'none') {
                newData.requiredDocuments.add(data.supplementalDocuments.value);
                newData = set(newData, 'visaDocument.hidden', false);
              } else {
                const has551 = newData.requiredDocuments.has('foreign-passport-i-551');
                const has766 = newData.requiredDocuments.has('i-766');
                const hasCertOfNaturalization = newData.requiredDocuments.has('certificate-of-naturalization');
                const has560 = newData.requiredDocuments.has('n-560');
                const hasPermanentResidentCard = newData.requiredDocuments.has('permanent-resident-card');
                if (!hasPermanentResidentCard && !has551 && !has766 && !hasCertOfNaturalization && !has560) {
                  newData = set(newData, 'fail.hidden', false);
                } else {
                  newData = set(newData, 'collection.hidden', false);
                }
              }
              setData({ ...newData });
            }}
          />

          <VisaDocumentQuestion
            isHidden={data.visaDocument.hidden}
            value={data.visaDocument.value}
            data={data}
            onChange={value => {
              const newData = set(data, 'visaDocument.value', value);
              setData({ ...newData });
            }}
            onNext={() => {
              let newData = set(data, 'visaDocument.hidden', true);
              if (data.visaDocument.value === 'yes') {
                newData.requiredDocuments.add('visa');
              }
              newData = set(newData, 'collection.hidden', false);
              setData({ ...newData });
            }}
          />

          {/* Start - Collection */}
          <Scanning
            info={info}
            documents={data.requiredDocuments}
            isHidden={data.collection.hidden}
            startOverAction={() => restartToPhase(1)}
            onNext={({ scans, fail }) => {
              let newData = set(data, 'collection.hidden', true);
              if (fail) {
                newData = set(newData, 'fail.hidden', false);
              } else {
                const ssnScan = scans.find(s => s.code === 'social-security-card');
                if (ssnScan) {
                  const { documentNumber } = ssnScan.file;
                  if (
                    documentNumber &&
                    documentNumber !== '___-__-____' &&
                    documentNumber.indexOf('_') < 0 &&
                    documentNumber.length === 11
                  ) {
                    setShouldSkipSSNQuestion(true);
                  }
                }
                newData = set(newData, 'dataConfirmation.hidden', false);
              }
              setData({ ...newData, scans });
            }}
          />

          <DataConfirmation
            info={info}
            shouldSkipSSNQuestion={shouldSkipSSNQuestion}
            scans={data.scans}
            isSecuredBadge={data.isSecuredBadge}
            usaBorn={data.citizenship.value === 'usa_born'}
            isUSACitizen={(() => {
              const usaCitizenValues = ['usa_born', 'usa_naturalized', 'usa_born_abroad', 'non_citizen_national'];
              return usaCitizenValues.indexOf(data.citizenship.value) > -1;
            })()}
            isHidden={data.dataConfirmation.hidden}
            onNext={badgeApplicationData => {
              let newData = set(data, 'dataConfirmation.hidden', true);
              if (
                !info.isApplicantSameAsUserCompletingBadger &&
                (info.roleNameOfUserCompletingBadger === 'airport-security-coordinator' ||
                  info.roleNameOfUserCompletingBadger === 'trusted-agent')
              ) {
                newData = set(newData, 'manualUpload.hidden', false);
              } else {
                newData = set(newData, 'finalize.hidden', false);
              }
              setData({ ...newData, badgeApplicationData });
            }}
          />
          {/* End - Collection */}

          {/* This step if for an ASC to manually upload the badge application */}
          <ManualUpload
            isHidden={data.manualUpload.hidden}
            info={info}
            badgeApplicationData={data.badgeApplicationData}
          />

          <Finalize
            isHidden={data.finalize.hidden}
            info={info}
            badgeApplicationData={data.badgeApplicationData}
            onBack={() => {
              let newData = set(data, 'finalize.hidden', true);
              newData = set(newData, 'dataConfirmation.hidden', false);
              setData({ ...newData });
            }}
          />

          <BadgeApplication
            isHidden={data.badgeApplication.hidden}
            info={info}
            badgeApplicationData={data.badgeApplicationData}
          />

          <Fail isHidden={data.fail.hidden} onRestartStep={onStartOver} />
        </CardBody>
        {data.badgeApplication.hidden && data.manualUpload.hidden && (
          <CardFooter
            id="badgerCardFooter"
            className="d-flex justify-content-between"
            style={{ position: 'fixed', bottom: 50, width: '100%', zIndex: 999 }}
          >
            <div id="badgerBackButton" style={{ flex: 0.25, textAlign: 'left' }}>
              {/*  */}
            </div>
            <div id="badgerActionsButton" style={{ flex: 0.5, textAlign: 'center' }}>
              <Button
                hidden={badger.shouldStopAfterScans}
                size={isSmallScreen() ? 'small' : 'large'}
                variant="contained"
                color="default"
                style={{
                  opacity: !data.citizenship.hidden || info.step === 'rescan' ? 0 : 1,
                  pointerEvents: !data.citizenship.hidden || info.step === 'rescan' ? 'none' : undefined,
                }}
                onClick={onStartOver}
              >
                {t('actions.start-over')}
              </Button>
            </div>
            <div id="badgerNextButton" style={{ flex: 0.25, textAlign: 'right' }}>
              {/*  */}
            </div>
          </CardFooter>
        )}
      </Card>

      <Drawer style={{ zIndex: 1111 }} anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div style={{ textAlign: 'center', margin: '8px 0' }}>{t('actions.start-over-question')}</div>
        <Divider />
        <List>{restartActions()}</List>
      </Drawer>

      <Confirm
        api={app.api}
        title={app.confirmTitle}
        message={app.confirmContent}
        onConfirm={app.confirmCallbackOnConfirm}
        onClose={app.confirmCallbackOnClose}
      />
    </>
  );
}
