import React, { useContext } from 'react';
import { Alert, Button, Col, Row as ReactStrapRow } from 'reactstrap';
import { styled } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import TitledBox from '../../../../../../shared/TitledBox/TitledBox';
import { default as FormData } from '../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { buildFullName, formatPhoneNumber, handleError } from '../../../../../../utils';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { AppContext } from '../../../../../../App';
import EscortEndorsementPicker from '../../../BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import DrivingEndorsementPicker from '../../../BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import { getDrivingOptions } from '../../../../../../shared/BadgeTypes/drivingOptionsUtils';

const Row = styled(ReactStrapRow)({
  marginBottom: 16,
});

const ReadOnlyFormData = ({ label, value }) => {
  return <FormData label={label} value={value} skipTextTransform />;
};

export default function IssueBadgeSummaryModal({ isOpen, onClose, badgeApplication }) {
  const app = useContext(AppContext);
  if (!badgeApplication) return null;

  const getName = () => {
    const { applicant } = badgeApplication;
    const name = buildFullName({ ...applicant, nickname: null });
    const nickname = applicant.nickname || 'no nickname';
    return (
      <span>
        {name} <i>({nickname})</i>
      </span>
    );
  };

  const getPersonDisplayValue = property => {
    const defaultValue = '-';
    const { applicant } = badgeApplication;
    switch (property) {
      case 'airportPersonId':
        return applicant.airportPersonId || defaultValue;
      case 'email':
        return applicant.email || defaultValue;
      case 'dob':
        return applicant.dob;
      case 'mobile':
        return applicant.mobile ? formatPhoneNumber(applicant.mobile) : defaultValue;
      case 'phones':
        return applicant.otherPhones || defaultValue;
      case 'employeeId':
        return applicant.employeeId || defaultValue;
      case 'firstResponder':
        return applicant.firstResponder ? 'Yes' : 'No';
      case 'rapbackCaseNumber':
        return applicant.tscData?.rapbackCaseNumber || defaultValue;
      case 'rapbackStatus':
        return applicant.tscData?.rapbackStatus || defaultValue;
      case 'rapbackRenewalDate':
        return applicant.tscData?.rapbackRenewalDate
          ? moment.tz(applicant.tscData?.rapbackRenewalDate, app.timezone).format('MM/DD/YYYY hh:mm A')
          : defaultValue;
      case 'rapbackExpirationDate':
        return applicant.tscData?.rapbackExpirationDate
          ? moment.tz(applicant.tscData?.rapbackExpirationDate, app.timezone).format('MM/DD/YYYY hh:mm A')
          : defaultValue;
      case 'rapbackTerminationDate':
        return applicant.tscData?.rapbackTerminationDate
          ? moment.tz(applicant.tscData?.rapbackTerminationDate, app.timezone).format('MM/DD/YYYY hh:mm A')
          : defaultValue;
      case 'staId':
        return applicant.tscData?.staId || defaultValue;
      case 'staResult':
        return applicant.tscData?.staResult || defaultValue;
      case 'staResultDate':
        return applicant.tscData?.staResultDate
          ? moment.tz(applicant.tscData?.staResultDate, app.timezone).format('MM/DD/YYYY hh:mm A')
          : defaultValue;
      default:
        return defaultValue;
    }
  };

  const getBadgeDisplayValue = property => {
    const defaultValue = '-';
    switch (property) {
      case 'type':
        return badgeApplication.badgeType?.displayName || defaultValue;
      case 'signatory':
        return badgeApplication.signatory?.fullName || defaultValue;
      case 'company':
        return badgeApplication.signatory?.company?.name || defaultValue;
      case 'leo':
        return defaultValue;
      case 'doors':
        return badgeApplication.doors || '-';
      default:
        return defaultValue;
    }
  };

  const downloadPdf = () => {
    app.api.toggleLoading(true);
    badgeApplicationApi
      .downloadSummary({ badgeApplicationUuid: badgeApplication.uuid })
      .then(response => {
        const fileName = 'BadgeApplicationSummary.pdf';
        const options = { type: 'application/pdf' };
        const file = new File([response], fileName, options);
        const url = URL.createObjectURL(file);
        window.open(url);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const getDrivingOption = () => {
    const drivingOptions = getDrivingOptions(badgeApplication?.badgeType?.config?.endorsements?.driving);
    return drivingOptions.find(option => option.value === badgeApplication?.driving) || 'ERROR';
  };

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Issue Badge Summary"
      isOpen={isOpen}
      onClose={onClose}
      closeLabel="Close"
      leftSideExtra={
        <Button color="primary" onClick={downloadPdf}>
          Download as PDF
        </Button>
      }
    >
      <TitledBox title="Badge Holder" style={{ marginBottom: 32 }}>
        {!!badgeApplication?.applicant?.isNameChanged && (
          <Alert color="warning">
            This badge holder's name was updated during the badge application process. PACS may need to be updated with
            the new name.
          </Alert>
        )}
        <Row>
          <Col>
            <ReadOnlyFormData label="Full Name (Nickname)" value={getName()} />
          </Col>
          <Col>
            <ReadOnlyFormData label="Airport Person ID" value={getPersonDisplayValue('airportPersonId')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="Email" value={getPersonDisplayValue('email')} />
          </Col>
          <Col>
            <ReadOnlyFormData label="Date Of Birth" value={getPersonDisplayValue('dob')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="Mobile Phone" value={getPersonDisplayValue('mobile')} />
          </Col>
          <Col>
            <ReadOnlyFormData label="Phones (other)" value={getPersonDisplayValue('phones')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="Employer's ID" value={getPersonDisplayValue('employeeId')} />
          </Col>
          <Col>
            <ReadOnlyFormData label="First Responder" value={getPersonDisplayValue('firstResponder')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="STA ID" value={getPersonDisplayValue('staId')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="STA Result" value={getPersonDisplayValue('staResult')} />
          </Col>
          <Col>
            <ReadOnlyFormData label="STA Result Date" value={getPersonDisplayValue('staResultDate')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="Rapback Case #" value={getPersonDisplayValue('rapbackCaseNumber')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="Rapback Status" value={getPersonDisplayValue('rapbackStatus')} />
          </Col>
          <Col>
            <ReadOnlyFormData label="Rapback Renewal Date" value={getPersonDisplayValue('rapbackRenewalDate')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="Rapback Expiration Date" value={getPersonDisplayValue('rapbackExpirationDate')} />
          </Col>
          <Col>
            <ReadOnlyFormData
              label="Rapback Termination Date"
              value={getPersonDisplayValue('rapbackTerminationDate')}
            />
          </Col>
        </Row>
      </TitledBox>

      <TitledBox title="Badge">
        <Row>
          <Col>
            <ReadOnlyFormData label="Type" value={getBadgeDisplayValue('type')} />
          </Col>
          <Col>
            <ReadOnlyFormData label="Signatory" value={getBadgeDisplayValue('signatory')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReadOnlyFormData label="Company" value={getBadgeDisplayValue('company')} />
          </Col>
          <Col>
            <EscortEndorsementPicker
              isReadOnly={true}
              context={{
                escortEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.escort,
                escortSelection: badgeApplication.isEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
                setEscortSelection: () => false,
                shouldSkipDefaultSelection: true,
                data: { badgeType: badgeApplication?.badgeType },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DrivingEndorsementPicker
              isReadOnly={true}
              context={{
                drivingEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.driving,
                drivingSelection: getDrivingOption(),
                setDrivingSelection: () => false,
                shouldSkipDefaultSelection: true,
                data: { badgeType: badgeApplication?.badgeType },
              }}
            />
          </Col>
          <Col>
            <ReadOnlyFormData label="Doors" value={getBadgeDisplayValue('doors')} />
          </Col>
        </Row>
      </TitledBox>
    </AirBadgeModal>
  );
}
