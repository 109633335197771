import request from '../Request';

export default function AppointmentsUpcomingRequest({ user, staff, appointmentType, forUser, start, statusFilter }) {
  let data = {};

  if (staff && staff.value) data.staff = staff.value;
  if (appointmentType && appointmentType.value) data.appointmentType = appointmentType.value;
  if (forUser) data.uuid = forUser.uuid;
  if (start) data.start = start;
  data.statusFilter = statusFilter || 'unprocessed';

  return request(user, 'POST', 'appointments/upcoming', data);
}
