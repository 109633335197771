import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomModalHeader from '../../shared/components/CustomModalHeader/CustomModalHeader';

export default class Confirm extends Component {
  resetState() {
    this.props.api.updateState({
      confirmContent: '',
      confirmTitle: '',
      confirmCallbackOnConfirm: undefined,
      confirmCallbackOnClose: undefined,
    });
  }

  onClose() {
    this.resetState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onConfirm() {
    this.resetState();
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={!!this.props.message} toggle={this.onClose.bind(this)}>
          <CustomModalHeader toggle={this.onClose.bind(this)}>{this.props.title}</CustomModalHeader>
          <ModalBody>
            <p>{this.props.message}</p>
          </ModalBody>
          <ModalFooter>
            <Button color={'secondary'} onClick={this.onClose.bind(this)}>
              Close
            </Button>
            <Button color={'primary'} onClick={this.onConfirm.bind(this)}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
