import React, { useCallback, useContext, useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import BadgeApplicationAppointmentCreateModal from '../../../../Home/BadgeApplicationsList/components/BadgeApplicationAppointmentCreateModal';
import AppointmentsListEditModal from '../../../../Appointments/AppointmentsList/AppointmentsListEditModal';

export default function ScheduleBadgePickupAction() {
  const defaultActionLabel = 'Schedule Badge Pickup Appointment';
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone } = useContext(ActionPanelContext);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [actionButtonLabel, setActionButtonLabel] = useState(defaultActionLabel);
  const [isRescheduleAppointmentModalOpen, setIsRescheduleAppointmentModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [canBeRescheduled, setCanBeRescheduled] = useState(false);
  const { active: isBadgeApplicationActive, config } = badgeApplication;
  const { approveBadgeIssuance, badgePickupAppointment } = config;

  const checkAppointments = () => {
    const scheduledAppointment = (badgeApplication.appointments || []).find(
      a => a.appointmentType.uuid === badgePickupAppointment.appointmentTypeUuid
    );
    setSelectedAppointment(scheduledAppointment);
    setCanBeRescheduled(!!scheduledAppointment);
    if (scheduledAppointment) {
      setActionButtonLabel('Reschedule Badge Pickup Appointment');
    } else {
      setActionButtonLabel(defaultActionLabel);
    }
  };

  const isHidden = useCallback(() => {
    if (!!isBadgeApplicationActive === false) return true;
    if (approveBadgeIssuance.isEnabled && !approveBadgeIssuance.isComplete) return true;
    if (!badgePickupAppointment.isEnabled) return true;
    if (badgePickupAppointment.appointmentUuid && !canBeRescheduled) return true;
    return false;
  }, [badgeApplication, canBeRescheduled]);

  const onClick = () => {
    if (canBeRescheduled) {
      setIsRescheduleAppointmentModalOpen(true);
    } else {
      setIsAppointmentModalOpen(true);
    }
  };

  const onSaveAppointment = () => {
    setIsAppointmentModalOpen(false);
    onActionDone({ shouldReload: true });
  };

  const onCloseAppointmentModal = () => {
    setIsAppointmentModalOpen(false);
  };

  const onCloseRescheduleAppointmentModal = () => {
    setIsRescheduleAppointmentModalOpen(false);
  };

  const onRescheduleAppointment = () => {
    setIsRescheduleAppointmentModalOpen(false);
    onActionDone({ shouldReload: true });
  };

  useEffect(() => {
    setCanBeRescheduled(false);
    setSelectedAppointment(null);
    setIsRescheduleAppointmentModalOpen(false);
    setActionButtonLabel(defaultActionLabel);
    checkAppointments();
  }, [badgeApplication]);

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={onClick}>
        <ListItemIcon>
          {canBeRescheduled ? (
            <FontAwesomeIcon className="fa-regular fa-calendar-check" />
          ) : (
            <FontAwesomeIcon className="fa-regular fa-calendar-plus" />
          )}
        </ListItemIcon>
        <ListItemText primary={actionButtonLabel} />
      </ListItem>

      <BadgeApplicationAppointmentCreateModal
        isOpen={isAppointmentModalOpen}
        badgeApplication={badgeApplication}
        user={app.user}
        attendee={badgeApplication.applicant}
        api={app.api}
        timeZone={app.timezone}
        onSave={onSaveAppointment}
        onClose={onCloseAppointmentModal}
        defaultTypeUuid={badgePickupAppointment.appointmentTypeUuid}
      />

      {canBeRescheduled && isRescheduleAppointmentModalOpen && (
        <AppointmentsListEditModal
          isOpen={isRescheduleAppointmentModalOpen}
          editAppointment={selectedAppointment}
          onClose={onCloseRescheduleAppointmentModal}
          onSave={onRescheduleAppointment}
          user={app.user}
          api={app.api}
          timeZone={app.timezone}
          mode="badge-holder-reschedule"
        />
      )}
    </>
  );
}
