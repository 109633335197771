import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export function AutoCompleteUserOption({ user }) {
  const name = user?.name;
  const airportPersonId =
    user.original?.tscPersonId || user.original?.airportPersonId || user?.tscPersonId || user?.airportPersonId || '-';
  const email = user?.original?.email || user?.email || '-';
  let dob = user?.original?.dob || user?.dob || '-';
  if (dob && dob !== '-' && dob.includes('-')) {
    const [year, month, day] = dob.split('-');
    dob = `${month}/${day}/${year}`;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <strong>{name}</strong>
      <small>
        <i>Airport Person ID:</i> {airportPersonId}
      </small>
      <small>
        <i>Email:</i> {email}
      </small>
      <small>
        <i>DoB:</i> {dob}
      </small>
    </div>
  );
}

export function filterUserOptions(options, state) {
  if (!state?.inputValue) return [];

  const inputValue = state.inputValue.toLocaleLowerCase().trim();
  const parts = inputValue.split(' ');

  return options.filter(({ original }) => {
    const firstName = original.firstName.toLocaleLowerCase();
    const lastName = original.lastName.toLocaleLowerCase();
    const firstLast = `${firstName} ${lastName}`;
    const lastFirst = `${lastName} ${firstName}`;
    let firstMiddleLast = `${firstName} ${lastName}`;
    let firstMiddle = `${firstName}`;
    if (original.middleName) {
      const middleName = original.middleName.toLocaleLowerCase();
      firstMiddleLast = `${firstName} ${middleName} ${lastName}`;
      firstMiddle = `${firstName} ${middleName}`;
    }

    if (parts.length === 2) {
      return firstLast.startsWith(inputValue) || lastFirst.startsWith(inputValue) || firstMiddle.startsWith(inputValue);
    }
    if (parts.length === 3) {
      return firstMiddleLast.startsWith(inputValue);
    }

    return true;
  });
}

export default function AutoCompleteDropdown({
  id,
  value,
  onInputChange,
  onValueSelected,
  options,
  isLoading,
  placeholder,
  ...rest
}) {
  useEffect(() => {
    document
      .querySelectorAll('.airbadge-autocomplete > div > fieldset')
      .forEach(x => (x.style.border = '1px solid #e4e7ea'));
  }, []);

  return (
    <div>
      <Autocomplete
        id={id}
        value={value}
        onInputChange={onInputChange}
        onChange={(event, value) => onValueSelected(value)}
        getOptionSelected={(option, value) => (option?.name || option?.label) === (value?.name || value?.label)}
        getOptionLabel={option => option?.name || option?.label}
        options={options}
        loading={isLoading}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            className={`airbadge-autocomplete ${rest.disabled ? 'custom-disabled' : null}`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder={placeholder || ''}
          />
        )}
        {...rest}
      />
    </div>
  );
}
