import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { getDefaultBadgeExpiration } from '../../../Badges/BadgesList/CreateBadgeApplicationBadgeModal/CreateBadgeApplicationBadgeModal';
import { AppContext } from '../../../../../App';
import AuthorizedSignatoryGetRequest from '../../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryGetRequest';

export default function BadgeTypeSelectionModal({ isOpen, badgeTypes, onClose }) {
  const app = useContext(AppContext);
  const [selectedBadgeType, setSelectedBadgeType] = useState(null);
  const [selectedSignatory, setSelectedSignatory] = useState(null);
  const [signatories, setSignatories] = useState([]);

  const onModalClose = () => {
    const badgeType = selectedBadgeType.original;
    const signatory = selectedSignatory.original;

    let expiration = moment().endOf('month').add(2, 'years').format('YYYY-MM-DD');
    if (badgeType.config) {
      expiration = getDefaultBadgeExpiration({ badgeType, timezone: app.timezone });
    }

    onClose({ badgeType, signatory, expiration, endorsements: { driving: 'none', isEscort: false } });
  };

  useEffect(() => {
    setSelectedBadgeType(null);
    setSelectedSignatory(null);
    setSignatories([]);

    if (isOpen) {
      app.api.toggleLoading(true);
      AuthorizedSignatoryGetRequest()
        .then(list => {
          setSignatories(
            list
              .filter(x => x.active)
              .map(x => {
                const { user, profile_id, companyName } = x;
                const { first_name, last_name } = user;
                return {
                  label: `${first_name} ${last_name}, ${companyName}`,
                  value: profile_id,
                  original: x,
                };
              })
          );
        })
        .finally(() => app.api.toggleLoading(false));
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Choose Pre-Printing Information"
      isOpen={isOpen}
      onClose={onClose}
      saveLabel="Proceed to Printing"
      onSave={onModalClose}
      saveDisabled={!selectedBadgeType || !selectedSignatory}
    >
      <Grid container spacing={3} direction="column">
        <Grid item>
          <FormGroup>
            <Label>Which Badge Type is being issued?</Label>
            <Select
              placeholder=""
              classNamePrefix="airbadge"
              className="form-select"
              options={badgeTypes.map(bt => ({ value: bt.uuid, label: bt.displayName, original: bt }))}
              value={selectedBadgeType}
              onChange={setSelectedBadgeType}
            />
          </FormGroup>
        </Grid>

        <Grid item>
          <FormGroup>
            <Label>Authorized Signatory</Label>
            <Select
              placeholder=""
              classNamePrefix="airbadge"
              className="form-select"
              options={signatories}
              value={selectedSignatory}
              onChange={setSelectedSignatory}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </AirBadgeModal>
  );
}
