import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment-timezone';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import AutoCompleteDropdown, {
  AutoCompleteUserOption,
  filterUserOptions,
} from '../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import request from '../../../../ajax/Request';
import { handleError } from '../../../../utils';
import BookingGetAvailableAppointmentTypesRequest from '../../../../ajax/Booking/BookingGetAvailableAppointmentTypesRequest';
import { AppContext } from '../../../../App';
import InputWithCharacterLimit from '../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import appointmentApi from '../../../../ajax/Appointment/appointmentApi';

let attendeesRequestTimeout = null;
let attendeesAbortController = null;

export default function AppointmentRequestCreateModal({ isOpen, onClose }) {
  const app = useContext(AppContext);
  const [attendee, setAttendee] = useState(null);
  const [isAttendeesLoading, setIsAttendeesLoading] = useState(false);
  const [holderOptions, setHolderOptions] = useState([]);
  const [isAppointmentTypesLoading, setIsAppointmentTypesLoading] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentType, setAppointmentType] = useState(null);
  const [expiration, setExpiration] = useState(moment().add(7, 'days'));
  const [notes, setNotes] = useState('');

  const findAttendeeUsers = event => {
    if (attendeesRequestTimeout) {
      if (attendeesAbortController) attendeesAbortController.abort();
      clearTimeout(attendeesRequestTimeout);
    }

    const fullName = event?.target?.value || null;
    if (!fullName || fullName.length < 3) {
      setIsAttendeesLoading(false);
      return;
    }

    setHolderOptions([]);
    setIsAttendeesLoading(true);

    attendeesRequestTimeout = setTimeout(() => {
      attendeesAbortController = new AbortController();
      request(
        'authenticated-user',
        'GET',
        `appointments/available-attendees?search=${encodeURIComponent(fullName)}`,
        null,
        attendeesAbortController.signal
      )
        .then(({ success, results }) => {
          if (success) {
            setHolderOptions(
              results.map(r => ({
                label: `${r.firstName} ${r.lastName}`,
                name: `${r.firstName} ${r.lastName}`,
                value: r.uuid,
                original: r,
              }))
            );
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsAttendeesLoading(false));
    }, 500);
  };

  const getAppointmentTypes = () => {
    setIsAppointmentTypesLoading(true);
    BookingGetAvailableAppointmentTypesRequest(app.user, app.user.activeProfile?.uuid)
      .then(apptTypes => {
        setAppointmentTypes(
          apptTypes.map(x => ({
            label: `${x.name} (${x.duration} minutes, ${x.location})`,
            value: x.uuid,
          }))
        );
        setIsAppointmentTypesLoading(false);
      })
      .catch(error => handleError({ error }));
  };

  const onModalClose = args => {
    setAttendee(null);
    setIsAttendeesLoading(false);
    setHolderOptions([]);
    setIsAppointmentTypesLoading(false);
    setAppointmentTypes([]);
    setAppointmentType(null);
    setExpiration(moment().add(7, 'days'));
    setNotes('');
    onClose(args);
  };

  const isSaveDisabled = () => {
    return !attendee || !appointmentType || !expiration;
  };

  const onSave = () => {
    app.api.toggleLoading(true);
    appointmentApi
      .createAppointmentRequest({
        attendeeUuid: attendee.value,
        appointmentTypeUuid: appointmentType.value,
        expires: expiration.format('YYYY-MM-DD'),
        notes,
      })
      .then(({ success, message }) => {
        if (success) {
          onModalClose({ shouldReload: true });
        } else {
          handleError({ message });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onModalClicked = () => {
    const trigger = document.querySelector('.expiration-date-trigger .datetime-picker-popup');
    if (trigger?.style?.display !== 'none') {
      document.querySelector('#expirationDate').click();
    }
  };

  useEffect(() => {
    if (isOpen) getAppointmentTypes();
  }, [isOpen]);

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      isOpen={isOpen}
      onModalClicked={onModalClicked}
      onClose={onModalClose}
      title="New Appointment Request"
      saveLabel="Create Request"
      onSave={onSave}
      saveDisabled={isSaveDisabled()}
    >
      <Row>
        <Col>
          <FormGroup>
            <Label className="required">Attendee</Label>
            <AutoCompleteDropdown
              id="attendee"
              value={attendee}
              placeholder="Search by first or last name"
              size="small"
              onInputChange={findAttendeeUsers}
              isLoading={isAttendeesLoading}
              options={holderOptions}
              onValueSelected={setAttendee}
              filterOptions={filterUserOptions}
              renderOption={user => <AutoCompleteUserOption user={user} />}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <FormGroup>
            <Label className="required">Appointment Type</Label>
            <Select
              classNamePrefix="airbadge"
              isSearchable={false}
              options={appointmentTypes}
              className="form-select"
              value={appointmentType}
              placeholder=""
              onChange={setAppointmentType}
              isDisabled={isAppointmentTypesLoading}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <FormGroup>
            <Label className="required">How long should this request be available to the attendee?</Label>
            <DatetimePickerTrigger
              className="expiration-date-trigger"
              closeOnSelectDay={true}
              minDate={moment()}
              showTimePicker={false}
              moment={expiration}
              onChange={setExpiration}
            >
              <InputGroup>
                <InputGroupText>
                  <i className="fa-solid fa-calendar-days" />
                </InputGroupText>
                <Input
                  style={{ width: 150 }}
                  type="text"
                  id="expirationDate"
                  value={expiration ? expiration.format('MM/DD/YYYY') : ''}
                  readOnly
                />
              </InputGroup>
            </DatetimePickerTrigger>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <FormGroup>
            <Label className="mb-0">
              Attendee Instructions / Comments <i style={{ fontSize: 'smaller' }}>(optional)</i>
            </Label>
            <div className="mb-2">
              <small>
                <i>
                  This will show on the attendee's appointments summary page and will be added to any email
                  notifications that are sent as soon as this request is created
                </i>
              </small>
            </div>
            <InputWithCharacterLimit limit={191} type="textarea" value={notes} onChange={setNotes} />
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
