import React, { useContext, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { GoogleLogin } from '@react-oauth/google';
import logo from '../../containers/DefaultLayout/airbadge-logo.png';
import { AppContext, appSettings } from '../../App';
import packageInfo from '../../../package.json';
import { handleError } from '../../utils';
import request from '../../ajax/Request';

export default function GoogleLoginPage() {
  const app = useContext(AppContext);

  const setupUserData = response => {
    const user = {
      authToken: response.auth_token,
      uuid: response.uuid,
      isSuperAdmin: response.isSuperAdmin,
      isDemoUser: response.isDemoUser,
      firstName: response.first_name,
      middleName: response.middle_name,
      lastName: response.last_name,
      suffix: response.suffix,
      nickname: response.nickname,
      dob: response.dob,
      email: response.email,
      phone: response.phone,
      mobile: response.mobile,
      profiles: response.profiles,
      activeProfile: response.active_profile,
      managerForCompanies: response.manager_for_companies,
      numActiveAudits: response.numActiveAudits,
    };

    const role = response.profiles.length ? response.profiles[0].role.name : '';

    app.api.updateState({
      user,
      role,
      enrollment: response.enrollment,
    });

    localStorage.setItem('AirportBadges.user', JSON.stringify(user));
  };

  useEffect(() => {
    console.info(`AirBadge version ${packageInfo.version}`);
  }, []);

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col>
            <div className="m-auto" style={{ maxWidth: '500px' }}>
              <div className="text-center py-4">
                <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
                <Card>
                  <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h1 className="text-center mb-4">AirBadge Staff Login</h1>

                    <GoogleLogin
                      useOneTap
                      size="large"
                      onSuccess={({ credential }) => {
                        request('authenticated-user', 'POST', 'account/login-third-party', {
                          type: 'google',
                          token: credential,
                        })
                          .then(setupUserData)
                          .catch(error => handleError({ error }));
                      }}
                      onError={() => handleError({ message: 'Login failed' })}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
