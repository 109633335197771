import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';

export default function WeightQuestion({ isHidden, value, onBack, onNext, isSecuredBadge }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [pounds, setPounds] = useState(null);
  const [isSkipped, setIsSkipped] = useState(false);

  const isNextHidden = () => {
    if (isSkipped) return false;
    if (pounds) {
      return !(pounds < 500 && pounds > 49);
    }
    return true;
  };

  const onNextClicked = () => {
    if (isSkipped) {
      onNext('000');
    } else {
      onNext(pounds);
    }
  };

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col>
          <FormLabel>
            <Typography variant="h6">
              {t('question5')}
              {isSecuredBadge ? ' *' : ' **'}
            </Typography>
          </FormLabel>
        </Col>
      </Row>

      {/* 50 to 499 */}

      <Row>
        <Col xs="10" sm="5" lg="3">
          <FormLabel>
            <Typography variant="subtitle2">{t('question5_pounds')}</Typography>
          </FormLabel>
          <FormGroup>
            <input
              type="number"
              className={`airbadge-input ${isSkipped ? 'custom-disabled' : ''}`}
              min={50}
              max={499}
              step={1}
              pattern="[0-9]"
              value={pounds || ''}
              onChange={({ target }) => setPounds(target.value ? parseInt(target.value, 10) : 0)}
              disabled={isSkipped}
            />
          </FormGroup>
        </Col>
      </Row>

      {!isSecuredBadge && (
        <Row>
          <Col>
            <MuiFormGroup row>
              <MuiFormControlLabel
                control={<Switch color="primary" />}
                label={t('question5_skip')}
                checked={isSkipped}
                onChange={({ target }) => {
                  setIsSkipped(target.checked);
                  setPounds(null);
                }}
              />
            </MuiFormGroup>
          </Col>
        </Row>
      )}

      <BackButton onClick={onBack} />
      <NextButton isDisabled={isNextHidden()} onClick={onNextClicked} />
    </>
  );
}
