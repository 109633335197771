import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import { BlockFormLabel } from '../../../components/styledComponents';
import { trim } from './badgerUtils';

export default function EmailAddressQuestion({ isHidden, value, onBack, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [state, setState] = useState(value || '');

  const cleanupData = () => {
    const newState = trim(state);
    setState(newState);
    return newState;
  };

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col>
          <BlockFormLabel>
            <Typography variant="h6">{t('question14')}</Typography>
          </BlockFormLabel>
          <FormLabel>
            <Typography variant="subtitle2">{t('question14_extra')}</Typography>
          </FormLabel>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <FormGroup>
            <Input type="email" value={state} onChange={({ target }) => setState(target.value)} />
          </FormGroup>
        </Col>
      </Row>

      <BackButton onClick={onBack} />
      <NextButton isHidden={false} onClick={() => onNext(cleanupData())} />
    </>
  );
}
