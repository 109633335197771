import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { AppContext } from '../../../../../../App';
import request from '../../../../../../ajax/Request';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function ActivateBadgeModal({ isOpen, onClose, onSave, badge }) {
  const app = useContext(AppContext);
  const isPacsEnabled = app?.airport?.isPacsEnabled;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [isWarningShown, setIsWarningShown] = useState(false);
  const [daysSinceDeactivation, setDaysSinceDeactivation] = useState(0);

  const cleanup = () => {
    setIsModalOpen(false);
    setComment('');
    setIsWarningShown(false);
    setDaysSinceDeactivation(0);
  };

  const onModalClose = () => {
    cleanup();
    onClose();
  };

  const onSaveChanges = async () => {
    try {
      app.api.toggleLoading(true);
      const response = await request(app.user, 'PUT', `badges/${badge.uuid}/status`, { new_status: 'in_use', comment });
      if (response.success) {
        app.api.displayMessage('Badge successfully activated', 'success');
        onSave({ ...response, active: 1 });
        cleanup();
      }
    } catch (error) {
      app.api.displayMessage('Unable to activate badge', 'warning');
    } finally {
      app.api.toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen || !badge) return;

    const now = moment();
    const deactivationDate = moment(badge.deactivationDate, 'MM/DD/YYYY');
    const daysSinceDeactivation = now.diff(deactivationDate, 'days').toLocaleString();
    if (daysSinceDeactivation >= 25) {
      setIsWarningShown(true);
      setDaysSinceDeactivation(daysSinceDeactivation);
    }
    setIsModalOpen(true);
  }, [isOpen, badge]);

  if (!isOpen) return null;

  if (isPacsEnabled) {
    return (
      <AirBadgeModal
        title="Re-Activation Not Permitted"
        isOpen={isModalOpen}
        size={MODAL_SIZE_MEDIUM}
        onClose={onModalClose}
        closeLabel="Close"
      >
        <Alert color="danger">
          Your airport security policy does not allow badge re-activations in AirBadge. This must be done at access
          control.
        </Alert>
      </AirBadgeModal>
    );
  }

  return (
    <AirBadgeModal
      title="Activate Badge"
      size={MODAL_SIZE_MEDIUM}
      isOpen={isModalOpen}
      closeLabel="Cancel"
      onClose={onModalClose}
      saveLabel="Activate Badge"
      onSave={onSaveChanges}
    >
      {isWarningShown && (
        <Alert color="warning">
          It has been {daysSinceDeactivation} {daysSinceDeactivation === 1 ? 'day' : 'days'} since this badge was
          deactivated. Are you sure you mean to reactivate this badge? In many cases, this may not be TSA compliant.
        </Alert>
      )}
      <Row>
        <Col>
          <FormGroup>
            <Label>
              Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
            </Label>
            <Input type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
