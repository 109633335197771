import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import routes from '../../routes';
import QuickAuditFooter from './QuickAuditFooter';
import QuickAuditHeader from './QuickAuditHeader';
import Loading from './Loading';
import Message from './Message';
import Confirm from './Confirm';

export default class QuickAuditLayout extends Component {
  render() {
    return (
      <div className={'app'}>
        <Loading loading={this.props.state.loading} target={this.props.state.loadingTarget} />
        <Message brand={this.props.state.messageBrand} message={this.props.state.messageContent} />
        <Confirm
          api={this.props.api}
          title={this.props.state.confirmTitle}
          message={this.props.state.confirmContent}
          onConfirm={this.props.state.confirmCallbackOnConfirm}
          onClose={this.props.state.confirmCallbackOnClose}
        />

        <QuickAuditHeader api={this.props.api} state={this.props.state} />

        <div className={'app-body'}>
          <main className={'main'}>
            <Container className={'pt-4'} fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} state={this.props.state} api={this.props.api} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/default/home" />
              </Switch>
            </Container>
          </main>
          {/*<AppAside fixed>*/}
          {/*    <QuickAuditAside/>*/}
          {/*</AppAside>*/}
        </div>
        <footer className="app-footer">
          <Container>
            <QuickAuditFooter />
          </Container>
        </footer>
      </div>
    );
  }
}
