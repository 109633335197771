import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { AppContext, colors } from '../../../../../App';
import { BadgesLabel } from '../PeopleListEditModal';
import { hasFeature } from '../../../../../shared/Feature';

function NoImageDisplay() {
  return (
    <>
      <style>{`
          .cross {
            position: relative;
            height: 80px;
            width: 60px;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .cross::before,
          .cross::after {
            content: "";
            position: absolute;
            width: 140%;
            height: 1px; /* Adjust the thickness of the X */
            background-color: lightgray;
            opacity: 0.6;
            top: 50%;
            left: -20%;
            transform-origin: center;
          }

        .cross::before {transform: rotate(55deg);}
        .cross::after {transform: rotate(-55deg);}
      `}</style>
      <div className="cross">
        <div>No</div>
        <div>Image</div>
      </div>
    </>
  );
}

function NameDisplay({ person, fullName }) {
  return (
    <>
      <div>
        <span>{fullName}</span>
        <span className={!person.tsc_person_id ? 'ml-2 text-danger' : 'ml-2'}>
          (
          {person.tsc_person_id || (
            <>
              <FontAwesomeIcon
                className="fa fa-ban"
                color={colors.danger}
                style={{
                  fontSize: 12,
                  paddingLeft: 0,
                }}
              />{' '}
              Not associated
            </>
          )}
          )
        </span>
      </div>
      <BadgesLabel numActiveBadges={person.numActiveBadges} />
    </>
  );
}

export default function PersonInfoModalHeader({ person, fullName, dateOfBirth }) {
  const app = useContext(AppContext);
  const isHeadshotsFeatureEnabled = hasFeature('headshot_pictures', app?.airport?.config?.features || []);

  return (
    <div
      style={{
        width: '100%',
        border: '1px solid #c8ced3',
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        padding: 12,
      }}
    >
      <Grid container>
        <Grid container spacing={3}>
          {isHeadshotsFeatureEnabled && (
            <Grid item xs={2}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {person.headshot_picture ? (
                  <img
                    alt="Portrait Image"
                    style={{ height: 80, width: 60, backgroundColor: '#ffffff', border: '1px solid #c8ced3' }}
                    src={`data:image/jpeg;base64,${person.headshot_picture}`}
                  />
                ) : (
                  <NoImageDisplay />
                )}
              </div>
            </Grid>
          )}
          <Grid item sm={10} md={7}>
            <ReadOnlyFormData
              skipTextTransform
              label="Full Name (Airport Person ID)"
              value={<NameDisplay person={person} fullName={fullName} />}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <ReadOnlyFormData skipTextTransform label="Date Of Birth" value={dateOfBirth} />
          </Grid>
        </Grid>
        {person.is_tsc_exempt ? (
          <Grid container>
            <Grid item xs={12} className="text-center" style={{ paddingTop: 8 }}>
              <i className="fa fa-exclamation-triangle mr-2 text-warning" aria-hidden="true"></i>
              <i>This person is not receiving updates from TSC</i>
              <i className="fa fa-exclamation-triangle ml-2 text-warning" aria-hidden="true"></i>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
