import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';
import { appSettings } from '../../../App';
import request from '../../../ajax/Request';
import { handleError } from '../../../utils';

const useStyles = makeStyles(theme => ({
  cardBody: {
    padding: '1.25rem',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
}));

export default function NewUser({ state, history }) {
  const styles = useStyles();
  const [email, setEmail] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const { airport } = state;
  const goToLogin = () => history.replace('/login');

  const onSendEmail = () => {
    setIsSending(true);
    request(null, 'POST', 'account/welcome-email', { email })
      .then(({ success }) => {
        if (success) {
          setIsDone(true);
          setSeconds(5);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsSending(false));
  };

  useEffect(() => {
    if (seconds === null) return;
    if (seconds === 0) return goToLogin();
    setTimeout(() => setSeconds(seconds - 1), 1000);
  }, [seconds]);

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" md="10" lg="6" xl="6">
            <div className="text-center py-4">
              <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
            </div>
            <Card>
              <CardBody className={styles.cardBody}>
                {isDone && (
                  <div
                    style={{
                      textAlign: 'center',
                      margin: '20px 0',
                      fontSize: 'larger',
                    }}
                  >
                    <h2 className="mb-3">Request Received</h2>
                    <p>
                      If <strong>{email}</strong> is in AirBadge, a welcome email has been sent to that address. Check
                      your email now.
                    </p>
                    <div style={{ marginBottom: 25 }}>
                      You will be redirected to the Login page in {seconds} seconds ...
                    </div>
                    <Button onClick={goToLogin} style={{ marginTop: 10 }}>
                      Return to Login
                    </Button>
                  </div>
                )}
                {!isDone && (
                  <>
                    <div className="mt-2 mb-3" style={{ textAlign: 'center' }}>
                      <h1>Welcome to AirBadge</h1>
                    </div>
                    <p className="text-muted mb-4">
                      Enter the email address associated with your account to receive an email with instructions for
                      accessing your account
                    </p>
                    <InputGroup className="mb-4">
                      <InputGroupText>
                        <i className="fa fa-envelope-o" />
                      </InputGroupText>
                      <Input
                        autoFocus
                        type="text"
                        placeholder="Email"
                        autoComplete="Email"
                        onChange={({ target }) => setEmail((target.value || '').trim())}
                        onKeyDown={e => {
                          if (e.key === 'Enter') return onSendEmail();
                        }}
                      />
                    </InputGroup>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Button disabled={!email || isSending} color="primary" onClick={onSendEmail}>
                        Send Instructions
                      </Button>
                      <button type="button" className="btn btn-link" onClick={goToLogin} style={{ marginTop: 15 }}>
                        Return to Login
                      </button>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
            <div className="text-center mt-4">
              <p>AirBadge is Provided by</p>
              <p>{airport && airport.name}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
