export function getDrivingOptions(config) {
  if (!config?.options) {
    return [
      { label: 'None', value: 'none' },
      {
        label: 'Movement Areas (taxiways & runways)',
        value: 'movement_areas',
      },
      { label: 'Limited (ramps/non-movement)', value: 'limited' },
    ];
  }

  const returnValues = [];

  let noneOption = config.options.none;
  noneOption = { ...noneOption, label: noneOption.displayName, value: 'none' };
  returnValues.push(noneOption);

  let movementAreasOption = config.options.movement_areas;
  movementAreasOption = { ...movementAreasOption, label: movementAreasOption.displayName, value: 'movement_areas' };
  returnValues.push(movementAreasOption);

  let limitedOption = config.options.limited;
  limitedOption = { ...limitedOption, label: limitedOption.displayName, value: 'limited' };
  returnValues.push(limitedOption);

  if (config.options.driving_4) {
    let driving4Option = config.options.driving_4;
    driving4Option = { ...driving4Option, label: driving4Option.displayName, value: 'driving_4' };
    returnValues.push(driving4Option);
  }

  if (config.options.driving_5) {
    let driving5Option = config.options.driving_5;
    driving5Option = { ...driving5Option, label: driving5Option.displayName, value: 'driving_5' };
    returnValues.push(driving5Option);
  }

  return returnValues;
}
