import React, { useContext, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';
import Loading from '../../../containers/DefaultLayout/Loading';
import Message from '../../../containers/DefaultLayout/Message';
import { AppContext, appSettings } from '../../../App';

export default function Logout({ state }) {
  const { forceLogout } = useContext(AppContext);

  useEffect(() => {
    forceLogout();
  }, []);

  return (
    <div className="app flex-row align-items-center">
      <Loading loading={state.loading} />
      <Message message={state.messageContent} brand={state.messageBrand} />

      <Container>
        <Row className="justify-content-center">
          <Col>
            <div className={'m-auto'} style={{ maxWidth: '500px' }}>
              <div className={'text-center py-4'}>
                <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
              </div>
              <Card className="p-4">
                <CardBody>
                  <Form>
                    <h1 className="text-center">Logout Successful</h1>
                    <p className="text-center text-muted">You have logged out of AirBadge</p>
                    <Row>
                      <Col>
                        <div className="text-center my-4">
                          <Link to="/login">
                            <Button color="primary" style={{ width: 150 }}>
                              Log In
                            </Button>
                          </Link>
                        </div>
                        <div className="text-center">
                          <a href="https://airbadge.us" color="link" className="px-0 btn-link">
                            Visit AirBadge.us
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
