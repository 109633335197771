import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext, colors } from '../../../../../App';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { handleError } from '../../../../../utils';
import request from '../../../../../ajax/Request';
import { compareValuesAndFlagIfDifferent } from '../../../ActionItems/ActionItemDetails/components/ResolveTSCDataMismatchAction';
import useConfirm from '../../../../../shared/useConfirm';

let requestTimeout = null;
let abortController = null;

export default function ViewTSCInfoAction({ person, onDone }) {
  const app = useContext(AppContext);
  const showConfirm = useConfirm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [airBadgeInfo, setAirBadgeInfo] = useState({});
  const [tscInfo, setTscInfo] = useState({});
  const [hasDiscrepancies, setHasDiscrepancies] = useState(false);

  const applyChanges = () => {
    app.api.toggleLoading(true);
    request('authenticated-user', 'PUT', `people/${person.uuid}/overwrite-with-tsc-info`, { tscInfo })
      .then(() => {
        app.api.toggleLoading(false);
        showConfirm({
          title: 'Reminder',
          content: <div>If there is an open action item for this person, you can safely close the action item.</div>,
          onConfirm: () => null,
          onClose: false,
        });
        setIsModalOpen(false);
        onDone({ shouldReload: true });
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onOverwriteClicked = () => {
    showConfirm({
      title: 'Are you sure?',
      content: <div>Are you sure you want to overwrite AirBadge data with TSC data?</div>,
      onConfirm: () => {
        applyChanges();
      },
      okButtonText: 'Overwrite AirBadge with TSC Data',
      isDangerous: true,
    });
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    onDone({ shouldReload: false });
  };

  const onModalOpen = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setIsModalOpen(true);
    setIsLoading(true);
    setHasDiscrepancies(false);

    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      setIsLoading(true);
      const signal = abortController.signal;
      request('authenticated-user', 'GET', `people/${person.uuid}/tsc-info-for-review`, null, signal)
        .then(({ message, hasDiscrepancies, AirBadgeUser, TSCUser }) => {
          if (message) {
            return handleError({ message });
          }

          setAirBadgeInfo(AirBadgeUser);
          setTscInfo(TSCUser);
          setHasDiscrepancies(hasDiscrepancies);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, []);

  return (
    <div>
      <ListItem button onClick={onModalOpen}>
        <ListItemIcon>
          <FontAwesomeIcon className="fa-solid fa-spell-check" color={colors.primary} style={{ fontSize: 20 }} />
        </ListItemIcon>
        <ListItemText primary="View TSC Name" />
      </ListItem>

      <AirBadgeModal
        size={MODAL_SIZE_LARGE}
        title="View TSC Name & Date of Birth"
        isOpen={isModalOpen}
        onClose={onModalClose}
        closeLabel="Close"
      >
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={50} />
          </div>
        )}

        {!isLoading && (
          <>
            <div className="separator" style={{ marginTop: 16, marginBottom: 8 }}>
              TSC
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around' }}>
              <ReadOnlyFormData
                label="First Name"
                value={compareValuesAndFlagIfDifferent(tscInfo.firstName, airBadgeInfo.firstName)}
                skipTextTransform
              />
              <ReadOnlyFormData
                label="Middle Name"
                value={compareValuesAndFlagIfDifferent(tscInfo.middleName, airBadgeInfo.middleName)}
                skipTextTransform
              />
              <ReadOnlyFormData
                label="Last Name"
                value={compareValuesAndFlagIfDifferent(tscInfo.lastName, airBadgeInfo.lastName)}
                skipTextTransform
              />
              <ReadOnlyFormData
                label="Suffix"
                value={compareValuesAndFlagIfDifferent(tscInfo.suffix, airBadgeInfo.suffix)}
                skipTextTransform
              />
              <ReadOnlyFormData
                label="Date of Birth"
                value={compareValuesAndFlagIfDifferent(tscInfo.dob, airBadgeInfo.dob)}
                skipTextTransform
              />
            </div>

            <div className="separator" style={{ marginTop: 40, marginBottom: 8 }}>
              AIRBADGE
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <ReadOnlyFormData label="First Name" value={airBadgeInfo.firstName || '-'} skipTextTransform />
              <ReadOnlyFormData label="Middle Name" value={airBadgeInfo.middleName || '-'} skipTextTransform />
              <ReadOnlyFormData label="Last Name" value={airBadgeInfo.lastName || '-'} skipTextTransform />
              <ReadOnlyFormData label="Suffix" value={airBadgeInfo.suffix || '-'} skipTextTransform />
              <ReadOnlyFormData label="Date of Birth" value={airBadgeInfo.dob || '-'} skipTextTransform />
            </div>

            {!hasDiscrepancies && (
              <Alert color="info" style={{ marginTop: 40 }}>
                If all the information is correct, and there is an open action item for this person, you can safely
                close the action item.
              </Alert>
            )}

            {hasDiscrepancies && (
              <Alert color="warning" style={{ marginTop: 40 }}>
                <p>
                  <strong style={{ color: colors.danger }}>Discrepancies found.</strong> This is a serious situation
                  that requires immediate attention.
                </p>
                <div>To correct this action you may need to do 1 or all of the following:</div>
                <ul>
                  <li>
                    <i>(Recommended)</i> Have the badge holder complete a new badge application
                  </li>
                  <li>Replace identification documents</li>
                  <li>Collect printed badge(s)</li>
                  <li>Reprint badge(s)</li>
                  <li>Update PACS profile(s)</li>
                </ul>
                <p>
                  In certain scenarios, it may be inconsequential to accept the information from TSC without performing
                  any of the corrective actions outlined above.
                </p>
                <div>
                  <Button color="danger" onClick={onOverwriteClicked}>
                    Overwrite AirBadge with TSC Data
                  </Button>
                </div>
              </Alert>
            )}
          </>
        )}
      </AirBadgeModal>
    </div>
  );
}
