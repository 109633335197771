import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment';
import { Alert, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AppContext } from '../../../../../../App';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import request from '../../../../../../ajax/Request';
import { reasons } from '../../BadgeCreateModal';
import { handleError } from '../../../../../../utils';

const signatoryReasons = [
  { label: 'Terminated Employment', value: 'Terminated' },
  { label: 'Lost', value: 'Lost' },
  { label: 'Stolen', value: 'Stolen' },
  { label: 'Destroyed', value: 'Destroyed' },
  { label: 'Suspended', value: 'Suspended' },
];

const nonCollectibleStatuses = ['Lost', 'Stolen', 'Unaccounted For'];

export default function DeactivateBadgeModal({ isOpen, onClose, onSave, badge, isSignatoryMode }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [reason, setReason] = useState(null);
  const [isCollected, setIsCollected] = useState(false);
  const [isMisprintWarningShown, setIsMisprintWarningShown] = useState(false);
  const [collectedDate, setCollectedDate] = useState(moment());
  const [isBadgeWarningShown, setIsBadgeWarningShown] = useState(false);

  const cleanup = () => {
    setIsBadgeWarningShown(false);
    setIsModalOpen(false);
    setComment('');
    setReason(null);
    setIsCollected(false);
    setIsMisprintWarningShown(false);
    setCollectedDate(moment());
  };

  const onModalClose = () => {
    cleanup();
    onClose();
  };

  const onSaveChanges = async () => {
    const payload = {
      new_status: reason.value.toLocaleLowerCase(),
      comment,
    };
    if (isCollectibleStatus()) {
      payload.collected = isCollected ? 1 : 0;
      payload.collectedDate = moment(collectedDate).format('YYYY-MM-DD HH:mm:ss');
    }

    try {
      app.api.toggleLoading(true);
      const response = await request(app.user, 'PUT', `badges/${badge.uuid}/status`, payload);
      if (response.success) {
        onSave({ ...response, active: 0 });
        cleanup();
      } else {
        app.api.toggleLoading(false);
      }
    } catch (error) {
      app.api.toggleLoading(false);
      handleError({ error });
    }
  };

  const isCollectibleStatus = () => {
    return reason && !nonCollectibleStatuses.includes(reason.value);
  };

  useEffect(() => {
    if (!isOpen || !badge) return;
    setIsModalOpen(true);
  }, [isOpen, badge]);

  if (!isOpen) return null;

  const onModalClick = () => {
    const date = document.querySelector('.collectedDate-trigger .datetime-picker-popup');
    if (date && date.style.display !== 'none') {
      document.querySelector('#collectedDate').click();
    }
  };

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose} onClick={onModalClick}>
      <CustomModalHeader toggle={onModalClose}>Deactivate Badge</CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label className="required">Why is the badge being deactivated?</Label>
              <Select
                classNamePrefix="airbadge"
                options={isSignatoryMode ? signatoryReasons : reasons}
                className="form-select"
                value={reason}
                placeholder=""
                onChange={x => {
                  setReason(x);
                  setIsMisprintWarningShown(false);
                  setIsBadgeWarningShown(x.value === 'Suspended' || x.value === 'Terminated');
                  if (!isSignatoryMode) {
                    setIsCollected(![...nonCollectibleStatuses, 'Terminated'].includes(x.value));
                  } else {
                    setIsCollected(false);
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        {isSignatoryMode ? null : (
          <>
            {isCollectibleStatus() && (
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={isCollected}
                        onChange={({ target }) => {
                          const checked = target.checked;
                          setIsCollected(checked);
                          setIsMisprintWarningShown(!checked && reason.label === 'Misprint');
                        }}
                      />
                      Badge Was Collected
                    </Label>
                  </FormGroup>
                  <br />
                </Col>
              </Row>
            )}
            {isCollected && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Collected Date</Label>
                    <DatetimePickerTrigger
                      className="collectedDate-trigger"
                      moment={collectedDate}
                      showTimePicker={false}
                      onChange={moment => setCollectedDate(moment)}
                    >
                      <Input type="text" id="collectedDate" value={collectedDate.format('MM/DD/YYYY')} readOnly />
                    </DatetimePickerTrigger>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {isMisprintWarningShown && (
              <Row>
                <Col>
                  <Alert color="warning">
                    If this badge was issued, select a different status to represent why it is being deactivated.
                  </Alert>
                </Col>
              </Row>
            )}
          </>
        )}
        {isBadgeWarningShown && (
          <Row>
            <Col>
              <Alert color="warning">Badge must be returned to the Badge Office.</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <Input type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel="Deactivate Badge"
          saveDisabled={!reason || isMisprintWarningShown}
          onSave={onSaveChanges}
        />
      </ModalFooter>
    </Modal>
  );
}
