import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'reactstrap';
import docSpringApi from '../../../ajax/DocSpring/docSpringApi';
import { AppContext, colors } from '../../../App';
import { handleError, isSmallScreen } from '../../../utils';

let requestTimeout = null;

export default function DocSpringDocument({
  transactionUuid: transactUuidProp,
  badgerKey = null,
  onCancelled,
  onSubmitSuccess,
}) {
  const [requestId, setRequestId] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [tokenSecret, setTokenSecret] = useState(null);
  const [dataRequestState, setDataRequestState] = useState(null);
  const [currentTransactionUuid, setCurrentTransactionUuid] = useState(null);
  const formContainerRef = useRef();
  const { transactionUuid: transactUuidParam } = useParams();
  const { api } = useContext(AppContext);
  const history = useHistory();

  function eventListenerCallback(event) {
    if (event?.data === 'cancelled') {
      window.DocSpring?.closeModal();
    }
  }

  const onSaveHandler = submissionId => {
    if (requestTimeout) {
      clearTimeout(requestTimeout);
    }

    requestTimeout = setTimeout(() => {
      api.toggleLoading(true);
      docSpringApi
        .updateTransactionStatus(currentTransactionUuid, submissionId)
        .then(() => {
          if (onSubmitSuccess) {
            return onSubmitSuccess();
          }

          const returnTo = sessionStorage.getItem('AirBadge.BadgeApplications.returnTo');
          let url = '/';
          switch (returnTo) {
            case 'people-grid':
              url = '/default/people';
              break;
            case 'applications-grid':
              url = '/default/badge-applications';
              break;
            default:
              url = '/';
          }
          history.replace(url);
        })
        .catch(error => handleError({ error }))
        .finally(() => api.toggleLoading(false));
    }, 500);
  };

  useEffect(() => {
    if (!transactUuidParam && !transactUuidProp) return;

    (async () => {
      let transactionUuid = transactUuidParam;

      if (transactUuidProp) {
        transactionUuid = transactUuidProp;
      }

      setCurrentTransactionUuid(transactionUuid);

      api.toggleLoading(true);
      if (badgerKey) {
        const { token, dataRequestId, state } = await docSpringApi.generateDataRequestTokenUsingKey(
          badgerKey,
          transactionUuid
        );

        if (token) {
          setTokenId(token.id);
          setTokenSecret(token.secret);
        }

        if (dataRequestId) {
          setRequestId(dataRequestId);
        }

        if (state) {
          setDataRequestState(state);
        }
      } else {
        const { token, dataRequestId, state } = await docSpringApi.generateDataRequestToken(transactionUuid);

        if (token) {
          setTokenId(token.id);
          setTokenSecret(token.secret);
        }

        if (dataRequestId) {
          setRequestId(dataRequestId);
        }

        if (state) {
          setDataRequestState(state);
        }
      }
      api.toggleLoading(false);
    })();

    window.top.addEventListener('message', eventListenerCallback);

    return () => {
      window.top.removeEventListener('message', eventListenerCallback);
      setRequestId(null);
      setTokenId(null);
      setTokenSecret(null);
      setDataRequestState(null);
    };
  }, [transactUuidParam, transactUuidProp]);

  useEffect(() => {
    if (requestId && tokenId && tokenSecret && dataRequestState !== 'completed') {
      const docSpringConfig = {
        domainVerification: false,
        dataRequestId: requestId,
        tokenId: tokenId,
        tokenSecret: tokenSecret,
        focusFirstFieldOnLoad: false,
        showSignatureModalOnFocus: false,
        closeModalOnClickOverlay: true,
        closeModalOnSave: true,
        showTermsOfServiceLink: false,
        submittingTitle: template => 'Submitting...',
        pdfProcessingTitle: template => 'Processing...',
        pdfProcessingMessage: template => `Please wait while we fill in ${template.name}...`,
        waitingForDataRequestsTitle: (remainingDataRequestsCount, template) => '',
        waitingForDataRequestsMessage: (remainingDataRequestsCount, template) =>
          `${remainingDataRequestsCount} signer(s) remaining, then the final PDF will be generated.`,
        completedTitle: (template, downloadURL) => 'Thank you!',
        completedTitleNoDownload: (template, downloadURL) => 'Thank you!',
        pdfProcessedMessage: (template, downloadURL) =>
          `Click the "Download PDF" button to download the filled in ${template.name} PDF.`,
        downloadButtonLabel: 'Download!',

        footerHTML: function () {
          return `
            <div class="dsp-footerLeft">
              <button class="dsp-button" style="background-color: ${colors.warning}" onclick="window.top.postMessage('cancelled', '*')">Cancel - Do Not Sign</button>
            </div>
          `;
        },

        onInitialize: function () {
          if (onCancelled) {
            // TODO: DocSpring - I am having to do this because the DocSpring JS API doesn't notify
            //       us when the modal is closed without submitting the data
            const overlay = document.getElementsByClassName('dsp-modal-overlay')[0];
            const observer = new MutationObserver(mutationsList => {
              for (const mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
                  // Check if the removedNodes array contains the target element
                  if (Array.from(mutation.removedNodes).includes(overlay)) {
                    onCancelled();
                  }
                }
              }
            });
            observer.observe(document.body, { childList: true });
          }
        },

        onSave: function (submissionId) {
          onSaveHandler(submissionId);
        },
      };

      if (isSmallScreen()) {
        docSpringConfig.iframeStyle = 'height: 100vh;';
      }

      window.DocSpring.createVisualForm(docSpringConfig);
    }
  }, [requestId, tokenId, tokenSecret]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <div ref={formContainerRef} className="docspring-form"></div>
        </Col>
      </Row>
      {dataRequestState === 'completed' ? (
        <Row>
          <Col className="text-center">
            <Alert color="info">This document has been already signed!</Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}
