import React, { useContext, useState } from 'react';
import { Alert } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SecurityIcon from '@material-ui/icons/FastForward';
import { AppContext, colors } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { handleError } from '../../../../../../utils';
import peopleApi from '../../../../../../ajax/People/peopleApi';
import MergeDuplicateUsersModal from '../../../../People/PeopleList/MergeDuplicateUsersModal';

export default function BypassBackgroundCheckAction() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { api } = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const { applicationDocument, backgroundCheck } = badgeApplication.config;
  const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState(false);
  const [duplicates, setDuplicates] = useState([]);

  const bypassBackgroundCheck = () => {
    api.toggleLoading(true);
    badgeApplicationApi
      .bypassBackgroundCheck({ badgeApplicationUuid: badgeApplication.uuid })
      .then(({ success }) => {
        if (success) {
          onActionDone({ shouldReload: true });
        } else {
          api.toggleLoading(false);
        }
      })
      .catch(error => {
        api.toggleLoading(false);
        handleError({ error });
      });
  };

  const onProceed = () => {
    const {
      isRenewal,
      applicant: { uuid: excludedUserUuid, badgerData },
    } = badgeApplication;
    const { firstName, middleName, lastName, dob: dateOfBirth } = badgerData;

    setIsModalOpen(false);
    setDuplicates([]);

    if (isRenewal) {
      return bypassBackgroundCheck();
    }

    // Check for possible duplicates!
    api.toggleLoading(true);
    peopleApi
      .findDuplicates({
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        excludedUserUuid,
      })
      .then(({ success, results }) => {
        if (success && results?.length) {
          // STOP!
          api.toggleLoading(false);
          setDuplicates(results);
          setIsDuplicatesModalOpen(true);
        } else {
          bypassBackgroundCheck();
        }
      })
      .catch(error => {
        handleError({ error });
        bypassBackgroundCheck();
      });
  };

  const onDuplicatesModalClosed = ({ wasMerged, notDuplicate }) => {
    setIsDuplicatesModalOpen(false);
    if (wasMerged || notDuplicate) bypassBackgroundCheck();
  };

  const isHidden = () => {
    if (!!badgeApplication.active === false) {
      return true;
    }
    if (applicationDocument.isEnabled && !applicationDocument.isComplete) {
      // Application document needs to be done first
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for doing this
      return true;
    }
    if (backgroundCheck.isEnabled && backgroundCheck.isComplete) {
      return true;
    }

    return false;
  };

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <SecurityIcon style={{ color: colors.warning }} />
        </ListItemIcon>
        <ListItemText primary="Bypass Background Check" />
        <AirBadgeModal
          size={MODAL_SIZE_MEDIUM}
          title="Are you sure?"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          closeLabel="Cancel"
          onSave={onProceed}
          saveLabel="Bypass Background Check"
          saveColor="danger"
        >
          <Alert color="danger">
            This will bypass the background check for this person. This is commonly used for LEOs and other individuals
            not reported to TSC. This is a major security consideration. Only proceed if you are certain.
          </Alert>
        </AirBadgeModal>
      </ListItem>

      <MergeDuplicateUsersModal
        isForBackgroundCheck
        isOpen={isDuplicatesModalOpen}
        onClose={onDuplicatesModalClosed}
        duplicates={duplicates}
        onMergeActionLabel="bypass background check"
        sourcePerson={badgeApplication?.applicant}
      />
    </>
  );
}
