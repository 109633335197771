import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import logo from '../../containers/DefaultLayout/airbadge-logo.png';
import { appSettings } from '../../App';
import { handleError } from '../../utils';
import badgeOfficeApi from '../../ajax/BadgeOffice/badgeOfficeApi';
import { createPusherInstance } from '../../pushNotificationUtils';
import accountApi from '../../ajax/Account/accountApi';

let activityMonitorTimeout = null;

export function generateRandomNumber() {
  let randomNumber;

  do {
    randomNumber = Math.floor(Math.random() * (99999 - 11111 + 1)) + 11111;
  } while (randomNumber % 10 === 0 || Math.floor(randomNumber / 10000) === 0);

  return randomNumber;
}

export default function KioskPage({ state: appState, api: appApi }) {
  const [code, setCode] = useState('');
  const [name, setName] = useState('Badge Office');
  const [pushNotificationsClient, setPushNotificationsClient] = useState(null);
  const [loginCode, setLoginCode] = useState('');

  const updateSignInButton = ({ isDisabled, label }) => {
    // I am having to do direct DOM manipulation because this function
    // loses scope and can't reference this React component's state
    document.getElementById('signInButton').disabled = isDisabled;
    document.getElementById('signInButton').innerHTML = label;
  };

  const startActivityMonitor = ({ pushNotificationsClient }) => {
    clearTimeout(activityMonitorTimeout);

    // 5 minutes = 300000
    const timeout = 300000;

    activityMonitorTimeout = setTimeout(() => {
      revalidate().then(() => {
        // https://pusher.com/docs/channels/using_channels/connection/#querying-the-connection-state
        const pushNotificationsClientStatus = pushNotificationsClient.connection.state;
        if (['initialized', 'connecting', 'connected'].indexOf(pushNotificationsClientStatus) === -1) {
          window.location.reload();
        } else {
          startActivityMonitor({ pushNotificationsClient });
        }
      });
    }, timeout);
  };

  const logIntoAirBadge = ({ authToken, authorizedByUserUuid }) => {
    accountApi
      .loginKiosk({ authToken, kioskUuid: getUuidFromCookie(), authorizedByUserUuid })
      .then(({ success, userData }) => {
        if (!success) return;

        const user = {
          authToken: userData.auth_token,
          uuid: userData.uuid,
          isSuperAdmin: userData.isSuperAdmin,
          isDemoUser: userData.isDemoUser,
          firstName: userData.first_name,
          middleName: userData.middle_name,
          lastName: userData.last_name,
          suffix: userData.suffix,
          nickname: userData.nickname,
          dob: userData.dob,
          email: userData.email,
          phone: userData.phone,
          mobile: userData.mobile,
          profiles: userData.profiles,
          activeProfile: userData.active_profile,
          managerForCompanies: userData.manager_for_companies,
          numActiveAudits: userData.numActiveAudits,
          isKiosk: true,
        };

        const role = userData.profiles?.length ? userData.profiles[0].role.name : '';

        appApi.updateState({
          user,
          role,
          enrollment: true,
        });

        localStorage.setItem('AirportBadges.user', JSON.stringify(user));

        window.location.href = '/#/default/home';
      })
      .catch(error => handleError({ error }))
      .finally(() => updateSignInButton({ isDisabled: false, label: 'Sign In' }));
  };

  const onSignInClicked = () => {
    updateSignInButton({ isDisabled: true, label: 'Validating code...' });

    const kioskUuid = getUuidFromCookie();
    badgeOfficeApi
      .validateLoginCode({ kioskUuid, loginCode })
      .then(({ success }) => {
        if (!success) window.location.reload();
      })
      .catch(error => handleError({ error }));
  };

  const getKioskNameFromCookie = () => {
    const cookie = document.cookie;
    if (!cookie) {
      return 'Badge Office';
    }
    const cookieArray = cookie.split(';');
    const match = cookieArray.find(x => x.indexOf('airbadge_kiosk_cookie') > -1);
    if (match) {
      const cookieValue = match.split('=')[1];
      return decodeURI(cookieValue).split(' || ')[1];
    }
    return 'Badge Office';
  };

  const getUuidFromCookie = () => {
    const cookie = document.cookie;
    if (!cookie) {
      return '';
    }
    const cookieArray = cookie.split(';');
    const match = cookieArray.find(x => x.indexOf('airbadge_kiosk_cookie') > -1);
    if (match) {
      const cookieValue = match.split('=')[1];
      return decodeURI(cookieValue).split(' || ')[0];
    }
    return '';
  };

  const hasValidCookie = () => {
    const cookie = document.cookie;

    // Does a cookie exist?
    if (!cookie) {
      return false;
    }

    // Does this machine have the correct cookie?
    return cookie.indexOf('airbadge_kiosk_cookie') > -1;
  };

  const revalidate = () => {
    return new Promise(resolve => {
      if (!hasValidCookie()) return resolve('false');
      badgeOfficeApi
        .validateKiosk({ kioskUuid: getUuidFromCookie() })
        .then(({ isValid }) => {
          if (!isValid) window.location.reload();
          resolve('true');
        })
        .catch(error => {
          handleError({ error });
          resolve('false');
        });
    });
  };

  const completeEnrollment = data => {
    const genericErrorMessage =
      'Kiosk enrollment failed. Refresh this web browser tab to get a new code and try again.';
    appApi.toggleLoading(true);
    badgeOfficeApi
      .completeKioskEnrollment(data)
      .then(({ success }) => {
        if (!success) {
          return handleError({ message: 'Unable to enroll kiosk. Try again.' });
        }
        window.location.reload();
      })
      .catch(error => handleError({ error, message: genericErrorMessage }))
      .finally(() => appApi.toggleLoading(false));
  };

  const initializePushNotifications = ({ airportCode, authCode }) => {
    if (!airportCode) throw new Error('MISSING AIRPORT CODE');

    const token = `${airportCode}-KIOSK`;
    const pusher = createPusherInstance({ token, currentProfileUuid: null });

    if (!hasValidCookie()) {
      const kioskEnrollmentChannel = pusher.subscribe(`${airportCode}-kiosk-${authCode}`);
      kioskEnrollmentChannel.bind('enrollment', ({ message, content }) => {
        if (message === 'start-enrollment' && content) {
          // {content} contains the UUID, Name, and Token for the new kiosk
          completeEnrollment({ ...JSON.parse(content), code: `${authCode}` });
        }
      });
    } else {
      setName(getKioskNameFromCookie());
    }

    const kioskChannel = pusher.subscribe(`${airportCode}-kiosks`);
    kioskChannel.bind('enrollment', ({ message, content }) => {
      if (message === 'remove-enrollment' && content) {
        revalidate();
      }
    });
    kioskChannel.bind('authorize', ({ content }) => {
      if (content) {
        const { kioskUuid, isAuthorized, authToken, authorizedByUserUuid } = { ...JSON.parse(content) };
        if (kioskUuid === getUuidFromCookie()) {
          if (isAuthorized) {
            logIntoAirBadge({ authToken, authorizedByUserUuid });
          } else {
            handleError({ message: 'Invalid code entered. Try again.' });
            updateSignInButton({ isDisabled: false, label: 'Sign In' });
          }
        }
      }
    });

    setPushNotificationsClient(pusher);

    return pusher;
  };

  useEffect(() => {
    if (!appState.airport) return;
    const code = generateRandomNumber();
    setCode(code);
    const pushNotificationsClient = initializePushNotifications({
      airportCode: appState.airport?.abbreviation?.toLocaleUpperCase(),
      authCode: code,
    });
    revalidate();
    startActivityMonitor({ pushNotificationsClient });

    return () => {
      const airportCode = appState.airport?.abbreviation?.toLocaleUpperCase();
      pushNotificationsClient.unsubscribe(`${airportCode}-kiosks`);
      setPushNotificationsClient(null);
    };
  }, [appState.airport]);

  if (!appState.airport) return null;

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col>
            <div className="m-auto" style={{ maxWidth: '500px' }}>
              <div className="text-center py-4">
                <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
                <Card>
                  <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h1 className="text-center mb-4">{name} Kiosk</h1>
                    {!hasValidCookie() ? (
                      <>
                        <Alert color="warning" style={{ fontSize: '1.2em', width: '100%' }}>
                          This kiosk is not yet enrolled. <br /> Use the code below to register the device.
                        </Alert>
                        <Alert
                          color="info"
                          style={{ width: '100%', textAlign: 'center', fontSize: '2em', letterSpacing: 3 }}
                        >
                          {code}
                        </Alert>
                      </>
                    ) : (
                      <div>
                        <div style={{ fontSize: 18, marginBottom: 16, opacity: 0.7 }}>
                          Ask a badge office staff member to provide you a code
                        </div>
                        <FormGroup style={{ textAlign: 'left' }}>
                          <Label>Enter the code below</Label>
                          <Input type="text" value={loginCode} onChange={event => setLoginCode(event.target.value)} />
                        </FormGroup>
                        <Button
                          color="primary"
                          style={{ width: 160 }}
                          onClick={onSignInClicked}
                          id="signInButton"
                          disabled={!loginCode}
                        >
                          Sign In
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="text-center">
              <p>AirBadge is provided by</p>
              <p>{appState.airport?.name}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
