import React, { useContext, useEffect, useState } from 'react';
import { BadgeApplicationContext } from '../BadgeApplicationsList/BadgeApplicationModal';
import DrivingEndorsementPicker from './components/DrivingEndorsementPicker';
import EscortEndorsementPicker from './components/EscortEndorsementPicker';

export default function BadgeApplicationEndorsementsPicker({ shouldSkipDefaultSelection }) {
  const [drivingEndorsementConfig, setDrivingEndorsementConfig] = useState(null);
  const { applicationData, setApplicationData } = useContext(BadgeApplicationContext);
  const [escortEndorsementConfig, setEscortEndorsementConfig] = useState(null);
  const [drivingSelection, setDrivingSelection] = useState(null);
  const [escortSelection, setEscortSelection] = useState(null);

  useEffect(() => {
    setApplicationData({ ...applicationData, driving: drivingSelection, escort: escortSelection });
  }, [drivingSelection, escortSelection]);

  useEffect(() => {
    setDrivingEndorsementConfig(
      applicationData.badgeType?.config?.endorsements?.driving ||
        applicationData.badgeType?.original?.config?.endorsements?.driving ||
        null
    );
    setEscortEndorsementConfig(
      applicationData.badgeType?.config?.endorsements?.escort ||
        applicationData.badgeType?.original?.config?.endorsements?.escort ||
        null
    );
    setDrivingSelection(applicationData.driving);
    setEscortSelection(applicationData.escort);
  }, [applicationData.badgeType]);

  return (
    <>
      <DrivingEndorsementPicker
        context={{
          drivingEndorsementConfig,
          drivingSelection,
          setDrivingSelection,
          escortEndorsementConfig,
          escortSelection,
          setEscortSelection,
          shouldSkipDefaultSelection,
          data: applicationData,
        }}
      />
      <EscortEndorsementPicker
        context={{
          drivingEndorsementConfig,
          drivingSelection,
          setDrivingSelection,
          escortEndorsementConfig,
          escortSelection,
          setEscortSelection,
          shouldSkipDefaultSelection,
          data: applicationData,
        }}
      />
    </>
  );
}
