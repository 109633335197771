import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';
import Loading from '../../../containers/DefaultLayout/Loading';
import Message from '../../../containers/DefaultLayout/Message';
import EnrollmentRefuseRequest from '../../../ajax/Enrollment/EnrollmentRefuseRequest';
import InputMask from 'react-input-mask';
import EnrollmentValidateNumberRequest from '../../../ajax/Enrollment/EnrollmentValidateNumberRequest';
import EnrollmentVerifyNumberRequest from '../../../ajax/Enrollment/EnrollmentVerifyNumberRequest';
import { sanitizePhoneNumber } from '../../../utils';
import { appSettings } from '../../../App';

export default class Enrollment extends Component {
  state = {
    accept: false,
    refuse: false,
    number: '',
    verificationCode: '',
    redirectToHomePage: false,
    isNumberValid: false,
    verificationAttempts: 0,
  };

  onAcceptClick() {
    this.setState({
      accept: true,
      isNumberValid: false,
      verificationAttempts: 0,
      refuse: false,
    });
  }

  onRefuseClick() {
    this.setState({
      accept: false,
      isNumberValid: false,
      verificationAttempts: 0,
      refuse: true,
    });
  }

  onRefuseBackClick() {
    this.setState({
      accept: false,
      isNumberValid: false,
      verificationAttempts: 0,
      refuse: false,
    });
  }

  onRefuseConfirmClick() {
    this.props.api.toggleLoading(true);

    EnrollmentRefuseRequest(this.props.state.user)
      .then(() => {
        this.props.api.toggleLoading(false);

        this.setState({
          redirectToHomePage: true,
        });
      })
      .catch(() => {
        this.props.api.toggleLoading(false);
        this.props.api.displayMessage('Could not store reply, please try again.', 'warning');
      });
  }

  onNumberChange(event) {
    this.setState({
      number: sanitizePhoneNumber(event.target.value),
    });
  }

  onVerificationCodeChange(event) {
    this.setState({
      verificationCode: event.target.value,
    });
  }

  onAcceptBackClick() {
    this.setState({
      accept: false,
      isNumberValid: false,
      verificationAttempts: 0,
      refuse: false,
      number: '',
    });
  }

  attemptsExceeded() {
    this.props.api.displayMessage(
      'Number of daily attempts exceeded. Try again tomorrow or contact the badge office for support.',
      'warning'
    );

    this.setState({
      redirectToHomePage: true,
    });
  }

  numberGotVerified() {
    this.props.api.displayMessage('Your number is confirmed in AirBadge.', 'success');

    const updatedUser = {
      ...this.props.state.user,
      mobile: this.state.number,
    };
    this.props.api.updateState({ user: updatedUser });
    localStorage.setItem('AirportBadges.user', JSON.stringify(updatedUser));

    this.setState({
      redirectToHomePage: true,
    });
  }

  onAcceptConfirmClick() {
    this.props.api.toggleLoading(true);

    EnrollmentValidateNumberRequest(this.props.state.user, this.state.number)
      .then(response => {
        if (response.attempts_exceeded) {
          this.attemptsExceeded();
          return;
        }

        if (!response.is_valid) {
          this.props.api.displayMessage('The mobile number you provided is not valid.', 'warning');
        }

        this.setState({ isNumberValid: response.is_valid });
      })
      .catch(() => {
        this.props.api.displayMessage('Could not validate number, please try again.', 'warning');
      })
      .finally(() => {
        this.props.api.toggleLoading(false);
      });
  }

  onStartOverClick() {
    this.setState({
      accept: true,
      isNumberValid: false,
      verificationAttempts: 0,
      refuse: false,
    });
  }

  onAcceptVerifyBackClick() {
    this.setState({
      accept: true,
      isNumberValid: false,
      verificationAttempts: 0,
      refuse: false,
      verificationCode: '',
    });
  }

  onAcceptVerifyClick() {
    if (this.state.verificationAttempts >= 3) {
      return;
    }

    this.setState({
      verificationAttempts: this.state.verificationAttempts + 1,
    });

    this.props.api.toggleLoading(true);

    const number = this.state.number.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

    const verificationCode = this.state.verificationCode.trim(' ');

    EnrollmentVerifyNumberRequest(this.props.state.user, number, verificationCode)
      .then(response => {
        if (response.is_verified) {
          this.numberGotVerified();
        } else {
          this.props.api.displayMessage('Invalid code, please try again.', 'warning');

          this.setState({
            verificationCode: '',
          });
        }
      })
      .catch(() => {
        this.props.api.displayMessage('Could not verified number, please try again.', 'warning');
      })
      .finally(() => {
        this.props.api.toggleLoading(false);
      });
  }

  render() {
    if (!this.props.state.user.authToken) {
      return <Redirect to={'/login'} />;
    }

    if (this.state.redirectToHomePage) {
      return <Redirect to={'/default/home'} />;
    }

    const { airport } = this.props.state;

    return (
      <div className={'app flex-row align-items-center'}>
        <Loading loading={this.props.state.loading} />
        <Message message={this.props.state.messageContent} brand={this.props.state.messageBrand} />
        <Container>
          <Row className={'justify-content-center'}>
            <Col>
              <div className={'m-auto'} style={{ maxWidth: '500px' }}>
                <div className={'text-center py-4'}>
                  <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
                </div>
                <Card>
                  <CardBody>
                    <h2 className="mt-2 mb-3 text-center">Mobile Number Enrollment</h2>
                    <div hidden={this.state.accept || this.state.refuse}>
                      <p>
                        By providing your mobile number you are consenting to receive text messages from your airport
                        via AirBadge. You represent that you have the authority to agree to receive text messages on the
                        mobile phone number that you provide. Message frequency depends upon activity. Text messaging is
                        not available in all areas. Not all mobile devices or handsets may be supported. AirBadge and
                        your mobile carrier are not liable for delayed or undelivered messages. Text message fees and
                        data rates may apply from your carrier.
                      </p>

                      <Row style={{ marginTop: 32 }}>
                        <Col style={{ textAlign: 'center' }}>
                          <Button style={{ width: 120 }} color="danger" onClick={this.onRefuseClick.bind(this)}>
                            Refuse
                          </Button>
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                          <Button style={{ width: 120 }} color="primary" onClick={this.onAcceptClick.bind(this)}>
                            Accept
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div hidden={!this.state.accept || this.state.isNumberValid}>
                      <FormGroup>
                        <Label>Enter Your Mobile Phone Number:</Label>

                        <InputMask
                          mask="+1 (999) 999-9999"
                          className={'form-control'}
                          onChange={this.onNumberChange.bind(this)}
                          value={this.state.number}
                        />
                      </FormGroup>

                      <Row style={{ marginTop: 32 }}>
                        <Col>
                          <Button color={'secondary'} block onClick={this.onAcceptBackClick.bind(this)}>
                            Back
                          </Button>
                        </Col>
                        <Col>
                          <Button color={'primary'} block onClick={this.onAcceptConfirmClick.bind(this)}>
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div hidden={!this.state.refuse}>
                      <p>
                        You selected the option to refuse enrolling your mobile number. If you have already enrolled
                        your number it will be removed. Please be aware that TSA requires two factor authentication for
                        logins. If you refuse to enroll your mobile number you may become unable to login to AirBadge.
                        If you are not able to log into AirBadge, badging activities may need to be done manually or in
                        person at the airport. Your airport may use text messages to communicate important information
                        with you. If you refuse to receive these messages you will not receive them.
                      </p>

                      <Row style={{ marginTop: 32 }}>
                        <Col>
                          <Button color={'secondary'} block onClick={this.onRefuseBackClick.bind(this)}>
                            Back
                          </Button>
                        </Col>
                        <Col>
                          <Button color={'danger'} block onClick={this.onRefuseConfirmClick.bind(this)}>
                            Confirm
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div hidden={!this.state.accept || !this.state.isNumberValid}>
                      <div hidden={this.state.verificationAttempts >= 3} style={{ marginBottom: 8 }}>
                        <span>A verification code was sent to</span>
                        <InputMask
                          style={{ width: 140, height: 28, padding: '0 5px', margin: '5px 8px' }}
                          disabled
                          readOnly
                          mask="+1 (999) 999-9999"
                          value={this.state.number}
                        />
                        <span>via text message. Enter your code as soon as you receive it.</span>
                      </div>

                      <p hidden={this.state.verificationAttempts < 3}>
                        The verification code you entered did not match the code that was sent to you. Please start the
                        process again.
                      </p>

                      <FormGroup hidden={this.state.verificationAttempts >= 3}>
                        <InputMask
                          mask="99999"
                          className={'form-control'}
                          onChange={this.onVerificationCodeChange.bind(this)}
                          value={this.state.verificationCode}
                        />
                      </FormGroup>

                      <Row style={{ marginTop: 32 }}>
                        <Col>
                          <Button color={'secondary'} block onClick={this.onAcceptVerifyBackClick.bind(this)}>
                            Back
                          </Button>
                        </Col>
                        <Col>
                          {this.state.verificationAttempts >= 3 ? (
                            <Button
                              color={'primary'}
                              block
                              className={'mt-0'}
                              onClick={this.onStartOverClick.bind(this)}
                            >
                              Start Over
                            </Button>
                          ) : (
                            <Button color={'primary'} block onClick={this.onAcceptVerifyClick.bind(this)}>
                              Confirm
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <div className="text-center">
                  <p>AirBadge is Provided by</p>
                  <p>{airport && airport.name}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
