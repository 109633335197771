import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function VisaDocumentQuestion({ isHidden, value, data, onChange, onNext }) {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isNextHidden, setIsNextHidden] = useState(true);

  if (isHidden) return null;

  return (
    <>
      <BlockFormLabel>
        <Typography variant="h6">{translate('visaDocument.question1.label')}</Typography>
      </BlockFormLabel>

      <FormControl>
        <RadioGroup
          aria-label="visa document"
          name="visaDocument"
          value={value}
          onChange={({ target }) => {
            onChange(target.value);
            setIsNextHidden(!target.value);
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            label={translate('visaDocument.question1.answer1')}
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label={translate('visaDocument.question1.answer2')}
          />
        </RadioGroup>
      </FormControl>

      <NextButton isDisabled={isNextHidden} onClick={onNext} />
    </>
  );
}
