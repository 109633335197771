import React, { useContext, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../shared/components/AirBadgeModal/AirBadgeModal';
import FileUploadForm from '../../../shared/components/FileUploadModal/FileUploadForm';
import InputWithCharacterLimit from '../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import { handleError } from '../../../utils';
import { AppContext } from '../../../App';
import violationsApi from '../../../ajax/Violations/violationsApi';

export default function UploadViolationFileModal({ isOpen, violation, onClose }) {
  const app = useContext(AppContext);
  const [files, setFiles] = useState([]);
  const [notes, setNotes] = useState('');

  const reset = () => {
    setFiles([]);
    setNotes('');
  };

  const onModalClose = () => {
    reset();
    onClose({ shouldReload: false });
  };

  const doUpload = () => {
    app.api.toggleLoading(true);

    if (files && files.length) {
      const file = files[0];
      violationsApi
        .uploadFile({ violationUuid: violation.uuid, file, notes })
        .then(success => {
          if (success) {
            reset();
            onClose({ shouldReload: true });
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }
  };

  const addFiles = files => {
    setFiles(files);
  };

  if (!isOpen || !violation) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Upload Violation File"
      isOpen={isOpen}
      onClose={onModalClose}
      closeLabel="Cancel"
      onSave={doUpload}
      saveLabel="Upload File"
      saveDisabled={!files?.length}
    >
      <FileUploadForm onFilesAdded={addFiles} hideTSCWarning={true} />

      <FormGroup className="mt-5">
        <Label>
          Notes <i style={{ fontSize: 'smaller' }}>(optional)</i>
        </Label>
        <InputWithCharacterLimit type="textarea" value={notes} onChange={setNotes} />
      </FormGroup>
    </AirBadgeModal>
  );
}
