const countries = [
  {
    label: 'United States of America',
    twoCharacterCode: 'US',
    value: 'USA',
  },
  { label: 'Afghanistan', twoCharacterCode: 'AF', value: 'AFG' },
  { label: 'Åland Islands', twoCharacterCode: 'AX', value: 'ALA' },
  { label: 'Albania', twoCharacterCode: 'AL', value: 'ALB' },
  { label: 'Algeria', twoCharacterCode: 'DZ', value: 'DZA' },
  { label: 'American Samoa', twoCharacterCode: 'AS', value: 'ASM' },
  { label: 'Andorra', twoCharacterCode: 'AD', value: 'AND' },
  { label: 'Angola', twoCharacterCode: 'AO', value: 'AGO' },
  { label: 'Anguilla', twoCharacterCode: 'AI', value: 'AIA' },
  { label: 'Antarctica', twoCharacterCode: 'AQ', value: 'ATA' },
  { label: 'Antigua and Barbuda', twoCharacterCode: 'AG', value: 'ATG' },
  { label: 'Argentina', twoCharacterCode: 'AR', value: 'ARG' },
  { label: 'Armenia', twoCharacterCode: 'AM', value: 'ARM' },
  { label: 'Aruba', twoCharacterCode: 'AW', value: 'ABW' },
  { label: 'Australia', twoCharacterCode: 'AU', value: 'AUS' },
  { label: 'Austria', twoCharacterCode: 'AT', value: 'AUT' },
  { label: 'Azerbaijan', twoCharacterCode: 'AZ', value: 'AZE' },
  { label: 'Bahamas (the)', twoCharacterCode: 'BS', value: 'BHS' },
  { label: 'Bahrain', twoCharacterCode: 'BH', value: 'BHR' },
  { label: 'Bangladesh', twoCharacterCode: 'BD', value: 'BGD' },
  { label: 'Barbados', twoCharacterCode: 'BB', value: 'BRB' },
  { label: 'Belarus', twoCharacterCode: 'BY', value: 'BLR' },
  { label: 'Belgium', twoCharacterCode: 'BE', value: 'BEL' },
  { label: 'Belize', twoCharacterCode: 'BZ', value: 'BLZ' },
  { label: 'Benin', twoCharacterCode: 'BJ', value: 'BEN' },
  { label: 'Bermuda', twoCharacterCode: 'BM', value: 'BMU' },
  { label: 'Bhutan', twoCharacterCode: 'BT', value: 'BTN' },
  {
    label: 'Bolivia (Plurinational State of)',
    twoCharacterCode: 'BO',
    value: 'BOL',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    twoCharacterCode: 'BQ',
    value: 'BES',
  },
  { label: 'Bosnia and Herzegovina', twoCharacterCode: 'BA', value: 'BIH' },
  { label: 'Botswana', twoCharacterCode: 'BW', value: 'BWA' },
  { label: 'Bouvet Island', twoCharacterCode: 'BV', value: 'BVT' },
  { label: 'Brazil', twoCharacterCode: 'BR', value: 'BRA' },
  {
    label: 'British Indian Ocean Territory (the)',
    twoCharacterCode: 'IO',
    value: 'IOT',
  },
  { label: 'Brunei Darussalam', twoCharacterCode: 'BN', value: 'BRN' },
  { label: 'Bulgaria', twoCharacterCode: 'BG', value: 'BGR' },
  { label: 'Burkina Faso', twoCharacterCode: 'BF', value: 'BFA' },
  { label: 'Burundi', twoCharacterCode: 'BI', value: 'BDI' },
  { label: 'Cabo Verde', twoCharacterCode: 'CV', value: 'CPV' },
  { label: 'Cambodia', twoCharacterCode: 'KH', value: 'KHM' },
  { label: 'Cameroon', twoCharacterCode: 'CM', value: 'CMR' },
  { label: 'Canada', twoCharacterCode: 'CA', value: 'CAN' },
  { label: 'Cayman Islands (the)', twoCharacterCode: 'KY', value: 'CYM' },
  {
    label: 'Central African Republic (the)',
    twoCharacterCode: 'CF',
    value: 'CAF',
  },
  { label: 'Chad', twoCharacterCode: 'TD', value: 'TCD' },
  { label: 'Chile', twoCharacterCode: 'CL', value: 'CHL' },
  { label: 'China', twoCharacterCode: 'CN', value: 'CHN' },
  { label: 'Christmas Island', twoCharacterCode: 'CX', value: 'CXR' },
  {
    label: 'Cocos (Keeling) Islands (the)',
    twoCharacterCode: 'CC',
    value: 'CCK',
  },
  { label: 'Colombia', twoCharacterCode: 'CO', value: 'COL' },
  { label: 'Comoros (the)', twoCharacterCode: 'KM', value: 'COM' },
  {
    label: 'Congo (the Democratic Republic of the)',
    twoCharacterCode: 'CD',
    value: 'COD',
  },
  { label: 'Congo (the)', twoCharacterCode: 'CG', value: 'COG' },
  { label: 'Cook Islands (the)', twoCharacterCode: 'CK', value: 'COK' },
  { label: 'Costa Rica', twoCharacterCode: 'CR', value: 'CRI' },
  { label: "Côte d'Ivoire", twoCharacterCode: 'CI', value: 'CIV' },
  { label: 'Croatia', twoCharacterCode: 'HR', value: 'HRV' },
  { label: 'Cuba', twoCharacterCode: 'CU', value: 'CUB' },
  { label: 'Curaçao', twoCharacterCode: 'CW', value: 'CUW' },
  { label: 'Cyprus', twoCharacterCode: 'CY', value: 'CYP' },
  { label: 'Czechia', twoCharacterCode: 'CZ', value: 'CZE' },
  { label: 'Denmark', twoCharacterCode: 'DK', value: 'DNK' },
  { label: 'Djibouti', twoCharacterCode: 'DJ', value: 'DJI' },
  { label: 'Dominica', twoCharacterCode: 'DM', value: 'DMA' },
  { label: 'Dominican Republic (the)', twoCharacterCode: 'DO', value: 'DOM' },
  { label: 'Ecuador', twoCharacterCode: 'EC', value: 'ECU' },
  { label: 'Egypt', twoCharacterCode: 'EG', value: 'EGY' },
  { label: 'El Salvador', twoCharacterCode: 'SV', value: 'SLV' },
  { label: 'Equatorial Guinea', twoCharacterCode: 'GQ', value: 'GNQ' },
  { label: 'Eritrea', twoCharacterCode: 'ER', value: 'ERI' },
  { label: 'Estonia', twoCharacterCode: 'EE', value: 'EST' },
  { label: 'Eswatini', twoCharacterCode: 'SZ', value: 'SWZ' },
  { label: 'Ethiopia', twoCharacterCode: 'ET', value: 'ETH' },
  {
    label: 'Falkland Islands (the) [Malvinas]',
    twoCharacterCode: 'FK',
    value: 'FLK',
  },
  { label: 'Faroe Islands (the)', twoCharacterCode: 'FO', value: 'FRO' },
  { label: 'Fiji', twoCharacterCode: 'FJ', value: 'FJI' },
  { label: 'Finland', twoCharacterCode: 'FI', value: 'FIN' },
  { label: 'France', twoCharacterCode: 'FR', value: 'FRA' },
  { label: 'French Guiana', twoCharacterCode: 'GF', value: 'GUF' },
  { label: 'French Polynesia', twoCharacterCode: 'PF', value: 'PYF' },
  {
    label: 'French Southern Territories (the)',
    twoCharacterCode: 'TF',
    value: 'ATF',
  },
  { label: 'Gabon', twoCharacterCode: 'GA', value: 'GAB' },
  { label: 'Gambia (the)', twoCharacterCode: 'GM', value: 'GMB' },
  { label: 'Georgia', twoCharacterCode: 'GE', value: 'GEO' },
  { label: 'Germany', twoCharacterCode: 'DE', value: 'DEU' },
  { label: 'Ghana', twoCharacterCode: 'GH', value: 'GHA' },
  { label: 'Gibraltar', twoCharacterCode: 'GI', value: 'GIB' },
  { label: 'Greece', twoCharacterCode: 'GR', value: 'GRC' },
  { label: 'Greenland', twoCharacterCode: 'GL', value: 'GRL' },
  { label: 'Grenada', twoCharacterCode: 'GD', value: 'GRD' },
  { label: 'Guadeloupe', twoCharacterCode: 'GP', value: 'GLP' },
  { label: 'Guam', twoCharacterCode: 'GU', value: 'GUM' },
  { label: 'Guatemala', twoCharacterCode: 'GT', value: 'GTM' },
  { label: 'Guernsey', twoCharacterCode: 'GG', value: 'GGY' },
  { label: 'Guinea', twoCharacterCode: 'GN', value: 'GIN' },
  { label: 'Guinea-Bissau', twoCharacterCode: 'GW', value: 'GNB' },
  { label: 'Guyana', twoCharacterCode: 'GY', value: 'GUY' },
  { label: 'Haiti', twoCharacterCode: 'HT', value: 'HTI' },
  {
    label: 'Heard Island and McDonald Islands',
    twoCharacterCode: 'HM',
    value: 'HMD',
  },
  { label: 'Holy See (the)', twoCharacterCode: 'VA', value: 'VAT' },
  { label: 'Honduras', twoCharacterCode: 'HN', value: 'HND' },
  { label: 'Hong Kong', twoCharacterCode: 'HK', value: 'HKG' },
  { label: 'Hungary', twoCharacterCode: 'HU', value: 'HUN' },
  { label: 'Iceland', twoCharacterCode: 'IS', value: 'ISL' },
  { label: 'India', twoCharacterCode: 'IN', value: 'IND' },
  { label: 'Indonesia', twoCharacterCode: 'ID', value: 'IDN' },
  { label: 'Iran (Islamic Republic of)', twoCharacterCode: 'IR', value: 'IRN' },
  { label: 'Iraq', twoCharacterCode: 'IQ', value: 'IRQ' },
  { label: 'Ireland', twoCharacterCode: 'IE', value: 'IRL' },
  { label: 'Isle of Man', twoCharacterCode: 'IM', value: 'IMN' },
  { label: 'Israel', twoCharacterCode: 'IL', value: 'ISR' },
  { label: 'Italy', twoCharacterCode: 'IT', value: 'ITA' },
  { label: 'Jamaica', twoCharacterCode: 'JM', value: 'JAM' },
  { label: 'Japan', twoCharacterCode: 'JP', value: 'JPN' },
  { label: 'Jersey', twoCharacterCode: 'JE', value: 'JEY' },
  { label: 'Jordan', twoCharacterCode: 'JO', value: 'JOR' },
  { label: 'Kazakhstan', twoCharacterCode: 'KZ', value: 'KAZ' },
  { label: 'Kenya', twoCharacterCode: 'KE', value: 'KEN' },
  { label: 'Kiribati', twoCharacterCode: 'KI', value: 'KIR' },
  {
    label: "Korea (the Democratic People's Republic of)",
    twoCharacterCode: 'KP',
    value: 'PRK',
  },
  { label: 'Korea (the Republic of)', twoCharacterCode: 'KR', value: 'KOR' },
  { label: 'Kuwait', twoCharacterCode: 'KW', value: 'KWT' },
  { label: 'Kyrgyzstan', twoCharacterCode: 'KG', value: 'KGZ' },
  {
    label: "Lao People's Democratic Republic (the)",
    twoCharacterCode: 'LA',
    value: 'LAO',
  },
  { label: 'Latvia', twoCharacterCode: 'LV', value: 'LVA' },
  { label: 'Lebanon', twoCharacterCode: 'LB', value: 'LBN' },
  { label: 'Lesotho', twoCharacterCode: 'LS', value: 'LSO' },
  { label: 'Liberia', twoCharacterCode: 'LR', value: 'LBR' },
  { label: 'Libya', twoCharacterCode: 'LY', value: 'LBY' },
  { label: 'Liechtenstein', twoCharacterCode: 'LI', value: 'LIE' },
  { label: 'Lithuania', twoCharacterCode: 'LT', value: 'LTU' },
  { label: 'Luxembourg', twoCharacterCode: 'LU', value: 'LUX' },
  { label: 'Macao', twoCharacterCode: 'MO', value: 'MAC' },
  {
    label: 'Republic of North Macedonia',
    twoCharacterCode: 'MK',
    value: 'MKD',
  },
  { label: 'Madagascar', twoCharacterCode: 'MG', value: 'MDG' },
  { label: 'Malawi', twoCharacterCode: 'MW', value: 'MWI' },
  { label: 'Malaysia', twoCharacterCode: 'MY', value: 'MYS' },
  { label: 'Maldives', twoCharacterCode: 'MV', value: 'MDV' },
  { label: 'Mali', twoCharacterCode: 'ML', value: 'MLI' },
  { label: 'Malta', twoCharacterCode: 'MT', value: 'MLT' },
  { label: 'Marshall Islands (the)', twoCharacterCode: 'MH', value: 'MHL' },
  { label: 'Martinique', twoCharacterCode: 'MQ', value: 'MTQ' },
  { label: 'Mauritania', twoCharacterCode: 'MR', value: 'MRT' },
  { label: 'Mauritius', twoCharacterCode: 'MU', value: 'MUS' },
  { label: 'Mayotte', twoCharacterCode: 'YT', value: 'MYT' },
  { label: 'Mexico', twoCharacterCode: 'MX', value: 'MEX' },
  {
    label: 'Micronesia (Federated States of)',
    twoCharacterCode: 'FM',
    value: 'FSM',
  },
  { label: 'Moldova (the Republic of)', twoCharacterCode: 'MD', value: 'MDA' },
  { label: 'Monaco', twoCharacterCode: 'MC', value: 'MCO' },
  { label: 'Mongolia', twoCharacterCode: 'MN', value: 'MNG' },
  { label: 'Montenegro', twoCharacterCode: 'ME', value: 'MNE' },
  { label: 'Montserrat', twoCharacterCode: 'MS', value: 'MSR' },
  { label: 'Morocco', twoCharacterCode: 'MA', value: 'MAR' },
  { label: 'Mozambique', twoCharacterCode: 'MZ', value: 'MOZ' },
  { label: 'Myanmar', twoCharacterCode: 'MM', value: 'MMR' },
  { label: 'Namibia', twoCharacterCode: 'NA', value: 'NAM' },
  { label: 'Nauru', twoCharacterCode: 'NR', value: 'NRU' },
  { label: 'Nepal', twoCharacterCode: 'NP', value: 'NPL' },
  { label: 'Netherlands (the)', twoCharacterCode: 'NL', value: 'NLD' },
  { label: 'New Caledonia', twoCharacterCode: 'NC', value: 'NCL' },
  { label: 'New Zealand', twoCharacterCode: 'NZ', value: 'NZL' },
  { label: 'Nicaragua', twoCharacterCode: 'NI', value: 'NIC' },
  { label: 'Niger (the)', twoCharacterCode: 'NE', value: 'NER' },
  { label: 'Nigeria', twoCharacterCode: 'NG', value: 'NGA' },
  { label: 'Niue', twoCharacterCode: 'NU', value: 'NIU' },
  { label: 'Norfolk Island', twoCharacterCode: 'NF', value: 'NFK' },
  {
    label: 'Northern Mariana Islands (the)',
    twoCharacterCode: 'MP',
    value: 'MNP',
  },
  { label: 'Norway', twoCharacterCode: 'NO', value: 'NOR' },
  { label: 'Oman', twoCharacterCode: 'OM', value: 'OMN' },
  { label: 'Pakistan', twoCharacterCode: 'PK', value: 'PAK' },
  { label: 'Palau', twoCharacterCode: 'PW', value: 'PLW' },
  { label: 'Palestine, State of', twoCharacterCode: 'PS', value: 'PSE' },
  { label: 'Panama', twoCharacterCode: 'PA', value: 'PAN' },
  { label: 'Papua New Guinea', twoCharacterCode: 'PG', value: 'PNG' },
  { label: 'Paraguay', twoCharacterCode: 'PY', value: 'PRY' },
  { label: 'Peru', twoCharacterCode: 'PE', value: 'PER' },
  { label: 'Philippines (the)', twoCharacterCode: 'PH', value: 'PHL' },
  { label: 'Pitcairn', twoCharacterCode: 'PN', value: 'PCN' },
  { label: 'Poland', twoCharacterCode: 'PL', value: 'POL' },
  { label: 'Portugal', twoCharacterCode: 'PT', value: 'PRT' },
  { label: 'Puerto Rico', twoCharacterCode: 'PR', value: 'PRI' },
  { label: 'Qatar', twoCharacterCode: 'QA', value: 'QAT' },
  { label: 'Réunion', twoCharacterCode: 'RE', value: 'REU' },
  { label: 'Romania', twoCharacterCode: 'RU', value: 'ROU' },
  { label: 'Russian Federation (the)', twoCharacterCode: 'RU', value: 'RUS' },
  { label: 'Rwanda', twoCharacterCode: 'RW', value: 'RWA' },
  { label: 'Saint Barthélemy', twoCharacterCode: 'BL', value: 'BLM' },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    twoCharacterCode: 'SH',
    value: 'SHN',
  },
  { label: 'Saint Kitts and Nevis', twoCharacterCode: 'KN', value: 'KNA' },
  { label: 'Saint Lucia', twoCharacterCode: 'LC', value: 'LCA' },
  { label: 'Saint Martin (French part)', twoCharacterCode: 'MF', value: 'MAF' },
  { label: 'Saint Pierre and Miquelon', twoCharacterCode: 'PM', value: 'SPM' },
  {
    label: 'Saint Vincent and the Grenadines',
    twoCharacterCode: 'VC',
    value: 'VCT',
  },
  { label: 'Samoa', twoCharacterCode: 'WS', value: 'WSM' },
  { label: 'San Marino', twoCharacterCode: 'SM', value: 'SMR' },
  { label: 'Sao Tome and Principe', twoCharacterCode: 'ST', value: 'STP' },
  { label: 'Saudi Arabia', twoCharacterCode: 'SA', value: 'SAU' },
  { label: 'Senegal', twoCharacterCode: 'SN', value: 'SEN' },
  { label: 'Serbia', twoCharacterCode: 'RS', value: 'SRB' },
  { label: 'Seychelles', twoCharacterCode: 'SC', value: 'SYC' },
  { label: 'Sierra Leone', twoCharacterCode: 'SL', value: 'SLE' },
  { label: 'Singapore', twoCharacterCode: 'SG', value: 'SGP' },
  { label: 'Sint Maarten (Dutch part)', twoCharacterCode: 'SX', value: 'SXM' },
  { label: 'Slovakia', twoCharacterCode: 'SK', value: 'SVK' },
  { label: 'Slovenia', twoCharacterCode: 'SI', value: 'SVN' },
  { label: 'Solomon Islands', twoCharacterCode: 'SB', value: 'SLB' },
  { label: 'Somalia', twoCharacterCode: 'SO', value: 'SOM' },
  { label: 'South Africa', twoCharacterCode: 'ZA', value: 'ZAF' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    twoCharacterCode: 'GS',
    value: 'SGS',
  },
  { label: 'South Sudan', twoCharacterCode: 'SS', value: 'SSD' },
  { label: 'Spain', twoCharacterCode: 'ES', value: 'ESP' },
  { label: 'Sri Lanka', twoCharacterCode: 'LK', value: 'LKA' },
  { label: 'Sudan (the)', twoCharacterCode: 'SD', value: 'SDN' },
  { label: 'Suriname', twoCharacterCode: 'SR', value: 'SUR' },
  { label: 'Svalbard and Jan Mayen', twoCharacterCode: 'SJ', value: 'SJM' },
  { label: 'Sweden', twoCharacterCode: 'SE', value: 'SWE' },
  { label: 'Switzerland', twoCharacterCode: 'CH', value: 'CHE' },
  { label: 'Syrian Arab Republic', twoCharacterCode: 'SY', value: 'SYR' },
  { label: 'Taiwan (Province of China)', twoCharacterCode: 'TW', value: 'TWN' },
  { label: 'Tajikistan', twoCharacterCode: 'TJ', value: 'TJK' },
  {
    label: 'Tanzania, United Republic of',
    twoCharacterCode: 'TZ',
    value: 'TZA',
  },
  { label: 'Thailand', twoCharacterCode: 'TH', value: 'THA' },
  { label: 'Timor-Leste', twoCharacterCode: 'TL', value: 'TLS' },
  { label: 'Togo', twoCharacterCode: 'TG', value: 'TGO' },
  { label: 'Tokelau', twoCharacterCode: 'TK', value: 'TKL' },
  { label: 'Tonga', twoCharacterCode: 'TO', value: 'TON' },
  { label: 'Trinidad and Tobago', twoCharacterCode: 'TT', value: 'TTO' },
  { label: 'Tunisia', twoCharacterCode: 'TN', value: 'TUN' },
  { label: 'Turkey', twoCharacterCode: 'TR', value: 'TUR' },
  { label: 'Turkmenistan', twoCharacterCode: 'TM', value: 'TKM' },
  {
    label: 'Turks and Caicos Islands (the)',
    twoCharacterCode: 'TC',
    value: 'TCA',
  },
  { label: 'Tuvalu', twoCharacterCode: 'TV', value: 'TUV' },
  { label: 'Uganda', twoCharacterCode: 'UG', value: 'UGA' },
  { label: 'Ukraine', twoCharacterCode: 'UA', value: 'UKR' },
  { label: 'United Arab Emirates (the)', twoCharacterCode: 'AE', value: 'ARE' },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland (the)',
    twoCharacterCode: 'GB',
    value: 'GBR',
  },
  {
    label: 'United States Minor Outlying Islands (the)',
    twoCharacterCode: 'UM',
    value: 'UMI',
  },
  { label: 'Uruguay', twoCharacterCode: 'UY', value: 'URY' },
  { label: 'Uzbekistan', twoCharacterCode: 'UZ', value: 'UZB' },
  { label: 'Vanuatu', twoCharacterCode: 'VU', value: 'VUT' },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    twoCharacterCode: 'VE',
    value: 'VEN',
  },
  { label: 'Viet Nam', twoCharacterCode: 'VN', value: 'VNM' },
  { label: 'Virgin Islands (British)', twoCharacterCode: 'VG', value: 'VGB' },
  { label: 'Virgin Islands (U.S.)', twoCharacterCode: 'VI', value: 'VIR' },
  { label: 'Wallis and Futuna', twoCharacterCode: 'WF', value: 'WLF' },
  { label: 'Western Sahara', twoCharacterCode: 'EH', value: 'ESH' },
  { label: 'Yemen', twoCharacterCode: 'YE', value: 'YEM' },
  { label: 'Zambia', twoCharacterCode: 'ZM', value: 'ZMB' },
  { label: 'Zimbabwe', twoCharacterCode: 'ZW', value: 'ZWE' },
];

export default countries;
