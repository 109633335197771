import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext, colors } from '../../../../../App';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { handleError, notify } from '../../../../../utils';
import request from '../../../../../ajax/Request';

export default function BadgeDetailsAdminActionsPanel({ badge, onActionDone }) {
  const app = useContext(AppContext);

  const onDissociateClicked = () => {
    app.api.toggleLoading(true);
    request('authenticated-user', 'POST', `badges/${badge.uuid}/dissociate-from-pacs`)
      .then(({ success }) => {
        if (!success) {
          return handleError({ message: 'Failed' });
        }
        notify({ message: 'PACS ID Removed' });
        onActionDone({ shouldReload: true });
      })
      .catch(error => handleError({ error, message: 'Failed' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onForceTscUpdateClicked = () => {
    app.api.toggleLoading(true);
    request('authenticated-user', 'POST', `badges/${badge.uuid}/update-tsc`)
      .then(({ success }) => {
        if (!success) {
          return handleError({ message: 'Failed' });
        }
        notify({ message: 'Update sent to TSC' });
        onActionDone({ shouldReload: true });
      })
      .catch(error => handleError({ error, message: 'Failed' }))
      .finally(() => app.api.toggleLoading(false));
  };

  if (!app?.user?.isSuperAdmin) return null;

  return (
    <div style={{ marginTop: 24 }}>
      <div className="separator" style={{ color: colors.danger, fontWeight: 'bold' }}>
        ADMIN ACTIONS
      </div>

      <style>{`
        .color-adjust {
          color: ${colors.danger}
        }
      `}</style>

      <Grid container>
        <Grid item sm={12} md={6}>
          <List>
            <ListItem button onClick={onForceTscUpdateClicked}>
              <ListItemIcon>
                <FontAwesomeIcon
                  className="fa-solid fa-bolt-lightning"
                  color={colors.danger}
                  style={{ fontSize: 18 }}
                />
              </ListItemIcon>
              <ListItemText primary="Force TSC Update" classes={{ root: 'color-adjust' }} />
            </ListItem>
          </List>
        </Grid>
        <Grid item sm={12} md={6}>
          <List>
            <ListItem button onClick={onDissociateClicked} disabled={!badge?.isAssociatedToPacs}>
              <ListItemIcon>
                <FontAwesomeIcon className="fa-solid fa-link-slash" color={colors.danger} style={{ fontSize: 18 }} />
              </ListItemIcon>
              <ListItemText primary="Dissociate From PACS" classes={{ root: 'color-adjust' }} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
