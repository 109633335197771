import React, { useContext, useEffect, useState } from 'react';
import { Button, Label } from 'reactstrap';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ActionPanelContext } from '../../../BadgeApplicationActionPanel';
import ReadOnlyFormData from '../../../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { TabActions, ValidateContext } from '../ValidateBadgeApplicationAction';
import { AppContext } from '../../../../../../../../App';
import BadgeApplicationTrainingEditor from '../../../../../../ApplicationAdministration/BadgeApplications/components/BadgeApplicationTrainingEditor';
import badgeApplicationApi from '../../../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { handleError } from '../../../../../../../../utils';
import cloneDeep from 'lodash/cloneDeep';
import badgeApplicationCommentApi from '../../../../../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';
import EscortEndorsementPicker from '../../../../../BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import DrivingEndorsementPicker from '../../../../../BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import { getDrivingOptions } from '../../../../../../../../shared/BadgeTypes/drivingOptionsUtils';

export default function ValidateBadge({ isOpen }) {
  const [driving, setDriving] = useState(null);
  const [isEscort, setIsEscort] = useState(null);
  const [isLeo, setIsLeo] = useState(null);
  const [isFirstResponder, setIsFirstResponder] = useState(null);
  const [auxiliaryConfig, setAuxiliaryConfig] = useState(null);
  const [isComplete, setIsComplete] = useState(false);

  const app = useContext(AppContext);
  const theme = useTheme();
  const { badgeApplication, updateBadgeApplicationConfig } = useContext(ActionPanelContext);
  const {
    setShouldReloadBadgeApplication,
    gotoTab,
    closeModal,
    onStepMarkedComplete,
    isBackgroundCheckAlreadyStarted,
  } = useContext(ValidateContext);

  const { doors, purpose, config } = badgeApplication;
  const { validation } = config;
  const { badgeValidation } = validation.meta;

  const onTrainingConfigChanged = ({ updatedConfig }) => {
    auxiliaryConfig.training = updatedConfig;
    setAuxiliaryConfig(cloneDeep(auxiliaryConfig));
    badgeApplicationApi
      .updateConfig({ badgeApplicationUuid: badgeApplication.uuid, auxiliaryConfig })
      .catch(error => handleError({ error }));
    setShouldReloadBadgeApplication(true);
  };

  const onMarkComplete = async () => {
    badgeValidation.isComplete = true;
    badgeValidation.driving = driving.value;
    badgeValidation.isEscort = isEscort;
    badgeValidation.isLeo = isLeo;
    badgeValidation.isFirstResponder = isFirstResponder;
    await updateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });

    if (isComplete) {
      // This step was previously marked complete - just go to the next tab
      return gotoTab(2);
    }

    onStepMarkedComplete(badgeApplication.config);
    badgeApplicationCommentApi.create({
      badgeApplicationUuid: badgeApplication.uuid,
      comment: 'Badge/Training validation complete',
    });
    gotoTab(2);
  };

  const isMarkCompleteDisabled = () => {
    return isEscort === null || isLeo === null || isFirstResponder === null || driving === null;
  };

  useEffect(() => {
    if (isOpen) {
      setAuxiliaryConfig(badgeApplication.auxiliaryConfig);
      setShouldReloadBadgeApplication(false);

      const driving = badgeValidation?.driving || badgeApplication?.driving || badgeApplication?.badge?.driving || null;
      const isEscort =
        badgeValidation?.isEscort || badgeApplication?.isEscort || badgeApplication?.badge?.isEscort || null;
      const isLeo = badgeValidation?.isLeo || badgeApplication?.badge?.isLEO || null;
      const isFirstResponder = badgeValidation?.isFirstResponder || badgeApplication?.applicant?.firstResponder || null;

      const drivingOptions = getDrivingOptions(badgeApplication?.badgeType?.config?.endorsements?.driving);
      setDriving(drivingOptions.find(x => x.value === driving));

      setIsEscort(!!isEscort);
      setIsLeo(!!isLeo);
      setIsFirstResponder(!!isFirstResponder);
      setIsComplete(!!badgeValidation?.isComplete);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <ReadOnlyFormData skipTextTransform label="Requested access" value={doors || 'Not specified'} />
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyFormData skipTextTransform label="Badge purpose" value={purpose || 'Not specified'} />
        </Grid>
      </Grid>

      <div style={{ marginBottom: theme.spacing(1) }}>&nbsp;</div>

      <Grid container spacing={5}>
        <Grid item xs={6}>
          <div>
            <Label className="required">Law Enforcement Officer (LEO) Badge</Label>
            <RadioGroup
              row
              aria-label="LEO"
              name="rgLEO"
              value={`${isLeo}`}
              onChange={event => setIsLeo(event.target.value === 'true')}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
                disabled={isBackgroundCheckAlreadyStarted}
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
                disabled={isBackgroundCheckAlreadyStarted}
              />
            </RadioGroup>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Label className="required">Individual is a First Responder</Label>
            <RadioGroup
              row
              aria-label="first responder"
              name="rgFirstResponder"
              value={`${isFirstResponder}`}
              onChange={event => setIsFirstResponder(event.target.value === 'true')}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
                disabled={isBackgroundCheckAlreadyStarted}
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
                disabled={isBackgroundCheckAlreadyStarted}
              />
            </RadioGroup>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={6}>
          <DrivingEndorsementPicker
            context={{
              drivingEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.driving,
              drivingSelection: driving,
              setDrivingSelection: setDriving,
              shouldSkipDefaultSelection: true,
              data: badgeApplication,
            }}
            isDisabled={isBackgroundCheckAlreadyStarted}
          />
        </Grid>
        <Grid item xs={6}>
          <EscortEndorsementPicker
            context={{
              escortEndorsementConfig: badgeApplication?.badgeType?.config?.endorsements?.escort,
              escortSelection: isEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
              setEscortSelection: selection => {
                if (selection?.value === 1) {
                  setIsEscort(true);
                } else {
                  setIsEscort(false);
                }
              },
              shouldSkipDefaultSelection: true,
              data: badgeApplication,
            }}
            isDisabled={isBackgroundCheckAlreadyStarted}
          />
        </Grid>
      </Grid>

      {app.airport.isTrainingEnabled && (
        <>
          <div className="separator mt-2">Training</div>

          <div style={{ marginBottom: 16, marginTop: 16 }}>
            Add training to be automatically assigned after vetting has completed
          </div>

          <BadgeApplicationTrainingEditor
            config={auxiliaryConfig?.training}
            onTrainingConfigChanged={onTrainingConfigChanged}
            isDisabled={isBackgroundCheckAlreadyStarted}
          />
        </>
      )}

      <div style={{ marginBottom: theme.spacing(3) }}>&nbsp;</div>

      <TabActions>
        <Button onClick={() => gotoTab(0)}>
          <i className="fa-solid fa-chevron-left mr-2"></i>Person / Vetting
        </Button>
        <div>
          <Button onClick={() => closeModal()} className="mr-2">
            Close
          </Button>
          <Button
            color="primary"
            onClick={onMarkComplete}
            disabled={isMarkCompleteDisabled()}
            style={{ marginLeft: 15, minWidth: 100 }}
          >
            Validate Documents
            <i className="fa-solid fa-chevron-right ml-2"></i>
          </Button>
        </div>
      </TabActions>
    </>
  );
}
