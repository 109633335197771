import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppContext } from '../../../../App';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import request from '../../../../ajax/Request';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import AppointmentUpdateRequest from '../../../../ajax/Appointment/AppointmentUpdateRequest';
import StaffGetAllRequest from '../../../../ajax/Staff/StaffGetAllRequest';
import StaffGetAvailabilityRequest from '../../../../ajax/Staff/StaffGetAvailabilityRequest';
import BookingGetAvailableAppointmentTypesRequest from '../../../../ajax/Booking/BookingGetAvailableAppointmentTypesRequest';
import AutoCompleteDropdown, {
  AutoCompleteUserOption,
  filterUserOptions,
} from '../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import { handleError, isSmallScreen, notify } from '../../../../utils';
import InputWithCharacterLimit from '../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import { AppointmentCalendarContext } from './AppointmentsList';

function TimeSlots({ availableSlots, slotRenderer, isLoading = false }) {
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center" style={{ marginTop: 48 }}>
        <CircularProgress color="primary" size={50} thickness={5} />
      </div>
    );
  }

  if (!availableSlots || !availableSlots.length) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 48 }}>
        <strong>No times available</strong>
      </div>
    );
  }

  const defaultStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
  };
  const slots = availableSlots.map(slot => slotRenderer(slot.start, slot.end));

  return (
    <>
      {/* XS */}
      <div className="d-block d-sm-none">
        <div id="xs-slots" style={{ ...defaultStyle }}>
          {slots}
        </div>
      </div>

      {/* SM */}
      <div className="d-none d-sm-block d-md-none">
        <div id="sm-slots" style={{ ...defaultStyle }}>
          {slots}
        </div>
      </div>

      {/* MD */}
      <div className="d-none d-md-block d-lg-none">
        <div id="md-slots" style={{ ...defaultStyle }}>
          {slots}
        </div>
      </div>

      {/* LG */}
      <div className="d-none d-lg-block d-xl-none">
        <div id="lg-slots" style={{ ...defaultStyle, marginTop: 48 }}>
          {slots}
        </div>
      </div>

      {/* XL */}
      <div className="d-none d-xl-block">
        <div id="xl-slots" style={{ ...defaultStyle, flexDirection: 'column', marginTop: 0 }}>
          {slots}
        </div>
      </div>
    </>
  );
}

let attendeesRequestTimeout = null;
let attendeesAbortController = null;

export default function AppointmentsListEditModal(props) {
  if (!props.editAppointment) {
    return null;
  }

  const app = useContext(AppContext);
  const appointmentCalendarContext = useContext(AppointmentCalendarContext);
  const { user, api, onClose, isOpen, timeZone, editAppointment, onSave, mode, reload, shouldShowExtraActionButtons } =
    props;
  const uuid = editAppointment.uuid;
  const [appointmentType, setAppointmentType] = useState({
    value: props.editAppointment.appointmentType.uuid,
    label: props.editAppointment.appointmentType.name,
  });
  const [staff, setStaff] = useState({
    value: props.editAppointment.staff.uuid,
    label: props.editAppointment.staff.name,
  });
  const [holder, setHolder] = useState({
    value: props.editAppointment.attendee_uuid,
    label: props.editAppointment.attendee_name,
  });
  const [availableSlots, setAvailableSlots] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [holderOptions, setHolderOptions] = useState([]);
  const initDate = editAppointment.date ? new Date(editAppointment.date) : new Date();
  const [startDate, setStartDate] = useState(initDate);
  const [startTime, setStartTime] = useState(null);
  const defaultEmptyStaff = { label: 'Any Available', value: null };
  const noStaff = { label: 'No Resource Available', value: null };
  const noTypeEmptyStaff = { label: 'Please Select an Appointment Type', value: null };
  const [emptyStaff, setEmptyStaff] = useState(defaultEmptyStaff);
  const [isAttendeesLoading, setIsAttendeesLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState(false);
  const isAppointmentAlreadyEnded = moment.tz(editAppointment.ended, app.timezone).isBefore(moment().tz(app.timezone));
  const isBadgeHolder = !app.user.activeProfile;
  const isCurrentUserSignatory = app.user.activeProfile?.role?.name === 'authorized-signatory';

  /*
   * Find active Users
   */
  const findAttendeeUsers = event => {
    if (attendeesRequestTimeout) {
      if (attendeesAbortController) attendeesAbortController.abort();
      clearTimeout(attendeesRequestTimeout);
    }

    const fullName = get(event, 'target.value', null);
    if (!fullName || fullName.length < 3) {
      setIsAttendeesLoading(false);
      return;
    }

    setHolderOptions([]);
    setIsAttendeesLoading(true);

    attendeesRequestTimeout = setTimeout(() => {
      attendeesAbortController = new AbortController();
      request(
        'authenticated-user',
        'GET',
        `appointments/available-attendees?search=${encodeURIComponent(fullName)}`,
        null,
        attendeesAbortController.signal
      )
        .then(({ success, results }) => {
          if (success) {
            setHolderOptions(
              results.map(r => ({
                label: `${r.firstName} ${r.lastName}`,
                name: `${r.firstName} ${r.lastName}`,
                value: r.uuid,
                original: r,
              }))
            );
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsAttendeesLoading(false));
    }, 500);
  };

  const onSaveAppointmentClick = () => {
    const appointment = {
      staff: staff,
      appointmentType: appointmentType,
      startDate: startDate,
      startTime: startTime,
      timeZone: timeZone,
      user: holder,
      uuid: uuid,
      relatedToBadgeApplication: props?.editAppointment?.badgeApplicationUuid || undefined,
      comment: notes,
    };

    if (!appointmentType || !startDate || !startTime) {
      notify({ message: 'Missing required form data' });
      return;
    }

    api.toggleLoading(true);
    AppointmentUpdateRequest(user, uuid, appointment)
      .then(response => {
        if (response.success === false) {
          return notify({ message: response.message });
        }

        if (onSave) {
          onSave();
        } else {
          closeModal({ shouldReload: true });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to update appointment' }))
      .finally(() => api.toggleLoading(false));
  };
  /*
   * GET Appointment Types
   */
  const getAppointmentTypes = () => {
    const { user } = app;
    return BookingGetAvailableAppointmentTypesRequest(user, user.activeProfile?.uuid).then(apptTypes => {
      setAppointmentTypes(
        apptTypes.map(x => ({
          label: `${x.name} (${x.duration} minutes, ${x.location})`,
          value: x.uuid,
        }))
      );
    });
  };

  /*
   * GET Staff
   */
  const getStaff = () => {
    return StaffGetAllRequest().then(staffMembers => {
      if (appointmentType) {
        const stOpts = staffMembers.map(stf => {
          if (stf.appointment_types.filter(aType => aType.uuid === appointmentType.value).length) {
            let stfLabel = `${stf.name}`;
            if (stf.individual) {
              stfLabel += ` (${stf.individual.first_name}  ${stf.individual.last_name})`;
            }
            return {
              label: stfLabel,
              value: stf.uuid,
            };
          } else {
            return null;
          }
        });

        const reducedOpts = stOpts.filter(i => i);

        if (reducedOpts.length) {
          setEmptyStaff(defaultEmptyStaff);
          setStaff(defaultEmptyStaff);
          setStaffOptions([...[defaultEmptyStaff], ...reducedOpts]);
        } else {
          setEmptyStaff(noStaff);
          setStaff(noStaff);
          setStaffOptions([noStaff]);
        }
      } else {
        setEmptyStaff(noTypeEmptyStaff);
        setStaff(noTypeEmptyStaff);
        setStaffOptions([noTypeEmptyStaff]);
      }
    });
  };

  const trimAvailabilityIfNecessary = (slots, originalStartTime, selectedDate, leadTimeHours) => {
    if (!isBadgeHolder && !isCurrentUserSignatory) {
      return slots;
    }

    const leadTimeMoment = moment().tz(app.timezone).add(leadTimeHours, 'hours');
    const startTimeMoment = moment.tz(editAppointment.started, app.timezone);

    let noTimeSlotsBeforeDateTime;
    if (leadTimeMoment.isAfter(startTimeMoment)) {
      noTimeSlotsBeforeDateTime = startTimeMoment;
    } else {
      noTimeSlotsBeforeDateTime = leadTimeMoment;
    }

    return slots.filter(({ start }) => {
      // Make sure the time slot is 4 characters long, so it can be easily parsed
      let startTimeFromSlot = `${start}`;
      if (startTimeFromSlot.length === 3) startTimeFromSlot = `0${startTimeFromSlot}`;
      // Parse the time slot into hours and minutes
      const hours = parseInt(startTimeFromSlot.substring(0, 2), 10);
      const minutes = parseInt(startTimeFromSlot.substring(2, 4), 10);
      const startTimeMoment = moment.tz(selectedDate, app.timezone).set({ hour: hours, minute: minutes, second: 0 });
      return !startTimeMoment.isBefore(noTimeSlotsBeforeDateTime);
    });
  };

  /*
   * GET Available Time Slots
   */
  const getAvailability = (stf, date) => {
    if (appointmentType && holder) {
      const { user, timezone } = app;
      setAvailableSlots([]);
      setIsLoadingTimeSlots(true);
      StaffGetAvailabilityRequest(
        user,
        stf,
        appointmentType,
        date || startDate,
        timezone,
        editAppointment.uuid,
        holder,
        mode === 'badge-holder-reschedule'
      )
        .then(data => {
          const { leadTimeHours = 24, availability } = data;
          const originalStartTime = Number(editAppointment.start_raw);
          const availableTimeSlots = trimAvailabilityIfNecessary(
            availability,
            originalStartTime,
            date || startDate,
            leadTimeHours
          );
          const hasAvailableTimeSlotMatchingOriginalTimeSlot = !!availableTimeSlots.find(
            ({ start }) => start === originalStartTime
          );
          if (!date && hasAvailableTimeSlotMatchingOriginalTimeSlot) {
            setStartTime(originalStartTime);
          }
          setAvailableSlots(availableTimeSlots);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingTimeSlots(false));
    }
  };

  const changeStaff = value => {
    setStaff(value);
    if (appointmentType && appointmentType.value && holder && holder.value) {
      getAvailability(value);
    }
  };

  const reset = () => {
    setNotes('');
    setIsLoadingTimeSlots(false);
    setAppointmentType(null);
    setAppointmentTypes([]);
    setStartDate(new Date());
    setStartTime(null);
    setStaffOptions([]);
    setStaff(null);
    setEmptyStaff(defaultEmptyStaff);
    setHolder(null);
    setAvailableSlots([]);
    setHolderOptions([]);
  };

  const closeModal = args => {
    reset();
    onClose();
    if (args?.shouldReload === true) {
      reload();
    }
  };

  const isSaveDisabled = () => {
    return !staff || !appointmentType || !startDate || !startTime || !holder;
  };

  const convertTime = time => {
    const hours = time > 1259 ? Math.floor(time / 100) - 12 : Math.floor(time / 100);
    const minutes = String(time).slice(-2) || '00';
    const meridian = time >= 1200 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${meridian}`;
  };

  const timeSlot = (start, end) => {
    const selClass = start === startTime ? 'selected' : '';
    return (
      <div className={`time-slot ${selClass}`} key={`${start}-${end}`} onClick={() => setStartTime(start)}>
        {convertTime(start)}
      </div>
    );
  };

  const onCancelClicked = () => {
    appointmentCalendarContext.onCancelAppointment({ appointment: editAppointment });
  };

  const onMarkCompletedClicked = () => {
    appointmentCalendarContext.onMarkCompleted({ appointment: editAppointment });
  };

  const onMarkMissedClicked = () => {
    appointmentCalendarContext.onMarkMissed({ appointment: editAppointment });
  };

  const onForceRescheduleClicked = () => {
    appointmentCalendarContext.onForceReschedule({ appointment: editAppointment });
  };

  useEffect(() => {
    if (isOpen && appointmentCalendarContext.isAppointmentUpdateDone) {
      closeModal({ shouldReload: true });
    }
  }, [isOpen, appointmentCalendarContext.isAppointmentUpdateDone]);

  useEffect(() => {
    if (props.editAppointment) {
      setNotes(props.editAppointment.comment);
    }
  }, [props.editAppointment]);

  useEffect(() => {
    if (holder && holder.value && appointmentType && appointmentType.value) {
      getAvailability(staff);
    }
  }, [appointmentType, holder]);

  useEffect(() => {
    getStaff();
  }, [appointmentType]);

  useEffect(() => {
    if (!isOpen) return;

    app.api.toggleLoading(true);
    getAppointmentTypes()
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        size={mode !== 'badge-holder-reschedule' ? 'xl' : 'lg'}
        className="appointmentModal"
      >
        <CustomModalHeader toggle={closeModal}>
          {mode === 'badge-holder-reschedule' ? (
            <>
              Reschedule {editAppointment.appointmentType.name} Appointment
              <br />
              for <i>{editAppointment.attendee_name}</i>
            </>
          ) : (
            'Appointment Details'
          )}
        </CustomModalHeader>
        <ModalBody style={isSmallScreen() ? null : { minHeight: 400 }}>
          {mode === 'badge-holder-reschedule' ? (
            <Row>
              <Col xs="12" md="6" className={isSmallScreen() ? 'text-center' : null}>
                <DatePicker
                  inline
                  minDate={new Date()}
                  selected={startDate}
                  onChange={date => {
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    setStartDate(date);
                    setStartTime(null);
                    getAvailability(staff, date);
                  }}
                />
              </Col>

              <Col xs="12" md="6" className="timeSlots">
                <TimeSlots availableSlots={availableSlots} slotRenderer={timeSlot} isLoading={isLoadingTimeSlots} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12" md="12" lg="6" xl="5">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Attendee</Label>
                      <AutoCompleteDropdown
                        id="attendee"
                        value={holder}
                        placeholder="Search by first or last name"
                        size="small"
                        onInputChange={findAttendeeUsers}
                        isLoading={isAttendeesLoading}
                        options={holderOptions}
                        onValueSelected={option => {
                          setHolder(option);
                        }}
                        filterOptions={filterUserOptions}
                        renderOption={user => <AutoCompleteUserOption user={user} />}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Appointment Type</Label>
                      <Select
                        classNamePrefix="airbadge"
                        isSearchable={false}
                        options={appointmentTypes}
                        className="form-select"
                        value={appointmentType}
                        placeholder=""
                        onChange={option => {
                          setAppointmentType(option);
                          setStartTime(null);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Resource</Label>
                      <Select
                        classNamePrefix="airbadge"
                        options={staffOptions}
                        className="form-select"
                        isDisabled={!appointmentType}
                        value={mode === 'badge-holder-reschedule' ? emptyStaff : staff || emptyStaff}
                        placeholder=""
                        onChange={option => {
                          setStartTime(null);
                          changeStaff(option);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {mode !== 'badge-holder-reschedule' && (
                  <Row className="mt-3">
                    <Col>
                      <FormGroup>
                        <Label>Notes for the Badge Office</Label>
                        <InputWithCharacterLimit
                          style={{ height: 60 }}
                          limit={191}
                          value={notes}
                          onChange={value => setNotes(value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col xs="12" md="12" lg="6" xl="4" className={isSmallScreen() ? 'text-center' : null}>
                <DatePicker
                  inline
                  minDate={new Date()}
                  selected={startDate}
                  onChange={date => {
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    setStartDate(date);
                    setStartTime(null);
                    getAvailability(staff, date);
                  }}
                />
              </Col>

              <Col xs="12" md="12" lg="12" xl="3" className="timeSlots">
                <TimeSlots availableSlots={availableSlots} slotRenderer={timeSlot} isLoading={isLoadingTimeSlots} />
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Cancel"
            onClose={closeModal}
            saveLabel="Update Appointment"
            onSave={onSaveAppointmentClick}
            saveDisabled={isSaveDisabled()}
            leftSideExtra={
              mode !== 'badge-holder-reschedule' &&
              shouldShowExtraActionButtons && (
                <div>
                  <Button color="success" className="mr-4" onClick={onMarkCompletedClicked}>
                    Mark Completed
                  </Button>

                  {isAppointmentAlreadyEnded ? (
                    <Button color="warning" className="mr-4" onClick={onMarkMissedClicked}>
                      Mark Missed
                    </Button>
                  ) : (
                    <Button color="warning" className="mr-4" onClick={onForceRescheduleClicked}>
                      Force Reschedule
                    </Button>
                  )}

                  <Button color="danger" onClick={onCancelClicked}>
                    Cancel Appointment
                  </Button>
                </div>
              )
            }
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
