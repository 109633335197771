import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Loading from '../../../containers/DefaultLayout/Loading';
import Message from '../../../containers/DefaultLayout/Message';

export default class Page500 extends Component {
  render() {
    return (
      <div className={'app flex-row align-items-center'}>
        <Loading loading={this.props.state.loading} />
        <Message message={this.props.state.messageContent} brand={this.props.state.messageBrand} />
        <Container>
          <Row className={'justify-content-center'}>
            <Col md={'6'}>
              <span className={'clearfix'}>
                <h1 className={'float-left display-3 mr-4'}>500</h1>
                <h4 className={'pt-3'}>Houston, we have a problem!</h4>
                <p className={'text-muted float-left'}>The page you are looking for is temporarily unavailable.</p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
