import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Alert, Button, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function BadgeApplicationPaymentEditor({ paymentConfig, onChanged, isAlreadyPaid }) {
  const [paymentItems, setPaymentItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addLabel, setAddLabel] = useState('');
  const [addAmount, setAddAmount] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [originalTotal, setOriginalTotal] = useState(0);

  const getTotal = fees => {
    const total = fees.reduce((total, { amount }) => total + parseFloat(amount), 0);
    return parseFloat(total).toFixed(2);
  };

  const onRemoveItemClicked = ({ item }) => {
    const updatedList = paymentItems.filter(x => x.id !== item.id);
    setPaymentItems(updatedList);
    onChanged({ fees: updatedList, originalTotal, newTotal: getTotal(updatedList) });
  };

  const onAddItemClicked = () => {
    setSelectedId(null);
    setIsEditMode(false);
    setAddLabel('');
    setAddAmount('');
    setIsAddModalOpen(true);
  };

  const onEditItemClicked = ({ item }) => {
    setSelectedId(item.id);
    setIsEditMode(true);
    setAddLabel(item.label);
    setAddAmount(item.amount);
    setIsAddModalOpen(true);
  };

  const onAddModalClose = wasAdded => {
    setIsAddModalOpen(false);
    if (wasAdded) {
      let updatedList;
      if (isEditMode) {
        updatedList = paymentItems.map(x => {
          if (x.id === selectedId) {
            return { ...x, label: addLabel, amount: parseFloat(addAmount).toFixed(2) };
          }
          return x;
        });
      } else {
        updatedList = [
          ...paymentItems,
          {
            id: uuid(),
            label: addLabel,
            amount: parseFloat(addAmount).toFixed(2),
          },
        ];
      }
      setPaymentItems(updatedList);
      onChanged({ fees: updatedList, originalTotal, newTotal: getTotal(updatedList) });
    }
  };

  useEffect(() => {
    setOriginalTotal(0);
    setPaymentItems([]);
    if (paymentConfig?.items?.length) {
      const fees = paymentConfig.items.map(({ label, amount }) => ({
        id: uuid(),
        label,
        amount: parseFloat(amount).toFixed(2),
      }));
      setPaymentItems(fees);
      setOriginalTotal(getTotal(fees));
    }
  }, [paymentConfig]);

  if (isAlreadyPaid) {
    return (
      <div>
        <Alert color="warning">Badge Application payment already received. No changes to fees are allowed.</Alert>
      </div>
    );
  }

  return (
    <div>
      {paymentItems.map((item, index) => (
        <Grid
          key={index}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            marginBottom: 18,
            padding: 16,
            borderRadius: 5,
            border: '1px solid black',
          }}
        >
          <Grid item xs={2}>
            $ {item.amount}
          </Grid>
          <Grid item xs={7}>
            {item.label}
          </Grid>
          <Grid container direction="row" justifyContent="space-evenly" item xs={3}>
            <Grid item>
              <button className="btn btn-link p-0" onClick={() => onEditItemClicked({ item })}>
                Edit
              </button>
            </Grid>
            {paymentItems.length > 1 && (
              <Grid item>
                <button className="btn btn-link p-0 text-danger" onClick={() => onRemoveItemClicked({ item })}>
                  Remove
                </button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}

      <Button size="sm" color="primary" className="mt-2" onClick={() => onAddItemClicked()}>
        <i className="fa-solid fa-plus mr-2"></i>Add Fee
      </Button>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title={isEditMode ? 'Edit Payment Item' : 'Add Payment Item'}
        isOpen={isAddModalOpen}
        onClose={() => onAddModalClose(false)}
        onSave={() => onAddModalClose(true)}
        saveLabel={isEditMode ? 'Save Changes' : 'Add Item'}
        saveDisabled={!addLabel || !addAmount}
      >
        <FormGroup>
          <Label>Label</Label>
          <Input value={addLabel} onChange={event => setAddLabel(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>Amount</Label>
          <InputGroup>
            <InputGroupText>$</InputGroupText>
            <Input type="number" value={addAmount} onChange={event => setAddAmount(event.target.value)} />
          </InputGroup>
        </FormGroup>
      </AirBadgeModal>
    </div>
  );
}
