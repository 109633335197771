import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import badgeApplicationApi from '../../../../../ajax/BadgeApplication/badgeApplicationApi';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';

export default function BadgeApplicationCompleteAppointmentModal({
  user = {},
  badgeApplication = {},
  onClose = () => false,
  appFunctions = {},
  isOpen = false,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (!isOpen) return;
    setIsModalOpen(true);
  }, [isOpen, badgeApplication]);

  const onModalClose = ({ didMakeChanges }) => {
    setComment('');
    setIsModalOpen(false);
    onClose(didMakeChanges);
  };

  const save = async () => {
    appFunctions.toggleLoading(true);
    const response = await badgeApplicationApi.completeAppointment({
      user,
      badgeApplicationUuid: badgeApplication.uuid,
      comment,
    });

    if (!response.success) {
      const message = 'Unable to mark appointment as completed';
      return appFunctions.displayMessage(message, 'warning');
    }

    const message = 'Appointment successfully marked as completed';
    appFunctions.displayMessage(message, 'success');
    appFunctions.toggleLoading(false);
    return onModalClose({ didMakeChanges: true });
  };

  if (!badgeApplication) return null;

  return (
    <Modal isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Complete Badge Application Appointment</CustomModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: 15,
            paddingBottom: 20,
            fontWeight: 500,
          }}
        >
          This will mark the <strong>{badgeApplication.badgeType.displayName}</strong> Badge Application appointment for{' '}
          <strong>{badgeApplication.applicant.fullName}</strong> as completed and will advance the badge application
          process to the next step
        </div>

        <FormGroup>
          <Label>Comments</Label>
          <Input required type="textarea" value={comment} onChange={event => setComment(event.target.value)} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onModalClose}>
          Close
        </Button>
        <Button color="primary" onClick={save}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
