import React, { useState } from 'react';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import ReactTableTotalCount from '../../Common/ReactTableTotalCount';
import appointmentApi from '../../../../ajax/Appointment/appointmentApi';
import { handleError } from '../../../../utils';
import AppointmentRequestCreateModal from './AppointmentRequestCreateModal';
import AppointmentRequestEditModal from './AppointmentRequestEditModal';

let requestTimeout = null;
let abortController = null;

export default function AppointmentRequests({ isOpen, onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [data, setData] = useState([]);
  const [params, setParams] = useState([]);
  const [isAppointmentRequestCreateModalOpen, setIsAppointmentRequestCreateModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isAppointmentRequestEditModalOpen, setIsAppointmentRequestEditModalOpen] = useState(false);

  const onViewDetailsClicked = selectedRequest => {
    setSelectedRequest(selectedRequest);
    setIsAppointmentRequestEditModalOpen(true);
  };

  const onViewDetailsModalClose = args => {
    setIsAppointmentRequestEditModalOpen(false);
    if (args?.shouldReload) {
      loadData({ pageSize: params[1], page: params[2], sorted: params[3], filtered: params[4] });
    }
  };

  const columns = [
    {
      id: 'attendeeName',
      Header: 'Attendee',
      accessor: ({ attendee }) => attendee.fullName || 'ERROR',
      sortable: true,
    },
    {
      id: 'appointmentTypeName',
      Header: 'Type',
      accessor: ({ appointmentType }) => appointmentType.name || 'ERROR',
      sortable: true,
    },
    {
      id: 'actions',
      Header: 'Actions',
      accessor: row => (
        <Button color="primary" onClick={() => onViewDetailsClicked(row)}>
          View Details
        </Button>
      ),
      sortable: false,
      width: 150,
    },
  ];

  const onAppointmentRequestCreateModalClose = args => {
    setIsAppointmentRequestCreateModalOpen(false);
    if (args?.shouldReload) {
      loadData({ pageSize: params[1], page: params[2], sorted: params[3], filtered: params[4] });
    }
  };

  const onModalClose = () => {
    setSelectedRequest(null);
    setIsLoading(true);
    setPages(null);
    setTotal(null);
    setData([]);
    setParams([]);
    onClose();
  };

  const loadData = tableState => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    const { filtered, pageSize, page, sorted } = tableState;
    setParams([null, pageSize, page, sorted, filtered]);
    setIsLoading(true);

    requestTimeout = setTimeout(() => {
      setIsLoading(true);
      abortController = new AbortController();
      appointmentApi
        .getAppointmentRequests({ data: tableState, signal: abortController.signal })
        .then(({ results, pages, total }) => {
          setData(results);
          setPages(pages);
          setTotal(total);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      isOpen={isOpen}
      title="Appointment Requests"
      onClose={onModalClose}
      closeLabel="Close"
    >
      <div className="mt-2 mb-4">
        <Button color="primary" onClick={() => setIsAppointmentRequestCreateModalOpen(true)}>
          <i className="fa-solid fa-plus mr-2"></i>Add New Appointment Request
        </Button>
      </div>
      <div className="table-responsive" style={{ minHeight: 420 }}>
        <ReactTable
          manual
          defaultSorted={[{ id: 'attendeeName', desc: false }]}
          className="border-0 -striped"
          loading={isLoading}
          data={data}
          columns={columns}
          pages={pages}
          minRows={0}
          defaultPageSize={25}
          onFetchData={loadData}
          showPagination={pages > 1}
        />

        <ReactTableTotalCount params={params} total={total} />
      </div>

      <AppointmentRequestCreateModal
        isOpen={isAppointmentRequestCreateModalOpen}
        onClose={onAppointmentRequestCreateModalClose}
      />

      <AppointmentRequestEditModal
        isOpen={isAppointmentRequestEditModalOpen}
        onClose={onViewDetailsModalClose}
        selectedRequest={selectedRequest}
      />
    </AirBadgeModal>
  );
}
