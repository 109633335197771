import React, { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { AppContext } from '../../../../../App';
import peopleApi from '../../../../../ajax/People/peopleApi';
import { handleError, validEmailAddressRegex } from '../../../../../utils';
import FontAwesomeIcon from '../../../../../shared/components/FontAwesome/FontAwesomeIcon';

export function FixInvalidEmailAction({ badge, onEmailFixed }) {
  const [hasInvalidEmail, setHasInvalidEmail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = args => {
    setIsModalOpen(false);
    if (args?.wasChanged) {
      onEmailFixed();
    }
  };

  useEffect(() => {
    setHasInvalidEmail(false);
    if (badge && badge.id !== -1) {
      const { badgeHolderEmail } = badge;
      if (
        !badgeHolderEmail ||
        badgeHolderEmail.indexOf('fake') > -1 ||
        !validEmailAddressRegex.test(badgeHolderEmail)
      ) {
        setHasInvalidEmail(true);
      }
    }
  }, [badge]);

  if (!badge || !hasInvalidEmail) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)} disabled={!hasInvalidEmail}>
        <ListItemIcon>
          <FontAwesomeIcon className="fa-solid fa-envelope text-danger" />
        </ListItemIcon>
        <ListItemText primary="Fix Invalid Email Address" />
      </ListItem>

      <FixInvalidEmailModal isOpen={isModalOpen} onClose={onModalClose} badgeData={badge} />
    </>
  );
}

export default function FixInvalidEmailModal({ isOpen, onClose, badgeData }) {
  const app = useContext(AppContext);
  const [badgeHolderName, setBadgeHolderName] = useState('Unknown');
  const [email, setEmail] = useState('');

  const onChangeEmail = () => {
    app.api.toggleLoading(true);
    const { badgeHolderUuid } = badgeData;
    const updates = { email, force: true };
    peopleApi
      .patch({ uuid: badgeHolderUuid, updates })
      .then(({ success, message }) => {
        if (success) {
          onClose({ wasChanged: true });
        } else {
          handleError({ message: message || 'Unable to update email address' });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to update email address' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const isSaveDisabled = () => {
    return !email || email.indexOf('fake') > -1 || !validEmailAddressRegex.test(email);
  };

  useEffect(() => {
    setBadgeHolderName('Unknown');
    setEmail('');

    if (isOpen && badgeData) {
      const { badgeHolderName } = badgeData;
      setBadgeHolderName(badgeHolderName);
    }
  }, [isOpen, badgeData]);

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Provide Valid Email Address"
      isOpen={isOpen}
      onClose={onClose}
      onSave={onChangeEmail}
      saveLabel="Change Email Address"
      saveDisabled={isSaveDisabled()}
    >
      <p className="mb-4">
        After you provide a valid email address, {badgeHolderName} will be sent an email confirming the change.
      </p>
      <FormGroup>
        <Label className="required">Valid Email Address</Label>
        <Input type="email" value={email} onChange={event => setEmail(event.target.value)} />
      </FormGroup>
    </AirBadgeModal>
  );
}
