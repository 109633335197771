import React, { Component } from 'react';

export default class Message extends Component {
  style = {
    message: {
      position: 'fixed',
      bottom: '0',
      width: '100vw',
      zIndex: '9998',
    },
  };

  resolveColorClass(brand) {
    return brand === 'warning' ? 'text-dark' : 'text-white';
  }

  resolveIconClass(brand) {
    switch (brand) {
      case 'success':
        return 'check';
      case 'warning':
        return 'exclamation-triangle';
      case 'danger':
        return 'times';
      case 'info':
      default:
        return 'info-circle';
    }
  }

  render() {
    return (
      <div
        className={'message p-3 ' + this.resolveColorClass(this.props.brand) + ' bg-' + this.props.brand}
        style={this.style.message}
        hidden={!this.props.message ? 'hidden' : ''}
      >
        <i className={'mr-2 fa fa-' + this.resolveIconClass(this.props.brand)} />
        {this.props.message}
      </div>
    );
  }
}
