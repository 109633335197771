import request from '../Request';

export default {
  search,
};

function search({ query, isDeactivatedRecordsExcluded, signal }) {
  return request(
    'authenticated-user',
    'POST',
    'search',
    {
      query,
      isDeactivatedRecordsExcluded,
    },
    signal
  );
}
