import request from '../Request';

export default {
  getContactInfo,
  updateContactInfo,
  uploadBadgePhoto,
  downloadBadgePhoto,
  getAppointmentSettings,
  updateAppointmentSettings,
  getMessageTemplates,
  updateMessageTemplate,
  updateEmailFooter,
  getEmailFooter,
  getKiosks,
  startKioskEnrollment,
  completeKioskEnrollment,
  disenrollKiosk,
  validateKiosk,
  validateLoginCode,
  authorizeKioskLogin,
  sendMessage,
  getSettings,
  updateSettings,
};

function getContactInfo() {
  return request('authenticated-user', 'GET', `badge-offices/1/contact-info`);
}

function updateContactInfo({ user, badgeOfficeId, contactInfo }) {
  return request(user, 'PUT', `badge-offices/${badgeOfficeId}/contact-info`, {
    contact_info: contactInfo,
  });
}

function downloadBadgePhoto({ user, badgeOfficeId }) {
  return request(user, 'GET', `badge-offices/${badgeOfficeId}/photo`);
}

function uploadBadgePhoto({ user, badgeOfficeId, photo }) {
  return request(user, 'POST', `badge-offices/${badgeOfficeId}/photo`, {
    photo,
  });
}

function getAppointmentSettings({ user, badgeOfficeId }) {
  return request(user, 'GET', `badge-offices/${badgeOfficeId}/appointment-settings`);
}

function updateAppointmentSettings({ badgeOfficeId, appointmentSettings }) {
  return request(
    'authenticated-user',
    'PUT',
    `badge-offices/${badgeOfficeId}/appointment-settings`,
    appointmentSettings
  );
}

function getMessageTemplates({ badgeOfficeId }) {
  return request('authenticated-user', 'GET', `badge-offices/${badgeOfficeId}/templates`);
}

function updateMessageTemplate({ badgeOfficeId, template }) {
  return request('authenticated-user', 'POST', `badge-offices/${badgeOfficeId}/templates`, { template });
}

function updateEmailFooter(badgeOfficeId, emailFooter) {
  return request('authenticated-user', 'PUT', `badge-offices/${badgeOfficeId}/email-footer`, {
    emailFooter,
  });
}

function getEmailFooter(badgeOfficeId) {
  return request('authenticated-user', 'GET', `badge-offices/${badgeOfficeId}/email-footer`);
}

function getKiosks() {
  return request('authenticated-user', 'POST', `badge-offices/1/kiosks`);
}

function startKioskEnrollment(data) {
  return request('authenticated-user', 'POST', `badge-offices/1/kiosk`, data);
}

function completeKioskEnrollment(data) {
  return request('authenticated-user', 'PUT', `badge-offices/1/kiosk`, data);
}

function disenrollKiosk({ kioskUuid }) {
  return request('authenticated-user', 'DELETE', `badge-offices/1/kiosk/${kioskUuid}`);
}

function validateKiosk({ kioskUuid }) {
  return request('authenticated-user', 'GET', `badge-offices/1/kiosk/${kioskUuid}/validate`);
}

function validateLoginCode({ kioskUuid, loginCode }) {
  return request('authenticated-user', 'GET', `badge-offices/1/kiosk/${kioskUuid}/login/${loginCode}`);
}

function authorizeKioskLogin({ kioskUuid, isValid, userUuid }) {
  return request('authenticated-user', 'POST', `badge-offices/1/kiosk/${kioskUuid}/authorize-login`, {
    isValid,
    userUuid,
  });
}

function sendMessage({ userUuid, subject, message }) {
  return request('authenticated-user', 'POST', 'badge-offices/1/send-message', {
    userUuid,
    subject,
    message,
  });
}

function getSettings(args) {
  return request('authenticated-user', 'GET', `badge-offices/settings`, null, args?.signal);
}

function updateSettings(settings) {
  return request('authenticated-user', 'PUT', `badge-offices/settings`, { settings });
}
