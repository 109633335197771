import React, { useContext, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import { Alert, Card, CardBody, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import { AppContext } from '../../../../App';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import { handleError } from '../../../../utils';

export default function BadgeApplicationBadgerData({ isOpen, onClose, selectedBadgeApplication }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [config, setConfig] = useState({});
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState('');

  const loadData = () => {
    const { api } = app;
    const { uuid: badgeApplicationUuid } = selectedBadgeApplication;
    api.toggleLoading(true);
    badgeApplicationApi
      .getBadgerData(badgeApplicationUuid)
      .then(({ success, data }) => {
        if (success) {
          // Need to call "setSelectedConfig" twice to force a re-render
          setConfig(null);
          setTimeout(() => {
            setConfig(data || {});
          }, 100);
        }
      })
      .catch(e => console.error(e))
      .finally(() => app.api.toggleLoading(false));
  };

  const onModalClose = args => {
    setHasChanged(false);
    setIsModalOpen(false);
    setError('');
    setConfig(null);
    onClose(args);
  };

  const onSave = () => {
    const { api } = app;
    const { uuid: badgeApplicationUuid } = selectedBadgeApplication;
    api.toggleLoading(true);
    badgeApplicationApi
      .updateBadgerData({ badgeApplicationUuid, data: config })
      .then(({ success }) => {
        if (success) {
          onModalClose({ shouldReload: true });
        } else {
          handleError({ message: 'Failed to update badge application config' });
        }
      })
      .catch(e => console.error(e))
      .finally(() => api.toggleLoading(false));
  };

  const onChange = async json => {
    try {
      const config = JSON.parse(json);
      setConfig(config);
      setError('');
      setHasChanged(true);
    } catch (err) {
      setError('Invalid JSON');
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    if (!selectedBadgeApplication) {
      setIsModalOpen(false);
    }
    setIsModalOpen(isOpen);
    loadData();
  }, [isOpen, selectedBadgeApplication]);

  useEffect(() => {
    const { user } = app;
    if (isOpen && !user.isSuperAdmin) {
      window.location.href = '/#/default/home';
    }
  }, [isOpen, app.user]);

  if (!isModalOpen || !selectedBadgeApplication) return null;

  return (
    <Modal size="lg" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Edit Badger Data</CustomModalHeader>
      <ModalBody>
        {error && (
          <Alert color="warning" fade={false}>
            {error}
          </Alert>
        )}
        <Card>
          <CardBody>
            <Row>
              <Col style={{ height: 750 }}>
                {config && (
                  <AceEditor
                    name="badge-application-config"
                    style={{ zIndex: 0 }}
                    mode="json"
                    theme="chrome"
                    width="100%"
                    height="100%"
                    defaultValue={JSON.stringify(config, null, 2)}
                    onChange={onChange}
                    editorProps={{ $blockScrolling: false }}
                    setOptions={{ useWorker: false }}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Close"
          onClose={onModalClose}
          saveLabel="Save Changes"
          onSave={onSave}
          saveDisabled={!hasChanged || !!error}
        />
      </ModalFooter>
    </Modal>
  );
}
