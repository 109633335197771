import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/MailOutline';
import { colors } from '../../../../../../App';
// import { ActionPanelContext } from '../BadgeApplicationActionPanel';

export default function NotifyApplicantAction() {
  // const app = useContext(AppContext);
  // const { badgeApplication, onActionDone } = useContext(ActionPanelContext);

  const isHidden = () => {
    return true;
  };

  const onClick = () => {
    //
  };

  return (
    <ListItem button hidden={isHidden()} onClick={onClick}>
      <ListItemIcon>
        <EmailIcon style={{ color: colors.primary }} />
      </ListItemIcon>
      <ListItemText primary="Resend Applicant Notification" />
    </ListItem>
  );
}
