import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import logo from './airbadge-logo.png';

export default class QuickAuditHeader extends Component {
  state = {
    navbar: false,
  };

  style = {
    alphaBadge: {
      fontWeight: 'normal',
    },
  };

  toggleNavbar() {
    this.setState({ navbar: !this.state.navbar });
  }

  render() {
    // const profiles = this.props.state.user.profiles.filter(profile => profile.role.name !== 'badge-holder');
    //
    // const activeProfile = this.props.state.user.activeProfile;

    return (
      <div>
        <Navbar color={'white'} light expand={'lg'} className={'shadow-sm'}>
          <NavbarBrand href="/#/default/home">
            <img
              src={logo}
              width="30"
              height="30"
              alt={'AirBadge Logo'}
              className={'d-inline-block align-middle mr-2'}
            />

            <div className={'d-inline-block align-middle mr-2'}>AIRPORT BADGES</div>
          </NavbarBrand>

          <NavbarToggler onClick={this.toggleNavbar.bind(this)} className={'rounded-0'} />

          {/*<Collapse isOpen={this.state.navbar} navbar>*/}
          {/*    <Nav className={'ml-auto'} navbar>*/}
          {/*        <NavLink href="#/default/home">*/}
          {/*            <NavItem className={'p-2'}>*/}
          {/*                <i className={'fa fa-plane mr-2'}/>*/}
          {/*                Home*/}
          {/*            </NavItem>*/}
          {/*        </NavLink>*/}

          {/*        <NavLink href="#/default/audits">*/}
          {/*            <NavItem className={'p-2'} hidden={!activeProfile}>*/}
          {/*                <i className={'fa fa-calendar-check-o mr-2'}/>*/}
          {/*                Audits*/}
          {/*            </NavItem>*/}
          {/*        </NavLink>*/}

          {/*        /!* BADGES *!/*/}

          {/*        <UncontrolledDropdown*/}
          {/*            nav inNavbar className={'p-2'}*/}
          {/*            hidden={!activeProfile}>*/}
          {/*            <DropdownToggle nav caret>*/}
          {/*                <i className={'fa fa-id-badge mr-2'}/>*/}
          {/*                Badges*/}
          {/*            </DropdownToggle>*/}
          {/*            <DropdownMenu right>*/}
          {/*                <NavLink href="#/default/badges" className={'p-0'}>*/}
          {/*                    <DropdownItem>*/}
          {/*                        <i className={'fa fa-id-card-o mr-2'}/>*/}
          {/*                        Badges*/}
          {/*                    </DropdownItem>*/}
          {/*                </NavLink>*/}

          {/*                <NavLink href="#/default/badge-applications" className={'p-0'}>*/}
          {/*                    <DropdownItem>*/}
          {/*                        <i className={'fa fa-file-text-o mr-2'}/>*/}
          {/*                        Applications*/}
          {/*                    </DropdownItem>*/}
          {/*                </NavLink>*/}
          {/*            </DropdownMenu>*/}
          {/*        </UncontrolledDropdown>*/}

          {/*        /!* USERS *!/*/}

          {/*        <UncontrolledDropdown*/}
          {/*            nav inNavbar className={'p-2'}*/}
          {/*            hidden={!activeProfile}>*/}
          {/*            <DropdownToggle nav caret>*/}
          {/*                <i className={'fa fa-users mr-2'}/>*/}
          {/*                Users*/}
          {/*            </DropdownToggle>*/}
          {/*            <DropdownMenu right>*/}
          {/*                <NavLink href="#/default/people" className={'p-0'}>*/}
          {/*                    <DropdownItem>*/}
          {/*                        <i className={'fa fa-users mr-2'}/>*/}
          {/*                        People*/}
          {/*                    </DropdownItem>*/}
          {/*                </NavLink>*/}

          {/*                <NavLink href="#/default/authorized-signatories" className={'p-0'}>*/}
          {/*                    <DropdownItem*/}
          {/*                        hidden={!activeProfile || !['system-admin', 'airport-security-coordinator', 'trusted-agent'].includes(activeProfile.role.name)}>*/}
          {/*                        <i className={'fa fa-id-card-o mr-2'}/>*/}
          {/*                        Auth. signatories*/}
          {/*                    </DropdownItem>*/}
          {/*                </NavLink>*/}

          {/*                <NavLink href="#/default/trusted-agents" className={'p-0'}>*/}
          {/*                    <DropdownItem*/}
          {/*                        hidden={!activeProfile || !['system-admin', 'airport-security-coordinator'].includes(activeProfile.role.name)}>*/}
          {/*                        <i className={'fa fa-shield mr-2'}/>*/}
          {/*                        Trusted Agents*/}
          {/*                    </DropdownItem>*/}
          {/*                </NavLink>*/}
          {/*            </DropdownMenu>*/}
          {/*        </UncontrolledDropdown>*/}

          {/*        /!*<NavLink href="#/default/action-items" hidden={!activeProfile || !['system-admin', 'airport-security-coordinator', 'trusted-agent'].includes(activeProfile.role.name)}>*!/*/}
          {/*        /!*    <NavItem className={'p-2'} hidden={!activeProfile}>*!/*/}
          {/*        /!*        <i className={'fa fa-check-square mr-2'}/>*!/*/}
          {/*        /!*        Action Items*!/*/}
          {/*        /!*    </NavItem>*!/*/}
          {/*        /!*</NavLink>*!/*/}

          {/*        /!* CURRENT USER  *!/*/}

          {/*        <UncontrolledDropdown nav inNavbar className={'p-2'}>*/}
          {/*            <DropdownToggle className={'current-profile'} nav caret title={this.getDisplayName(this.props.state.user.activeProfile)}>*/}
          {/*                <i className={'fa fa-user-o mr-2'}/>*/}
          {/*                {this.getDisplayName(this.props.state.user.activeProfile)}*/}
          {/*            </DropdownToggle>*/}
          {/*            <DropdownMenu right>*/}
          {/*                <DropdownItem header tag="div">*/}
          {/*                    <strong>Profiles</strong>*/}
          {/*                </DropdownItem>*/}

          {/*                {*/}
          {/*                    profiles.map(profile => {*/}
          {/*                        return <DropdownItem key={profile.uuid}*/}
          {/*                                             onClick={() => this.setProfile(profile)}>*/}
          {/*                            <NavLink href={'#'} className={'p-0'}>*/}
          {/*                                <i className={'fa fa-user-o mr-2'}/>*/}
          {/*                                {this.getDisplayName(profile)}*/}
          {/*                            </NavLink>*/}
          {/*                        </DropdownItem>*/}
          {/*                    })*/}
          {/*                }*/}

          {/*                <DropdownItem header tag="div">*/}
          {/*                    <strong>Account</strong>*/}
          {/*                </DropdownItem>*/}

          {/*                <DropdownItem>*/}
          {/*                    <NavLink href="#/default/settings" className={'p-0'}>*/}
          {/*                        <i className={'fa fa-cogs mr-2'}/>*/}
          {/*                        Settings*/}
          {/*                    </NavLink>*/}
          {/*                </DropdownItem>*/}

          {/*                <DropdownItem hidden={!activeProfile || !['system-admin', 'airport-security-coordinator'].includes(activeProfile.role.name)}>*/}
          {/*                    <NavLink href="#/default/import" className={'p-0'}>*/}
          {/*                        <i className={'fa fa-upload mr-2'}/>*/}
          {/*                        Import*/}
          {/*                    </NavLink>*/}
          {/*                </DropdownItem>*/}

          {/*                <DropdownItem>*/}
          {/*                    <NavLink href="#/logout" className={'p-0'}>*/}
          {/*                        <i className={'fa fa-sign-out mr-2'}/>*/}
          {/*                        Logout*/}
          {/*                    </NavLink>*/}
          {/*                </DropdownItem>*/}
          {/*            </DropdownMenu>*/}
          {/*        </UncontrolledDropdown>*/}
          {/*    </Nav>*/}
          {/*</Collapse>*/}
        </Navbar>
      </div>
    );
  }
}
