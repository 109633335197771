import request from '../Request';

export default {
  create: createComment,
  list: listComments,
};

function createComment({ badgeApplicationUuid = '', comment }) {
  return request('authenticated-user', 'POST', `badge-applications/${badgeApplicationUuid}/comments`, {
    comment,
  });
}

function listComments({ user, badgeApplicationUuid = '' }) {
  return request(user || 'authenticated-user', 'GET', `badge-applications/${badgeApplicationUuid}/comments`);
}
