import request from '../Request';

export default {
  getDocSpringForms,
  saveDocSpringForm,
  getDocSpringFormsAssignments,
  findUsers,
  assignForm,
  getMyForms,
  generateDataRequestToken,
  handleDocSpringOnSaveCallback,
  getAvailableForms,
  cancelAssignment,
};

function getDocSpringForms(pageSize, page, sorted, filtered) {
  return request('authenticated-user', 'POST', 'forms/data-table', {
    pageSize,
    page,
    sorted,
    filtered,
  });
}

function getDocSpringFormsAssignments(pageSize, page, sorted, filtered) {
  return request('authenticated-user', 'POST', 'forms/assignments/data-table', {
    pageSize,
    page,
    sorted,
    filtered,
  });
}

function saveDocSpringForm(formInformation) {
  return request('authenticated-user', 'POST', 'forms/save', formInformation);
}

function findUsers({ criteria, signal }) {
  return request('authenticated-user', 'POST', `forms/find-users`, criteria, signal);
}

function assignForm(data) {
  return request('authenticated-user', 'POST', 'forms/assign', data);
}

function getMyForms(props) {
  return request('authenticated-user', 'POST', 'forms/my-forms', {}, props?.signal);
}

function generateDataRequestToken(formAssignmentUuid, isForTesting = false) {
  return request('authenticated-user', 'POST', `forms/generate-data-request-token`, {
    formAssignmentUuid,
    isForTesting,
  });
}

function handleDocSpringOnSaveCallback(submissionId) {
  return request('authenticated-user', 'POST', `forms/handle-ds-on-save-callback`, { submissionId });
}

function getAvailableForms(data) {
  let restrictToProfile = true;
  if (data && Object.hasOwn(data, 'restrictToProfile')) {
    restrictToProfile = data.restrictToProfile;
  }
  return request('authenticated-user', 'GET', `forms/get-available-forms?restrictToProfile=${restrictToProfile}`);
}

function cancelAssignment(formAssignmentUuid) {
  return request('authenticated-user', 'DELETE', `forms/assignment/${formAssignmentUuid}`);
}
