import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import DrivingEndorsementPicker from './components/DrivingEndorsementPicker';
import EscortEndorsementPicker from './components/EscortEndorsementPicker';
import { BadgeCreateContext } from '../../Badges/BadgesList/BadgeCreateModal';

export default function BadgeEndorsementsPicker({ shouldSkipDefaultSelection, displayAsResponsiveRow }) {
  const { data, setData } = useContext(BadgeCreateContext);
  const [drivingEndorsementConfig, setDrivingEndorsementConfig] = useState(null);
  const [escortEndorsementConfig, setEscortEndorsementConfig] = useState(null);
  const [drivingSelection, setDrivingSelection] = useState(null);
  const [escortSelection, setEscortSelection] = useState(null);

  useEffect(() => {
    setData({ ...data, driving: drivingSelection, escort: escortSelection });
  }, [drivingSelection, escortSelection]);

  useEffect(() => {
    setDrivingEndorsementConfig(
      data.badgeType?.config?.endorsements?.driving || data.badgeType?.original?.config?.endorsements?.driving || null
    );
    setEscortEndorsementConfig(
      data.badgeType?.config?.endorsements?.escort || data.badgeType?.original?.config?.endorsements?.escort || null
    );
    setDrivingSelection(data.driving);
    setEscortSelection(data.escort);
  }, [data.badgeType]);

  if (displayAsResponsiveRow) {
    return (
      <Row>
        <Col>
          <DrivingEndorsementPicker
            isDisabled={shouldSkipDefaultSelection}
            context={{
              drivingEndorsementConfig,
              drivingSelection,
              setDrivingSelection,
              escortEndorsementConfig,
              escortSelection,
              setEscortSelection,
              shouldSkipDefaultSelection,
              data,
            }}
          />
        </Col>
        <Col>
          <EscortEndorsementPicker
            isDisabled={shouldSkipDefaultSelection}
            context={{
              drivingEndorsementConfig,
              drivingSelection,
              setDrivingSelection,
              escortEndorsementConfig,
              escortSelection,
              setEscortSelection,
              shouldSkipDefaultSelection,
              data,
            }}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <DrivingEndorsementPicker
        context={{
          drivingEndorsementConfig,
          drivingSelection,
          setDrivingSelection,
          escortEndorsementConfig,
          escortSelection,
          setEscortSelection,
          shouldSkipDefaultSelection,
          data,
        }}
      />
      <EscortEndorsementPicker
        context={{
          drivingEndorsementConfig,
          drivingSelection,
          setDrivingSelection,
          escortEndorsementConfig,
          escortSelection,
          setEscortSelection,
          shouldSkipDefaultSelection,
          data,
        }}
      />
    </>
  );
}
