import React, { useContext, useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import clone from 'lodash/cloneDeep';
import { makeStyles } from '@material-ui/core/styles';
import collect_icon from '../../../../../images/BadgeApplication/process images_collect.svg';
import prepare_icon from '../../../../../images/BadgeApplication/process images_prepare.svg';
import transmit_icon from '../../../../../images/BadgeApplication/process images_transmit.svg';
import authorize_icon from '../../../../../images/BadgeApplication/process images_authorize.svg';
import issue_icon from '../../../../../images/BadgeApplication/process images_issue.svg';
import { isSmallScreen } from '../../../../../utils';
import { hasFeature } from '../../../../../shared/Feature';
import { AppContext } from '../../../../../App';

const useStyles = makeStyles(theme => ({
  wrapper: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(90deg, rgba(24,48,59,1) 0%, rgba(22,70,99,1) 75%, rgba(22,70,99,1) 100%)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  phaseContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  phase: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `0 ${theme.spacing(2)}px`,
  },
  phaseTitle: {
    marginBottom: theme.spacing(1),
    letterSpacing: 1,
    fontWeight: 500,
    position: 'relative',
  },
  phaseIcon: {
    height: 110,
    width: 110,
    border: '4px solid #003851',
    borderRadius: '50%',
    padding: 10,
    backgroundColor: '#ffffff',
  },
  phaseRequirements: {
    marginTop: 16,
  },
  requirementIcon: {
    marginRight: theme.spacing(1),
  },
  requirement: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  currentPhaseIndicator: {
    position: 'absolute',
    width: '100%',
    height: 3,
    backgroundColor: '#ffffff',
    borderRadius: 5,
  },
}));

function Phase({ index, phase, phases, classes }) {
  const { label, icon, iconPadding, requirements = [], current } = phase;

  return (
    <div className={classes.phase}>
      <div className={classes.phaseTitle}>
        {current ? (
          <div>
            {label}
            <div className={classes.currentPhaseIndicator}></div>
          </div>
        ) : (
          <div>{label}</div>
        )}
      </div>
      <img src={icon} alt={label} className={classes.phaseIcon} style={iconPadding ? { padding: iconPadding } : null} />
      <div className={classes.phaseRequirements}>
        {!isSmallScreen() &&
          requirements.map((r, i) => (
            <div key={i} className={classes.requirement}>
              <div>
                {r.isCompleted ? (
                  <i className={`fa fa-check ${classes.requirementIcon}`} aria-hidden="true"></i>
                ) : (
                  <i className={`fa fa-circle ${classes.requirementIcon}`} aria-hidden="true"></i>
                )}
              </div>
              <div>{r.label}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

const defaultPhaseData = [
  {
    label: 'COLLECT',
    status: '- not ready -',
    current: false,
    done: false,
    icon: collect_icon,
  },
  {
    label: 'PREPARE',
    status: '- not ready -',
    current: false,
    done: false,
    icon: prepare_icon,
    iconPadding: '10px 5px 10px 15px',
  },
  {
    label: 'TRANSMIT',
    status: '- not ready -',
    current: false,
    done: false,
    icon: transmit_icon,
  },
  {
    label: 'AUTHORIZE',
    status: '- not ready -',
    current: false,
    done: false,
    icon: authorize_icon,
  },
  {
    label: 'ISSUE',
    status: '- not ready -',
    current: false,
    done: false,
    icon: issue_icon,
  },
];

export default function Phases({ badgeApplication }) {
  const app = useContext(AppContext);
  const classes = useStyles();
  const [phases, setPhases] = useState(clone(defaultPhaseData));

  let paymentStep = null;
  if (hasFeature('payments', app?.airport?.config?.features || [])) {
    const { isPaid, paymentChoice } = badgeApplication;
    if (['applicant', 'signatory', 'in-person'].indexOf(paymentChoice) > -1) {
      paymentStep = {
        label: isPaid ? 'Payment Received' : 'Awaiting Payment',
        isCompleted: isPaid,
      };
    }
  }

  useEffect(() => {
    const newPhases = clone(defaultPhaseData);

    if (!badgeApplication) return null;

    // Iterate over the JSON config and create the steps to render
    const steps = [];
    Object.keys(badgeApplication.config).forEach(x => {
      const configEntry = badgeApplication.config[x];
      const milestone = configEntry.milestone.toLocaleUpperCase();
      if (x === 'applicationDocument') {
        const { documentTransactions } = configEntry;
        if (documentTransactions.applicant) {
          steps.push({
            milestone: milestone,
            label: 'Applicant has signed Application Document',
            isCompleted: documentTransactions.applicant.isComplete ? 1 : 0,
            isRequired: configEntry.isRequired ? 1 : 0,
            isEnabled: configEntry.isEnabled ? 1 : 0,
            order: configEntry.order || 1,
          });
        }
        if (documentTransactions.signatory) {
          steps.push({
            milestone: milestone,
            label: 'Signatory has signed Application Document',
            isCompleted: documentTransactions.signatory.isComplete ? 1 : 0,
            isRequired: configEntry.isRequired ? 1 : 0,
            isEnabled: configEntry.isEnabled ? 1 : 0,
            order: configEntry.order || 1,
          });
        }
      } else if (x === 'fingerprintAppointment') {
        steps.push({
          milestone: milestone,
          label: 'Fingerprints Appointment Scheduled',
          isCompleted: configEntry.isComplete ? 1 : 0,
          isRequired: configEntry.isRequired ? 1 : 0,
          isEnabled: configEntry.isEnabled ? 1 : 0,
          order: configEntry.order || 1,
        });
        steps.push({
          milestone: milestone,
          label: 'Fingerprints Received',
          isCompleted: configEntry.fingerprintsFileUuid ? 1 : 0,
          isRequired: configEntry.isRequired ? 1 : 0,
          isEnabled: configEntry.isEnabled ? 1 : 0,
          order: configEntry.order || 1,
        });
      } else {
        steps.push({
          milestone: milestone,
          label: configEntry.label,
          isCompleted: configEntry.isComplete ? 1 : 0,
          isRequired: configEntry.isRequired ? 1 : 0,
          isEnabled: configEntry.isEnabled ? 1 : 0,
          order: configEntry.order || 1,
        });
      }
    });
    const milestones = groupBy(steps, 'milestone');

    newPhases.forEach(phase => {
      const matchingMilestone = milestones[phase.label];
      if (matchingMilestone) {
        phase.requirements = matchingMilestone
          .filter(m => m.isEnabled)
          .sort((a, b) => a.order - b.order)
          .map(m => ({ label: m.label, isCompleted: m.isCompleted }));
      }
    });

    const currentPhase = badgeApplication.currentPhase.toLocaleUpperCase();
    if (currentPhase !== 'CANCELLED' && currentPhase !== 'TERMINATED' && currentPhase !== 'COMPLETED') {
      const completedPhaseIndex = newPhases.findIndex(p => p.label === currentPhase);
      newPhases[completedPhaseIndex].status = '- ready -';
      newPhases[completedPhaseIndex].current = true;

      if (paymentStep) {
        newPhases[completedPhaseIndex].requirements.push({
          ...paymentStep,
          milestone: newPhases[completedPhaseIndex].milestone,
        });
      }

      newPhases.forEach((phase, index) => {
        if (index < completedPhaseIndex) {
          phase.status = '- completed -';
          phase.done = true;
        } else if (index > completedPhaseIndex) {
          phase.status = '- not ready -';
        }
      });
    } else if (currentPhase === 'CANCELLED') {
      newPhases.forEach(p => (p.status = '- cancelled -'));
    } else if (currentPhase === 'TERMINATED') {
      newPhases.forEach(p => (p.status = '- terminated -'));
    } else if (currentPhase === 'COMPLETED') {
      newPhases.forEach(p => {
        p.status = '- completed -';
        p.done = true;
      });
    }

    setPhases(newPhases);
  }, [badgeApplication]);

  return (
    <div className={classes.wrapper}>
      <h2 style={{ position: 'relative', top: -10, fontWeight: 600, letterSpacing: 1 }}>Process Overview</h2>
      <div className={classes.phaseContainer}>
        {phases.map((phase, index) => (
          <Phase key={index} index={index + 1} phase={phase} phases={phases} classes={classes} />
        ))}
      </div>
    </div>
  );
}
