import request from '../Request';

export default {
  changePassword,
  changeEmail,
  removeMobileEnrollment,
  removeMobileEnrollmentForUser,
};

function changePassword({ user, newPassword, oldPassword }) {
  return request(user, 'PUT', `settings/${user.uuid}/change-password`, {
    newPassword,
    oldPassword,
  });
}

function changeEmail({ user, newEmail, oldEmail }) {
  return request(user, 'PUT', `settings/${user.uuid}/change-email`, {
    newEmail,
    oldEmail,
  });
}

function removeMobileEnrollment({ user }) {
  return request(user, 'PUT', `settings/${user.uuid}/remove-mobile-enrollment`);
}

function removeMobileEnrollmentForUser({ user, userUUID }) {
  return request(user, 'PUT', `settings/${userUUID}/remove-mobile-enrollment`);
}
