import React, { useContext, useEffect, useState } from 'react';
import trim from 'lodash/trim';
import { Alert, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AppContext } from '../../../../App';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import { handleError, validEmailAddressRegex } from '../../../../utils';
import peopleApi from '../../../../ajax/People/peopleApi';

const defaultFormData = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
};

export default function CreateNewPersonForBadgeApplicationModal({ isOpen, onClose }) {
  const app = useContext(AppContext);
  const isCurrentUserSignatory = app?.user?.activeProfile?.role?.name === 'authorized-signatory';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ ...defaultFormData });
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasConfirmEmailError, setHasConfirmEmailError] = useState(false);

  const onModalClose = () => {
    setIsModalOpen(false);
    onClose({ user: null });
  };

  const onSaveClicked = () => {
    const { api } = app;
    api.toggleLoading(true);
    const individual = {
      first_name: trim(formData.firstName),
      last_name: trim(formData.lastName),
      email: trim(formData.email),
      mobile: trim(formData.mobile),
    };
    peopleApi
      .create({
        individual,
        shouldReturnMatchIfExists: true,
        shouldStopIfPotentialDuplicatesFound: isCurrentUserSignatory,
      })

      .then(({ success, message = '', user }) => {
        if (success) {
          setIsModalOpen(false);
          return onClose({ user });
        }

        if (message.indexOf('duplicate') > -1) {
          api.confirmMessage(
            'Potential Duplicate Found',
            <div>
              <Alert color="warning">
                <big>
                  Unable to guarantee this won't create an individual that already exists.
                  <br />
                  To resolve this, contact your Badge Office with all the details of the badge application you need
                  created.
                </big>
              </Alert>
            </div>,
            null,
            false
          );
        } else {
          handleError({ message: 'Unable to create individual. Contact the Badge Office to resolve.' });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => api.toggleLoading(false));
  };

  const checkEmails = (email_1, email_2) => {
    setHasConfirmEmailError(email_1 && email_2 && email_1 !== email_2);
  };

  const isSaveDisabled = () => {
    return (
      hasEmailError ||
      hasConfirmEmailError ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !confirmedEmail
    );
  };

  const updateFieldValue = field => event => {
    const newData = { ...formData };
    const regex = /^[A-Za-z\s-]*$/;
    if (regex.test(event?.target?.value)) {
      newData[field] = event?.target?.value;
    }
    setFormData(newData);
  };

  const checkEmailForErrors = email => {
    if (!email) {
      return setHasEmailError(false);
    }

    setHasEmailError(!validEmailAddressRegex.test(email));
  };

  useEffect(() => {
    if (!isOpen) return;
    setFormData({ ...defaultFormData });
    setConfirmedEmail('');
    setHasEmailError(false);
    setHasConfirmEmailError(false);
    setIsModalOpen(true);
  }, [isOpen]);

  if (!isModalOpen) return null;

  return (
    <Modal isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader label="New Individual" onClick={onModalClose} />
      <ModalBody>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label className="form-label-with-asterisk">
                First Name <span className="required-asterisk">*</span>
              </Label>
              <Input value={formData.firstName} onChange={updateFieldValue('firstName')} />
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label className="form-label-with-asterisk">
                Last Name <span className="required-asterisk">*</span>
              </Label>
              <Input value={formData.lastName} onChange={updateFieldValue('lastName')} />
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label className="form-label-with-asterisk">
                Email <span className="required-asterisk">*</span>
              </Label>
              <Input
                type="email"
                value={formData.email}
                onChange={event => {
                  setFormData({ ...formData, email: event?.target?.value });
                  checkEmails(event?.target?.value, confirmedEmail);
                  checkEmailForErrors(event?.target?.value);
                }}
                invalid={hasEmailError}
              />
              <FormFeedback>Invalid email address</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label className="form-label-with-asterisk">
                Confirm Email <span className="required-asterisk">*</span>
                <div style={{ fontSize: 'smaller', opacity: 0.7 }}>
                  <i>Type email again to confirm spelling. Paste is disabled.</i>
                </div>
              </Label>
              <Input
                type="email"
                value={confirmedEmail}
                onChange={event => {
                  checkEmails(formData.email, event?.target?.value);
                  setConfirmedEmail(event?.target?.value);
                }}
                onPaste={event => event.preventDefault()}
                invalid={!!hasConfirmEmailError}
              />
              <FormFeedback>Emails must match</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          onClose={onModalClose}
          saveLabel="Create"
          onSave={onSaveClicked}
          saveDisabled={isSaveDisabled()}
        />
      </ModalFooter>
    </Modal>
  );
}
