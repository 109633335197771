import React from 'react';
import { Alert, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomModalHeader from './components/CustomModalHeader/CustomModalHeader';

export default function VersionOutOfDateModal({ isOpen, onClose, version }) {
  return (
    <Modal size="md" isOpen={isOpen} toggle={onClose}>
      <CustomModalHeader toggle={onClose}>AirBadge Version Update</CustomModalHeader>
      <ModalBody>
        <Alert color="info">
          <div style={{ textAlign: 'center', width: '100%', marginBottom: 16 }}>
            <big>There is a new version of AirBadge available!</big>
          </div>
          <div style={{ marginBottom: 16 }}>
            AirBadge version <strong>{version}</strong> was just released. Click the button below to reload your browser
            tab.
          </div>
          <i>
            If this popup doesn't go away after a reload, you may need to close the browser tab and reopen AirBadge in a
            new tab.
          </i>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <div>
            <Button
              size="lg"
              color="primary"
              onClick={() => window.location.reload(true)}
              style={{ padding: '8px 50px' }}
            >
              <i className="fa-solid fa-rotate mr-2"></i>Reload AirBadge
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}
