import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { image, text } from '@pdfme/schemas';
import { generate } from '@pdfme/generator';
import Cropper from 'cropperjs';
import AirBadgeModal, {
  MODAL_SIZE_LARGE,
  MODAL_SIZE_MEDIUM,
} from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { handleError } from '../../../../utils';
import { colors } from '../../../../App';
import FontAwesomeIcon from '../../../../shared/components/FontAwesome/FontAwesomeIcon';

function ProgressIndicator({ isInitialized }) {
  if (isInitialized) return null;

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
      }}
    >
      <CircularProgress color="primary" size={50} />
    </div>
  );
}

function ToolbarButton({ icon, label, onClick }) {
  return (
    <Button color="primary" title={label} onClick={onClick}>
      <FontAwesomeIcon color="white" className={`${icon} mr-2`} style={{ fontSize: '1rem', marginTop: 2 }} />
    </Button>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 0.5,
    marginRight: theme.spacing(2),
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 0.5,
    marginLeft: theme.spacing(2),
  },
}));

export default function WebcamCaptureModal({
  isOpen,
  onClose,
  badgeTemplate,
  saveButtonLabel,
  isCaptureOnlyMode = false,
  title = 'Take Picture',
}) {
  const classes = useStyles();
  const [webcamStream, setWebcamStream] = useState(null);
  const [hasImage, setHasImage] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [webcamDeviceId, setWebcamDeviceId] = useState(null);
  const [isSelectWebcamModalOpen, setIsSelectWebcamModalOpen] = useState(false);
  const [webcams, setWebcams] = useState([]);
  const webcamRef = useRef(null);

  const onCloseModal = () => {
    setWebcams([]);
    setWebcamDeviceId(null);
    stopWebcam();
    setHasImage(false);
    setIsInitialized(false);
    onClose();
  };

  // Check if webcam access is supported.
  const hasGetUserMedia = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  const onSelectWebcamModalClose = deviceId => {
    setIsSelectWebcamModalOpen(false);
    if (deviceId) startWebcam(deviceId);
    else setWebcamDeviceId(null);
  };

  const startWebcam = async deviceId => {
    setIsInitialized(false);
    setHasImage(false);
    cropper && cropper.destroy();
    setCropper(null);

    if (hasGetUserMedia()) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const webcams = devices.filter(device => device.kind === 'videoinput');
      if (webcams.length > 1 && !deviceId) {
        setWebcams(webcams);
        return setIsSelectWebcamModalOpen(true);
      } else if (!deviceId) {
        deviceId = webcams[0].deviceId;
        setWebcamDeviceId(deviceId);
        return startWebcam(deviceId);
      }

      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: { exact: deviceId },
            aspectRatio: 3 / 4,
            width: { ideal: 480 },
            height: { ideal: 640 },
          },
        })
        .then(function (stream) {
          const webcamElement = document.getElementById('webcam');
          webcamElement.srcObject = stream;
          setWebcamStream(stream);
          setIsInitialized(true);
        })
        .catch(error => handleError({ error }));
    }
  };

  const stopWebcam = () => {
    if (webcamStream) {
      webcamStream.getTracks().forEach(track => track.stop());
      webcamStream.getVideoTracks().forEach(track => track.stop());
      setWebcamStream(null);
      const webcamElement = document.getElementById('webcam');
      if (webcamElement) {
        webcamElement.srcObject = null;
      }
    }
  };

  const onCropImageClicked = async () => {
    const croppedCanvas = cropper.getCroppedCanvas();
    const badgeImageElement = document.getElementById('badgeImage');
    badgeImageElement.src = croppedCanvas.toDataURL('image/jpeg');
    if (badgeTemplate) {
      badgeTemplate.sampledata[0].faceImage = document.getElementById('badgeImage').src;
      const pdf = await generate({
        template: badgeTemplate,
        plugins: { text, image },
        inputs: badgeTemplate.sampledata,
      });
      const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      document.getElementById('badgeMock').src = URL.createObjectURL(blob);
    }
  };

  const captureImage = async () => {
    // If webcam supported, add event listener to the capture button
    if (hasGetUserMedia()) {
      const webcamElement = document.getElementById('webcam');
      const canvasElement = document.getElementById('canvas');
      const cropperCanvas = document.getElementById('cropImage');
      const badgeImageElement = document.getElementById('badgeImage');
      const context = canvasElement.getContext('2d');
      // Draw the video frame to the canvas
      context.drawImage(webcamElement, 0, 0, canvasElement.width, canvasElement.height);
      // Convert the canvas to an image and display it
      badgeImageElement.src = canvasElement.toDataURL('image/jpeg');
      if (badgeTemplate) {
        badgeTemplate.sampledata[0].faceImage = badgeImageElement.src;
        const pdf = await generate({
          template: badgeTemplate,
          plugins: { text, image },
          inputs: badgeTemplate.sampledata,
        });
        const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
        document.getElementById('badgeMock').src = URL.createObjectURL(blob);
      }

      cropperCanvas.src = canvasElement.toDataURL('image/jpeg');
      const cropper = new Cropper(cropperCanvas, {
        viewMode: 1,
        autoCropArea: 0.5,
        zoomOnWheel: false,
        aspectRatio: 3 / 4,
      });
      setCropper(cropper);
      setHasImage(true);
      stopWebcam();
      if (isCaptureOnlyMode) {
        onSaveImageClicked();
      }
    } else {
      handleError({ message: 'Webcam is not enabled' });
    }
  };

  const onSaveImageClicked = () => {
    const badgeImageElement = document.getElementById('badgeImage');
    stopWebcam();
    setHasImage(false);
    setIsInitialized(false);
    onClose({ imageData: badgeImageElement.src });
  };

  useEffect(() => {
    if (isOpen) {
      const domTimer = setTimeout(() => {
        if (webcamRef.current) {
          startWebcam();
        }
      }, 0);

      return () => {
        clearTimeout(domTimer);
        stopWebcam();
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      id="webcamCaptureModal"
      size={MODAL_SIZE_LARGE}
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      saveLabel={saveButtonLabel || 'Add Image To Badge'}
      saveDisabled={!isCaptureOnlyMode && !hasImage}
      onSave={isCaptureOnlyMode ? captureImage : onSaveImageClicked}
      style={{ maxWidth: 875 }}
      leftSideExtra={
        hasImage ? (
          <Button id="retry" color="warning" onClick={() => startWebcam(webcamDeviceId)}>
            <i className="fa-solid fa-camera mr-2"></i>Start Over
          </Button>
        ) : null
      }
    >
      <ProgressIndicator isInitialized={isInitialized} />
      <div
        style={{
          display: hasImage ? 'none' : 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <video
          id="webcam"
          ref={webcamRef}
          autoPlay
          playsInline
          style={{
            display: hasImage ? 'none' : 'inline-block',
            objectFit: 'cover',
            width: 480,
            height: 640,
          }}
        ></video>
        {!isCaptureOnlyMode && (
          <Button
            id="capture"
            className="mt-4"
            color="primary"
            size="lg"
            onClick={captureImage}
            disabled={!isInitialized}
          >
            <i className="fa-solid fa-camera mr-2"></i>Capture Image
          </Button>
        )}
      </div>
      <div className={classes.wrapper} style={!hasImage ? { display: 'none' } : undefined}>
        <div className={classes.leftColumn}>
          <img
            id="cropImage"
            src=""
            alt="Cropper Canvas"
            style={{
              width: 360,
              height: 480,
              border: `3px solid ${colors.secondary}`,
              display: hasImage ? 'inline-block' : 'none',
            }}
          />
        </div>
        <div className={classes.rightColumn}>
          <img
            id="badgeImage"
            src=""
            alt="Badge Image"
            style={{
              width: 360,
              height: 480,
              border: `3px dashed ${colors.success}`,
              display: badgeTemplate ? 'none' : 'inline-block',
            }}
          />
          <iframe
            id="badgeMock"
            style={{ height: 600, width: 400, display: badgeTemplate ? 'inline-block' : 'none' }}
          ></iframe>
        </div>
      </div>
      <div className={classes.wrapper} style={!hasImage ? { display: 'none' } : { marginBottom: 24 }}>
        <div className={classes.leftColumn}>
          {isInitialized && !hasImage && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
              <Button id="capture" color="primary" size="lg" onClick={captureImage} disabled={!isInitialized}>
                <i className="fa-solid fa-camera mr-2"></i>Capture Image
              </Button>
            </div>
          )}
          {hasImage && (
            <div
              style={{
                width: '100%',
                marginTop: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 16,
                }}
              >
                <ButtonGroup size="sm" className="mr-2">
                  <ToolbarButton
                    icon="fa-solid fa-magnifying-glass-plus"
                    label="Zoom In"
                    onClick={() => cropper.zoom(0.1)}
                  />
                  <ToolbarButton
                    icon="fa-solid fa-magnifying-glass-minus"
                    label="Zoom Out"
                    onClick={() => cropper.zoom(-0.1)}
                  />
                </ButtonGroup>

                <ButtonGroup size="sm" className="mr-2">
                  <ToolbarButton
                    icon="fa-solid fa-arrow-rotate-left"
                    label="Rotate Left"
                    onClick={() => cropper.rotate(-45)}
                  />
                  <ToolbarButton
                    icon="fa-solid fa-arrow-rotate-right"
                    label="Rotate Right"
                    onClick={() => cropper.rotate(45)}
                  />
                </ButtonGroup>

                <ButtonGroup size="sm">
                  <ToolbarButton icon="fa-solid fa-arrow-left" label="Move Left" onClick={() => cropper.move(-10, 0)} />
                  <ToolbarButton
                    icon="fa-solid fa-arrow-right"
                    label="Move Right"
                    onClick={() => cropper.move(10, 0)}
                  />
                  <ToolbarButton icon="fa-solid fa-arrow-up" label="Move Up" onClick={() => cropper.move(0, -10)} />
                  <ToolbarButton icon="fa-solid fa-arrow-down" label="Move Down" onClick={() => cropper.move(0, 10)} />
                </ButtonGroup>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <Button color="primary" onClick={() => cropper.reset()}>
                  <i className="fa-solid fa-clock-rotate-left mr-2"></i>
                  Reset
                </Button>

                <Button color="primary" onClick={onCropImageClicked}>
                  <i className="fa-solid fa-crop-simple mr-2"></i>
                  Crop Image
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={classes.rightColumn}>&nbsp;</div>
      </div>
      <canvas id="canvas" width="360" height="480" style={{ display: 'none' }}></canvas>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Choose a Webcam"
        isOpen={isSelectWebcamModalOpen}
        onClose={() => onSelectWebcamModalClose()}
        saveLabel="Use Chosen Webcam"
        saveDisabled={!webcamDeviceId}
        onSave={() => onSelectWebcamModalClose(webcamDeviceId)}
      >
        <RadioGroup
          aria-label="webcam choice"
          name="webcam-choice"
          value={webcamDeviceId}
          onChange={event => setWebcamDeviceId(event.target.value)}
        >
          {webcams.map((w, i) => (
            <FormControlLabel
              key={w.deviceId}
              value={w.deviceId}
              control={<Radio color="primary" />}
              label={w.label || `Webcam ${i + 1}`}
            />
          ))}
        </RadioGroup>
      </AirBadgeModal>
    </AirBadgeModal>
  );
}
