import request from '../Request';

export default function AccountLoginRequest(email, password) {
  const method = 'POST';

  const url = 'account/login';

  const data = {
    email,
    password,
  };

  return request(null, method, url, data);
}
