import request from '../Request';

export default {
  search,
  getSingle,
};

function search({ criteria, signal }) {
  return request('authenticated-user', 'POST', 'authorized-signatories/search', { ...criteria }, signal);
}

function getSingle({ profileUuid }) {
  return request('authenticated-user', 'GET', `authorized-signatories/${profileUuid}`);
}
