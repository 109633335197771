import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { AppContext } from '../../../../../../App';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import request from '../../../../../../ajax/Request';
import { handleError } from '../../../../../../utils';

export default function FlagBadgeModal({ isOpen, onClose, onSave, badge }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');

  const cleanup = () => {
    setIsModalOpen(false);
    setComment('');
  };

  const onModalClose = () => {
    cleanup();
    onClose();
  };

  const onSaveChanges = async () => {
    let newFlag = 1;
    let errorMsg = `Unable to add flag`;
    if (badge.flagged) {
      newFlag = 0;
      errorMsg = `Flag could not be removed`;
    }
    try {
      app.api.toggleLoading(true);
      const response = await request(app.user, 'PATCH', `badge/${badge.uuid}`, { flagged: newFlag, comment });
      if (response.success) {
        onSave({ flagged: newFlag, ...response });
        cleanup();
      }
    } catch (error) {
      handleError({ error, message: errorMsg });
    } finally {
      app.api.toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen || !badge) return;
    setIsModalOpen(true);
  }, [isOpen, badge]);

  if (!isOpen) return null;

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>
        {badge.flagged ? 'Remove Flag from Badge' : 'Add Flag to Badge'}
      </CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label className="required">Comment</Label>
              <Input required type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel={badge.flagged ? 'Remove Flag' : 'Add Flag'}
          saveDisabled={!comment}
          onSave={onSaveChanges}
        />
      </ModalFooter>
    </Modal>
  );
}
