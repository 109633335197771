import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import NativeSelect from '../../../../../shared/components/NativeSelect/NativeSelect';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';

const feetOptions = [
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
];

const inchesOptions = [
  { label: '0', value: '00' },
  { label: '1', value: '01' },
  { label: '2', value: '02' },
  { label: '3', value: '03' },
  { label: '4', value: '04' },
  { label: '5', value: '05' },
  { label: '6', value: '06' },
  { label: '7', value: '07' },
  { label: '8', value: '08' },
  { label: '9', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
];

export default function HeightQuestion({ isHidden, value, onBack, onNext, isSecuredBadge }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [feet, setFeet] = useState(null);
  const [inches, setInches] = useState(null);
  const [isSkipped, setIsSkipped] = useState(false);
  const [includeBlankFeet, setIncludeBlankFeet] = useState(true);
  const [includeBlankInches, setIncludeBlankInches] = useState(true);

  const isNextHidden = () => {
    if (isSkipped) return false;
    return !feet || !inches;
  };

  const onNextClicked = () => {
    if (isSkipped) {
      onNext('000');
    } else {
      onNext(`${feet.value}${inches.value}`);
    }
  };

  if (isHidden) return null;

  return (
    <>
      <Row>
        <Col>
          <FormLabel>
            <Typography variant="h6">
              {t('question4')}
              {isSecuredBadge ? ' *' : ' **'}
            </Typography>
          </FormLabel>
        </Col>
      </Row>

      <Row>
        <Col xs="6" sm="4" lg="2">
          <FormLabel>
            <Typography variant="subtitle2">{t('question4_feet')}</Typography>
          </FormLabel>
          <FormGroup>
            <NativeSelect
              id="feetSelect"
              name="feetSelect"
              options={feetOptions}
              value={feet}
              onChange={selectedOption => {
                setIncludeBlankFeet(false);
                setFeet(selectedOption);
              }}
              disabled={isSkipped}
              includeBlank={includeBlankFeet}
            />
          </FormGroup>
        </Col>
        <Col xs="6" sm="4" lg="2">
          <FormLabel>
            <Typography variant="subtitle2">{t('question4_inches')}</Typography>
          </FormLabel>
          <FormGroup>
            <NativeSelect
              id="inchesSelect"
              name="inchesSelect"
              options={inchesOptions}
              value={inches}
              onChange={selectedOption => {
                setIncludeBlankInches(false);
                setInches(selectedOption);
              }}
              disabled={isSkipped}
              includeBlank={includeBlankInches}
            />
          </FormGroup>
        </Col>
      </Row>

      {!isSecuredBadge && (
        <Row>
          <Col>
            <MuiFormGroup row>
              <MuiFormControlLabel
                control={<Switch color="primary" />}
                label={t('question4_skip')}
                checked={isSkipped}
                onChange={({ target }) => {
                  setIsSkipped(target.checked);
                  setIncludeBlankFeet(true);
                  setIncludeBlankInches(true);
                  setFeet(null);
                  setInches(null);
                }}
              />
            </MuiFormGroup>
          </Col>
        </Row>
      )}

      <BackButton onClick={onBack} />
      <NextButton isDisabled={isNextHidden()} onClick={onNextClicked} />
    </>
  );
}
