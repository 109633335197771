import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import moment from 'moment-timezone';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import { AppContext } from '../../../../../../App';
import request from '../../../../../../ajax/Request';
import badgeApi from '../../../../../../ajax/Badge/badgeApi';
import AutoCompleteDropdown from '../../../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import { handleError, notify } from '../../../../../../utils';
import trim from 'lodash/trim';
import ReadOnlyFormData from '../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import StaggeredDatePicker from '../../../../../../shared/components/DatePickers/StaggeredDatePicker';

const BADGE_ID = 'Badge ID';
const ACTIVE_DATES = 'Active Dates';
const CARD_NUMBER = 'Card Number';
const GROUP = 'Group';

export default function EditBadgeDataModal({ isOpen, onClose, onSave, badge, title = 'No title' }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBadgeId, setNewBadgeId] = useState('');
  const [newCardNumber, setNewCardNumber] = useState('');
  const [newGroup, setNewGroup] = useState(null);
  const [newGroupManualEntry, setNewGroupManualEntry] = useState('');
  const [newDates, setNewDates] = useState([moment(), moment()]);
  const [comment, setComment] = useState('');
  const [groups, setGroups] = useState([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);

  const cleanup = () => {
    setNewBadgeId('');
    setNewCardNumber('');
    setNewGroup(null);
    setNewDates([null, null]);
    setComment('');
    setGroups([]);
    setNewGroupManualEntry('');
    setIsGroupsLoading(false);
  };

  const onModalClose = () => {
    onClose();
    cleanup();
  };

  const onSaveChanges = async () => {
    let payload = {};
    switch (title) {
      case BADGE_ID: {
        payload = { newBadgeId: trim(newBadgeId) };
        break;
      }
      case ACTIVE_DATES: {
        const effectiveDate = moment.tz(newDates[0], app.timezone).format('YYYY-MM-DD HH:mm:ss');
        const expirationDate = moment.tz(newDates[1], app.timezone).format('YYYY-MM-DD HH:mm:ss');
        payload = { newDates: { effectiveDate, expirationDate } };
        break;
      }
      case CARD_NUMBER: {
        payload = { newCardNumber: trim(newCardNumber) };
        break;
      }
      case GROUP: {
        if (newGroupManualEntry) {
          payload = { newGroup: trim(newGroupManualEntry) };
        } else {
          payload = { newGroup: newGroup.value };
        }
        break;
      }
      default: {
        // Do nothing
      }
    }

    try {
      app.api.toggleLoading(true);
      const response = await request(app.user, 'PATCH', `badge/${badge.uuid}`, { ...payload, comment });
      if (response.success) {
        onSave(response);
        cleanup();
      }
    } catch (error) {
      handleError({ error, message: `${title} could not be changed` });
    } finally {
      app.api.toggleLoading(false);
    }
  };

  const isSaveDisabled = () => {
    switch (title) {
      case BADGE_ID: {
        return !!newBadgeId === false;
      }
      case ACTIVE_DATES: {
        if (!newDates[0] || !newDates[1]) return true;
        const effectiveDate = moment.tz(newDates[0], app.timezone);
        const expirationDate = moment.tz(newDates[1], app.timezone);
        return !effectiveDate.isBefore(expirationDate);
      }
      case CARD_NUMBER: {
        return !!newCardNumber === false;
      }
      case GROUP: {
        return !!newGroup === false && !!newGroupManualEntry === false;
      }
      default: {
        return true;
      }
    }
  };

  const loadGroups = () => {
    setIsGroupsLoading(true);
    badgeApi
      .getGroups()
      .then(({ success, results = [] }) => {
        if (success) setGroups(results.map(r => ({ label: r, value: r })));
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsGroupsLoading(false));
  };

  useEffect(() => {
    if (!isOpen || !badge) return;
    setNewDates([null, null]);
    setIsModalOpen(true);
    if (title === 'Group') loadGroups();
  }, [isOpen, badge, title]);

  if (!isOpen) return null;

  let Form = <></>;
  switch (title) {
    case BADGE_ID: {
      Form = (
        <>
          <Row>
            <Col>
              <ReadOnlyFormData
                skipTextTransform
                faded
                label="Current Badge ID"
                value={<i>{badge.badgeId || '-'}</i>}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <FormGroup>
                <Label className="required">New Badge ID</Label>
                <Input value={newBadgeId} onChange={({ target }) => setNewBadgeId(target.value)} />
              </FormGroup>
            </Col>
          </Row>
        </>
      );
      break;
    }
    case ACTIVE_DATES: {
      Form = (
        <>
          <Row>
            <Col>
              <ReadOnlyFormData
                skipTextTransform
                faded
                label="Current Active Dates"
                value={<i>{badge.badgeDates}</i>}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <FormGroup>
                <Label className="required">New Effective Date</Label>
                {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
                <StaggeredDatePicker
                  startingYear={new Date().getFullYear() - 9}
                  endingYear={new Date().getFullYear() + 10}
                  onValidDate={({ year, month, day }) => {
                    setNewDates([`${year}-${month}-${day}`, newDates[1]]);
                    if (!newDates[1]) return;
                    const effectiveDate = moment.tz(`${year}-${month}-${day}`, app.timezone);
                    const expirationDate = moment.tz(newDates[1], app.timezone);
                    if (!effectiveDate.isBefore(expirationDate)) {
                      notify({
                        message: 'The effective date must be before the expiration date',
                        severity: 'warning',
                      });
                    }
                  }}
                  onInvalidDate={() => setNewDates([null, newDates[1]])}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="required">New Expiration Date</Label>
                {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
                <StaggeredDatePicker
                  startingYear={new Date().getFullYear() - 2}
                  endingYear={new Date().getFullYear() + 10}
                  onValidDate={({ year, month, day }) => {
                    setNewDates([newDates[0], `${year}-${month}-${day}`]);
                    if (!newDates[0]) return;
                    const effectiveDate = moment.tz(newDates[0], app.timezone);
                    const expirationDate = moment.tz(`${year}-${month}-${day}`, app.timezone);
                    if (!effectiveDate.isBefore(expirationDate)) {
                      notify({
                        message: 'The effective date must be before the expiration date',
                        severity: 'warning',
                      });
                    }
                  }}
                  onInvalidDate={() => setNewDates([newDates[0], null])}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      );
      break;
    }
    case CARD_NUMBER: {
      Form = (
        <>
          <Row>
            <Col>
              <ReadOnlyFormData
                skipTextTransform
                faded
                label="Current Card Number"
                value={<i>{badge.cardNumber || '-'}</i>}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <FormGroup>
                <Label className="required">New Card Number</Label>
                <Input value={newCardNumber} onChange={({ target }) => setNewCardNumber(target.value)} />
              </FormGroup>
            </Col>
          </Row>
        </>
      );
      break;
    }
    case GROUP: {
      Form = (
        <>
          <Row>
            <Col>
              <ReadOnlyFormData skipTextTransform faded label="Current Group" value={<i>{badge.group || '-'}</i>} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <FormGroup>
                <Label className="required">New Group</Label>
                <AutoCompleteDropdown
                  id="newGroup"
                  value={newGroup}
                  onValueSelected={x => setNewGroup(x)}
                  options={groups}
                  placeholder=""
                  size="small"
                  isLoading={isGroupsLoading}
                  getOptionLabel={option => option.label || 'error'}
                  onInputChange={({ target: { value } }) => setNewGroupManualEntry(value)}
                  freeSolo
                  disableClearable
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      );
      break;
    }
    default: {
      Form = <></>;
    }
  }

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>Change {title}</CustomModalHeader>
      <ModalBody>{Form}</ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onModalClose}
          saveLabel="Save"
          onSave={onSaveChanges}
          saveDisabled={isSaveDisabled()}
        />
      </ModalFooter>
    </Modal>
  );
}
