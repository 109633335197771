import request from '../Request';

export default {
  create: createCompany,
  update: updateCompany,
  list: listCompanies,
  toggleActive: toggleActiveStatus,
  getSimpleList,
};

function createCompany(data) {
  return request('authenticated-user', 'POST', `company`, data);
}

function updateCompany({ uuid = '', ...rest }) {
  return request('authenticated-user', 'PATCH', `company/${uuid}`, rest);
}

function getSimpleList() {
  return request('authenticated-user', 'GET', `company`);
}

function listCompanies(user, pageSize = 50, page = 0, sorted = [], filtered = {}) {
  return request('authenticated-user', 'POST', 'company/custom/data-table', {
    pageSize,
    page,
    sorted,
    filtered,
  });
}

function toggleActiveStatus({ uuid = '', active }) {
  return request('authenticated-user', 'POST', `company/toggle-active/${uuid}`, { active });
}
