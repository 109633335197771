import React from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Button } from 'reactstrap';
import Check from '@material-ui/icons/Check';
import { colors } from '../../../App';
import { isSmallScreen } from '../../../utils';

export default function HorizontalLinearStepper({
  steps,
  stepLabels,
  validatedSteps,
  finishCallback,
  loop,
  saveButtonLabel,
  onDelete,
}) {
  if (!steps || !stepLabels) {
    return null;
  }

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (loop) {
        setActiveStep(0);
      } else {
        setActiveStep(steps.length - 1);
      }
      if (finishCallback) {
        finishCallback();
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const CustomStepIcon = props => {
    if (props.completed) return <Check color="primary" />;

    return (
      <div
        style={{
          color: 'white',
          backgroundColor: props.active ? colors.primary : 'lightgrey',
          borderRadius: '50%',
          width: 28,
          height: 28,
          textAlign: 'center',
          paddingTop: 3,
          paddingRight: props.icon === 1 ? 1 : 0,
        }}
      >
        {props.icon}
      </div>
    );
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {stepLabels.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel StepIconComponent={CustomStepIcon}>{isSmallScreen() ? '' : label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {steps[activeStep]}

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
        <Button
          color="secondary"
          style={{ opacity: activeStep === 0 ? 0 : 1, width: 100 }}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          <i className="fa-solid fa-chevron-left mr-2"></i>
          Back
        </Button>

        {(onDelete && (
          <Button onClick={onDelete} color="danger">
            Cancel Appointment
          </Button>
        )) || <div style={{ opacity: 0 }}>&nbsp;</div>}

        <Button
          onClick={handleNext}
          color="primary"
          disabled={validatedSteps && !validatedSteps[activeStep]}
          style={{ minWidth: 100 }}
        >
          {activeStep === stepLabels.length - 1 ? (
            saveButtonLabel ? (
              saveButtonLabel
            ) : (
              'Create'
            )
          ) : (
            <>
              Next
              <i className="fa-solid fa-chevron-right ml-2"></i>
            </>
          )}
        </Button>
      </Box>
    </Box>
  );
}
