import React, { useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import badgeApplicationCommentApi from '../../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';
import { handleError } from '../../../../../utils';

export default function BadgeApplicationComments({
  comments = [],
  reloadComments,
  user,
  badgeApplicationUuid,
  appFunctions,
  appState,
}) {
  const isTsaAgent = user?.activeProfile?.role?.name === 'tsa-agent';
  const dateFormat = 'MM/DD/YYYY h:mm a';
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [newComment, setNewComment] = useState('');
  const timezone = appState.timezone;

  const onCommentModalClose = () => {
    setIsCommentModalOpen(false);
    setNewComment('');
  };

  const saveComment = async () => {
    appFunctions.toggleLoading(true);
    const response = await badgeApplicationCommentApi.create({ user, badgeApplicationUuid, comment: newComment });
    appFunctions.toggleLoading(false);
    if (!response.success) {
      return handleError({ message: 'Unable to add comment' });
    }
    onCommentModalClose();
    if (document.getElementById('commentsContainer')) {
      document.getElementById('commentsContainer').scrollTo({ top: 0 });
    }
    return reloadComments();
  };

  return (
    <>
      {!isTsaAgent && (
        <div>
          <Button
            style={{ width: 120, marginBottom: 16 }}
            color="primary"
            onClick={() => setIsCommentModalOpen(true)}
            size="sm"
          >
            Add Comment
          </Button>
        </div>
      )}

      <Card>
        <CardBody>
          <div id="commentsContainer" className="comments-container" style={{ maxHeight: 264, minHeight: 264 }}>
            {comments.map(x => (
              <div key={x.badgeApplicationCommentUuid}>
                <div>
                  <small className="text-muted">
                    <strong className="mr-2">{x.author}</strong>
                    {moment.tz(x.created, timezone).format(dateFormat)}
                  </small>
                </div>
                <p>
                  {x.content?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      <span>{line}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>

      <Modal isOpen={isCommentModalOpen} toggle={onCommentModalClose} size="md">
        <CustomModalHeader toggle={onCommentModalClose}>Add Badge Application Comment</CustomModalHeader>
        <ModalBody>
          <Input required type="textarea" value={newComment} onChange={event => setNewComment(event.target.value)} />
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Cancel"
            onClose={onCommentModalClose}
            saveLabel="Save"
            onSave={saveComment}
            saveDisabled={!newComment}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
