import request from '../Request';

export default function BadgeCommentCreateRequest(user, badgeComment) {
  const method = 'POST';

  const url = 'badge-comments';

  const data = {
    badge_comment: badgeComment,
  };

  return request(user, method, url, data);
}
