import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppContext } from '../../../../App';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import request from '../../../../ajax/Request';
import BookingGetAvailableAppointmentTypesRequest from '../../../../ajax/Booking/BookingGetAvailableAppointmentTypesRequest';
import StaffGetAllRequest from '../../../../ajax/Staff/StaffGetAllRequest';
import StaffGetAvailabilityRequest from '../../../../ajax/Staff/StaffGetAvailabilityRequest';
import AppointmentCreateRequest from '../../../../ajax/Appointment/AppointmentCreateRequest';
import AutoCompleteDropdown, {
  AutoCompleteUserOption,
  filterUserOptions,
} from '../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import { handleError, isSmallScreen } from '../../../../utils';
import InputWithCharacterLimit from '../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';

function TimeSlots({ availableSlots, slotRenderer, isLoading = false }) {
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center" style={{ marginTop: 48 }}>
        <CircularProgress color="primary" size={50} thickness={5} />
      </div>
    );
  }

  if (!availableSlots || !availableSlots.length) {
    return (
      <div className="d-flex justify-content-center" style={{ marginTop: 48 }}>
        <strong>No times available</strong>
      </div>
    );
  }

  const defaultStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
  };
  const slots = availableSlots.map(slot => slotRenderer(slot.start, slot.end));

  return (
    <>
      {/* XS */}
      <div className="d-block d-sm-none">
        <div id="xs-slots" style={{ ...defaultStyle }}>
          {slots}
        </div>
      </div>

      {/* SM */}
      <div className="d-none d-sm-block d-md-none">
        <div id="sm-slots" style={{ ...defaultStyle }}>
          {slots}
        </div>
      </div>

      {/* MD */}
      <div className="d-none d-md-block d-lg-none">
        <div id="md-slots" style={{ ...defaultStyle }}>
          {slots}
        </div>
      </div>

      {/* LG */}
      <div className="d-none d-lg-block d-xl-none">
        <div id="lg-slots" style={{ ...defaultStyle, marginTop: 48 }}>
          {slots}
        </div>
      </div>

      {/* XL */}
      <div className="d-none d-xl-block">
        <div id="xl-slots" style={{ ...defaultStyle, flexDirection: 'column', marginTop: 0 }}>
          {slots}
        </div>
      </div>
    </>
  );
}

let attendeesRequestTimeout = null;
let attendeesAbortController = null;

export default function AppointmentsListCreateModal({ onClose, isOpen, reload }) {
  const app = useContext(AppContext);
  const defaultEmptyStaff = { label: 'Any Available', value: null };
  const noStaff = { label: 'No Resource Available', value: null };
  const noTypeEmptyStaff = { label: 'Select an Appointment Type', value: null };
  const [appointmentType, setAppointmentType] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staff, setStaff] = useState(null);
  const [emptyStaff, setEmptyStaff] = useState(defaultEmptyStaff);
  const [holder, setHolder] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [holderOptions, setHolderOptions] = useState([]);
  const [isAttendeesLoading, setIsAttendeesLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState(false);

  /*
   * Find active Users
   */
  const findAttendeeUsers = event => {
    if (attendeesRequestTimeout) {
      if (attendeesAbortController) attendeesAbortController.abort();
      clearTimeout(attendeesRequestTimeout);
    }

    const fullName = get(event, 'target.value', null);
    if (!fullName || fullName.length < 3) {
      setIsAttendeesLoading(false);
      return;
    }

    setHolderOptions([]);
    setIsAttendeesLoading(true);

    attendeesRequestTimeout = setTimeout(() => {
      attendeesAbortController = new AbortController();
      request(
        'authenticated-user',
        'GET',
        `appointments/available-attendees?search=${encodeURIComponent(fullName)}`,
        null,
        attendeesAbortController.signal
      )
        .then(({ success, results }) => {
          if (success) {
            setHolderOptions(
              results.map(r => ({
                label: `${r.firstName} ${r.lastName}`,
                name: `${r.firstName} ${r.lastName}`,
                value: r.uuid,
                original: r,
              }))
            );
          }
        })
        .catch(e => console.error(e))
        .finally(() => setIsAttendeesLoading(false));
    }, 500);
  };

  /*
   * GET Appointment Types
   */
  const getAppointmentTypes = () => {
    const { user } = app;
    return BookingGetAvailableAppointmentTypesRequest(user, user.activeProfile.uuid).then(apptTypes => {
      setAppointmentTypes(
        apptTypes.map(x => ({
          label: `${x.name} (${x.duration} minutes, ${x.location})`,
          value: x.uuid,
        }))
      );
    });
  };

  /*
   * GET Staff
   */
  const getStaff = () => {
    return StaffGetAllRequest().then(staffMembers => {
      if (appointmentType) {
        const stOpts = staffMembers.map(stf => {
          if (stf.appointment_types.filter(aType => aType.uuid === appointmentType.value).length) {
            let stfLabel = `${stf.name}`;
            if (stf.individual) {
              stfLabel += ` (${stf.individual.first_name}  ${stf.individual.last_name})`;
            }
            return {
              label: stfLabel,
              value: stf.uuid,
            };
          } else {
            return null;
          }
        });

        const reducedOpts = stOpts.filter(i => i);

        if (reducedOpts.length) {
          setEmptyStaff(defaultEmptyStaff);
          setStaff(defaultEmptyStaff);
          setStaffOptions([...[defaultEmptyStaff], ...reducedOpts]);
        } else {
          setEmptyStaff(noStaff);
          setStaff(noStaff);
          setStaffOptions([noStaff]);
        }
      } else {
        setEmptyStaff(noTypeEmptyStaff);
        setStaff(noTypeEmptyStaff);
        setStaffOptions([noTypeEmptyStaff]);
      }
    });
  };

  /*
   * GET Available Time Slots
   */
  const getAvailability = (stf, date) => {
    if (appointmentType && holder) {
      const { user, timezone } = app;
      setAvailableSlots([]);
      setIsLoadingTimeSlots(true);
      StaffGetAvailabilityRequest(user, stf, appointmentType, date || startDate, timezone, null, holder)
        .then(data => setAvailableSlots(data.availability))
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingTimeSlots(false));
    }
  };

  const changeStaff = value => {
    setStaff(value);
    if (appointmentType && appointmentType.value && holder && holder.value) {
      getAvailability(value);
    }
  };

  const reset = () => {
    setNotes('');
    setIsLoadingTimeSlots(false);
    setAppointmentType(null);
    setAppointmentTypes([]);
    setStartDate(new Date());
    setStartTime(null);
    setStaffOptions([]);
    setStaff(null);
    setEmptyStaff(defaultEmptyStaff);
    setHolder(null);
    setAvailableSlots([]);
    setHolderOptions([]);
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const isSaveDisabled = () => {
    return !staff || !appointmentType || !startDate || !startTime || !holder;
  };

  const convertTime = time => {
    const hours = time > 1259 ? Math.floor(time / 100) - 12 : Math.floor(time / 100);
    const minutes = String(time).slice(-2) || '00';
    const meridian = time >= 1200 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${meridian}`;
  };

  const timeSlot = (start, end) => {
    const selClass = start === startTime ? 'selected' : '';
    return (
      <div className={`time-slot ${selClass}`} key={`${start}-${end}`} onClick={() => setStartTime(start)}>
        {convertTime(start)}
      </div>
    );
  };

  const onCreateAppointmentClick = () => {
    const appointment = {
      staff,
      appointmentType,
      startDate,
      startTime,
      timeZone: app.timezone,
      user: holder,
      relatedToBadgeApplication: undefined,
      comment: notes,
    };

    if (!appointmentType || !startDate || !startTime) {
      app.api.notify({ message: 'Missing required form data', severity: 'warning' });
      return;
    }

    app.api.toggleLoading(true);
    AppointmentCreateRequest(null, appointment)
      .then(response => {
        if (response.success === false) {
          app.api.notify({ message: response.message, severity: 'error' });
          return;
        }
        closeModal();
        reload();
      })
      .catch(error => handleError({ error, message: 'Unable to create appointment' }))
      .finally(() => app.api.toggleLoading(false));
  };

  useEffect(() => {
    if (holder && holder.value && appointmentType && appointmentType.value) {
      getAvailability(staff);
    }
  }, [appointmentType, holder]);

  useEffect(() => {
    getStaff();
  }, [appointmentType]);

  useEffect(() => {
    if (!isOpen) return;

    app.api.toggleLoading(true);
    getAppointmentTypes()
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="xl" className="appointmentModal">
      <CustomModalHeader toggle={closeModal}>Create Appointment</CustomModalHeader>
      <ModalBody>
        <Row className="mb-4">
          {/* START: LEFT SIDE OF MODAL */}
          <Col xs="12" md="12" lg="6" xl="5">
            <Row>
              <Col>
                <FormGroup>
                  <Label>Attendee</Label>
                  <AutoCompleteDropdown
                    id="attendee"
                    value={holder}
                    placeholder="Search by first or last name"
                    size="small"
                    onInputChange={findAttendeeUsers}
                    isLoading={isAttendeesLoading}
                    options={holderOptions}
                    onValueSelected={option => {
                      setHolder(option);
                    }}
                    filterOptions={filterUserOptions}
                    renderOption={user => <AutoCompleteUserOption user={user} />}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Appointment Type</Label>
                  <Select
                    classNamePrefix="airbadge"
                    isSearchable={false}
                    options={appointmentTypes}
                    className="form-select"
                    value={appointmentType}
                    placeholder=""
                    onChange={option => {
                      setAppointmentType(option);
                      setStartTime(null);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Resource</Label>
                  <Select
                    classNamePrefix="airbadge"
                    options={staffOptions}
                    className="form-select"
                    isDisabled={!appointmentType}
                    value={staff || emptyStaff}
                    placeholder=""
                    onChange={option => {
                      setStartTime(null);
                      changeStaff(option);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <FormGroup>
                  <Label>Notes for the Badge Office</Label>
                  <InputWithCharacterLimit
                    style={{ height: 60 }}
                    limit={191}
                    value={notes}
                    onChange={value => setNotes(value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          {/* END: LEFT SIDE OF MODAL */}

          {/* START: MIDDLE OF MODAL */}
          <Col xs="12" md="12" lg="6" xl="4" className={isSmallScreen() ? 'text-center' : null}>
            <DatePicker
              inline
              minDate={new Date()}
              selected={startDate}
              onChange={date => {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                setStartDate(date);
                setStartTime(null);
                getAvailability(staff, date);
              }}
              className="start-trigger"
            />
          </Col>
          {/* END: MIDDLE OF MODAL */}

          {/* START: RIGHT SIDE OF MODAL */}
          <Col xs="12" md="12" lg="12" xl="3" className="timeSlots">
            <TimeSlots availableSlots={availableSlots} slotRenderer={timeSlot} isLoading={isLoadingTimeSlots} />
          </Col>
          {/* END: RIGHT SIDE OF MODAL */}
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={closeModal}
          saveLabel="Create Appointment"
          onSave={onCreateAppointmentClick}
          saveDisabled={isSaveDisabled()}
        />
      </ModalFooter>
    </Modal>
  );
}
