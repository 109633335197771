import request from '../Request';

function listPayments({ data, signal }) {
  return request('authenticated-user', 'POST', `payments/list`, data, signal);
}

function createPayment(data) {
  return request('authenticated-user', 'POST', `payments/create`, data);
}

function recordInPersonPayment(data) {
  return request('authenticated-user', 'POST', `payments/record-in-person-payment`, data);
}

function getPayment({ uuid, signal }) {
  return request('authenticated-user', 'GET', `payments/${uuid}`, null, signal);
}

function cancelPayment({ uuid }) {
  return request('authenticated-user', 'DELETE', `payments/${uuid}`);
}

function updatePayment({ uuid, changes }) {
  return request('authenticated-user', 'PUT', `payments/${uuid}`, { changes });
}

function generateReport({ status, fromDate, toDate }) {
  return request('authenticated-user', 'POST', `payments/generate-report`, { status, fromDate, toDate });
}

export default {
  list: listPayments,
  create: createPayment,
  recordInPersonPayment,
  get: getPayment,
  cancel: cancelPayment,
  update: updatePayment,
  generateReport,
};
