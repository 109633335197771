import request from '../Request';

export default function AccountVerifyTwoFactorAuthCodeRequest(userUuid, code) {
  const method = 'POST';

  const url = 'account/verify-two-factor-auth-code';

  const data = {
    user_uuid: userUuid,
    code,
  };

  return request(null, method, url, data);
}
