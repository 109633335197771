import React, { useContext, useEffect, useState } from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { generateRandomNumber } from '../../../../Kiosk/KioskPage';
import { handleError } from '../../../../../utils';
import { createPusherInstance } from '../../../../../pushNotificationUtils';
import { AppContext } from '../../../../../App';
import badgeOfficeApi from '../../../../../ajax/BadgeOffice/badgeOfficeApi';

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: '#e4e7ea',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 45,
    letterSpacing: 5,
    fontWeight: 500,
    padding: '8px 0',
  },
  code: {
    textAlign: 'center',
    fontSize: 45,
  },
  button: {
    fontSize: 16,
    letterSpacing: 'normal',
    textAlign: 'center',
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
  notice: {
    fontSize: 16,
    letterSpacing: 1,
    fontStyle: 'italic',
    opacity: 0.7,
  },
}));

export default function KioskCodeModal({ user, isOpen, onClose }) {
  const app = useContext(AppContext);
  const airportCode = app.airport?.abbreviation?.toLocaleUpperCase();
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [pushNotificationsClient, setPushNotificationsClient] = useState(null);
  const [pushNotificationsChannel, setPushNotificationsChannel] = useState(null);

  const authenticateKioskRequest = ({ badgerHolderUuid, kioskUuid, codeFromModal, codeFromKiosk }) => {
    let isValid = Number(codeFromKiosk) === codeFromModal;
    badgeOfficeApi
      .authorizeKioskLogin({
        kioskUuid,
        isValid,
        userUuid: badgerHolderUuid,
      })
      .catch(error => handleError({ error }));
  };

  const generateCode = ({ selectedUser }) => {
    pushNotificationsChannel.unbind('authenticate-code');

    const badgerHolderUuid = selectedUser.uuid;
    const randomNumber = generateRandomNumber();
    setCode(randomNumber);

    pushNotificationsChannel.bind('authenticate-code', ({ content }) => {
      const parsed = { ...JSON.parse(content) };
      authenticateKioskRequest({
        badgerHolderUuid,
        kioskUuid: parsed.kioskUuid,
        codeFromModal: randomNumber,
        codeFromKiosk: parsed.loginCode,
      });
    });
  };

  useEffect(() => {
    if (!user) setCode('');
  }, [user]);

  useEffect(() => {
    if (pushNotificationsChannel) generateCode({ selectedUser: user });
  }, [pushNotificationsChannel]);

  useEffect(() => {
    if (!isOpen && pushNotificationsClient) {
      setCode('');
      pushNotificationsClient.unsubscribe(`${airportCode}-kiosks`);
      setPushNotificationsClient(null);
      return;
    }

    if (isOpen && !pushNotificationsClient) {
      const pusher = createPusherInstance({
        token: app?.user?.authToken,
        currentProfileUuid: app?.user?.activeProfile?.uuid || null,
      });
      const channel = pusher.subscribe(`${airportCode}-kiosks`);
      setPushNotificationsChannel(channel);
      setPushNotificationsClient(pusher);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Generate Kiosk Code"
      isOpen={isOpen}
      onClose={onClose}
      closeLabel="Close"
    >
      <Row>
        <Col>
          <div className="mb-3" style={{ fontSize: 'larger' }}>
            Ask the individual to choose a kiosk and enter this code into the prompt
          </div>
          <Card className={classes.card}>
            <CardBody className={classes.cardBody}>
              {!user ? (
                <div className={classes.notice}>Select a badge holder</div>
              ) : (
                <>
                  <div style={{ textAlign: 'center' }}>{code}</div>
                  <button
                    className={`btn btn-link ${classes.button}`}
                    onClick={() => generateCode({ selectedUser: user })}
                  >
                    Click here to generate a new code
                  </button>
                </>
              )}
            </CardBody>
          </Card>

          <Alert color="warning" style={{ fontSize: 'larger' }}>
            Do not close this screen until they have successfully logged in to the kiosk
          </Alert>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
