import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AirBadgeModal, {
  MODAL_SIZE_LARGE,
  MODAL_SIZE_MEDIUM,
} from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import peopleApi from '../../../../ajax/People/peopleApi';
import { buildFullName, handleError } from '../../../../utils';
import { AppContext } from '../../../../App';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';

export default function MergeDuplicateUsersModal({
  isOpen,
  onClose,
  duplicates,
  onMergeActionLabel,
  sourcePerson,
  isForBackgroundCheck,
}) {
  const { api: appApi } = useContext(AppContext);
  const [selection, setSelection] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const sortByFinalScore = useCallback(() => {
    return duplicates.sort((a, b) => b.finalScore - a.finalScore);
  }, [duplicates]);

  const onRowClick = selection => {
    setSelection(selection);
  };

  const onProceed = () => {
    if (selection && selection !== '000') {
      setIsConfirmModalOpen(true);
    } else {
      onClose({ wasMerged: false, notDuplicate: true });
    }
  };

  const getSelectionFullName = () => {
    return duplicates.find(d => d.uuid === selection)?.fullName || 'ERROR';
  };

  const getSourcePersonFullName = () => {
    return buildFullName(sourcePerson?.badgerData || sourcePerson);
  };

  const doMerge = () => {
    const source = { uuid: sourcePerson.uuid };
    const target = { uuid: duplicates.find(d => d.uuid === selection)?.uuid };
    appApi.toggleLoading(true);
    peopleApi
      .merge({ source, target })
      .then(({ success, message }) => {
        if (success) {
          onClose({ wasMerged: true, notDuplicate: false });
        } else {
          handleError({ message: message || 'Merge failed to determine an outcome. Contact support.' });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to merge records' }))
      .finally(() => appApi.toggleLoading(false));
    setIsConfirmModalOpen(false);
  };

  useEffect(() => {
    setSelection(null);
    setIsConfirmModalOpen(false);
  }, [isOpen]);

  if (!isOpen || !duplicates?.length) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Duplicate Record Check"
      isOpen={isOpen}
      closeLabel={isForBackgroundCheck ? 'Go Back' : 'Close'}
      onClose={() => onClose({ wasMerged: false, notDuplicate: false })}
      onSave={onProceed}
      saveLabel="Proceed"
      saveDisabled={!selection}
    >
      <div
        style={{
          border: '1px solid #c8ced3',
          backgroundColor: '#f0f0f0',
          borderRadius: 5,
          padding: 12,
          marginBottom: 24,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Row style={{ width: '100%' }}>
            <Col>
              <ReadOnlyFormData label="Full Legal Name" value={getSourcePersonFullName()} />
            </Col>
            <Col>
              <ReadOnlyFormData
                label="Date of Birth"
                skipTextTransform
                value={
                  sourcePerson?.badgerData?.dob ||
                  sourcePerson.dob ||
                  sourcePerson.date_of_birth || <i style={{ opacity: 0.6 }}>- not set -</i>
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="separator mb-3">Potential Duplicates</div>

      <Alert color="info" className="mb-4">
        Potential duplicate records exist. Carefully review the list below. If you find a match, click the row to select
        it and then click the button below to merge the records
        {isForBackgroundCheck && ` and continue with ${onMergeActionLabel}`}.
        {isForBackgroundCheck && (
          <div className="mt-2">If this record is not a duplicate, select the last option in the table.</div>
        )}
      </Alert>

      <RadioGroup
        aria-label="selected-record"
        name="selected-record"
        value={selection}
        onChange={event => setSelection(event?.target?.value)}
      >
        <Table striped>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Name</th>
              <th>DoB</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {sortByFinalScore().map(d => (
              <tr key={d.uuid} style={{ cursor: 'pointer' }} onClick={() => onRowClick(d.uuid)}>
                <td style={{ textAlign: 'center' }}>
                  <Radio color="primary" value={d.uuid} style={{ padding: 0 }} />
                </td>
                <td>{d.fullName}</td>
                <td>{d.dateOfBirth}</td>
                <td>{d.email}</td>
              </tr>
            ))}
            {isForBackgroundCheck && (
              <tr style={{ cursor: 'pointer' }} onClick={() => onRowClick('000')}>
                <td style={{ textAlign: 'center' }}>
                  <Radio color="primary" value="000" style={{ padding: 0 }} />
                </td>
                <td colSpan={3}>This is not a duplicate record.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </RadioGroup>

      {selection === '000' && (
        <Alert color="warning" fade={false}>
          Only proceed if you are confident that you are not creating a duplicate record
        </Alert>
      )}

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Confirmation"
        isOpen={isConfirmModalOpen}
        closeLabel="Go Back"
        onClose={() => setIsConfirmModalOpen(false)}
        saveLabel="Merge Records"
        saveColor="warning"
        onSave={doMerge}
      >
        <p>
          Are you sure you want to merge this{isForBackgroundCheck && ` applicant's`} record into the record for{' '}
          <strong>{getSelectionFullName()}</strong>?
        </p>
        <Alert color="warning">
          This action irreversible. If you make a mistake, you will need to contact support.
        </Alert>
      </AirBadgeModal>
    </AirBadgeModal>
  );
}
