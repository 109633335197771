import React from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import Select from 'react-select';
import IndividualGetRequest from '../../../ajax/Individual/IndividualGetRequest';
import moment from 'moment';
import MessageCreateRequest from '../../../ajax/Message/MessageCreateRequest';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MessageUploadAttachmentRequest from '../../../ajax/Message/MessageUploadAttachmentRequest';
import CustomModalHeader from '../../../shared/components/CustomModalHeader/CustomModalHeader';

export default class MessagesCreateModal extends React.Component {
  state = {
    individualOptions: [],
    expires: '',
    subject: '',
    priority: {
      label: 'Normal',
      value: 'normal',
    },
    html: '',
    text: '',
    attachments: [],
    recipients: [],
    sendMessageNotification: true,
    messageInsteadNotification: false,

    // Tabs
    contentTab: 'rich-content-tab',

    // Options
    priorityOptions: [
      {
        label: 'Low',
        value: 'low',
      },
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'High',
        value: 'high',
      },
    ],
  };

  resetModal() {
    this.setState({
      expires: '',
      subject: '',
      recipients: [],
      priority: {
        label: 'Normal',
        value: 'normal',
      },
      html: '',
      text: '',
      attachments: [],
      sendMessageNotification: true,
      messageInsteadNotification: false,
      contentTab: 'rich-content-tab',
    });
  }

  onModalClick() {
    const expires = document.querySelector('.expires-trigger .datetime-picker-popup');

    if (expires.style.display !== 'none') {
      document.querySelector('#expires').click();
    }
  }

  toggleContentTab(contentTab) {
    if (this.state.contentTab !== contentTab) {
      this.setState({
        contentTab,
      });
    }
  }

  componentDidMount() {
    const { api, state, defaultRecipient } = this.props;
    api.toggleLoading(true);
    IndividualGetRequest(state.user)
      .then(individuals => {
        const individualOptions = individuals.map(({ uuid, first_name, last_name }) => ({
          label: `${first_name} ${last_name}`,
          value: uuid,
        }));
        this.setState({ individualOptions });
        if (defaultRecipient && defaultRecipient.uuid) {
          this.updateProperty('recipients', [individualOptions.find(x => x.value === defaultRecipient.uuid)]);
        }
      })
      .catch(e => {
        console.error(e);
        api.displayMessage('Unable to fetch list of recipients', 'warning');
      })
      .finally(() => api.toggleLoading(false));
  }

  updateProperty(property, value) {
    const state = this.state;
    state[property] = value;
    this.setState(state);
  }

  onCreateMessageClick() {
    const message = {
      user_id: this.props.state.user.user_id,
      expires: this.state.expires ? moment(this.state.expires).format('YYYY-MM-DD HH:mm:ss') : null,
      subject: this.state.subject,
      html: this.state.html,
      text: this.state.text,
      priority: this.state.priority.value,
      send_message_notification: this.state.sendMessageNotification,
      message_instead_notification: this.state.messageInsteadNotification && this.state.sendMessageNotification,
      recipient_user_uuids: this.state.recipients.map(recipient => recipient.value),
      attachment_uuids: this.state.attachments.map(attachment => attachment.uuid),
    };

    if (!message.subject) {
      this.props.api.displayMessage('Subject cannot be empty.', 'warning');
      return;
    }

    if (!message.recipient_user_uuids || !message.recipient_user_uuids.length) {
      this.props.api.displayMessage('Please select the message recipients first.', 'warning');
      return;
    }

    if (!message.html || !message.text) {
      this.props.api.displayMessage('Please provide both email body and sms text message content.', 'warning');
      return;
    }

    this.props.api.toggleLoading(true);

    MessageCreateRequest(this.props.state.user, message)
      .then(() => {
        this.props.api.displayMessage('Message was created successfully.', 'success');

        this.resetModal();

        this.onClose();
      })
      .catch(() => {
        this.props.api.displayMessage('Message could not be created.', 'warning');
      })
      .finally(() => {
        this.props.api.toggleLoading(false);
      });
  }

  onClose() {
    this.props.onClose();
  }

  onAttachmentFileSelected(file) {
    if (!file) {
      return;
    }

    this.props.api.toggleLoading(true);

    MessageUploadAttachmentRequest(this.props.state.user, file)
      .then(response => {
        const attachment = {
          uuid: response.uuid,
          filename: response.filename,
        };

        this.setState({
          attachments: [...this.state.attachments, attachment],
        });
      })
      .catch(() => {
        this.props.api.displayMessage('Could not upload attachment.', 'warning');
      })
      .finally(() => {
        this.props.api.toggleLoading(false);
      });
  }

  removeAttachment(attachment) {
    this.setState({
      attachments: this.state.attachments.filter(stateAttachment => stateAttachment.uuid !== attachment.uuid),
    });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.onClose.bind(this)}
          size={'lg'}
          onClick={this.onModalClick.bind(this)}
        >
          <CustomModalHeader toggle={this.onClose.bind(this)}>Create Message</CustomModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Subject</Label>
              <Input
                value={this.state.subject}
                onChange={event => this.updateProperty('subject', event.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label>Recipients</Label>
              <Select
                classNamePrefix="airbadge"
                options={this.state.individualOptions}
                isMulti
                className={'form-select'}
                value={this.state.recipients}
                placeholder={''}
                onChange={value => this.updateProperty('recipients', value)}
              />
            </FormGroup>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Expires</Label>
                  <DatetimePickerTrigger
                    className={'expires-trigger'}
                    moment={this.state.expires ? moment(this.state.expires) : moment()}
                    showTimePicker={false}
                    onChange={moment => this.updateProperty('expires', moment.format('MM/DD/YYYY'))}
                  >
                    <Input
                      type={'text'}
                      id="expires"
                      value={this.state.expires}
                      onChange={event => this.updateProperty('expires', event.target.value)}
                      required
                    />
                  </DatetimePickerTrigger>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Priority</Label>
                  <Select
                    classNamePrefix="airbadge"
                    options={this.state.priorityOptions}
                    className={'form-select'}
                    value={this.state.priority}
                    onChange={value => this.updateProperty('priority', value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Nav tabs>
              <NavItem>
                <NavLink
                  className={this.state.contentTab === 'rich-content-tab' ? 'active' : ''}
                  onClick={() => {
                    this.toggleContentTab('rich-content-tab');
                  }}
                >
                  Email (Body)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.contentTab === 'plain-content-tab' ? 'active' : ''}
                  onClick={() => {
                    this.toggleContentTab('plain-content-tab');
                  }}
                >
                  SMS (Text)
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.contentTab} className={'mb-2'}>
              <TabPane tabId="rich-content-tab">
                <Row>
                  <Col sm="12">
                    <ReactQuill
                      theme="snow"
                      value={this.state.html}
                      onChange={value => this.updateProperty('html', value)}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="plain-content-tab">
                <Row>
                  <Col sm="12">
                    <Input
                      type="textarea"
                      style={{ height: '193px' }}
                      value={this.state.text}
                      onChange={event => this.updateProperty('text', event.target.value)}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Attachments</Label>
                  <Input
                    type="file"
                    name="file"
                    onChange={event => this.onAttachmentFileSelected(event.target.files[0])}
                  />
                </FormGroup>

                <p hidden={this.state.attachments.length}>No attachments uploaded.</p>

                <ul>
                  {this.state.attachments.map(attachment => {
                    return (
                      <li key={attachment.uuid} className={'mb-2'}>
                        <span className={'mr-2'}>{attachment.filename}</span>

                        <Button color={'outline-danger'} size={'sm'} onClick={() => this.removeAttachment(attachment)}>
                          <i className={'fa fa-trash'} />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </Col>

              <Col>
                <FormGroup check className={'mb-4'}>
                  <Label check>
                    <Input
                      type={'checkbox'}
                      checked={this.state.sendMessageNotification}
                      onChange={event => this.updateProperty('sendMessageNotification', event.target.checked)}
                    />
                    Send Message Notification
                  </Label>
                </FormGroup>

                <FormGroup check className={'mb-4'}>
                  <Label check>
                    <Input
                      type={'checkbox'}
                      checked={this.state.messageInsteadNotification && this.state.sendMessageNotification}
                      onChange={event => this.updateProperty('messageInsteadNotification', event.target.checked)}
                      disabled={!this.state.sendMessageNotification}
                    />
                    Send As Complete Message
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color={'secondary'} onClick={this.onClose.bind(this)}>
              Close
            </Button>
            <Button color={'primary'} onClick={this.onCreateMessageClick.bind(this)}>
              Send
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
