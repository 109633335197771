import React from 'react';

export default function ReactTableTotalCount({ params, total }) {
  if (!total) return <div />;

  const paramsPageSize = params[1];
  const paramsPage = params[2];
  const from = paramsPageSize * paramsPage + 1;
  const to = from + paramsPageSize - 1; // Subtracting 1 to account for zero indexed arrays
  const formattedTotal = Number(total).toLocaleString();
  const formattedFrom = Number(from).toLocaleString();
  const formattedTo = Number(to).toLocaleString();

  const finalFromValue = from > total ? formattedTotal : formattedFrom;
  let finalToValue;
  if (to > total || to === 0) {
    finalToValue = formattedTotal;
  } else {
    finalToValue = formattedTo;
  }

  return (
    <div className={'px-2 py-4'}>
      Showing {finalFromValue} to {finalToValue} of {formattedTotal} entries
    </div>
  );
}
