import React, { useContext, useState } from 'react';
import { Alert } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AppContext } from '../../../../../App';
import backgroundCheckApi from '../../../../../ajax/BackgroundCheck/backgroundCheckApi';
import { handleError } from '../../../../../utils';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';

export default function UncancelRapbackModal({ isOpen, onClose, user }) {
  const app = useContext(AppContext);
  const [selection, setSelection] = useState(null);

  const onModalClose = () => {
    setSelection(null);
    onClose();
  };

  const isSaveDisabled = () => {
    return !selection || selection === 'false';
  };

  const onUncancelRapback = () => {
    app.api.toggleLoading(true);
    backgroundCheckApi
      .changeRapbackSubscription({ userUuid: user.uuid, action: 'uncancel' })
      .then(({ success, errors, message }) => {
        if (success) {
          onClose();
        } else if (errors) {
          app.api.confirmMessage(
            'Response from TSC',
            <Alert color="danger">
              <p>{errors}</p>
            </Alert>,
            () => null,
            false
          );
        } else if (message) {
          app.api.confirmMessage('Conflict Found', <p>{message}</p>, () => null, false);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  if (!isOpen) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Uncancel Rapback Confirmation"
      isOpen={isOpen}
      onClose={onModalClose}
      closeLabel={selection === 'true' ? 'Cancel' : 'Close'}
      onSave={selection === 'true' && onUncancelRapback}
      saveLabel="Uncancel Rapback Subscription"
      saveDisabled={isSaveDisabled()}
      saveColor="primary"
    >
      <Alert color="info">
        The TSC rapback subscription for <strong>{user.fullName}</strong> is currently cancelled
      </Alert>
      <div className="mt-4">Would you like to uncancel their rapback subscription?</div>
      <RadioGroup
        row
        aria-label="selection"
        name="selection"
        value={selection}
        onChange={event => setSelection(event?.target?.value)}
      >
        <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
        <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
      </RadioGroup>
    </AirBadgeModal>
  );
}
