import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Col, FormGroup, Row } from 'reactstrap';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import NextButton from '../../../components/NextButton';
import BackButton from '../../../components/BackButton';
import { colors } from '../../../../../App';
import { sanitizePhoneNumber } from '../../../../../utils';

const VALID_PHONE_NUMBER_REGEX = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;

export default function PhoneNumberQuestion({ isHidden, value, onBack, onNext }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badger.collectedDataConfirmation' });
  const [mobile, setMobile] = useState('');
  const [home, setHome] = useState('');
  const [work, setWork] = useState('');
  const [isMobilePhoneInvalid, setIsMobilePhoneInvalid] = useState(false);
  const [isHomePhoneInvalid, setIsHomePhoneInvalid] = useState(false);
  const [isWorkPhoneInvalid, setIsWorkPhoneInvalid] = useState(false);

  const isEmptyPhoneNumber = phoneNumber => {
    return sanitizePhoneNumber(phoneNumber).replace(/_/g, '') === '';
  };

  const onBackClicked = () => {
    setIsWorkPhoneInvalid(false);
    setIsHomePhoneInvalid(false);
    setIsMobilePhoneInvalid(false);

    if (!VALID_PHONE_NUMBER_REGEX.test(mobile)) {
      setMobile('');
    }
    if (!VALID_PHONE_NUMBER_REGEX.test(home)) {
      setHome('');
    }
    if (!VALID_PHONE_NUMBER_REGEX.test(work)) {
      setWork('');
    }

    onBack();
  };

  const onNextClicked = () => {
    setIsWorkPhoneInvalid(false);
    setIsHomePhoneInvalid(false);
    setIsMobilePhoneInvalid(false);

    const phoneNumbers = [];
    if (VALID_PHONE_NUMBER_REGEX.test(mobile)) {
      phoneNumbers.push({ type: 'M', number: mobile });
    } else {
      setMobile('');
    }
    if (VALID_PHONE_NUMBER_REGEX.test(home)) {
      phoneNumbers.push({ type: 'H', number: home });
    } else {
      setHome('');
    }
    if (VALID_PHONE_NUMBER_REGEX.test(work)) {
      phoneNumbers.push({ type: 'B', number: work });
    } else {
      setWork('');
    }
    onNext(phoneNumbers);
  };

  const isNextButtonDisabled = () => {
    return (
      !VALID_PHONE_NUMBER_REGEX.test(mobile) &&
      !VALID_PHONE_NUMBER_REGEX.test(home) &&
      !VALID_PHONE_NUMBER_REGEX.test(work)
    );
  };

  useEffect(() => {
    if (!isHidden && value && Array.isArray(value)) {
      value.forEach(phoneNumber => {
        switch (phoneNumber.type) {
          case 'M':
            setMobile(phoneNumber.number);
            break;
          case 'H':
            setHome(phoneNumber.number);
            break;
          case 'B':
            setWork(phoneNumber.number);
            break;
        }
      });
    }
  }, [isHidden, value]);

  if (isHidden) return null;

  return (
    <>
      <FormLabel>
        <Typography variant="h6">{t('question11_pre')}</Typography>
      </FormLabel>
      <Row>
        <Col xs="12" sm="2">
          <FormLabel>
            <Typography variant="subtitle1">{t('question11_mobile')}</Typography>
          </FormLabel>
          <FormGroup>
            <InputMask
              mask="+1 (999) 999-9999"
              className="form-control"
              style={!isEmptyPhoneNumber(mobile) && isMobilePhoneInvalid ? { borderColor: colors.danger } : null}
              value={mobile}
              onChange={event => {
                setIsMobilePhoneInvalid(false);
                setMobile(event.target.value);
              }}
              onBlur={() => setIsMobilePhoneInvalid(!VALID_PHONE_NUMBER_REGEX.test(mobile))}
            />
            {!isEmptyPhoneNumber(mobile) && isMobilePhoneInvalid && (
              <div style={{ paddingLeft: 5 }}>
                <Typography color="error" variant="caption">
                  {t('question11_invalid')}
                </Typography>
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="2">
          <FormLabel>
            <Typography variant="subtitle1">{t('question11_home')}</Typography>
          </FormLabel>
          <FormGroup>
            <InputMask
              mask="+1 (999) 999-9999"
              className="form-control"
              style={!isEmptyPhoneNumber(home) && isHomePhoneInvalid ? { borderColor: colors.danger } : null}
              value={home}
              onChange={event => {
                setIsHomePhoneInvalid(false);
                setHome(event.target.value);
              }}
              onBlur={() => setIsHomePhoneInvalid(!VALID_PHONE_NUMBER_REGEX.test(home))}
            />
            {!isEmptyPhoneNumber(home) && isHomePhoneInvalid && (
              <div style={{ paddingLeft: 5 }}>
                <Typography color="error" variant="caption">
                  {t('question11_invalid')}
                </Typography>
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="2">
          <FormLabel>
            <Typography variant="subtitle1">{t('question11_work')}</Typography>
          </FormLabel>
          <FormGroup>
            <InputMask
              mask="+1 (999) 999-9999"
              className="form-control"
              style={!isEmptyPhoneNumber(work) && isWorkPhoneInvalid ? { borderColor: colors.danger } : null}
              value={work}
              onChange={event => {
                setIsWorkPhoneInvalid(false);
                setWork(event.target.value);
              }}
              onBlur={() => setIsWorkPhoneInvalid(!VALID_PHONE_NUMBER_REGEX.test(work))}
            />
            {!isEmptyPhoneNumber(work) && isWorkPhoneInvalid && (
              <div style={{ paddingLeft: 5 }}>
                <Typography color="error" variant="caption">
                  {t('question11_invalid')}
                </Typography>
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>

      <BackButton onClick={onBackClicked} />
      <NextButton isDisabled={isNextButtonDisabled()} onClick={onNextClicked} />
    </>
  );
}
