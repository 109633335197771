import React, { createContext, useContext, useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../components/AirBadgeModal/AirBadgeModal';
import { AppContext } from '../../App';
import lakotaApi, { FINGER_POSITIONS } from '../../ajax/LakotaFingerprints/lakotaApi';
import { handleError } from '../../utils';
import AnnotateStep from './AnnotateStep';
import CaptureStep from './CaptureStep';
import badgeApplicationApi from '../../ajax/BadgeApplication/badgeApplicationApi';
import { buildEftData } from './eftUtils';
import request from '../../ajax/Request';
import useConfirm from '../useConfirm';

let requestTimeout = null;
let abortController = null;

export const DEFAULT_COLOR = '#2a2828';
export const AMPUTATED_COLOR = '#FF0000';
export const BANDAGED_COLOR = '#0000FF';

export const defaultFingerConfig = {
  position: null,
  isAmputated: false,
  isBandaged: false,
  color: DEFAULT_COLOR,
};

export const LakotaFingerprintsContext = createContext();

export default function LakotaFingerprints({ isOpen, onClose, badgeApplication, isSimulationMode }) {
  const app = useContext(AppContext);
  const showConfirm = useConfirm();
  const config = app?.airport?.lakotaFingerprintsConfig;
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [device, setDevice] = useState(null);

  const [leftPinky, setLeftPinky] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_LITTLE });
  const [leftRing, setLeftRing] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_RING });
  const [leftMiddle, setLeftMiddle] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_MIDDLE });
  const [leftIndex, setLeftIndex] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_INDEX });
  const [leftThumb, setLeftThumb] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_THUMB });

  const [rightPinky, setRightPinky] = useState({
    ...defaultFingerConfig,
    position: FINGER_POSITIONS.FLAT_RIGHT_LITTLE,
  });
  const [rightRing, setRightRing] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_RING });
  const [rightMiddle, setRightMiddle] = useState({
    ...defaultFingerConfig,
    position: FINGER_POSITIONS.FLAT_RIGHT_MIDDLE,
  });
  const [rightIndex, setRightIndex] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_INDEX });
  const [rightThumb, setRightThumb] = useState({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_THUMB });

  const onModalClose = ({ wasCaptured }) => {
    if (requestTimeout) clearTimeout(requestTimeout);
    if (abortController) abortController.abort();

    setIsReady(false);
    setIsLoading(false);
    setErrorMessage(null);
    setWarningMessage(null);
    setActiveStep(0);
    setDevice(null);

    setLeftPinky({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_LITTLE });
    setLeftRing({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_RING });
    setLeftMiddle({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_MIDDLE });
    setLeftIndex({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_INDEX });
    setLeftThumb({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_LEFT_THUMB });

    setRightPinky({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_LITTLE });
    setRightRing({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_RING });
    setRightMiddle({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_MIDDLE });
    setRightIndex({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_INDEX });
    setRightThumb({ ...defaultFingerConfig, position: FINGER_POSITIONS.FLAT_RIGHT_THUMB });

    onClose({ wasCaptured: wasCaptured === true });
  };

  const getBadgeHolderData = async () => {
    const { data } = await request(
      'authenticated-user',
      'GET',
      `fingerprints/data/manual/${isSimulationMode ? '1234567' : badgeApplication?.uuid}`
    );
    return JSON.parse(atob(data));
  };

  const downloadEft = async ({
    leftFour,
    leftFourStats,
    thumbs,
    thumbsStats,
    rightFour,
    rightFourStats,
    shouldAttachToBadgeApplication = false,
  }) => {
    try {
      app.api.toggleLoading(true);

      const badgeHolderData = await getBadgeHolderData();

      const data = buildEftData({
        airportSON: app?.airport?.fingerprintsConfig?.son || 'MISSING_SON',
        device,
        rapbackCaseNumber: badgeApplication?.applicant?.tscData?.rapbackCaseNumber,
        badgeHolderData,
        leftFourImage: leftFour,
        leftFourStats,
        thumbsImage: thumbs,
        thumbsStats,
        rightFourImage: rightFour,
        rightFourStats,
        fingerConfigs: [
          leftPinky,
          leftRing,
          leftMiddle,
          leftIndex,
          leftThumb,
          rightPinky,
          rightRing,
          rightMiddle,
          rightIndex,
          rightThumb,
        ],
      });

      const { ebtsFile, errorMessage, violations, warning } = await lakotaApi.createEft({ config, data });
      if (errorMessage) {
        setErrorMessage(errorMessage);
        handleError({ error: errorMessage });
        console.log('violations:', violations);
        console.log('warning:', warning);
        app.api.toggleLoading(false);
        return;
      }

      // noinspection JSCheckFunctionSignatures
      const blob = new Blob([Uint8Array.from(atob(ebtsFile), c => c.charCodeAt(0))], {
        type: 'application/octet-stream',
      });
      if (shouldAttachToBadgeApplication) {
        const file = new File([blob], 'fingerprints.eft', {
          type: 'application/octet-stream',
          lastModified: new Date(),
        });
        await badgeApplicationApi.uploadFingerprints({
          badgeApplicationUuid: badgeApplication?.uuid,
          comment: null,
          file,
        });
      } else {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'fingerprints.eft';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      onModalClose({ wasCaptured: true });
    } catch (error) {
      handleError({ error });
    } finally {
      app.api.toggleLoading(false);
    }
  };

  const attachToBadgeApplication = data => {
    downloadEft({ ...data, shouldAttachToBadgeApplication: true });
  };

  const deInit = () => {
    lakotaApi
      .cancelScan({ config })
      .then(() => lakotaApi.deInitializeDevice({ config }).catch(error => handleError({ error })))
      .catch(error => handleError({ error }));
  };

  const init = ({ signal }) => {
    setIsLoading(true);
    setIsReady(false);
    lakotaApi
      .initializeDevice({ config, signal })
      .then(() => {
        setIsReady(true);
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsLoading(false));
  };

  const queryForDevices = () => {
    if (requestTimeout) clearTimeout(requestTimeout);
    if (abortController) abortController.abort();

    setIsLoading(true);
    requestTimeout = setTimeout(() => {
      setIsLoading(true);
      abortController = new AbortController();
      const signal = abortController.signal;
      lakotaApi
        .getListOfConnectedDevices({ config, signal })
        .then(devices => {
          if (!devices?.length) {
            handleError({ error: 'No fingerprint devices found' });
          } else if (devices.length > 1) {
            handleError({ error: 'Multiple fingerprint devices found. Only 1 device should be connected.' });
          } else {
            setDevice(devices[0]);
            return init({ signal });
          }
        })
        .catch(error => handleError({ error }));
    }, 500);
  };

  const proceedToCapture = () => {
    setActiveStep(1);
  };

  const goBackToAnnotate = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (isOpen) {
      if (!app?.airport?.fingerprintsConfig?.son) {
        onClose({ wasCaptured: false });
        showConfirm({
          title: 'Missing SON from fingerprints configuration',
          content: (
            <div>An Admin has not configured the SON for this airport. Contact support to have this resolved.</div>
          ),
          onConfirm: () => false,
          okButtonText: 'OK',
          isDangerous: true,
          onClose: false,
        });
      }
      setActiveStep(0);
      queryForDevices();
    }

    return () => {
      if (requestTimeout) clearTimeout(requestTimeout);
      if (abortController) abortController.abort();
      deInit();
    };
  }, [isOpen]);

  if (!isOpen) return null;

  if (isLoading) {
    return (
      <AirBadgeModal
        size={MODAL_SIZE_LARGE}
        title="Capture Fingerprints"
        isOpen={isOpen}
        onClose={onModalClose}
        hideFooter
      >
        {isLoading && <CircularProgress />}
      </AirBadgeModal>
    );
  }

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Capture Fingerprints"
      isOpen={isOpen}
      onClose={onModalClose}
      hideFooter
    >
      {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      {!!warningMessage && <Alert color="warning">{warningMessage}</Alert>}

      <LakotaFingerprintsContext.Provider
        value={{
          isReady,
          setErrorMessage,
          setWarningMessage,
          leftPinky,
          setLeftPinky,
          leftRing,
          setLeftRing,
          leftMiddle,
          setLeftMiddle,
          leftIndex,
          setLeftIndex,
          leftThumb,
          setLeftThumb,
          rightPinky,
          setRightPinky,
          rightRing,
          setRightRing,
          rightMiddle,
          setRightMiddle,
          rightIndex,
          setRightIndex,
          rightThumb,
          setRightThumb,
          proceedToCapture,
          goBackToAnnotate,
          badgeApplication,
          downloadEft,
          attachToBadgeApplication,
          onCancel: onModalClose,
        }}
      >
        {activeStep === 0 && <AnnotateStep />}
        {activeStep === 1 && <CaptureStep />}
      </LakotaFingerprintsContext.Provider>
    </AirBadgeModal>
  );
}
