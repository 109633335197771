// noinspection JSValidateTypes

import React, { useContext, useState } from 'react';
import { Alert, Card, CardBody, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { buildFullName, handleError } from '../../utils';
import FontAwesomeIcon from '../components/FontAwesome/FontAwesomeIcon';
import searchApi from '../../ajax/Search/searchApi';
import { AppContext, colors } from '../../App';
import PeopleListEditModal from '../../views/DefaultWorkflow/People/PeopleList/PeopleListEditModal';
import BadgeDetailsModal from '../../views/DefaultWorkflow/Badges/BadgesList/BadgeDetailsModal';
import SearchResultOption from './SearchResultOption';
import BadgeApplicationDetailsModal from '../../views/DefaultWorkflow/BadgeApplications/BadgeApplicationDetailsModal/BadgeApplicationDetailsModal';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inputOverlay: { position: 'absolute', width: '100%', height: '100%', zIndex: 1, cursor: 'pointer' },
  excludeLabel: {
    fontSize: 14,
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.primary,
  },
  noResults: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'italic',
  },
  helpClose: {
    outline: 'none',
    position: 'absolute',
    right: 8,
    top: 8,
  },
  results: {
    maxHeight: 400,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));

let requestTimeout = null;
let abortController = null;

export default function SiteHeaderSearch() {
  const app = useContext(AppContext);
  const classes = useStyles();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isDeactivatedRecordsExcluded, setIsDeactivatedRecordsExcluded] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [input, setInput] = useState('');
  const [isViewPersonModalOpen, setIsViewPersonModalOpen] = useState(false);
  const [isViewBadgeModalOpen, setIsViewBadgeModalOpen] = useState(false);
  const [isViewBadgeApplicationModalOpen, setIsViewBadgeApplicationModalOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isMoreSearchResults, setIsMoreSearchResults] = useState(false);

  const onViewDetailsModalClose = () => {
    setIsViewPersonModalOpen(false);
    setIsViewBadgeModalOpen(false);
    setIsViewBadgeApplicationModalOpen(false);
    setSelectedEntry(null);
  };

  const onSearchModalClose = () => {
    setIsHelpOpen(false);
    setIsViewPersonModalOpen(false);
    setIsViewBadgeModalOpen(false);
    setIsViewBadgeApplicationModalOpen(false);
    setIsSearchModalOpen(false);
    setIsDeactivatedRecordsExcluded(false);
    setIsLoading(false);
    setSearchResults([]);
    setIsMoreSearchResults(false);
    setInput('');
  };

  const onOpenSearch = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setSelectedEntry(null);
    setIsSearchModalOpen(true);
    setTimeout(() => document.getElementById('selectedUser')?.focus());
  };

  const onPersonClicked = selection => {
    setSelectedEntry(selection);
    onSearchModalClose();
    setIsViewPersonModalOpen(true);
  };

  const onBadgeApplicationClicked = selection => {
    setSelectedEntry(selection);
    onSearchModalClose();
    setIsViewBadgeApplicationModalOpen(true);
  };

  const onBadgeClicked = selection => {
    setSelectedEntry(selection);
    onSearchModalClose();
    setIsViewBadgeModalOpen(true);
  };

  const parseSearchResults = results => {
    if (!results?.length) return [];

    setIsMoreSearchResults(results.length > 10);

    return results.slice(0, 10).map(({ uuid, display, active, badge_applications, active_badges, legacy_badges }) => {
      const activeBadges = active_badges.map(x => ({ ...x, isActive: true }));
      const inactiveBadges = legacy_badges.map(x => ({ ...x, isActive: false }));
      return {
        uuid,
        value: uuid,
        label: buildFullName(display),
        isActive: !!active,
        meta: display,
        badgeApplications: badge_applications,
        badges: [...activeBadges, ...inactiveBadges],
      };
    });
  };

  const performSearch = (query, isDeactivatedRecordsExcludedOverride) => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setSearchResults([]);

    if (!query || query.length < 3) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      let deactivateToggle = isDeactivatedRecordsExcluded;
      if (isDeactivatedRecordsExcludedOverride !== undefined) {
        deactivateToggle = isDeactivatedRecordsExcludedOverride;
      }
      searchApi
        .search({ query, isDeactivatedRecordsExcluded: deactivateToggle, signal: abortController.signal })
        .then(({ success, results }) => {
          if (success) setSearchResults(parseSearchResults(results));
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  return (
    <div className={classes.wrapper}>
      <InputGroup>
        <div onClick={onOpenSearch} className={classes.inputOverlay}>
          &nbsp;
        </div>
        <InputGroupText
          style={{
            backgroundColor: colors.primary,
            color: '#ffffff',
            border: `1px solid ${colors.primary}`,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </InputGroupText>
        <Input
          placeholder="SEARCH"
          disabled
          style={{
            color: colors.primary,
            borderColor: colors.primary,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          }}
        />
      </InputGroup>

      <Modal size="lg" isOpen={isSearchModalOpen} toggle={onSearchModalClose} centered={true}>
        <ModalHeader
          toggle={onSearchModalClose}
          close={
            <button className="close" onClick={onSearchModalClose} style={{ outline: 'none' }}>
              <i className="fa fa-times" />
            </button>
          }
        >
          <i className="fa-solid fa-magnifying-glass mr-2"></i> Search for People or Badges
        </ModalHeader>
        <ModalBody>
          <Input
            bsSize="lg"
            id="selectedUser"
            placeholder="Type here to begin your search"
            value={input}
            onChange={event => {
              const input = event?.target?.value;
              setInput(input);
              performSearch(input);
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <MuiFormControlLabel
              style={{ margin: 0 }}
              classes={{ label: classes.excludeLabel }}
              label="Exclude deactivated records"
              control={
                <Checkbox
                  color="primary"
                  name="confirm"
                  checked={isDeactivatedRecordsExcluded}
                  onChange={event => {
                    const isChecked = event?.target?.checked;
                    setIsDeactivatedRecordsExcluded(isChecked);
                    performSearch(input, isChecked);
                  }}
                />
              }
            />
            <button className="btn btn-link p-0" onClick={() => setIsHelpOpen(!isHelpOpen)}>
              <div style={{ display: 'inline-block', textDecoration: 'underline', position: 'relative', top: -2 }}>
                Help
              </div>
              <FontAwesomeIcon className="fa-solid fa-circle-question ml-1" style={{ fontSize: 18 }} />
            </button>
          </div>

          {isHelpOpen && (
            <Alert id="searchHelp" color="info" style={{ marginTop: 8 }} fade={false}>
              <button className={`close ${classes.helpClose}`} onClick={() => setIsHelpOpen(!isHelpOpen)}>
                <i className="fa fa-times" />
              </button>
              <div style={{ fontWeight: 600 }}>You can search using any of the following</div>
              <ul>
                <li>Any part of someone's name (e.g., first, middle, last, suffix, or nickname)</li>
                <li>Email Address</li>
                <li>Date of Birth (mm/dd/yyyy)</li>
                <li>Airport Person ID</li>
                <li>Mobile Phone Number (with no dashes or parenthesis)</li>
                <li>CHRC Case Number</li>
                <li>STA ID</li>
                <li>Badge Number</li>
              </ul>

              <div className="mb-1">
                <i className="fa-solid fa-ban text-danger" style={{ opacity: 0.8 }}></i> = deactivated
              </div>
              <div className="mb-3">
                <i>(deactivated badges will only show if you searched for the badge number)</i>
              </div>

              <i>
                Searches are limited to 10 results. If you don't see what you are looking for, try adding more details.
              </i>
            </Alert>
          )}

          <div id="results" className="mt-3">
            <Card
              style={{
                display: input?.length < 3 ? 'none' : '',
                marginBottom: 4,
              }}
            >
              <CardBody className={classes.results} style={{ padding: !searchResults?.length ? 16 : 0 }}>
                {!isLoading && !searchResults?.length && <div className={classes.noResults}>No matches found</div>}

                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress color="inherit" size={30} thickness={5} />
                    <span className="ml-3" style={{ fontStyle: 'italic' }}>
                      Finding matches
                    </span>
                  </div>
                )}

                {!!searchResults?.length &&
                  searchResults.map((result, index) => (
                    <SearchResultOption
                      key={index}
                      shouldShowHighlight={index % 2 !== 0}
                      data={result}
                      onPersonClicked={onPersonClicked}
                      onBadgeApplicationClicked={onBadgeApplicationClicked}
                      onBadgeClicked={onBadgeClicked}
                    />
                  ))}
              </CardBody>
            </Card>
            {isMoreSearchResults && (
              <div style={{ paddingLeft: 5, fontSize: 14, opacity: 0.8 }}>
                <i>
                  Searches are limited to 10 results. If you don't see what you are looking for, try adding more
                  details.
                </i>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>

      <PeopleListEditModal
        isOpen={isViewPersonModalOpen}
        onClose={onViewDetailsModalClose}
        selectedPerson={selectedEntry}
      />

      <BadgeDetailsModal
        isOpen={isViewBadgeModalOpen}
        onClose={onViewDetailsModalClose}
        state={app}
        api={app.api}
        badge={selectedEntry}
      />

      <BadgeApplicationDetailsModal
        isOpen={isViewBadgeApplicationModalOpen}
        onClose={onViewDetailsModalClose}
        selectedBadgeApplication={selectedEntry}
      />
    </div>
  );
}
