const states = [
  { value: 'AB', label: 'Canada-Alberta' },
  { value: 'BC', label: 'Canada-British Columbia' },
  { value: 'MB', label: 'Canada-Manitoba' },
  { value: 'NB', label: 'Canada-New Brunswick' },
  { value: 'NL', label: 'Canada-Newfoundland and Labrador' },
  { value: 'NT', label: 'Canada-Northwest Territories' },
  { value: 'NS', label: 'Canada-Nova Scotia' },
  { value: 'NU', label: 'Canada-Nunavut' },
  { value: 'ON', label: 'Canada-Ontario' },
  { value: 'PE', label: 'Canada-Prince Edward Island' },
  { value: 'QC', label: 'Canada-Quebec' },
  { value: 'SK', label: 'Canada-Saskatchewan' },
  { value: 'YT', label: 'Canada-Yukon' },
];
export default states;
