import request from '../Request';

export default function MessageCreateRequest(user, message) {
  const method = 'POST';

  const url = 'messages';

  const data = {
    message,
  };

  return request(user, method, url, data);
}
