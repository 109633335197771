import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';
import Grid from '@material-ui/core/Grid';
import FontAwesomeIcon from '../../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { AppContext, colors } from '../../../../../../../App';
import AirBadgeModal, {
  MODAL_SIZE_EXTRA_LARGE,
} from '../../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { ActionPanelContext } from '../../BadgeApplicationActionPanel';
import InfoBox from '../../../../../../../shared/InfoBox/InfoBox';
import ReadOnlyFormData from '../../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import ValidatePerson from './ValidatePerson/ValidatePerson';
import ValidateBadge from './ValidateBadge/ValidateBadge';
import { handleError } from '../../../../../../../utils';
import ValidateDocuments from './ValidateDocuments/ValidateDocuments';
import request from '../../../../../../../ajax/Request';
import useConfirm from '../../../../../../../shared/useConfirm';
import badgeApplicationApi from '../../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import ValidatePayments from './ValidatePayments/ValidatePayments';
import { hasFeature } from '../../../../../../../shared/Feature';

function TabContent({ isActive, children }) {
  return <div style={{ width: '100%', display: isActive ? 'inline-block' : 'none', paddingTop: 16 }}>{children}</div>;
}

export function TabActions({ children }) {
  return (
    <div
      style={{
        marginTop: 32,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  );
}

export const ValidateContext = createContext();

let requestTimeout = null;
let abortController = null;

export default function ValidateBadgeApplicationAction() {
  const [shouldReloadBadgeApplication, setShouldReloadBadgeApplication] = useState(false);

  const app = useContext(AppContext);
  const showConfirm = useConfirm();
  const { badgeApplication, isCurrentUserSignatory, onActionDone } = useContext(ActionPanelContext);
  const { config, applicant, signatory, badgeType, isRenewal, renewingBadge } = badgeApplication;
  const { applicationDocument, validation, backgroundCheck, approveBackgroundCheckResults, adjudicateCHRC } = config;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isPersonVettingComplete, setIsPersonVettingComplete] = useState(false);
  const [isBadgeTrainingComplete, setIsBadgeTrainingComplete] = useState(false);
  const [isDocumentsComplete, setIsDocumentsComplete] = useState(false);
  const [isLoadingBadges, setIsLoadingBadges] = useState(false);
  const [currentBadges, setCurrentBadges] = useState([]);
  const [isPaymentsEnabled, setIsPaymentsEnabled] = useState(false);

  const isBackgroundCheckAlreadyStarted = !!backgroundCheck?.isComplete;

  const closeModal = args => {
    setCurrentBadges([]);
    setActiveTab(0);
    setIsLoadingBadges(false);
    setIsPersonVettingComplete(false);
    setIsBadgeTrainingComplete(false);
    setIsDocumentsComplete(false);
    setIsModalOpen(false);
    if (args?.isValidationTotallyComplete) {
      const isFingerprintsStepEnabledInConfig = !!badgeApplication.config.fingerprintAppointment.isEnabled;
      const hasReceivedFingerprintsFile = !!badgeApplication.config.fingerprintAppointment.fingerprintsFileUuid;
      const isPaymentsFeatureEnabled = hasFeature('payments', app?.airport?.config?.features || []);
      const { isPaid, settings } = badgeApplication;
      const { shouldPaymentBeSettledBeforeTransmit } = settings;

      if (isPaymentsFeatureEnabled && !isPaid && shouldPaymentBeSettledBeforeTransmit) {
        return onActionDone({ shouldReload: true });
      }

      if (!isFingerprintsStepEnabledInConfig || hasReceivedFingerprintsFile) {
        if (
          badgeApplication?.config?.uploadChrcDocumentation?.isEnabled &&
          !badgeApplication?.config?.uploadChrcDocumentation?.isComplete
        ) {
          return onActionDone({ shouldReload: true });
        }

        app.api.toggleLoading(false);
        showConfirm({
          title: 'Transmit Background Check',
          content: (
            <div>
              <div>The applicant and their badge application are ready to be transmitted to TSC.</div>
              <p>Would you like to transmit the background check?</p>
            </div>
          ),
          onConfirm: () => {
            app.api.toggleLoading(true);
            badgeApplicationApi
              .startBackgroundCheck({ badgeApplicationUuid: badgeApplication.uuid })
              .then(() => onActionDone({ shouldReload: true }))
              .catch(error => handleError({ error }))
              .finally(() => app.api.toggleLoading(false));
          },
          okButtonText: 'Transmit Background Check',
        });
      } else {
        onActionDone({ shouldReload: true });
      }
    } else {
      if (shouldReloadBadgeApplication || args?.shouldReloadBadgeApplication) {
        onActionDone({ shouldReload: true });
      }
    }
  };

  const gotoTab = index => {
    setActiveTab(index);
  };

  const onStepMarkedComplete = badgeApplicationConfig => {
    setIsPersonVettingComplete(badgeApplicationConfig.validation.meta.personValidation.isComplete);
    setIsBadgeTrainingComplete(badgeApplicationConfig.validation.meta.badgeValidation.isComplete);
    setIsDocumentsComplete(badgeApplicationConfig.validation.meta.documentValidation.isComplete);
  };

  const loadBadges = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setIsLoadingBadges(true);

    requestTimeout = setTimeout(() => {
      setIsLoadingBadges(true);
      abortController = new AbortController();
      request('authenticated-user', 'GET', `badges/user-stats/${applicant.uuid}`, null, abortController.signal)
        .then(({ badges }) => setCurrentBadges(badges || []))
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingBadges(false));
    }, 500);
  };

  useEffect(() => {
    const isPersonVettingComplete = validation?.meta?.personValidation?.isComplete;
    const isBadgeTrainingComplete = validation?.meta?.badgeValidation?.isComplete;
    const isDocumentsComplete = validation?.meta?.documentValidation?.isComplete;
    setIsPersonVettingComplete(isPersonVettingComplete);
    setIsBadgeTrainingComplete(isBadgeTrainingComplete);
    setIsDocumentsComplete(isDocumentsComplete);

    if (isModalOpen) {
      loadBadges();
    }

    if (isBadgeTrainingComplete || isDocumentsComplete) {
      setActiveTab(2);
    } else if (isPersonVettingComplete) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (!badgeApplication) return;

    setIsPaymentsEnabled(
      hasFeature('payments', app?.airport?.config?.features || []) && badgeApplication.isPaid === false
    );
  }, [badgeApplication, isDocumentsComplete]);

  const isHidden = useCallback(() => {
    if (!validation?.isEnabled) {
      return true;
    }
    if (applicationDocument.isEnabled && !applicationDocument.isComplete) {
      // Application document needs to be done first
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for this
      return true;
    }
    if (approveBackgroundCheckResults.isComplete && validation?.isComplete) {
      // STA and Validation are complete
      return true;
    }
    return !(
      (approveBackgroundCheckResults.isEnabled && !approveBackgroundCheckResults.isComplete) ||
      (adjudicateCHRC.isEnabled && !adjudicateCHRC.isComplete)
    );
  }, [applicationDocument, isCurrentUserSignatory]);

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <FontAwesomeIcon className="fa-solid fa-file-circle-check" color={colors.primary} style={{ fontSize: 22 }} />
        </ListItemIcon>
        <ListItemText
          primary={validation?.isComplete ? 'Re-validate Badge Application' : 'Validate Badge Application'}
        />
      </ListItem>

      <AirBadgeModal
        size={MODAL_SIZE_EXTRA_LARGE}
        title={validation?.isComplete ? 'Re-validate Badge Application' : 'Validate Badge Application'}
        isOpen={isModalOpen}
        onClose={closeModal}
        hideFooter
      >
        <InfoBox className="mb-2">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ReadOnlyFormData
                label="Applicant (Airport Person ID)"
                value={`${applicant.fullName} (${applicant.airportPersonId || '-'})`}
                skipTextTransform
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyFormData
                label="Badge Type (Application Type)"
                value={`${badgeType.displayName} (${isRenewal ? 'Renewal' : 'New'})`}
                skipTextTransform
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyFormData label="Authorized Signatory" value={signatory.fullName} />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyFormData label="Company" value={signatory.company.name} />
            </Grid>
          </Grid>

          <div style={{ position: 'relative' }}>
            <div className="separator mt-4 mb-2">Current and Recently Deactivated Badges</div>
            {isRenewal && (
              <div
                className="mr-1"
                style={{
                  fontStyle: 'italic',
                  width: '100%',
                  position: 'absolute',
                  top: 18,
                  textAlign: 'right',
                  fontSize: 12,
                }}
              >
                <i className="fa-solid fa-circle text-success mr-1" style={{ fontSize: 14 }}></i>= renewing badge
              </div>
            )}
          </div>
          {currentBadges.length === 0 ? (
            <div>
              {isLoadingBadges ? (
                <CircularProgress size={20} />
              ) : (
                'This applicant does not have any current or recently deactivated badges'
              )}
            </div>
          ) : (
            <Table size="sm" borderless={true}>
              <thead>
                <tr>
                  {isRenewal && <th style={{ width: 30 }}></th>}
                  <th style={{ width: 150 }}>ID</th>
                  <th style={{ width: 150 }}>Type</th>
                  <th style={{ width: 150 }}>Expires</th>
                  <th>Company</th>
                </tr>
              </thead>
              <tbody>
                {currentBadges.map(({ uuid, signatoryCompanyName, number, expires, badgeTypeDisplayName }) => (
                  <tr key={uuid}>
                    {isRenewal && (
                      <td>
                        {renewingBadge?.uuid === uuid ? (
                          <i className="fa-solid fa-circle text-success" style={{ fontSize: 15 }}></i>
                        ) : null}
                      </td>
                    )}
                    <td>{number}</td>
                    <td>{badgeTypeDisplayName}</td>
                    <td>{expires ? moment.tz(expires, app.timezone).format('YYYY-MM-DD') : '-'}</td>
                    <td>{signatoryCompanyName}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </InfoBox>

        <Stepper activeStep={activeTab} style={{ marginTop: 16, marginBottom: 8 }}>
          <Step completed={isPersonVettingComplete}>
            <StepLabel StepIconComponent={props => <StepIcon {...props} classes={{ completed: 'text-success' }} />}>
              PERSON / VETTING
            </StepLabel>
          </Step>
          <Step completed={isBadgeTrainingComplete}>
            <StepLabel StepIconComponent={props => <StepIcon {...props} classes={{ completed: 'text-success' }} />}>
              {app.airport.isTrainingEnabled ? 'BADGE / TRAINING' : 'BADGE'}
            </StepLabel>
          </Step>
          <Step completed={isDocumentsComplete}>
            <StepLabel StepIconComponent={props => <StepIcon {...props} classes={{ completed: 'text-success' }} />}>
              DOCUMENTS
            </StepLabel>
          </Step>
          {isPaymentsEnabled && !validation.isComplete && (
            <Step completed={validation.isComplete}>
              <StepLabel StepIconComponent={props => <StepIcon {...props} classes={{ completed: 'text-success' }} />}>
                PAYMENT
              </StepLabel>
            </Step>
          )}
        </Stepper>

        <Card>
          <CardBody>
            <ValidateContext.Provider
              value={{
                setShouldReloadBadgeApplication,
                closeModal,
                gotoTab,
                isBackgroundCheckAlreadyStarted,
                onStepMarkedComplete,
              }}
            >
              {/* Person / Vetting */}
              <TabContent isActive={activeTab === 0}>
                <ValidatePerson isOpen={activeTab === 0} />
              </TabContent>

              {/* Configuration */}
              <TabContent isActive={activeTab === 1}>
                <ValidateBadge isOpen={activeTab === 1} />
              </TabContent>

              {/* Documents */}
              <TabContent isActive={activeTab === 2}>
                <ValidateDocuments isOpen={activeTab === 2} isPaymentsEnabled={isPaymentsEnabled} />
              </TabContent>

              {/* Payments */}
              {isPaymentsEnabled && (
                <TabContent isActive={activeTab === 3}>
                  <ValidatePayments isOpen={activeTab === 3} />
                </TabContent>
              )}
            </ValidateContext.Provider>
          </CardBody>
        </Card>
      </AirBadgeModal>
    </>
  );
}
