import React, { useContext } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { colors } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';

export default function ShowIssueBadgeSummaryAction() {
  const { badgeApplication, isCurrentUserSignatory, setIsSummaryModalOpen } = useContext(ActionPanelContext);

  const isHidden = () => {
    const { active, config } = badgeApplication;
    const { issueBadge } = config;
    if (!!active === false) {
      // Badge Application is not active
      return true;
    }
    if (isCurrentUserSignatory) {
      // Signatories are not responsible for doing this
      return true;
    }
    if (issueBadge.isEnabled && issueBadge.isComplete) {
      // Badge has already been issued
      return true;
    }
    if (badgeApplication.currentPhase.toLocaleUpperCase() !== 'ISSUE') {
      return true;
    }
    return false;
  };

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsSummaryModalOpen(true)}>
        <ListItemIcon>
          <ListAltIcon style={{ color: colors.primary }} />
        </ListItemIcon>
        <ListItemText primary="Show Issue Badge Summary" />
      </ListItem>
    </>
  );
}
