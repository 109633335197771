import request from '../Request';

export default {
  getActionItemsSummary,
  getAll: getAllActionItems,
  get: getSingleActionItem,
  create: createActionItem,
  update: updateActionItem,
  delete: deleteActionItem,
  findUsers,
  findBadges,
  findBadgeApplications,
  getAllClassifications: getAllActionItemClassifications,
  getClassification: getSingleActionItemClassification,
  createClassification: createActionItemClassification,
  updateClassification: updateActionItemClassification,
  deleteClassification: deleteActionItemClassification,
  getCount,
};

/*
 * Action Items
 */

function getActionItemsSummary({ signal }) {
  return request('authenticated-user', 'GET', `action-item/summary`, null, signal);
}

function getAllActionItems([, pageSize, page, sorted, { hideInactive, filters: filtered }, badgeAuditId]) {
  return request('authenticated-user', 'POST', `action-item/list`, {
    pageSize,
    page,
    sorted,
    hideInactive,
    filtered,
    badgeAuditId,
  });
}

function getCount({ relatedToUserUuid, badgeApplicationUuid }) {
  return request('authenticated-user', 'POST', `action-item/count`, {
    relatedToUserUuid,
    badgeApplicationUuid,
  });
}

function getSingleActionItem({ uuid }) {
  return request('authenticated-user', 'GET', `action-item/${uuid}`);
}

function createActionItem(data) {
  return request('authenticated-user', 'POST', `action-item`, data);
}

function updateActionItem(data) {
  return request('authenticated-user', 'PATCH', `action-item/${data.uuid}`, data);
}

function deleteActionItem({ uuid }) {
  return request('authenticated-user', 'DELETE', `action-item/${uuid}`);
}

function findUsers(criteria, signal) {
  return request('authenticated-user', 'POST', `action-item/find-users`, criteria, signal);
}

function findBadges(criteria, signal) {
  return request('authenticated-user', 'POST', `action-item/find-badges`, criteria, signal);
}

function findBadgeApplications(criteria, signal) {
  return request('authenticated-user', 'POST', `action-item/find-badge-applications`, criteria, signal);
}

/*
 * Action Item Classifications
 */

function getAllActionItemClassifications() {
  return request('authenticated-user', 'POST', `action-item/classification/list`);
}

function getSingleActionItemClassification({ id }) {
  return request('authenticated-user', 'GET', `action-item/classification/${id}`);
}

function createActionItemClassification(data) {
  return request('authenticated-user', 'POST', `action-item/classification`, data);
}

function updateActionItemClassification(data) {
  return request('authenticated-user', 'PUT', `action-item/classification/${data.id}`, data);
}

function deleteActionItemClassification({ id }) {
  return request('authenticated-user', 'DELETE', `action-item/classification/${id}`);
}
