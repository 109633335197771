import request, { upload } from '../Request';

export default {
  getAllViolationTypes,
  createViolationType,
  updateViolationType,
  changeViolationTypeStatus,
  getAllViolations,
  createViolation,
  getViolation,
  addViolationComment,
  changeStatus,
  updateViolation,
  uploadFile,
  getFiles,
};

function getAllViolationTypes(data) {
  return request('authenticated-user', 'POST', 'violations/get-all-types', data);
}

function createViolationType(data) {
  return request('authenticated-user', 'POST', 'violations/type', data);
}

function updateViolationType(data) {
  return request('authenticated-user', 'PUT', `violations/type/${data.id}`, data);
}

function changeViolationTypeStatus({ id, isActive }) {
  return request('authenticated-user', 'PATCH', `violations/type/${id}`, { isActive });
}

function getAllViolations({ data, signal }) {
  return request('authenticated-user', 'POST', 'violations/get-all', data, signal);
}

function createViolation(data) {
  return request('authenticated-user', 'POST', 'violations', data);
}

function getViolation({ violationUuid, signal }) {
  return request('authenticated-user', 'GET', `violations/${violationUuid}`, null, signal);
}

function addViolationComment({ violationUuid, comment }) {
  return request('authenticated-user', 'POST', `violations/${violationUuid}/comment`, { comment });
}

function changeStatus({ violationUuid, newStatus, comment }) {
  return request('authenticated-user', 'PUT', `violations/${violationUuid}/status`, { newStatus, comment });
}

function updateViolation({ violationUuid, updates }) {
  return request('authenticated-user', 'PUT', `violations/${violationUuid}`, updates);
}

function uploadFile({ violationUuid, file, notes }) {
  const data = {};
  data.notes = notes;
  data.size = file.size;
  data.extension = file.name.split('.').pop();
  data.mimeType = file.type;
  return upload('authenticated-user', 'POST', `violations/${violationUuid}/upload-file`, file, data);
}

function getFiles({ violationUuid }) {
  return request('authenticated-user', 'GET', `violations/${violationUuid}/files`);
}
