import React, { createContext, useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import AppointmentsUpcomingRequest from '../../../../ajax/Appointment/AppointmentsUpcomingRequest';
import StaffGetAllRequest from '../../../../ajax/Staff/StaffGetAllRequest';
import BookingGetAvailableAppointmentTypesRequest from '../../../../ajax/Booking/BookingGetAvailableAppointmentTypesRequest';
import AppointmentsListCreateModal from './AppointmentsListCreateModal';
import AppointmentsListEditModal from './AppointmentsListEditModal';
import BadgeOfficeCalendar from './components/BadgeOfficeCalendar';
import { Grid, Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppointmentsGrid from './AppointmentsGrid';
import { emptyAppointmentType, emptyStaff } from '../Appointments';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { handleError, isSmallScreen } from '../../../../utils';
import AppointmentStatusChangeModal from './components/AppointmentStatusChangeModal';
import AppointmentRequests from '../AppointmentRequests/AppointmentRequests';

const isMobile = isSmallScreen();

const useStyles = makeStyles(theme => ({
  addNewButton: {
    marginLeft: isMobile ? theme.spacing(1) : undefined,
  },
  filterRowTop: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'flex-start',
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  filterRowBottom: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  paddles: {
    marginRight: isMobile ? theme.spacing(1) : 0,
    marginLeft: isMobile ? theme.spacing(1) : 0,
    marginBottom: isMobile ? theme.spacing(2) : 0,
  },
  backButton: { width: 75, height: '100%', marginRight: theme.spacing(3) },
  startDate: {
    marginRight: isMobile ? theme.spacing(1) : theme.spacing(3),
    marginLeft: isMobile ? theme.spacing(1) : 0,
    marginBottom: isMobile ? theme.spacing(2) : 0,
    '& #start-date': {
      height: 38,
      paddingLeft: 35,
      width: isMobile ? undefined : 145,
    },
  },
  todayButton: {
    marginRight: isMobile ? theme.spacing(1) : theme.spacing(3),
    marginLeft: isMobile ? theme.spacing(1) : 0,
    marginBottom: isMobile ? theme.spacing(2) : 0,
  },
  statusesSelect: {
    flex: 1,
    marginRight: isMobile ? theme.spacing(1) : theme.spacing(3),
    marginLeft: isMobile ? theme.spacing(1) : 0,
    marginBottom: isMobile ? theme.spacing(2) : 0,
  },
  typesSelect: {
    flex: 1,
    marginRight: isMobile ? theme.spacing(1) : theme.spacing(3),
    marginLeft: isMobile ? theme.spacing(1) : 0,
    marginBottom: isMobile ? theme.spacing(2) : 0,
  },
  staffSelect: {
    flex: 1,
    marginRight: isMobile ? theme.spacing(1) : 0,
    marginLeft: isMobile ? theme.spacing(1) : 0,
    marginBottom: isMobile ? theme.spacing(2) : 0,
  },
  forwardButton: {
    width: 75,
    marginRight: isMobile ? undefined : theme.spacing(3),
    float: isMobile ? 'right' : undefined,
  },
}));

const defaultStatusFilter = { label: 'Unprocessed Appointments', value: 'unprocessed' };
const statusFilters = [
  {
    label: 'All Appointments',
    value: 'all',
  },
  defaultStatusFilter,
  {
    label: 'Completed Appointments',
    value: 'completed',
  },
  {
    label: 'Missed Appointments',
    value: 'missed',
  },
  {
    label: 'Cancelled Appointments',
    value: 'cancelled',
  },
];

export const AppointmentCalendarContext = createContext({});

export default function AppointmentsList({
  user,
  api,
  timeZone,
  appointmentType,
  setAppointmentType,
  staff,
  setStaff,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [newAppointmentModal, setNewAppointmentModal] = useState(false);
  const [editAppointmentModal, setEditAppointmentModal] = useState(false);
  const [editAppointment, setEditAppointment] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([emptyAppointmentType]);
  const [staffOptions, setStaffOptions] = useState([emptyStaff]);
  const [data, setData] = useState(null);
  const [params, setParams] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [startDateFormatted, setStartDateFormatted] = useState(moment().format('MM/DD/YYYY'));
  const [isGridView, setIsGridView] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [statusFilter, setStatusFilter] = useState(defaultStatusFilter);
  const [appointmentStatusChangeModalData, setAppointmentStatusChangeModalData] = useState({});
  const [isAppointmentStatusChangeModalOpen, setIsAppointmentStatusChangeModalOpen] = useState(false);
  const [isAppointmentUpdateDone, setIsAppointmentUpdateDone] = useState(false);
  const [isAppointmentRequestsModalOpen, setIsAppointmentRequestsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const onAppointmentRequestsModalClose = () => {
    setIsAppointmentRequestsModalOpen(false);
  };

  const reload = () => {
    requestData(...params);
    setShouldReload(false);
  };

  const requestData = (user, staff, appointmentType, start) => {
    setIsLoading(true);
    AppointmentsUpcomingRequest({
      user,
      staff,
      appointmentType,
      forUser: null,
      start: start.format('YYYY-MM-DD'),
      statusFilter: statusFilter.value,
    })
      .then(res => setData(res.data))
      .finally(() => setIsLoading(false));
  };

  const onNewAppointmentModalClose = () => {
    setNewAppointmentModal(false);
    setShouldReload(true);
  };

  const onEditAppointmentModalClose = () => {
    setEditAppointmentModal(false);
    setEditAppointment(null);
    setShouldReload(true);
  };

  const onAddNewClick = () => {
    setNewAppointmentModal(true);
  };

  const onEditAppointmentClick = row => {
    setEditAppointment(row);
    setEditAppointmentModal(true);
  };

  const onChangeStartDay = newMoment => {
    const newDate = newMoment;
    const newParams = [user, staff, appointmentType, newDate];
    setStartDate(newDate);
    setStartDateFormatted(newDate.format('MM/DD/YYYY'));
    setParams(newParams);
    requestData(...newParams);
  };

  const goBackOneDay = () => {
    const newDate = moment(startDate).subtract(1, 'days');
    const newParams = [user, staff, appointmentType, newDate];
    setStartDate(newDate);
    setStartDateFormatted(newDate.format('MM/DD/YYYY'));
    setParams(newParams);
    requestData(...newParams);
  };

  const goForwardOneDay = () => {
    const newDate = moment(startDate).add(1, 'days');
    const newParams = [user, staff, appointmentType, newDate];
    setStartDate(newDate);
    setStartDateFormatted(newDate.format('MM/DD/YYYY'));
    setParams(newParams);
    requestData(...newParams);
  };

  const goToNow = () => {
    const newDate = moment();
    const newParams = [user, staff, appointmentType, newDate];
    setStartDate(newDate);
    setStartDateFormatted(newDate.format('MM/DD/YYYY'));
    setParams(newParams);
    requestData(...newParams);
  };

  const onMarkCompleted = ({ appointment }) => {
    setAppointmentStatusChangeModalData({ appointment, status: 'completed' });
    setIsAppointmentStatusChangeModalOpen(true);
  };

  const onMarkMissed = ({ appointment }) => {
    setAppointmentStatusChangeModalData({ appointment, status: 'missed' });
    setIsAppointmentStatusChangeModalOpen(true);
  };

  const onForceReschedule = ({ appointment }) => {
    setAppointmentStatusChangeModalData({ appointment, status: 'reschedule' });
    setIsAppointmentStatusChangeModalOpen(true);
  };

  const onCancelAppointment = ({ appointment }) => {
    setAppointmentStatusChangeModalData({ appointment, status: 'cancelled' });
    setIsAppointmentStatusChangeModalOpen(true);
  };

  const onAppointmentStatusChangeModalClosed = ({ shouldReload }) => {
    setIsAppointmentStatusChangeModalOpen(false);
    setAppointmentStatusChangeModalData({});
    if (shouldReload) reload();
  };

  useEffect(() => {
    const newParams = [user, staff, appointmentType, startDate];
    requestData(...newParams);
    setParams(newParams);

    if (staffOptions.length === 1) {
      StaffGetAllRequest(user)
        .then(staffMembers => {
          const stOpts = staffMembers.map(stf => {
            let stfLabel = `${stf.name}`;

            if (stf.individual) {
              stfLabel += ` (${stf.individual.first_name}  ${stf.individual.last_name})`;
            }

            return {
              label: stfLabel,
              value: stf.uuid,
            };
          });

          setStaffOptions([...[emptyStaff], ...stOpts]);
        })
        .catch(error => handleError({ error }));
    }

    if (appointmentTypes.length === 1) {
      BookingGetAvailableAppointmentTypesRequest(user)
        .then(apptTypes => {
          const apptOpts = apptTypes.map(appt => {
            return {
              label: appt.name,
              value: appt.uuid,
            };
          });
          setAppointmentTypes([...[emptyAppointmentType], ...apptOpts]);
        })
        .catch(error => handleError({ error }));
    }
  }, [user, staff, appointmentType, statusFilter, timeZone]);

  return (
    <AppointmentCalendarContext.Provider
      value={{
        onCancelAppointment,
        onMarkCompleted,
        onMarkMissed,
        onForceReschedule,
        isAppointmentUpdateDone,
        setIsAppointmentUpdateDone,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: 15, marginBottom: 20 }}>
            <Grid container spacing={4}>
              <Grid item>
                <Button color="primary" onClick={onAddNewClick} className={classes.addNewButton}>
                  <i className="fa fa-plus mr-2" />
                  Add New
                </Button>
              </Grid>
              <Grid item style={isGridView ? null : { display: 'none' }}>
                <div id="gridOptionsBtn"></div>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={() => setIsAppointmentRequestsModalOpen(true)}>
                  <i className="fa-solid fa-calendar mr-2"></i>
                  Appointment Requests
                </Button>
              </Grid>
              <Grid item>
                <Button color="secondary" onClick={() => history.push('/default/badge-office/appointment-settings')}>
                  <i className="fa-regular fa-calendar-xmark mr-2"></i>
                  Update Badge Office Schedule
                </Button>
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    aria-label="appointments view"
                    name="appointmentsView"
                    value={isGridView ? 'true' : 'false'}
                    onChange={({ target }) => setIsGridView(target.value === 'true')}
                  >
                    <FormControlLabel value="false" control={<Radio color="primary" />} label="Daily View" />
                    <FormControlLabel value="true" control={<Radio color="primary" />} label="Upcoming List View" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container style={isGridView ? null : { display: 'none' }}>
              <Grid item xs={12}>
                <AppointmentsGrid
                  shouldReload={shouldReload}
                  setShouldReload={setShouldReload}
                  appointmentTypes={appointmentTypes}
                  staffOptions={staffOptions}
                  appointmentType={appointmentType}
                  setAppointmentType={setAppointmentType}
                  staff={staff}
                  setStaff={setStaff}
                  shouldShowExtraActionButtons={isGridView}
                />
              </Grid>
            </Grid>

            <Grid container style={isGridView ? { display: 'none' } : null}>
              <Grid item xs={12}>
                <div className={classes.filterRowTop}>
                  <div className={classes.paddles}>
                    <Button onClick={goBackOneDay} className={classes.backButton}>
                      <i className="fa fa-chevron-left" aria-label="Go Back One Day"></i>
                    </Button>
                    <Button onClick={goForwardOneDay} className={classes.forwardButton}>
                      <i className="fa fa-chevron-right" aria-label="Go Forward One Day"></i>
                    </Button>
                  </div>
                  <Button onClick={goToNow} className={classes.todayButton}>
                    Show Today
                  </Button>
                  <div className={classes.startDate}>
                    <DatetimePickerTrigger
                      className="start-date-trigger"
                      moment={startDate}
                      showTimePicker={false}
                      onChange={onChangeStartDay}
                    >
                      <Input type="text" id="start-date" value={startDateFormatted} readOnly />
                      <i
                        className="fa fa-calendar"
                        style={{
                          position: 'absolute',
                          left: 10,
                          fontSize: 20,
                          top: 8,
                          pointerEvents: 'none',
                        }}
                      ></i>
                    </DatetimePickerTrigger>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.filterRowBottom}>
                  <div className={classes.statusesSelect}>
                    <Select
                      classNamePrefix="airbadge"
                      isSearchable={true}
                      options={statusFilters}
                      className="form-select"
                      value={statusFilter}
                      placeholder=""
                      onChange={value => setStatusFilter(value)}
                    />
                  </div>
                  <div className={classes.typesSelect}>
                    <Select
                      classNamePrefix="airbadge"
                      isSearchable={true}
                      options={appointmentTypes}
                      className="form-select"
                      value={appointmentType}
                      placeholder=""
                      onChange={value => setAppointmentType(value)}
                    />
                  </div>
                  <div className={classes.staffSelect}>
                    <Select
                      classNamePrefix="airbadge"
                      isSearchable={true}
                      options={staffOptions}
                      className="form-select"
                      value={staff}
                      placeholder=""
                      onChange={value => setStaff(value)}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <BadgeOfficeCalendar
                  isLoading={isLoading}
                  startDate={startDate}
                  appointments={data}
                  onEdit={onEditAppointmentClick}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <AppointmentsListCreateModal
        isOpen={newAppointmentModal}
        onClose={onNewAppointmentModalClose}
        user={user}
        api={api}
        reload={reload}
        timeZone={timeZone}
      />

      <AppointmentsListEditModal
        isOpen={editAppointmentModal}
        onClose={onEditAppointmentModalClose}
        user={user}
        api={api}
        reload={reload}
        timeZone={timeZone}
        editAppointment={editAppointment}
        shouldShowExtraActionButtons={isGridView}
      />

      <AppointmentStatusChangeModal
        isOpen={isAppointmentStatusChangeModalOpen}
        data={appointmentStatusChangeModalData}
        onClose={onAppointmentStatusChangeModalClosed}
      />

      <AppointmentRequests isOpen={isAppointmentRequestsModalOpen} onClose={onAppointmentRequestsModalClose} />
    </AppointmentCalendarContext.Provider>
  );
}
