import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext, colors } from '../../../../../../App';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FontAwesomeIcon from '../../../../../../shared/components/FontAwesome/FontAwesomeIcon';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import InputWithCharacterLimit from '../../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import { handleError } from '../../../../../../utils';

const CHARACTER_LIMIT = 255;

export function TerminateBadgeApplicationModal({ isOpen, onClose, children }) {
  const [reason, setReason] = useState('');

  useEffect(() => {
    setReason('');
  }, [isOpen]);

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Terminate Badge Application"
      isOpen={isOpen}
      onClose={() => onClose({ shouldTerminate: false, reason: null })}
      closeLabel="Cancel"
      onSave={() => onClose({ shouldTerminate: true, reason })}
      saveLabel="Terminate Badge Application"
      saveDisabled={!reason}
      saveColor="danger"
    >
      <div>{children}</div>
      <FormGroup>
        <Label className="required">Reason for termination</Label>
        <div style={{ fontSize: 'smaller', marginBottom: 6 }}>
          This will be appended to the notification that is sent out to all configured recipients
        </div>
        <InputWithCharacterLimit limit={CHARACTER_LIMIT} value={reason} onChange={value => setReason(value)} />
      </FormGroup>
    </AirBadgeModal>
  );
}

export default function CancelBadgeApplicationAction() {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { badgeApplication, onActionDone } = useContext(ActionPanelContext);

  const isDisabled = useCallback(() => {
    const { active } = badgeApplication;
    return !!active === false;
  }, [badgeApplication]);

  const onModalClose = ({ shouldTerminate, reason }) => {
    if (!shouldTerminate) {
      return setIsModalOpen(false);
    }

    app.api.toggleLoading(true);
    const { uuid } = badgeApplication;
    badgeApplicationApi
      .cancel({ badgeApplicationUuid: uuid, reason })
      .then(() => {
        setIsModalOpen(false);
        onActionDone({ shouldReload: true });
      })
      .catch(error => handleError({ error, message: 'Unable to terminate Badge Application' }))
      .finally(() => app.api.toggleLoading(false));
  };

  return (
    <>
      <ListItem button disabled={isDisabled()} onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <FontAwesomeIcon className="fa fa-ban" color={colors.danger} />
        </ListItemIcon>
        <ListItemText primary="Terminate Badge Application" />
      </ListItem>

      <TerminateBadgeApplicationModal isOpen={isModalOpen} onClose={onModalClose} />
    </>
  );
}
